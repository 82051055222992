import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface Props {
  penColor?: string;
  width?: number;
  height?: number;
  onEnd?: (event: MouseEvent) => void;
}

// eslint-disable-next-line react/display-name
export const SignaturePad = React.forwardRef<SignatureCanvas, Props>(
  ({ penColor = '#fff', onEnd, ...props }, ref) => {
    return (
      <SignatureCanvas
        ref={ref}
        penColor={penColor}
        onEnd={onEnd}
        canvasProps={{
          className: 'sigCanvas',
          ...props
        }}
      />
    );
  }
);
