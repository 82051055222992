import { makeStyles } from '@mui/styles';

const options = {
  name: 'time-picker'
};

const useStyles = makeStyles(
  () => ({
    icon: {
      width: 24,
      height: 24,
      paddingLeft: 4,
      marginRight: -4,
      border: 0,
      borderLeftWidth: 1,
      borderColor: '#E3EAF3',
      borderStyle: 'solid'
    }
  }),
  options
);

export default useStyles;
