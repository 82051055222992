import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IVerifyCodeValues {
  authCode: string;
}

interface IVerifyTwoFactorCodeThunkArguments {
  values: IVerifyCodeValues;
  options?: Partial<IRequestOptions>;
}

export const get2fa = createAsyncThunk('getTwoFactorCodeAction', async () => {
  try {
    const data: any = await authService.get('/get_qr');
    return data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const verify2fa = createAsyncThunk(
  'verifyTwoFactorCodeAction',
  async ({ values, options }: IVerifyTwoFactorCodeThunkArguments) => {
    try {
      const data: any = await authService.post('/2fa_check', values);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error(
        'Authentication is failed please go to login page and try again'
      );
    }
  }
);

export const twoFactorSetupSlice = createSlice({
  name: 'twoFactorSetupSlice',
  initialState: {
    get2fa: {
      loading: false,
      data: null,
      error: null
    },

    verify2fa: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get2fa.pending, (state) => {
      state.get2fa.loading = true;
      state.get2fa.data = null;
      state.get2fa.error = null;
    });
    builder.addCase(get2fa.fulfilled, (state, { payload }) => {
      state.get2fa.loading = false;
      state.get2fa.data = payload.data;
      state.get2fa.error = null;
    });
    builder.addCase(get2fa.rejected, (state) => {
      state.get2fa.loading = false;
      state.get2fa.data = {};
      state.get2fa.error = 'Something went wrong';
    });
    builder.addCase(verify2fa.pending, (state) => {
      state.verify2fa.loading = true;
      state.verify2fa.data = null;
      state.verify2fa.error = null;
    });
    builder.addCase(verify2fa.fulfilled, (state, { payload }) => {
      state.verify2fa.loading = false;
      state.verify2fa.data = payload;
      state.verify2fa.error = null;
    });
    builder.addCase(verify2fa.rejected, (state) => {
      state.verify2fa.loading = false;
      state.verify2fa.data = null;
      state.verify2fa.error =
        'Wrong verify code, please check your google authenticator app for actual code';
    });
  }
});

export default twoFactorSetupSlice.reducer;
