import { useNavigate } from 'react-router';
import { Stack, Grid, Button } from '@mui/material';
import { Card } from '../../components/cards';
import { Badge } from '../../components/badge';
import { LabeledRecord } from '../../components/labeled-record';
import { PageHeader } from '../../components/page-header';
import { IconInfo } from '../../components/icons';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import {
  ROUTE_SUPER_ADMIN_USERS,
  ROUTE_SUPER_ADMIN_USER_PROFILE_EDIT
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { useLayout } from '../../core/hooks/useLayout';
import { useDialog } from '../../core/hooks/useDialog';

const headerProps = {
  divider: false,
  title: 'Dianna Russell',
  meta: (
    <>
      <Badge label={'312'} variant={'secondary'} />
      <Badge label={'Active'} variant="active" RightIcon={<IconInfo />} />
    </>
  )
};

const backUrl = url(ROUTE_SUPER_ADMIN_USERS);

export const SuperAdminUserProfileContainer = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const navigate = useNavigate();

  useLayout({
    backButton: {
      title: 'Users',
      path: backUrl
    }
  });

  const handleConfirmResetPassword = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm reset password',
      message:
        'Do you really want to reset the password for Dianne Russel (Diffco)?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleConfirmReset2FA = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm reset 2-factor authentication',
      message: 'Do you really want to reset 2FA for Dianne Russel (Diffco)?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleConfirmUserTermination = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm user termination',
      message: 'Do you really want to terminate Dianne Russell?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleSendInviteAgain = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm user send invite',
      message: 'Do you really want to send invite to Dianne Russell again?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  return (
    <>
      <PageHeader {...headerProps}>
        <Button color="secondary" onClick={handleSendInviteAgain('123')}>
          Send invite again
        </Button>
        <Button
          color="secondary"
          onClick={() =>
            navigate(url(ROUTE_SUPER_ADMIN_USER_PROFILE_EDIT, { id: '312' }))
          }
        >
          Edit
        </Button>
        <Button color="secondary" onClick={handleConfirmReset2FA('123')}>
          Reset 2FA
        </Button>
        <Button color="secondary" onClick={handleConfirmResetPassword('123')}>
          Reset Password
        </Button>
        <Button color="third" onClick={handleConfirmUserTermination('123')}>
          Terminate
        </Button>
      </PageHeader>
      <Stack mt={1.5} spacing={1} direction="column">
        <Card title="General Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord label="Administrator Name:" value="Debra Lane" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Company Name:" value="Splashworks" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Email:" value="d.lane@gmail.com" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Phone Number:" value="(465) 432-5432" />
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};
