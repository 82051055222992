import { ComponentType, useState, useEffect } from 'react';
import { DialogProps } from '../../../core/providers/dialog.provider';
import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
  DurationField,
  PlaceServiceField,
  ServiceCodeMultipleField,
  TextField,
  AppointmentTypeField,
  SelectField
} from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { coreTeamMembersColumns } from '../_mockdata';
import { useDialog } from '../../../core/hooks/useDialog';
import { AddRecurrenceDialog } from './AddRecurrenceDialog';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { AppointmentApi, LocationApi } from '../../../core/http';
import { addMinutes, format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { ROUTE_APPOINTMENTS_COMPARE_AVAILABILITY } from '../../../constants/route.paths';
import { clearNullishObject } from '../../../core';
import { get, isUndefined, map } from 'lodash';
import { useClientLocations } from '../../../core/hooks/useClientLocations';
import { AppointmentCareTeamApi } from '../../../core/http/requests/appointment-care-team.api';
import { ActiveClientAutocompleteField } from '../../../components/fields/active-client-autocomplete-field';

interface Props extends DialogProps {
  onClose?: () => void;
  clientUuid?: string;
}

const appointmentCareTeamApi = new AppointmentCareTeamApi();
const appointmentApi = new AppointmentApi();
const locationApi = new LocationApi();

export const AddNewAppointmentDialog: ComponentType<Props> = ({
  popDialog,
  clientUuid: originalClientUuid
}) => {
  const navigate = useNavigate();
  const [openAddRecurrence] = useDialog(AddRecurrenceDialog);
  const [recurrence, setRecurrence] = useState({ values: {}, display: '' });
  const [locations, setLocations] = useState<any>();
  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [careTeamLoading, setCareTeamLoading] = useState<boolean>(false);
  const [careTeamData, setCareTeamData] = useState<any>();
  const uuid = '';

  const {
    isLoading,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    getFieldTouchedError
  } = useEditResource(appointmentApi, {
    uuid,
    initialValues: {
      clientUuid: originalClientUuid || '',
      typeUuid: '',
      careTeamMembers: [],
      serviceCodeUuids: [],
      dateStart: new Date(),
      dateEnd: '',
      duration: '',
      servicePlaceUuid: '',
      location: ''
    },
    validationSchema: yup.object().shape({
      clientUuid: yup.string().required('Required field'),
      typeUuid: yup.string().required('Required field'),
      serviceCodeUuids: yup.array().min(1, 'Required field'),
      dateStart: yup.string().required('Required field'),
      duration: yup.string().required('Required field'),
      servicePlaceUuid: yup.string().required('Required field'),
      location: yup.string().required('Required field')
    }),
    onSubmit: async ({ dateEnd, dateStart, ...values }) => {
      const startDate = dateStart || new Date();
      const endDate = dateEnd;

      // TODO: add recurrence logic
      // const recurrenceValues = get(recurrence, 'values');
      // const recurrenceStartDate = get(recurrence, 'values.startRangeDate');
      // const recurrentEndDate = get(recurrence, 'values.endRangeDate');

      const payload = {
        ...values,
        // isRecurrent: !isEmpty(recurrenceValues),
        // recurrentDays: get(recurrenceValues, 'recurrentDays', []),
        // recurrentPattern: get(recurrenceValues, 'recurrentPattern', null),
        // recurrentStartDate: isDate(recurrenceStartDate)
        //   ? recurrenceStartDate.toISOString()
        //   : null,
        // recurrentEndDate: isDate(recurrentEndDate)
        //   ? recurrentEndDate.toISOString()
        //   : null,
        duration: values.duration,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        location: values.location
      };

      const params = new URLSearchParams(
        clearNullishObject(payload)
      ).toString();
      popDialog();
      navigate(ROUTE_APPOINTMENTS_COMPARE_AVAILABILITY + '?' + params);
    }
  });

  const { location, servicePlaceUuid, clientUuid, serviceCodeUuids } = values;
  const { items: clientLocationsData } = useClientLocations(clientUuid);

  useEffect(() => {
    if (!isEmpty(servicePlaceUuid)) {
      if (
        servicePlaceUuid === '32c426a9-36fa-496e-9004-f08890c1cb05' &&
        clientUuid
      ) {
        setLoadingLocations(true);
        const transforemdLocations = map(
          clientLocationsData,
          ({ city, state }) => {
            return {
              label: `Home, ${state}-${city}`,
              value: `Home, ${state}-${city}`
            };
          }
        );
        setLocations(transforemdLocations);
        setLoadingLocations(false);
      } else {
        setLoadingLocations(true);
        locationApi.findAll({ limit: 1000 }).then(({ items }) => {
          const transforemdLocations = map(
            items,
            ({ state, city, region, zipCode }) => {
              return {
                label: `${state}-${city}, ${region}, ${zipCode}`,
                value: `${state}-${city}, ${region}, ${zipCode}`
              };
            }
          );
          setLocations(transforemdLocations);
          setLoadingLocations(false);
        });
      }
    }
  }, [servicePlaceUuid, clientUuid]);

  useEffect(() => {
    if (
      !isEmpty(location) &&
      !isEmpty(serviceCodeUuids) &&
      !isEmpty(clientUuid)
    ) {
      const [state, city] = location.split('-');
      setCareTeamLoading(true);
      appointmentCareTeamApi
        .findAll({
          serviceCodeUuids,
          clientUuid,
          city,
          state
        })
        .then((response) => {
          setCareTeamData(response);
        })
        .finally(() => {
          setCareTeamLoading(false);
        });
    }
  }, [location, serviceCodeUuids, clientUuid]);

  const handleChangeDuration = (dateStart, duration) => {
    setFieldValue('dateEnd', addMinutes(dateStart, duration));
    setFieldValue('duration', duration);
  };

  const onChangeLocation = (location) => {
    setFieldValue('location', location);
  };

  const appointmentStartDate = get(values, 'dateStart');
  const appointmentDuration = get(values, 'duration');

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 800,
          height: 'calc(100vh - 32px)'
        }
      }}
    >
      <DialogTitle>Add new appointment</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={1}>
          <Grid item md={6}>
            <ActiveClientAutocompleteField
              disabled={!!originalClientUuid}
              label="Client *"
              placeholder="Select"
              value={values.clientUuid}
              onChange={(clientUuid) => {
                setFieldValue('clientUuid', clientUuid);
                setFieldValue('careTeamMembers', []);
              }}
              errors={getFieldTouchedError('clientUuid')}
            />
          </Grid>
          <Grid item md={6}>
            <AppointmentTypeField
              label="Appointment Type *"
              placeholder="Select"
              onChange={handleChange('typeUuid')}
              value={values.typeUuid}
              onBlur={handleBlur('typeUuid')}
              errors={getFieldTouchedError('typeUuid')}
            />
          </Grid>
        </Grid>
        <ServiceCodeMultipleField
          label="Service code *"
          placeholder="Select"
          value={values.serviceCodeUuids}
          onChange={(value) => setFieldValue('serviceCodeUuids', value)}
          errors={getFieldTouchedError('serviceCodeUuids')}
        />
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <DatePicker
              label="Date*"
              value={values.dateStart}
              formatValue={false}
              onChange={(value) => setFieldValue('dateStart', value)}
              errors={getFieldTouchedError('dateStart')}
              onBlur={handleBlur('dateStart')}
            />
          </Grid>
          <Grid item md={3}>
            <TimePicker
              label="Start Time"
              value={values.dateStart}
              onChange={(value) => {
                setFieldValue('dateStart', value);
                handleChangeDuration(value, values.duration);
              }}
              errors={getFieldTouchedError('dateStart')}
            />
          </Grid>
          <Grid item md={3}>
            <DurationField
              label="Duration*"
              value={values.duration}
              onChange={(duration) =>
                handleChangeDuration(values.dateStart, duration)
              }
              onBlur={handleBlur('duration')}
              errors={getFieldTouchedError('duration')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label="End time"
              disabled
              value={values.dateEnd && format(values.dateEnd, 'hh:mm aaa')}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={6}>
            <PlaceServiceField
              label="Place of Service*"
              placeholder="Select"
              value={values.servicePlaceUuid}
              onChange={(value) => setFieldValue('servicePlaceUuid', value)}
              onBlur={handleBlur('servicePlaceUuid')}
              errors={getFieldTouchedError('servicePlaceUuid')}
            />
          </Grid>
          <Grid item md={6}>
            <SelectField
              label="Location"
              required
              disabled={loadingLocations}
              items={locations || []}
              placeholder="Select"
              value={values.location}
              onChange={onChangeLocation}
              onBlur={handleBlur('location')}
              errors={getFieldTouchedError('location')}
            />
          </Grid>
        </Grid>

        <Divider />
        <Box my={2}>
          <Typography variant="h4" mb={1}>
            Recurrence
          </Typography>
          {!isEmpty(recurrence?.values) ? (
            <>
              <Box mb={2}>
                <Chip
                  label={recurrence.display}
                  onDelete={() => setRecurrence(null)}
                  color="primary"
                  size="small"
                />
              </Box>
              <Button
                color="secondary"
                onClick={() => {
                  openAddRecurrence({
                    appointmentStartDate,
                    appointmentDuration,
                    values: recurrence.values,
                    onSubmit: (value) => {
                      setRecurrence(value);
                    }
                  });
                }}
              >
                Edit recurrence
              </Button>
            </>
          ) : (
            <Button
              color="secondary"
              disabled={!appointmentStartDate || !appointmentDuration}
              onClick={() => {
                openAddRecurrence({
                  appointmentStartDate,
                  appointmentDuration,
                  onSubmit: (value) => {
                    setRecurrence(value);
                  }
                });
              }}
            >
              + Add recurrence
            </Button>
          )}
        </Box>
        <Box sx={{ height: 280 }}>
          {(!isUndefined(careTeamData) || careTeamLoading) && (
            <>
              <Divider />
              <Box mb={1}>
                <Typography variant="h4" my={2}>
                  Therapists
                </Typography>
                <TableView
                  style={{ maxHeight: 280 }}
                  isStickyHeader={true}
                  loadingSkeletonCount={2}
                  loading={careTeamLoading}
                  onChangeSort={(sortParams) => {
                    const items = get(careTeamData, 'items', []);
                    setCareTeamData({
                      ...careTeamData,
                      items: items.sort((a, b) => {
                        const keys = Object.keys(sortParams);
                        let n = 0;
                        for (let i = 0; i < keys.length; i++) {
                          if (!sortParams[keys[i]]) continue;
                          n =
                            (a[keys[i]] - b[keys[i]]) *
                            (sortParams[keys[i]] === 'desc' ? -1 : 1);

                          if (n !== 0) {
                            return n;
                          }

                          continue;
                        }
                        return n;
                      })
                    });
                  }}
                  rowSelection={{
                    selectedRowKeys: values.careTeamMembers,
                    onChange: (selected) => {
                      setFieldValue('careTeamMembers', selected);
                    }
                  }}
                  showEmptyState
                  EmptyStateProps={{ title: 'No therapists found', p: 2 }}
                  keyExtractor={(item) => item.employee.uuid}
                  columns={coreTeamMembersColumns}
                  dataSource={get(careTeamData, 'items', [])}
                />
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="third" sx={{ marginRight: 'auto' }} onClick={popDialog}>
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setFieldValue(
              'careTeamMembers',
              get(careTeamData, 'items', []).map((item) => item.employee.uuid)
            );
            handleSubmit();
          }}
          disabled={isEmpty(get(careTeamData, 'items', []))}
        >
          Show all therapists
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={isLoading || isEmpty(values.careTeamMembers)}
        >
          Compare availability
        </Button>
      </DialogActions>
    </Dialog>
  );
};
