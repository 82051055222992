import { ComponentType, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Stack, TextField, Button } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BackButton } from '../../components/back-button';
import {
  ROUTE_TWO_FACTOR_RECOVERY,
  ROUTE_SIGN_IN,
  ROUTE_TWO_FACTOR_SETUP_SUCCESS,
  ROUTE_CLIENTS,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import {
  verify2FA,
  set2FA,
  resendCode
} from '../../core/store/slices/twoFactorSlice';
import { url } from '../../core/utils/route.utils';
import { AppState } from '../../core/store/store';

const TIMER_LIMIT = 10;

export const TwoFactor: ComponentType = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const verify2FALoading = useSelector(
    (state: AppState) => state.twoFactor.verify2FALoading
  );
  const set2FALoading = useSelector(
    (state: AppState) => state.twoFactor.setTwoFactorLoading
  );
  const resendCodeLoading = useSelector(
    (state: AppState) => state.twoFactor.resendCodeLoading
  );

  const [searchParams] = useSearchParams();
  const [timer, setTimer] = useState<number>(TIMER_LIMIT);

  const type = searchParams.get('type');

  const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        code: ''
      },
      validateOnBlur: true,
      validationSchema: yup.object().shape({
        code: yup
          .string()
          .min(6, ({ min }) => `Security code must be ${min} characters`)
          .required('Security code is required')
      }),
      onSubmit: (values) => {
        if (type === 'set') {
          dispatch(
            set2FA({
              values,
              options: {
                onSuccess: () => {
                  navigate(url(ROUTE_TWO_FACTOR_SETUP_SUCCESS));
                }
              }
            })
          );
        } else if (type === 'verify') {
          dispatch(
            verify2FA({
              values,
              options: {
                onSuccess: () => {
                  navigate(url(ROUTE_CLIENTS));
                }
              }
            })
          );
        }
      }
    });

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const addZero = (n: number) => {
    if (n < 10) {
      return `0${n}`;
    }
    return n;
  };

  return (
    <>
      <Box className={classes.twoFactorRoot}>
        <Box className={classes.block}>
          <BackButton title="Login" onClick={() => navigate(ROUTE_SIGN_IN)} />
          <Typography variant="h4" mb={2} mt={2}>
            {type === 'set'
              ? 'Setup 2-factor authorization'
              : '2-Factor Authorization'}
          </Typography>
          <Typography variant="body2">
            We have sent you a six-digit security code to(234) ***-**00. Please,
            enter it below to complete the setup.
          </Typography>
          <Box component="form" mt={2}>
            <TextField
              helperText={touched.code && errors.code}
              type="string"
              value={values.code}
              onChange={handleChange('code')}
              onBlur={handleBlur('code')}
              inputProps={{ maxLength: 6 }}
              onKeyPress={(event) => {
                if (
                  !(
                    event.charCode >= 8 &&
                    event.charCode <= 57 &&
                    event.charCode != 43 &&
                    event.charCode != 45 &&
                    event.charCode != 32
                  )
                ) {
                  event.preventDefault();
                }
              }}
            />

            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Button
                size="medium"
                disabled={verify2FALoading || set2FALoading}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
              <Button
                variant="text"
                disabled={timer !== 0 || resendCodeLoading}
                onClick={() =>
                  dispatch(
                    resendCode({
                      options: { onSuccess: () => setTimer(TIMER_LIMIT) }
                    })
                  )
                }
              >
                Resend Code
                {timer > 0 && (
                  <>
                    ({Math.floor(timer / 60)}:{addZero(timer % 60)})
                  </>
                )}
              </Button>
            </Stack>
          </Box>
          <Box mt={7} className={classes.enterRecoveryCodeBlock}>
            <Button
              variant="text"
              onClick={() => navigate(url(ROUTE_TWO_FACTOR_RECOVERY))}
            >
              Enter Recovery Code
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </>
  );
};
