import { EmployeeForm } from '../../../features/employees/EmployeeForm';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const EmployeeProfileEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Employees'
  })
)(EmployeeForm);
