import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { EmployeeAvailabilityDto } from '../dto';
import { EmployeeCaseLoadDto } from '../dto/employee-case-load-dto';

export class EmployeeCaseLoadApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeCaseLoadDto>> {
    return this.httpClient
      .get(`/api/v1/employee/${params.employeeUuid}/case-load`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeAvailabilityDto, data.items)
        };
      });
  }
}
