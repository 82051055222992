import React from 'react';
import { Button, Divider, Link } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { Badge } from '../../components/badge';
import { Alert } from '../../components/alert/Alert';
import { useLayout } from '../../core/hooks/useLayout';
import { ROUTE_EMPLOYEE_DETAILS } from '../../constants/route.paths';
import { url } from '../../core';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useEmployeeDetail } from '../../core/hooks/useEmployeeDetail';
import { AppointmentGeneralForm } from '../appointments/AppointmentGeneralForm';
import { useAppointmentEdit } from '../../core/hooks/useAppointmentEdit';

export const EmployeeAppointmentEdit = () => {
  const { employeeId, uuid } = useParams();
  const navigate = useNavigate();

  const backUrl =
    url(ROUTE_EMPLOYEE_DETAILS, { id: employeeId, tab: 'dashboard' }) +
    '#appointments';

  const { data: employee } = useEmployeeDetail({
    uuid: employeeId
  });

  const {
    isLoading,
    isEdit,
    handleSubmit,
    values,
    setFieldValue,
    data: appointment,
    getFieldTouchedError,
    handleDelete,
    handleConvert,
    handleCancel,
    handleBlur,
    setFieldTouched
  } = useAppointmentEdit(uuid, backUrl);

  useLayout({
    backButton: {
      title: employee?.displayName || '',
      path: backUrl
    }
  });

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit Appointment' : 'Add Appointment'}
        meta={
          isEdit && (
            <>
              {employee?.id && (
                <Badge label={employee?.id} variant={'secondary'} />
              )}
              {appointment?.status && (
                <Badge
                  label={appointment?.status}
                  variant={appointment?.status}
                />
              )}
            </>
          )
        }
      >
        {isEdit && (
          <>
            {appointment?.canConvert && (
              <Button
                color="secondary"
                onClick={() => handleConvert(appointment)}
                disabled={isLoading}
              >
                Convert
              </Button>
            )}
            {appointment?.canDelete && (
              <Button
                color="secondary"
                onClick={() => handleDelete(appointment)}
                disabled={isLoading}
              >
                Delete
              </Button>
            )}
            {appointment?.canCancel && (
              <Button
                color="secondary"
                onClick={() => handleCancel(appointment)}
                disabled={isLoading}
              >
                Cancel appointment
              </Button>
            )}
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: 32,
                borderColor: '#F6F9FF!important',
                margin: '0 8px 0 16px!important'
              }}
            />
          </>
        )}
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button color="third" onClick={() => navigate(backUrl)}>
          Cancel
        </Button>
      </PageHeader>
      <Alert mb={1.25}>
        This appointment is for 2:30 Hour(s) and occurs weekly on MON,WED,FRI
        02/24/2021 through 01/06/2023.
        <Link variant="body2" color="primary" underline="none">
          Recurring Details
        </Link>
      </Alert>
      <AppointmentGeneralForm
        {...{
          values,
          setFieldValue,
          getFieldTouchedError,
          handleSubmit,
          handleBlur,
          isLoading,
          setFieldTouched
        }}
      />
    </>
  );
};
