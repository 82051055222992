import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineController,
  LineElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  indexAxis: 'x' as const,
  responsive: true,
  options: {
    scales: {
      y: {
        stacked: true
      }
    }
  },

  plugins: {
    legend: {
      display: false,
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    },
    datalabels: {
      align: 'end' as const,
      anchor: 'end' as const,
      formatter: function (value, context) {
        const res = context.chart.data.datasets.map((item) => {
          return item.data[context.dataIndex];
        });

        const maxIndex = res.findIndex(
          (item) => Math.max(...res) === item && item === value
        );

        if (Math.max(...res) === value && context.datasetIndex === maxIndex) {
          return currencyFormatter(value);
        }
        return null;
      }
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: true,
        color: '#4B5663'
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: true
      },
      scaleLabel: {
        display: false
      },
      ticks: {
        color: '#172B4D',
        font: {
          size: 10,
          weight: '500px'
        },
        padding: 10,
        callback: (value) => currencyFormatter(value)
      }
    }
  }
};
