import { Expose } from 'class-transformer';

export class FileDto {
  @Expose()
  id: number;

  @Expose()
  uuid: string;

  @Expose()
  url: string;

  @Expose()
  type: string;

  @Expose()
  rootType: string;

  @Expose()
  fileName: string;

  @Expose()
  description: string;

  @Expose()
  expirationDate: string;

  @Expose()
  noExpirationDate: boolean;

  @Expose()
  createdAt: string;
}
