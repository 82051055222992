import { useEffect } from 'react';
import { DataTableRowSelection } from './TableView';

export const useTableSelection = (
  dataSource: Record<string, any>[],
  rowSelection?: DataTableRowSelection,
  keyExtractor?: (item: Record<string, any>) => string
) => {
  const selectedRows = rowSelection?.selectedRowKeys || [];
  const onChange = rowSelection?.onChange;
  const isSelectedRowsEmpty = selectedRows.length === 0;
  const isSelectedAll =
    selectedRows.length > 0 && selectedRows.length === dataSource.length;

  useEffect(() => {
    if (onChange) {
      onChange(selectedRows);
    }
  }, [selectedRows]);

  const handleSelectRow = (id: string) => {
    if (selectedRows.includes(id)) {
      onChange && onChange(selectedRows.filter((item) => item !== id));
    } else {
      onChange && onChange([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (isSelectedRowsEmpty) {
      onChange && onChange(dataSource.map((item) => keyExtractor(item)));
    } else {
      onChange([]);
    }
  };

  return {
    selectedRows,
    isSelectedRowsEmpty,
    isSelectedAll,
    handleSelectRow,
    handleSelectAll
  };
};
