import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'table-view'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& .MuiCheckbox-root .MuiSvgIcon-root': {
        color: '#B8BDD9'
      },
      '& .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root, & .MuiCheckbox-indeterminate .MuiSvgIcon-root':
        {
          color: theme.palette.secondary.main
        }
    },
    scrollable: {
      height: '400px',
      overflowY: 'scroll'
    },
    striped: {
      '& tbody tr:nth-of-type(even) ': {
        backgroundColor: '#F4F6FF'
      }
    }
  }),
  options
);

export default useStyles;
