import { useState, useEffect, FC } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import { IconSubtract } from '../../icons';
import { filter, find, map, isEmpty, get } from 'lodash';
import { TextField } from '../text-field';

export interface OptionsAutocompleteProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  options: Options[];
  limitTags?: number;
  multiple?: boolean;
  onChange?: any;
  label?: string;
  placeholder?: string;
  helperText?: string | null;
  value?: string | string[];
  size?: 'small' | 'medium';
  forcePopupIcon?: boolean;
  freeSolo?: boolean;
  filterOptions?: any;
  errors?: any;
}

export interface Options {
  label: string;
  value: string;
}

export const OptionsAutocomplete: FC<OptionsAutocompleteProps> = ({
  className,
  loading,
  disabled,
  limitTags,
  label,
  placeholder,
  size,
  multiple,
  helperText,
  value,
  onChange,
  options,
  forcePopupIcon = false,
  errors
}) => {
  const [selected, setSelected] = useState<Options[] | Options>(
    multiple ? [] : null
  );

  useEffect(() => {
    if (!isEmpty(value) && !isEmpty(options)) {
      if (Array.isArray(value)) {
        const selectedValues: Options[] = filter(options, (option) =>
          value.includes(option.value)
        );
        setSelected(selectedValues);
      } else {
        const selectedValue: Options = find(
          options,
          (option) => option.value === value
        );
        setSelected(selectedValue);
      }
    } else {
      setSelected(multiple ? [] : null);
    }
  }, [value, options]);

  const handleChange = (e, value) => {
    onChange && onChange(multiple ? map(value, 'value') : get(value, 'value'));
  };

  return (
    <Autocomplete
      fullWidth
      clearIcon={<IconSubtract />}
      ChipProps={{
        color: 'primary'
      }}
      {...{
        disabled,
        options,
        size,
        multiple,
        loading,
        value: selected,
        limitTags,
        forcePopupIcon
      }}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          className={className}
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'unset'
            }
          }}
          {...params}
          {...{
            label,
            placeholder: isEmpty(selected) ? placeholder : null,
            helperText
          }}
          hiddenLabel
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>
          }}
          errors={errors}
        />
      )}
    />
  );
};
