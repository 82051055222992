import { useEffect, useState } from 'react';
import { Stack, Grid } from '@mui/material';
import {
  SearchField,
  TextField,
  WeekArrowPicker
} from '../../../../components/fields';
import { TableView } from '../../../../components/table-view';
import { Card } from '../../../../components/cards';
import {
  getEmployeeAbsenceScheduleTotals,
  getEmployeeAbsenceScheduleDays,
  createEmployeeAbsenceScheduleColumns
} from './_mockdata';
import { Badge } from '../../../../components/badge';
import { useEmployeeAvailabilitySchedule } from '../../../../core/hooks/useEmployeeAvailabilitySchedule';
import { startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns';

export const EmployeeAbsenceSchedule = () => {
  const [searchString, setSearchString] = useState('');
  const [dates, setDates] = useState([
    startOfWeek(startOfDay(new Date())),
    endOfWeek(endOfDay(new Date()))
  ]);
  const { items, handleChangeParams } = useEmployeeAvailabilitySchedule({
    defaultParams: {
      'filter[startDate][gte]': dates[0],
      'filter[endDate][lte]': dates[1]
    }
  });

  useEffect(() => {
    handleChangeParams({
      'filter[startDate][gte]': dates[0],
      'filter[endDate][lte]': dates[1]
    });
  }, [dates]);

  const itemsWithTotals = items.map((item) => ({
    ...item,
    total: item.leaveDays.length
  }));

  const totals = getEmployeeAbsenceScheduleTotals(itemsWithTotals, dates);
  const days = getEmployeeAbsenceScheduleDays(itemsWithTotals, dates);

  const enhancedItems = itemsWithTotals.length
    ? [days, ...itemsWithTotals, totals]
    : itemsWithTotals;

  console.log(enhancedItems);

  return (
    <>
      <Card title="Employee Absence Schedule">
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack direction="row" spacing={2}>
                <TextField disabled value="Stars Therapy Inc" size="small" />
                <WeekArrowPicker dates={dates} setDates={setDates} />
              </Stack>
            </Grid>
            <Grid item md={6}>
              <SearchField
                placeholder="Search"
                onChange={(v) => setSearchString(v)}
              />
            </Grid>
          </Grid>
        </Stack>
        <TableView
          keyExtractor={(item) => item.id}
          dataSource={enhancedItems.filter(
            (item) =>
              !item.employeeName || item.employeeName.startsWith(searchString)
          )}
          showEmptyState
          EmptyStateProps={{
            description: null
          }}
          columns={createEmployeeAbsenceScheduleColumns(items, dates)}
          scrollable
          isStickyHeader
        />
        <Stack direction="row" spacing={2}>
          <Grid>
            <Badge variant="success" label="V" /> Vacation
          </Grid>
          <Grid>
            <Badge variant="warning" label="S" /> Sick
          </Grid>
          <Grid>
            <Badge label="P" /> Personal
          </Grid>
          <Grid>
            <Badge label="O" /> Optional
          </Grid>
        </Stack>
      </Card>
    </>
  );
};
