import { Expose, Transform } from 'class-transformer';
import { get } from 'lodash';

export class UserDto {
  @Expose()
  id: number;

  @Expose()
  uuid: string;

  @Expose()
  userName: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  avatar: string;

  @Expose()
  email: string;

  @Expose()
  phoneNumber: string;

  @Expose()
  createdAt: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'userRole.uuid'))
  roleUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'userRole.title'))
  roleTitle: string;

  @Expose()
  roles: string[];

  @Expose()
  userRole: any;

  @Expose()
  get displayName() {
    return (
      [this.firstName, this.lastName].filter(Boolean).join(' ') || this.email
    );
  }
}
