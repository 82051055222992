export enum SERVICE_CODE_TYPE {
  billable = 'Billable',
  non_billable = 'Non billable'
}

export enum ENFORCE_RATE {
  default_rate_can_be_used = 'Default rate can be used',
  enforce_custom_provider_rate = 'Enforce custom provider rate',
  enforce_custom_client_rate = 'Enforce custom client rate',
  enforce_custom_provider_client_rate = 'Enforce custom provider client rate'
}

export enum CALCULATION_TYPE {
  billtime_paytime = 'Bill by time / Pay by time',
  billunits_paytime = 'Bill by units / Pay by time',
  billunits_payunits = 'Bill by units / Pay by units'
}

export enum ROUND_TYPE {
  at_halfway = 'Round at halfway (default)',
  past_halfway = 'Round past halfway '
}

export enum PAYABLE_TYPE {
  payable = 'Payable',
  non_payable = 'Non payable'
}

export enum VALIDATION_TYPE {
  not_needed = 'Not needed',
  optional = 'Optional',
  required = 'Required'
}

export enum VALIDATION_OPTIONAL_TYPE {
  not_needed = 'Not needed',
  optional = 'Optional'
}

export enum SIGNATURE_FORM {
  account = 'Account',
  provider_account = 'Provider account',
  either = 'Either'
}

export enum AUTHORIZATION {
  optional = 'Optional',
  required = 'Required'
}

export enum PERIOD_TYPE {
  month = 'Month',
  week = 'Week',
  '2week' = '2 week',
  half_year = 'Half year',
  year = 'Year'
}

export enum STATUS_VARIANT {
  pending = 'warning',
  active = 'success',
  converted = 'success',
  conflicted = 'warning',
  notConverted = 'warning',
  cancelled = 'default'
}

export enum STATUS_LABEL_BY_CODE {
  pending = 'Pending',
  active = 'Active',
  converted = 'Converted',
  conflicted = 'Conflicted',
  notConverted = 'Not converted',
  cancelled = 'Cancelled'
}

export enum NOTIFICATION_MESSAGE_GROUP {
  account = 'Account',
  appointment = 'Appointment',
  time_card = 'Time Card',
  reminder = 'Reminder'
}

export enum CLIENT_STATUS_VARIANTS {
  active = 'active',
  terminated = 'error'
}
export enum NEWS_VISIBILITY {
  all = 'All',
  employee = 'Employee',
  therapist = 'Therapist',
  management = 'Management',
  administrator = 'Administrator'
}
