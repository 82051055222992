import { DataTableColumn } from '../../components/table-view/TableView';
import { format } from 'date-fns';
import { FULL_DATE_FORMAT } from '../../constants/app.constants';

export const rolesColumns: DataTableColumn[] = [
  {
    title: 'Role Name',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Update Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sortable: true,
    render: (date) => {
      return date ? format(new Date(date), FULL_DATE_FORMAT) : '-';
    }
  }
];

export const rolesData: any[] = [
  {
    uuid: 1,
    name: 'Therapist',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 2,
    name: 'Scheduling',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 3,
    name: 'Human Resources',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 4,
    name: 'App Administrator',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 5,
    name: 'Lead Admin with Billing Rate',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 6,
    name: 'Directors',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 7,
    name: 'Lead Admin',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 8,
    name: 'Billing',
    updatedAt: new Date().toISOString()
  },
  {
    uuid: 9,
    name: 'Self Manage Schedule',
    updatedAt: new Date().toISOString()
  }
];

export const roleData = {
  uuid: 1,
  name: 'Therapist',
  updatedAt: new Date().toISOString(),
  permissions: {
    viewAppointments: false,
    modifyAppointments: false,
    viewTimesheets: false,
    confirmTimesheets: false,
    unconfirmTimesheets: false,
    viewClientDashboard: false,
    viewEmployeeDashboard: false,
    viewBilledVsPlannedDashboard: false,
    viewDashboard: false,
    viewLeadershipDashboard: false,
    viewCaseloadManagementDashboard: false,
    viewMyReminders: false,
    modifyMyReminders: false,
    viewOtherEmployeeReminders: false,
    modifyOtherEmployeeReminders: false,
    viewAnnouncements: false,
    modifyAnnouncements: false,
    viewNews: false,
    modifyNews: false,
    viewMasterData: false,
    modifyMasterData: false,
    viewClients: false,
    modifyClients: false,
    viewEmployees: false,
    modifyEmployees: false,
    viewServiceAuthorizations: false,
    modifyServiceAuthorizations: false,
    viewUploadNewFiles: false,
    modifyUploadNewFiles: false,
    viewServiceAuthorizationReport: false,
    viewBillingReport: false,
    viewTimeCardReport: false,
    viewCancellationReport: false,
    viewCaseLoadTrackerReport: false,
    viewServiceCodes: false,
    modifyServiceCodes: false,
    viewFindingSource: false,
    modifyFindingSource: false,
    viewCreateRoleAndPermissions: false,
    modifyCreateRoleAndPermissions: false,
    modifyAssignRoleAndPermissions: false
  }
};
