import { get } from 'lodash';
import { useNavigate } from 'react-router';
import { Card } from '../../../components/cards';
import { TableView } from '../../../components/table-view';
import { ROUTE_CLIENT_DETAILS } from '../../../constants/route.paths';
import { url } from '../../../core';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientApi } from '../../../core/http';
import { clientsColumns } from '../_mockdata';

const clientApi = new ClientApi();

export const ClientListWidget = ({ employeeUuid }) => {
  const navigate = useNavigate();
  const { data, isLoading, handleChangeSort } = useListResource(clientApi, {
    defaultParams: { rootType: 'employee', rootGuuid: employeeUuid }
  });
  return (
    <Card title="Client List">
      <TableView
        loading={isLoading}
        showPagination={!isLoading}
        showTablePerPage={false}
        dataSource={get(data, 'items') || []}
        columns={clientsColumns}
        onChangeSort={handleChangeSort}
        showEmptyState
        onRowClick={({ uuid }) =>
          navigate(
            url(ROUTE_CLIENT_DETAILS, {
              id: uuid,
              tab: 'dashboard'
            })
          )
        }
        EmptyStateProps={{
          title: 'You dont have any clients yet.',
          description: null
        }}
      />
    </Card>
  );
};
