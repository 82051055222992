import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ComponentType, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { FileAttach, TextField, TimePicker } from '../../../components/fields';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { Box, Divider, FormHelperText, Grid, Typography } from '@mui/material';
import { Card } from '../../../components/cards';
import { SignaturePad } from '../../../components/signature-pad';
import { useEditResource } from '../../../core/hooks/useEditResource';
import {
  AppointmentApi,
  AppointmentDto,
  FileApi,
  TimesheetApi
} from '../../../core/http';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import ReactResizeDetector from 'react-resize-detector';
import SignatureCanvas from 'react-signature-canvas';
import { v4 as uuidv4 } from 'uuid';
import { addHours, differenceInMinutes, format } from 'date-fns';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

interface Props extends DialogProps {
  uuid: string;
  appointment?: AppointmentDto;
  onClose?: () => void;
  title?: string;
  onSuccess?: () => void;
}
const appointmentApi = new AppointmentApi();
const timesheetApi = new TimesheetApi();
const fileApi = new FileApi();

export const ConvertToTimesheetDialog: ComponentType<Props> = ({
  popDialog,
  uuid,
  appointment,
  title = 'Convert to timesheet',
  onSuccess
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const refClientSign = useRef<SignatureCanvas>();
  const refProviderSign = useRef<SignatureCanvas>();

  const { isEdit, handleSubmit, values, setFieldValue, getFieldTouchedError } =
    useEditResource(timesheetApi, {
      uuid,
      initialValues: {
        payerUuid: '',
        serviceCode: '',
        startDateOfService: '',
        endDateOfService: '',
        unitOfService: '',
        hours: '',
        serviceAuthorizationFileUuid: '',
        clientSignatureFilled: false,
        providerSignatureFilled: false
      },
      validationSchema: yup.object().shape({
        payerUuid: yup.string().required('Please select one of the options.'),
        startDateOfService: yup
          .string()
          .required('Please enter worked time.')
          .typeError('Invalid date'),
        endDateOfService: yup
          .string()
          .required('Please enter worked time.')
          .typeError('Invalid date'),
        unitOfService: yup
          .number()
          .required('Please enter unit of services.')
          .typeError('Units must contain only numbers.'),
        serviceAuthorizationFileUuid: yup.string(),
        // .required('Please upload a file.'),
        // clientSignatureFilled: yup
        //   .boolean()
        //   .test('is boolean', 'Please sign.', (value) => value === true)
        //   .required('Please sign.'),
        providerSignatureFilled: yup
          .boolean()
          .test('is boolean', 'Please sign.', (value) => value === true)
          .required('Please sign.')
      }),
      normalizeSetValues: (values) => {
        const serviceDate = new Date(values.dateOfService);
        return {
          ...values,
          dateOfService: serviceDate
        };
      },
      onSubmit: async (values) => {
        const clientFile = dataURLtoBlob(
          refClientSign.current.getCanvas().toDataURL('image/png')
        );
        const providerFile = dataURLtoBlob(
          refProviderSign.current.getCanvas().toDataURL('image/png')
        );

        const clientSignatureFileUuid = uuidv4();
        const providerSignatureFileUuid = uuidv4();
        const authorizations = get(appointment, 'authorizations');
        setIsLoading(true);

        Promise.all([
          fileApi.create({
            guid: clientSignatureFileUuid,
            file: clientFile,
            fileName: 'client-signature',
            description: '',
            expirationDate: format(new Date(), 'yyyy-MM-dd'),
            noExpirationDate: true,
            type: 'licence',
            rootId: appointment.client.uuid,
            rootType: 'client'
          }),
          fileApi.create({
            guid: providerSignatureFileUuid,
            file: providerFile,
            fileName: 'employee-signature',
            description: '',
            expirationDate: format(new Date(), 'yyyy-MM-dd'),
            noExpirationDate: true,
            type: 'licence',
            rootId: appointment.primaryTherapist.uuid,
            rootType: 'employee'
          })
        ]).then(() => {
          Promise.all(
            authorizations.map((authorization) => {
              return timesheetApi
                .create({
                  ...values,
                  uuid: uuidv4(),
                  startDateOfService: format(
                    values.startDateOfService,
                    'yyyy-MM-dd HH:mm:ss'
                  ),
                  endDateOfService: format(
                    values.endDateOfService,
                    'yyyy-MM-dd HH:mm:ss'
                  ),
                  clientSignatureFileUuid,
                  providerSignatureFileUuid,
                  authorizationUuid: authorization?.uuid
                })
                .catch((e) => {
                  popDialog();
                  showMessage(e.response.data.errorMessage, 'error');
                });
            })
          )
            .then(() => {
              appointmentApi
                .convert(appointment.uuid)
                .then(() => {
                  showMessage('Timesheet created.');
                  popDialog();
                  onSuccess && onSuccess();
                })
                .catch((e) => {
                  popDialog();
                  showMessage(e.response.data.errorMessage, 'error');
                });
            })
            .catch(() => {
              popDialog();
              showMessage('e.errorMessage', 'error');
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
      },
      onSuccess: () => {
        showMessage(`Timesheet ${isEdit ? 'updated' : 'created'} successfully`);
      },
      onError: (e) => {
        showMessage(e.errorMessage, 'error');
      }
    });

  useEffect(() => {
    if (!isEmpty(appointment)) {
      const providerName = `${appointment?.primaryTherapist?.firstName} ${appointment?.primaryTherapist?.lastName}`;
      const clientName = `${appointment?.client?.firstName} ${appointment?.client?.lastName}`;
      const payerName = get(
        get(appointment, 'client.contacts').find((x) => x.type === 'insured'),
        'payer.name',
        clientName
      );
      setFieldValue('payerName', payerName);
      setFieldValue('payerUuid', get(appointment, 'client.uuid', ''));
      setFieldValue('providerName', providerName);
      setFieldValue('clientName', clientName);
      const dateOfService = format(
        new Date(appointment?.startDate),
        'MM/dd/yyyy'
      );
      setFieldValue('dateOfService', dateOfService);

      const startDate = new Date(appointment.startDate.slice(0, 10));
      setFieldValue('startDateOfService', startDate);
      setFieldValue('endDateOfService', addHours(startDate, 1));
      const authorization = get(appointment, 'authorizations.0');
      if (authorization) {
        const serviceCode = get(authorization, 'serviceCode.code');
        const serviceDescription = get(
          authorization,
          'serviceCode.description'
        );

        setFieldValue('serviceName', `${serviceCode} - ${serviceDescription}`);
      }
    }
  }, [appointment]);

  useEffect(() => {
    if (values.startDateOfService && values.endDateOfService) {
      const diffInMinutes = differenceInMinutes(
        values.endDateOfService,
        values.startDateOfService
      );
      setFieldValue(
        'hours',
        diffInMinutes > 0 ? (diffInMinutes / 60).toFixed(1) : 0
      );

      if (diffInMinutes < 0) {
        setFieldValue('endDateOfService', values.startDateOfService);
      }
    }
  }, [values.startDateOfService, values.endDateOfService]);

  const authorizations = get(appointment, 'authorizations');
  const authorizationsIsEmpty = isEmpty(authorizations);

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': authorizationsIsEmpty
          ? {}
          : {
              maxWidth: 1180,
              maxHeight: 840
            },
        '& .MuiDialogActions-root': {
          border: 'none'
        },
        '& .MuiOutlinedInput-root': {
          '& .Mui-disabled': {
            color: '#172B4D',
            WebkitTextFillColor: '#172B4D'
          }
        }
      }}
    >
      {authorizationsIsEmpty ? (
        <>
          <DialogContent
            dividers={false}
            sx={{
              minHeight: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h6">
              Sorry, there is no authorization for this appointment.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="third" onClick={popDialog}>
              Cancel
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent dividers={false}>
            <Card title={`Service – ${values.serviceName}`}>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <TextField disabled label="Payer" value={values.payerName} />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <TextField
                    disabled
                    label="Provider"
                    value={values.providerName}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    disabled
                    label="Date of Service"
                    value={values.dateOfService}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    disabled
                    label="Service Code"
                    value={values.serviceName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item md={2}>
                  <TimePicker
                    required
                    label="Time Worked"
                    value={values.startDateOfService}
                    onChange={(value) =>
                      setFieldValue('startDateOfService', value)
                    }
                    errors={getFieldTouchedError('startDateOfService')}
                  />
                </Grid>
                <Grid item md={2} display="flex" alignItems="flex-end">
                  <TimePicker
                    label=" "
                    value={values.endDateOfService}
                    onChange={(value) =>
                      setFieldValue('endDateOfService', value)
                    }
                    errors={getFieldTouchedError('endDateOfService')}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    required
                    label="Units of Service"
                    onChange={(value) => setFieldValue('unitOfService', value)}
                    errors={getFieldTouchedError('unitOfService')}
                    value={values.unitOfService}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    required
                    disabled
                    label="Hours"
                    value={values.hours}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container columnSpacing={1} mt={2} rowSpacing={0}>
                <Grid item md={4}>
                  <TextField
                    disabled
                    label="Client Signature"
                    value={values.clientName}
                  />
                  <Box mb={2}>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => {
                        refClientSign.current && refClientSign.current.clear();
                      }}
                    >
                      Update Signature
                    </Button>
                  </Box>
                  <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) => (
                      <>
                        <Card height={128} p={0}>
                          <SignaturePad
                            penColor="black"
                            {...{ width, height, ref: refClientSign }}
                            onEnd={() => {
                              setFieldValue('clientSignatureFilled', true);
                            }}
                          />
                          <FormHelperText>
                            {getFieldTouchedError('clientSignatureFilled')}
                          </FormHelperText>
                        </Card>
                      </>
                    )}
                  </ReactResizeDetector>
                </Grid>
                <Grid item md={4} ml={4}>
                  <TextField
                    disabled
                    required
                    label="Provider Signature"
                    value={values.providerName}
                  />
                  <Box mb={2}>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setFieldValue('providerSignatureFilled', false);
                        refProviderSign.current &&
                          refProviderSign.current.clear();
                      }}
                    >
                      Update Signature
                    </Button>
                  </Box>
                  <ReactResizeDetector handleWidth handleHeight>
                    {({ width, height }) => (
                      <Card height={128} p={0}>
                        <SignaturePad
                          penColor="black"
                          {...{ width, height, ref: refProviderSign }}
                          onEnd={() => {
                            setFieldValue('providerSignatureFilled', true);
                          }}
                        />
                        <FormHelperText>
                          {getFieldTouchedError('providerSignatureFilled')}
                        </FormHelperText>
                      </Card>
                    )}
                  </ReactResizeDetector>
                </Grid>
              </Grid>
              <Divider />
              <FileAttach
                canRemove
                sx={{ mt: 2, background: '#F9FBFE !important' }}
                createPayload={{
                  description: '',
                  expirationDate: format(new Date(), 'yyyy-MM-dd'),
                  noExpirationDate: true,
                  type: 'licence',
                  rootId: appointment.primaryTherapist.uuid,
                  rootType: 'employee'
                }}
                onChange={(guid) => {
                  setFieldValue('serviceAuthorizationFileUuid', guid);
                }}
              />
            </Card>
          </DialogContent>
          <DialogActions>
            <Button color="third" onClick={popDialog} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} disabled={isLoading}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
