import { Expose } from 'class-transformer';

export class EmployeeAbsenceScheduleDto {
  @Expose()
  employeeAvatarUrl: string;

  @Expose()
  employeeName: string;

  @Expose()
  employeeUuid: string;

  @Expose()
  leaveDays: any[];
}
