import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchEmployee } from '../store/slices/employeeSlice';

export const useEmployee = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.employee);

  useEffect(() => {
    dispatch(fetchEmployee({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', []),
    itemsLoading: get(findAll, 'loading', false)
  };
};
