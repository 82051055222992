import { EditClientReminder } from '../../../features/reminders/EditClientReminder';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const CreateClientReminderPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Reminders'
  })
)(EditClientReminder);
