import { ComponentType, ReactNode } from 'react';
import useStyles from './styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider } from '@mui/material';

interface Props {
  title: ReactNode;
  meta?: ReactNode;
  divider?: boolean;
}

export const PageHeader: ComponentType<Props> = ({
  title,
  divider = true,
  meta,
  children
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">{title}</Typography>
          {meta && (
            <Stack direction="row" spacing={1} className={classes.meta}>
              {meta}
            </Stack>
          )}
          <Stack direction="row" spacing={1} className={classes.action}>
            {children}
          </Stack>
        </Stack>
      </Box>
      {divider && <Divider sx={{ mx: -3, mb: 3 }} />}
    </>
  );
};
