import { Components } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './typography';

export const themeComponents = (): Components => {
  const theme = createTheme();

  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: '100vh',
          height: '100%'
        },
        body: {
          minHeight: '100vh',
          height: '100%',
          margin: 0
        },
        '#root': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%'
        }
      }
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 10,
          '&:last-child': {
            paddingBottom: 10
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small'
      },
      styleOverrides: {
        root: {
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,

          '&.MuiButton-containedThird': {
            border: '1px solid #ECF0F7',
            color: '#496492',

            '&:hover': {
              borderColor: '#85B6FF',
              color: '#0052CC'
            },
            '&:active': {
              borderColor: '#E0EDFF',
              backgroundColor: '#E0EDFF',
              color: '#0052CC'
            }
          }
        },
        sizeSmall: {
          height: 32,
          fontSize: 12
        },
        sizeMedium: {
          fontSize: 14,
          height: 40
        }
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ///tooltip styles
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 33
        },
        indicator: {
          height: 2,
          backgroundColor: '#0052CC',
          borderRadius: 2,
          padding: 0,
          zIndex: 10
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '16px',
          color: '#6C7294',
          padding: theme.spacing(1, 0),
          marginRight: theme.spacing(2),
          minHeight: 33,
          minWidth: 'auto',
          '&.Mui-selected': {
            color: '#0052CC'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: 'none',
          border: '1px solid #E7EAF9',
          borderRadius: 8,

          '& .MuiCardContent-root': {
            padding: theme.spacing(4)
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#CACDE2'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root:hover': {
            '& Path': {
              fill: '#0052CC'
            }
          },
          borderRadius: 4
        },
        sizeSmall: {
          padding: 0
        },
        sizeMedium: {
          padding: 4,
          borderRadius: '50%'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ...typography.subtitle1,
          backgroundColor: '#F9FBFE',
          '& .MuiTableCell-root': {
            color: '#496492',
            // textTransform: 'capitalize',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: 'none',
            padding: theme.spacing(1)
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            '& .MuiTypography-root': {
              ...typography.body3
            },
            ...typography.body3,
            lineHeight: '24px',
            padding: theme.spacing(1.25, 1)
          },
          '& .MuiTableRow-root:hover': {
            backgroundColor: '#F9FBFE'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'common.dark',
          borderColor: '#ECF0F7'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ' &:hover': {
            cursor: 'pointer'
          }
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#B8BDD9'
        }
      }
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#ECF0F7'
        },

        vertical: {
          borderColor: 'rgba(255, 255, 255, 0.5) !important',
          height: 16
        }
      }
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
          padding: theme.spacing(0.5),
          backgroundColor: '#F6F9FF',
          borderRadius: 4
        }
      }
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          margin: 0,
          marginRight: 2,
          color: '#172B4D',
          '&.Mui-selected, &:hover, &.Mui-selected:hover': {
            backgroundColor: '#fff',
            color: '#0052CC',
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)'
          }
        }
      }
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        helperText: ' ',
        InputLabelProps: {
          shrink: true
        },
        SelectProps: {
          native: true
        }
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            position: 'static',
            marginBottom: 4,
            transform: 'none',
            width: '100%',
            maxWidth: '100%',
            color: '#496492',
            ...typography.body2,
            fontWeight: 500,

            '&.Mui-focused': {
              color: '#6C7294'
            }
          },
          '& .MuiInputBase-input': {
            ...typography.subtitle2,
            '&::-ms-reveal': {
              display: 'none'
            }
          },
          '& .MuiOutlinedInput-input': {
            ...typography.body3
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            color: '#172B4D'
          },

          '& .Mui-error.MuiInputBase-root': {
            backgroundColor: '#FFE6E6'
          },

          '& .Mui-error.MuiInputLabel-root': {
            color: '#496492'
          },

          '& .Mui-disabled.MuiInputLabel-root': {
            color: '#496492'
          },
          '& .Mui-disabled.MuiOutlinedInput-root': {
            backgroundColor: '#FAFBFC'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#EB5757',
          fontSize: 10,
          marginLeft: 0,
          marginRight: 0,
          minHeight: 25
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false
      },
      styleOverrides: {
        root: {
          backgroundColor: '#F1F5FB'
        },
        input: {
          padding: 8,
          height: 24,
          '&::placeholder': {
            color: '#6C7294'
          }
        },
        multiline: {
          padding: 8,
          '& .MuiInputBase-inputMultiline': {
            padding: 0
          }
        },
        inputSizeSmall: {
          height: 16
        },
        notchedOutline: {
          border: 'none'
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0
          },

          '& .MuiInputBase-sizeSmall': {
            height: 32,
            padding: '2px 6px!important',

            '& .MuiChip-root': {
              height: 24,
              fontSize: 12,
              '& .MuiChip-label': {
                paddingLeft: 12
              },
              '& .MuiChip-deleteIcon': {
                color: 'rgba(255,255,255,0.2)',
                fontSize: 20
              }
            },

            '& .MuiAutocomplete-clearIndicator': {
              padding: 2,
              visibility: 'visible'
            }
          }
        },
        input: {
          padding: 0
        },
        paper: {
          border: 0,
          boxShadow: '0px 4px 8px rgba(73, 100, 146, 0.24)',
          borderRadius: 4,
          fontSize: 14
        },
        listbox: {
          margin: theme.spacing(1)
        },
        option: {
          fontSize: 14,
          height: 32,

          '&.Mui-focused': {
            backgroundColor: '#F9FBFE!important',
            borderRadius: 2
          }
        }
      }
    },

    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm'
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(9, 30, 66, 0.54)'
          }
        },
        paper: {
          borderRadius: 4
        },
        paperWidthSm: {
          maxWidth: 480
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 20,
          fontFamily: 'Poppins',
          fontWeight: 600,
          lineHeight: 1.4,
          padding: theme.spacing(3)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...typography.body2,
          padding: theme.spacing(0, 3),

          '& .MuiFormHelperText-root': {
            marginTop: 0,
            minHeight: 9
          }
        }
      }
    },
    MuiDialogActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
          borderTop: '1px solid #ECF0F7',
          margin: theme.spacing(0, 3, 3, 3),
          padding: theme.spacing(3, 0, 0, 0)
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {},

        paper: {
          maxHeight: '160px !important',
          border: 'none',
          borderRadius: 4,
          boxShadow: '0px 4px 8px rgba(73, 100, 146, 0.24)',

          '& .MuiList-root': {
            padding: theme.spacing(1.5, 1)
          },
          '& .Mui-selected': {
            borderRadius: 2,
            backgroundColor: '#F9FBFE',
            color: '#0052CC'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          '& .MuiChip-deleteIcon': {
            color: 'rgba(255,255,255,0.2)',
            fontSize: 20
          }
        },
        sizeSmall: {
          height: 28,
          fontSize: 12,
          '& .MuiChip-label': {
            paddingLeft: 12
          }
        }
      }
    }
  };
};
