import { DataTableColumn } from '../../components/table-view/TableView';
import { UserRecord } from '../../components/user-record';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../constants/app.constants';
import { normalizeDate } from '../../core';

export const forEmployeesColumn: DataTableColumn[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    sortable: true,
    render: (date) => normalizeDate(date)
  },
  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (user: any) => (
      <UserRecord
        user={{
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.avatar
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Task Type',
    dataIndex: 'taskType.title',
    key: 'taskType',
    sortable: true
  }
];

export const forClientsColumn: DataTableColumn[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    sortable: true,
    render: (date) => (date ? format(new Date(date), DATE_FORMAT) : '')
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  }
];

export const forEmployeesData = [
  {
    id: 267400,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    taskType: 'Training'
  },
  {
    id: 267401,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/13.jpg',
      name: 'Annette Black'
    },
    taskType: 'Training'
  },
  {
    id: 267402,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Leslie Alexander'
    },
    taskType: 'Report Whiting'
  },
  {
    id: 267403,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Albert Flores'
    },
    taskType: 'Training'
  },
  {
    id: 267404,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Theresa Webb'
    },
    taskType: 'Training'
  },
  {
    id: 267405,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      name: 'Annette Black'
    },
    taskType: 'Training'
  },
  {
    id: 267406,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      name: 'Leslie Alexander'
    },
    taskType: 'Report Whiting'
  },
  {
    id: 267407,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'Albert Flores'
    },
    taskType: 'Training'
  },
  {
    id: 267408,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
      name: 'Dianne Russell'
    },
    taskType: 'Training'
  },
  {
    id: 267409,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
      name: 'Ronald Richards'
    },
    taskType: 'Training'
  },
  {
    id: 267410,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
      name: 'Leslie Alexander'
    },
    taskType: 'Report Whiting'
  },
  {
    id: 267411,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
      name: 'Guy Hawkins'
    },
    taskType: 'Training'
  }
];

export const forClientsData = [
  {
    id: 267400,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Albert',
    lastName: 'Flores'
  },
  {
    id: 267401,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Kathryn',
    lastName: 'Murphy'
  },
  {
    id: 267402,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Arlene',
    lastName: 'McCoy'
  },
  {
    id: 267403,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Jerome',
    lastName: 'Bell'
  },
  {
    id: 267404,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Albert',
    lastName: 'Flores'
  },
  {
    id: 267405,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Kathryn',
    lastName: 'Murphy'
  },
  {
    id: 267406,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Arlene',
    lastName: 'McCoy'
  },
  {
    id: 267407,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Jerome',
    lastName: 'Bell'
  },
  {
    id: 267408,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Albert',
    lastName: 'Flores'
  },
  {
    id: 267409,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Kathryn',
    lastName: 'Murphy'
  },
  {
    id: 267410,
    title: 'Convert all appointments',
    dueDate: '01/28/2022',
    therapist: 'Arlene',
    lastName: 'McCoy'
  },
  {
    id: 267411,
    title: 'Manage all appointments',
    dueDate: '01/28/2022',
    therapist: 'Jerome',
    lastName: 'Bell'
  }
];
