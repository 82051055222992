import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IInviteValues {
  email: string;
  password: string;
  companyCode: string;
}

interface IInviteThunkArguments {
  values: IInviteValues;
  options?: Partial<IRequestOptions>;
}

export const invite = createAsyncThunk(
  'inviteAction',
  async ({ options }: IInviteThunkArguments) => {
    try {
      const data: any = await delay(2000, {
        success: true
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const inviteSlice = createSlice({
  name: 'inviteSlice',
  initialState: {
    invite: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(invite.pending, (state) => {
      state.invite.loading = true;
      state.invite.data = {};
      state.invite.error = null;
    });
    builder.addCase(invite.fulfilled, (state, { payload }) => {
      state.invite.loading = false;
      state.invite.data = payload;
      state.invite.error = null;
    });
    builder.addCase(invite.rejected, (state) => {
      state.invite.loading = false;
      state.invite.data = {};
      state.invite.error = 'Something went wrong';
    });
  }
});

export default inviteSlice.reducer;
