import { PageHeader } from '../../../components/page-header';
import { useLayout } from '../../../core/hooks/useLayout';
import {
  ROUTE_MASTER_DATA,
  ROUTE_MASTER_DATA_CREATE_FUNDING_SOURCE,
  ROUTE_MASTER_DATA_EDIT_FUNDING_SOURCE
} from '../../../constants/route.paths';
import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { fundingSourceColumns } from './_mockdata';
import { IconPen, IconTrash } from '../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../core';
import { useDialog } from '../../../core/hooks/useDialog';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { Card } from '../../../components/cards';
import { useListResource } from '../../../core/hooks/useListResource';
import { FundingSourceApi } from '../../../core/http';
import { ServiceCodeField } from '../../../components/fields';
import { useFilter } from '../../../core/hooks/useFilter';
import get from 'lodash/get';

const fundingSourceApi = new FundingSourceApi();

export const FundingSourceContainer = () => {
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  useLayout({
    backButton: {
      title: 'Master Data',
      path: ROUTE_MASTER_DATA
    }
  });

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams
  } = useListResource(fundingSourceApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  return (
    <>
      <PageHeader title="Funding Sources">
        <Button
          onClick={() => navigate(ROUTE_MASTER_DATA_CREATE_FUNDING_SOURCE)}
        >
          Add new
        </Button>
      </PageHeader>
      <Card mb={3}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <TextField
              label="Vendor Name"
              placeholder="Search"
              size="small"
              helperText={null}
              value={getFilterValue('vendorName')}
              onChange={setFilterValue('vendorName')}
            />
          </Grid>
          <Grid item md={6}>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <ServiceCodeField
                label="Service Code"
                size="small"
                helperText={null}
                value={getFilterValue('serviceCode')}
                onChange={setFilterValue('serviceCode')}
              />
              <Button onClick={handleSearch}>Search</Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...fundingSourceColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <IconButton
                  size="small"
                  onClick={() =>
                    navigate(
                      url(ROUTE_MASTER_DATA_EDIT_FUNDING_SOURCE, {
                        uuid
                      })
                    )
                  }
                >
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
