import { FC } from 'react';
import { Box } from '@mui/system';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import useStyles from './styles';
import { SuperAdminSidebar } from '../super-admin-sidebar';
import { Header } from '../header';

export const SuperAdminLayout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <SuperAdminSidebar />
      <Box className={classes.main}>
        <Box className={classes.header}>
          <Header />
        </Box>
        <SimpleBar className={classes.content}>{children}</SimpleBar>
      </Box>
    </Box>
  );
};
