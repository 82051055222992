import { ReminderListContainer } from '../../features/reminders/ReminderListContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const RemindersPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Reminders'
  })
)(ReminderListContainer);
