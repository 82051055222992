import { Expose, Transform } from 'class-transformer';
import { get } from 'lodash';

export class EmployeeContactDto {
  @Expose()
  uuid: string;

  @Expose()
  employeeUuid: string;

  @Expose()
  addressLine: string;

  @Expose()
  addressLineSecond: string;

  @Expose()
  city: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'city.uuid'))
  cityUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'state.uuid'))
  stateUuid: string;

  @Expose()
  zipCode: string;

  @Expose()
  phoneNumberPrimary: string;

  @Expose()
  phoneNumberSecondary: string;

  @Expose()
  phoneNumberWork: string;

  @Expose()
  notifications: boolean;
}
