import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { useProfitCenter } from '../../../core/hooks/useProfitCenter';
import { ProfitCenterDto } from '../../../core/http';

interface Props extends Omit<SelectFieldProps, 'items'> {
  labelExtractor?: (item: ProfitCenterDto) => string;
}

export const ProfitCenterField: FC<Props> = ({
  labelExtractor,
  value,
  ...props
}) => {
  const { items } = useProfitCenter();

  const selectItems = map(items, (item: ProfitCenterDto) => ({
    value: item.uuid,
    label: labelExtractor ? labelExtractor(item) : item.profitCentre
  }));

  return (
    <SelectField
      label="Profit Center"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
