import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { createFetchAsync } from '../slice.utils';
import { UserApi } from '../../http/requests/user.api';
import { UserDto } from '../../http';

const userApi = new UserApi();

export const fetchUserMeAsync = createFetchAsync(
  'user',
  'me',
  async (params, { rejectWithValue }) => {
    try {
      return await userApi.me();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchUserMe = fetchUserMeAsync.asyncThunk;

export const fetchUserMe = () => (dispatch, getState) => {
  const {
    user: { me }
  } = getState();

  if (!me.payload && me.loading == false) {
    dispatch(forceFetchUserMe());
  }
};

type UserState = {
  me: AsyncState<UserDto>;
};

const initialState: UserState = {
  me: fetchUserMeAsync.initialState
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchUserMeAsync.extraReducers
  }
});

export default user.reducer;
