import { useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import { EmployeeAbsenceScheduleApi } from '../http';
import useDeepCompareEffect from 'use-deep-compare-effect';

const employeeAbsenceScheduleApi = new EmployeeAbsenceScheduleApi();

export const useEmployeeAvailabilitySchedule = (
  options = {
    defaultParams: {}
  }
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<any>();
  const [params, setParams] = useState({
    ...options.defaultParams
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    employeeAbsenceScheduleApi
      .findAll(options.defaultParams)
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setErrors(e.errorMessage);
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, []);

  useDeepCompareEffect(() => {
    fetchData();
  }, [params]);

  const handleChangeParams = (filter: any) => {
    setParams((prevState) => ({ ...prevState, ...filter }));
  };

  return {
    items: get(data, 'items', []),
    handleChangeParams,
    isLoading,
    errors
  };
};
