import React from 'react';
import { BaseLayout } from '../../layouts/base-layout';

// eslint-disable-next-line react/display-name
export const withBaseLayout = (ComposedComponent) => (props) =>
  (
    <BaseLayout>
      <ComposedComponent {...props} />
    </BaseLayout>
  );
