import { Expose } from 'class-transformer';

export class ServiceAuthorizationReportDto {
  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  serviceCode: string;

  @Expose()
  serviceDescription: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  fundingSource: string;

  @Expose()
  unitLength: number;

  @Expose()
  maxHours: number;

  @Expose()
  workHours: number;

  @Expose()
  leftHours: number;
}
