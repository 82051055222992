import { Profile } from '../../features/profile/Profile';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const ProfilePage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Profile'
  })
)(Profile);
