import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminAdministratorProfileContainer } from '../../features/super-admin-administrators/SuperAdminAdministratorProfile';

export const SuperAdminAdministratorProfile = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Administrators'
  })
)(SuperAdminAdministratorProfileContainer);
