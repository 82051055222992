export function bytesToSize(bytes: number, precision = 2): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Bytes';
  }
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1000)).toString(),
    10
  );
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / 1000 ** i).toFixed(precision)} ${sizes[i]}`;
}

export const numericStringMask = (str, mask) => {
  if (!mask) return str;

  const numeric = str.replaceAll(/[^\d]/g, '');

  let idx = 0;

  const formated = mask.split('').map((el) => {
    if (el === '#') {
      el = numeric[idx];
      idx++;
    }
    return el;
  });

  return formated.join('');
};

export const usPhoneNumber = (str) => {
  if (!str) return '';

  return numericStringMask(str, '(###) ###-####');
};
