import { ComponentType } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { url } from '../../core';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import {
  DatePicker,
  SelectField,
  TextField,
  CheckboxList,
  PhoneField
} from '../../components/fields';
import { ROUTE_SUPER_ADMIN_COMPANIES } from '../../constants/route.paths';
import { useLayout } from '../../core/hooks/useLayout';

export const SuperAdminCompanyEditContainer: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const headerProps = {
    divider: true,
    title: (
      <Typography variant="h2">
        {id ? 'Edit' : 'Add new'} company profile
      </Typography>
    )
  };

  const backUrl = url(ROUTE_SUPER_ADMIN_COMPANIES);

  useLayout({
    backButton: {
      title: 'Companies',
      path: backUrl
    }
  });

  const fieldsOfPracticeList = [
    {
      label: 'ABA',
      value: 'aba'
    },
    {
      label: 'Early',
      value: 'early'
    },
    {
      label: 'ST',
      value: 'st'
    },
    {
      label: 'OT',
      value: 'ot'
    },
    {
      label: 'PT',
      value: 'pt'
    },
    {
      label: 'Others',
      value: 'others'
    }
  ];

  return (
    <>
      <PageHeader title="" {...headerProps}>
        <Button
          onClick={() => navigate(ROUTE_SUPER_ADMIN_COMPANIES)}
          color="third"
        >
          Cancel
        </Button>
        <Button>Save</Button>
      </PageHeader>
      <Card title="General Information">
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <DatePicker
              label="Date of Signing the Contract"
              value={new Date()}
              required
            />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={6}>
            <SelectField
              label="Administrator"
              required
              items={[
                { label: 'Debra Lane (debra.lane@gmail.com)', value: '123' },
                {
                  label: 'Franky Kolbasa (franky.kolbasa@gmail.com)',
                  value: '213'
                }
              ]}
              placeholder="Select"
            />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <TextField required label="Company name:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Legal Company Name:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Current plan:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Max Employees:" />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={6}>
            <CheckboxList
              value={[]}
              onChange={(value) => console.log(value)}
              items={fieldsOfPracticeList}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Contact Information">
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <DatePicker
              label="Date of Signing the Contract"
              value={new Date()}
              required
            />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={6}>
            <TextField required label="Address Line 1:" />
          </Grid>
          <Grid item md={6}>
            <TextField required label="Address Line 2:" />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <TextField required label="City:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="State:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Zip Code:" />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <PhoneField label="Primary Phone Number:" required />
          </Grid>
          <Grid item md={3}>
            <PhoneField label="Secondary Phone Number:" required />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
