import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegion } from '../store/slices/regionSlice';
import { AppState } from '../store/store';
import get from 'lodash/get';

export const useRegion = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.region);

  useEffect(() => {
    dispatch(fetchRegion({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
