import { ReferralTypeContainer } from '../../features/master-data/referral-type/ReferralTypeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataReferralType = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Referral Types'
  })
)(ReferralTypeContainer);
