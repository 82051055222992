import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { FileAttach, TextField } from '../../../components/fields';
import * as yup from 'yup';
import { FileApi } from '../../../core/http';
import { useEditResource } from '../../../core/hooks/useEditResource';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';

const fileApi = new FileApi();

export const UploadNewFileDialog = ({
  popDialog,
  clientUuid,
  onSuccess,
  onError
}) => {
  const [createUuid] = useState(uuidv4());
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    getFieldTouchedError,
    isLoading
  } = useEditResource(fileApi, {
    createUuid,
    uuid: null,
    initialValues: {
      guid: uuidv4(),
      type: 'certificate',
      expirationDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      noExpirationDate: false,
      description: '',
      fileName: '',
      rootType: 'client',
      rootId: clientUuid
    },
    normalizeValues: (values) => {
      return { ...values, guid: uuidv4() };
    },
    validationSchema: yup.object().shape({
      fileName: yup.string().required('File name is a required field'),
      type: yup.string().required('File type is a required field'),
      guid: yup.string().required('File is a required field')
    }),
    onSuccess: onSuccess,
    onError: onError
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>Upload new file</DialogTitle>
      <DialogContent>
        <FileAttach
          canRemove={false}
          value={values.guid}
          sx={{ mb: 2 }}
          errors={getFieldTouchedError('guid')}
          onChange={(_, file) => setFieldValue('file', file)}
        />
        <TextField
          required
          sx={{ mb: 2 }}
          disabled={isLoading}
          helperText={getFieldTouchedError('fileName')}
          errors={getFieldTouchedError('fileName')}
          label="File Name"
          value={values.fileName}
          onChange={handleChange('fileName')}
        />
        <TextField
          disabled={isLoading}
          label="Description"
          multiline={true}
          rows={3}
          value={values.description}
          onChange={handleChange('description')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
