import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const LossOfRevenueVsForecast = ({
  convertedM2DRevenue,
  lossM2DRevenue,
  forecastM2DRevenue
}) => {
  const data = {
    labels: ['Loss total', 'Forecast total', 'Converted total'],
    datasets: forecastM2DRevenue.map((item) => ({
      categoryPercentage: 1,
      label: item.code,
      data: [
        lossM2DRevenue.find((subItem) => subItem.code === item.code)?.value ||
          0,
        convertedM2DRevenue.find((subItem) => subItem.code === item.code)
          ?.value || 0,
        forecastM2DRevenue.find((subItem) => subItem.code === item.code)
          ?.value || 0
      ],
      fill: false,
      backgroundColor: ['#A8AFF2', '#A7E0F2', '#FEEF68', '#FFCB7E', '#FF967E']
    }))
  };

  return (
    <Card height={617} title="Loss of Revenue M2D vs Forecast">
      <Box sx={{ width: '100%', height: 464 }}>
        <Bar
          options={options}
          data={data}
          style={{ maxHeight: 464, width: '100%' }}
        />
      </Box>
    </Card>
  );
};
