import axios from 'axios';

const url: string = process.env.REACT_APP_API_URL
  ? String(process.env.REACT_APP_API_URL).trim()
  : '';
export const authService = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

export default authService;
