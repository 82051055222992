import { plainToClass } from 'class-transformer';
import {
  API_APPOINTMENT_FIND_ALL,
  API_APPOINTMENT_FIND_ONE,
  API_APPOINTMENT_CREATE,
  API_APPOINTMENT_DELETE,
  API_APPOINTMENT_UPDATE,
  API_APPOINTMENT_CANCEL,
  API_APPOINTMENT_CONVERT
} from '../api.routes';
import { BaseApi } from './base.api';
import { AppointmentDto } from '../dto';
import { FindAllResponse } from '../types';

export class AppointmentApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<AppointmentDto>> {
    return this.httpClient
      .get(API_APPOINTMENT_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(AppointmentDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<AppointmentDto> {
    return this.httpClient
      .get(API_APPOINTMENT_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(AppointmentDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<AppointmentDto> {
    return this.httpClient
      .post(`${API_APPOINTMENT_CREATE}?clientUuid=${data.clientUuid}`, data)
      .then(({ data }) => {
        return plainToClass(AppointmentDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<AppointmentDto> {
    return this.httpClient
      .put(API_APPOINTMENT_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(AppointmentDto, data);
      });
  }

  /**
   * Cancel
   * @param uuid
   * @param data
   */
  public cancel(uuid, data): Promise<AppointmentDto> {
    return this.httpClient
      .put(API_APPOINTMENT_CANCEL, data, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(AppointmentDto, data);
      });
  }

  /**
   * Convert
   * @param uuid
   */
  public convert(uuid: string): Promise<AppointmentDto> {
    return this.httpClient
      .put(API_APPOINTMENT_CONVERT, {}, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(AppointmentDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid, data): Promise<void> {
    return this.httpClient.delete(API_APPOINTMENT_DELETE, {
      data,
      pathParams: { uuid }
    });
  }
}
