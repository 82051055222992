import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { StateDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';
import { StateApi } from '../../http/requests/state.api';

const stateApi = new StateApi();

export const fetchStateAsync = createFetchAsync(
  'state',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await stateApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchState = fetchStateAsync.asyncThunk;

export const fetchState = (payload) => (dispatch, getState) => {
  const {
    state: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchState(payload));
  }
};

type StateState = {
  findAll: AsyncState<FindAllResponse<StateDto>>;
};

const initialState: StateState = {
  findAll: fetchStateAsync.initialState
};

const state = createSlice({
  name: 'state',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchStateAsync.extraReducers
  }
});

export default state.reducer;
