import { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import { ClientApi } from '../../../core/http';

interface Props extends DialogProps {
  uuid: string;
  userName: string;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

const api = new ClientApi();

export const TerminateUserDialog: FC<Props> = ({
  uuid,
  userName,
  onSuccess,
  onFail,
  popDialog
}) => {
  const [reason, setReason] = useState<string>('');

  const handleConfirm = () => {
    api
      .delete(uuid, { reason })
      .then(() => {
        onSuccess && onSuccess();
        popDialog();
      })
      .catch(() => {
        onFail && onFail();
      });
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>Terminate User</DialogTitle>
      <DialogContent dividers={false}>
        <Typography variant="body3">
          Are you really want to Terminate client {userName}?
        </Typography>
        <TextField
          sx={{ mt: 2 }}
          label="Reason"
          multiline
          value={reason}
          rows={5}
          onChange={(e: any) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
