import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'badge'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    card: {
      backgroundColor: '#F6F9FF',
      borderRadius: 4,
      padding: theme.spacing(1.5),
      width: '100%'
    }
  }),
  options
);

export default useStyles;
