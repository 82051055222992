import { Box, Stack, Typography } from '@mui/material';
import { useDrag } from 'react-dnd';
import { IconDrag } from '../icons';
import { set, add, format } from 'date-fns';
import { SelectBox } from './SelectBox';
import { map } from 'lodash';

function round5(x) {
  return Math.round(x / 15) * 15;
}

export const AreaSelection = ({
  currentLeft,
  startTime,
  duration,
  therapists,
  data,
  availabilityViewWidth,
  ...props
}: any) => {
  const {
    setPrimaryTherapist,
    setSecondaryTherapist,
    primaryTherapist,
    secondaryTherapist
  } = props;

  const [collected, dragRef, dragPreview] = useDrag(
    () => ({
      type: 'timebox',
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    []
  );

  const { isDragging } = collected;

  const startDateInitial = set(new Date(), {
    minutes: 0,
    hours: startTime
  });

  const fifteenMinutesWidth = availabilityViewWidth / 32;

  const currentLeftInMinutes = round5((currentLeft / fifteenMinutesWidth) * 15);

  const startDate = add(startDateInitial, {
    minutes: +currentLeftInMinutes
  });

  const endDate = add(startDateInitial, {
    minutes: +duration + +currentLeftInMinutes
  });

  const startHoursFormatted = format(startDate, 'hh');
  const endHoursFormatted = format(endDate, 'hh');
  const startMinutesFormatted = format(startDate, 'mm');
  const endMinutesFormatted = format(endDate, 'mm');
  const startDayTime = format(startDate, 'a');
  const endDayTime = format(endDate, 'a');

  const freeTimes = map(Object.keys(data), (therapist) => {
    return data[therapist].isNotBusy;
  });

  const onSelectPrimaryTherapist = (therapist: any) => {
    setPrimaryTherapist(therapist);
  };

  const onSelectSecondaryTherapist = (therapist: any) => {
    setSecondaryTherapist(therapist);
  };

  return (
    <Box
      ref={dragPreview}
      style={{
        left: currentLeft,
        width: fifteenMinutesWidth * (duration / 15),
        height: '100%',
        position: 'absolute'
      }}
    >
      <Box
        style={{
          width: fifteenMinutesWidth * (duration / 15),
          height: '100%',
          background: 'transparent',
          border: '1px dashed #219653',
          position: 'absolute',
          borderRadius: 4,
          zIndex: 999,
          boxShadow: ' 0px 4px 8px rgba(233, 189, 70, 0.08);',
          pointerEvents: 'none'
        }}
      ></Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}
        style={{
          alignItems: 'center',
          width: '100%',
          padding: 8,
          height: 43
        }}
      >
        {duration > 30 && (
          <Typography>
            {startHoursFormatted}:{startMinutesFormatted} {startDayTime} -{' '}
            {endHoursFormatted}:{endMinutesFormatted} {endDayTime}
          </Typography>
        )}
        <Box
          {...collected}
          ref={dragRef}
          style={{
            width: 24,
            height: 24,
            cursor: 'move',
            zIndex: 11
          }}
        >
          <IconDrag />
        </Box>
      </Stack>
      <Stack
        direction="column"
        sx={{
          height: '100%'
        }}
      >
        {therapists.map((item, index) => {
          const primaryUuid = primaryTherapist?.uuid || null;
          const secondaryUuid = secondaryTherapist?.uuid || null;
          const isSelected =
            item.uuid === secondaryUuid || item.uuid === primaryUuid;
          return (
            <Box
              key={index}
              sx={{
                height: 43,
                alignItems: 'center',
                display: isDragging || isSelected ? 'hidden' : 'flex'
              }}
            >
              <SelectBox
                customWidth="100%"
                selected={isSelected}
                key={index}
                currentTime={
                  +format(startDate, 'HH') * 60 + +startMinutesFormatted
                }
                fromTime={format(startDate, 'HH:mm')}
                toTime={format(endDate, 'HH:mm')}
                duration={+duration}
                freeTimes={freeTimes[index]}
                therapistUuid={item.uuid}
                onSelectPrimary={(therapist) =>
                  onSelectPrimaryTherapist(therapist)
                }
                onSelectSecondary={(therapist) =>
                  onSelectSecondaryTherapist(therapist)
                }
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
