import { DataTableColumn } from '../../../components/table-view/TableView';

export const notificationMessagesColumns: DataTableColumn[] = [
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    cellProps: {
      width: '50%'
    }
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    render: (text: string) => <div dangerouslySetInnerHTML={{ __html: text }} />
  }
];
