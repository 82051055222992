import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { RolesForm } from '../../features/roles/RolesForm';

export const RolesEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Roles'
  })
)(RolesForm);
