import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options, labels } from './chart.options';

export const M2dCancellations = () => {
  const data = {
    labels: labels,

    datasets: [
      {
        axis: 'y',
        label: 'Scheduled',
        data: [269324.34, 100000.34, 124000.34, 250000.34],
        fill: false,
        backgroundColor: ['#FF967E', '#FF967E', '#FF967E', '#FF967E']
      }
    ]
  };

  return (
    <Card height={350} title="M2D Cancellations">
      <Box sx={{ width: '100%', height: 205 }}>
        <Bar
          options={options}
          data={data}
          style={{ maxHeight: 300, width: '100%' }}
        />
      </Box>
    </Card>
  );
};
