import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { NewsContainer } from '../../features/news/NewsContainer';

export const NewsPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: News'
  })
)(NewsContainer);
