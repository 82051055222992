import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import useStyles from './styles';
import { TypographyProps } from '@mui/material/Typography';

ChartJS.register(ArcElement, Tooltip, Legend);

const options: ChartOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const index = get(tooltipItem, 'dataIndex');
          const label = get(tooltipItem, 'label');
          const dataValue = get(tooltipItem, ['dataset', 'data', index]);
          const displayValue = get(
            tooltipItem,
            ['dataset', 'displayValue', index],
            dataValue
          );
          return `${label}: ${displayValue}`;
        }
      }
    }
  }
};

interface Item {
  label: string;
  color: string;
  value: number | string;
  displayValue?: string;
}

interface Props {
  items: Item[];

  WrapProps?: BoxProps;
  ChartWrapProps?: BoxProps;
  LabelProps?: TypographyProps;
}

export const DoughnutChart: FC<Props> = ({
  items,
  WrapProps,
  ChartWrapProps,
  LabelProps
}) => {
  const backgroundColor = map(items, 'color');
  const data = map(items, 'value');
  const labels = map(items, 'label');
  const displayValue = map(items, 'displayValue');
  const classes = useStyles();

  const chartData = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          data,
          displayValue,
          backgroundColor,
          borderWidth: 0
        }
      ]
    }),
    [items]
  );

  return (
    <Box className={classes.root} {...WrapProps}>
      <Box width={286} height={286} mb={3} {...ChartWrapProps}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Doughnut data={chartData} options={options} />
      </Box>
      <Box>
        {map(items, (item, index) => (
          <Stack
            key={index}
            spacing={1}
            direction="row"
            alignItems="center"
            mb={1}
          >
            <Box
              className={classes.indicator}
              sx={{ backgroundColor: item.color }}
            />
            <Typography
              variant="body2"
              color="#647593"
              minWidth={160}
              fontWeight={500}
              {...LabelProps}
            >
              {item.label}
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {get(item, 'displayValue', get(item, 'value'))}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};
