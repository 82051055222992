import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';

export class ClientDto {
  @Expose()
  id: string;

  @Expose()
  uuid: string;

  @Expose()
  get displayName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  birthDate: string;

  @Expose()
  ageMonths: number;

  @Expose()
  @Transform((_, obj) => {
    if (obj && obj.contacts) {
      return obj.contacts.find((item) => item.type === 'primary')
        ?.primaryPhoneNumber;
    }
  })
  phone: string;

  @Expose()
  status: string;

  @Expose()
  timeZone: string;

  @Expose()
  gender: string;

  @Expose()
  memberId: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'primaryLanguage.uuid'))
  primaryLanguageUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'primaryLanguage.name'))
  primaryLanguage: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'secondaryLanguage.uuid'))
  secondaryLanguageUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'secondaryLanguage.name'))
  secondaryLanguage: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'profitCenter.profitCentre'))
  profitCenter: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'profitCenter.uuid'))
  profitCenterUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'fundingSource.name'))
  fundingSource: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'fundingSource.uuid'))
  fundingSourceUuid: string;

  @Expose()
  referralAcceptanceDate: string;

  @Expose()
  notes: string;

  @Expose()
  contacts: any;

  @Expose()
  isDeleted: boolean;

  @Expose()
  updatedDate: Date;

  @Expose()
  creationDate: Date;

  @Expose()
  createdByUserUuid: string;

  @Expose()
  updatedByUserUuid: string;
}
