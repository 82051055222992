import { Expose } from 'class-transformer';

export class StateDto {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;
}
