import { PageHeader } from '../../components/page-header';
import { SearchField } from '../../components/fields';
import { TableView } from '../../components/table-view';
import { filesColumn } from './_mockdata';
import { useDialog } from '../../core/hooks/useDialog';
import { UploadNewFileDialog } from './UploadNewFileDialog';
import { IconButton, Stack } from '@mui/material';
import { IconPen, IconTrash } from '../../components/icons';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useListResource } from '../../core/hooks/useListResource';
import { FileApi, FileDto } from '../../core/http';
import { get } from 'lodash';
import { useSnackbar } from '../../core/hooks/useSnackbar';

const fileApi = new FileApi();

export const FileListContainer = () => {
  const [openUploadDialog] = useDialog(UploadNewFileDialog);
  const [openConfirmDialog, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const {
    data,
    isLoading,
    pagination,
    fetchData,
    deleteRecord,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort,
    newParamsLoading
  } = useListResource(fileApi);

  const handleDelete = (data: FileDto) => () => {
    openConfirmDialog({
      title: 'Delete file',
      message: 'Do you really want to delete this file?',
      onConfirm: () => {
        deleteRecord(data.uuid).then(() => {
          showMessage('File is deleted successfully');
          closeConfirm();
          fetchData();
        });
      }
    });
  };

  const handleEdit = (data: FileDto) => () => {
    openUploadDialog({ data, fetchData });
  };

  return (
    <>
      <PageHeader title="Files" />
      <SearchField
        sx={{ width: 400, mb: 3 }}
        size="small"
        placeholder="Search"
        loading={newParamsLoading}
        onChange={(value) => handleChangeParams('search', value || null)}
      />
      <TableView
        showEmptyState
        showTablePerPage
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        pagination={pagination}
        loading={isLoading}
        showPagination={true}
        dataSource={get(data, 'items') || []}
        columns={[
          ...filesColumn,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, data: FileDto) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton size="small" onClick={handleEdit(data)}>
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(data)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
