import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: [
    'Inter',
    'system-ui',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'Liberation Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji'
  ].join(','),
  fontSize: 14,
  h1: {
    fontFamily: 'Gilroy',
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: '-0.02em'
  },
  h2: {
    fontFamily: 'Poppins',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.25,
    letterSpacing: '-0.02em'
  },
  h3: {
    fontFamily: 'Gilroy',
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: '-0.014em'
  },
  h4: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.33,
    letterSpacing: '-0.014em'
  },
  h5: {
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: '-0.014em'
  },
  h6: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.57,
    letterSpacing: '-0.028em'
  },
  subtitle1: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.25
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.25
  },
  body1: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.5
  },
  body2: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.4
  },
  body3: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.71
  },

  caption: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.33
  },
  button: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.333,
    textTransform: 'none'
  },
  overline: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.35
  }
};
