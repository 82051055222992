import { plainToClass } from 'class-transformer';
import {
  API_USER_FIND_ALL,
  API_USER_FIND_ONE,
  API_USER_CREATE,
  API_USER_DELETE,
  API_USER_UPDATE,
  API_USER_ME,
  API_USER_RESET_PASSWORD_SEND_LINK,
  API_USER_CHANGE_PASSWORD,
  API_USER_RESET_2FA
} from '../api.routes';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { UserDto } from '../dto';

export class UserApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<UserDto>> {
    return this.httpClient
      .get(API_USER_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(UserDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<UserDto> {
    return this.httpClient
      .get(API_USER_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(UserDto, data);
      });
  }

  /**
   * Me
   * @param uuid
   */
  public me(): Promise<UserDto> {
    return this.httpClient
      .get(API_USER_ME)
      .then(({ data }) => {
        return plainToClass(UserDto, data);
      })
      .catch(() => {
        return {};
      });
  }

  /**
   * Update password
   * @param uuid
   */
  public sendResetPasswordLink(data): Promise<any> {
    return this.httpClient
      .post(API_USER_RESET_PASSWORD_SEND_LINK, data)
      .then(({ data }) => {
        return plainToClass(UserDto, data);
      })
      .catch(() => {
        return {};
      });
  }

  /**
   * Change password
   * @param uuid
   */
  public changePassword(data): Promise<any> {
    return this.httpClient
      .post(API_USER_CHANGE_PASSWORD, data)
      .then(({ data }) => {
        return plainToClass(UserDto, data);
      })
      .catch(() => {
        return {};
      });
  }

  /**
   * Reset 2FA
   * @param uuid
   */
  public reset2FA(data): Promise<any> {
    return this.httpClient
      .post(API_USER_RESET_2FA, data)
      .then(({ data }) => {
        return plainToClass(UserDto, data);
      })
      .catch(() => {
        return {};
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<UserDto> {
    return this.httpClient.post(API_USER_CREATE, data).then(({ data }) => {
      return plainToClass(UserDto, data);
    });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<UserDto> {
    return this.httpClient.put(API_USER_UPDATE, data).then(({ data }) => {
      return plainToClass(UserDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid, data): Promise<void> {
    return this.httpClient.delete(API_USER_DELETE, {
      pathParams: { uuid },
      data
    });
  }
}
