import React from 'react';
import { Stack, Typography, Box, Skeleton } from '@mui/material';
import { Card } from '../../../components/cards';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { isNumber, map } from 'lodash';
import { EmployeeCaseLoadApi } from '../../../core/http/requests/employee-case-load.api';
import { useListResource } from '../../../core/hooks/useListResource';
import { round } from 'lodash';

interface Props {
  employeeUuid?: string;
}

const employeeCaseLoadApi = new EmployeeCaseLoadApi();

export const CaseLoadWidget = ({ employeeUuid }: Props) => {
  const { data, isLoading } = useListResource(employeeCaseLoadApi, {
    defaultParams: { employeeUuid }
  });

  const caseLoadTypes = [
    {
      title: 'Scheduled (h)',
      backgroundColor: 'rgba(242, 201, 76, 0.2)',
      type: 'total'
    },
    {
      title: 'UNConverted (h)',
      backgroundColor: '#ECF0F7',
      type: 'unconverted'
    },
    {
      title: 'Converted (h)',
      backgroundColor: 'rgba(39, 174, 96, 0.2)',
      type: 'converted'
    },
    {
      title: 'Cancellations (h)',
      backgroundColor: 'rgba(235, 87, 87, 0.1)',
      type: 'cancelled'
    },
    {
      title: 'Staff Cancellations (h)',
      backgroundColor: 'rgba(235, 87, 87, 0.1)',
      type: 'staffCancelled'
    },
    {
      title: 'Parent Cancellations (h) ',
      backgroundColor: 'rgba(235, 87, 87, 0.1)',
      type: 'parentCancelled'
    },
    {
      title: 'Completion Rate',
      backgroundColor: 'rgba(0, 82, 204, 0.1)',
      type: 'terminated'
    }
  ];

  return (
    <Card title="Case Load" meta={<WeekRangePicker />}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        {map(caseLoadTypes, (item, key) => {
          return isLoading ? (
            <Skeleton
              key={key}
              variant="rectangular"
              width="100%"
              height={70}
              style={{ borderRadius: 4 }}
            />
          ) : (
            <Box
              key={key}
              style={{
                backgroundColor: item.backgroundColor,
                padding: 12,
                borderRadius: 4,
                width: '100%',
                height: 70,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Stack spacing={1} alignItems="center">
                <Typography variant="h2">
                  {isNumber(data[item.type]) ? round(data[item.type], 2) : 0}
                  {item.type === 'terminated' && '%'}
                </Typography>
                <Typography variant="body1">{item.title}</Typography>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    </Card>
  );
};
