import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { RegionDto, RegionApi } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const regionApi = new RegionApi();

export const fetchRegionAsync = createFetchAsync(
  'region',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await regionApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchRegionSource = fetchRegionAsync.asyncThunk;

export const fetchRegion = (payload) => (dispatch, getState) => {
  const {
    region: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchRegionSource(payload));
  }
};

type RegionState = {
  findAll: AsyncState<FindAllResponse<RegionDto>>;
};

const initialState: RegionState = {
  findAll: fetchRegionAsync.initialState
};

const region = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchRegionAsync.extraReducers
  }
});

export default region.reducer;
