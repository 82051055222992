import { FC } from 'react';
import { DEV_MODE } from '../../constants/app.constants';
import { useUser } from '../../core/hooks/useUser';

interface Props {
  children: JSX.Element;
  permission: string;
}

export const PermissionView: FC<Props> = ({ children, permission }) => {
  const { user } = useUser();
  if (DEV_MODE) {
    return children;
  }
  const current = user.userRole.permissions[permission];

  if (current) {
    return children;
  }

  return null;
};
