import { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-header';
import { SearchField } from '../../components/fields';
import { Button, IconButton, Stack } from '@mui/material';
import { IconPen, IconTrash } from '../../components/icons';
import { TableView } from '../../components/table-view';
import { announcementsColumns } from './_mockdata';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { AnnouncementsEditDialog } from './AnnouncementsEditDialog';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { useListResource } from '../../core/hooks/useListResource';
import { AnnouncementApi } from '../../core/http/requests';
import { get } from 'lodash';
import { PermissionView } from '../../components/permission-view';
import useDebounce from '../../core/hooks/useDebounce';

const announcementApi = new AnnouncementApi();

export const AnnouncementsContainer = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const [openEdit, closeEdit] = useDialog(AnnouncementsEditDialog);
  const { showMessage } = useSnackbar();
  const [search, setSearch] = useState('');
  const debouncedValue: string = useDebounce<string>(search, 200);

  const {
    data,
    isLoading,
    fetchData,
    pagination,
    handleChangePage,
    handleChangeParams,
    handleChangePerPage,
    handleChangeSort,
    deleteRecord
  } = useListResource(announcementApi);

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams('search', search || null);
    }
  }, [debouncedValue]);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          showMessage('Announcement is deleted successfully');
          closeConfirm();
          fetchData();
        });
      }
    });
  };

  const handleOpenEdit =
    (uuid = '') =>
    () => {
      openEdit({
        uuid,
        onSuccess: () => {
          closeEdit();
          showMessage(
            `Announcement ${uuid ? 'updated' : 'created'} successfully`
          );
          fetchData();
        },
        onError: () => {
          closeEdit();
          showMessage('Somnething went wrong.', 'error');
        }
      });
    };

  return (
    <>
      <PageHeader title="Announcements">
        <PermissionView permission="modifyAnnouncements">
          <Button onClick={handleOpenEdit()}>Add New</Button>
        </PermissionView>
      </PageHeader>
      <SearchField
        sx={{ width: 400, mb: 3 }}
        size="small"
        placeholder="Search"
        onChange={(value) => {
          setSearch(value);
        }}
      />
      <TableView
        loading={isLoading}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        showEmptyState
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        pagination={pagination}
        columns={[
          ...announcementsColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, record) => (
              <Stack direction="row" alignItems="center">
                <IconButton size="small" onClick={handleOpenEdit(record.uuid)}>
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(record.uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
