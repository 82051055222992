import React, { ComponentType, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { IconSearch } from '../../icons';
import useStyles from './styles';

interface Props extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  onChange?: (value: string) => void;
  loading?: boolean;
}

export const SearchField: ComponentType<Props> = ({ onChange, ...props }) => {
  const [value, setValue] = useState('');
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange && onChange(value);
    setValue(value);
  };

  return (
    <TextField
      classes={{ root: classes.root }}
      {...props}
      onChange={handleChange}
      helperText=""
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconSearch fontSize="small" />
          </InputAdornment>
        )
      }}
    />
  );
};
