import { useNavigate } from 'react-router';
import { Button, Stack, IconButton } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { SearchField } from '../../components/fields';
import { companiesColumn, companiesData } from './_mockdata';
import { TableView } from '../../components/table-view';
import { IconPen, IconTrash } from '../../components/icons';
import {
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE_CREATE,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';

export const SuperAdminCompaniesContainer = () => {
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const handleConfirmCompanyDeactivation = (uuid: string) => {
    openConfirm({
      title: 'Confirm company deactivation',
      message:
        'Do you really want to deactivate company profile? All company users will have an "INACTIVE" status',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  return (
    <>
      <PageHeader title="Companies">
        <Button color="secondary">Export</Button>
      </PageHeader>
      <Stack direction="row" justifyContent="space-between">
        <Button
          onClick={() =>
            navigate(url(ROUTE_SUPER_ADMIN_COMPANY_PROFILE_CREATE))
          }
        >
          Add new
        </Button>
        <SearchField
          sx={{ width: 400, mb: 3 }}
          size="small"
          placeholder="Search"
        />
      </Stack>
      <TableView
        showEmptyState
        showTablePerPage
        showPagination
        onRowClick={(record) =>
          navigate(
            url(ROUTE_SUPER_ADMIN_COMPANY_PROFILE, { id: record.companyCode })
          )
        }
        dataSource={companiesData}
        columns={[
          ...companiesColumn,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: () => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  size="small"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    navigate(
                      url(ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT, {
                        id: '123'
                      })
                    );
                  }}
                >
                  <IconPen />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    handleConfirmCompanyDeactivation('123');
                  }}
                >
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
