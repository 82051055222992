import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchServiceType } from '../store/slices/serviceTypeSlice';

export const useServiceType = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.serviceType);

  useEffect(() => {
    dispatch(fetchServiceType({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
