import { Box } from '@mui/material';
import { ComponentType, ReactNode } from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import get from 'lodash/get';

interface Props {
  label: any;
  variant?: string;
  sx?: any;
  circle?: boolean;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  size?: 'small';
  textUppercase?: boolean;
}

export const Badge: ComponentType<Props> = ({
  label,
  variant = 'default',
  sx,
  circle,
  LeftIcon,
  RightIcon,
  size = 'default',
  textUppercase = true
}) => {
  const classes = useStyles();

  const variants = {
    active: 'success',
    converted: 'success',
    terminated: 'error',
    expired: 'warning',
    conflicted: 'warning',
    not_converted: 'warning',
    cancelled: 'default',
    error: 'error',
    warning: 'warning',
    success: 'success',
    info: 'info',
    default: 'default'
  };
  const variantStyle = get(variants, variant, 'default');

  const stylesX =
    LeftIcon || RightIcon
      ? {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          ...sx
        }
      : {
          ...sx,
          display: 'inline-flex'
        };

  return (
    <Box
      sx={stylesX}
      className={clsx(classes.root, {
        [classes.circle]: circle,
        [classes.primary]: variantStyle === 'primary',
        [classes.default]: variantStyle === 'default',
        [classes.secondary]: variantStyle === 'secondary',
        [classes.success]: variantStyle === 'success',
        [classes.warning]: variantStyle === 'warning',
        [classes.error]: variantStyle === 'error',
        [classes.sizeSmall]: size === 'small',
        [classes.textUppercase]: textUppercase
      })}
      component="span"
    >
      {LeftIcon && (
        <Box mr={0.5} sx={{ alignItems: 'center', display: 'flex' }}>
          {LeftIcon}
        </Box>
      )}
      {label}
      {RightIcon && (
        <Box ml={0.5} sx={{ alignItems: 'center', display: 'flex' }}>
          {RightIcon}
        </Box>
      )}
    </Box>
  );
};
