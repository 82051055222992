import React, { ComponentType, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogProps } from '../../core/providers/dialog.provider';
import { Typography, Button, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface Props extends DialogProps {
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  body?: ReactNode;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmDialog: ComponentType<Props> = ({
  popDialog,
  title = 'Confirm your action',
  message,
  onConfirm,
  okText = 'Confirm',
  cancelText = 'Cancel',
  body
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 480 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <Typography variant="body3">{message}</Typography>
          {body && body}
        </DialogContent>
      )}
      <DialogActions sx={{ border: 'none' }}>
        <Button color="third" onClick={popDialog}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm}>{okText}</Button>
      </DialogActions>
    </Dialog>
  );
};
