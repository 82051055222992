import { AppointmentsList } from '../../../features/appointments/AppointementsList';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const AppointmentsListPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Appointments'
  })
)(AppointmentsList);
