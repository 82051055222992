import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchServiceDescription } from '../store/slices/serviceDescriptionSlice';

export const useServiceDescription = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector(
    (state: AppState) => state.serviceDescription
  );

  useEffect(() => {
    dispatch(fetchServiceDescription({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
