import React from 'react';
import { Helmet } from 'react-helmet';

/* eslint-disable react/display-name */
export const withHelmet =
  ({ title }) =>
  (ComposedComponent) =>
  (props) =>
    (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <ComposedComponent {...props} />
      </>
    );
