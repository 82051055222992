import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IconTrash } from '../icons';
import useStyles from './styles';

interface Props {
  onSelectPrimary: (arg: any) => void;
  onSelectSecondary: (arg: any) => void;
  onRemove?: () => void;
  therapistUuid: string;
  freeTimes?: any;
  currentTime?: any;
  duration?: any;
  fromTime?: any;
  toTime?: any;
  hidden?: boolean;
  selected?: boolean;
  visible?: boolean;
  type?: string;
  customWidth?: string;
  alwaysShown?: boolean;
}

export const SelectBox = ({
  onSelectPrimary,
  onSelectSecondary,
  onRemove,
  therapistUuid,
  freeTimes,
  currentTime,
  duration,
  fromTime,
  toTime,
  selected,
  customWidth,
  alwaysShown = false,
  type
}: Props) => {
  const [isSelectOpen, setIsSelectOpen] = useState(selected);
  const classes = useStyles({ isSelectOpen });
  const [therapistType, setTherapistType] = useState(type || '');

  const shouldBeShown = freeTimes?.find(
    (item) =>
      ((item.from <= currentTime && item.to >= currentTime + duration) ||
        (item.from <= currentTime + 1440 &&
          item.to >= currentTime + 1440 + duration)) &&
      !item.isBusy
  );
  const onTherapistTypeSelect = (e, type) => {
    e.stopPropagation();
    if (!alwaysShown) {
      setIsSelectOpen(false);
    }
    setTherapistType(type);
  };
  const calcWidth = () => {
    let width = '';
    if (duration === 60) {
      width = '100%';
    } else if (duration === 30) {
      width = '50%';
    } else {
      width = '25%';
    }
    return width;
  };
  const isSmall = duration <= 30;
  return (
    <Box
      style={{
        height: 32,
        width: customWidth || calcWidth(),
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        zIndex: 110,
        cursor: shouldBeShown ? 'pointer' : 'default'
      }}
      className={
        isSelectOpen
          ? classes.selectBoxOpened
          : shouldBeShown && !selected
          ? classes.selectBox
          : undefined
      }
      onClick={() =>
        shouldBeShown && !selected ? setIsSelectOpen(true) : undefined
      }
    >
      {isSelectOpen ? (
        <Stack sx={{ width: '100%', padding: isSmall ? '2px' : '4px 8px' }}>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'space-between' }}
          >
            <Box className={classes.therapistSelectStack}>
              <Typography
                onClick={(e) => {
                  onTherapistTypeSelect(e, 'primary');
                  onSelectPrimary({
                    uuid: therapistUuid,
                    from: fromTime,
                    to: toTime
                  });
                }}
                style={{
                  color: '#fff',
                  padding: isSmall ? '4px' : '4px 8px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor:
                    therapistType === 'primary' ? '#0052CC' : 'transparent'
                }}
              >
                {isSmall ? '1' : 'Primary'}
              </Typography>
              <Typography
                onClick={(e) => {
                  onTherapistTypeSelect(e, 'secondary');
                  onSelectSecondary({
                    uuid: therapistUuid,
                    from: fromTime,
                    to: toTime
                  });
                }}
                style={{
                  color: '#fff',
                  padding: isSmall ? '4px' : '4px 8px',
                  borderRadius: '4px',
                  backgroundColor:
                    therapistType === 'secondary' ? '#0052CC' : 'transparent'
                }}
              >
                {isSmall ? '2' : 'Secondary'}
              </Typography>
            </Box>
            <IconButton onClick={onRemove} style={{ padding: 0 }}>
              <IconTrash />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <Stack className={classes.rootStack} direction="row" spacing={1}>
          <Typography className={classes.selectTherapist} variant="body1">
            {isSmall ? 'Select' : 'Select Primary Therapist'}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};
