import { makeStyles } from '@mui/styles';

const options = {
  name: 'badge'
};

const useStyles = makeStyles(
  () => ({
    root: {},
    step: {
      width: 24,
      height: 24,
      textAlign: 'center',
      borderRadius: '50%',
      backgroundColor: '#ECF0F7',
      fontSize: 12,
      lineHeight: 2,
      fontWeight: 600,
      color: '#647593'
    },
    stepPassed: {
      backgroundColor: '#219653',
      color: '#fff'
    },
    stepActive: {
      backgroundColor: '#0052CC',
      color: '#fff'
    },
    label: {
      color: '#647593',
      fontSize: 12,
      lineHeight: 1.333,
      fontWeight: 500
    },
    labelPassed: {
      color: '#172B4D'
    },

    labelActive: {
      color: '#172B4D'
    }
  }),
  options
);

export default useStyles;
