import { plainToClass } from 'class-transformer';
import {
  API_CLIENT_INSURED_PERSON_CREATE,
  API_CLIENT_INSURED_PERSON_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { ClientInsuredPersonDto } from '../dto';

export class ClientInsuredPersonApi extends BaseApi {
  /**
   * Create
   * @param data
   */
  public create(data): Promise<ClientInsuredPersonDto> {
    return this.httpClient
      .post(API_CLIENT_INSURED_PERSON_CREATE, data)
      .then(({ data }) => {
        return plainToClass(ClientInsuredPersonDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<ClientInsuredPersonDto> {
    return this.httpClient
      .put(API_CLIENT_INSURED_PERSON_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(ClientInsuredPersonDto, data);
      });
  }
}
