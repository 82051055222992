import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { API_SERVICE_AUTHORIZATION_REPORT } from '../api.routes';
import { ServiceAuthorizationReportDto } from '../dto';

export class ServiceAuthorizationReportApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(
    params
  ): Promise<FindAllResponse<ServiceAuthorizationReportDto>> {
    return this.httpClient
      .get(API_SERVICE_AUTHORIZATION_REPORT, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ServiceAuthorizationReportDto, data.items)
        };
      });
  }
}
