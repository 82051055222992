import { Expose } from 'class-transformer';

export class LeaveDayDto {
  @Expose()
  uuid: string;

  @Expose()
  employeeUuid: string;

  @Expose()
  type: string;

  @Expose()
  daysNumber: number;

  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  note: string;
}
