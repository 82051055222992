import { TwoFactorSetupStepTwo } from '../../../features/auth/TwoFactorSetupStepTwo';
import { Helmet } from 'react-helmet';

export const TwoFactorSetupStepTwoPage = () => {
  return (
    <>
      <Helmet>
        <title>Splashworks: Two Factor Setup</title>
      </Helmet>
      <TwoFactorSetupStepTwo />
    </>
  );
};
