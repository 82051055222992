import { plainToClass } from 'class-transformer';
import {
  API_MESSAGE_CREATE,
  API_MESSAGE_DELETE,
  API_MESSAGE_FIND_ALL,
  API_MESSAGE_FIND_ONE,
  API_MESSAGE_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { MessageDto } from '../dto';
import { FindAllResponse } from '../types';
import { messagesData } from '../../../features/clients/_mockdata';

export class MessagesApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<MessageDto>> {
    return this.httpClient
      .get(API_MESSAGE_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(MessageDto, data.items)
        };
      })
      .catch(() => {
        return {
          items: plainToClass(MessageDto, messagesData)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<MessageDto> {
    return this.httpClient
      .get(API_MESSAGE_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(MessageDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<MessageDto> {
    return this.httpClient.post(API_MESSAGE_CREATE, data).then(({ data }) => {
      return plainToClass(MessageDto, data);
    });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<MessageDto> {
    return this.httpClient.put(API_MESSAGE_UPDATE, data).then(({ data }) => {
      return plainToClass(MessageDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_MESSAGE_DELETE, {
      pathParams: { uuid }
    });
  }
}
