import { makeStyles } from '@mui/styles';
import waves from '../../assets/waves.png';

export const useStyles = makeStyles(() => ({
  signInRoot: {},
  twoFactorRoot: {},
  twoFactorRecoveryRoot: {},
  resetPasswordRoot: {},
  resetPasswordSuccessRoot: {},
  twoFactorSetupRoot: {},
  inviteRoot: {},

  twoFactorSetupStepTwoRoot: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'radial-gradient(115.93% 96.4% at 86.99% 22.26%, #0052CC 0%, #7200CC 100%)'
  },
  twoFactorSetupStepTwoWaves: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    flex: 1,
    backgroundImage: `url(${waves})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  twoFactorSetupStepTwoBlock: {
    paddingRight: 24,
    paddingLeft: 24,
    paddingBottom: 24,
    paddingTop: 24,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 24px 32px rgba(0, 0, 0, 0.04)',
    borderRadius: 4
  },
  twoFactorSetupStepTwoDescriptionText: {
    width: 512,
    paddingRight: 35,
    paddingLeft: 35
  },
  twoFactorSetupStepTwoStepsContainer: {
    display: 'flex'
  },
  twoFactorSetupStepTwoStepBlock: {
    width: 352,
    minHeight: 420,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ECF0F6'
  },
  twoFactorSetupStepTwoStepHeaderText: {
    paddingLeft: 15,
    paddingRight: 15
  },
  twoFactorSetupStepTwoQrCodeImage: {
    width: 140,
    height: 140
  },
  recoveryCode: {
    background: '#ECF0F7 !important',
    border: 'none !important',
    color: '#000 !important',
    paddingTop: 10,
    paddingBottom: 10,
    width: 305,
    height: 40,
    textAlign: 'center',
    borderRadius: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  twoFactorSetupStepTwoDownloadButton: {
    cursor: 'pointer'
  },
  twoFactorSetupStepTwoBackToLoginButton: {
    fontSize: 12,
    fontWeight: 500
  },
  twoFactorSetupSuccessRoot: {
    twoFactorImgBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    block: {
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    connectToAdminBlock: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: 0,
      right: 0,
      bottom: 40
    },
    enterRecoveryCodeBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  block: {
    width: 400
  },
  connectToAdminBlock: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    bottom: 40
  },
  formControl: {
    width: '100%'
  },
  companyBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #ECF0F7'
  },
  companyLogo: {
    height: '34px'
  },
  companyChangeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  enterRecoveryCodeBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
