import { FC } from 'react';

import { Box, Grid } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { Card } from '../../../components/cards';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { transformEmployeeAppointments } from '../../../core';
import { upcomingAppointmentColumns } from '../_mockdata';
import { useAppointment } from '../../../core/hooks/useAppointment';
import { CaseLoadWidget } from '../widgets/CaseLoadWidget';
import { FilesListWidget } from '../widgets/FilesListWidget';
import { ProductivityDashboardWidget } from '../widgets/ProductivityDashboardWidget';
import { ClientListWidget } from '../widgets/ClientListWidget';
import { addDays } from 'date-fns';

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data, isLoading } = useAppointment({
    profile: {
      'filter[employee][eq]': id,
      'filter[startDate][gt]': new Date().toISOString(),
      'filter[endDate][lte]': addDays(new Date(), 7).toISOString()
    }
  });

  return (
    <Box>
      <Grid container spacing={1} justifyContent="center">
        <Grid item md={6} xs={12}>
          <ProductivityDashboardWidget employeeUuid={id} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Card sx={{ height: 331 }} title="Upcoming appointments">
            <TableView
              showEmptyState
              EmptyStateProps={{
                pt: 2,
                title: 'Employee has no upcoming appointments',
                description: null
              }}
              loading={isLoading}
              showTablePerPage={!isLoading}
              showPagination={!isLoading}
              dataSource={
                data
                  ? transformEmployeeAppointments(data, null).slice(0, 3)
                  : []
              }
              keyExtractor={(item) => item.id}
              onRowClick={() => navigate(pathname + '#appointments')}
              columns={upcomingAppointmentColumns}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CaseLoadWidget employeeUuid={id} />
        </Grid>
        <Grid item xs={12}>
          <ClientListWidget employeeUuid={id} />
        </Grid>
        <Grid item xs={12}>
          <FilesListWidget employeeUuid={id} />
        </Grid>
      </Grid>
    </Box>
  );
};
