import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminCompanyEditContainer } from '../../features/super-admin-companies/SuperAdminCompanyEdit';

export const SuperAdminCompanyEdit = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Companies'
  })
)(SuperAdminCompanyEditContainer);
