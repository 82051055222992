import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchClient } from '../store/slices/clientSlice';

export const useClient = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.client);

  useEffect(() => {
    dispatch(fetchClient({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', []),
    itemsLoading: get(findAll, 'loading', false)
  };
};
