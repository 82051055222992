import { Box, Stack, Typography } from '@mui/material';
import useStyles from './styles';

export const TimeBox = (props) => {
  const classes = useStyles();

  const { isBusy } = props;

  return (
    <Box
      style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        height: 32,
        position: 'absolute',
        borderRadius: 4,
        zIndex: 11
      }}
      className={isBusy ? classes.timeBoxBusy : classes.timeBox}
    >
      <Stack className={classes.rootStack} direction="row" spacing={1}>
        <Typography
          className={classes.timeBoxTime}
          variant="body1"
          color="#172B4D"
        >
          {props.name}
        </Typography>
        <Typography
          className={classes.timeBoxTime}
          variant="body1"
          color="#172B4D80"
        >
          {props.from} – {props.to}
        </Typography>
      </Stack>
    </Box>
  );
};
