import { AuthLayout } from '../../../layouts/auth-layout';
import { Invite } from '../../../features/auth/Invite';
import { Helmet } from 'react-helmet';

export const InvitePage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: User Invite</title>
      </Helmet>
      <Invite />
    </AuthLayout>
  );
};
