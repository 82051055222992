import { plainToClass } from 'class-transformer';
import { API_CLIENT_SERVICE_AUTHORIZATION_FIND_ALL } from '../api.routes';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { ClientServiceAuthorizationDto } from '../dto';

export class ClientServiceAuthorizationApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(
    pathParams
  ): Promise<FindAllResponse<ClientServiceAuthorizationDto>> {
    return this.httpClient
      .get(API_CLIENT_SERVICE_AUTHORIZATION_FIND_ALL, { pathParams })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ClientServiceAuthorizationDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(
    clientUuid: string,
    uuid: string
  ): Promise<ClientServiceAuthorizationDto> {
    return this.httpClient
      .get(`/api/v1/clients/${clientUuid}/authorization-service/${uuid}`)
      .then(({ data }) => {
        return plainToClass(ClientServiceAuthorizationDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<ClientServiceAuthorizationDto> {
    return this.httpClient
      .post(`/api/v1/clients/${data.clientUuid}/authorization-service`, data)
      .then(({ data }) => {
        return plainToClass(ClientServiceAuthorizationDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<ClientServiceAuthorizationDto> {
    return this.httpClient
      .put(`/api/v1/clients/${data.clientUuid}/authorization-service`, data)
      .then(({ data }) => {
        return plainToClass(ClientServiceAuthorizationDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(params): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/clients/${params.clientUuid}/authorization-service/${params.uuid}`
    );
  }
}
