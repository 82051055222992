import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Stack, Button, FormControl } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ROUTE_SIGN_IN } from '../../constants/route.paths';
import { SelectField, TextField } from '../../components/fields';
import { BackButton } from '../../components/back-button';

export const ConnectToAdministrator: ComponentType = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { handleChange, handleBlur, values, touched, errors } = useFormik({
    initialValues: {
      messageType: '',
      email: '',
      note: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      messageType: yup.string().required('Please select one the options.'),
      email: yup
        .string()
        .email('Please enter valid email.')
        .required('Email address is Required.'),
      note: yup.string()
    }),
    onSubmit: () => {
      return;
    }
  });

  return (
    <>
      <Box className={classes.signInRoot}>
        <Box className={classes.block}>
          <BackButton title="Login" onClick={() => navigate(ROUTE_SIGN_IN)} />
          <Typography variant="h3" mb={3} mt={2}>
            Contact to your administrator
          </Typography>
          <FormControl className={classes.formControl}>
            <Stack spacing={0.5}>
              <SelectField
                disabled={false}
                placeholder="Select"
                errors={touched?.messageType && errors?.messageType}
                value={values.messageType}
                label="Message type"
                onChange={handleChange('messageType')}
                onBlur={handleBlur('messageType')}
                items={[
                  {
                    label: 'Forgot email/password from my account',
                    value: 'forgot_mail_or_password'
                  }
                ]}
              />
              <TextField
                value={values.email}
                helperText={touched.email && errors.email}
                label="Enter your Email"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
              />
              <TextField
                disabled={false}
                errors={errors.note}
                label="Note"
                multiline
                rows={2}
                value={values.note}
                onChange={handleChange('note')}
              />
              <Button size="medium" sx={{ width: 61 }}>
                Send
              </Button>
            </Stack>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
