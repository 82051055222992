import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { DEV_MODE } from '../constants/app.constants';
import { UserDto } from '../core/http';

interface Props {
  permission?: string;
  user: UserDto;
}

export const PermissionRoute: FC<Props> = ({ permission, user }) => {
  if (DEV_MODE || !permission) {
    return <Outlet />;
  }
  const currentPermission = user?.userRole?.permissions[permission];

  return currentPermission ? <Outlet /> : <Navigate to={'/404'} />;
};
