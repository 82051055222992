import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface ISet2FAValues {
  code: string;
}

interface IVerify2FAValues {
  code: string;
}

interface IVerify2FAThunkArguments {
  values: IVerify2FAValues;
  options?: Partial<IRequestOptions>;
}

interface ISet2FAThunkArguments {
  values: ISet2FAValues;
  options?: Partial<IRequestOptions>;
}

interface IResendCodeThunkArguments {
  options?: Partial<IRequestOptions>;
}

export const verify2FA = createAsyncThunk(
  'verify2FAAction',
  async ({ options }: IVerify2FAThunkArguments) => {
    try {
      const data: any = await delay(2000, {
        success: true
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const set2FA = createAsyncThunk(
  'set2FAAction',
  async ({ options }: ISet2FAThunkArguments) => {
    try {
      const data: any = await delay(2000, {
        success: true
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const resendCode = createAsyncThunk(
  'resendCodeAction',
  async ({ options }: IResendCodeThunkArguments) => {
    try {
      const data: any = await delay(2000, {
        success: true
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const twoFactorSlice = createSlice({
  name: 'twoFactorSlice',
  initialState: {
    verify2FALoading: false,
    verify2FAData: {},
    verify2FAError: null,
    set2FALoading: false,
    set2FAData: {},
    set2FAError: null,
    resendCodeLoading: false,
    resendCodeData: {},
    resendCodeError: null
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verify2FA.pending, (state) => {
      state.verify2FALoading = true;
      state.verify2FAData = {};
      state.verify2FAError = null;
    });
    builder.addCase(verify2FA.fulfilled, (state, { payload }) => {
      state.verify2FALoading = false;
      state.verify2FAData = payload;
      state.verify2FAError = null;
    });
    builder.addCase(verify2FA.rejected, (state) => {
      state.verify2FALoading = false;
      state.verify2FAData = {};
      state.verify2FAError = 'Something went wrong';
    });
    builder.addCase(set2FA.pending, (state) => {
      state.set2FALoading = true;
      state.set2FAData = {};
      state.set2FAError = null;
    });
    builder.addCase(set2FA.fulfilled, (state, { payload }) => {
      state.set2FALoading = false;
      state.set2FAData = payload;
      state.set2FAError = null;
    });
    builder.addCase(set2FA.rejected, (state) => {
      state.set2FALoading = false;
      state.set2FAData = {};
      state.set2FAError = 'Something went wrong';
    });
    builder.addCase(resendCode.pending, (state) => {
      state.resendCodeLoading = true;
      state.resendCodeData = {};
      state.resendCodeError = null;
    });
    builder.addCase(resendCode.fulfilled, (state, { payload }) => {
      state.resendCodeLoading = false;
      state.resendCodeData = payload;
      state.resendCodeError = null;
    });
    builder.addCase(resendCode.rejected, (state) => {
      state.resendCodeLoading = false;
      state.resendCodeData = {};
      state.resendCodeError = 'Something went wrong';
    });
  }
});

export default twoFactorSlice.reducer;
