import { ProfitCenterContainer } from '../../features/master-data/profit-center/ProfitCenterContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataProfitCenter = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Profit Centers'
  })
)(ProfitCenterContainer);
