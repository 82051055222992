export const DATE_FORMAT = 'MM/dd/yyyy';
export const TIME_FORMAT = 'hh:mm a';
export const FULL_DATE_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DEV_MODE = true;

export const calendarViewTypeItems = [
  {
    label: 'Calendar',
    value: 'calendar'
  },
  {
    label: 'List',
    value: 'table'
  }
];

export const calendarStatusFilterItems = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Not Converted',
    value: 'not_converted'
  },
  {
    label: 'Converted',
    value: 'converted'
  },
  {
    label: 'Cancelled',
    value: 'cancelled'
  },
  {
    label: 'Conflicted',
    value: 'conflicted'
  }
];

export const weekItems = [
  {
    label: 'Sun',
    value: 'sunday'
  },
  {
    label: 'Mon',
    value: 'monday'
  },
  {
    label: 'Tue',
    value: 'tuesday'
  },
  {
    label: 'Wed',
    value: 'wednesday'
  },
  {
    label: 'Thu',
    value: 'thursday'
  },
  {
    label: 'Fri',
    value: 'friday'
  },
  {
    label: 'Sat',
    value: 'saturday'
  }
];

export const monthWeekItems = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '4',
    value: '4'
  },
  {
    label: '5',
    value: '5'
  }
];

export const monthItems = [
  {
    label: 'January',
    value: 'january'
  },
  {
    label: 'February',
    value: 'february'
  },
  {
    label: 'March',
    value: 'march'
  },
  {
    label: 'April',
    value: 'april'
  },
  {
    label: 'May',
    value: 'may'
  },
  {
    label: 'June',
    value: 'june'
  },
  {
    label: 'July',
    value: 'july'
  },
  {
    label: 'August',
    value: 'august'
  },
  {
    label: 'September',
    value: 'september'
  },
  {
    label: 'October',
    value: 'october'
  },
  {
    label: 'November',
    value: 'november'
  },
  {
    label: 'December',
    value: 'december'
  }
];
