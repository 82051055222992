import { ReportsContainer } from '../../features/reports/ReportsContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const ReportsPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Reports'
  })
)(ReportsContainer);
