import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_EMPLOYEE_DETAILS,
  ROUTE_MAKE_UP_APPOINTMENTS_CREATE
} from '../../constants/route.paths';
import { useEmployeeDetail } from '../../core/hooks/useEmployeeDetail';
import { useLayout } from '../../core/hooks/useLayout';
import { AppointmentDto } from '../../core/http';
import { PageHeader } from '../../components/page-header';
import { Badge } from '../../components/badge';
import { Button, Link } from '@mui/material';
import { Alert } from '../../components/alert/Alert';
import { AppointmentView } from '../appointments/AppointmentView';
import { useAppointmentDetail } from '../../core/hooks/useAppointmentDetail';
import { PermissionView } from '../../components/permission-view';

export const EmployeeAppointmentView = () => {
  const { employeeId, uuid } = useParams();
  const navigate = useNavigate();
  const backUrl =
    url(ROUTE_EMPLOYEE_DETAILS, { id: employeeId, tab: 'dashboard' }) +
    '#appointments';

  const { data: employee } = useEmployeeDetail({
    uuid: employeeId
  });
  const { data: appointment, isLoading } = useAppointmentDetail({
    uuid
  });

  useLayout({
    backButton: {
      title: employee?.displayName || '',
      path: backUrl
    }
  });

  const handleMakeUp = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_MAKE_UP_APPOINTMENTS_CREATE, {
        uuid: appointment.uuid,
        employeeId
      })
    );
  };

  return (
    <>
      <PageHeader
        title="Appointment"
        meta={
          <>
            {employee?.id && (
              <Badge label={employee?.id} variant={'secondary'} />
            )}
            {appointment?.status && (
              <Badge
                label={appointment?.status}
                variant={appointment?.status}
              />
            )}
          </>
        }
      >
        {appointment?.canMakeUp && (
          <PermissionView permission="modifyAppointments">
            <Button
              color="secondary"
              onClick={() => handleMakeUp(appointment)}
              disabled={isLoading}
            >
              Make Up
            </Button>
          </PermissionView>
        )}
      </PageHeader>

      {appointment && (
        <Alert mb={1.25}>
          This appointment is for 2:30 Hour(s) and occurs weekly on MON,WED,FRI
          02/24/2021 through 01/06/2023.
          <Link variant="body2" color="primary" underline="none">
            Recurring Details
          </Link>
        </Alert>
      )}

      <AppointmentView appointment={appointment} />
    </>
  );
};
