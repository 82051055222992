import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { ClientCareTeamMembersDto } from '../dto/client-care-team-members.dto';

export class ClientCareTeamMembersApi extends BaseApi {
  /**
   * Find all
   */
  public findAll({
    clientUuid,
    ...params
  }): Promise<FindAllResponse<ClientCareTeamMembersDto>> {
    return this.httpClient
      .get(`/api/v1/clients/${clientUuid}/care-team`, {
        params
      })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ClientCareTeamMembersDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(
    clientUuid: string,
    uuid: string
  ): Promise<ClientCareTeamMembersDto> {
    return this.httpClient
      .get(`/api/v1/clients/${clientUuid}/care-team/${uuid}`, {
        pathParams: { uuid }
      })
      .then(({ data }) => {
        return plainToClass(ClientCareTeamMembersDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<ClientCareTeamMembersDto> {
    return this.httpClient
      .post(`/api/v1/clients/${data.clientUuid}/care-team`, data)
      .then(({ data }) => {
        return plainToClass(ClientCareTeamMembersDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<ClientCareTeamMembersDto> {
    return this.httpClient
      .put(`/api/v1/clients/${data.clientUuid}/care-team`, data)
      .then(({ data }) => {
        return plainToClass(ClientCareTeamMembersDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(params, uuid): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/clients/${params.clientUuid}/care-team/${uuid}`
    );
  }
}
