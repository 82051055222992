import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { ServiceCodeDto } from '../../../core/http';
import { useServiceCode } from '../../../core/hooks/useServiceCode';

export const ServiceCodeField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useServiceCode();
  const selectItems = map(items, (item: ServiceCodeDto) => ({
    value: item.uuid,
    label: `${item.code} - ${item.description}`
  }));

  return <SelectField value={value || ''} items={selectItems} {...props} />;
};
