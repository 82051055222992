import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { ServiceTypeDto } from '../../../core/http';
import { useServiceType } from '../../../core/hooks/useServiceType';

export const ServiceTypeField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useServiceType();
  const sortedItems = [...items].sort((a, b) => (a.title > b.title ? 1 : -1));
  const selectItems = map(sortedItems, (item: ServiceTypeDto) => ({
    value: item.uuid,
    label: item.title
  }));

  return <SelectField value={value || ''} items={selectItems} {...props} />;
};
