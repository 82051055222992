import { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TableView } from '../../../components/table-view';
import { RadioGroup } from '../../../components/fields/radio-group';
import { CalendarView } from '../../../components/calendar-view/CalendarView';
import { AppointmentCalendarCard } from '../../../components/appointment-calendar-card';
import { AppointmentCalendarDrawerCard } from '../../../components/appointment-calendar-drawer-card';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { AppointmentDto } from '../../../core/http';
import { get } from 'lodash';
import {
  calendarStatusFilterItems,
  calendarViewTypeItems
} from '../../../constants/app.constants';
import { useAppointmentList } from '../../../core/hooks/useAppointmentList';
import { AppointmentActionButtons } from '../../../components/appointment-action-buttons/appointment-action-buttons';
import { AppointmentDrawerActions } from '../../../components/appointment-drawer-actions';
import { clientAppointmentColumns } from '../../../constants/columns';
import { PermissionView } from '../../../components/permission-view';
import { AddNewAppointmentDialog } from '../../appointments/dialogs/AddNewAppointmentDialog';
import { useDialog } from '../../../core/hooks/useDialog';

export const Appointments = ({ status }) => {
  const { id } = useParams();
  const [openAddAppointment] = useDialog(AddNewAppointmentDialog);
  const [currentView, setCurrentView] = useState<'calendar' | 'table'>(
    'calendar'
  );

  const {
    data: appointmentResponse,
    isLoading,
    pagination,
    handleChangePerPage,
    handleChangePage,
    handleChangeSort,
    handleStatusFilter,
    handleCancel,
    handleDelete,
    handleConvert,
    handleClientView: handleView,
    handleClientEdit: handleEdit,
    handleClientMakeUp: handleMakeUp,
    startDate,
    setStartDate
  } = useAppointmentList({
    clientId: id,
    defaultParams: {
      'filter[client.uuid][eq]': id,
      limit: 20
    }
  });

  const isDisabled = status === 'terminated';
  const data = get(appointmentResponse, 'items', []);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Stack direction="row" spacing={3}>
          <PermissionView permission="modifyAppointments">
            <Button
              disabled={isDisabled}
              onClick={() => openAddAppointment({ clientUuid: id })}
            >
              Add new
            </Button>
          </PermissionView>
          <RadioGroup
            items={calendarViewTypeItems}
            defaultValue="calendar"
            onChange={(view) => {
              setCurrentView(view as 'calendar' | 'table');
            }}
          />
          <RadioGroup
            items={calendarStatusFilterItems}
            defaultValue="all"
            onChange={handleStatusFilter}
          />
        </Stack>
        <WeekRangePicker
          withToday
          onChange={(startDate) => {
            setStartDate(startDate);
          }}
        />
      </Stack>
      <Box>
        {currentView === 'calendar' ? (
          <CalendarView
            subtractHeight={316}
            startDate={startDate}
            groupKey="startDate"
            dataSource={data}
            renderItem={(item: AppointmentDto, _, selectedItem) => {
              return (
                <AppointmentCalendarCard
                  item={item}
                  selected={selectedItem?.uuid === item.uuid}
                />
              );
            }}
            renderDrawer={(item: AppointmentDto) => (
              <AppointmentCalendarDrawerCard item={item} />
            )}
            renderDrawerFooter={(appointment: AppointmentDto) => (
              <AppointmentDrawerActions
                {...{
                  appointment,
                  handleConvert,
                  handleDelete,
                  handleEdit,
                  handleCancel,
                  handleView,
                  handleMakeUp
                }}
              />
            )}
          />
        ) : (
          <TableView
            showEmptyState
            showTablePerPage
            showPagination
            loading={isLoading}
            onChangePage={handleChangePage}
            pagination={pagination}
            onChangePerPage={handleChangePerPage}
            onChangeSort={handleChangeSort}
            EmptyStateProps={{
              title: 'Appointments has not been found'
            }}
            dataSource={data}
            keyExtractor={(item) => item.uuid}
            columns={[
              ...clientAppointmentColumns,
              {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (_, appointment: AppointmentDto) => (
                  <AppointmentActionButtons
                    {...{
                      appointment,
                      handleConvert,
                      handleDelete,
                      handleEdit,
                      handleCancel,
                      handleView,
                      handleMakeUp
                    }}
                  />
                )
              }
            ]}
          />
        )}
      </Box>
    </>
  );
};
