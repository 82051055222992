import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Stack
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { IconCalendar } from '../../../components/icons';
import { DatePicker, SearchField } from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { historyColumns } from '../_mockdata';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientChangesApi } from '../../../core/http/requests/client-changes.api';
import { get } from 'lodash';
import { useFilter } from '../../../core/hooks/useFilter';
import { ActiveEmployeeAutocompleteField } from '../../../components/fields/active-employee-autocomplete-field';

interface Props extends DialogProps {
  onClose?: () => void;
}

const clientChangesApi = new ClientChangesApi();

export const ChangesHistoryDialog: ComponentType<Props> = ({ popDialog }) => {
  const {
    data,
    pagination,
    isLoading,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(clientChangesApi);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    initialValue: {
      'filter[createdAt][gt]': null,
      'filter[createdAt][lt]': null
    },
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          minHeight: 'calc(100vh - 64px)',
          maxWidth: 'unset',
          width: 1180
        },
        '& .MuiOutlinedInput-root ': {
          height: 32
        }
      }}
    >
      <DialogTitle>Changes history</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            borderRadius: 0.5,
            borderColor: '#ECF0F6',
            borderWidth: 1,
            borderStyle: 'solid'
          }}
          px={2}
          py={2}
          mb={4}
        >
          <Stack direction="row" spacing={1}>
            <Box>
              <DatePicker
                disableInput
                Icon={<IconCalendar />}
                label="Date From"
                helperText={null}
                value={getFilterValue('filter[createdAt][gt]')}
                onChange={setFilterValue('filter[createdAt][gt]')}
              />
            </Box>
            <Box>
              <DatePicker
                disableInput
                Icon={<IconCalendar />}
                label="Date To"
                helperText={null}
                value={getFilterValue('filter[createdAt][lt]')}
                onChange={setFilterValue('filter[createdAt][lt]')}
              />
            </Box>

            <ActiveEmployeeAutocompleteField
              label="Employee"
              helperText={null}
              size="small"
              placeholder="Search"
              value={getFilterValue('filter[editor][eq]')}
              onChange={setFilterValue('filter[editor][eq]')}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}
            >
              <Button onClick={handleSearch}>Search</Button>
            </Box>
          </Stack>
        </Box>
        <TableView
          loading={isLoading}
          dataSource={get(data, 'items', [])}
          columns={historyColumns}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={false}
          showPagination={true}
          pagination={pagination}
          keyExtractor={(item: { createdAt: string }) =>
            `${Math.random()}${item.createdAt}`
          }
          showEmptyState
          EmptyStateProps={{
            title: 'No client changes found'
          }}
        />
        <Box pb={4} />
      </DialogContent>
    </Dialog>
  );
};
