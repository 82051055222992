import { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import {
  EmployeeField,
  FundingSourceField,
  ServiceCodeMultipleField,
  TextField
} from '../../../components/fields';
import { ClientCareTeamMembersApi } from '../../../core/http/requests/client-care-team-members.api';
import { useCareTeamMembersEdit } from '../../../core/hooks/useCareTeamMemberEdit';
import { get, map } from 'lodash';
import { ClientCareTeamMembersDto } from '../../../core/http/dto/client-care-team-members.dto';
import { useSelector } from 'react-redux';
import { AppState } from '../../../core/store/store';
import { ActiveEmployeeAutocompleteField } from '../../../components/fields/active-employee-autocomplete-field';
import { useActiveEmployee } from '../../../core/hooks/useActiveEmployee';

const clientCareTeamMemberApi = new ClientCareTeamMembersApi();

interface EditDialogProps extends DialogProps {
  uuid?: string;
  clientUuid?: string;
  onSuccess?: () => void;
  popDialog?: () => void;
  onError?: (error) => void;
  data?: ClientCareTeamMembersDto;
}

export const AddNewMemberDialog: FC<EditDialogProps> = ({
  popDialog,
  onSuccess,
  onError,
  clientUuid,
  data
}) => {
  const { items: employees } = useActiveEmployee();
  const {
    isLoading,
    values,
    handleChange,
    getFieldTouchedError,
    handleBlur,
    handleSubmit,
    isEdit,
    setFieldValue
  } = useCareTeamMembersEdit(clientCareTeamMemberApi, {
    uuid: data?.uuid,
    clientUuid: data?.client?.uuid || clientUuid,
    normalizeSetValues: (values) => {
      return {
        clientUuid: clientUuid || values?.client?.uuid,
        employeeUuid: values?.employee?.uuid,
        title: values?.employee?.title?.title,
        profitCenter: values?.employee?.profitCenter?.officeName,
        fundingSourceUuid: values?.fundingSource.uuid,
        serviceCodes: map(values?.serviceCodes, 'uuid')
      };
    },
    onSuccess,
    onError
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 800 } }}
    >
      <DialogTitle> {isEdit ? 'Edit Member' : 'Add New Member'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} mb={3.5}>
          <Grid item md={4}>
            <ActiveEmployeeAutocompleteField
              label="Employee Name"
              helperText={null}
              size="medium"
              disabled={isLoading}
              placeholder="Select"
              value={values.employeeUuid}
              onChange={handleChange('employeeUuid')}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Title"
              placeholder={
                employees.find((item) => item.uuid === values.employeeUuid)
                  ?.title ||
                employees.find((item) => item.uuid === values.employeeUuid)
                  ?.titleCustom ||
                'Not set'
              }
              disabled
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Profit Center"
              placeholder={
                employees.find((item) => item.uuid === values.employeeUuid)
                  ?.profitCenter?.profitCentre || 'Not set'
              }
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <FundingSourceField
              label="Funding Source"
              placeholder="Select"
              required
              disabled={isLoading}
              value={values.fundingSourceUuid}
              onChange={handleChange('fundingSourceUuid')}
              onBlur={handleBlur('fundingSourceUuid')}
              errors={getFieldTouchedError('fundingSourceUuid')}
            />
          </Grid>
          <Grid item md={8}>
            <ServiceCodeMultipleField
              label="Service code"
              placeholder="Select"
              wrappable
              required
              disabled={isLoading}
              value={values.serviceCodes}
              onChange={(value) => setFieldValue('serviceCodes', value)}
              onBlur={handleBlur('serviceCodes')}
              errors={getFieldTouchedError('serviceCodes')}
              helperText={getFieldTouchedError('serviceCodes')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
