import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { ClientLocationsApi } from '../http/requests/client-location.api';

const clientLocationsApi = new ClientLocationsApi();

export const useClientLocations = (clientUuid) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (clientUuid) {
      clientLocationsApi
        .findAll({ clientUuid })
        .then((response) => {
          setData(response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [clientUuid]);

  return {
    items: get(data, 'items', []),
    loading
  };
};
