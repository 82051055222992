import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { IconArrowDown } from '../../icons';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { useServiceCode } from '../../../core/hooks/useServiceCode';
import { normalizeDate } from '../../../core/utils/data.utils';
import { Badge } from '../../badge';
import { Checkbox } from '../checkbox';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import useStyles from '../select-field/styles';
import { TextField } from '../text-field';
import { getDay } from 'date-fns';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: (values: string[]) => void;
  onBlur?: (value: string) => void;
  value?: string[];
  errors?: any;
  size?: 'small' | 'medium';
  helperText?: string | null;
  required?: boolean;
  disabled?: boolean;
  wrappable?: boolean;
}

export const ServiceCodeMultipleField: FC<Props> = ({
  label,
  placeholder,
  onChange,
  errors,
  value = [],
  helperText,
  size,
  required = false,
  wrappable,
  ...props
}) => {
  const { items, itemsLoading } = useServiceCode();
  const itemsByUuid = keyBy(items, 'uuid');
  const classes = useStyles();

  const handleChange = (e: SelectChangeEvent) => {
    onChange && onChange(e.target.value as any);
  };

  const handleDelete = (uuid: string) => () => {
    const filteredValues = value.filter((v: string) => v !== uuid);
    onChange && onChange(filteredValues);
  };

  const chipLabel = (uuid: string) => {
    const item = get(itemsByUuid, uuid, {});
    return `${item.code} -  ${item.description}`;
  };

  const sortedItems = [...items].sort((a, b) => (a.code > b.code ? 1 : -1));

  return (
    <TextField
      disabled={itemsLoading}
      required={required}
      errors={!!errors}
      helperText={errors || helperText}
      select
      {...{ label, value, size, ...props }}
      classes={{ root: classes.root }}
      InputLabelProps={{
        shrink: true
      }}
      SelectProps={{
        IconComponent: IconArrowDown,
        multiple: true,
        onChange: handleChange,
        renderValue: (selected: string[]) => {
          if (selected.length === 0) {
            return <>{placeholder}</>;
          }
          return (
            <Box
              className={
                wrappable
                  ? classes.chipContainerWrappable
                  : classes.chipContainer
              }
            >
              {selected.map((uuid: string) => (
                <Chip
                  key={uuid}
                  label={chipLabel(uuid)}
                  size="small"
                  color="primary"
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={handleDelete(uuid)}
                />
              ))}
            </Box>
          );
        }
      }}
    >
      {sortedItems.map((option) => {
        const createdDate = getDay(new Date(option.createdAt));
        const updatedDate = getDay(new Date(option.updatedAt));
        const daysRemaining = updatedDate - createdDate;
        return (
          <MenuItem
            key={option.uuid}
            value={option.uuid}
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected
            }}
          >
            <Stack direction="row" spacing={1}>
              <Checkbox checked={value.includes(option.uuid)} />
              <Typography
                variant="body3"
                sx={{
                  maxWidth: '550px',
                  marginRight: '8px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {[
                  option.code,
                  option.description,
                  normalizeDate(option.createdAt) +
                    '-' +
                    normalizeDate(option.updatedAt)
                ]
                  .filter(Boolean)
                  .join(' - ')}
              </Typography>
              <Badge
                label={`${daysRemaining} days remaining`}
                variant="warning"
                size="small"
                textUppercase={false}
              />
            </Stack>
          </MenuItem>
        );
      })}
    </TextField>
  );
};
