import { filter, get, reduce } from 'lodash';
import {
  addHours,
  format,
  isAfter,
  isBefore,
  parse,
  parseISO,
  subHours
} from 'date-fns';
import { arrayFromLength } from '../../core/utils/data.utils';
import {
  DEFAULT_CELL_HEIGHT,
  GROUP_DATE_FORMAT,
  GROUP_HOUR_FORMAT,
  HOUR_FORMAT,
  ITEM_INFO_HEIGHT
} from './constants';
import { FULL_DATE_FORMAT } from '../../constants/app.constants';

export const calculatePositions = (
  groupedByHour: any,
  startDate: Date,
  startHour: number,
  hours: number
) => {
  const calculatedHeight = reduce(
    groupedByHour,
    (acc, items, dayWithHour) => {
      const itemsHeight = reduce(
        items,
        (acc, item: any) =>
          acc + (item?.endDate ? DEFAULT_CELL_HEIGHT : ITEM_INFO_HEIGHT),
        0
      );

      const hourKey = format(
        parse(dayWithHour, GROUP_HOUR_FORMAT, new Date()),
        HOUR_FORMAT
      );
      const hourValue = itemsHeight > DEFAULT_CELL_HEIGHT ? itemsHeight : 0;
      const preHourValue = get(acc, hourKey, 0);

      if (preHourValue > hourValue) {
        return { ...acc, [hourKey]: preHourValue };
      }
      return {
        ...acc,
        [hourKey]: hourValue
      };
    },
    {}
  );

  const oppositeHeight = reduce(
    calculatedHeight,
    (acc, value) => {
      return value > 0 ? acc + value : acc + DEFAULT_CELL_HEIGHT;
    },
    0
  );

  const totalHeight =
    oppositeHeight +
    (hours - Object.keys(calculatedHeight).length) * DEFAULT_CELL_HEIGHT;

  const positions = reduce(
    arrayFromLength(hours),
    (acc, _, index) => {
      const currentHour = addHours(startDate, startHour + index);
      const prevHour = addHours(startDate, startHour + index - 1);
      const currentHourKey = format(currentHour, HOUR_FORMAT);
      const prevHourKey = format(prevHour, HOUR_FORMAT);
      const currentHourValue =
        get(calculatedHeight, currentHourKey) || DEFAULT_CELL_HEIGHT;
      const prevHourValue = get(acc, prevHourKey, 0);

      const value = currentHourValue + prevHourValue;

      return { ...acc, [currentHourKey]: value };
    },
    {}
  );

  return { positions, calculatedHeight, totalHeight };
};

export const heightByStartAndEnd = (positions: any, start: any, end: any) => {
  const startDate = subHours(parseISO(start), 1);
  const endDate = subHours(parseISO(end), 1);
  const startPosition = get(positions, format(startDate, HOUR_FORMAT), 0);
  const endPosition = get(positions, format(endDate, HOUR_FORMAT));

  return endPosition - startPosition;
};

export const groupItems = (dataSource: any[], groupKey: string) => {
  return reduce(
    dataSource,
    (acc, value) => {
      const dateValue = get(value, groupKey);
      const date = new Date(
        format(new Date(dateValue.replace('Z', '')), FULL_DATE_FORMAT)
      );
      const dateKey = format(date, GROUP_DATE_FORMAT);
      const hourKey = format(date, GROUP_HOUR_FORMAT);

      const dateValues = get(acc[0], dateKey, 0);
      const hourValues = get(acc[1], hourKey, []);

      return [
        {
          ...acc[0],
          [dateKey]: dateValues + 1
        },
        {
          ...acc[1],
          [hourKey]: [...hourValues, value]
        }
      ];
    },
    [{}, {}]
  );
};

export const excludeOldItems = (
  startDate: Date,
  endDate: Date,
  dataSource: any[],
  groupKey: string
) => {
  return filter(dataSource, (item) => {
    const dateValue = get(item, groupKey);
    const date = new Date(
      format(new Date(dateValue.replace('Z', '')), FULL_DATE_FORMAT)
    );
    return (
      isAfter(date, startDate) && isBefore(date, endDate) && isAfter8AM(date)
    );
  });
};

const isAfter8AM = (date: Date) => {
  return date.getHours() >= 8;
};
