import { FC } from 'react';
import { Box, Button, Stack, IconButton, Tooltip } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { careTeamMembersColumns } from '../_mockdata';
import { SearchField } from '../../../components/fields';
import { IconPen, IconTrash } from '../../../components/icons';
import { useDialog } from '../../../core/hooks/useDialog';
import { AddNewMemberDialog } from '../dialogs/AddNewMemberDialog';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { ClientCareTeamMembersApi } from '../../../core/http/requests/client-care-team-members.api';
import { useListResource } from '../../../core/hooks/useListResource';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { PermissionView } from '../../../components/permission-view';

const clientCareTeamMembersApi = new ClientCareTeamMembersApi();

interface Props {
  status: string;
}

export const CareTeamMembers: FC<Props> = ({ status }) => {
  const [openCreateMemberDialog, closeCreateMemberDialog] =
    useDialog(AddNewMemberDialog);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const { id } = useParams();

  const {
    data,
    isLoading,
    handleChangePage,
    handleChangePerPage,
    handleChangeSort,
    handleChangeParams,
    pagination,
    fetchData
  } = useListResource(clientCareTeamMembersApi, {
    defaultParams: { clientUuid: id }
  });

  const isDisabled = isLoading || status === 'terminated';
  const handleOpenEdit = (data) => () => {
    if (isDisabled) {
      showMessage(
        'Sorry, employee or client of this member has been deleted, you can not edit this member.',
        'warning'
      );
      return;
    }
    openCreateMemberDialog({
      data,
      onSuccess: () => {
        closeCreateMemberDialog();
        fetchData();
        showMessage('Member was successfully updated');
      },
      onError: () => {
        closeCreateMemberDialog();
        showMessage('Something went wrong please try again', 'error');
      }
    });
  };
  const handleDelete = (uuid: string, fullName: string) => () => {
    openConfirm({
      title: 'Delete',
      message: `Do you really want to delete care team member ${fullName}?`,
      onConfirm: () => {
        clientCareTeamMembersApi
          .delete({ clientUuid: id }, uuid)
          .then(() => {
            closeConfirm();
            showMessage(`Member ${fullName} was successfully deleted.`);
            fetchData();
          })
          .catch(() =>
            showMessage('Something went wrong, please try again later', 'error')
          );
      }
    });
  };

  const handleCreateNewMember = () => {
    openCreateMemberDialog({
      clientUuid: id,
      onError: () => {
        closeCreateMemberDialog();
        showMessage('Something went wrong please try again later.', 'error');
      },
      onSuccess: () => {
        closeCreateMemberDialog();
        fetchData();
        showMessage('Member was successfully created');
      }
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyClients">
          <Button onClick={() => handleCreateNewMember()} disabled={isDisabled}>
            Add new
          </Button>
        </PermissionView>
        <SearchField
          sx={{ width: 400 }}
          size="small"
          placeholder="Search"
          onChange={(value) => {
            handleChangeParams('search', value || null);
          }}
        />
      </Stack>
      <Box>
        <TableView
          loading={isLoading}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={true}
          showPagination={true}
          pagination={pagination}
          showEmptyState
          EmptyStateProps={{
            title: 'You dont have any care team members yet.',
            description: null,
            action: (
              <Button
                onClick={() => handleCreateNewMember()}
                disabled={isDisabled}
              >
                Add new
              </Button>
            )
          }}
          keyExtractor={(item: { id: string }) => item.id}
          dataSource={get(data, 'items') || []}
          hideColumns={['therapist']}
          columns={[
            ...careTeamMembersColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              cellProps: {
                width: 50
              },
              render: (_, record) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <Tooltip title="Edit" placement="bottom-end">
                    <IconButton size="small" onClick={handleOpenEdit(record)}>
                      <IconPen />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="bottom-end">
                    <IconButton
                      size="small"
                      onClick={handleDelete(
                        record.uuid,
                        record.employee.firstName +
                          ' ' +
                          record.employee.lastName
                      )}
                    >
                      <IconTrash />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
