import { Button, Grid, IconButton, Stack } from '@mui/material';
import { IconPen, IconTrash } from '../../components/icons';
import { TableView } from '../../components/table-view';
import { forClientsColumn } from './_mockdata';
import { Link } from 'react-router-dom';
import { url } from '../../core';
import { ROUTE_EDIT_CLIENT_REMINDER } from '../../constants/route.paths';
import { ClientAutocompleteField, DatePicker } from '../../components/fields';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useListResource } from '../../core/hooks/useListResource';
import { ClientReminderApi } from '../../core/http';
import get from 'lodash/get';
import { useFilter } from '../../core/hooks/useFilter';
import { PermissionView } from '../../components/permission-view';
import { ActiveClientAutocompleteField } from '../../components/fields/active-client-autocomplete-field';

const clientReminderApi = new ClientReminderApi();

export const ForClients = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { handleSearch, getFilterFieldProps } = useFilter({
    initialValue: {
      'filter[dueDate][eq]': null
    },
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });
  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(clientReminderApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  return (
    <>
      <Grid container spacing={1} mb={3}>
        <Grid item md={10}>
          <ActiveClientAutocompleteField
            label="Client"
            placeholder="Search"
            size="small"
            helperText={null}
            {...getFilterFieldProps('filter[clientUuid][eq]')}
          />
        </Grid>
        <Grid item md={2}>
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <DatePicker
              label="Due Date"
              size="small"
              helperText={null}
              {...getFilterFieldProps('filter[dueDate][eq]')}
            />
            <Button onClick={handleSearch}>Search</Button>
          </Stack>
        </Grid>
      </Grid>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...forClientsColumn,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, record) => (
              <Stack direction="row" alignItems="center">
                <PermissionView permission="modifyOtherEmployeeReminders">
                  <IconButton
                    size="small"
                    component={Link}
                    to={url(ROUTE_EDIT_CLIENT_REMINDER, { id: record.uuid })}
                  >
                    <IconPen />
                  </IconButton>
                </PermissionView>
                <PermissionView permission="modifyOtherEmployeeReminders">
                  <IconButton size="small" onClick={handleDelete(record.uuid)}>
                    <IconTrash />
                  </IconButton>
                </PermissionView>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
