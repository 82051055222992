import { plainToClass } from 'class-transformer';
import {
  API_TIMESHEET_FIND_ALL,
  API_TIMESHEET_FIND_ONE,
  API_TIMESHEET_CREATE,
  API_TIMESHEET_DELETE,
  API_TIMESHEET_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { TimesheetDto } from '../dto';
import { FindAllResponse } from '../types';

export class TimesheetApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<TimesheetDto>> {
    return this.httpClient
      .get(API_TIMESHEET_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(TimesheetDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<TimesheetDto> {
    return this.httpClient
      .get(API_TIMESHEET_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(TimesheetDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<TimesheetDto> {
    return this.httpClient.post(API_TIMESHEET_CREATE, data).then(({ data }) => {
      return plainToClass(TimesheetDto, data);
    });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<TimesheetDto> {
    return this.httpClient.put(API_TIMESHEET_UPDATE, data).then(({ data }) => {
      return plainToClass(TimesheetDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_TIMESHEET_DELETE, {
      pathParams: { uuid }
    });
  }
}
