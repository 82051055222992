import { FC } from 'react';
import map from 'lodash/map';
import { Box, BoxProps, Divider, Stack, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

interface Props {
  items: { label: string; value: any }[];
  WrapProps?: BoxProps;
  LabelProps?: TypographyProps;
  ValueProps?: TypographyProps;
}

export const LabeledList: FC<Props> = ({
  items,
  WrapProps,
  LabelProps,
  ValueProps
}) => {
  return (
    <Box width={300} ml={10} {...WrapProps}>
      {map(items, ({ label, value }) => (
        <>
          <Stack direction="row" alignItems="center" py={1.25}>
            <Typography variant="body3" width={230} {...LabelProps}>
              {label}
            </Typography>
            <Typography variant="body3" fontWeight={500} {...ValueProps}>
              {value}
            </Typography>
          </Stack>
          <Divider />
        </>
      ))}
    </Box>
  );
};
