import { PageHeader } from '../../components/page-header';
import { Box, Button } from '@mui/material';
import { Tabs } from '../../components/tabs';
import { ServiceAuthorizations } from './tabs/ServiceAuthorizations';
import { TimeCards } from './tabs/TimeCards';
import { Billing } from './tabs/Billing';
import { Cancellations } from './tabs/Cancellations';
import { SARCCancellations } from './tabs/SARCCancellations';
import { CaseLoadTracker } from './tabs/CaseLoadTracker';
import { ClientAppointments } from './tabs/ClientAppointments';

export const ReportsContainer = () => {
  return (
    <>
      <PageHeader title="Reports" divider={false}>
        <Button>Export</Button>
      </PageHeader>
      <Box>
        <Tabs
          hashed
          items={[
            {
              key: 'service-authorizations',
              label: 'Service Authorizations',
              content: <ServiceAuthorizations />
            },
            {
              key: 'time-cards',
              label: 'Time Cards',
              content: <TimeCards />
            },
            {
              key: 'billing',
              label: 'Billing',
              content: <Billing />
            },
            {
              key: 'cancellations',
              label: 'Cancellations',
              content: <Cancellations />
            },
            {
              key: 'sarc-cancellations',
              label: 'SARC Cancellations',
              content: <SARCCancellations />
            },
            {
              key: 'case-load-tracker',
              label: 'Case Load Tracker',
              content: <CaseLoadTracker />
            },
            {
              key: 'client-appointments',
              label: 'Client Appointments',
              content: <ClientAppointments />
            }
          ]}
        />
      </Box>
    </>
  );
};
