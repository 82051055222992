import { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Pagination,
  PaginationItem,
  Stack
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogProps } from '../../core/providers/dialog.provider';
import { notificationData } from './_mockdata';
import map from 'lodash/map';
import { NotificationCard } from './NotificationCard';
import { TablePerPage } from '../../components/table-view/table-per-page';
import { useTablePerPage } from '../../components/table-view/useTablePerPage';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const NotificationDialog: FC<Props> = ({ popDialog }) => {
  const notifications = notificationData.slice(0, 7);

  const { perPage, onChange: onChangePerPage, items } = useTablePerPage();

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 900 } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box>Notifications</Box>
          <Stack spacing={1} direction="row">
            <Button color="secondary">Clear all</Button>
            <Button color="secondary" onClick={popDialog}>
              Close
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {map(notifications, (item, key) => (
          <NotificationCard key={key} readOpacity {...{ item }} />
        ))}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Pagination
            count={10}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: () => <span>Prev</span>,
                  next: () => <span>Next</span>
                }}
                {...item}
              />
            )}
          />
          <TablePerPage {...{ perPage, onChange: onChangePerPage, items }} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
