import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Card } from '../../../components/cards';
import { TextField } from '../../../components/fields';

export const CodeFormTemplateDialog = ({ popDialog }) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 900
        }
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>805 Codes Form Template</DialogTitle>
      <DialogContent>
        <Box bgcolor="#F6F9FF" p={2} borderRadius={0.5} mb={2}>
          <Stack direction="row">
            <Box flex="1 1 50%">
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={146}>
                  Appointment Type:
                </Typography>
                <Typography variant="caption">Direct Therapy</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={146}>
                  Client Name:
                </Typography>
                <Typography variant="caption">Debra Lane</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={146}>
                  Client Contact Number:
                </Typography>
                <Typography variant="caption">(321) 321-4323</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={146}>
                  Client Address:
                </Typography>
                <Typography variant="caption">
                  6391 Elgin St. Celina, Delaware 10299
                </Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={146}>
                  Type of Service:
                </Typography>
                <Typography variant="caption">
                  6910 Santa Teresa Blvd. STE 200
                </Typography>
              </Stack>
            </Box>
            <Box flex="1 1 50%">
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={166}>
                  Appointment Date/Time:
                </Typography>
                <Typography variant="caption">03/10/2022 12:45</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={166}>
                  Client DOB:
                </Typography>
                <Typography variant="caption">12/22/1987</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={166}>
                  Insurance:
                </Typography>
                <Typography variant="caption">SARC</Typography>
              </Stack>
              <Stack direction="row" flexWrap="wrap" mb={1}>
                <Typography variant="caption" color="#647593" width={166}>
                  Therapist:
                </Typography>
                <Typography variant="caption">Lana Webber</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Card title="Parent Report/Check in" mb={1} HeaderProps={{ mb: 0.5 }}>
          <Typography variant="body3" mb={2} component="div">
            Add subjective information reported by the parent/caregiver; What
            did the parent report during session; Parent feedback about
            education provided during previous session(s). Family schedule or
            environment changes; Medical changes; etc.
          </Typography>
          <TextField label="Type your parent report" multiline rows={4} />
        </Card>
        <Card
          title="Observations from session"
          mb={1}
          HeaderProps={{ mb: 0.5 }}
        >
          <Typography variant="body3" mb={2} component="div">
            Observable,measurable, people participating in session, etc.
          </Typography>
          <TextField label="Type your observation" multiline rows={4} />
        </Card>
        <Card
          title="Parent Education Provided in Session"
          mb={1}
          HeaderProps={{ mb: 0.5 }}
        >
          <Typography variant="body3" mb={2} component="div">
            Specific strategies suggested, handouts provided, programs targeted,
            modifications made to previous strategies, etc.
          </Typography>
          <TextField label="Provided Parent Education" multiline rows={4} />
        </Card>
        <Card title="Plan for next session and/or updates for the team" mb={0}>
          <TextField label="Plan for next session" multiline rows={4} />
        </Card>
      </DialogContent>
      <DialogActions sx={{ border: 'none', pt: 2 }}>
        <Button color="third" onClick={() => popDialog()}>
          Cancel
        </Button>
        <Button onClick={() => popDialog()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
