import { makeStyles } from '@mui/styles';
import waves from '../../assets/waves.png';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'radial-gradient(115.93% 96.4% at 86.99% 22.26%, #0052CC 0%, #7200CC 100%)'
  },
  waves: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${waves})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  spinnerBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 78
  },
  spinner: {
    width: 28,
    height: 28,
    animation: '$spin 2000ms infinite linear'
  },
  loadingText: {
    marginTop: 10
  },
  bottomInfoBar: {
    position: 'absolute',
    bottom: 40,
    justifyContent: 'center'
  },
  infoBarText: {
    cursor: 'default'
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' }
  }
}));
