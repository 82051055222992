import { DataTableColumn } from '../../components/table-view/TableView';
import { Status } from '../../components/status';
import { UserRecord } from '../../components/user-record';
import { Badge } from '../../components/badge';
import { addDays, setHours, startOfWeek } from 'date-fns';
import { Stack } from '@mui/material';
import { usPhoneNumber } from '../../core';
import get from 'lodash/get';
import { normalizeDate } from '../../core';

export const availabilityDataColumns: DataTableColumn[] = [
  {
    title: 'Scheduling',
    dataIndex: 'scheduling',
    key: 'scheduling'
  },
  {
    title: 'Teletherapy',
    dataIndex: 'teletherapyMode',
    key: 'teletherapyMode',
    render: (value) =>
      value === true ? 'YES' : typeof value === 'undefined' ? 'N/A' : 'NO'
  },
  {
    title: 'In-home',
    dataIndex: 'inHomeMode',
    key: 'inHomeMode',
    render: (value) =>
      value === true ? 'YES' : typeof value === 'undefined' ? 'N/A' : 'NO'
  },
  {
    title: 'Changed by',
    dataIndex: 'changedBy',
    key: 'changedBy'
  },
  {
    title: 'Changed Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    render: (value) => value || '-'
  }
];

export const availabilityData = [
  {
    scheduling:
      'MON,WED,FRI (From 12:00 AM to 12:05 AM - 02/03/2022 to 02/09/2022 )',
    teletherapy: 'YES',
    inHome: 'NO',
    changedDate: '01/28/2022 02:05 PM',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    notes: '-'
  },

  {
    scheduling:
      'MON,WED,FRI (From 12:00 AM to 12:05 AM - 02/03/2022 to 02/09/2022 )',
    teletherapy: 'NO',
    inHome: 'YES',
    changedDate: '01/28/2022 02:05 PM',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    notes: '-'
  }
];

export const upcomingAppointmentColumns: DataTableColumn[] = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sortable: true
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    sortable: true
  },

  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sortable: true
  }
];

export const appointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sortable: true
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    sortable: true
  },
  {
    title: 'First Name',
    dataIndex: 'client',
    key: 'firstName',
    render: (client) => get(client, 'firstName', '-')
  },
  {
    title: 'Last Name',
    dataIndex: 'client',
    key: 'lastName',
    render: (client) => get(client, 'lastName', '-')
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: ({ label, value }) => (
      <Badge
        label={label}
        variant={value}
        sx={{
          fontWeight: '500 !important'
        }}
      />
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  }
];

export const employeetAppointmentsData = [
  {
    id: 0,
    start: setHours(addDays(new Date(), -2), 8).toISOString(),
    end: setHours(addDays(new Date(), -2), 11).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 1,
    start: setHours(addDays(new Date(), 0), 9).toISOString(),
    end: setHours(addDays(new Date(), 0), 10).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Not Converted',
      type: 'warning'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 2,
    start: setHours(addDays(new Date(), 5), 13).toISOString(),
    end: setHours(addDays(new Date(), 5), 14).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Conflicted',
      type: 'error'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 3,
    start: setHours(addDays(new Date(), 6), 17).toISOString(),
    end: setHours(addDays(new Date(), 6), 18).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 4,
    start: setHours(addDays(new Date(), 0), 12).toISOString(),
    end: setHours(addDays(new Date(), 0), 13).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 5,
    start: setHours(addDays(new Date(), 1), 13).toISOString(),
    end: setHours(addDays(new Date(), 1), 14).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Confliced',
      type: 'error'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 6,
    start: setHours(addDays(new Date(), 1), 8).toISOString(),
    end: setHours(addDays(new Date(), 1), 9).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 7,
    start: setHours(addDays(new Date(), 4), 12).toISOString(),
    end: setHours(addDays(new Date(), 4), 13).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Not Converted',
      type: 'warning'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 9,
    start: setHours(addDays(new Date(), 6), 8).toISOString(),
    end: setHours(addDays(new Date(), 6), 9).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 10,
    start: setHours(addDays(new Date(), 4), 14).toISOString(),
    end: setHours(addDays(new Date(), 4), 15).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 11,
    start: setHours(addDays(new Date(), 4), 15).toISOString(),
    end: setHours(addDays(new Date(), 4), 16).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/12.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  }
];

export const filesColumns: DataTableColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true,
    cellProps: {
      sx: {
        width: 100
      }
    }
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName'
  },
  {
    title: 'Uploaded by',
    dataIndex: 'owner',
    key: 'uploadedBy',
    render: (owner) => (
      <UserRecord
        user={{
          name: owner.firstName + ' ' + owner.lastName,
          avatar: null
        }}
        color="common.dark"
      />
    ),

    cellProps: {
      sx: {
        width: 180
      }
    }
  },
  {
    title: 'Uploaded date',
    dataIndex: 'uploadedDate',
    key: 'uploadedDate',
    sortable: true,
    cellProps: {
      sx: {
        width: 200
      }
    }
  }
];

export const filesData = [
  {
    id: 57349,
    type: 'Certificate',
    fileName: 'Certificate 4382420349',
    expirationDate: '-',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 09:45 AM'
  },
  {
    id: 43933,
    type: 'License',
    fileName: 'Licence_43289',
    expirationDate: '12/22/2022',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 09:45 AM'
  }
];

export const clientsColumns: DataTableColumn[] = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => usPhoneNumber(phone)
  },
  {
    title: 'Funding Source',
    dataIndex: 'fundingSource',
    key: 'fundingSource'
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  },
  {
    title: 'Auth Start Date',
    dataIndex: 'authStartDate',
    key: 'authStartDate',
    sortable: true
  },
  {
    title: 'Auth End Date',
    dataIndex: 'authEndDate',
    key: 'authEndDate',
    sortable: true
  },
  {
    title: 'Authorized(h)',
    dataIndex: 'authorized',
    key: 'authorized',
    sortable: true
  },

  {
    title: 'Commited(h)',
    dataIndex: 'commited',
    key: 'commited',
    sortable: true
  },

  {
    title: 'Scheduled(h)',
    dataIndex: 'scheduled',
    key: 'scheduled',
    sortable: true
  }
];

export const clientsData = [
  {
    id: 1,
    firstName: 'Albert',
    lastName: 'Flores',
    phone: '(704) 555-0127',
    fundingSource: 'Company',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    authStartDate: '03/06/2022',
    authEndDate: '03/06/2022',
    authorized: '23',
    commited: '23',
    scheduled: '23'
  },
  {
    id: 2,
    firstName: 'Kathryn',
    lastName: 'Murphy',
    phone: '(704) 555-0127',
    fundingSource: 'Company',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    authStartDate: '03/06/2022',
    authEndDate: '03/06/2022',
    authorized: '23',
    commited: '23',
    scheduled: '23'
  },
  {
    id: 3,
    firstName: 'Arlene',
    lastName: 'McCopy',
    phone: '(704) 555-0127',
    fundingSource: 'Company',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    authStartDate: '03/06/2022',
    authEndDate: '03/06/2022',
    authorized: '23',
    commited: '23',
    scheduled: '23'
  },
  {
    id: 4,
    firstName: 'Jerome',
    lastName: 'Bell',
    phone: '(704) 555-0127',
    fundingSource: 'Company',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    authStartDate: '03/06/2022',
    authEndDate: '03/06/2022',
    authorized: '23',
    commited: '23',
    scheduled: '23'
  },
  {
    id: 5,
    firstName: 'Floyd',
    lastName: 'Miles',
    phone: '(704) 555-0127',
    fundingSource: 'Company',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    authStartDate: '03/06/2022',
    authEndDate: '03/06/2022',
    authorized: '23',
    commited: '23',
    scheduled: '23'
  }
];

export const authExpiringData = [
  {
    id: 0,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '03/06/2022'
  },

  {
    id: 1,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '04/06/2023'
  },
  {
    id: 2,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '20/10/2024'
  }
];

export const productivityData = [
  {
    key: 'Completion Rate',
    value: 80
  },
  {
    key: 'Cancellation Rate',
    value: 40
  },
  {
    key: 'Staff Cancelation',
    value: 10
  },
  {
    key: 'Parent Cancellation',
    value: 25
  }
];

export const caseLoadData = [
  {
    key: 'Scheduled (H)',
    value: '66.9',
    type: 'warning'
  },
  {
    key: 'Converted (H)',
    value: '53.3',
    type: 'success'
  },
  {
    key: 'Cancellations (H)',
    value: '13.6',
    type: 'error'
  },
  {
    key: 'Staff Cancellations (H)',
    value: '1.6',
    type: 'error'
  },
  {
    key: 'Parent Cancellations (H)',
    value: '12',
    type: 'error'
  },
  {
    key: 'Completion Rate',
    value: '79%',
    type: 'secondary'
  }
];

export const employeesColumns: DataTableColumn[] = [
  {
    title: '',
    dataIndex: 'isDeleted',
    key: 'isDeleted',
    render: (isDeleted) => <Status variant={!isDeleted ? 'active' : 'error'} />,
    cellProps: {
      width: 20
    }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true,
    cellProps: {
      width: 78
    }
  },
  {
    title: 'Employee Name',
    dataIndex: 'employee',
    key: 'employee',
    cellProps: {
      width: 193
    },
    render: ({ firstName, lastName, avatar }) => (
      <UserRecord
        user={{ name: `${firstName} ${lastName}`, avatar: avatar }}
        color="primary"
      />
    )
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => phone && usPhoneNumber(phone),
    cellProps: {
      width: 140
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    cellProps: {
      width: 228
    }
  },
  {
    title: 'Profit Center',
    dataIndex: 'profitCentre',
    key: 'profitCentre',
    cellProps: {
      width: 228
    }
  },
  {
    title: 'Supervisor',
    dataIndex: 'supervisor',
    key: 'supervisor',
    cellProps: {
      width: 187
    },
    render: ({ firstName, lastName, avatar }) =>
      firstName &&
      lastName && (
        <UserRecord
          user={{ name: `${firstName} ${lastName}`, avatar: avatar }}
          color="common.dark"
        />
      )
  }
];

export const appointmentsColumns: DataTableColumn[] = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sortable: true
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    sortable: true
  },

  {
    title: 'Status',
    dataIndex: 'variant',
    key: 'variant',
    sortable: true,
    render: (text) => (
      <Badge
        label={text}
        variant={text}
        sx={{
          fontWeight: '500 !important'
        }}
      />
    )
  }
];

export const appointmentsData = [
  {
    id: 0,
    firstName: 'Albert',
    lastName: 'Flores',
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    start: setHours(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      8
    ).toISOString(),
    title: 'Rest-Break',
    variant: 'success'
  },
  {
    id: 1,
    firstName: 'Albert',
    lastName: 'Flores',
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    start: setHours(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      8
    ).toISOString(),
    title: 'Report Writing',
    variant: 'success'
  },
  {
    id: 2,
    firstName: 'Albert',
    lastName: 'Flores',
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    start: setHours(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      8
    ).toISOString(),
    title: 'Lunch',
    variant: 'warning'
  },
  {
    id: 3,
    firstName: 'Albert',
    lastName: 'Flores',
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    start: setHours(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      8
    ).toISOString(),
    title: 'Drive Time',
    variant: 'default'
  },
  {
    id: 4,
    firstName: 'Albert',
    lastName: 'Flores',
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 3),
      8
    ).toISOString(),
    allDay: true,
    title: 'Leave Day',
    description: 'Personal'
  },
  {
    id: 5,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 5),
      8
    ).toISOString(),
    allDay: true,
    title: 'Leave Day',
    description: 'Personal'
  },
  {
    id: 6,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 0),
      8
    ).toISOString(),
    end: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 0),
      9
    ).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },

  {
    id: 66,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      10
    ).toISOString(),
    title: 'Rest-Break',
    variant: 'success'
  },
  {
    id: 6,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      8
    ).toISOString(),
    end: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      9
    ).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 6,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      10
    ).toISOString(),
    end: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
      11
    ).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'warning'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 8,
    firstName: 'Albert',
    lastName: 'Flores',
    start: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 0),
      9
    ).toISOString(),
    end: setHours(
      addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 0),
      10
    ).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  }
];
