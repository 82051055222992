import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;
ChartJS.overrides.bar.responsive = false;

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
      formatter: (value, ctx) => {
        const expenditures =
          ctx.chart.data.datasets[0].expenditures[ctx.dataIndex];
        return `${currencyFormatter(expenditures)}\n${value}%`;
      },

      textAlign: 'center' as const
    },
    legend: {
      position: 'bottom' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    }
  }
};
