import { plainToClass } from 'class-transformer';
import { API_REPORTS_BILLING_FIND_ALL } from '../api.routes';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { ReporsBillingDto } from '../dto/reports-billing.dto';

export class ReportsBillingApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<ReporsBillingDto>> {
    return this.httpClient
      .get(API_REPORTS_BILLING_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ReporsBillingDto, data.items)
        };
      });
  }
}
