import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction
} from '@reduxjs/toolkit';
import appReducer from './slices/appSlice';
import signInReducer from './slices/signInSlice';
import twoFactorReducer from './slices/twoFactorSlice';
import twoFactorRecoveryReducer from './slices/twoFactorRecoverySlice';
import resetPasswordReducer from './slices/resetPasswordSlice';
import inviteReducer from './slices/inviteSlice';
import twoFactorSetupReducer from './slices/twoFactorSetupSlice';
import clientReducer from './slices/clientSlice';
import clientServiceAuthorizationReducer from './slices/clientServiceAuthorizationSlice';
import clientDashboardReducer from './slices/clientDashboardSlice';
import clientAvailabilityReducer from './slices/clientAvailabilitySlice';
import clientLogReducer from './slices/clientLogSlice';
import clientProfileReducer from './slices/clientProfileSlice';
import appointmentsReducer from './slices/appointmentsSlice';
import appointmentReducer from './slices/appointmentSlice';
import employeeReducer from './slices/employeeSlice';
import employeeDashboardReducer from './slices/employeeDashboardSlice';
import languageReducer from './slices/languageSlice';
import fundingSourceReducer from './slices/fundingSourceSlice';
import profitCenterReducer from './slices/profitCenterSlice';
import organizationsReducer from './slices/organizationsSlice';
import titleReducer from './slices/titleSlice';
import employeeGroupReducer from './slices/employeeGroupSlice';
import serviceTypeReducer from './slices/serviceTypeSlice';
import placeServiceReducer from './slices/placeServiceSlice';
import appointmentTypeReducer from './slices/appointmentTypeSlice';
import appointmentCancelTypeReducer from './slices/appointmentCancelTypeSlice';
import serviceCodeReducer from './slices/serviceCodeSlice';
import serviceDescriptionReducer from './slices/serviceDescriptionSlice';
import locationReducer from './slices/locationSlice';
import timesheetReducer from './slices/timesheetSlice';
import regionReducer from './slices/regionSlice';
import taskTypeReducer from './slices/taskTypeSlice';
import stateReducer from './slices/stateSlice';
import cityReducer from './slices/citySlice';
import userReducer from './slices/userSlice';
import skillReducer from './slices/skillSlice';
import roleReducer from './slices/roleSlice';
import organizationReducer from './slices/organizationSlice';
import clientLocationsReducer from './slices/clientLocationsSlice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      app: appReducer,
      signIn: signInReducer,
      twoFactor: twoFactorReducer,
      twoFactorRecovery: twoFactorRecoveryReducer,
      twoFactorSetup: twoFactorSetupReducer,
      resetPassword: resetPasswordReducer,
      invite: inviteReducer,
      client: clientReducer,
      clientDashboard: clientDashboardReducer,
      clientServiceAuthorization: clientServiceAuthorizationReducer,
      clientAvailability: clientAvailabilityReducer,
      clientLog: clientLogReducer,
      clientProfile: clientProfileReducer,
      appointments: appointmentsReducer,
      employee: employeeReducer,
      employeeDashboard: employeeDashboardReducer,
      language: languageReducer,
      fundingSource: fundingSourceReducer,
      region: regionReducer,
      state: stateReducer,
      city: cityReducer,
      skill: skillReducer,
      profitCenter: profitCenterReducer,
      organizations: organizationsReducer,
      title: titleReducer,
      role: roleReducer,
      employeeGroup: employeeGroupReducer,
      serviceType: serviceTypeReducer,
      appointment: appointmentReducer,
      appointmentType: appointmentTypeReducer,
      appointmentCancelType: appointmentCancelTypeReducer,
      placeService: placeServiceReducer,
      serviceCode: serviceCodeReducer,
      serviceDescription: serviceDescriptionReducer,
      location: locationReducer,
      timesheet: timesheetReducer,
      taskType: taskTypeReducer,
      user: userReducer,
      organization: organizationReducer,
      clientLocations: clientLocationsReducer
    },
    middleware: getDefaultMiddleware({
      serializableCheck: false
    })
  });
};

const store = makeStore();

export default store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
