import { ClientsList } from '../../../features/clients/ClientsList';
import {
  withBaseLayout,
  withAuthorized,
  compose,
  withHelmet
} from '../../../core';

export const ClientsListPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Clients'
  })
)(ClientsList);
