import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { ServiceTypeApi, ServiceTypeDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const serviceTypeApi = new ServiceTypeApi();

export const fetchServiceTypeAsync = createFetchAsync(
  'serviceType',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await serviceTypeApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchServiceType = fetchServiceTypeAsync.asyncThunk;

export const fetchServiceType = (payload) => (dispatch, getState) => {
  const {
    serviceType: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchServiceType(payload));
  }
};

type ServiceTypeState = {
  findAll: AsyncState<FindAllResponse<ServiceTypeDto>>;
};

const initialState: ServiceTypeState = {
  findAll: fetchServiceTypeAsync.initialState
};

const serviceType = createSlice({
  name: 'serviceType',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchServiceTypeAsync.extraReducers
  }
});

export default serviceType.reducer;
