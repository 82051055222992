import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IResetPasswordValues {
  email: string;
}
interface ICrteateNewPasswordValues {
  hash: string;
  password: string;
}

interface IResetPasswordThunkArguments {
  values: IResetPasswordValues;
  options?: Partial<IRequestOptions>;
}
interface ICreateNewPasswordThunkArguments {
  values: ICrteateNewPasswordValues;
  options?: Partial<IRequestOptions>;
}

export const resetPassword = createAsyncThunk(
  'resetPasswordAction',
  async ({ values, options }: IResetPasswordThunkArguments) => {
    try {
      const data = await authService.post(
        '/send-link-to-reset-password',
        values
      );
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      if (options?.onFail) {
        options.onFail();
      }
      throw new Error('Something went wrong');
    }
  }
);

export const createNewPassword = createAsyncThunk(
  'createNewPasswordAction',
  async ({ values, options }: ICreateNewPasswordThunkArguments) => {
    try {
      const data: any = await authService.post('/set-new-password', values);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const resetPasswordSlice = createSlice({
  name: 'resetPasswordSlice',
  initialState: {
    resetPassword: {
      loading: false,
      data: null,
      error: null
    },
    createNewPassword: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword.loading = true;
      state.resetPassword.data = {};
      state.resetPassword.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.resetPassword.loading = false;
      state.resetPassword.data = payload;
      state.resetPassword.error = null;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.resetPassword.loading = false;
      state.resetPassword.data = {};
      state.resetPassword.error = 'Something went wrong';
    });
    builder.addCase(createNewPassword.pending, (state) => {
      state.createNewPassword.loading = true;
      state.createNewPassword.data = {};
      state.createNewPassword.error = null;
    });
    builder.addCase(createNewPassword.fulfilled, (state, { payload }) => {
      state.createNewPassword.loading = false;
      state.createNewPassword.data = payload;
      state.createNewPassword.error = null;
    });
    builder.addCase(createNewPassword.rejected, (state) => {
      state.createNewPassword.loading = false;
      state.createNewPassword.data = {};
      state.createNewPassword.error = 'Something went wrong';
    });
  }
});

export default resetPasswordSlice.reducer;
