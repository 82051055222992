import { ComponentType, useState, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { IconSearchAppointments } from '../../../components/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../../core/utils/route.utils';
import { ClientApi } from '../../../core/http';
import useDebounce from '../../../core/hooks/useDebounce';
import { SearchAutocomplete } from '../../../components/fields';
import { ROUTE_DASHBOARDS_SELECTED } from '../../../constants/route.paths';
import { isEmpty } from 'lodash';

const clientApi = new ClientApi();

export const ClientSearch: ComponentType = () => {
  const { tabs } = useParams();
  const [tabsKeys, setTabsKeys] = useState(tabs?.split(','));
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState([]);
  const [selectedClientUuid, setSelectedClientUuid] = useState<string>('');
  const [error] = useState<string>('');
  const debouncedValue: string = useDebounce<string>(searchValue, 200);
  const navigate = useNavigate();

  const handleAutocompleteChange = (event, item) => {
    if (item != null) {
      setSelectedClientUuid(item.value);
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      clientApi.findAll({ search: debouncedValue }).then((response) => {
        const data = response.items.map((item) => {
          return {
            label: item.displayName,
            value: item.uuid
          };
        });
        setData(data);
      });
    }
  }, [debouncedValue]);

  const handleShowDashboard = () => {
    if (isEmpty(selectedClientUuid)) return;
    if (tabsKeys.includes('client-details')) return;

    setTabsKeys([...tabsKeys, 'client-details']);

    const path = url(ROUTE_DASHBOARDS_SELECTED, {
      tabs: [...tabsKeys, `client-details:${selectedClientUuid}`].join(',')
    });
    navigate(path);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          pt: 5,
          justifyContent: 'center',
          height: 'calc(100vh - 180px)'
        }}
      >
        <Stack alignItems="center" spacing={2}>
          <Box
            sx={{
              '& .MuiSvgIcon-root': {
                width: 164,
                height: 164
              }
            }}
          >
            <IconSearchAppointments />
          </Box>
          <Typography
            variant="h4"
            sx={{ width: 244, textAlign: 'center' }}
            mt={2}
          >
            Select client dashboard
          </Typography>
          <SearchAutocomplete
            errors={error}
            helperText={error}
            placeholder="Search by Client Name, ID"
            onAutocompleteChange={handleAutocompleteChange}
            dataSource={data}
            getOptionLabel={(data) => data.label}
            onTextChange={(text) => setSearchValue(text)}
          />
          <Button fullWidth size="medium" onClick={() => handleShowDashboard()}>
            Show dashboard
          </Button>
        </Stack>
      </Box>
    </>
  );
};
