import { AuthLayout } from '../../../layouts/auth-layout';
import { TwoFactorSetup } from '../../../features/auth/TwoFactorSetup';
import { Helmet } from 'react-helmet';

export const TwoFactorSetupPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Two Factor Setup</title>
      </Helmet>
      <TwoFactorSetup />
    </AuthLayout>
  );
};
