import get from 'lodash/get';
import { find } from 'lodash';
import { timeZoneItems } from '../../../core/utils/data.utils';

export const locationColumns = [
  {
    title: 'Location',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'Address',
    key: 'address',
    dataIndex: 'address'
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'city',
    render: (text: string) => (text ? text : '-')
  },
  {
    title: 'State',
    key: 'state',
    dataIndex: 'state',
    render: (text: string) => (text ? text : '-')
  },
  {
    title: 'Zip Code',
    key: 'zipCode',
    dataIndex: 'zipCode',
    render: (text: string) => (text ? text : '-')
  },
  {
    title: 'Time Zone',
    key: 'timeZone',
    dataIndex: 'timeZone',
    render: (text: string) =>
      get(
        find(timeZoneItems, (x) => parseInt(x.value) === parseInt(text)),
        'label',
        '-'
      )
  },
  {
    title: 'Place of Service',
    key: 'servicePlace',
    dataIndex: 'servicePlace',
    cellProps: {
      sx: {
        width: 154
      }
    },
    render: (servicePlace: any) => get(servicePlace, 'code', '-')
  }
];

export const locationData = [
  {
    id: 1,
    name: 'Salinas',
    address: '150 Cayuga Street',
    city: 'Salinas',
    state: 'CA',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: '1-Office'
  },
  {
    id: 2,
    name: 'San Jose',
    address: 'Salinas',
    city: 'Salinas',
    state: 'CA',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: '1-Office'
  },
  {
    id: 3,
    name: 'Watsonwille',
    address: 'Salinas',
    city: 'Freedom',
    state: 'Freedom',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: '1-Office'
  },
  {
    id: 4,
    name: 'SARC Watsonwille',
    address: 'Salinas',
    city: 'Salinas',
    state: 'Freedom',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: 'SARC'
  },
  {
    id: 5,
    name: 'SARC San Jose',
    address: 'San Jose',
    city: 'San Jose',
    state: 'Freedom',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: 'SARC'
  },
  {
    id: 6,
    name: 'SARC Salinas',
    address: 'Watsonwille',
    city: 'Watsonwille',
    state: 'Freedom',
    zipCode: '93901',
    timezone: 'UTC-08:00',
    placeOfService: 'SARC'
  },
  {
    id: 7,
    name: 'Zoom',
    address: 'Zoom',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 8,
    name: 'Whatsapp',
    address: 'Whatsapp',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 9,
    name: 'FaceTime',
    address: 'FaceTime',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 10,
    name: 'Phone Session',
    address: 'Phone Session',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 11,
    name: 'Google Hangout',
    address: 'Google Hangout',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 12,
    name: 'Skype',
    address: 'Skype',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 13,
    name: 'DUO',
    address: 'DUO',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  },
  {
    id: 14,
    name: 'Alternative',
    address: 'Alternative',
    city: null,
    state: null,
    zipCode: null,
    timezone: null,
    placeOfService: 'SARC'
  }
];
