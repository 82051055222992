import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserMe, forceFetchUserMe } from '../store/slices/userSlice';
import { AppState } from '../store/store';

export const useUser = () => {
  const dispatch = useDispatch();
  const { payload: user, loading } = useSelector(
    (state: AppState) => state.user.me
  );

  useEffect(() => {
    if (localStorage.getItem('login')) {
      dispatch(fetchUserMe());
    }
  }, []);

  const fetchMe = () => {
    dispatch(forceFetchUserMe());
  };

  return {
    loading,
    user,
    fetchMe
  };
};
