import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options, backgrounds } from './chart.options';

export const WeeklyConvertedVsSheduled = ({
  weeklyConvertedRevenueByServiceCode,
  weeklyScheduledRevenueByServiceCode
}) => {
  const labels = [
    ...Object.keys(weeklyConvertedRevenueByServiceCode).map(
      (item) => `Converted ${item}`
    ),
    ...Object.keys(weeklyScheduledRevenueByServiceCode).map(
      (item) => `Scheduled ${item}`
    )
  ];

  const combined = {};

  Object.keys(weeklyConvertedRevenueByServiceCode).forEach((key: string) => {
    weeklyConvertedRevenueByServiceCode[key].forEach((item) => {
      if (!combined[item.code]) {
        combined[item.code] = {
          [key]: []
        };
      }
      if (!combined[item.code][key]) {
        combined[item.code][key] = [];
      }
      combined[item.code][key].push({
        converted: item.value,
        scheduled: weeklyScheduledRevenueByServiceCode[key].find(
          (subItem) => item.code === subItem.code
        ).value
      });
    });
  });

  const datasets = [];

  Object.keys(combined).forEach((key, index) => {
    const dataset = {
      categoryPercentage: 0.3,
      label: key,
      data: [],
      fill: false,
      backgroundColor: backgrounds[index]
    };
    Object.keys(combined[key]).forEach((week) => {
      dataset.data.push(
        ...combined[key][week].map((item) => item.converted),
        ...combined[key][week].map((item) => item.scheduled)
      );
    });
    datasets.push(dataset);
  });

  const data = {
    labels,
    datasets
  };

  return (
    <Card height={617} title="$ Weekly Converted vs Scheduled">
      <Box sx={{ width: '100%', height: 464 }}>
        <Bar
          options={options}
          data={data}
          style={{ maxHeight: 464, width: '100%' }}
        />
      </Box>
    </Card>
  );
};
