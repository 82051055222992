import { makeStyles } from '@mui/styles';

const options = {
  name: 'labeled-record'
};

const useStyles = makeStyles(
  () => ({
    root: {
      // marginBottom: theme.spacing(3),
    },
    label: {
      fontSize: 12,
      fontWeight: 500,
      color: '#647593'
    },
    value: {
      fontSize: 14,
      fontWeight: 500
    }
  }),
  options
);

export default useStyles;
