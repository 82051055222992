import { PageHeader } from '../../components/page-header';
import { Tabs } from '../../components/tabs';
import { ForEmployees } from './ForEmployees';
import { ForClients } from './ForClients';
import { useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_CREATE_EMPLOYEE_REMINDER,
  ROUTE_CREATE_CLIENT_REMINDER
} from '../../constants/route.paths';
import { PermissionView } from '../../components/permission-view';

export const ReminderListContainer = () => {
  const [activeTab, setActiveTab] = useState('employee');

  return (
    <>
      <PageHeader title="Reminders" divider={false}>
        <PermissionView permission="modifyOtherEmployeeReminders">
          <Button
            size="small"
            component={Link}
            to={url(
              activeTab == 'employee'
                ? ROUTE_CREATE_EMPLOYEE_REMINDER
                : ROUTE_CREATE_CLIENT_REMINDER
            )}
          >
            Create
          </Button>
        </PermissionView>
      </PageHeader>
      <Tabs
        hashed
        onChange={setActiveTab}
        items={[
          {
            key: 'employee',
            label: 'For Employees',
            content: <ForEmployees />
          },
          {
            key: 'client',
            label: 'For Clients',
            content: <ForClients />
          }
        ]}
      />
    </>
  );
};
