import { DataTableColumn } from '../../components/table-view/TableView';
import { addDays, format } from 'date-fns';
import { FULL_DATE_FORMAT } from '../../constants/app.constants';
import { UserRecord } from '../../components/user-record';
import { Box } from '@mui/material';
import { dateConverter } from '../../core/utils/data.utils';

export const announcementsColumns: DataTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text: string) => (
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 470,
          whiteSpace: 'nowrap'
        }}
      >
        {text}
      </Box>
    )
  },
  {
    title: 'Created by',
    dataIndex: 'owner',
    key: 'owner',
    cellProps: {
      width: 200
    },
    render: (user: any) => (
      <UserRecord
        user={{ name: `${user.firstName} ${user.lastName}`, avatar: null }}
      />
    )
  },
  {
    title: 'File',
    dataIndex: 'file',
    key: 'file',
    cellProps: {
      width: 70
    },
    render: (file: boolean) => (file ? 'YES' : 'NO')
  },
  {
    title: 'Show to',
    dataIndex: 'showTo.groupName',
    key: 'showTo',
    sortable: true,
    cellProps: {
      width: 151
    }
  },
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
    cellProps: {
      width: 200
    },
    render: (value) => dateConverter(value.startDate, value.endDate)
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortable: true,
    cellProps: {
      width: 165
    },
    render: (date) => {
      return date ? format(new Date(date), FULL_DATE_FORMAT) : '-';
    }
  }
];
