import { makeStyles } from '@mui/styles';

const options = {
  name: 'template-component'
};

const useStyles = makeStyles(
  () => ({
    root: {
      width: 400
    }
  }),
  options
);

export default useStyles;
