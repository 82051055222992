import React, { FC, useEffect, useState } from 'react';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { TOOLBAR_CONFIG } from './config';
import { Label } from './Label';
import './styles.css';
import { FormControl, FormHelperText } from '@mui/material';
import clsx from 'clsx';

interface Props {
  onChange: (value: string) => void;
  label?: string;
  value?: string;
  errors?: any;
  editorStyle?: React.CSSProperties;
}

const generateEditorStateFromValue = (value: string) => {
  const contentBlock = htmlToDraft(value || '');
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  return EditorState.createWithContent(contentState);
};

export const FieldWysiwyg: FC<Props> = ({
  label,
  value,
  onChange,
  errors,
  editorStyle
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isSetted, setIsSetted] = useState(false);

  useEffect(() => {
    if (value && !isSetted) {
      setEditorState(generateEditorStateFromValue(value));
      setIsSetted(true);
    }
  }, [value]);

  const handleChange = (editorState) => {
    setEditorState(editorState);
  };

  const onBlur = () => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <FormControl error={true} fullWidth>
      {label && <Label>{label}</Label>}
      <Editor
        wrapperClassName={clsx({
          invalid: !!errors
        })}
        editorStyle={editorStyle}
        editorState={editorState}
        toolbar={TOOLBAR_CONFIG}
        onEditorStateChange={handleChange}
        onBlur={onBlur}
      />
      <FormHelperText>{errors}</FormHelperText>
    </FormControl>
  );
};
