import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'calendar-view-header'
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: -40,
      position: 'relative',
      overflow: 'hidden'
    },

    drawer: {
      position: 'absolute',
      width: 289,
      zIndex: 100,
      right: 0,
      top: 0,
      bottom: 0,

      backgroundColor: '#FFFFFF',
      padding: theme.spacing(2, 0, 3, 3),
      boxShadow: '-16px 0px 23px 0px rgb(0 0 0 / 8%)',
      transform: 'translateX(120%)',
      transition: 'transform 0.3s ease-in-out',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      overflowY: 'scroll'
    },

    drawerOpen: {
      transform: 'translateX(0)'
    },

    hasItems: {
      backgroundColor: '#F6F9FF!important'
    },

    table: {
      width: '100%',
      borderSpacing: theme.spacing(0.5, 0),

      '& td:not(:first-child)': {
        backgroundColor: '#FBFCFF'
      },

      '& td': {
        padding: theme.spacing(0.5, 0.5, 0, 0.5),
        verticalAlign: 'top'
      },

      '& td>*': {
        marginBottom: 4
      },

      '& td>*:last-child': {
        marginBottom: 0
      }
    },
    tableHeader: {
      width: '100%',
      borderSpacing: theme.spacing(0.5, 0),

      '& td:first-child': {
        padding: theme.spacing(0.5, 0.5, 0, 0.5)
      }
    },
    hourCell: {}
  }),
  options
);

export default useStyles;
