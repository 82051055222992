import { makeStyles } from '@mui/styles';

const options = {
  name: 'search-field'
};

const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#F1F5FB',
        borderRadius: 4
      },
      '& .MuiInputLabel-root': {
        color: '#496492'
      },

      '& .MuiOutlinedInput-input': {
        padding: '5px 8px',
        lineHeight: '24px',
        fontSize: 14,
        fontWeight: 400,
        height: 22,
        '&::placeholder': {
          opacity: 1,
          color: '#8389AA'
        }
      },

      '& .MuiInputBase-adornedEnd': {
        paddingRight: 0
      },

      '& .MuiInputAdornment-root': {
        height: 24,
        border: 0,
        borderStyle: 'solid',
        paddingLeft: 4,
        borderLeftWidth: 1,
        borderColor: '#ECF0F7'
      }
    }
  }),
  options
);

export default useStyles;
