import { plainToClass } from 'class-transformer';
import {
  API_EMPLOYEE_REMINDER_CREATE,
  API_EMPLOYEE_REMINDER_DELETE,
  API_EMPLOYEE_REMINDER_FIND_ALL,
  API_EMPLOYEE_REMINDER_FIND_ONE,
  API_EMPLOYEE_REMINDER_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { EmployeeReminderDto } from '../dto';
import { FindAllResponse } from '../types';

export class EmployeeReminderApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeReminderDto>> {
    return this.httpClient
      .get(API_EMPLOYEE_REMINDER_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeReminderDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<EmployeeReminderDto> {
    return this.httpClient
      .get(API_EMPLOYEE_REMINDER_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(EmployeeReminderDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<EmployeeReminderDto> {
    return this.httpClient
      .post(API_EMPLOYEE_REMINDER_CREATE, data, {
        formData: true
      })
      .then(({ data }) => {
        return plainToClass(EmployeeReminderDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<EmployeeReminderDto> {
    return this.httpClient
      .put(API_EMPLOYEE_REMINDER_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(EmployeeReminderDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_EMPLOYEE_REMINDER_DELETE, {
      pathParams: { uuid }
    });
  }
}
