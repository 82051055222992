import { Expose } from 'class-transformer';
import { ClientDto } from './client.dto';
import { EmployeeDto } from './employee.dto';
import { FundingSourceDto } from './funding-source.dto';

export class ClientCareTeamMembersDto {
  @Expose()
  uuid: string;

  @Expose()
  client: ClientDto;

  @Expose()
  employee: EmployeeDto;

  @Expose()
  funcdingSource: FundingSourceDto;

  @Expose()
  matching: string;

  @Expose()
  forecast: string;

  @Expose()
  scheduled: string;

  @Expose()
  conversion: string;

  @Expose()
  get displayName() {
    return [this.employee?.firstName, this.employee?.lastName]
      .filter(Boolean)
      .join(' ');
  }
}
