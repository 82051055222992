import { isEmpty, map } from 'lodash';
import { possibleTabs } from '../_mockdata';

export const generateTabs = (
  tabKeys: string[],
  contentProps = {},
  labelProps = {}
) => {
  if (isEmpty(tabKeys)) return;
  return map(tabKeys, (key) => {
    const parsedTab = key.includes(':') ? key.split(':')[0] : key;
    return {
      key: key,
      label: possibleTabs[parsedTab].label(labelProps[parsedTab] || ''),
      description: possibleTabs[parsedTab].description,
      content: possibleTabs[parsedTab].content(contentProps[parsedTab] || '')
    };
  });
};
