import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Grid
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { DatePicker, SelectField, TextField } from '../../../components/fields';
import * as yup from 'yup';
import { LeaveDayApi } from '../../../core/http';
import { useEditResource } from '../../../core/hooks/useEditResource';
import { differenceInDays, format } from 'date-fns';

interface Props extends DialogProps {
  employeeUuid: string;
  uuid: string;
  leaveDaysFetchData?: () => void;
  onError?: (error) => void;
}

const leaveDayApi = new LeaveDayApi();

export const AddLeaveDaysDialog: ComponentType<Props> = ({
  popDialog,
  employeeUuid,
  uuid,
  leaveDaysFetchData,
  onError
}) => {
  const minDate = new Date('01/01/1950');

  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(leaveDayApi, {
    uuid,
    initialValues: {
      type: '',
      startDate: '',
      endDate: '',
      note: '',
      daysNumber: 1,
      employeeUuid: ''
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Please select one of the options'),
      startDate: yup
        .date()
        .required('Please select start date')
        .typeError('Please enter valid date')
        .min(minDate, 'Start date must be later than 01/01/1950')
        .when('endWorkDate', {
          is: yup.date().isValid('Please enter valid date'),
          then: yup
            .date()
            .max(
              yup.ref('endDate'),
              'Start date must be earlier than End date '
            )
            .typeError('Please enter valid date'),
          otherwise: yup.date().nullable().typeError('Please enter valid date')
        }),
      endDate: yup
        .date()
        .required('Please select End date')
        .typeError('Invalid date')
        .min(yup.ref('startDate'), 'End date must be later then start date')
    }),
    normalizeValues: (values) => {
      return {
        ...values,
        employeeUuid,
        startDate: format(new Date(values.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(values.endDate), 'yyyy-MM-dd')
      };
    },
    onSuccess: () => {
      leaveDaysFetchData();
      popDialog();
    },
    onError,
    extraParams: {
      uuid
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 600
        }
      }}
    >
      <DialogTitle>{isEdit ? 'Edit leave days' : 'Add leave days'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} rowSpacing={2}>
          <Grid item md={6}>
            <SelectField
              disabled={false}
              label="Type"
              placeholder="Select"
              required
              value={values.type}
              onChange={handleChange('type')}
              errors={getFieldTouchedError('type')}
              items={[
                {
                  label: 'Vacation',
                  value: 'vacation'
                },
                {
                  label: 'Sick',
                  value: 'sick'
                },
                {
                  label: 'Personal',
                  value: 'personal'
                },
                {
                  label: 'Optional',
                  value: 'optional'
                }
              ]}
            />
          </Grid>
          <Grid
            item
            md={6}
            justifyContent="flex-start"
            alignItems="center"
            display="flex"
          >
            {values.startDate && values.endDate && (
              <Typography variant="body3">
                {Math.abs(
                  differenceInDays(
                    new Date(values.startDate),
                    new Date(values.endDate)
                  )
                )}{' '}
                days left
              </Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <DatePicker
              label="Starts on"
              required
              value={values.startDate}
              onChange={handleChange('startDate')}
              errors={getFieldTouchedError('startDate')}
            />
          </Grid>
          <Grid item md={6}>
            <DatePicker
              label="Until"
              required
              value={values.endDate}
              onChange={handleChange('endDate')}
              errors={getFieldTouchedError('endDate')}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              multiline={true}
              rows={5.6}
              label="Note"
              value={values.note}
              onChange={handleChange('note')}
              errors={getFieldTouchedError('note')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
