import useStyles from './styles';
import { FC } from 'react';
import { Typography } from '@mui/material';
import format from 'date-fns/format';

interface Props {
  date: Date;
  meta?: string;
}

export const CalendarViewHeader: FC<Props> = ({ date, meta }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6">{format(date, 'MMM dd')}</Typography>
      <Typography variant="body1" component="span">
        {format(date, 'iiii')}
      </Typography>
      {meta && (
        <Typography variant="body1" component="span" color="#219653" ml={1}>
          {meta}
        </Typography>
      )}
    </div>
  );
};
