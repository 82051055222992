import { useState, ComponentType } from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

interface Props {
  items: {
    label: string;
    value: string;
  }[];
  bgColor?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  responsive?: boolean;
}

const BUTTON_STYLE_PROPS = {
  active: {
    variant: 'contained'
  },
  default: {
    variant: 'text'
  }
};

export const RadioGroup: ComponentType<Props> = ({
  items,
  bgColor = 'background.light',
  onChange,
  defaultValue = null,
  responsive = false
}) => {
  const [value, setValue] = useState<string | null>(defaultValue);
  const classes = useStyles();

  const handleClick = (value: string) => () => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Stack
      className={clsx(classes.root, responsive && classes.rootResponsive)}
      sx={{ backgroundColor: bgColor, height: 32 }}
      direction="row"
      display="inline-flex"
      alignItems="center"
      p={0.5}
      borderRadius={0.5}
      spacing={0.25}
    >
      {items.map((item) => {
        const type = item.value === value ? 'active' : 'default';
        return (
          <Button
            key={item.value}
            size="small"
            className={clsx(
              classes.button,
              responsive && classes.buttonResponsive,
              {
                [classes.buttonActive]: item.value === value
              }
            )}
            {...(BUTTON_STYLE_PROPS[type] as ButtonProps)}
            onClick={handleClick(item.value)}
          >
            {item.label}
          </Button>
        );
      })}
    </Stack>
  );
};
