import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  type: 'scatter' as const,
  indexAxis: 'x' as const,
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: false
    },
    datalabels: {
      align: 'end' as const,
      anchor: 'end' as const,
      formatter: function (value, context) {
        const res = context.chart.data.datasets.map((item) => {
          return item.data[context.dataIndex];
        });

        const maxIndex = res.findIndex(
          (item) => Math.max(...res) === item && item === value
        );

        if (Math.max(...res) === value && context.datasetIndex === maxIndex) {
          return currencyFormatter(value);
        }
        return null;
      }
    }
  },
  scales: {
    xAxis: {
      barPercentage: 0.2,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        display: true,
        color: '#4B5663'
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: true
      },
      scaleLabel: {
        display: false
      },
      min: 0,
      max: 190000.0,
      ticks: {
        color: '#172B4D',
        stepSize: 10000.0,
        font: {
          size: 10,
          weight: '500'
        },
        callback: (value) => currencyFormatter(value)
      }
    }
  }
};
