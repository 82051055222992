import { Grid } from '@mui/material';
import { CurrentM2dCancellations } from '../widgets/current-m2d-cancellations/CurrentM2dCancellations';
import { M2dCancellations } from '../widgets/m2d-cancellations/M2dCancellations';
import { RevenueAndMarginChart } from '../widgets/revenue-and-margin-chart/RevenueAndMarginChart';
import { RevenueAndMargin } from '../widgets/revenue-and-margin-table/RevenueAndMarginTable';
import { WeeklyActualVsSheduledVsForecast } from '../widgets/weekly-actual-vs-scheduled-vs-forecast/WeeklyActionVsScheduledVsForecast';
import { WeeklyCancellationVsScheduled } from '../widgets/weekly-cancellation-vs-scheduled/WeeklyCancellationVsScheduled';

export const BillingVsPlannedDashboard = () => {
  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <RevenueAndMargin />
      </Grid>
      <Grid item md={12}>
        <RevenueAndMarginChart />
      </Grid>
      <Grid item md={12}>
        <WeeklyActualVsSheduledVsForecast />
      </Grid>
      <Grid item md={12}>
        <WeeklyCancellationVsScheduled />
      </Grid>
      <Grid item md={12}>
        <M2dCancellations />
      </Grid>
      <Grid item md={12}>
        <CurrentM2dCancellations />
      </Grid>
    </Grid>
  );
};
