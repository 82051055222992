import { HolidayContainer } from '../../features/master-data/holiday/HolidayContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataHoliday = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Holidays'
  })
)(HolidayContainer);
