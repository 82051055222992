import { FC } from 'react';
import { Box, Divider, Typography, Stack } from '@mui/material';
import { useStyles } from './styles';
import logoText from '../../assets/logo-text.png';
import spinner from '../../assets/spinner.svg';

export const OnboardingPage: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.waves} />
      <img src={logoText} />
      <Box className={classes.spinnerBlock}>
        <img src={spinner} className={classes.spinner} />
        <Typography
          variant="body2"
          className={classes.loadingText}
          color="common.white"
        >
          Loading projects...
        </Typography>
      </Box>
      <Stack
        className={classes.bottomInfoBar}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1.5}
      >
        <Typography
          variant="body2"
          color="common.white"
          className={classes.infoBarText}
        >
          Synergetic programming
        </Typography>
        <Typography
          variant="body2"
          color="common.white"
          className={classes.infoBarText}
        >
          Logistics
        </Typography>
        <Typography
          variant="body2"
          color="common.white"
          className={classes.infoBarText}
        >
          Administration
        </Typography>
        <Typography
          variant="body2"
          color="common.white"
          className={classes.infoBarText}
        >
          Schedulling
        </Typography>
        <Typography
          variant="body2"
          color="common.white"
          className={classes.infoBarText}
        >
          Human Resources
        </Typography>
      </Stack>
    </Box>
  );
};
