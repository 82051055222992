import React, { ComponentType, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps, Box } from '@mui/material';
import { IconEye, IconEyeClosed } from '../../icons';

interface SelectFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  onChange?: (value: string) => void;
  onBlur?: any;
  placeholder?: string;
  helperText?: string | null | any;
  errors?: any;
  value?: any;
}

export const PasswordField: ComponentType<SelectFieldProps> = ({
  onChange,
  onBlur,
  label,
  helperText,
  value = null,
  placeholder,
  errors,
  ...props
}) => {
  const handleTextChange = (text: string) => {
    if (onChange) {
      onChange(text);
    }
  };
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <TextField
      error={!!errors}
      helperText={errors || helperText}
      value={value}
      {...{ label, placeholder, ...props }}
      type={passwordVisible ? 'text' : 'password'}
      onChange={(event) => handleTextChange(event.target.value)}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ cursor: 'pointer', visibility: value ? 'visible' : 'hidden' }}
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {/* <Divider
              orientation="vertical"
              sx={{
                background: '#E3EAF3',
                width: 0.1,
                position: 'relative',
                right: 5,
                height: 25
              }}
            /> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiSvgIcon-root': {
                  width: 'unset',
                  height: 'unset'
                }
              }}
            >
              {passwordVisible ? <IconEyeClosed /> : <IconEye />}
            </Box>
          </InputAdornment>
        )
      }}
    />
  );
};
