import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuthExpiring } from '../../../core/store/slices/clientDashboardSlice';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { TableView } from '../../../components/table-view';
import { Card } from '../../../components/cards';
import { ROUTE_CLIENT_APPOINTMENT_EDIT } from '../../../constants/route.paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { transformEmployeeAppointments, url } from '../../../core';
import { normalizeDate } from '../../../core';
import { AppointmentCalendarDrawerCard } from '../../../components/appointment-calendar-drawer-card';
import { AppointmentCalendarCard } from '../../../components/appointment-calendar-card';
import { CalendarView } from '../../../components/calendar-view/CalendarView';
import { RadioGroup } from '../../../components/fields/radio-group';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { calendarViewTypeItems } from '../../../constants/app.constants';
import { IconAppointmentCancel, IconPen } from '../../../components/icons';
import { LabeledRecord } from '../../../components/labeled-record';
import { usPhoneNumber } from '../../../core';
import { useEmployeeDetail } from '../../../core/hooks/useEmployeeDetail';
import { get } from 'lodash';
import { useAppointmentList } from '../../../core/hooks/useAppointmentList';
import { clientAppointmentColumns } from '../_mockdata';
import { ProductivityDashboardWidget } from '../../employees/widgets/ProductivityDashboardWidget';
import { CaseLoadWidget } from '../../employees/widgets/CaseLoadWidget';
import { ClientListWidget } from '../../employees/widgets/ClientListWidget';
import { FilesListWidget } from '../../employees/widgets/FilesListWidget';
import { upcomingAppointmentColumns } from '../../employees/_mockdata';
import { AppointmentDto } from '../../../core/http';

export const EmployeeDetailedDashboard = ({ uuid }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [currentView, setCurrentView] = useState<'calendar' | 'table'>(
    'calendar'
  );

  useEffect(() => {
    dispatch(getAuthExpiring());
  }, []);

  const {
    data: appointmentResponse,
    isLoading,
    pagination,
    startDate,
    setStartDate
  } = useAppointmentList({
    clientId: uuid,
    defaultParams: {
      'filter[client.uuid][eq]': uuid,
      limit: 5
    }
  });
  const appointmentsData = get(appointmentResponse, 'items', []);
  const { data: employeeData } = useEmployeeDetail({
    uuid: uuid
  });

  return (
    <>
      <Grid container spacing={1} justifyContent="center" mb={1.5}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <ProductivityDashboardWidget employeeUuid={uuid} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Card sx={{ height: 331 }} title="Upcoming appointments">
              <TableView
                showEmptyState
                EmptyStateProps={{
                  pt: 2,
                  title: 'Employee has no upcoming appointments',
                  description: null
                }}
                loading={isLoading}
                showTablePerPage={!isLoading}
                showPagination={!isLoading}
                dataSource={
                  appointmentsData
                    ? transformEmployeeAppointments(
                        appointmentsData,
                        null
                      ).slice(0, 3)
                    : []
                }
                keyExtractor={(item) => item.id}
                onRowClick={() => navigate(pathname + '#appointments')}
                columns={upcomingAppointmentColumns}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid md={12}>
          <Stack mt={1.5} spacing={1} direction="column">
            <Card title="General Information">
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="First Name:"
                    value={employeeData?.firstName}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Last Name:"
                    value={employeeData?.lastName}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord label="Gender:" value={employeeData?.gender} />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord label="Email:" value={employeeData?.email} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  {
                    <LabeledRecord
                      label="Hire Date:"
                      value={normalizeDate(employeeData?.hireDate)}
                    />
                  }
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="End Work Date:"
                    value={normalizeDate(employeeData?.endWorkDate) || '-'}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord label="Title:" value={employeeData?.title} />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord label="HRID:" value={employeeData?.hrid} />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Type of Service:"
                    value={employeeData?.serviceTypeNames?.join(', ') || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Group:"
                    value={employeeData?.groupNames?.join(', ') || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Supervisor:"
                    value={
                      employeeData?.supervisor
                        ? `${employeeData?.supervisor?.firstName} ${employeeData?.supervisor?.lastName}`
                        : '-'
                    }
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Profit center:"
                    value={employeeData?.profitCentre}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Hourly Rate:"
                    value={employeeData?.hourlyRate}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Targeted Hours:"
                    value={employeeData?.targetedHour}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={2.4}>
                  <LabeledRecord label="PTO:" value={employeeData?.pto} />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Primary Language:"
                    value={employeeData?.primaryLanguage}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Secondary Language:"
                    value={employeeData?.secondaryLanguage || '-'}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Skills:"
                    value={employeeData?.skills.join(',') || '-'}
                  />
                </Grid>
              </Grid>
            </Card>

            <Card title="Contact Information">
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Address:"
                    value={employeeData?.contactInfo?.addressLine}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="City:"
                    value={employeeData?.contactInfo?.city.title}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Province/State:"
                    value={employeeData?.contactInfo?.state.title}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Zip Code:"
                    value={employeeData?.contactInfo?.zipCode}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Primary Phone Number:"
                    value={usPhoneNumber(
                      employeeData?.contactInfo?.phoneNumberPrimary
                    )}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Secondary Phone Number:"
                    value={
                      usPhoneNumber(
                        employeeData?.contactInfo?.phoneNumberSecondary
                      ) || '-'
                    }
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Work Phone Number:"
                    value={
                      usPhoneNumber(
                        employeeData?.contactInfo?.phoneNumberWork
                      ) || '-'
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <LabeledRecord
                    label="Enable Text Notifications:"
                    value={
                      employeeData?.contactInfo?.notifications ? 'Yes' : 'No'
                    }
                  />
                </Grid>
              </Grid>
            </Card>
          </Stack>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Card
              mb={1.5}
              px={3}
              py={2}
              sx={{
                height: 540
              }}
            >
              <Stack direction="row" mb={3} justifyContent="space-between">
                <Stack direction="row" spacing={3}>
                  <Typography variant="h4">Appointments</Typography>
                  <RadioGroup
                    items={calendarViewTypeItems}
                    defaultValue="calendar"
                    onChange={(view) => {
                      setCurrentView(view as 'calendar' | 'table');
                    }}
                  />
                </Stack>
                <WeekRangePicker
                  withToday
                  onChange={(startDate) => {
                    setStartDate(startDate);
                  }}
                />
              </Stack>

              {currentView === 'calendar' ? (
                <CalendarView
                  contentHeight={400}
                  dataSource={appointmentsData}
                  groupKey="start"
                  startDate={startDate}
                  renderItem={(item: AppointmentDto, _, selectedItem) => (
                    <AppointmentCalendarCard
                      item={item}
                      selected={selectedItem?.uuid === item.uuid}
                    />
                  )}
                  renderDrawer={(item: AppointmentDto) => (
                    <AppointmentCalendarDrawerCard item={item} />
                  )}
                  renderDrawerFooter={(item) => (
                    <Stack direction="row" marginTop="auto" spacing={1}>
                      <Button
                        onClick={() =>
                          navigate(
                            url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                              clientId: uuid,
                              uuid: item.uuid
                            })
                          )
                        }
                      >
                        Open
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() =>
                          navigate(
                            url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                              clientId: uuid,
                              uuid: item.uuid
                            })
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button color="secondary">Cancel app</Button>
                    </Stack>
                  )}
                />
              ) : (
                <TableView
                  showEmptyState
                  EmptyStateProps={{
                    description: null
                  }}
                  loading={isLoading}
                  showTablePerPage={false}
                  showPagination={true}
                  pagination={pagination}
                  dataSource={appointmentsData}
                  keyExtractor={(item) => item.uuid}
                  onRowClick={() => navigate(pathname + '#appointments')}
                  columns={[
                    ...clientAppointmentColumns,
                    {
                      title: '',
                      dataIndex: 'actions',
                      key: 'actions',
                      render: (_, { uuid }) => (
                        <Stack direction="row" alignItems="center">
                          <Tooltip title="Edit" placement="bottom-end">
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigate(
                                  url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                                    clientId: uuid,
                                    uuid
                                  })
                                );
                              }}
                            >
                              <IconPen />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom-end">
                            <IconButton size="small">
                              <IconAppointmentCancel />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      )
                    }
                  ]}
                />
              )}
            </Card>
            <Card>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12}>
                  <CaseLoadWidget employeeUuid={uuid} />
                </Grid>
                <Grid item xs={12}>
                  <ClientListWidget employeeUuid={uuid} />
                </Grid>
                <Grid item xs={12}>
                  <FilesListWidget employeeUuid={uuid} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
