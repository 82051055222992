export const referralTypeColumns = [
  {
    title: 'Referral Type',
    key: 'title',
    dataIndex: 'title'
  }
];

export const referralTypeData = [
  { id: 1, name: 'Insurance' },
  { id: 2, name: 'Yellow POS' },
  { id: 3, name: 'SANDIS' }
];
