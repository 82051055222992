import { DataTableColumn } from '../../components/table-view/TableView';

export const rolesColumn: DataTableColumn[] = [
  {
    title: 'Role Name',
    dataIndex: 'name',
    key: 'name',
    sortable: true
  },
  {
    title: 'Update Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  }
];

export const rolesData = [
  {
    name: 'Super Administrator',
    updatedAt: '02/02/2022 01:34 PM'
  },
  {
    name: 'Administrator',
    updatedAt: '02/02/2022 01:34 PM'
  },
  {
    name: 'Sales Manager',
    updatedAt: '02/02/2022 01:34 PM'
  }
];
