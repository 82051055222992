import React, { ComponentType } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { AppointmentCancelTypeField } from '../../../components/fields/appointment-cancel-type-field';
import { TextField } from '../../../components/fields';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AppointmentApi, AppointmentDto } from '../../../core/http';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { normalizeDate } from '../../../core';

interface Props extends DialogProps {
  appointment: AppointmentDto;
  onClose?: () => void;
  onSuccess: (value: any) => void;
  values?: null;
}

const appointmentApi = new AppointmentApi();

export const CancelAppointmentDialog: ComponentType<Props> = ({
  appointment,
  popDialog,
  onSuccess
}) => {
  const { showMessage } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      uuid: '',
      cancelTypeUuid: '',
      cancelReason: '',
      strategy: ''
    },
    validationSchema: yup.object().shape({
      cancelTypeUuid: yup.string().required('Please select a cancel type'),
      cancelReason: yup.string().required('Please enter a cancel reason')
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      appointmentApi
        .cancel(appointment.uuid, values)
        .then(() => {
          onSuccess && onSuccess(values);
          showMessage('Appointment cancelled successfully');
          popDialog();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  });

  const handleStrategy = (strategy) => () => {
    setFieldValue('uuid', appointment.uuid);
    setFieldValue('strategy', strategy);
    handleSubmit();
  };

  const fromTo = `from ${normalizeDate(
    appointment.startDate
  )} to ${normalizeDate(appointment.endDate)}`;

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 600 }
      }}
    >
      <DialogTitle>Cancel appointment</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <AppointmentCancelTypeField
            label="Cancellation type*"
            placeholder="Select"
            onChange={(value) => setFieldValue('cancelTypeUuid', value)}
            errors={touched.cancelTypeUuid && errors.cancelTypeUuid}
            value={values.cancelTypeUuid}
          />
          <TextField
            label="Reason for cancellation*"
            rows={3}
            multiline
            onChange={(value) => setFieldValue('cancelReason', value)}
            errors={touched.cancelReason && errors.cancelReason}
            value={values.cancelReason}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
        >
          <Typography variant="body3">
            Other events in the same series will remain the same
          </Typography>
          <Box width={164} display="flex" justifyContent="flex-end">
            <Button disabled={isLoading} onClick={handleStrategy('current')}>
              Save only this event
            </Button>
          </Box>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
        >
          <Typography variant="body3">
            Save this change for entire series {fromTo}
          </Typography>
          <Box width={164} display="flex" justifyContent="flex-end">
            <Button disabled={isLoading} onClick={handleStrategy('all')}>
              Save all events
            </Button>
          </Box>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
        >
          <Typography variant="body3">
            Save this change for all appointment {fromTo}
          </Typography>
          <Box width={164} display="flex" justifyContent="flex-end">
            <Button disabled={isLoading} onClick={handleStrategy('next')}>
              Save all next events
            </Button>
          </Box>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1.5}
          pb={3.5}
        >
          <Typography variant="body3">
            Return to the edit page without saving
          </Typography>
          <Button
            disabled={isLoading}
            color="third"
            onClick={() => popDialog()}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
