import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { FundingSourceDto } from '../../../core/http';
import { useRegion } from '../../../core/hooks/useRegion';

export const RegionField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useRegion();

  const selectItems = map(items, (item: FundingSourceDto) => ({
    value: item.uuid,
    label: item.name
  }));
  return (
    <SelectField
      label="Region"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
