import { ComponentType, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Stack,
  Skeleton,
  Avatar,
  Typography,
  Button,
  Grid
} from '@mui/material';
import { url, urlParamsToObject } from '../../core';
import { useDialog } from '../../core/hooks/useDialog';
import { IconInfo } from '../../components/icons';
import { Badge } from '../../components/badge';
import { useUser } from '../../core/hooks/useUser';
import avatar from '../../assets/avatar.png';
import { ChangePasswordDialog } from './dialogs/ChangePasswordDialog';
import { Manage2FADialog } from './dialogs/Manage2FADialog';
import { ROUTE_PROFILE, ROUTE_PROFILE_EDIT } from '../../constants/route.paths';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import { LabeledRecord } from '../../components/labeled-record';

interface TempParams {
  rf?: string;
}

export const Profile: ComponentType = () => {
  const navigate = useNavigate();
  const [openChangePasswordDialog] = useDialog(ChangePasswordDialog);
  const [openManage2FADialog] = useDialog(Manage2FADialog);
  const { search } = useLocation();

  const params: TempParams = urlParamsToObject(search);

  const { user, loading, fetchMe } = useUser();

  useEffect(() => {
    if (params?.rf) {
      fetchMe();
      navigate(ROUTE_PROFILE);
    }
  }, [params]);

  const headerProps = {
    divider: true,
    title:
      loading || !user ? (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="circular" sx={{ height: 32, width: 32 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Avatar src={user?.avatar || avatar} />
          <Typography variant="h2">My Profile</Typography>
        </Stack>
      ),
    meta:
      loading || !user ? (
        <>
          <Skeleton variant="text" sx={{ height: 32, width: 32 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 80 }} />
        </>
      ) : (
        <>
          <Badge label={user.id} variant={'secondary'} />
          <Badge label="ACTIVE" variant="active" RightIcon={<IconInfo />} />
        </>
      )
  };
  return (
    <>
      <PageHeader title="" {...headerProps}>
        <Button color="secondary" onClick={openManage2FADialog}>
          Manage 2FA
        </Button>
        <Button color="secondary" onClick={openChangePasswordDialog}>
          Change password
        </Button>
        <Button
          color="secondary"
          onClick={() => navigate(url(ROUTE_PROFILE_EDIT, { id: user?.uuid }))}
        >
          Edit
        </Button>
      </PageHeader>
      <Card title="General Information">
        <Grid container>
          <Grid item md={3}>
            <LabeledRecord
              label="Administrator Name"
              value={user?.displayName || '-'}
            />
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Email" value={user?.email || '-'} />
          </Grid>
          <Grid item md={3}>
            <LabeledRecord
              label="Phone Number"
              value={user?.phoneNumber || '-'}
            />
          </Grid>
          <Grid item md={3}>
            <LabeledRecord label="Role" value={user.roleTitle} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
