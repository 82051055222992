import { CompareAvailability } from '../../features/appointments/CompareAvailablity';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const CompareAvailabilityPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Compare Availability'
  })
)(CompareAvailability);
