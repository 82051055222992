import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  indexAxis: 'y' as const,
  responsive: true,
  options: {
    scales: {
      y: {
        stacked: true
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    },
    datasets: {
      label: {
        display: true
      }
    },
    datalabels: {
      display: true,
      align: function (context) {
        if (context.dataset.data[context.dataIndex] > 0) {
          return 'end' as const;
        }
        return 'start' as const;
      },
      anchor: function (context) {
        if (context.dataset.data[context.dataIndex] > 0) {
          return 'end' as const;
        }
        return 'start' as const;
      },
      formatter: (value) => value
    }
  },

  scales: {
    xAxis: {
      grid: {
        display: true,
        drawBorder: false
      },
      ticks: {
        display: true,
        color: '#4B5663',
        callback: (value) => value
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: false
      },
      scaleLabel: {
        display: false
      },
      ticks: {
        display: true
      }
    }
  }
};
