import { RegionContainer } from '../../features/master-data/region/RegionContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataRegion = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Regions'
  })
)(RegionContainer);
