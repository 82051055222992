import { FC } from 'react';
import { Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { TableView } from '../../../components/table-view';
import { administratorsColumn, administratorsData } from './_mockdata';

interface Props extends DialogProps {
  uuid: string;
  userName: string;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const SuperAdminHistoryDialog: FC<Props> = ({ popDialog }) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 800 } }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Company administrators
          <Button color="third" onClick={popDialog}>
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent dividers={false}>
        <TableView
          showEmptyState
          showTablePerPage
          showPagination
          dataSource={administratorsData}
          columns={administratorsColumn}
        />
      </DialogContent>
    </Dialog>
  );
};
