import {
  PathRouteProps,
  Route,
  Routes as RouterRoutes
} from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import {
  ROUTE_PROFILE,
  ROUTE_SIGN_IN,
  ROUTE_CONNECT_TO_ADMINISTRATOR,
  ROUTE_TWO_FACTOR,
  ROUTE_TWO_FACTOR_RECOVERY,
  ROUTE_RESET_PASSWORD,
  ROUTE_CREATE_NEW_PASSWORD,
  ROUTE_RESET_PASSWORD_SUCCESS,
  ROUTE_INVITE,
  ROUTE_TWO_FACTOR_SETUP,
  ROUTE_TWO_FACTOR_SETUP_SUCCESS,
  ROUTE_CLIENTS,
  ROUTE_CLIENT_DETAILS,
  ROUTE_CLIENT_PROFILE_EDIT,
  ROUTE_CLIENT_PROFILE_CREATE,
  ROUTE_TWO_FACTOR_SETUP_2,
  ROUTE_FILES,
  ROUTE_DASHBOARDS,
  ROUTE_EMPLOYEES,
  ROUTE_EMPLOYEE_DETAILS,
  ROUTE_EMPLOYEE_PROFILE_EDIT,
  ROUTE_EMPLOYEE_PROFILE_CREATE,
  ROUTE_APPOINTMENTS,
  ROUTE_APPOINTMENTS_LIST,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_REPORTS,
  ROUTE_TIMESHEET,
  ROUTE_BILLING,
  ROUTE_REMINDERS,
  ROUTE_NEWS,
  ROUTE_MASTER_DATA,
  ROUTE_ROLES,
  ROUTE_EDIT_EMPLOYEE_REMINDER,
  ROUTE_CREATE_EMPLOYEE_REMINDER,
  ROUTE_EDIT_CLIENT_REMINDER,
  ROUTE_CREATE_CLIENT_REMINDER,
  ROUTE_HOME,
  ROUTE_MASTER_DATA_REGION,
  ROUTE_MASTER_DATA_LOCATION,
  ROUTE_MASTER_DATA_APPOINTMENT_TYPE,
  ROUTE_MASTER_DATA_PLACE_OF_SERVICE,
  ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE,
  ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE_STATUS,
  ROUTE_MASTER_DATA_REFERRAL_TYPE,
  ROUTE_MASTER_DATA_SERVICE_DESCRIPTION,
  ROUTE_MASTER_DATA_SERVICE_TYPE,
  ROUTE_MASTER_DATA_PROFIT_CENTER,
  ROUTE_MASTER_DATA_TASK_TYPE,
  ROUTE_MASTER_DATA_THERAPY_TITLE,
  ROUTE_MASTER_DATA_HOLIDAY,
  ROUTE_MASTER_DATA_EMPLOYEE_GROUPS,
  ROUTE_MASTER_DATA_NOTIFICATION_MESSAGES,
  ROUTE_MASTER_DATA_UPLOAD_HANDBOOK,
  ROUTE_MASTER_DATA_SERVICE_CODE,
  ROUTE_MASTER_DATA_FUNDING_SOURCE,
  ROUTE_MASTER_DATA_INTERNAL_WORK_TYPES,
  ROUTE_CLIENT_APPOINTMENT_CREATE,
  ROUTE_CLIENT_APPOINTMENT_EDIT,
  ROUTE_EMPLOYEE_APPOINTMENT_CREATE,
  ROUTE_APPOINTMENTS_COMPARE_AVAILABILITY,
  ROUTE_EMPLOYEE_APPOINTMENT_EDIT,
  ROUTE_MAKE_UP_APPOINTMENTS,
  ROUTE_MAKE_UP_APPOINTMENTS_CREATE,
  ROUTE_MAKE_UP_APPOINTMENTS_EDIT,
  ROUTE_APPOINTMENTS_CREATE,
  ROUTE_REPORTS_TIMESHEET_EDIT,
  ROUTE_CLIENT_SERVICE_AUTHORIZATION_CREATE,
  ROUTE_CLIENT_SERVICE_AUTHORIZATION_EDIT,
  ROUTE_BILLING_DETAILS,
  ROUTE_PROFILE_EDIT,
  ROUTE_ROLES_EDIT,
  ROUTE_ROLES_CREATE,
  ROUTE_DASHBOARDS_SELECTED,
  ROUTE_EMPLOYEE_APPOINTMENT_VIEW,
  ROUTE_CLIENT_APPOINTMENT_VIEW,
  ROUTE_NEWS_CREATE,
  ROUTE_NEWS_EDIT,
  ROUTE_SUPER_ADMIN_COMPANIES,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT,
  ROUTE_SUPER_ADMIN_ADMINISTRATORS,
  ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE,
  ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_EDIT,
  ROUTE_SUPER_ADMIN_USERS,
  ROUTE_SUPER_ADMIN_USER_PROFILE,
  ROUTE_SUPER_ADMIN_USER_PROFILE_EDIT,
  ROUTE_SUPER_ADMIN_ROLES,
  ROUTE_SUPER_ADMIN_ROLE_EDIT,
  ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_CREATE,
  ROUTE_SUPER_ADMIN_USER_PROFILE_CREATE,
  ROUTE_SUPER_ADMIN_ROLE_CREATE,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE_CREATE,
  ROUTE_MASTER_DATA_EDIT_FUNDING_SOURCE,
  ROUTE_MASTER_DATA_CREATE_FUNDING_SOURCE,
  ROUTE_MASTER_DATA_EDIT_SERVICE_CODE,
  ROUTE_MASTER_DATA_CREATE_SERVICE_CODE
} from '../constants/route.paths';
import { SignInPage } from '../pages/auth/sign-in';
import { ConnectToAdministratorPage } from '../pages/auth/connect-to-administrator';
import { TwoFactorPage } from '../pages/auth/two-factor';
import { TwoFactorRecoveryPage } from '../pages/auth/two-factor-recovery';
import { ResetPasswordPage } from '../pages/auth/reset-password';
import { CreateNewPasswordPage } from '../pages/auth/create-new-password';
import { ResetPasswordSuccessPage } from '../pages/auth/reset-password-success';
import { InvitePage } from '../pages/auth/invite';
import { TwoFactorSetupPage } from '../pages/auth/two-factor-setup';
import { TwoFactorSetupSuccessPage } from '../pages/auth/two-factor-setup-success';
import { ClientsListPage } from '../pages/clients/clients-list';
import { AppointmentsSearchPage } from '../pages/appointments/appointments-search';
import { AppointmentsListPage } from '../pages/appointments/appointments-list';
import { EmployeesListPage } from '../pages/employees/employees-list';
import { EmployeeDetailsPage } from '../pages/employees/employee-details';
import { EmployeeProfileEditPage } from '../pages/employees/employee-profile/edit';
import { EmployeeProfileCreatePage } from '../pages/employees/employee-profile/create';
import { ClientDetailsPage } from '../pages/clients/client-details';
import { ClientProfileEditPage } from '../pages/clients/client-profile/edit';
import { ClientProfileCreatePage } from '../pages/clients/client-profile/create';
import { TwoFactorSetupStepTwoPage } from '../pages/auth/two-factor-setup-step-two';
import { FilesPage } from '../pages/files';
import { EmptyPage } from '../pages/empty-page';
import { RemindersPage } from '../pages/reminders';
import { EditEmployeeReminderPage } from '../pages/reminders/employee/edit';
import { CreateEmployeeReminderPage } from '../pages/reminders/employee/create';
import { EditClientReminderPage } from '../pages/reminders/client/edit';
import { CreateClientReminderPage } from '../pages/reminders/client/create';
import { MasterDataPage } from '../pages/master-data';
import { MasterDataRegion } from '../pages/master-data/region';
import { MasterDataLocation } from '../pages/master-data/location';
import { MasterDataAppointmentType } from '../pages/master-data/appointment-type';
import { MasterDataPlaceOfService } from '../pages/master-data/place-of-service';
import { MasterDataAppointmentCancelType } from '../pages/master-data/appointment-cancel-type';
import { MasterDataAppointmentCancelTypeStatus } from '../pages/master-data/appointment-cancel-type-status';
import { MasterDataReferralType } from '../pages/master-data/referral-type';
import { MasterDataServiceDescription } from '../pages/master-data/service-description';
import { MasterDataServiceType } from '../pages/master-data/service-type';
import { MasterDataProfitCenter } from '../pages/master-data/profile-center';
import { MasterDataTaskType } from '../pages/master-data/task-type';
import { MasterDataTherapyTitle } from '../pages/master-data/therapy-title';
import { MasterDataHoliday } from '../pages/master-data/holiday';
import { MasterDataEmployeeGroup } from '../pages/master-data/employee-group';
import { MasterDataNotificationMessages } from '../pages/master-data/notification-messages';
import { MasterDataServiceCode } from '../pages/master-data/service-code';
import { MasterDataFundingSource } from '../pages/master-data/funding-source';
import { MasterDataFundingSourceEdit } from '../pages/master-data/funding-source-edit';
import { MasterDataInternalWorkType } from '../pages/master-data/internal-work-type';
import { ClientAppointmentEditPage } from '../pages/clients/client-details/appointment-edit';
import { EmployeeAppointmentEditPage } from '../pages/employees/employee-details/appointment-edit';
import { NotFoundPage } from '../pages/not-found-page';
import { MakeUpAppointmentsListPage } from '../pages/appointments/make-up-appointments-list';
import { MakeUpAppointmentsFormPage } from '../pages/appointments/make-up-appointments-form';
import { AppointmentCreatePage } from '../pages/appointments/appointment-create';
import { TimeSheetsListPage } from '../pages/timesheets/timesheets-list';
import { ReportsPage } from '../pages/reports';
import { ReportsTimeSheetEditorPage } from '../pages/reports/time-sheet-editor';
import { ClientServiceAuthorizationCreate } from '../pages/clients/client-service-authorization/create';
import { ClientServiceAuthorizationEdit } from '../pages/clients/client-service-authorization/edit';
import { CompareAvailabilityPage } from '../pages/appointments/compare-availability';
import { BillingPage } from '../pages/billing';
import { BillingDetailsPage } from '../pages/billing/billing-details-page';
import { ProfilePage } from '../pages/profile/Profile';
import { ProfileEditPage } from '../pages/profile/ProfileEdit';
import { RolesPage } from '../pages/roles';
import { RolesEditPage } from '../pages/roles/edit';
import { AnnouncementsPage } from '../pages/announcements';
import { NewsPage } from '../pages/news';
import { NewsEditPage } from '../pages/news/edit';
import { DashboardsSelectPage } from '../pages/dashboards';
import { DashboardsPage } from '../pages/dashboards/Dashboards';
import { ClientAppointmentViewPage } from '../pages/clients/client-details/appointment-view';
import { EmployeeAppointmentViewPage } from '../pages/employees/employee-details/appointment-view';
import { SuperAdminCompaniesPage } from '../pages/super-admin-companies';
import { SuperAdminCompanyProfile } from '../pages/super-admin-companies/super-admin-company-profile';
import { SuperAdminCompanyEdit } from '../pages/super-admin-companies/super-admin-company-edit';
import { SuperAdminAdministratorsPage } from '../pages/super-admin-administrators';
import { SuperAdminAdministratorProfile } from '../pages/super-admin-administrators/super-admin-administrator-profile';
import { SuperAdminAdministratorEdit } from '../pages/super-admin-administrators/super-admin-administrator-edit';
import { SuperAdminUsersPage } from '../pages/super-admin-users';
import { SuperAdminUserProfile } from '../pages/super-admin-users/super-admin-user-profile';
import { SuperAdminUserEdit } from '../pages/super-admin-users/super-admin-user-edit';
import { SuperAdminRolesPage } from '../pages/super-admin-roles';
import { SuperAdminRoleEdit } from '../pages/super-admin-roles/super-admin-role-edit';
import { useUser } from '../core/hooks/useUser';
import { PermissionRoute } from './PermissonRoute';
import { MasterDataEditServiceCode } from '../pages/master-data/service-code-edit';

interface PermissionRouteProps extends PathRouteProps {
  permission?: string;
}

const openRoutes: PathRouteProps[] = [
  {
    path: ROUTE_SIGN_IN,
    element: <SignInPage />
  },
  {
    path: ROUTE_CONNECT_TO_ADMINISTRATOR,
    element: <ConnectToAdministratorPage />
  },
  {
    path: ROUTE_TWO_FACTOR,
    element: <TwoFactorPage />
  },
  {
    path: ROUTE_TWO_FACTOR_RECOVERY,
    element: <TwoFactorRecoveryPage />
  },
  {
    path: ROUTE_RESET_PASSWORD,
    element: <ResetPasswordPage />
  },
  {
    path: ROUTE_CREATE_NEW_PASSWORD,
    element: <CreateNewPasswordPage />
  },
  {
    path: ROUTE_RESET_PASSWORD_SUCCESS,
    element: <ResetPasswordSuccessPage />
  },
  {
    path: ROUTE_INVITE,
    element: <InvitePage />
  },
  {
    path: ROUTE_TWO_FACTOR_SETUP,
    element: <TwoFactorSetupPage />
  },
  {
    path: ROUTE_TWO_FACTOR_SETUP_SUCCESS,
    element: <TwoFactorSetupSuccessPage />
  },
  {
    path: ROUTE_TWO_FACTOR_SETUP_2,
    element: <TwoFactorSetupStepTwoPage />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
];

export const protectedRoutes: PermissionRouteProps[] = [
  {
    path: ROUTE_PROFILE,
    element: <ProfilePage />
  },
  {
    path: ROUTE_PROFILE_EDIT,
    element: <ProfileEditPage />
  },
  {
    path: ROUTE_DASHBOARDS,
    element: <DashboardsSelectPage />,
    permission: 'viewDashboard'
  },
  {
    path: ROUTE_DASHBOARDS_SELECTED,
    element: <DashboardsPage />,
    permission: 'viewDashboard'
  },
  {
    path: ROUTE_HOME,
    element: <ClientsListPage />,
    permission: 'viewClients'
  },
  {
    path: ROUTE_EMPLOYEES,
    element: <EmployeesListPage />,
    permission: 'viewEmployees'
  },
  {
    path: ROUTE_EMPLOYEE_DETAILS,
    element: <EmployeeDetailsPage />,
    permission: 'viewEmployees'
  },
  {
    path: ROUTE_EMPLOYEE_APPOINTMENT_CREATE,
    element: <EmployeeAppointmentEditPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_EMPLOYEE_APPOINTMENT_VIEW,
    element: <EmployeeAppointmentViewPage />,
    permission: 'viewAppointments'
  },
  {
    path: ROUTE_EMPLOYEE_APPOINTMENT_EDIT,
    element: <EmployeeAppointmentEditPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_EMPLOYEE_PROFILE_EDIT,
    element: <EmployeeProfileEditPage />
  },
  {
    path: ROUTE_EMPLOYEE_PROFILE_CREATE,
    element: <EmployeeProfileCreatePage />,
    permission: 'modifyEmployees'
  },
  {
    path: ROUTE_APPOINTMENTS,
    element: <AppointmentsSearchPage />,
    permission: 'viewAppointments'
  },
  {
    path: ROUTE_APPOINTMENTS_CREATE,
    element: <AppointmentCreatePage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_MAKE_UP_APPOINTMENTS,
    element: <MakeUpAppointmentsListPage />,
    permission: 'viewAppointments'
  },
  {
    path: ROUTE_MAKE_UP_APPOINTMENTS_CREATE,
    element: <MakeUpAppointmentsFormPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_APPOINTMENTS_COMPARE_AVAILABILITY,
    element: <CompareAvailabilityPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_MAKE_UP_APPOINTMENTS_EDIT,
    element: <MakeUpAppointmentsFormPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_APPOINTMENTS_LIST,
    element: <AppointmentsListPage />,
    permission: 'viewAppointments'
  },
  {
    path: ROUTE_ANNOUNCEMENTS,
    element: <AnnouncementsPage />,
    permission: 'viewAnnouncements'
  },
  {
    path: ROUTE_REPORTS,
    element: <ReportsPage />,
    permission: 'viewAnnouncements'
  },
  {
    path: ROUTE_REPORTS_TIMESHEET_EDIT,
    element: <ReportsTimeSheetEditorPage />,
    permission: 'viewBillingReport'
  },
  {
    path: ROUTE_TIMESHEET,
    element: <TimeSheetsListPage />,
    permission: 'viewTimesheets'
  },
  {
    path: ROUTE_BILLING,
    element: <BillingPage />
  },
  {
    path: ROUTE_BILLING_DETAILS,
    element: <BillingDetailsPage />
  },
  {
    path: ROUTE_NEWS,
    element: <NewsPage />,
    permission: 'viewNews'
  },
  {
    path: ROUTE_NEWS_CREATE,
    element: <NewsEditPage />,
    permission: 'modifyNews'
  },
  {
    path: ROUTE_NEWS_EDIT,
    element: <NewsEditPage />,
    permission: 'modifyNews'
  },
  {
    path: ROUTE_ROLES,
    element: <RolesPage />,
    permission: 'viewCreateRoleAndPermissions'
  },
  {
    path: ROUTE_ROLES_CREATE,
    element: <RolesEditPage />,
    permission: 'modifyCreateRoleAndPermissions'
  },
  {
    path: ROUTE_ROLES_EDIT,
    element: <RolesEditPage />,
    permission: 'modifyCreateRoleAndPermissions'
  },
  {
    path: ROUTE_CLIENTS,
    element: <ClientsListPage />,
    permission: 'viewClients'
  },
  {
    path: ROUTE_CLIENT_DETAILS,
    element: <ClientDetailsPage />,
    permission: 'viewClients'
  },
  {
    path: ROUTE_CLIENT_APPOINTMENT_CREATE,
    element: <ClientAppointmentEditPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_CLIENT_APPOINTMENT_VIEW,
    element: <ClientAppointmentViewPage />,
    permission: 'viewAppointments'
  },
  {
    path: ROUTE_CLIENT_APPOINTMENT_EDIT,
    element: <ClientAppointmentEditPage />,
    permission: 'modifyAppointments'
  },
  {
    path: ROUTE_CLIENT_PROFILE_EDIT,
    element: <ClientProfileEditPage />,
    permission: 'modifyClients'
  },
  {
    path: ROUTE_CLIENT_PROFILE_CREATE,
    element: <ClientProfileCreatePage />,
    permission: 'modifyClients'
  },
  {
    path: ROUTE_CLIENT_SERVICE_AUTHORIZATION_CREATE,
    element: <ClientServiceAuthorizationCreate />,
    permission: 'modifyClients'
  },
  {
    path: ROUTE_CLIENT_SERVICE_AUTHORIZATION_EDIT,
    element: <ClientServiceAuthorizationEdit />,
    permission: 'modifyClients'
  },
  {
    path: ROUTE_FILES,
    element: <FilesPage />,
    permission: 'viewUploadNewFiles'
  },
  {
    path: ROUTE_REMINDERS,
    element: <RemindersPage />,
    permission: 'viewMyReminders'
  },
  {
    path: ROUTE_EDIT_EMPLOYEE_REMINDER,
    element: <EditEmployeeReminderPage />,
    permission: 'modifyMyReminders'
  },
  {
    path: ROUTE_CREATE_EMPLOYEE_REMINDER,
    element: <CreateEmployeeReminderPage />,
    permission: 'modifyMyReminders'
  },
  {
    path: ROUTE_EDIT_CLIENT_REMINDER,
    element: <EditClientReminderPage />,
    permission: 'modifyMyReminders'
  },
  {
    path: ROUTE_CREATE_CLIENT_REMINDER,
    element: <CreateClientReminderPage />,
    permission: 'modifyMyReminders'
  },
  {
    path: ROUTE_MASTER_DATA,
    element: <MasterDataPage />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_REGION,
    element: <MasterDataRegion />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_LOCATION,
    element: <MasterDataLocation />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_APPOINTMENT_TYPE,
    element: <MasterDataAppointmentType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_PLACE_OF_SERVICE,
    element: <MasterDataPlaceOfService />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE,
    element: <MasterDataAppointmentCancelType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE_STATUS,
    element: <MasterDataAppointmentCancelTypeStatus />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_REFERRAL_TYPE,
    element: <MasterDataReferralType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_SERVICE_DESCRIPTION,
    element: <MasterDataServiceDescription />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_SERVICE_TYPE,
    element: <MasterDataServiceType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_PROFIT_CENTER,
    element: <MasterDataProfitCenter />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_TASK_TYPE,
    element: <MasterDataTaskType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_THERAPY_TITLE,
    element: <MasterDataTherapyTitle />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_HOLIDAY,
    element: <MasterDataHoliday />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_EMPLOYEE_GROUPS,
    element: <MasterDataEmployeeGroup />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_NOTIFICATION_MESSAGES,
    element: <MasterDataNotificationMessages />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_SERVICE_CODE + '/*',
    element: <MasterDataServiceCode />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_EDIT_SERVICE_CODE,
    element: <MasterDataEditServiceCode />,
    permission: 'modifyMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_CREATE_SERVICE_CODE,
    element: <MasterDataEditServiceCode />,
    permission: 'modifyMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_FUNDING_SOURCE + '/*',
    element: <MasterDataFundingSource />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_EDIT_FUNDING_SOURCE,
    element: <MasterDataFundingSourceEdit />,
    permission: 'modfiyMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_CREATE_FUNDING_SOURCE,
    element: <MasterDataFundingSourceEdit />,
    permission: 'modfiyMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_UPLOAD_HANDBOOK,
    element: <EmptyPage title="Upload handbook" />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_MASTER_DATA_INTERNAL_WORK_TYPES,
    element: <MasterDataInternalWorkType />,
    permission: 'viewMasterData'
  },
  {
    path: ROUTE_SUPER_ADMIN_COMPANIES,
    element: <SuperAdminCompaniesPage />
  },
  {
    path: ROUTE_SUPER_ADMIN_COMPANY_PROFILE,
    element: <SuperAdminCompanyProfile />
  },
  {
    path: ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT,
    element: <SuperAdminCompanyEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_COMPANY_PROFILE_CREATE,
    element: <SuperAdminCompanyEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_ADMINISTRATORS,
    element: <SuperAdminAdministratorsPage />
  },
  {
    path: ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE,
    element: <SuperAdminAdministratorProfile />
  },
  {
    path: ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_EDIT,
    element: <SuperAdminAdministratorEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_CREATE,
    element: <SuperAdminAdministratorEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_USERS,
    element: <SuperAdminUsersPage />
  },
  {
    path: ROUTE_SUPER_ADMIN_USER_PROFILE,
    element: <SuperAdminUserProfile />
  },
  {
    path: ROUTE_SUPER_ADMIN_USER_PROFILE_EDIT,
    element: <SuperAdminUserEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_USER_PROFILE_CREATE,
    element: <SuperAdminUserEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_ROLES,
    element: <SuperAdminRolesPage />
  },
  {
    path: ROUTE_SUPER_ADMIN_ROLE_EDIT,
    element: <SuperAdminRoleEdit />
  },
  {
    path: ROUTE_SUPER_ADMIN_ROLE_CREATE,
    element: <SuperAdminRoleEdit />
  }
];

export const Routes = () => {
  const { user, loading } = useUser();

  if (localStorage.getItem('login') && (loading || !user)) {
    return null;
  }

  return (
    <RouterRoutes>
      {openRoutes.map((route, index) => {
        return <Route key={index} {...route} />;
      })}
      <Route path="/" element={<ProtectedRoute />}>
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <PermissionRoute user={user} permission={route.permission} />
              }
            >
              <Route path={route.path} element={route.element} />
            </Route>
          );
        })}
      </Route>
    </RouterRoutes>
  );
};
