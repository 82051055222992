import React, { ComponentType, useMemo } from 'react';
import LayoutContext from '../contexts/layout.context';

interface BackButton {
  title: string;
  path?: string;
  onClick?: () => void;
}

export const LayoutProvider: ComponentType = ({ children }) => {
  const [backButton, setBackButton] = React.useState<BackButton | null>(null);

  const value = useMemo(
    () => ({
      backButton,
      setBackButton
    }),
    [backButton]
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
