import { ComponentType, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ROUTE_CLIENT_DETAILS,
  ROUTE_CLIENTS
} from '../../constants/route.paths';
import { url } from '../../core';
import { useLayout } from '../../core/hooks/useLayout';
import { diffObjectsValues, getObjectValues, normalizeDate } from '../../core';
import { useEditClientResource } from '../../core/hooks/useEditClientResource';
import { useDialog } from '../../core/hooks/useDialog';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { Badge } from '../../components/badge';

import {
  Box,
  Button,
  Stack,
  Grid,
  Typography,
  Skeleton,
  FormControlLabel
} from '@mui/material';
import {
  TextField,
  SelectField,
  DatePicker,
  Checkbox,
  FundingSourceField,
  LanguageField,
  ProfitCenterField,
  NumberField
} from '../../components/fields';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { ClientContactForm } from './ClientContactForm';
import { ClientInsuredContactForm } from './ClientInsuredContactForm';
import isEmpty from 'lodash/isEmpty';

export const ClientForm: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const {
    isEdit,
    isLoading,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    primaryContactProps,
    secondaryContactProps,
    emergencyContactProps,
    insuredPersonProps,
    setFieldTouched,
    data
  } = useEditClientResource({
    uuid: id,
    onSuccess: () => {
      navigate(url(ROUTE_CLIENTS));
      if (isEdit) {
        showMessage(`Client ${data.displayName} updated successfully`);
      } else {
        showMessage(`Client ${data.displayName} created successfully`);
      }
    },
    onError: () => {
      showMessage('Something went wrong, please try again later', 'error');
    }
  });

  const primaryContactValues = primaryContactProps.values;
  const secondaryContactValues = secondaryContactProps.values;
  const emergencyContactValues = emergencyContactProps.values;
  const insuredPersonValues = insuredPersonProps.values;

  const backButtonProps = {
    title: isEdit ? 'Client Profile' : 'Clients',
    path: isEdit ? url(ROUTE_CLIENT_DETAILS, { id }) : url(ROUTE_CLIENTS)
  };

  const [isEmergency, setIsEmergency] = useState<boolean>(false);
  const [fillAsPrimary, setFillAsPrimary] = useState<boolean>(false);

  useEffect(() => {
    const emergencyDiff = diffObjectsValues(
      primaryContactValues,
      emergencyContactValues,
      ['firstName', 'lastName']
    );

    const insuredDiff = diffObjectsValues(
      primaryContactValues,
      insuredPersonValues,
      ['firstName', 'lastName']
    );

    if (id && isEmpty(emergencyDiff)) {
      setIsEmergency(true);
    }

    if (id && isEmpty(insuredDiff)) {
      setFillAsPrimary(true);
    }
  }, [id, isLoading]);

  useEffect(() => {
    if (isEmergency) {
      emergencyContactProps.setValues({
        ...primaryContactValues,
        type: 'emergency',
        uuid: emergencyContactValues.uuid
      });
    } else {
      emergencyContactProps.resetForm();
    }
  }, [isEmergency, primaryContactValues]);

  useEffect(() => {
    if (fillAsPrimary) {
      insuredPersonProps.setValues({
        ...insuredPersonValues,
        ...getObjectValues(primaryContactValues, [
          'firstName',
          'lastName',
          'addressLine1',
          'addressLine2',
          'cityUuid',
          'stateUuid',
          'zipCode'
        ]),
        type: 'insured'
      });
    }
  }, [fillAsPrimary, primaryContactValues]);

  useLayout({
    backButton: backButtonProps
  });

  const handleCancel = () => () => {
    openConfirm({
      title: 'Cancel',
      message: 'Do you really want to cancel ? ',
      onConfirm: () => {
        if (isEdit) {
          navigate(url(ROUTE_CLIENT_DETAILS, { id }));
        } else {
          navigate(url(ROUTE_CLIENTS));
        }
        closeConfirm();
      }
    });
  };
  const headerProps = {
    divider: false,
    title: isEdit ? 'Edit Client Profile' : 'Add Client Profile',
    meta: isEdit && (
      <Stack direction="row" spacing={1}>
        {isLoading ? (
          <>
            <Skeleton variant="text" sx={{ height: 32, width: 32 }} />
            <Skeleton variant="text" sx={{ height: 32, width: 80 }} />
          </>
        ) : (
          <>
            <Badge label={values.id} variant={'secondary'} />
            <Badge label={values?.status} variant={values?.status} />
          </>
        )}
      </Stack>
    )
  };
  const validateAndSubmit = () => {
    Object.keys(primaryContactValues).forEach((key) => {
      primaryContactProps.setFieldTouched(key, true);
    });
    Object.keys(secondaryContactValues).forEach((key) => {
      secondaryContactProps.setFieldTouched(key, true);
    });
    Object.keys(emergencyContactValues).forEach((key) => {
      emergencyContactProps.setFieldTouched(key, true);
    });
    Object.keys(insuredPersonValues).forEach((key) => {
      insuredPersonProps.setFieldTouched(key, true);
    });
    handleSubmit();
  };

  return (
    <>
      <PageHeader {...headerProps}>
        <Button
          onClick={() => {
            validateAndSubmit();
          }}
        >
          Save
        </Button>
        <Button color="secondary" onClick={handleCancel()}>
          Cancel
        </Button>
      </PageHeader>
      <Stack mt={1.5} spacing={1} direction="column">
        <Card title="General Information">
          <Box>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  disabled={isLoading}
                  errors={touched.firstName && errors.firstName}
                  label="First Name"
                  value={values.firstName}
                  onChange={(e) => {
                    setFieldTouched('firstName');
                    setFieldValue('firstName', e);
                  }}
                  onBlur={handleBlur('firstName')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  disabled={isLoading}
                  errors={touched.lastName && errors.lastName}
                  label="Last Name"
                  value={values.lastName}
                  onChange={(e) => {
                    setFieldTouched('lastName');
                    setFieldValue('lastName', e);
                  }}
                  onBlur={handleBlur('lastName')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <DatePicker
                  required
                  disabled={isLoading}
                  errors={touched.birthDate && errors.birthDate}
                  label="Birth Date"
                  value={normalizeDate(values.birthDate) || ''}
                  onChange={(e) => {
                    setFieldTouched('birthDate');
                    setFieldValue('birthDate', e);
                  }}
                  onBlur={handleBlur('birthDate')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <SelectField
                  required
                  placeholder="Select"
                  disabled={isLoading}
                  errors={touched.gender && errors.gender}
                  value={values.gender}
                  label="Gender"
                  onChange={(e) => {
                    setFieldTouched('gender');
                    setFieldValue('gender', e);
                  }}
                  onBlur={handleBlur('gender')}
                  items={[
                    {
                      label: 'Male',
                      value: 'male'
                    },
                    {
                      label: 'Female',
                      value: 'female'
                    }
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <LanguageField
                  required
                  placeholder="Select"
                  label="Primary Language"
                  value={values.primaryLanguageUuid}
                  errors={
                    touched.primaryLanguageUuid && errors.primaryLanguageUuid
                  }
                  onChange={(e) => {
                    setFieldTouched('primaryLanguageUuid');
                    setFieldValue('primaryLanguageUuid', e);
                  }}
                  onBlur={handleBlur('primaryLanguageUuid')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <LanguageField
                  placeholder="Select"
                  label="Secondary Language"
                  value={values.secondaryLanguageUuid}
                  errors={
                    touched.secondaryLanguageUuid &&
                    errors.secondaryLanguageUuid
                  }
                  onChange={(e) => {
                    setFieldTouched('secondaryLanguageUuid');
                    setFieldValue('secondaryLanguageUuid', e);
                  }}
                  onBlur={handleBlur('secondaryLanguageUuid')}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <ProfitCenterField
                  required
                  placeholder="Select"
                  label="Profit Center"
                  value={values.profitCenterUuid}
                  errors={touched.profitCenterUuid && errors.profitCenterUuid}
                  onBlur={handleBlur('profitCenterUuid')}
                  onChange={(e) => {
                    setFieldTouched('profitCenterUuid');
                    setFieldValue('profitCenterUuid', e);
                  }}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FundingSourceField
                  required
                  placeholder="Select"
                  disabled={isLoading}
                  errors={touched.fundingSourceUuid && errors.fundingSourceUuid}
                  value={values.fundingSourceUuid}
                  label="Funding Source"
                  onChange={(e) => {
                    setFieldTouched('fundingSourceUuid');
                    setFieldValue('fundingSourceUuid', e);
                  }}
                  onBlur={handleBlur('fundingSourceUuid')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={3} xs={12}>
                <DatePicker
                  required
                  disabled={isLoading}
                  errors={
                    touched.referralAcceptanceDate &&
                    errors.referralAcceptanceDate
                  }
                  label="Referral Acceptance Date"
                  value={normalizeDate(values.referralAcceptanceDate) || ''}
                  onChange={(e) => {
                    setFieldTouched('referralAcceptanceDate');
                    setFieldValue('referralAcceptanceDate', e);
                  }}
                  onBlur={handleBlur('referralAcceptanceDate')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <NumberField
                  required
                  errors={touched.memberId && errors.memberId}
                  label="Member ID"
                  maxLength={5}
                  value={values.memberId || ''}
                  disabled={isLoading}
                  onChange={(e) => {
                    setFieldTouched('lastName');
                    setFieldValue('memberId', e);
                  }}
                  onBlur={handleBlur('memberId')}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12} xs={12}>
                <TextField
                  disabled={isLoading}
                  errors={errors.notes}
                  label="Notes"
                  multiline
                  rows={5}
                  value={values.notes}
                  onChange={(e) => {
                    setFieldTouched('notes');
                    setFieldValue('notes', e);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card
          title={
            <>
              {'Primary Contact Details'}
              <Box ml={3} component="span">
                <FormControlLabel
                  label={
                    <Typography variant="body3">
                      Set as Emergency Contact
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={isEmergency}
                      onChange={(event) => setIsEmergency(event.target.checked)}
                    />
                  }
                />
              </Box>
            </>
          }
        >
          <ClientContactForm
            {...{
              required: true,
              isLoading,
              values: primaryContactProps.values,
              errors: primaryContactProps.errors,
              touched: primaryContactProps.touched,
              setFieldTouched: primaryContactProps.setFieldTouched,
              setFieldValue: primaryContactProps.setFieldValue,
              handleBlur: primaryContactProps.handleBlur
            }}
          />
        </Card>
        <Card
          title={
            <>
              {'Insured Person Details'}
              <Box ml={3} component="span">
                <FormControlLabel
                  label={
                    <Typography variant="body3">
                      Fill as Primary Contact
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={fillAsPrimary}
                      onChange={(event) =>
                        setFillAsPrimary(event.target.checked)
                      }
                    />
                  }
                />
              </Box>
            </>
          }
        >
          <ClientInsuredContactForm
            {...{
              isLoading,
              fillAsPrimary,
              values: insuredPersonProps.values,
              errors: insuredPersonProps.errors,
              touched: insuredPersonProps.touched,
              setFieldValue: insuredPersonProps.setFieldValue,
              setFieldTouched: insuredPersonProps.setFieldTouched,
              handleBlur: insuredPersonProps.handleBlur
            }}
          />
        </Card>

        <Card title="Secondary Contact Details">
          <ClientContactForm
            {...{
              isLoading,
              values: secondaryContactProps.values,
              errors: secondaryContactProps.errors,
              touched: secondaryContactProps.touched,
              setFieldValue: secondaryContactProps.setFieldValue,
              setFieldTouched: secondaryContactProps.setFieldTouched,
              handleBlur: secondaryContactProps.handleBlur
            }}
          />
        </Card>
        <Card
          title="Emergency Contact Details"
          sx={{ display: isEmergency ? 'none' : 'block' }}
        >
          <ClientContactForm
            {...{
              isLoading,
              disabledAll: isEmergency,
              values: emergencyContactProps.values,
              errors: emergencyContactProps.errors,
              touched: emergencyContactProps.touched,
              setFieldValue: emergencyContactProps.setFieldValue,
              setFieldTouched: emergencyContactProps.setFieldTouched,
              handleBlur: emergencyContactProps.handleBlur
            }}
          />
        </Card>
      </Stack>
    </>
  );
};
