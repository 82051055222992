import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';
interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IRecover2FAValues {
  authCode: string;
}

interface IRecover2FAThunkArguments {
  values: IRecover2FAValues;
  options?: Partial<IRequestOptions>;
}

export const twoFactorRecover = createAsyncThunk(
  'twoFactorRecoverAction',
  async ({ values, options }: IRecover2FAThunkArguments) => {
    try {
      const data: any = await authService.post('/2fa_check', values);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data.data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const twoFactorRecoverSlice = createSlice({
  name: 'twoFactorRecoverySlice',
  initialState: {
    twoFactorRecover: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(twoFactorRecover.pending, (state) => {
      state.twoFactorRecover.loading = true;
      state.twoFactorRecover.data = null;
      state.twoFactorRecover.error = null;
    });
    builder.addCase(twoFactorRecover.fulfilled, (state, { payload }) => {
      state.twoFactorRecover.loading = false;
      state.twoFactorRecover.data = payload;
      state.twoFactorRecover.error = null;
    });
    builder.addCase(twoFactorRecover.rejected, (state) => {
      state.twoFactorRecover.loading = false;
      state.twoFactorRecover.data = null;
      state.twoFactorRecover.error = 'Please enter valid code';
    });
  }
});

export default twoFactorRecoverSlice.reducer;
