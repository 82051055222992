import axios from 'axios';
import { axiosConfig } from './axios.config';
import { AxiosConfig } from './axios.d';

const instanceAxios = axios.create(axiosConfig);

instanceAxios.interceptors.request.use((config: AxiosConfig) => {
  if (!config.url) {
    return config;
  }

  const currentUrl = new URL(config.url, config.baseURL);

  Object.entries(config.pathParams || {}).forEach(
    ([k, v]: [string, string]) => {
      currentUrl.pathname = currentUrl.pathname.replace(
        `:${k}`,
        Array.isArray(v) ? v.join(',') : encodeURIComponent(v)
      );
    }
  );

  const authPart =
    currentUrl.username && currentUrl.password
      ? `${currentUrl.username}:${currentUrl.password}`
      : '';

  if (config.formData) {
    const formData = new FormData();
    Object.keys(config.data).forEach((fieldName) => {
      const fieldValue = config.data[fieldName];
      if (Array.isArray(fieldValue)) {
        fieldValue.forEach((value) => {
          formData.append(`${fieldName}[]`, value);
        });
      } else {
        formData.append(fieldName, config.data[fieldName]);
      }
    });

    if (!config?.headers) {
      config.headers = {};
    }
    config.headers['Content-Type'] = 'multipart/form-data';
    config.data = formData;
  }

  return {
    ...config,
    baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
    url: currentUrl.href
  };
});

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('login');
    window.location.href = '/sign-in';
  } else if (window.location.href === '/sign-in') {
    window.location.href = '/';
  }
  return Promise.reject(error);
};

instanceAxios.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export const setBearerToken = (token: string) => {
  instanceAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const clearBearerToken = () => {
  delete instanceAxios.defaults.headers.common['Authorization'];
};

export default instanceAxios;
