import { useParams } from 'react-router';
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import {
  DatePicker,
  TimePicker,
  TextField,
  ClientField,
  Checkbox
} from '../../components/fields';
import { ROUTE_REMINDERS } from '../../constants/route.paths';
import { useEditResource } from '../../core/hooks/useEditResource';
import * as yup from 'yup';
import { ClientReminderApi } from '../../core/http';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { useLayout } from '../../core/hooks/useLayout';

const clientReminderApi = new ClientReminderApi();

export const EditClientReminder = () => {
  const navigation = useNavigate();
  const { showMessage } = useSnackbar();
  const { id } = useParams();
  const backUrl = ROUTE_REMINDERS + '/#client';

  useLayout({
    backButton: {
      title: 'Reminders',
      path: backUrl
    }
  });

  const {
    isEdit,
    isLoading,
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(clientReminderApi, {
    uuid: id,
    initialValues: {
      title: '',
      clientUuid: '',
      dueDate: null,
      isTextNotificationsEnabled: false,
      isEmailNotificationsEnabled: false
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is a required field'),
      clientUuid: yup.string().required('Client is a required field'),
      dueDate: yup
        .date()
        .required('Due date is a required field')
        .typeError('Please enter valid date')
    }),
    normalizeSetValues: (values) => ({
      ...values,
      dueDate: new Date(values.dueDate)
    }),
    normalizeValues: (values) => {
      return {
        ...values,
        dueDateTime: values.dueDate || new Date(),
        dueDate: values.dueDate.toISOString()
      };
    },
    onSuccess: () => {
      showMessage(
        `Client reminder ${isEdit ? 'updated' : 'created'} successfully`
      );
      navigation(backUrl);
    },
    onError: () => {
      return;
    }
  });

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit Client Reminder' : 'Add Client Reminder'}
      >
        <Button
          size="small"
          onClick={() => {
            handleSubmit();
          }}
        >
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button
          disabled={isLoading}
          size="small"
          color="secondary"
          onClick={() => navigation(backUrl)}
        >
          Cancel
        </Button>
      </PageHeader>
      <Card>
        <Grid container columnSpacing={1}>
          <Grid item md={8}>
            <TextField
              label="Reminder Name*"
              value={values.title}
              onChange={handleChange('title')}
              errors={getFieldTouchedError('title')}
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              label="Due Date*"
              formatValue={false}
              value={values.dueDate}
              onChange={(date: any) => setFieldValue('dueDate', date)}
              errors={getFieldTouchedError('dueDate')}
            />
          </Grid>
          <Grid item md={2}>
            <TimePicker
              label="Due Time"
              value={values.dueDate}
              onChange={(date: any) => setFieldValue('dueDate', date)}
            />
          </Grid>
        </Grid>
        <ClientField
          sx={{
            maxWidth: 368
          }}
          label="Client name*"
          value={values.clientUuid}
          onChange={handleChange('clientUuid')}
          errors={getFieldTouchedError('clientUuid')}
        />
      </Card>

      <Card title="Notes">
        <TextField
          label="Reminder Notes"
          multiline
          rows={5}
          value={values.notes}
          onChange={handleChange('notes')}
          errors={getFieldTouchedError('notes')}
        />
      </Card>

      <Card title="Notification Type">
        <Stack direction="row" spacing={3} ml={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isTextNotificationsEnabled}
                onChange={(e, checked) => {
                  setFieldValue('isTextNotificationsEnabled', checked);
                }}
              />
            }
            label={<Typography variant="body3">Text Message</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isEmailNotificationsEnabled}
                onChange={(e, checked) => {
                  setFieldValue('isEmailNotificationsEnabled', checked);
                }}
              />
            }
            label={<Typography variant="body3">Email</Typography>}
          />
        </Stack>
      </Card>
    </>
  );
};
