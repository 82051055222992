import React from 'react';
import { useUser } from '../hooks/useUser';
import { OnboardingPage } from '../../features/onboarding';

// eslint-disable-next-line react/display-name
export const withAuthorized = (ComposedComponent) => (props) => {
  const { user, loading } = useUser();

  if (loading) return <OnboardingPage />;

  if (!user) return null;

  return <ComposedComponent {...props} />;
};
