import React, { ComponentType } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { DataTableColumn } from './TableView';
import { Skeleton } from '@mui/material';

interface DataTableRowProps {
  isSelectable: boolean;
  columns: DataTableColumn[];
}

export const TableViewLoadingRow: ComponentType<DataTableRowProps> = ({
  isSelectable,
  columns
}) => {
  return (
    <>
      <TableRow>
        {isSelectable && (
          <TableCell>
            <Skeleton
              variant="rectangular"
              width={12}
              height={12}
              sx={{ ml: 0.875 }}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          return (
            <TableCell key={column.key} {...column.cellProps}>
              <Skeleton variant="text" />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};
