import { Expose } from 'class-transformer';

export class ClientChangesDto {
  @Expose()
  entity: string;

  @Expose()
  entityUuid: string;

  @Expose()
  field: string;

  @Expose()
  value: string;

  @Expose()
  editor: any;

  @Expose()
  createdAt: string;
}
