import { useNavigate } from 'react-router';
import { Button, Stack, IconButton } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { SearchField } from '../../components/fields';
import { rolesColumn, rolesData } from './_mockdata';
import { TableView } from '../../components/table-view';
import { IconPen, IconTrash } from '../../components/icons';
import {
  ROUTE_SUPER_ADMIN_ROLE_CREATE,
  ROUTE_SUPER_ADMIN_ROLE_EDIT
} from '../../constants/route.paths';
import { url } from '../../core';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useDialog } from '../../core/hooks/useDialog';

export const SuperAdminRolesContainer = () => {
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const handleRoleDelete = (uuid: string) => {
    openConfirm({
      title: 'Confirm role delete',
      message: 'Do you really want to delete this role?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };
  return (
    <>
      <PageHeader title="Super Admin Roles">
        <Button color="secondary">Export</Button>
      </PageHeader>
      <Stack direction="row" justifyContent="space-between">
        <Button onClick={() => navigate(url(ROUTE_SUPER_ADMIN_ROLE_CREATE))}>
          Add new
        </Button>
        <SearchField
          sx={{ width: 400, mb: 3 }}
          size="small"
          placeholder="Search"
        />
      </Stack>
      <TableView
        showEmptyState
        showTablePerPage
        showPagination
        dataSource={rolesData}
        columns={[
          ...rolesColumn,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: () => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  size="small"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    navigate(
                      url(ROUTE_SUPER_ADMIN_ROLE_EDIT, {
                        id: '123'
                      })
                    );
                  }}
                >
                  <IconPen />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(evt: any) => {
                    evt.stopPropagation();
                    handleRoleDelete('123');
                  }}
                >
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
