import { SelectField } from '../select-field';
import { FC } from 'react';
import { SelectFieldProps } from '../select-field/SelectField';
import { timeZoneItems } from '../../../core/utils/data.utils';

export const TimezoneField: FC<Omit<SelectFieldProps, 'items'>> = ({
  ...props
}) => {
  return <SelectField label="Time Zone" items={timeZoneItems} {...props} />;
};
