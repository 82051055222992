import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'empty-state'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    image: {
      marginBottom: theme.spacing(3),
      '& img': {
        display: 'block',
        width: 164
      }
    },
    title: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      marginBottom: 2,
      color: '#172B4D'
    },
    description: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.71,
      color: '#496492'
    },
    content: {
      textAlign: 'center'
    },
    action: {
      marginTop: theme.spacing(3)
    }
  }),
  options
);

export default useStyles;
