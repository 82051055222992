import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_EMPLOYEES,
  ROUTE_EMPLOYEE_DETAILS
} from '../../constants/route.paths';
import { Box, Button, Stack, Grid, Divider, Skeleton } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { Badge } from '../../components/badge';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { Card } from '../../components/cards';
import {
  LanguageField,
  ProfitCenterField,
  TitleField,
  EmployeeField,
  MultipleSelectField,
  NumberField,
  SelectField,
  DatePicker,
  TextField
} from '../../components/fields';
import { useEditEmployeeResource } from '../../core/hooks/useEditEmployeeResource';
import { useServiceType } from '../../core/hooks/useServiceType';
import { useEmployeeGroup } from '../../core/hooks/useEmployeeGroup';
import { useDialog } from '../../core/hooks/useDialog';
import { useLayout } from '../../core/hooks/useLayout';
import { IconCalendar } from '../../components/icons';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import get from 'lodash/get';
import { EmployeeContactForm } from './EmployeeContactForm';
import { SkillMultipleSelectField } from '../../components/fields/skill-select-multiple-field';
import { PermissionView } from '../../components/permission-view';
import { useRole } from '../../core/hooks/useRole';

export const EmployeeForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const {
    data,
    isEdit,
    isLoading,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    employeeContactInfoProps,
    setFieldTouched
  } = useEditEmployeeResource({
    uuid: id,
    onSuccess: () => {
      navigate(url(ROUTE_EMPLOYEES));
      if (isEdit) {
        showMessage(`Employee ${data.displayName} updated successfully`);
      } else {
        showMessage(
          `Employee ${values.firstName} ${values.lastName} created successfully`
        );
      }
    },
    onError: (error) => {
      const status = get(error, 'response.status');
      if (status === 400) {
        showMessage('Please check the form for errors', 'error');
      }
    }
  });

  const { items: roles } = useRole();
  const { items: serviceTypes } = useServiceType();
  const { items: groups } = useEmployeeGroup();

  const validateAndSubmit = () => {
    Object.keys(employeeContactInfoProps.values).forEach((key) => {
      employeeContactInfoProps.setFieldTouched(key, true);
    });
    employeeContactInfoProps.validateForm();
    handleSubmit();
  };

  const backButtonProps = {
    title: isEdit ? 'Employee Profile' : 'Employees',
    path: isEdit ? url(ROUTE_EMPLOYEE_DETAILS, { id }) : url(ROUTE_EMPLOYEES)
  };
  useLayout({
    backButton: backButtonProps
  });

  const handleCancel = () => () => {
    openConfirm({
      title: 'Cancel',
      message: 'Do you really want to cancel ? ',
      onConfirm: () => {
        navigate(url(ROUTE_EMPLOYEES));
        closeConfirm();
      }
    });
  };

  const handleResetPassword = () => () => {
    openConfirm({
      title: 'Reset Password',
      message:
        'Do you really want to reset the password to this profile? The user will receive an email with reset information.',
      onConfirm: () => {
        closeConfirm();
      }
    });
  };
  const headerProps = {
    divider: false,
    title: isEdit ? 'Edit Employee Profile' : 'Add New Employee Profile',
    meta: isEdit && (
      <Stack direction="row" spacing={1}>
        {isLoading ? (
          <>
            <Skeleton variant="text" sx={{ height: 32, width: 32 }} />
            <Skeleton variant="text" sx={{ height: 32, width: 80 }} />
          </>
        ) : (
          <>
            <Badge label={data?.id} variant={'secondary'} />
            <Badge
              label={data?.isDeleted ? 'Terminated' : 'Active'}
              variant={data?.isDeleted ? 'danger' : 'success'}
            />
          </>
        )}
      </Stack>
    )
  };

  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };
  const getFieldTouchedError = (fieldName: string) => {
    return get(touched, fieldName) && get(errors, fieldName);
  };

  const getFieldProps = (fieldName: string) => {
    return {
      disabled: isLoading,
      errors: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  return (
    <>
      <PageHeader {...headerProps}>
        {id ? (
          <>
            <PermissionView permission="modifyEmployees">
              <Button
                color="secondary"
                onClick={handleResetPassword()}
                disabled={isLoading}
              >
                Reset password
              </Button>
            </PermissionView>
            <PermissionView permission="modifyEmployees">
              <Button onClick={() => validateAndSubmit()} disabled={isLoading}>
                Save
              </Button>
            </PermissionView>
            <Button color="third" onClick={handleCancel()} disabled={isLoading}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                validateAndSubmit();
              }}
            >
              Save and send invite
            </Button>
            <Button color="third" onClick={handleCancel()}>
              Cancel
            </Button>
          </>
        )}
      </PageHeader>
      <Box>
        <Stack spacing={1.25} direction="column">
          <Card title="General Information">
            <Grid container spacing={1}>
              <Grid item md={3} xs={12}>
                <TextField
                  label="First Name"
                  required
                  {...getFieldProps('firstName')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  required
                  label="Last Name"
                  {...getFieldProps('lastName')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <SelectField
                  label="Gender"
                  required
                  items={[
                    {
                      label: 'Male',
                      value: 'male'
                    },
                    {
                      label: 'Female',
                      value: 'female'
                    }
                  ]}
                  {...getFieldProps('gender')}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField required label="Email" {...getFieldProps('email')} />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={1.5} xs={12}>
                <DatePicker
                  label="Hire Date"
                  required
                  Icon={<IconCalendar />}
                  {...getFieldProps('hireDate')}
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <DatePicker
                  label="End Work Date"
                  Icon={<IconCalendar />}
                  {...getFieldProps('endWorkDate')}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ProfitCenterField
                  required
                  placeholder="Select"
                  label="Profit Center"
                  {...getFieldProps('profitCenterUuid')}
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <NumberField
                  required
                  label="Available Hours"
                  {...getFieldProps('availableHours')}
                />
              </Grid>
              <Grid item md={1.5} xs={12}>
                <NumberField required label="HRID" {...getFieldProps('hrid')} />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item md={3} xs={12}>
                <TitleField
                  placeholder="Select"
                  label="Title"
                  required
                  {...getFieldProps('titleUuid')}
                  errors={
                    getFieldTouchedError('titleCustom') ||
                    getFieldTouchedError('titleUuid')
                  }
                />
              </Grid>
              <Grid item md={9} xs={12}>
                <TextField
                  placeholder="Custom title"
                  label=""
                  {...getFieldProps('titleCustom')}
                  disabled={getFieldValue('titleUuid') || isLoading}
                  errors={
                    getFieldTouchedError('titleCustom') ||
                    getFieldTouchedError('titleUuid')
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <MultipleSelectField
                  label="Group"
                  dataSource={groups.map((group) => ({
                    value: group.uuid,
                    label: group.groupName
                  }))}
                  {...getFieldProps('groups')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <SelectField
                  label="Role"
                  required
                  items={roles.map((item) => ({
                    value: item.uuid,
                    label: item.title
                  }))}
                  {...getFieldProps('roleUuid')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3.5}>
              <Grid item md={12}>
                <MultipleSelectField
                  label="Type of Service"
                  placeholder="Select"
                  dataSource={serviceTypes.map((type) => ({
                    value: type.uuid,
                    label: type.title
                  }))}
                  {...getFieldProps('serviceTypes')}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 4 }} />
            <Grid container spacing={1} mt={3.5}>
              <Grid item md={3}>
                <LanguageField
                  required
                  placeholder="Select language"
                  label="Primary Language"
                  {...getFieldProps('primaryLanguageUuid')}
                />
              </Grid>
              <Grid item md={3}>
                <LanguageField
                  placeholder="Select language"
                  label="Secondary Language"
                  {...getFieldProps('secondaryLanguageUuid')}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 4 }} />
            <Grid container spacing={1}>
              <Grid item md={12}>
                <SkillMultipleSelectField
                  placeholder="Select skills"
                  {...getFieldProps('skillsUuids')}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Grid container spacing={1}>
              <Grid item md={6}>
                <EmployeeField
                  disabled={isLoading}
                  label="Supervisor"
                  placeholder="Select"
                  {...getFieldProps('supervisorUuid')}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item md={3}>
                <NumberField
                  label="Hourly Rate"
                  {...getFieldProps('hourlyRate')}
                />
              </Grid>
              <Grid item md={3}>
                <NumberField
                  label="Targeted Hours"
                  {...getFieldProps('targetedHour')}
                />
              </Grid>
              <Grid item md={3}>
                <NumberField label="PTO" {...getFieldProps('pto')} />
              </Grid>
            </Grid>
          </Card>
        </Stack>
      </Box>

      <Card title="Primary Contact Details">
        <EmployeeContactForm
          isLoading={isLoading}
          values={employeeContactInfoProps.values}
          errors={employeeContactInfoProps.errors}
          touched={employeeContactInfoProps.touched}
          setFieldTouched={employeeContactInfoProps.setFieldTouched}
          setFieldValue={employeeContactInfoProps.setFieldValue}
          handleBlur={employeeContactInfoProps.handleBlur}
        />
      </Card>
    </>
  );
};
