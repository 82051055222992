import React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  BoxProps,
  ButtonBase,
  Typography,
  Menu,
  Stack
} from '@mui/material';
import { ComponentType } from 'react';
import useStyles from './styles';
import avatar from '../../assets/avatar.png';
import { useUser } from '../../core/hooks/useUser';
import { ROUTE_PROFILE } from '../../constants/route.paths';
import { useLocation, useNavigate } from 'react-router';
import { useDialog } from '../../core/hooks/useDialog';
import { LogoutDialog } from './dialogs/LogoutDialog';

export const UserMenu: ComponentType<BoxProps> = ({ ...props }) => {
  const classes = useStyles();
  const { user } = useUser();
  const navigate = useNavigate();
  const [openLogoutDialog] = useDialog(LogoutDialog);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) return null;

  return (
    <Box {...props}>
      <ButtonBase onClick={handleClick} className={classes.button}>
        <Avatar className={classes.avatar} src={avatar} />
        <Box textAlign="left">
          <Typography variant="button" color="text" component="div">
            {user.displayName}
          </Typography>
          <Typography
            variant="button"
            fontSize={10}
            color="text"
            component="div"
          >
            {user?.roleTitle}
          </Typography>
        </Box>
      </ButtonBase>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        MenuListProps={{
          style: {
            width: 176
          }
        }}
      >
        <Stack direction="column">
          <Button
            className={clsx(classes.button, {
              [classes.selected]: pathname === '/profile'
            })}
            variant="text"
            onClick={() => navigate(ROUTE_PROFILE)}
          >
            Profile
          </Button>
          <Button
            className={classes.button}
            variant="text"
            onClick={openLogoutDialog}
          >
            Logout
          </Button>
        </Stack>
      </Menu>
    </Box>
  );
};
