import { useState } from 'react';
import { Card } from '../../../components/cards';
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import {
  Checkbox,
  DatePicker,
  EmployeeAutocompleteField,
  FundingSourceField
} from '../../../components/fields';
import get from 'lodash/get';
import { TableView } from '../../../components/table-view';
import { billingColumns } from '../_mockdata';
import { useListResource } from '../../../core/hooks/useListResource';
import { ReportsBillingApi } from '../../../core/http/requests/reports-billing';
import { ServiceCodeField } from '../../../components/fields/service-code-field';
import { AppointmentCancelTypeField } from '../../../components/fields/appointment-cancel-type-field';
import { RegionField } from '../../../components/fields/region-field';

const reportsBillingApi = new ReportsBillingApi();

export const Billing = () => {
  const [filter, setFilter] = useState({});

  const handleFilterChange = (field) => (value) => {
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const {
    data,
    isLoading,
    handleChangePage,
    handleChangePerPage,
    handleChangeSort,
    pagination,
    handleChangeParams
  } = useListResource(reportsBillingApi);

  const handleSearch = () => {
    Object.keys(filter).forEach((element) => {
      if (element) {
        handleChangeParams(
          `filter[${element.toString()}][eq]`,
          filter[element]
        );
      } else {
        return;
      }
    });
  };

  return (
    <>
      <Card mb={3}>
        <Grid container columnSpacing={1} mb={1.5}>
          <Grid item sm={2.4}>
            <Stack direction="row" spacing={1}>
              <DatePicker
                label="From"
                size="small"
                helperText={null}
                value={get(filter, 'startDateOfService', null)}
                onChange={handleFilterChange('startDateOfService')}
              />
              <DatePicker
                label="To"
                size="small"
                helperText={null}
                value={get(filter, 'endDateOfService', null)}
                onChange={handleFilterChange('endDateOfService')}
              />
            </Stack>
          </Grid>
          <Grid item sm={2.4}>
            <EmployeeAutocompleteField
              label="Employee"
              placeholder="Search"
              size="small"
              helperText={null}
              value={get(filter, 'app.primaryTherapist')}
              onChange={handleFilterChange('app.primaryTherapist')}
            />
          </Grid>
          <Grid item sm={2.4}>
            {/* <EmployeeAutocompleteField
              label="Employee"
              placeholder="Search"
              size="small"
              helperText={null}
              value={get(filter, 'employee2')}
              onChange={handleFilterChange('employee2')}
            /> */}
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item sm={2.4}>
            <ServiceCodeField
              label="Service Code"
              size="small"
              helperText={null}
              value={get(filter, 'auth.serviceCode')}
              onChange={handleFilterChange('auth.serviceCode')}
            />
          </Grid>
          <Grid item sm={2.4}>
            <FundingSourceField
              label="Funding Source"
              size="small"
              helperText={null}
              value={get(filter, 'payer.fundingSource')}
              onChange={handleFilterChange('payer.fundingSource')}
            />
          </Grid>
          <Grid item sm={2.4}>
            <RegionField
              label="Region"
              size="small"
              helperText={null}
              value={get(filter, 'region')}
              onChange={handleFilterChange('region')}
            />
          </Grid>
          <Grid item sm={2.4}>
            <AppointmentCancelTypeField
              label="Appointment Status"
              size="small"
              helperText={null}
              placeholder="Select"
              // items={[
              //   { value: '1', label: 'status 1' },
              //   { value: '2', label: 'status 2' }
              // ]}
              value={get(filter, 'app.status')}
              onChange={handleFilterChange('app.status')}
            />
          </Grid>

          <Grid item sm={2.2} display="flex" alignItems="flex-end">
            <Stack direction="row" spacing={1} mt={2.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={get(filter, 'showSecondary')}
                    onChange={(e) => {
                      handleFilterChange('showSecondary')(e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="body2">
                    Show Secondary Therapist
                  </Typography>
                }
              />
              <Button onClick={() => handleSearch()}>Search</Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <TableView
        loading={isLoading}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        showTablePerPage={true}
        showPagination={true}
        pagination={pagination}
        showEmptyState
        EmptyStateProps={{
          title: 'You dont have any billings yet.',
          description: null
        }}
        keyExtractor={(item: { uuid: string }) => item.uuid}
        TableProps={{
          sx: {
            whiteSpace: 'nowrap',
            minWidth: 2000
          }
        }}
        dataSource={get(data, 'items', [])}
        columns={billingColumns}
      />
    </>
  );
};
