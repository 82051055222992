import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminRoleEditContainer } from '../../features/super-admin-roles/SuperAdminRoleEdit';

export const SuperAdminRoleEdit = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Roles'
  })
)(SuperAdminRoleEditContainer);
