import { DataTableColumn } from '../../components/table-view/TableView';
import { Typography } from '@mui/material';
import { UserRecord } from '../../components/user-record';
import { normalizeDate } from '../../core';

export const filesColumn: DataTableColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true,
    cellProps: { width: 80 }
  },
  {
    title: 'Name',
    dataIndex: 'fileName',
    key: 'fileName',
    cellProps: { width: 300 },
    render: (text) => (
      <Typography variant="body2" color="primary">
        {text}
      </Typography>
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sortable: true
  },
  {
    title: 'Exp. Dates',
    dataIndex: 'expirationDate',
    key: 'expDate',
    sortable: true,
    render: (text) => (
      <Typography variant="body2" color="primary">
        {text}
      </Typography>
    )
  },
  {
    title: 'Size (mb)',
    dataIndex: 'fileSize',
    key: 'fileSize',
    render: (kbytes) => Math.floor(kbytes / 1024)
  },
  {
    title: 'Owned by',
    dataIndex: 'owner',
    key: 'owner',
    render: (user) => (
      <Typography variant="body2" color="primary">
        {user?.firstName} {user?.lastName}
      </Typography>
    )
  },
  {
    title: 'Upload by',
    dataIndex: 'root',
    key: 'root',
    render: (user: any) => (
      <UserRecord user={{ name: user.name, avatar: null }} />
    )
  },
  {
    title: 'Added Date',
    dataIndex: 'owner.createdAt',
    key: 'owner.createdAt',
    sortable: true,
    render: (value) => normalizeDate(value)
  }
];

export const filesData = [
  {
    id: 267400,
    name: '323893-3232...',
    type: 'Certificate',
    expDate: '03/06/2022',
    size: '2.4',
    ownedBy: {
      name: 'Savannah Nguyen'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    addedDate: '02/02/2022 01:34 PM'
  },
  {
    id: 651444,
    name: 'service_auth323209',
    type: 'Service Auth',
    expDate: '03/06/2022',
    size: '0.2',
    ownedBy: {
      name: 'Theresa Webb'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      name: 'Annette Black'
    },
    addedDate: '02/02/2022 01:34 PM'
  },
  {
    id: 487555,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 651544,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 487441,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 449003,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 651535,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 653518,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 267333,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  },
  {
    id: 651777,
    name: '32329dsds90dsd',
    type: 'Certificate',
    expDate: '03/04/2022',
    size: '0.6',
    ownedBy: {
      name: 'Leslie Alexander'
    },
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    addedDate: '01/28/2022 02:05 PM'
  }
];
