import { Box, Button, IconButton, Stack } from '@mui/material';
import useStyles from './styles';
import { SvgIcon } from '@mui/material';
import { ReactComponent as SvgLeft } from './assets/left.svg';
import { ReactComponent as SvgRight } from './assets/right.svg';

import { startOfWeek, endOfWeek, addWeeks, subWeeks, format } from 'date-fns';
import { FC, useEffect, useState } from 'react';

interface Props {
  withToday?: boolean;
  onChange?: (startDate: Date, endDate: Date) => void;
}

export const WeekRangePicker: FC<Props> = ({ withToday, onChange }) => {
  const [value, setValue] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const classes = useStyles();

  useEffect(() => {
    if (onChange) {
      onChange(value, endOfWeek(value, { weekStartsOn: 1 }));
    }
  }, [value]);

  const handleClickPrev = () => {
    setValue(startOfWeek(subWeeks(value, 1), { weekStartsOn: 1 }));
  };

  const handeClickNext = () => {
    setValue(addWeeks(value, 1));
  };

  const handleClickToday = () => {
    setValue(startOfWeek(new Date(), { weekStartsOn: 1 }));
  };

  const formatDate = (date: Date) => {
    return (
      format(date, 'MMM d') +
      ' - ' +
      format(endOfWeek(date, { weekStartsOn: 1 }), 'd, yyyy')
    );
  };

  return (
    <Stack direction="row" spacing={1}>
      <Stack
        className={classes.root}
        direction="row"
        alignItems="center"
        spacing={0.25}
      >
        <IconButton className={classes.button} onClick={handleClickPrev}>
          <SvgIcon component={SvgLeft} viewBox="0 0 24 24" />
        </IconButton>
        <IconButton className={classes.button} onClick={handeClickNext}>
          <SvgIcon component={SvgRight} viewBox="0 0 24 24" />
        </IconButton>
        <Box className={classes.date}>{formatDate(value)}</Box>
      </Stack>
      {withToday && (
        <Button color="third" onClick={handleClickToday}>
          Today
        </Button>
      )}
    </Stack>
  );
};
