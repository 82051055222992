import { ClientForm } from '../../../features/clients/ClientForm';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const ClientProfileEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Clients'
  })
)(ClientForm);
