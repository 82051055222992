import { ComponentType, useState } from 'react';
import { Box, Button, Stack, IconButton, Tooltip, Grid } from '@mui/material';
import get from 'lodash/get';
import { useListResource } from '../../core/hooks/useListResource';
import { TimesheetApi, TimesheetDto } from '../../core/http';
import { useDialog } from '../../core/hooks/useDialog';
import { TableView } from '../../components/table-view';
import {
  ClientField,
  DatePicker,
  EmployeeField,
  ProfitCenterField
} from '../../components/fields';
import { PageHeader } from '../../components/page-header';
import { ExportTimesheetDialog } from './dialogs/ExportTimesheetDialog';
import { EditTimesheetDialog } from './dialogs/EditTimesheetDialog';
import { timesheetsColumns } from './_mockdata';
import { IconPen, IconTrash } from '../../components/icons';
import { IconArrowDown } from '../../components/icons';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { ServiceCodeField } from '../../components/fields/service-code-field';
import { useFilter } from '../../core/hooks/useFilter';

const timesheetApi = new TimesheetApi();

export const TimesheetsList: ComponentType = () => {
  const [openExportDialog, closeExportDialog] = useDialog(
    ExportTimesheetDialog
  );
  const [openEditDialog, closeEditDialog] = useDialog(EditTimesheetDialog);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  const handleExport = () => {
    openExportDialog({
      selectedUuids,
      setSelectedUuids,
      onSuccess: () => {
        closeExportDialog();
        setSelectedUuids([]);
      }
    });
  };
  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(timesheetApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };
  const handleEdit = (uuid: string) => () => {
    openEditDialog({
      uuid,
      onSuccess: () => {
        fetchData();
        closeEditDialog();
      }
    });
  };
  return (
    <>
      <PageHeader title="Timesheets" />
      <Grid container spacing={1} mb={1.5}>
        <Grid item md={2.84}>
          <EmployeeField
            label="Therapists"
            placeholder="Search"
            size="small"
            helperText={null}
            value={getFilterValue('filter[app.primaryTherapist][eq]')}
            onChange={setFilterValue('filter[app.primaryTherapist][eq]')}
          />
        </Grid>
        <Grid item md={1.42}>
          <DatePicker
            label="From"
            size="small"
            Icon={<IconArrowDown />}
            helperText={null}
            value={getFilterValue('filter[startDateOfService][gte]', null)}
            onChange={setFilterValue('filter[startDateOfService][gte]')}
          />
        </Grid>
        <Grid item md={1.42}>
          <DatePicker
            label="To"
            size="small"
            Icon={<IconArrowDown />}
            helperText={null}
            value={getFilterValue('filter[startDateOfService][lte]', null)}
            onChange={setFilterValue('filter[startDateOfService][lte]')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={3} alignItems="flex-end">
        <Grid item md={2.84}>
          <ClientField
            placeholder="Search"
            label="Client"
            size="small"
            helperText={null}
            value={getFilterValue('filter[payer][eq]')}
            onChange={setFilterValue('filter[payer][eq]')}
          />
        </Grid>
        <Grid item md={2.84}>
          <ServiceCodeField
            label="Service Code"
            placeholder="Search"
            size="small"
            helperText={null}
            value={getFilterValue('filter[auth.serviceCode][eq]')}
            onChange={setFilterValue('filter[auth.serviceCode][eq]')}
          />
        </Grid>
        <Grid item md={2.84}>
          <ProfitCenterField
            label="Provider"
            labelExtractor={(item) =>
              `${item.profitCentre} (${item.officeName})`
            }
            placeholder="Select"
            size="small"
            helperText={null}
            value={getFilterValue('filter[payer.profitCenter][eq]')}
            onChange={setFilterValue('filter[payer.profitCenter][eq]')}
            disabled={false}
          />
        </Grid>
        <Grid item>
          <Button onClick={handleSearch}>Search</Button>
        </Grid>
      </Grid>
      <Box mb={3}>
        <Button onClick={handleExport} disabled={selectedUuids.length <= 0}>
          Export selected
        </Button>
      </Box>
      <Box>
        <TableView
          loading={isLoading}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          showTablePerPage={true}
          showPagination={true}
          onChangeSort={handleChangeSort}
          pagination={pagination}
          keyExtractor={(item: any) => item.uuid}
          dataSource={get(data, 'items', [])}
          rowSelection={{
            selectedRowKeys: selectedUuids,
            onChange: (id) => setSelectedUuids(id)
          }}
          showEmptyState
          EmptyStateProps={{
            title: 'You dont have any Timesheet',
            description: null
          }}
          columns={[
            ...timesheetsColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              cellProps: {
                width: 50
              },
              render: (_, { uuid }: TimesheetDto) => (
                <Stack direction="row" alignItems="center">
                  <Tooltip title="Edit" placement="bottom-end">
                    <IconButton size="small" onClick={handleEdit(uuid)}>
                      <IconPen />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" placement="bottom-end">
                    <IconButton size="small" onClick={handleDelete(uuid)}>
                      <IconTrash />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
