import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { AppointmentTypeApi, AppointmentTypeDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const appointmentTypeApi = new AppointmentTypeApi();

export const fetchAppointmentTypeAsync = createFetchAsync(
  'appointmentType',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await appointmentTypeApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchAppointmentType = fetchAppointmentTypeAsync.asyncThunk;

export const fetchAppointmentType = (payload) => (dispatch, getState) => {
  const {
    appointmentType: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchAppointmentType(payload));
  }
};

type AppointmentTypeState = {
  findAll: AsyncState<FindAllResponse<AppointmentTypeDto>>;
};

const initialState: AppointmentTypeState = {
  findAll: fetchAppointmentTypeAsync.initialState
};

const appointmentType = createSlice({
  name: 'appointmentType',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchAppointmentTypeAsync.extraReducers
  }
});

export default appointmentType.reducer;
