import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { EmployeeGroupApi, EmployeeGroupDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const employeeGroupApi = new EmployeeGroupApi();

export const fetchEmployeeGroupeAsync = createFetchAsync(
  'employeeGroup',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await employeeGroupApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchEmployeeGroup = fetchEmployeeGroupeAsync.asyncThunk;

export const fetchEmployeeGroup = (payload) => (dispatch, getState) => {
  const {
    employeeGroup: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchEmployeeGroup(payload));
  }
};

type EmployeeGroupState = {
  findAll: AsyncState<FindAllResponse<EmployeeGroupDto>>;
};

const initialState: EmployeeGroupState = {
  findAll: fetchEmployeeGroupeAsync.initialState
};

const employeeGroup = createSlice({
  name: 'employeeGroup',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchEmployeeGroupeAsync.extraReducers
  }
});

export default employeeGroup.reducer;
