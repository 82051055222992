import { useParams } from 'react-router';
import { PageHeader } from '../../../components/page-header';
import { useLayout } from '../../../core/hooks/useLayout';
import { ROUTE_MASTER_DATA_FUNDING_SOURCE } from '../../../constants/route.paths';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../components/cards';
import { EnumField, TextField } from '../../../components/fields';
import { useEffect, useState } from 'react';
import map from 'lodash/map';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { FundingSourceApi, FundingSourceContractApi } from '../../../core/http';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import { ServiceCodeField } from '../../../components/fields/service-code-field';
import { ServiceDescriptionField } from '../../../components/fields/service-description-field';
import { PERIOD_TYPE } from '../../../constants/enums';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import isEmpty from 'lodash/isEmpty';

const fundingSourceApi = new FundingSourceApi();
const fundingSourceContractApi = new FundingSourceContractApi();

const getEmptyContract = (fundingSourceUuid) => ({
  uuid: uuidv4(),
  fundingSourceUuid,
  serviceCodeUuid: '',
  serviceDescriptionUuid: '',
  rate: '',
  periodType: '',
  procedureInfo: '',
  unitLength: ''
});

export const FundingSourceEdit = () => {
  const [createUuid] = useState(uuidv4());
  const { uuid } = useParams();
  const navigation = useNavigate();
  const { showMessage } = useSnackbar();

  useLayout({
    backButton: {
      title: 'Funding Sources',
      path: ROUTE_MASTER_DATA_FUNDING_SOURCE
    }
  });

  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    getFieldTouchedError
  } = useEditResource(fundingSourceApi, {
    uuid,
    createUuid,
    initialValues: {
      name: '',
      contracts: []
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Name is a required field'),
      contracts: yup.array().of(
        yup.object().shape({
          serviceCodeUuid: yup
            .string()
            .required('Service Code is a required field'),
          serviceDescriptionUuid: yup
            .string()
            .required('Service Description is a required field'),
          rate: yup.number().required('Rate is a required field'),
          periodType: yup.string().required('Period Type is a required field'),
          procedureInfo: yup
            .string()
            .required('Procedure Info is a required field'),
          unitLength: yup.number().required('Unit Length is a required field')
        })
      )
    }),
    normalizeSetValues: (values) => {
      return {
        ...values,
        contracts: isEmpty(values.contracts)
          ? [getEmptyContract(values.uuid)]
          : map(values.contracts, (item) => {
              return {
                ...item,
                serviceCodeUuid: item.serviceCode.uuid,
                serviceDescriptionUuid: item.serviceDescription.uuid
              };
            })
      };
    },
    normalizeValues: (values) => {
      return {
        ...values,
        contracts: isEmpty(values.contracts)
          ? [getEmptyContract(values.uuid)]
          : map(values.contracts, (item) => {
              return {
                ...item,
                rate: +item.rate,
                procedureInfo: +item.procedureInfo,
                unitLength: +item.unitLength
              };
            })
      };
    },
    onSuccess: async (values) => {
      const contracts = get(values, 'contracts', []);
      Promise.all(
        map(contracts, (contract) => {
          const payload = {
            ...contract,
            uuid: contract.uuid || uuidv4(),
            fundingSourceUuid: get(contract, 'fundingSourceUuid', uuid)
          };
          if (!get(contract, 'uuid')) {
            return fundingSourceContractApi.create(payload);
          } else {
            return fundingSourceContractApi.update(payload);
          }
        })
      ).then(() => {
        navigation(ROUTE_MASTER_DATA_FUNDING_SOURCE);
        showMessage(
          `Funding Source ${uuid ? 'updated' : 'created'} successfully`
        );
      });
    },
    onError: () => {
      showMessage('Something went wrong', 'error');
    }
  });

  useEffect(() => {
    if (!uuid) {
      setFieldValue('contracts', [getEmptyContract(createUuid)]);
    }
  }, [uuid]);

  const handleAddContract = () => {
    setFieldValue('contracts', [
      ...values.contracts,
      { ...getEmptyContract(uuid || createUuid) }
    ]);
  };

  const contracts = get(values, 'contracts', []);

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit Funding Source' : 'Add  Funding Source'}
      >
        <Button
          size="small"
          disabled={isLoading}
          onClick={() => handleSubmit()}
        >
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => navigation(ROUTE_MASTER_DATA_FUNDING_SOURCE)}
        >
          Cancel
        </Button>
      </PageHeader>
      <Card py={3}>
        <TextField
          label="Vendor Name*"
          helperText={null}
          value={values.name}
          onChange={handleChange('name')}
          errors={getFieldTouchedError('name')}
        />
      </Card>

      <Box mb={3}>
        <Button onClick={handleAddContract}>Add new contract</Button>
      </Box>

      <Typography variant="h3" mb={3}>
        Contracts
      </Typography>

      {map(contracts, (contract, index) => (
        <Card
          title={`Contract #${index + 1}`}
          py={3}
          key={index}
          meta={
            <Button color="primary" variant="outlined">
              Label
            </Button>
          }
        >
          <Grid container columnSpacing={1}>
            <Grid item md={6}>
              <ServiceCodeField
                label="Service Code*"
                value={contract.serviceCodeUuid}
                onChange={(value) =>
                  setFieldValue(`contracts[${index}].serviceCodeUuid`, value)
                }
                errors={getFieldTouchedError([
                  'contracts',
                  index,
                  'serviceCodeUuid'
                ])}
              />
            </Grid>
            <Grid item md={6}>
              <ServiceDescriptionField
                label="Service Description*"
                value={contract.serviceDescriptionUuid}
                onChange={(value) =>
                  setFieldValue(
                    `contracts[${index}].serviceDescriptionUuid`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'contracts',
                  index,
                  'serviceDescriptionUuid'
                ])}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={1}>
            <Grid item md={3}>
              <TextField
                label="Rate*"
                type="number"
                value={contract.rate}
                onChange={(value) => {
                  setFieldValue(`contracts[${index}].rate`, value);
                }}
                errors={getFieldTouchedError(['contracts', index, 'rate'])}
              />
            </Grid>
            <Grid item md={3}>
              <EnumField
                label="Hours/Period Type*"
                items={PERIOD_TYPE}
                value={contract.periodType}
                onChange={(value) =>
                  setFieldValue(`contracts[${index}].periodType`, value)
                }
                errors={getFieldTouchedError([
                  'contracts',
                  index,
                  'periodType'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                label="Procedure Info*"
                type="number"
                value={contract.procedureInfo}
                onChange={(value) =>
                  setFieldValue(`contracts[${index}].procedureInfo`, value)
                }
                errors={getFieldTouchedError([
                  'contracts',
                  index,
                  'procedureInfo'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                label="Unit Length*"
                type="number"
                value={contract.unitLength}
                onChange={(value) =>
                  setFieldValue(`contracts[${index}].unitLength`, value)
                }
                errors={getFieldTouchedError([
                  'contracts',
                  index,
                  'unitLength'
                ])}
              />
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};
