import { useState } from 'react';
import { AppointmentCalendarDrawerCard } from '../../../components/appointment-calendar-drawer-card';
import { CalendarView } from '../../../components/calendar-view/CalendarView';
import { Button, Stack } from '@mui/material';
import { RadioGroup } from '../../../components/fields/radio-group';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { TableView } from '../../../components/table-view';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { AppointmentDto } from '../../../core/http';
import {
  calendarStatusFilterItems,
  calendarViewTypeItems
} from '../../../constants/app.constants';
import { useAppointmentList } from '../../../core/hooks/useAppointmentList';
import { AppointmentActionButtons } from '../../../components/appointment-action-buttons/appointment-action-buttons';
import { employeeAppointmentColumns } from '../../../constants/columns';
import { AppointmentCalendarClientCard } from '../../../components/appointment-calendar-client-card';
import { AppointmentDrawerActions } from '../../../components/appointment-drawer-actions';
import { PermissionView } from '../../../components/permission-view';
import { AddNewAppointmentDialog } from '../../appointments/dialogs/AddNewAppointmentDialog';
import { useDialog } from '../../../core/hooks/useDialog';

export const Appointments = () => {
  const { id } = useParams();
  const [openAddAppointment] = useDialog(AddNewAppointmentDialog);
  const [currentView, setCurrentView] = useState<'calendar' | 'table'>(
    'calendar'
  );

  const {
    data: appointmentResponse,
    isLoading,
    pagination,
    handleChangeSort,
    handleStatusFilter,
    handleChangePerPage,
    handleChangePage,
    handleCancel,
    handleDelete,
    handleConvert,
    handleEmployeeEdit: handleEdit,
    handleEmployeeView: handleView,
    handleEmployeeMakeUp: handleMakeUp,
    startDate,
    setStartDate
  } = useAppointmentList({
    employeeId: id,
    defaultParams: {
      'filter[employee][eq]': id,
      limit: 12
    }
  });

  const data = get(appointmentResponse, 'items', []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Stack direction="row" spacing={3}>
          <Stack direction="row" spacing={1}>
            <PermissionView permission="modifyAppointments">
              <Button onClick={openAddAppointment}>Add Appointment</Button>
            </PermissionView>
            <PermissionView permission="modifyAppointments">
              <Button color="secondary">Add internal work</Button>
            </PermissionView>
          </Stack>
          <RadioGroup
            items={calendarViewTypeItems}
            defaultValue="calendar"
            onChange={(view) => {
              setCurrentView(view as 'calendar' | 'table');
            }}
          />
          <RadioGroup
            items={calendarStatusFilterItems}
            defaultValue="all"
            onChange={handleStatusFilter}
          />
        </Stack>
        <WeekRangePicker
          withToday
          onChange={(startDate) => {
            setStartDate(startDate);
          }}
        />
      </Stack>
      {currentView === 'calendar' ? (
        <CalendarView
          startDate={startDate}
          subtractHeight={316}
          dataSource={data}
          groupKey="startDate"
          renderItem={(item: AppointmentDto, _, selectedItem) => (
            <AppointmentCalendarClientCard
              item={item}
              selected={selectedItem?.uuid === item.uuid}
            />
          )}
          renderDrawer={(item: AppointmentDto) => (
            <AppointmentCalendarDrawerCard item={item} />
          )}
          renderDrawerFooter={(appointment: AppointmentDto) => (
            <AppointmentDrawerActions
              {...{
                appointment,
                handleView,
                handleConvert,
                handleEdit,
                handleDelete,
                handleCancel,
                handleMakeUp
              }}
            />
          )}
        />
      ) : (
        <TableView
          showEmptyState
          showTablePerPage
          showPagination
          loading={isLoading}
          pagination={pagination}
          onChangeSort={handleChangeSort}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          dataSource={data}
          keyExtractor={(item) => item.uuid}
          EmptyStateProps={{
            title: 'Appointments has not been found'
          }}
          columns={[
            ...employeeAppointmentColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, appointment: AppointmentDto) => (
                <AppointmentActionButtons
                  {...{
                    appointment,
                    handleView,
                    handleConvert,
                    handleEdit,
                    handleDelete,
                    handleCancel,
                    handleMakeUp
                  }}
                />
              )
            }
          ]}
        />
      )}
    </>
  );
};
