import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'snackbar'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      top: 24,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1000000,
      minWidth: 407,
      minHeight: 48,

      '& .MuiPaper-root': {
        borderRadius: 4,
        boxShadow:
          '0px 0px 1px rgba(0, 0, 0, 0.32), 0px 24px 32px rgba(23, 43, 77, 0.12)',
        padding: theme.spacing(0.5, 1.5),
        border: 0,
        background: '#fff'
      },

      '& .MuiAlert-message': {
        fontSize: 14,
        color: '#172B4D'
      },

      '& .MuiAlert-icon': {
        padding: theme.spacing(1.5, 0),
        marginRight: theme.spacing(1.5),
        fontSize: 16,
        opacity: 1
      },

      '& .MuiAlert-standardSuccess .MuiSvgIcon-root': {
        color: '#219653'
      },

      '& .MuiAlert-standardError .MuiSvgIcon-root': {
        color: '#EB5757'
      },

      '& .MuiAlert-standardWarning .MuiSvgIcon-root': {
        color: '#F2C94C'
      },

      '& .MuiAlert-standardInfo .MuiSvgIcon-root': {
        color: '#0052CC'
      }
    }
  }),
  options
);

export default useStyles;
