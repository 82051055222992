import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { ServiceCodeApi, ServiceCodeDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const serviceCodeApi = new ServiceCodeApi();

export const fetchServiceCodeAsync = createFetchAsync(
  'serviceCode',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await serviceCodeApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchServiceCode = fetchServiceCodeAsync.asyncThunk;

export const fetchServiceCode = (payload) => (dispatch, getState) => {
  const {
    serviceCode: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchServiceCode(payload));
  }
};

type ServiceCodeState = {
  findAll: AsyncState<FindAllResponse<ServiceCodeDto>>;
};

const initialState: ServiceCodeState = {
  findAll: fetchServiceCodeAsync.initialState
};

const serviceCode = createSlice({
  name: 'serviceCode',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchServiceCodeAsync.extraReducers
  }
});

export default serviceCode.reducer;
