import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { LocationDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';
import { ClientLocationsApi } from '../../http/requests/client-location.api';

const clientLocationsApi = new ClientLocationsApi();

export const fetchClientLocationsAsync = createFetchAsync(
  'clientLocations',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await clientLocationsApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchClientLocations = fetchClientLocationsAsync.asyncThunk;

export const fetchClientLocations = (payload) => (dispatch, getState) => {
  const {
    clientLocations: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchClientLocations(payload));
  }
};

type ClientLocationsState = {
  findAll: AsyncState<FindAllResponse<LocationDto>>;
};

const initialState: ClientLocationsState = {
  findAll: fetchClientLocationsAsync.initialState
};

const clientLocation = createSlice({
  name: 'ciclientLocationty',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchClientLocationsAsync.extraReducers
  }
});

export default clientLocation.reducer;
