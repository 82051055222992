import { FC } from 'react';
import { UnderConstruction } from '../components/under-construction/UnderConstruction';
import { compose, withAuthorized, withBaseLayout, withHelmet } from '../core';

export const EmptyPage: FC<{ title: string }> = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Page is still empty'
  })
)(UnderConstruction);
