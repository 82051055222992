import { Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const CancelattionVsM2DRevenue = ({
  m2dRevenue,
  parentCancelledRevenue,
  parentCancelledRescheduledRevenue,
  therapistCancelledRevenue,
  therapistCancelledRescheduledRevenue
}) => {
  const data = {
    cutout: 10,
    labels: [
      'Parent Cancelled',
      'Parent C. Rescheduled',
      'Therapist Cancelled',
      'Therapist C. Rescheduled',
      'M2D Revenue'
    ],
    datasets: [
      {
        data: [
          parentCancelledRevenue,
          parentCancelledRescheduledRevenue,
          therapistCancelledRevenue,
          therapistCancelledRescheduledRevenue,
          m2dRevenue
        ],
        backgroundColor: [
          '#A7E0F2',
          '#FEEF68',
          '#FFCB7E',
          '#FF967E',
          '#A8AFF2'
        ],
        borderWidth: 0
      }
    ]
  };
  return (
    <Card
      height={542}
      title="Cancellation vs M2D Revenue"
      width="100%"
      ContentProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      <Box sx={{ width: '50%' }}>
        <Doughnut data={data} options={options} />
      </Box>
    </Card>
  );
};
