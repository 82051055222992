import { Box } from '@mui/system';
import { ComponentType } from 'react';
import useStyles from './styles';
import clsx from 'clsx';

interface Props {
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'error'
    | 'active';
  sx?: any;
}

export const Status: ComponentType<Props> = ({ variant, sx }) => {
  const classes = useStyles();
  return (
    <Box
      sx={sx}
      className={clsx(classes.root, {
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
        [classes.warning]: variant === 'warning',
        [classes.error]: variant === 'error',
        [classes.active]: variant === 'active'
      })}
      component="div"
    />
  );
};
