import { plainToClass } from 'class-transformer';
import {
  API_AUTHORIZATION_FIND_ALL,
  API_AUTHORIZATION_FIND_ONE,
  API_AUTHORIZATION_CREATE,
  API_AUTHORIZATION_DELETE,
  API_AUTHORIZATION_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { AuthorizationDto } from '../dto';
import { FindAllResponse } from '../types';

export class AuthorizationApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<AuthorizationDto>> {
    return this.httpClient
      .get(API_AUTHORIZATION_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(AuthorizationDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<AuthorizationDto> {
    return this.httpClient
      .get(API_AUTHORIZATION_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(AuthorizationDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<AuthorizationDto> {
    return this.httpClient
      .post(API_AUTHORIZATION_CREATE, data)
      .then(({ data }) => {
        return plainToClass(AuthorizationDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<AuthorizationDto> {
    return this.httpClient
      .put(API_AUTHORIZATION_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(AuthorizationDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_AUTHORIZATION_DELETE, {
      pathParams: { uuid }
    });
  }
}
