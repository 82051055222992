import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PageHeader } from '../../components/page-header';
import { RemovableTabs } from '../../components/tabs/RemovableTabs';
import { ClientApi, EmployeeApi } from '../../core/http';
import { generateTabs } from './utils/generateTabs';

const clientApi = new ClientApi();
const employeeApi = new EmployeeApi();

export const Dashboards = () => {
  const { tabs } = useParams();
  const tabKeys = tabs.split(',');
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    generate();
  }, [tabs]);

  const generate = async () => {
    const contentProps = {};
    const labelProps = {};

    for (const tab of tabKeys) {
      const parsedTab = tab.includes(':') ? tab.split(':')[0] : tab;
      const tabValue = tab.includes(':') ? tab.split(':')[1] : '';
      if (parsedTab === 'client-details') {
        const response = await clientApi.findOne(tabValue);
        labelProps['client-details'] = response.displayName;
        contentProps['client-details'] = tabValue;
      }
      if (parsedTab === 'employee-details') {
        const response = await employeeApi.findOne(tabValue);
        labelProps['employee-details'] = response.displayName;
        contentProps['employee-details'] = tabValue;
      }
    }

    const data = generateTabs(tabKeys, contentProps, labelProps);
    setItems(data);
  };

  return (
    <Box>
      <PageHeader title="Dashboards" divider={false}>
        <Button title="Add new dashboard">Add new dashboard</Button>
      </PageHeader>
      <RemovableTabs items={items} isRemovable />
    </Box>
  );
};
