import React, { ComponentType } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import TableCell from '@mui/material/TableCell';
import { DataTableColumn } from '../TableView';
import { TableViewHeaderCell } from './TableViewHeaderCell';
import { SortingState } from '../useTableSorting';
import { FilteringState } from '../useTableFiltering';
import get from 'lodash/get';
import TableCell from '@mui/material/TableCell';
import { Checkbox } from '../../fields';

interface Props {
  isExpandable: boolean;
  isSelectable: boolean;
  isSelectedRowsEmpty: boolean;
  isSelectedAll: boolean;
  handleSelectAll: () => void;
  columns: DataTableColumn[];
  sort: (column: string) => void;
  sortingState: SortingState;

  filteringState: FilteringState;
  filter: (column: string, value: string) => void;
  clearFilter: (column: string) => void;
  loading?: boolean;
}

export const TableViewHeader: ComponentType<Props> = ({
  // isExpandable,
  isSelectable,
  isSelectedRowsEmpty,
  isSelectedAll,
  handleSelectAll,
  columns,
  sort,
  sortingState,
  filteringState,
  filter,
  clearFilter,
  loading
}) => {
  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <TableCell sx={{ width: 40 }}>
            <Checkbox
              color="secondary"
              checked={loading === false && isSelectedAll}
              indeterminate={!isSelectedRowsEmpty && !isSelectedAll}
              onClick={handleSelectAll}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableViewHeaderCell
            key={column.key}
            {...{
              column,
              sort,
              sortDirection: get(sortingState, column.key, null),
              filter,
              clearFilter,
              filteredValue: get(filteringState, column.key, null)
            }}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};
