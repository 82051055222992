import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminUsersContainer } from '../../features/super-admin-users/SuperAdminUsersContainer';

export const SuperAdminUsersPage = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Users'
  })
)(SuperAdminUsersContainer);
