import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { ClientAvailabilityDto } from '../dto';

export class ClientAvailabilityApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<ClientAvailabilityDto>> {
    return this.httpClient
      .get(`/api/v1/clients/${params.clientUuid}/availability`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ClientAvailabilityDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(
    clientUuid: string,
    uuid: string
  ): Promise<ClientAvailabilityDto> {
    return this.httpClient
      .get(`/api/v1/clients/${clientUuid}/availability/${uuid}`)
      .then(({ data }) => {
        return plainToClass(ClientAvailabilityDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<ClientAvailabilityDto> {
    return this.httpClient
      .post(`/api/v1/clients/${data.uuid}/availability`, data)
      .then(({ data }) => {
        return plainToClass(ClientAvailabilityDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<ClientAvailabilityDto> {
    return this.httpClient
      .put(`/api/v1/clients/${data.employeeUuid}/availability`, data)
      .then(({ data }) => {
        return plainToClass(ClientAvailabilityDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(params, uuid): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/clients/${params.clientUuid}/availability/${uuid}`
    );
  }
}
