import { ComponentType } from 'react';
import { Box, Button, Stack, Skeleton } from '@mui/material';
import { Tabs } from '../../components/tabs';
import { PageHeader } from '../../components/page-header';
import { IconInfo } from '../../components/icons';
import { Dashboard } from './tabs/Dashboard';
import { Profile } from './tabs/Profile';
import { Badge } from '../../components/badge';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_EMPLOYEE_PROFILE_EDIT,
  ROUTE_EMPLOYEES
} from '../../constants/route.paths';
import { Appointments } from './tabs/Appointments';
import { Availability } from './tabs/Availability';
import { useEmployeeDetail } from '../../core/hooks/useEmployeeDetail';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useDialog } from '../../core/hooks/useDialog';
import { Files } from './tabs/Files';
import { EmployeeApi } from '../../core/http';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { useLayout } from '../../core/hooks/useLayout';
import { PermissionView } from '../../components/permission-view';
import { useUser } from '../../core/hooks/useUser';
import { DEV_MODE } from '../../constants/app.constants';

const employeeApi = new EmployeeApi();

export const EmployeeDetails: ComponentType = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { user } = useUser();
  const { id } = useParams();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  useLayout({
    backButton: {
      title: 'Employees',
      path: ROUTE_EMPLOYEES
    }
  });

  const { isLoading, data } = useEmployeeDetail({
    uuid: id
  });

  const handleTerminate = () => () => {
    openConfirm({
      title: 'Terminate',
      message: `Do you really want to terminate employee ${data.displayName}? `,
      onConfirm: () => {
        employeeApi
          .delete(data.uuid)
          .then(() => {
            showMessage(`Employee ${data.displayName} terminated successfully`);
            navigate(ROUTE_EMPLOYEES);
          })
          .finally(() => {
            closeConfirm();
          });
      }
    });
  };

  const headerProps = {
    divider: false,
    title:
      isLoading || !data ? (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
        </Stack>
      ) : (
        data?.firstName + ' ' + data?.lastName
      ),
    meta:
      isLoading || !data ? (
        <>
          <Skeleton variant="text" sx={{ height: 32, width: 32 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 80 }} />
        </>
      ) : (
        <>
          <Badge label={data?.id || '-'} variant={'secondary'} />
          <Badge
            label={data?.isDeleted ? 'Terminated' : 'Active'}
            variant={data?.isDeleted ? 'error' : 'success'}
            RightIcon={<IconInfo />}
          />
        </>
      )
  };

  const tabs = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      content: <Dashboard />,
      permission: 'viewEmployees'
    },
    {
      key: 'profile',
      label: 'Profile',
      content: <Profile />,
      permission: 'viewEmployees'
    },
    {
      key: 'appointments',
      label: 'Appointments',
      content: <Appointments />,
      permission: 'viewAppointments'
    },

    {
      key: 'availability',
      label: 'Availability',
      content: <Availability />,
      permission: 'viewEmployees'
    },
    {
      key: 'files',
      label: 'Files',
      content: <Files />,
      permission: 'viewUploadNewFiles'
    }
  ];

  const enhancedTabs = tabs.filter(
    (item) => DEV_MODE || user.userRole.permissions[item.permission]
  );

  return (
    <>
      <PageHeader {...headerProps}>
        <PermissionView permission="modifyEmployees">
          <Button
            onClick={() =>
              navigate(url(ROUTE_EMPLOYEE_PROFILE_EDIT, { id: id }))
            }
          >
            Edit profile
          </Button>
        </PermissionView>
        <PermissionView permission="modifyEmployees">
          <Button
            color="secondary"
            onClick={handleTerminate()}
            disabled={isLoading || data?.isDeleted}
          >
            Terminate
          </Button>
        </PermissionView>
      </PageHeader>
      <Box>
        <Tabs hashed items={enhancedTabs} />
      </Box>
    </>
  );
};
