import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTE_DASHBOARDS_SELECTED } from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { DashboardCard } from './components/DashboardCard';
import { selectableTabs, possibleTabs } from './_mockdata';
import { PermissionView } from '../../components/permission-view';
import { generateTabs } from './utils/generateTabs';
import { useUser } from '../../core/hooks/useUser';
import { DEV_MODE } from '../../constants/app.constants';

export const DashboardsSelect = () => {
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();
  const canContinue = !isEmpty(selectedDashboards);

  const allowedDashboards = selectableTabs.filter(
    (item) =>
      DEV_MODE || user.userRole.permissions[possibleTabs[item].permission]
  );
  const visibleDashboards = generateTabs(allowedDashboards);

  const handleSelect = (key: string) => {
    if (selectedDashboards.includes(key)) {
      const filteredDashboards = selectedDashboards.filter(
        (currentKey) => currentKey !== key
      );
      setSelectedDashboards(filteredDashboards);
    } else {
      setSelectedDashboards((prevState) => {
        return [...prevState, key];
      });
    }
  };

  const handleContinue = () => {
    navigate(
      url(ROUTE_DASHBOARDS_SELECTED, {
        tabs: selectedDashboards.join(',')
      })
    );
  };

  return (
    <Box>
      <PermissionView permission="viewDashboard">
        <Stack direction="column" mt={8}>
          <Typography variant="h4" fontSize={24} textAlign="center">
            Dashboards
          </Typography>
          <Typography variant="body3" textAlign="center" color="#647593">
            Choose your set of dashboards:
          </Typography>
        </Stack>
      </PermissionView>
      {!visibleDashboards.length && (
        <Typography variant="body3" textAlign="center" color="#647593">
          No dashboard available
        </Typography>
      )}
      <Grid container spacing={1} mt={3.25} mb={4.25}>
        {map(visibleDashboards, ({ key, label, description }) => (
          <Grid item md={3} key={key}>
            <DashboardCard
              title={label}
              checked={selectedDashboards.includes(key)}
              description={description}
              onSelect={() => handleSelect(key)}
            />
          </Grid>
        ))}
      </Grid>
      <Box textAlign="center">
        <Button onClick={() => handleContinue()} disabled={!canContinue}>
          Continue
        </Button>
      </Box>
    </Box>
  );
};
