import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { EmployeeApi, EmployeeDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const employeeApi = new EmployeeApi();

export const fetchEmployeeAsync = createFetchAsync(
  'employee',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await employeeApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchActiveEmployeeAsync = createFetchAsync(
  'employee',
  'findAllActive',
  async (params, { rejectWithValue }) => {
    try {
      return await employeeApi.findAll({
        ...params,
        'filter[isDeleted][eq]': 0
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchEmployee = fetchEmployeeAsync.asyncThunk;
export const forceFetchActiveEmployee = fetchActiveEmployeeAsync.asyncThunk;

export const fetchEmployee = (payload) => (dispatch, getState) => {
  const {
    employee: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchEmployee(payload));
  }
};

export const fetchActiveEmployee = (payload) => (dispatch, getState) => {
  const {
    employee: { findAllActive }
  } = getState();

  if (!findAllActive.payload && findAllActive.loading == false) {
    dispatch(forceFetchActiveEmployee(payload));
  }
};

type EmployeeState = {
  findAll: AsyncState<FindAllResponse<EmployeeDto>>;
  findAllActive: AsyncState<FindAllResponse<EmployeeDto>>;
};

const initialState: EmployeeState = {
  findAll: fetchEmployeeAsync.initialState,
  findAllActive: fetchActiveEmployeeAsync.initialState
};

const employee = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchEmployeeAsync.extraReducers,
    ...fetchActiveEmployeeAsync.extraReducers
  }
});

export default employee.reducer;
