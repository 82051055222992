import { plainToClass } from 'class-transformer';
import {
  API_ORGANIZATION_FIND_ALL,
  API_ORGANIZATION_FIND_ONE,
  API_ORGANIZATION_CREATE,
  API_ORGANIZATION_DELETE,
  API_ORGANIZATION_UPDATE,
  API_ORGANIZATION_FIND_BY_CODE
} from '../api.routes';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { OrganizationDto } from '../dto/organization-dto';

export class OrganizationApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<OrganizationDto>> {
    return this.httpClient
      .get(API_ORGANIZATION_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(OrganizationDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: any): Promise<OrganizationDto> {
    return this.httpClient
      .get(API_ORGANIZATION_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(OrganizationDto, data);
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findByCode(code: any): Promise<OrganizationDto> {
    return this.httpClient
      .get(API_ORGANIZATION_FIND_BY_CODE, { pathParams: { code } })
      .then(({ data }) => {
        return plainToClass(OrganizationDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<OrganizationDto> {
    return this.httpClient
      .post(API_ORGANIZATION_CREATE, data)
      .then(({ data }) => {
        return plainToClass(OrganizationDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<OrganizationDto> {
    return this.httpClient
      .put(API_ORGANIZATION_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(OrganizationDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_ORGANIZATION_DELETE, {
      pathParams: { uuid }
    });
  }
}
