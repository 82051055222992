import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchAppointmentCancelType } from '../store/slices/appointmentCancelTypeSlice';

export const useAppointmentCancelType = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector(
    (state: AppState) => state.appointmentCancelType
  );

  useEffect(() => {
    dispatch(fetchAppointmentCancelType({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
