import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { FileAttach } from '../../../components/fields';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const UploadPhotoDialog: ComponentType<Props> = ({ popDialog }) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 700
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        }
      }}
    >
      <DialogTitle>Upload Photo</DialogTitle>
      <DialogContent>
        <FileAttach />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
