import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import authService from '../../configs/authService';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface ISignInValues {
  username: string;
  password: string;
  organizationCode: string;
}

interface IGetOrganizationValues {
  organizationCode: string;
}

interface ISignInThunkArguments {
  values: ISignInValues;
  options?: Partial<IRequestOptions>;
}

interface IGetOrganizationThunkArguments {
  values: IGetOrganizationValues;
  options?: Partial<IRequestOptions>;
}

export const signIn = createAsyncThunk(
  'signInAction',
  async ({ values, options }: ISignInThunkArguments) => {
    try {
      const data: any = await authService.post('/login', {
        ...values,
        organizationCode: +values.organizationCode
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data.data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getOrganization = createAsyncThunk(
  'getOrganizationAction',
  async ({ values, options }: IGetOrganizationThunkArguments) => {
    try {
      if (values.organizationCode === '312') {
        const data: any = await delay(500, {
          organization: 'Diffco',
          logo: 'https://i.ibb.co/6sDSTZb/diffcous-logo.png',
          organizationCode: +values.organizationCode
        });
        return data;
      } else {
        throw new Error();
      }
      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const resetOrganization = createAction('resetOrganizationAction');
export const resetSignIn = createAction('resetSignInAction');

export const signInSlice = createSlice({
  name: 'signInSlice',
  initialState: {
    signIn: {
      loading: false,
      data: null,
      error: null
    },
    getOrganization: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.signIn.loading = true;
      state.signIn.data = {};
      state.signIn.error = null;
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.signIn.loading = false;
      state.signIn.data = payload;
      state.signIn.error = null;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.signIn.loading = false;
      state.signIn.data = {};
      state.signIn.error = 'Invalid email or password';
    });
    builder.addCase(resetSignIn, (state) => {
      state.signIn.loading = false;
      state.signIn.data = null;
      state.signIn.error = null;
    });
    builder.addCase(getOrganization.pending, (state) => {
      state.getOrganization.loading = true;
      state.getOrganization.data = {};
      state.getOrganization.error = null;
    });
    builder.addCase(resetOrganization, (state) => {
      state.getOrganization.loading = false;
      state.getOrganization.data = {};
      state.getOrganization.error = null;
    });
    builder.addCase(getOrganization.fulfilled, (state, { payload }) => {
      state.getOrganization.loading = false;
      state.getOrganization.data = payload;
      state.getOrganization.error = null;
    });
    builder.addCase(getOrganization.rejected, (state) => {
      state.getOrganization.loading = false;
      state.getOrganization.data = {};
      state.getOrganization.error = 'Company with that code was not found';
    });
  }
});

export default signInSlice.reducer;
