import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { API_SARS_CANCELLATION_REPORT } from '../api.routes';
import { SarsCancellationReportDto } from '../dto/sars-cancellation-report.dto';

export class SarsCancellationReportApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<SarsCancellationReportDto>> {
    return this.httpClient
      .get(API_SARS_CANCELLATION_REPORT, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(SarsCancellationReportDto, data.items)
        };
      });
  }
}
