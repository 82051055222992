import { useState, useCallback } from 'react';

export type FilteringState = Record<string, any>;

export const useTableFiltering = () => {
  const [filteringState, setFilteringState] = useState<FilteringState>({});

  const filter = useCallback((column: string, value: any) => {
    setFilteringState((prevState) => ({
      ...prevState,
      [column]: value
    }));
  }, []);

  const clearFilter = useCallback((column: string) => {
    setFilteringState((prevState) => {
      const { [column]: _, ...rest } = prevState;

      return rest;
    });
  }, []);

  return {
    filteringState,
    filter,
    clearFilter
  };
};
