import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { PlaceServiceApi, PlaceServiceDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const placeServiceApi = new PlaceServiceApi();

export const fetchPlaceServiceAsync = createFetchAsync(
  'placeService',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await placeServiceApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchPlaceService = fetchPlaceServiceAsync.asyncThunk;

export const fetchPlaceService = (payload) => (dispatch, getState) => {
  const {
    placeService: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchPlaceService(payload));
  }
};

type PlaceServiceState = {
  findAll: AsyncState<FindAllResponse<PlaceServiceDto>>;
};

const initialState: PlaceServiceState = {
  findAll: fetchPlaceServiceAsync.initialState
};

const placeService = createSlice({
  name: 'placeService',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchPlaceServiceAsync.extraReducers
  }
});

export default placeService.reducer;
