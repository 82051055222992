import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import {
  productivityData,
  authExpiringData
} from '../../../features/clients/_mockdata';

export const getProductivity = createAsyncThunk(
  'getProductivityAction',
  async () => {
    try {
      const data: any = await delay(500, productivityData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getAuthExpiring = createAsyncThunk(
  'getAuthExpiringAction',
  async () => {
    try {
      const data: any = await delay(500, authExpiringData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const clientDashboardSlice = createSlice({
  name: 'clientDashboardSlice',
  initialState: {
    getProductivity: {
      loading: false,
      data: null,
      error: null
    },
    getAuthExpiring: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductivity.pending, (state) => {
      state.getProductivity.loading = true;
      state.getProductivity.data = null;
      state.getProductivity.error = null;
    });
    builder.addCase(getProductivity.fulfilled, (state, { payload }) => {
      state.getProductivity.loading = false;
      state.getProductivity.data = payload;
      state.getProductivity.error = null;
    });
    builder.addCase(getProductivity.rejected, (state) => {
      state.getProductivity.loading = false;
      state.getProductivity.data = null;
      state.getProductivity.error = 'Something went wrong';
    });
    builder.addCase(getAuthExpiring.pending, (state) => {
      state.getAuthExpiring.loading = true;
      state.getAuthExpiring.data = null;
      state.getAuthExpiring.error = null;
    });
    builder.addCase(getAuthExpiring.fulfilled, (state, { payload }) => {
      state.getAuthExpiring.loading = false;
      state.getAuthExpiring.data = payload;
      state.getAuthExpiring.error = null;
    });
    builder.addCase(getAuthExpiring.rejected, (state) => {
      state.getAuthExpiring.loading = false;
      state.getAuthExpiring.data = null;
      state.getAuthExpiring.error = 'Something went wrong';
    });
  }
});

export default clientDashboardSlice.reducer;
