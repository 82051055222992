import { Expose } from 'class-transformer';

export class MessageDto {
  @Expose()
  id: string;

  @Expose()
  message: string;

  @Expose()
  status: string;

  @Expose()
  uploadedBy: any;

  @Expose()
  uploadedDate: string;
}
