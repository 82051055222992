import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const WeeklyActualVsSheduledVsForecast = () => {
  const data = {
    labels: [
      'Dec 21 Wk1',
      'Dec 21 Wk2',
      'Dec 21 Wk3',
      'Dec 21 Wk4',
      'Jan 22 Wk1',
      'Jan 22 Wk2',
      'Jan 22 Wk3',
      'Jan 22 Wk4',
      'Feb 22 Wk1',
      'Feb 22 Wk2'
    ],
    datasets: [
      {
        label: 'Forecast',
        data: [...Array(10)].map(() => Math.random() * 100000),
        backgroundColor: Array(4).fill('#A8AFF2'),
        borderWidth: 0,
        stack: 'Stack 0'
      },
      {
        label: 'Scheduled',
        data: [...Array(10)].map(() => Math.random() * 100000),
        backgroundColor: Array(4).fill('#A7E0F2'),
        borderWidth: 0,
        stack: 'Stack 0'
      },
      {
        label: 'Converted',
        data: [...Array(10)].map(() => Math.random() * 100000),
        backgroundColor: Array(4).fill('#FEEF68'),
        borderWidth: 0,
        stack: 'Stack 0'
      },
      {
        label: 'Cancellation $',
        data: [...Array(10)].map(() => Math.random() * 100000),
        backgroundColor: Array(4).fill('#FFCB7E'),
        borderWidth: 0,
        stack: 'Stack 0'
      }
    ]
  };
  return (
    <Card
      height={428}
      title="Weekly Actual vs Scheduled vs Forecast"
      width="100%"
    >
      <Bar
        data={data}
        options={options}
        style={{ maxHeight: 292, width: '100%' }}
      />
    </Card>
  );
};
