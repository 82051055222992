import { PageHeader } from '../../components/page-header';
import {
  DatePicker,
  EmployeeField,
  EnumField,
  TextField
} from '../../components/fields';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { IconPen, IconTrash } from '../../components/icons';
import { TableView } from '../../components/table-view';
import { newsColumns } from './_mockdata';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { Card } from '../../components/cards';
import { useFilter } from '../../core/hooks/useFilter';
import { useNavigate } from 'react-router-dom';
import {
  ROUTE_NEWS_CREATE,
  ROUTE_NEWS_EDIT
} from '../../constants/route.paths';
import { url } from '../../core';
import { useListResource } from '../../core/hooks/useListResource';
import { NewsApi } from '../../core/http/requests/news.api';
import get from 'lodash/get';
import { NEWS_VISIBILITY } from '../../constants/enums';
import { PermissionView } from '../../components/permission-view';
import { ActiveEmployeeAutocompleteField } from '../../components/fields/active-employee-autocomplete-field';

const newsApi = new NewsApi();

export const NewsContainer = () => {
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(newsApi);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  const handleOpenEdit =
    (uuid = '') =>
    () => {
      navigate(url(ROUTE_NEWS_EDIT, { id: uuid }));
    };

  return (
    <>
      <PageHeader title="News">
        <PermissionView permission="modifyUploadNewFiles">
          <Button
            onClick={() => {
              navigate(ROUTE_NEWS_CREATE);
            }}
          >
            Add New
          </Button>
        </PermissionView>
      </PageHeader>
      <Card>
        <Grid container spacing={1}>
          <Grid item md={3.33}>
            <TextField
              label="Title"
              placeholder="Search"
              size="small"
              helperText={null}
              value={getFilterValue('search')}
              onChange={setFilterValue('search')}
            />
          </Grid>
          <Grid item md={3.33}>
            <ActiveEmployeeAutocompleteField
              label="Publisher"
              placeholder="Search"
              size="small"
              helperText={null}
              value={getFilterValue('filter[publisher][eq]')}
              onChange={setFilterValue('filter[publisher][eq]')}
            />
          </Grid>
          <Grid item md={3.33}>
            <EnumField
              label="Visibility"
              placeholder="Select"
              helperText={null}
              size="small"
              items={NEWS_VISIBILITY}
              value={getFilterValue('filter[visibility][eq]')}
              onChange={setFilterValue('filter[visibility][eq]')}
            />
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <DatePicker
                label="Publish Date"
                size="small"
                helperText={null}
                value={getFilterValue('filter[publishedAt][gt]', null)}
                onChange={setFilterValue('filter[publishedAt][gt]')}
              />
              <Button onClick={handleSearch}>Search</Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <TableView
        loading={isLoading}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        showTablePerPage={true}
        showPagination={true}
        onChangeSort={handleChangeSort}
        pagination={pagination}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        showEmptyState
        columns={[
          ...newsColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, record) => (
              <Stack direction="row" alignItems="center">
                <IconButton size="small" onClick={handleOpenEdit(record.uuid)}>
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(record.uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
