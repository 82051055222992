import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { CityDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';
import { CityApi } from '../../http/requests/city.api';

const cityApi = new CityApi();

export const fetchCityAsync = createFetchAsync(
  'city',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await cityApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchCity = fetchCityAsync.asyncThunk;

export const fetchCity = (payload) => (dispatch, getState) => {
  const {
    city: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchCity(payload));
  }
};

type CityState = {
  findAll: AsyncState<FindAllResponse<CityDto>>;
};

const initialState: CityState = {
  findAll: fetchCityAsync.initialState
};

const city = createSlice({
  name: 'city',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchCityAsync.extraReducers
  }
});

export default city.reducer;
