import { ClientAppointmentEdit } from '../../../features/clients/ClientAppointmentEdit';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const ClientAppointmentEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Client appointments'
  })
)(ClientAppointmentEdit);
