import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { TimesheetApi, TimesheetDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const timesheetApi = new TimesheetApi();

export const fetchTimesheetAsync = createFetchAsync(
  'timesheet',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await timesheetApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchTimesheet = fetchTimesheetAsync.asyncThunk;

export const fetchATimesheet = (payload) => (dispatch, getState) => {
  const {
    timesheet: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchTimesheet(payload));
  }
};

type TimesheetState = {
  findAll: AsyncState<FindAllResponse<TimesheetDto>>;
};

const initialState: TimesheetState = {
  findAll: fetchTimesheetAsync.initialState
};

const timesheet = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {},
  extraReducers: {
    ...forceFetchTimesheet.extraReducers
  }
});

export default timesheet.reducer;
