import { ComponentType } from 'react';
import { TextField } from '../text-field';

interface Props {
  onChange?: (value: string) => void;
  onBlur?: any;
  placeholder?: string;
  helperText?: string | null | any;
  errors?: any;
  value?: any;
  disabled?: boolean;
  maxLength?: number;
  label?: string;
  required?: boolean;
}

export const NumberField: ComponentType<Props> = ({
  onChange,
  onBlur,
  label,
  helperText,
  value = null,
  placeholder,
  disabled = false,
  errors,
  required,
  ...props
}) => {
  const handleKeyPress = (charCode: number) => {
    if (
      !(
        charCode >= 9 &&
        charCode <= 57 &&
        charCode != 43 &&
        charCode != 45 &&
        charCode != 32
      )
    ) {
      event.preventDefault();
    }
  };
  return (
    <TextField
      required={required}
      disabled={disabled}
      errors={errors}
      helperText={errors || helperText}
      value={value}
      {...{ label, placeholder, ...props }}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={(event) => handleKeyPress(event.charCode)}
    />
  );
};
