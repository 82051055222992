import { Box, Skeleton, Stack } from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../components/cards';
import { useListResource } from '../../../core/hooks/useListResource';
import { EmployeeProductivityApi } from '../../../core/http/requests/employee-productivity.api';
import { options } from '../tabs/charts.config';

const productivityApi = new EmployeeProductivityApi();

export const ProductivityDashboardWidget = ({ employeeUuid }) => {
  const { data, isLoading } = useListResource(productivityApi, {
    defaultParams: { employeeUuid }
  });

  const [transformedData, setTransformedData] = useState<any>();
  const chartBackgrounds = ['#749AFD', '#4172C8', '#A5A5A5', '#FFBA00'];

  useEffect(() => {
    if (!isLoading && data) {
      const dataLabels = {
        terminated: 'Completion Rate',
        cancelled: 'Cancellation Rate',
        staffCancelled: 'Staff Cancelation',
        parentCancelled: 'Parent Cancellation'
      };

      const datas = [];

      for (const key in data) {
        if (dataLabels[key]) {
          datas.push({
            key: key,
            value: data[key]
          });
        }
      }

      const d = map(datas, (item, index) => {
        const valuesTemp = new Array(4).fill(undefined);
        valuesTemp[index] = item.value;
        const obj = {
          data: valuesTemp,
          backgroundColor: chartBackgrounds[index],
          barPercentage: 1,
          stack: 'Stack 0',
          label: dataLabels[item.key]
        };

        return obj;
      });
      const labels =
        d &&
        d.map((obj: any) => {
          return obj.label;
        });

      const chartsData = {
        labels,
        datasets: d || [
          {
            label: '',
            data: [0],
            backgroundColor: '#fff',
            barPercentage: 1,
            stack: 'Stack 0'
          }
        ]
      };
      setTransformedData(chartsData);
    }
  }, [data]);

  return (
    <Card sx={{ height: 331 }} title="Productivity Dashboard">
      {isLoading ? (
        <Stack direction="row" alignItems={'center'}>
          <Skeleton variant="rectangular" width={400} height={250} />
          <Stack spacing={1} ml={1}>
            <Skeleton variant="text" width={120} height={12} />
            <Skeleton variant="text" width={120} height={12} />
            <Skeleton variant="text" width={120} height={12} />
            <Skeleton variant="text" width={120} height={12} />
          </Stack>
        </Stack>
      ) : (
        <Box
          sx={{
            width: 500,
            height: 300
          }}
        >
          {!isLoading && transformedData && (
            <Bar options={options} data={transformedData} />
          )}
        </Box>
      )}
    </Card>
  );
};
