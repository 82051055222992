import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import { clientAuthorizationsData } from '../../../features/clients/_mockdata';

interface IValues {
  id: number;
}

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}
interface IThunkArguments {
  values: IValues;
  options?: Partial<IRequestOptions>;
}

export const getServiceAuthorization = createAsyncThunk(
  'getServiceAuthorization',
  async ({ options }: IThunkArguments) => {
    try {
      const data: any = await delay(500, clientAuthorizationsData);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const clientServiceAuthorizationSlice = createSlice({
  name: 'clientServiceAuthorizationSlice',
  initialState: {
    getServiceAuthorization: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceAuthorization.pending, (state) => {
      state.getServiceAuthorization.loading = true;
      state.getServiceAuthorization.data = null;
      state.getServiceAuthorization.error = null;
    });
    builder.addCase(getServiceAuthorization.fulfilled, (state, { payload }) => {
      state.getServiceAuthorization.loading = false;
      state.getServiceAuthorization.data = payload;
      state.getServiceAuthorization.error = null;
    });
    builder.addCase(getServiceAuthorization.rejected, (state) => {
      state.getServiceAuthorization.loading = false;
      state.getServiceAuthorization.data = null;
      state.getServiceAuthorization.error = 'Something went wrong';
    });
  }
});

export default clientServiceAuthorizationSlice.reducer;
