import { ComponentType, useEffect, useState } from 'react';
import { Box, Button, Stack, IconButton } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { availabilityDataColumns } from '../_mockdata';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { IconPen, IconTrash } from '../../../components/icons';
import { AddAvailabilityDialog } from '../dialogs/AddAvailabilityDialog';
import { AddLeaveDaysDialog } from '../dialogs/AddLeaveDaysDialog';
import { useListResource } from '../../../core/hooks/useListResource';
import { EmployeeAvailabilityApi } from '../../../core/http/requests/employee-availability.api';
import { useParams } from 'react-router';
import { get } from 'lodash';
import {
  EmployeeAvailabilityDto,
  LeaveDayApi,
  LeaveDayDto
} from '../../../core/http';
import { format } from 'date-fns';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { PermissionView } from '../../../components/permission-view';
import { capitalizeFirstLetter } from '../../../core';

const employeeAvailabilityApi = new EmployeeAvailabilityApi();
const employeeLeaveDaysApi = new LeaveDayApi();

export const Availability: ComponentType = () => {
  const [openConfirmDialog, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const [convertedData, setConvertedData] = useState([]);
  const [openAvailabilityDialog] = useDialog(AddAvailabilityDialog);
  const [openAddLeaveDays, closeAddLeaveDays] = useDialog(AddLeaveDaysDialog);
  const { id } = useParams();
  const {
    data,
    isLoading,
    pagination,
    fetchData,
    handleChangePage,
    handleChangePerPage,
    handleChangeSort
  } = useListResource(employeeAvailabilityApi, {
    defaultParams: { employeeUuid: id }
  });

  const { data: leaveDaysData, fetchData: leaveDaysFetchData } =
    useListResource(employeeLeaveDaysApi, {
      defaultParams: { employeeUuid: id }
    });

  const handleCreateAvailability = () => {
    openAvailabilityDialog({ employeeUuid: id, fetchData });
  };

  const handleCreateLeaveDays = () => {
    openAddLeaveDays({
      employeeUuid: id,
      onSuccess: () => {
        closeAddLeaveDays();
        leaveDaysFetchData();
      }
    });
  };

  const handleDeleteAvailability = (uuid: string) => {
    openConfirmDialog({
      title: 'Delete availability',
      message: 'Do you really want to delete this availability ?',
      onConfirm: () => {
        employeeAvailabilityApi.delete({ employeeUuid: id }, uuid).then(() => {
          showMessage('Availability was deleted successfully');
          closeConfirm();
          fetchData();
        });
      }
    });
  };

  const handleDeleteLeaveDay = (uuid: string) => {
    openConfirmDialog({
      title: 'Delete leave days',
      message: 'Do you really want to delete this leave days ?',
      onConfirm: () => {
        employeeLeaveDaysApi.delete({ employeeUuid: id }, uuid).then(() => {
          showMessage('Leave days were deleted successfully');
          closeConfirm();
          leaveDaysFetchData();
        });
      }
    });
  };

  const handleEditAvailability = (uuid) => {
    openAvailabilityDialog({ uuid, fetchData, employeeUuid: id });
  };

  const handleEditLeaveDay = (uuid) => {
    openAddLeaveDays({ uuid, leaveDaysFetchData, employeeUuid: id });
  };

  useEffect(() => {
    if (data && data.items && leaveDaysData && leaveDaysData.items) {
      const availability = get(data, 'items')?.map(
        (item: EmployeeAvailabilityDto) => {
          return {
            ...item,
            root: 'availability',
            scheduling: `${item.weekday.join(',')} (From ${item.startTime} to ${
              item.endTime
            } -
            ${format(new Date(item.dateFrom), 'MM/dd/yyyy')} - ${format(
              new Date(item.dateTo),
              'MM/dd/yyyy'
            )})
            `
          };
        }
      );
      const leaveDays = get(leaveDaysData, 'items')?.map(
        (item: LeaveDayDto) => {
          return {
            ...item,
            root: 'leaveDay',
            scheduling: `${capitalizeFirstLetter(item.type)} - ${format(
              new Date(item.startDate),
              'MM/dd/yyyy'
            )} - ${format(new Date(item.endDate), 'MM/dd/yyyy')}`
          };
        }
      );
      setConvertedData([...availability, ...leaveDays]);
    }
  }, [data, leaveDaysData]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Stack spacing={0.5} direction="row">
          <PermissionView permission="modifyEmployees">
            <Button onClick={handleCreateAvailability}>Add new</Button>
          </PermissionView>
          <PermissionView permission="modifyEmployees">
            <Button onClick={handleCreateLeaveDays}>Add leave days</Button>
          </PermissionView>
        </Stack>
        <SearchField sx={{ width: 400 }} size="small" placeholder="Search" />
      </Stack>
      <Box>
        <TableView
          loading={isLoading}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={true}
          showPagination={true}
          pagination={pagination}
          dataSource={convertedData || []}
          showEmptyState
          EmptyStateProps={{
            title: 'You dont have any availabilities yet.',
            description: null,
            action: <Button onClick={handleCreateAvailability}>Add new</Button>
          }}
          keyExtractor={(item) => item.uuid}
          columns={[
            ...availabilityDataColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, { uuid, root }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <PermissionView permission="modifyEmployees">
                    <IconButton
                      component="span"
                      size="small"
                      onClick={() => {
                        if (root === 'availability') {
                          handleEditAvailability(uuid);
                        } else if (root === 'leaveDay') {
                          handleEditLeaveDay(uuid);
                        }
                      }}
                    >
                      <IconPen />
                    </IconButton>
                  </PermissionView>
                  <PermissionView permission="modifyEmployees">
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (root === 'availability') {
                          handleDeleteAvailability(uuid);
                        } else if (root === 'leaveDay') {
                          handleDeleteLeaveDay(uuid);
                        }
                      }}
                    >
                      <IconTrash />
                    </IconButton>
                  </PermissionView>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
