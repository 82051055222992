import { makeStyles } from '@mui/styles';

const options = {
  name: 'badge'
};

const useStyles = makeStyles(
  () => ({
    root: {
      width: 10,
      height: 10,
      borderRadius: '50%'
    },
    primary: {
      backgroundColor: '#CEF9FF'
    },
    secondary: {
      backgroundColor: '#EAF1FF',
      color: '#496492'
    },
    warning: {
      backgroundColor: '#F2C94C',
      color: '#D1A621'
    },
    error: {
      backgroundColor: '#EB5757'
    },
    info: {
      backgroundColor: '#EAF1FF'
    },
    active: {
      backgroundColor: '#53EF94',
      color: '#219653'
    }
  }),
  options
);

export default useStyles;
