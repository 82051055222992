import { plainToClass } from 'class-transformer';
import { API_EMPLOYEE_ABSENCE_SCHEDULE_FIND_ALL } from '../api.routes';
import { BaseApi } from './base.api';
import { EmployeeAbsenceScheduleDto } from '../dto';
import { FindAllResponse } from '../types';

export class EmployeeAbsenceScheduleApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeAbsenceScheduleDto>> {
    return this.httpClient
      .get(API_EMPLOYEE_ABSENCE_SCHEDULE_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeAbsenceScheduleDto, data.items)
        };
      });
  }
}
