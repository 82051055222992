import { AuthLayout } from '../../../layouts/auth-layout';
import { ResetPassword } from '../../../features/auth/ResetPassword';
import { Helmet } from 'react-helmet';
export const ResetPasswordPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Reset Password</title>
      </Helmet>
      <ResetPassword />
    </AuthLayout>
  );
};
