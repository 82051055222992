import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { EmployeeAvailabilityDto } from '../dto';

export class EmployeeAvailabilityApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeAvailabilityDto>> {
    return this.httpClient
      .get(
        `/api/v1/employees/${params.employeeUuid}/availability?clientUuid=${params.employeeUuid}`
      )
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeAvailabilityDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(
    employeeUuid: string,
    uuid: string
  ): Promise<EmployeeAvailabilityDto> {
    return this.httpClient
      .get(`/api/v1/employees/${employeeUuid}/availability/${uuid}`)
      .then(({ data }) => {
        return plainToClass(EmployeeAvailabilityDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<EmployeeAvailabilityDto> {
    return this.httpClient
      .post(`/api/v1/employees/${data.uuid}/availability`, data)
      .then(({ data }) => {
        return plainToClass(EmployeeAvailabilityDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<EmployeeAvailabilityDto> {
    return this.httpClient
      .put(`/api/v1/employees/${data.employeeUuid}/availability`, data)
      .then(({ data }) => {
        return plainToClass(EmployeeAvailabilityDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(params, uuid): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/employees/${params.employeeUuid}/availability/${uuid}`
    );
  }
}
