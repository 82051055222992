import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { useStyles } from './styles';
import circleCheck from '../../assets/circle-check.svg';
import { ROUTE_SIGN_IN } from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';

export const ResetPasswordSuccess: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Box>
          <img src={circleCheck} />
          <Typography variant="h2" mt={3}>
            Your request was sent successfully
          </Typography>
          <Typography variant="body3" color="#647593">
            Your administrator will contact you shortly.
          </Typography>
          <Box mt={2}>
            <Button size="medium" onClick={() => navigate(url(ROUTE_SIGN_IN))}>
              Login
            </Button>
          </Box>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button variant="text">Connect to your administrator</Button>
      </Box>
    </>
  );
};
