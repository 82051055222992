import { ComponentType, useState } from 'react';
import { Box, Button, Stack, IconButton, Tooltip } from '@mui/material';
import { FilterableTabs } from '../../components/tabs/FilterableTabs';
import { TableView } from '../../components/table-view';
import { clientsColumn } from './_mockdata';
import { SearchField, ServiceTypeField } from '../../components/fields';
import { PageHeader } from '../../components/page-header';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_CLIENT_DETAILS,
  ROUTE_CLIENT_PROFILE_EDIT,
  ROUTE_CLIENT_PROFILE_CREATE
} from '../../constants/route.paths';
import { IconPen, IconTrash } from '../../components/icons';
import get from 'lodash/get';
import { useListResource } from '../../core/hooks/useListResource';
import { ClientApi, ClientDto } from '../../core/http';
import { useDialog } from '../../core/hooks/useDialog';
import { TerminateUserDialog } from './dialogs/TerminateUserDialog';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { Card } from '../../components/cards';
import { PermissionView } from '../../components/permission-view';

const clientApi = new ClientApi();

const filters = [
  {
    key: 'all',
    label: 'All clients'
  },
  {
    key: 'active',
    label: 'Active'
  },
  {
    key: 'auth_expired',
    label: 'Authorization Expired'
  },
  {
    key: 'terminated',
    label: 'Terminated'
  }
];

export const ClientsList: ComponentType = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [openTerminateUserDialog] = useDialog(TerminateUserDialog);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState({});
  const {
    data,
    pagination,
    isLoading,
    fetchData,
    exportData,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort,
    newParamsLoading
  } = useListResource(clientApi);

  const handleDelete = (data: ClientDto) => () => {
    openTerminateUserDialog({
      uuid: data.uuid,
      userName: data.displayName,
      onSuccess: () => {
        showMessage(`Client ${data.displayName} terminated successfully`);
        fetchData();
      },
      onFail: () => {
        showMessage('Something went wrong, please try again later', 'error');
      }
    });
  };

  const handleEdit = (uuid: string) => () => {
    navigate(
      url(ROUTE_CLIENT_PROFILE_EDIT, {
        id: uuid
      })
    );
  };

  const handleFilterChange = (field) => (value) => {
    setFilter({
      ...filter,
      [field]: value
    });
  };

  const handleSearch = () => {
    Object.keys(filter).forEach((element) => {
      if (element && element.toString() !== 'search') {
        handleChangeParams(
          `filter[${element.toString()}][eq]`,
          filter[element]
        );
      } else {
        handleChangeParams(element.toString(), filter[element]);
      }
    });
  };

  return (
    <>
      <PageHeader title="Clients" divider={false}>
        <PermissionView permission="modifyClients">
          <Button onClick={() => navigate(url(ROUTE_CLIENT_PROFILE_CREATE))}>
            Add new
          </Button>
        </PermissionView>
        <Button color="secondary" onClick={exportData}>
          Export
        </Button>
      </PageHeader>
      <Box>
        <FilterableTabs
          onChange={(key) => {
            const value = get(filters, [key, 'key'], null);
            setSelectedTab(key);
            if (value === 'all') {
              handleChangeParams(
                'filter[authorizationsInfo.authorizedTo]',
                null
              );
              handleChangeParams('filter[status][eq]', null);
            } else if (value === 'active') {
              handleChangeParams(
                'filter[authorizationsInfo.authorizedTo]',
                null
              );
              handleChangeParams('filter[status][eq]', value);
            } else if (value === 'auth_expired') {
              handleChangeParams('filter[status][eq]', null);
              handleChangeParams(
                'filter[authorizationsInfo.authorizedTo]',
                '[]'
              );
            }
          }}
          items={filters}
        />
      </Box>
      <Card sx={{ mb: 3 }}>
        <Stack direction="row" spacing={1}>
          <SearchField
            sx={{ width: 400 }}
            size="small"
            loading={newParamsLoading}
            placeholder="Search by Client Name, ID, Phone, Email"
            onChange={handleFilterChange('search')}
          />
          <ServiceTypeField
            sx={{
              width: 274,
              height: 32,
              '& .MuiOutlinedInput-root': { height: 32 }
            }}
            placeholder="Type of Service"
            onChange={handleFilterChange('serviceType')}
            value={get(filter, 'serviceType', null)}
          />
          <Button onClick={() => handleSearch()}>Search</Button>
        </Stack>
      </Card>
      <Box>
        <TableView
          loading={isLoading}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={true}
          showPagination={true}
          pagination={pagination}
          keyExtractor={(item: { uuid: string }) => item.uuid}
          onRowClick={({ uuid }) =>
            navigate(
              url(ROUTE_CLIENT_DETAILS, {
                id: uuid,
                tab: 'dashboard'
              })
            )
          }
          dataSource={get(data, 'items', [])}
          showEmptyState
          EmptyStateProps={{
            title: 'No clients found',
            description: null,
            action: selectedTab === 0 && (
              <Button
                onClick={() => navigate(url(ROUTE_CLIENT_PROFILE_CREATE))}
              >
                Add new
              </Button>
            )
          }}
          columns={[
            ...clientsColumn,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, client: ClientDto) => (
                <Stack
                  direction="row"
                  alignItems="left"
                  justifyContent="flex-end"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <PermissionView permission="modifyClients">
                    <Tooltip title="Edit" placement="bottom-end">
                      <IconButton
                        size="small"
                        onClick={handleEdit(client.uuid)}
                      >
                        <IconPen />
                      </IconButton>
                    </Tooltip>
                  </PermissionView>
                  {client.status === 'active' && (
                    <PermissionView permission="modifyClients">
                      <Tooltip title="Terminate" placement="bottom-end">
                        <IconButton size="small" onClick={handleDelete(client)}>
                          <IconTrash />
                        </IconButton>
                      </Tooltip>
                    </PermissionView>
                  )}
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
