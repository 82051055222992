import { useState, useEffect, FC } from 'react';
import {
  OptionsAutocomplete,
  OptionsAutocompleteProps,
  Options
} from '../options-autocomplete';
import { useEmployee } from '../../../core/hooks/useEmployee';

type Props = {
  label?: string;
  disabled?: boolean;
} & Omit<OptionsAutocompleteProps, 'options'>;

export const EmployeeAutocompleteField: FC<Props> = (props) => {
  const [options, setOptions] = useState<Options[]>([]);

  const { items, itemsLoading } = useEmployee();

  useEffect(() => {
    setOptions(
      items.map((item) => ({ label: item.displayName, value: item.uuid }))
    );
  }, [items]);

  return (
    <OptionsAutocomplete
      {...{
        loading: itemsLoading,
        options,
        disabled: props.disabled || itemsLoading
      }}
      {...props}
    />
  );
};
