import { Typography, Grid } from '@mui/material';
import { Checkbox } from '../../components/fields';

export const PermissionsContainer = ({ items = [] }) => (
  <>
    <Grid container>
      <Grid item md={6}>
        <Typography variant="body1" color="#647593">
          Permission
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="body1" color="#647593">
          View
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Typography variant="body1" color="#647593">
          Modified
        </Typography>
      </Grid>
    </Grid>
    {items.map((item) => (
      <Grid container mt={2} key={item.name}>
        <Grid item md={6}>
          <Typography variant="body2">{item.name}</Typography>
        </Grid>
        <Grid item md={3}>
          <Checkbox />
        </Grid>
        <Grid item md={3}>
          <Checkbox />
        </Grid>
      </Grid>
    ))}
  </>
);
