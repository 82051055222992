import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'file-attach'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(0, 2),
      height: 80,
      borderRadius: 3,
      background: '#ECF4FF',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%'
    },
    emptyRoot: {
      height: 80,
      backgroundColor: '#fff',
      position: 'relative',
      border: '1px dashed #C7CBE5',
      borderRadius: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    error: {
      border: '1px dashed #EB5757'
    },
    errorText: {
      color: '#EB5757'
    },

    browseBtn: {},
    dragActive: {
      borderStyle: 'solid',
      backgroundColor: '#fdfdff'
    },
    file: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      opacity: 0,
      zIndex: 2,
      appearance: 'none',
      cursor: 'pointer'
    },
    label: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px'
    },
    fileName: {
      height: 24,
      overflow: 'hidden',
      paddingRight: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      maxWidth: 350
    },
    fileSize: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: '#647593'
    }
  }),
  options
);

export default useStyles;
