import { Expose } from 'class-transformer';

export class EmployeeGroupDto {
  @Expose()
  uuid: string;

  @Expose()
  groupName: string;

  @Expose()
  employeeCount: number;
}
