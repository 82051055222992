import React, { ComponentType, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  DatePicker,
  TimePicker,
  DurationField,
  CheckboxList,
  SelectField,
  RadioList,
  TextField
} from '../../../components/fields';
import { useFormik } from 'formik';
import * as yup from 'yup';
import get from 'lodash/get';
import { addDays, addMinutes, format, subDays } from 'date-fns';
import { parseDate } from '../../../core';

interface Props extends DialogProps {
  onClose?: () => void;
  onSubmit: (value: any) => void;
  values?: null;
  appointmentStartDate?: Date;
  appointmentDuration?: string;
}

const everyText = {
  daily: 'Day(s)',
  weekly: 'Week(s)',
  monthly: 'Month(s)'
};

export const AddRecurrenceDialog: ComponentType<Props> = ({
  popDialog,
  onSubmit,
  values: initialValues,
  appointmentStartDate,
  appointmentDuration
}) => {
  const {
    touched,
    values,
    errors,
    setValues,
    setFieldValue,
    handleSubmit,
    handleReset
  } = useFormik({
    initialValues: {
      recurrentPattern: 'daily',
      recurEvery: '',
      recurrentDays: [],
      startRange: null,
      endRange: null,
      occurrences: ''
    },
    validationSchema: yup.object().shape(
      {
        recurrentDays: yup.array().min(1, 'Please select at least one day'),
        startRange: yup
          .date()
          .nullable()
          .required('Start date is required')
          .when('endRange', {
            is: (endRange) => !!endRange,
            then: yup
              .date()
              .typeError('Please enter valid date')
              .required('Required field')
              .max(
                yup.ref('endRange'),
                'Start date must be earlier than End date'
              )
          }),
        endRange: yup
          .date()
          .nullable()
          .required('End date is required')
          .when('startRange', {
            is: (startRange) => !!startRange,
            then: yup
              .date()
              .typeError('Please enter valid date')
              .required('Required field')
              .min(
                yup.ref('startRange'),
                'End date must be later than Start date'
              )
          })
      },
      [['startRange', 'endRange']]
    ),
    onSubmit: (values) => {
      const startRange = get(values, 'startRange');
      const endRange = get(values, 'endRange');
      const startRangeDate = parseDate(startRange);
      const endRangeDate = parseDate(endRange);

      const recurrentDays = get(values, 'recurrentDays', []);
      const formattedStartDate = format(startRangeDate, 'MM/dd/yyyy');
      const formattedEndDate = format(endRangeDate, 'MM/dd/yyyy');

      onSubmit &&
        onSubmit({
          values: {
            startRange,
            endRange,
            startRangeDate,
            endRangeDate,
            ...values
          },
          display:
            recurrentDays.join(', ') +
            ` (${formattedStartDate} to ${formattedEndDate})`
        });
      popDialog();
    }
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const appointmentDateEnd = addMinutes(
    appointmentStartDate,
    parseInt(appointmentDuration)
  );

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 800 },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(9, 30, 66, 0.1)'
        }
      }}
    >
      <DialogTitle>
        Add recurrence
        <Typography color="#647593" variant="body3" component="div">
          Changes applied to All appointment in series
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <DatePicker
              label="Date*"
              formatValue={false}
              value={appointmentStartDate}
              disabled
            />
          </Grid>
          <Grid item md={3}>
            <TimePicker
              label="Start Time"
              value={appointmentStartDate}
              onChange={(date) => {
                console.log(date);
              }}
              disabled
            />
          </Grid>
          <Grid item md={3}>
            <DurationField
              label="Duration*"
              value={appointmentDuration}
              disabled
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label="End time"
              disabled
              value={
                appointmentDateEnd
                  ? format(appointmentDateEnd, 'hh:mm aaa')
                  : ''
              }
            />
          </Grid>
        </Grid>
        <Divider />
        <Box py={2}>
          <Typography variant="h4" mb={1}>
            Select pattern
          </Typography>

          <RadioList
            value={values.recurrentPattern}
            onChange={(value) => setFieldValue('recurrentPattern', value)}
            errors={errors.recurrentPattern}
            items={[
              {
                label: 'Daily',
                value: 'daily'
              },
              {
                label: 'Weekly',
                value: 'weekly'
              },
              {
                label: 'Monthly',
                value: 'monthly'
              }
            ]}
          />

          {values.recurrentPattern !== 'daily' && (
            <Stack direction="row" spacing={1} alignItems="center">
              <SelectField
                label="Recur every"
                items={[
                  {
                    label: '1',
                    value: '1'
                  },
                  {
                    label: '2',
                    value: '2'
                  },
                  {
                    label: '3',
                    value: '3'
                  },
                  {
                    label: '4',
                    value: '4'
                  },
                  {
                    label: '5',
                    value: '5'
                  }
                ]}
                value={values.recurEvery}
                onChange={(value) => setFieldValue('recurEvery', value)}
                errors={errors.recurEvery}
                fullWidth={false}
                sx={{
                  width: 91
                }}
              />
              <Typography variant="body3">
                {get(everyText, values.recurrentPattern)}
              </Typography>
            </Stack>
          )}

          <CheckboxList
            value={values.recurrentDays}
            onChange={(value) => setFieldValue('recurrentDays', value)}
            errors={errors.recurrentDays}
            helperText={touched.recurrentDays && errors.recurrentDays}
            items={[
              {
                label: 'Sun',
                value: 'SUN'
              },
              {
                label: 'Mon',
                value: 'MON'
              },
              {
                label: 'Tue',
                value: 'TUE'
              },
              {
                label: 'Wed',
                value: 'WED'
              },
              {
                label: 'Thu',
                value: 'THU'
              },
              {
                label: 'Fri',
                value: 'FRI'
              },
              {
                label: 'Sat',
                value: 'SAT'
              }
            ]}
          />
        </Box>

        <Divider />
        <Box py={2}>
          <Typography variant="h4" mb={1}>
            Range
          </Typography>
          <Grid container columnSpacing={1}>
            <Grid item md={4}>
              <DatePicker
                label="Start Date"
                maxDate={
                  values.endRange
                    ? subDays(parseDate(values.endRange), 1)
                    : null
                }
                value={values.startRange}
                onChange={(value) => setFieldValue('startRange', value)}
                errors={touched.startRange && errors.startRange}
              />
            </Grid>
            <Grid item md={4}>
              <DatePicker
                label="End Date"
                minDate={
                  values.startRange
                    ? addDays(parseDate(values.startRange), 1)
                    : null
                }
                value={values.endRange}
                onChange={(value) => setFieldValue('endRange', value)}
                errors={touched.endRange && errors.endRange}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                label="Occurrences"
                disabled
                value={values.occurrences}
                onChange={(value) => setFieldValue('occurrences', value)}
                errors={touched.occurrences && errors.occurrences}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="third" sx={{ marginRight: 'auto' }} onClick={popDialog}>
          Cancel
        </Button>
        <Button color="secondary" onClick={handleReset}>
          Clear all
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
