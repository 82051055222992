import { useDrop } from 'react-dnd';

interface Props {
  position?: {
    top?: string | number;
    left?: string | number;
  };
  size?: {
    width?: string | number;
    height?: string | number;
  };
  fifteenMinutesWidth: number;
  onHover?: () => void;
}

export const DropableArea = ({
  position,
  size,
  onHover,
  fifteenMinutesWidth
}: Props) => {
  const [_, drop] = useDrop(
    () => ({
      accept: 'timebox',
      hover: () => onHover()
    }),
    [fifteenMinutesWidth]
  );

  return (
    <div
      ref={drop}
      style={{
        position: 'absolute',
        zIndex: 10,
        top: 0,
        height: '100%',
        ...position,
        ...size
      }}
    />
  );
};
