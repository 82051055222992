import { Stack, Typography, Select, MenuItem, Box } from '@mui/material';
import useStyles from './styles';
import { FC } from 'react';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

interface Props {
  pagination?: {
    page: number;
    perPage: number;
    totalPages: number;
    total: number;
  };
  perPage: number;
  onChange: (perPage: number) => void;
  items: number[];
}

export const TablePerPage: FC<Props> = ({
  pagination,
  items,
  perPage,
  onChange
}) => {
  const classes = useStyles();

  const handleChange = (e: SelectChangeEvent) => {
    onChange(parseInt(e.target.value, 10));
  };

  const displayFooter = () => {
    if (!pagination) return null;
    const from = (pagination.page - 1) * pagination.perPage + 1;
    const total = pagination.total;
    const to =
      pagination.page * pagination.perPage > total
        ? total
        : pagination.page * pagination.perPage;

    return (
      <Typography className={classes.totalText}>
        Showing {from} to {to} of {total} entries
      </Typography>
    );
  };

  return (
    <Stack direction="row" alignItems="center">
      <Typography className={classes.showByText}>Show by</Typography>
      <Box sx={{ ml: 3, mr: 4 }}>
        <Select
          className={classes.select}
          value={String(perPage)}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {items.map((item) => (
            <MenuItem key={item} value={String(item)}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {displayFooter()}
    </Stack>
  );
};
