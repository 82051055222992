import React, { ComponentType, useState } from 'react';
import useStyles from './styles';
import {
  MenuItem,
  TextField,
  TextFieldProps,
  Box,
  Chip,
  SelectChangeEvent
} from '@mui/material';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import { IconArrowDown } from '../../icons';

interface ItemType {
  label: any;
  value: any;
}

export interface SelectFieldProps
  extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  onChange?: (value: any) => void;
  items: ItemType[];
  multiple?: boolean;
  placeholder?: string;
  helperText?: string | null | any;
  errors?: any;
  value?: any;
}

export const SelectField: ComponentType<SelectFieldProps> = ({
  items,
  multiple = false,
  onChange,
  label,
  value = null,
  placeholder,
  errors,
  ...props
}) => {
  const [values, setValues] = useState<any>(multiple ? [] : '');
  const classes = useStyles();
  const itemsKeyByValue = keyBy(items, 'value');

  const handleChange = (e: SelectChangeEvent) => {
    if (typeof e.target.value === 'undefined') return;
    setValues(e.target.value);
    onChange && onChange(e.target.value);
  };

  const handleDelete = (value: any) => () => {
    const filteredValues = values.filter((v: any) => v !== value);
    onChange && onChange(filteredValues);
  };

  return (
    <TextField
      select
      error={!!errors}
      helperText={errors || props.helperText}
      value={value || ''}
      {...{ label, ...props }}
      classes={{ root: classes.root }}
      SelectProps={{
        MenuProps: {
          sx: {
            '& .MuiPaper-root': {
              maxHeight: 150
            }
          }
        },
        IconComponent: IconArrowDown,
        multiple,
        displayEmpty: !!placeholder,
        onChange: handleChange,
        renderValue: multiple
          ? (selected: any): any => {
              if (selected.length === 0) {
                return <>{placeholder}</>;
              }
              return (
                <Box className={classes.chipContainer}>
                  {selected.map((value: any) => (
                    <Chip
                      key={value}
                      label={get(itemsKeyByValue, [value, 'label'], value)}
                      size="small"
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      // deleteIcon={<IconClose />}
                      onDelete={handleDelete(value)}
                    />
                  ))}
                </Box>
              );
            }
          : undefined
      }}
    >
      {!multiple && placeholder && (
        <MenuItem
          value=""
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected
          }}
        >
          {placeholder}
        </MenuItem>
      )}
      {items.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
