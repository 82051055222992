import { FC, useEffect, useState } from 'react';
import { Box, Button, Stack, IconButton } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { availabilityDataColumns } from '../_mockdata';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { IconPen, IconTrash } from '../../../components/icons';
import { AddAvailabilityDialog } from '../dialogs/AddAvailabilityDialog';
import { useParams } from 'react-router';
import { ClientAvailabilityApi } from '../../../core/http/requests/client-availability.api';
import { useListResource } from '../../../core/hooks/useListResource';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { get } from 'lodash';
import { ClientAvailabilityDto } from '../../../core/http/dto/client-availability.dto';
import { format } from 'date-fns';
import { PermissionView } from '../../../components/permission-view';

const clientAvailabilityApi = new ClientAvailabilityApi();

export const Availability: FC = () => {
  const { id } = useParams();
  const [openAvailabilityDialog] = useDialog(AddAvailabilityDialog);
  const [openConfirmDialog, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const [convertedData, setConvertedData] = useState([]);

  const {
    data,
    isLoading,
    pagination,
    fetchData,
    handleChangePage,
    handleChangePerPage,
    handleChangeSort,
    handleChangeParams,
    newParamsLoading
  } = useListResource(clientAvailabilityApi, {
    defaultParams: { clientUuid: id }
  });

  const handleCreateAvailability = () => {
    openAvailabilityDialog({ clientUuid: id, fetchData });
  };

  const handleDelete = (uuid: string) => () => {
    openConfirmDialog({
      title: 'Delete availability',
      message: 'Do you really want to delete this availability ?',
      onConfirm: () => {
        clientAvailabilityApi.delete({ clientUuid: id }, uuid).then(() => {
          showMessage('Availability was deleted successfully');
          closeConfirm();
          fetchData();
        });
      }
    });
  };

  const handleEdit = (uuid) => () => {
    openAvailabilityDialog({ uuid, fetchData, clientUuid: id });
  };

  useEffect(() => {
    data &&
      setConvertedData(
        get(data, 'items')?.map((item: ClientAvailabilityDto) => {
          return {
            ...item,
            scheduling: {
              weekday: item?.weekday,
              startTime: item?.startTime,
              endTime: item?.endTime,
              dateFrom: format(new Date(item?.dateFrom), 'dd/mm')
            }
          };
        })
      );
  }, [data]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyClients">
          <Button onClick={handleCreateAvailability}>Add new</Button>
        </PermissionView>
        <SearchField
          sx={{ width: 400 }}
          loading={newParamsLoading}
          placeholder="Search"
          onChange={(value) => {
            handleChangeParams('search', value || null);
          }}
        />
      </Stack>
      <Box>
        <TableView
          loading={isLoading}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={true}
          showPagination={true}
          pagination={pagination}
          showEmptyState
          EmptyStateProps={{
            title: 'You dont have any availabilities yet.',
            description: null,
            action: <Button onClick={handleCreateAvailability}>Add new</Button>
          }}
          keyExtractor={(item) => item.uuid}
          dataSource={convertedData}
          columns={[
            ...availabilityDataColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, { uuid }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <IconButton
                    component="span"
                    size="small"
                    onClick={handleEdit(uuid)}
                  >
                    <IconPen />
                  </IconButton>
                  <IconButton size="small" onClick={handleDelete(uuid)}>
                    <IconTrash />
                  </IconButton>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
