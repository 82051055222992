import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { useFormControl } from '@mui/material';
import clsx from 'clsx';

export const Label = styled(
  ({
    children,
    className
  }: {
    children?: React.ReactNode;
    className?: string;
  }) => {
    const formControlContext = useFormControl();
    const [dirty, setDirty] = React.useState(false);
    useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return <label className={className}>{children}</label>;
    }

    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;

    return (
      <label
        className={clsx(className, error || showRequiredError ? 'invalid' : '')}
      >
        {children}
        {required ? ' *' : ''}
      </label>
    );
  }
)`
  display: block;
  margin-bottom: 4px;
  color: #496492;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
`;
