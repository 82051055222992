import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

interface Props extends Omit<TextFieldProps, 'error' | 'variant' | 'errors'> {
  errors?: any;
  value?: any;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  required?: boolean;
  maxLength?: number;
}

export const TextField: FC<Props> = ({
  errors,
  value,
  onChange,
  maxLength,
  ...props
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length - 1 >= maxLength) return;
    onChange?.(value);
  };

  return (
    <MuiTextField
      error={!!errors}
      helperText={errors || props.helperText}
      onChange={handleChange}
      value={value}
      {...props}
    />
  );
};
