import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'appointment-calendar-card'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: '#fff',
      padding: theme.spacing(1, 1, 1, 1.25),
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
      borderLeft: '2px solid #C7CBE5',
      cursor: 'pointer',
      minHeight: 80,
      height: 'calc(100% - 4px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginBottom: 4,
      borderRadius: '0px 4px 4px 0px'
    },
    selected: {
      outline: '1px solid #0052CC'
    },
    minimal: {
      minHeight: 28,
      padding: theme.spacing(0, 1, 0, 1.25)
    },

    primary: {
      borderColor: '#CEF9FF'
    },
    secondary: {
      borderColor: '#EAF1FF'
    },
    warning: {
      borderColor: '#F2C94C'
    },
    error: {
      borderColor: '#f8a3a3'
    },
    info: {
      borderColor: '#C7CBE5'
    },
    success: {
      borderColor: '#6FCF97'
    }
  }),
  options
);

export default useStyles;
