import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { API_CASE_LOAD_TRACKER_REPORT } from '../api.routes';
import { CaseLoadTrackerReportDto } from '../dto/case-load-tracker-report.dto';

export class CaseLoadTrackerReportApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<CaseLoadTrackerReportDto>> {
    return this.httpClient
      .get(API_CASE_LOAD_TRACKER_REPORT, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(CaseLoadTrackerReportDto, data.items)
        };
      });
  }
}
