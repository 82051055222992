/* eslint-disable prefer-const */
import { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import {
  addHours,
  addMinutes,
  format,
  getHours,
  getMinutes,
  startOfDay
} from 'date-fns';
import { arrayFromLength, transformAvailability } from '../../core';
import { DISPLAY_HOURS } from './constants';
import { UserRecord } from '../user-record';
import { TimeSlot } from './TimeSlot';
import { indexOf, map } from 'lodash';
import { TimeBox } from './TimeBox';
import { AreaSelection } from './AreaSelection';
import { DropableArea } from './DropableArea';
import { SelectBox } from './SelectBox';

export const AvailabilityView = ({
  data,
  setPrimaryTherapist,
  setSecondaryTherapist,
  primaryTherapist,
  secondaryTherapist,
  loading
}: any) => {
  const availabilityViewRef = useRef(null);
  const [availabilityViewWidth, setAvailabilityViewWidth] = useState(1024);
  const availabilitiesData = transformAvailability(data);
  const [currentLeft, setCurrentLeft] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const fullWidthInMinutes = (DISPLAY_HOURS - 1) * 60;
  const fifteenMinutesWidth = availabilityViewWidth / 32;

  const minFromHour = 8;
  const minFromMinute = 0;

  const startTime = new Date();
  startTime.setHours(8);
  startTime.setMinutes(minFromMinute);

  useEffect(() => {
    handleResize();
  }, [availabilityViewRef]);

  const handleResize = () => {
    if (availabilityViewRef.current) {
      setAvailabilityViewWidth(
        availabilityViewRef.current.getBoundingClientRect().width
      );
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dragAreaSelection(currentIndex);
  }, [fifteenMinutesWidth]);

  const calculate = useCallback(
    (name?: string, startTime?: any, endTime?: any) => {
      const therapists = data.therapists.map(({ uuid }) => uuid);
      const startDate = new Date();
      const endDate = new Date();

      let [startHour, startMinute] = startTime.split(':');
      let [endHour, endMinute] = endTime.split(':');

      startDate.setHours(startHour);
      startDate.setMinutes(startMinute);
      endDate.setHours(endHour);
      endDate.setMinutes(endMinute);

      startHour = getHours(startDate);
      startMinute = getMinutes(startDate);

      endHour = getHours(endDate);
      endMinute = getMinutes(endDate);

      let startMinutes = startHour * 60 + startMinute;
      let endMinutes =
        endHour * 60 + endMinute < startMinutes
          ? endHour * 60 + endMinute + 1440
          : endHour * 60 + endMinute;

      const duration = endMinutes - startMinutes;
      const left =
        ((startMinutes - minFromHour * 60) / 15) * fifteenMinutesWidth;
      const top = (indexOf(therapists, name) + 1) * 43 + 5;
      const width = (duration * 100) / fullWidthInMinutes;

      return {
        top,
        left,
        width
      };
    },
    [fifteenMinutesWidth]
  );

  const dragAreaSelection = (index) => {
    const currentIndex =
      data.duration > 30 ? index - data.duration / 15 - 1 : index - 1;
    const leftPosition = fifteenMinutesWidth * currentIndex;
    setCurrentIndex(index);
    if (leftPosition >= 0) {
      setCurrentLeft(leftPosition);
    }
  };

  const onSelectPrimaryTherapist = (therapist: any) => {
    if (!therapist) {
      return;
    }
    if (secondaryTherapist && secondaryTherapist.uuid === therapist.uuid) {
      setSecondaryTherapist(null);
      setPrimaryTherapist(therapist);
    } else {
      setPrimaryTherapist(therapist);
    }
  };

  const onSelectSecondaryTherapist = (therapist: any) => {
    if (!therapist) {
      return;
    }
    if (primaryTherapist && primaryTherapist.uuid === therapist.uuid) {
      setPrimaryTherapist(null);
      setSecondaryTherapist(therapist);
    } else {
      setSecondaryTherapist(therapist);
    }
  };

  return (
    <Box>
      <Box
        style={{
          backgroundColor: '#F9FBFE',
          width: '100%',
          height: 40,
          display: 'flex'
        }}
      >
        <Box
          style={{
            borderRight: '1px solid #ECF0F7',
            height: 40,
            minWidth: 196
          }}
        />
        <Box
          style={{
            borderRight: '1px solid #ECF0F7',
            height: 40,
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Box
            style={{
              borderRight: '1px solid #ECF0F7',
              height: 40,
              display: 'flex',
              width: `${availabilityViewWidth + fullWidthInMinutes * 4}px`
            }}
          >
            {arrayFromLength(DISPLAY_HOURS).map((_, index) => {
              const currentDate = addHours(startTime, index);
              return (
                <Box
                  key={index}
                  width={`${availabilityViewWidth / (DISPLAY_HOURS - 1)}px`}
                  style={{
                    padding: '12px 8px',
                    borderLeft: index === 0 ? '1px solid #ECF0F7' : 'none'
                  }}
                >
                  <TimeSlot date={currentDate} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'row'
        }}
      >
        <Box
          style={{
            width: 196
          }}
        >
          <Box
            style={{
              padding: '10px 0',
              borderBottom: '1px solid #ECF0F7',
              borderRight: '1px solid #ECF0F7',
              width: 196
            }}
          >
            <Typography variant="h6">{data.clientName}</Typography>
          </Box>
          {data.therapists.map(({ name, avatar }) => {
            return (
              <Box
                key={name}
                style={{
                  width: 196,
                  padding: '9px 8px',
                  borderBottom: '1px solid #ECF0F7',
                  borderRight: '1px solid #ECF0F7'
                }}
              >
                <UserRecord user={{ name, avatar }} color="common.dark" />
              </Box>
            );
          })}
        </Box>
        <Box
          style={{
            width: '100%',
            maxWidth: 1024,
            position: 'relative',
            overflow: 'hidden'
          }}
          ref={availabilityViewRef}
        >
          {primaryTherapist && (
            <Box
              style={{
                position: 'relative',
                top: calculate(
                  primaryTherapist.uuid,
                  primaryTherapist.from,
                  primaryTherapist.to
                ).top,
                left:
                  calculate(
                    primaryTherapist.uuid,
                    primaryTherapist.from,
                    primaryTherapist.to
                  ).left + 'px',
                width: (+data.duration / 15) * fifteenMinutesWidth,
                zIndex: 11111111
              }}
            >
              <SelectBox
                onRemove={() => {
                  setSecondaryTherapist(null);
                  setPrimaryTherapist(null);
                }}
                type="primary"
                alwaysShown
                selected={true}
                fromTime={primaryTherapist.from}
                toTime={primaryTherapist.to}
                duration={+data.duration}
                customWidth="100%"
                therapistUuid={primaryTherapist.uuid}
                onSelectPrimary={(therapist) => {
                  onSelectPrimaryTherapist(therapist);
                }}
                onSelectSecondary={(therapist) => {
                  onSelectSecondaryTherapist(therapist);
                }}
              />
            </Box>
          )}

          {secondaryTherapist && (
            <Box
              style={{
                position: 'relative',
                top: calculate(
                  secondaryTherapist.uuid,
                  secondaryTherapist.from,
                  secondaryTherapist.to
                ).top,
                left:
                  calculate(
                    secondaryTherapist.uuid,
                    secondaryTherapist.from,
                    secondaryTherapist.to
                  ).left + 'px',
                width: (+data.duration / 15) * fifteenMinutesWidth,
                zIndex: 11111111
              }}
            >
              <SelectBox
                alwaysShown
                onRemove={() => {
                  setSecondaryTherapist(null);
                  setPrimaryTherapist(null);
                }}
                customWidth="100%"
                type="secondary"
                selected={true}
                fromTime={secondaryTherapist.from}
                toTime={secondaryTherapist.to}
                duration={+data.duration}
                therapistUuid={secondaryTherapist.uuid}
                onSelectPrimary={(therapist) => {
                  onSelectPrimaryTherapist(therapist);
                }}
                onSelectSecondary={(therapist) => {
                  onSelectSecondaryTherapist(therapist);
                }}
              />
            </Box>
          )}
          <AreaSelection
            data={availabilitiesData}
            setPrimaryTherapist={setPrimaryTherapist}
            setSecondaryTherapist={setSecondaryTherapist}
            primaryTherapist={primaryTherapist}
            secondaryTherapist={secondaryTherapist}
            therapists={data.therapists}
            availabilityViewWidth={availabilityViewWidth}
            currentLeft={currentLeft}
            startTime={minFromHour}
            duration={data.duration}
          />
          {arrayFromLength((fullWidthInMinutes - 15) / 15).map((_, index) => (
            <DropableArea
              key={index}
              onHover={() => dragAreaSelection(index)}
              fifteenMinutesWidth={fifteenMinutesWidth}
              size={{ width: fifteenMinutesWidth }}
              position={{ left: index * fifteenMinutesWidth + 'px' }}
            />
          ))}
          {map(Object.keys(availabilitiesData), (therapist) => {
            return [
              ...availabilitiesData[therapist].isBusy,
              ...availabilitiesData[therapist].isNotBusy
            ].map((item, index) => {
              const today = new Date();
              const from = addMinutes(startOfDay(today), item.from);
              const fromTime = format(from, 'HH:mm');
              const to = addMinutes(startOfDay(today), item.to);
              const toTime = format(to, 'HH:mm');
              const visibleTimeStart = format(from, 'hh:mm a');
              const visibleTimeEnd = format(to, 'hh:mm a');
              const currentTherapist = data.therapists.find(
                (item) => item.uuid === therapist
              );
              return (
                <TimeBox
                  from={visibleTimeStart}
                  isBusy={item.isBusy}
                  to={visibleTimeEnd}
                  name={currentTherapist.name}
                  key={index}
                  style={{
                    top: calculate(therapist, fromTime, toTime).top,
                    left: calculate(therapist, fromTime, toTime).left + 'px',
                    width: calculate(therapist, fromTime, toTime).width + '%'
                  }}
                />
              );
            });
          })}

          {arrayFromLength(data.therapists.length + 1).map((item: any) => {
            return (
              <Box
                key={item.name}
                style={{
                  height: 43,
                  width: '100%',
                  borderBottom: '1px solid #ECF0F6'
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
