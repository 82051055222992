import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as SvgSidebarToggle } from './assets/sidebar-toggle.svg';
import { ReactComponent as SvgFile } from './assets/file.svg';
import { ReactComponent as SvgMail } from './assets/mail.svg';
import { ReactComponent as SvgNotification } from './assets/notification.svg';
import { ReactComponent as SvgAnnouncements } from './assets/announcements.svg';
import { ReactComponent as SvgAppointments } from './assets/appointments.svg';
import { ReactComponent as SvgBilling } from './assets/billing.svg';
import { ReactComponent as SvgClients } from './assets/clients.svg';
import { ReactComponent as SvgDashboard } from './assets/dashboard.svg';
import { ReactComponent as SvgEmployees } from './assets/employees.svg';
import { ReactComponent as SvgMasterData } from './assets/master-data.svg';
import { ReactComponent as SvgNews } from './assets/news.svg';
import { ReactComponent as SvgReminder } from './assets/reminder.svg';
import { ReactComponent as SvgReport } from './assets/report.svg';
import { ReactComponent as SvgRoles } from './assets/roles.svg';
import { ReactComponent as SvgTimesheet } from './assets/timesheet.svg';
import { ReactComponent as SvgLogout } from './assets/logout.svg';
import { ReactComponent as SvgPen } from './assets/pen.svg';
import { ReactComponent as SvgTrash } from './assets/trash.svg';
import { ReactComponent as SvgSort } from './assets/sort.svg';
import { ReactComponent as SvgSearch } from './assets/search.svg';
import { ReactComponent as SvgCheckbox } from './assets/checkbox.svg';
import { ReactComponent as SvgCheckboxChecked } from './assets/checkbox-checked.svg';
import { ReactComponent as SvgCheckboxIndeterminate } from './assets/checkbox-indeterminate.svg';
import { ReactComponent as SvgTrashBig } from './assets/trash-big.svg';
import { ReactComponent as SvgArrowDown } from './assets/arrow-down.svg';
import { ReactComponent as SvgUpload } from './assets/upload.svg';
import { ReactComponent as SvgCalendar } from './assets/calendar.svg';
import { ReactComponent as SvgMdCalendar } from './assets/md-calendar.svg';
import { ReactComponent as SvgMdCalendarDots } from './assets/md-calendar-dots.svg';
import { ReactComponent as SvgMdCalendarX } from './assets/md-calendar-x.svg';
import { ReactComponent as SvgMdComments } from './assets/md-comments.svg';
import { ReactComponent as SvgMdGlobe } from './assets/md-globe.svg';
import { ReactComponent as SvgMdLocation } from './assets/md-location.svg';
import { ReactComponent as SvgMdOrganization } from './assets/md-organization.svg';
import { ReactComponent as SvgMdPeople } from './assets/md-people.svg';
import { ReactComponent as SvgBack } from './assets/back.svg';
import { ReactComponent as SvgInfo } from './assets/info.svg';
import { ReactComponent as SvgSearchAppointments } from './assets/search-appointments.svg';
import { ReactComponent as SvgClose } from './assets/close.svg';
import { ReactComponent as SvgAppointmentSuccess } from './assets/appointment-success.svg';
import { ReactComponent as SvgAppointmentWarning } from './assets/appointment-warning.svg';
import { ReactComponent as SvgAppointmentPending } from './assets/appointment-pending.svg';
import { ReactComponent as SvgLogo } from './assets/logo.svg';
import { ReactComponent as SvgLogoMin } from './assets/logo-min.svg';
import { ReactComponent as SvgEye } from './assets/eye.svg';
import { ReactComponent as SvgEyeClosed } from './assets/eye-closed.svg';
import { ReactComponent as SvgClock } from './assets/clock.svg';
import { ReactComponent as SvgHistory } from './assets/history.svg';
import { ReactComponent as SvgUnderConstruction } from './assets/under-construction.svg';
import { ReactComponent as SvgNotFound } from './assets/not-found.svg';
import { ReactComponent as SvgAppointmentCancel } from './assets/appointment-cancel.svg';
import { ReactComponent as SvgAppointmentConfirm } from './assets/appointment-confirm.svg';
import { ReactComponent as SvgAppointmentView } from './assets/appointment-view.svg';
import { ReactComponent as SvgAppointmentRefresh } from './assets/appointment-refresh.svg';
import { ReactComponent as SvgArrowRight16 } from './assets/arrow-right-16.svg';
import { ReactComponent as SvgArrowRight24 } from './assets/arrow-right-24.svg';
import { ReactComponent as SvgSubtract } from './assets/subtract.svg';
import { ReactComponent as SvgDrag } from './assets/drag.svg';
import { ReactComponent as SvgCaretNext } from './assets/caret-next.svg';
import { ReactComponent as SvgCaretBack } from './assets/caret-back.svg';

export const IconArrowRight16 = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowRight16} viewBox="0 0 16 16" {...props} />
);
export const IconArrowRight10 = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowRight24} viewBox="0 0 24 24" {...props} />
);
export const IconDrag = (props: SvgIconProps) => (
  <SvgIcon component={SvgDrag} viewBox="0 0 24 24" {...props} />
);

export const IconAppointmentCancel = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentCancel} viewBox="0 0 24 24" {...props} />
);

export const IconSubtract = (props: SvgIconProps) => (
  <SvgIcon component={SvgSubtract} viewBox="0 0 24 24" {...props} />
);

export const IconAppointmentConfirm = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentConfirm} viewBox="0 0 24 24" {...props} />
);

export const IconView = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentView} viewBox="0 0 24 24" {...props} />
);

export const IconAppointmentRefresh = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentRefresh} viewBox="0 0 24 24" {...props} />
);

export const IconNotFound = (props: SvgIconProps) => (
  <SvgIcon component={SvgNotFound} viewBox="0 0 208 220" {...props} />
);

export const IconHistory = (props: SvgIconProps) => (
  <SvgIcon component={SvgHistory} viewBox="0 0 24 24" {...props} />
);

export const IconUnderConstruction = (props: SvgIconProps) => (
  <SvgIcon component={SvgUnderConstruction} viewBox="0 0 256 256" {...props} />
);

export const IconClock = (props: SvgIconProps) => (
  <SvgIcon component={SvgClock} viewBox="0 0 24 24" {...props} />
);
export const IconLogo = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogo} viewBox="0 0 168 28" {...props} />
);
export const IconLogoMin = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogoMin} viewBox="0 0 28 28" {...props} />
);
export const IconEye = (props: SvgIconProps) => (
  <SvgIcon component={SvgEye} viewBox="0 0 16 16" {...props} />
);

export const IconEyeClosed = (props: SvgIconProps) => (
  <SvgIcon component={SvgEyeClosed} viewBox="0 0 16 16" {...props} />
);

export const IconSidebarToggle = (props: SvgIconProps) => (
  <SvgIcon component={SvgSidebarToggle} viewBox="0 0 24 24" {...props} />
);
export const IconSearchAppointments = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearchAppointments} viewBox="0 0 164 164" {...props} />
);
export const IconInfo = (props: SvgIconProps) => (
  <SvgIcon component={SvgInfo} viewBox="0 0 16 16" {...props} />
);

export const IconFile = (props: SvgIconProps) => (
  <SvgIcon component={SvgFile} viewBox="0 0 24 24" {...props} />
);

export const IconBack = (props: SvgIconProps) => (
  <SvgIcon component={SvgBack} viewBox="0 0 24 24" {...props} />
);

export const IconMail = (props: SvgIconProps) => (
  <SvgIcon component={SvgMail} viewBox="0 0 24 24" {...props} />
);

export const IconNotification = (props: SvgIconProps) => (
  <SvgIcon component={SvgNotification} viewBox="0 0 24 24" {...props} />
);

export const IconAnnouncements = (props: SvgIconProps) => (
  <SvgIcon component={SvgAnnouncements} viewBox="0 0 24 24" {...props} />
);

export const IconAppointments = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointments} viewBox="0 0 24 24" {...props} />
);

export const IconBilling = (props: SvgIconProps) => (
  <SvgIcon component={SvgBilling} viewBox="0 0 24 24" {...props} />
);

export const IconClients = (props: SvgIconProps) => (
  <SvgIcon component={SvgClients} viewBox="0 0 24 24" {...props} />
);

export const IconDashboard = (props: SvgIconProps) => (
  <SvgIcon component={SvgDashboard} viewBox="0 0 24 24" {...props} />
);

export const IconEmployees = (props: SvgIconProps) => (
  <SvgIcon component={SvgEmployees} viewBox="0 0 24 24" {...props} />
);

export const IconMasterData = (props: SvgIconProps) => (
  <SvgIcon component={SvgMasterData} viewBox="0 0 24 24" {...props} />
);

export const IconNews = (props: SvgIconProps) => (
  <SvgIcon component={SvgNews} viewBox="0 0 24 24" {...props} />
);

export const IconReminder = (props: SvgIconProps) => (
  <SvgIcon component={SvgReminder} viewBox="0 0 24 24" {...props} />
);

export const IconReport = (props: SvgIconProps) => (
  <SvgIcon component={SvgReport} viewBox="0 0 24 24" {...props} />
);

export const IconRoles = (props: SvgIconProps) => (
  <SvgIcon component={SvgRoles} viewBox="0 0 24 24" {...props} />
);

export const IconTimesheet = (props: SvgIconProps) => (
  <SvgIcon component={SvgTimesheet} viewBox="0 0 24 24" {...props} />
);

export const IconLogout = (props: SvgIconProps) => (
  <SvgIcon component={SvgLogout} viewBox="0 0 24 24" {...props} />
);

export const IconPen = (props: SvgIconProps) => (
  <SvgIcon component={SvgPen} viewBox="0 0 24 24" {...props} />
);

export const IconTrash = (props: SvgIconProps) => (
  <SvgIcon component={SvgTrash} viewBox="0 0 24 24" {...props} />
);

export const IconSort = (props: SvgIconProps) => (
  <SvgIcon component={SvgSort} viewBox="0 0 10 16" {...props} />
);

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearch} viewBox="0 0 24 24" {...props} />
);

export const IconCheckbox = (props: SvgIconProps) => (
  <SvgIcon component={SvgCheckbox} viewBox="0 0 24 24" {...props} />
);

export const IconCheckboxChecked = (props: SvgIconProps) => (
  <SvgIcon component={SvgCheckboxChecked} viewBox="0 0 24 24" {...props} />
);

export const IconCheckboxIndeterminate = (props: SvgIconProps) => (
  <SvgIcon
    component={SvgCheckboxIndeterminate}
    viewBox="0 0 24 24"
    {...props}
  />
);

export const IconTrashBig = (props: SvgIconProps) => (
  <SvgIcon component={SvgTrashBig} viewBox="0 0 18 20" {...props} />
);

export const IconArrowDown = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowDown} viewBox="0 0 24 24" {...props} />
);

export const IconUpload = (props: SvgIconProps) => (
  <SvgIcon component={SvgUpload} viewBox="0 0 24 24" {...props} />
);
export const IconCalendar = (props: SvgIconProps) => (
  <SvgIcon component={SvgCalendar} viewBox="0 0 24 24" {...props} />
);

export const IconMdCalendar = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdCalendar} viewBox="0 0 16 16" {...props} />
);

export const IconMdCalendarDots = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdCalendarDots} viewBox="0 0 16 16" {...props} />
);

export const IconMdCalendarX = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdCalendarX} viewBox="0 0 16 16" {...props} />
);

export const IconMdComments = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdComments} viewBox="0 0 16 16" {...props} />
);

export const IconMdGlobe = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdGlobe} viewBox="0 0 16 16" {...props} />
);
export const IconMdLocation = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdLocation} viewBox="0 0 16 16" {...props} />
);
export const IconMdOrganization = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdOrganization} viewBox="0 0 16 16" {...props} />
);
export const IconMdPeople = (props: SvgIconProps) => (
  <SvgIcon component={SvgMdPeople} viewBox="0 0 16 16" {...props} />
);

export const IconAppointmentSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentSuccess} viewBox="0 0 24 24" {...props} />
);

export const IconAppointmentWarning = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentWarning} viewBox="0 0 24 24" {...props} />
);

export const IconAppointmentPending = (props: SvgIconProps) => (
  <SvgIcon component={SvgAppointmentPending} viewBox="0 0 24 24" {...props} />
);

export const IconClose = (props: SvgIconProps) => (
  <SvgIcon component={SvgClose} viewBox="0 0 24 24" {...props} />
);

export const IconCaretNext = (props: SvgIconProps) => (
  <SvgIcon component={SvgCaretNext} viewBox="0 0 24 24" {...props} />
);

export const IconCaretBack = (props: SvgIconProps) => (
  <SvgIcon component={SvgCaretBack} viewBox="0 0 24 24" {...props} />
);
