import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'sidebar'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: 'none'
    },
    simpleBar: {
      height: '100%',

      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%'
      }
    },
    drawer: {
      height: '100%'
    },
    toggleBtn: {
      cursor: 'pointer',
      color: '#fff',

      '& .MuiSvgIcon-root:hover Path': {
        fill: '#B1D1FF'
      }
    },
    drawerPaper: {
      borderRadius: 0,
      backgroundColor: '#0052CC',
      color: '#fff',
      border: 'none'
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1.75, 1.5, 3.25, 1.5)
    },
    toolbarShift: { padding: theme.spacing(1.75, 0, 3.25, 1.5) },

    miniToggleButton: {
      padding: 0
    },
    logo: {
      display: 'block'
    },
    nav: {
      color: '#E0ECFF',
      padding: theme.spacing(0, 1.5)
    },
    navTitle: {
      fontSize: 14,
      lineHeight: '16px',
      padding: theme.spacing(1, 0.75),
      textTransform: 'uppercase',
      marginBottom: 8
    },
    navItem: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '&$navItemSelected, &$navItemSelected:hover, &:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 3
      }
    },
    navItemSelected: {},
    navItemIcon: {
      display: 'flex',
      marginRight: theme.spacing(2)
    },
    navItemLabel: {
      fontSize: 14,
      margin: 0
    }
  }),
  options
);

export default useStyles;
