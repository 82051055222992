import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Stack
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { PasswordField } from '../../../components/fields';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserApi } from '../../../core/http';
import { useSnackbar } from '../../../core/hooks/useSnackbar';

interface Props extends DialogProps {
  onClose?: () => void;
}
const userApi = new UserApi();

export const ChangePasswordDialog: ComponentType<Props> = ({ popDialog }) => {
  const { showMessage } = useSnackbar();
  const { handleChange, handleSubmit, values, touched, errors } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      currentPassword: yup
        .string()
        .required('This field is required.')
        .min(8, 'Password must be at least 8 characters long'),
      newPassword: yup
        .string()
        .required('This field is required.')
        .min(8, 'Password must be at least 8 characters long'),
      confirmPassword: yup
        .string()
        .min(8, 'Password must be at least 8 characters long')
        .required('This field is required.')
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    }),
    onSubmit: () => {
      userApi
        .changePassword({
          password: values.newPassword
        })
        .then(() => {
          popDialog();
          showMessage('Password has beed changed successfully.');
        });
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 400
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        }
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <PasswordField
            label="Current password"
            fullWidth
            value={values.currentPassword}
            onChange={handleChange('currentPassword')}
            helperText={touched.currentPassword && errors.currentPassword}
          />
          <PasswordField
            value={values.newPassword}
            label="Create Password"
            onChange={handleChange('newPassword')}
            fullWidth
            FormHelperTextProps={{
              style: {
                color: '#647593',
                fontSize: 12,
                fontWeight: 400,
                marginTop: 6
              }
            }}
            helperText={
              'Password must contain at least 8 characters, including at least one number, one lowercase and one uppercase'
            }
          />
          <PasswordField
            label="Confirm Password"
            fullWidth
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            helperText={
              touched.currentPassword &&
              (errors.newPassword || errors.confirmPassword)
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
