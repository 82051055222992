import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useStyles } from './styles';
import twoFactor from '../../assets/two-factor.svg';
import { ROUTE_TWO_FACTOR } from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';

export const TwoFactorSetup: ComponentType = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Box className={classes.twoFactorSetupRoot}>
        <Box className={classes.block}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item>
              <img src={twoFactor} />
            </Grid>
            <Grid item>
              <Typography variant="h2" mt={3}>
                Setup 2-Factor Authorization
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2">
                SplashWorks strongly recommend to set up 2-factor authorization
                to secure your account
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                onClick={() => navigate(url(`${ROUTE_TWO_FACTOR}?type=set`))}
              >
                Setup 2FA
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
