import { useEffect, useRef } from 'react';

interface UseOutsideClickedProps {
  onClickedOutside: (event: MouseEvent) => void;
}

export const useOutsideClicked = ({
  onClickedOutside
}: UseOutsideClickedProps) => {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickedOutside(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return ref;
};
