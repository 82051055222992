import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'select-field'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& .MuiInputBase-input': {
        minHeight: 24,
        lineHeight: '24px',
        '&::placeholder': {
          color: 'red !important'
        }
      },

      '& .MuiInputBase-sizeSmall .MuiInputBase-input': {
        paddingTop: 4,
        paddingBottom: 4
      },

      '& .MuiChip-root': {
        height: 24
      }
    },
    chipContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      gap: 4,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: 24,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    chipContainerWrappable: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 4,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    menuItem: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      color: theme.palette.common.black,
      padding: theme.spacing(0.5, 0.5),
      '&:hover': {
        borderRadius: 2,
        color: '#0052CC',
        backgroundColor: '#F9FBFE'
      }
    },
    menuItemSelected: {
      borderRadius: 2,
      backgroundColor: '#F9FBFE !important'
    },
    icon: {
      width: 24,
      height: 24,
      paddingLeft: 8,
      marginRight: 8,
      border: 0,
      borderLeftWidth: 1,
      borderColor: '#E3EAF3',
      borderStyle: 'solid'
    }
  }),
  options
);

export default useStyles;
