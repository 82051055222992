import { plainToClass } from 'class-transformer';
import {
  API_EMPLOYEE_FIND_ALL,
  API_EMPLOYEE_FIND_ONE,
  API_EMPLOYEE_CREATE,
  API_EMPLOYEE_DELETE,
  API_EMPLOYEE_UPDATE,
  API_EMPLOYEE_FIND_EXPIRED
} from '../api.routes';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { EmployeeDto } from '../dto';
import qs from 'qs';

export class EmployeeApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeDto>> {
    return this.httpClient
      .get(API_EMPLOYEE_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<any> {
    return this.httpClient
      .get(API_EMPLOYEE_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(EmployeeDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<EmployeeDto> {
    return this.httpClient.post(API_EMPLOYEE_CREATE, data).then(({ data }) => {
      return plainToClass(EmployeeDto, data);
    });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<EmployeeDto> {
    return this.httpClient.put(API_EMPLOYEE_UPDATE, data).then(({ data }) => {
      return plainToClass(EmployeeDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_EMPLOYEE_DELETE, {
      pathParams: { uuid }
    });
  }

  /**
   * Find Expired
   * @param uuid
   */
  public findExpired(): Promise<FindAllResponse<EmployeeDto>> {
    return this.httpClient.get(API_EMPLOYEE_FIND_EXPIRED).then(({ data }) => {
      return {
        ...data,
        items: plainToClass(EmployeeDto, data.items)
      };
    });
  }

  /**
   * Export
   * @param data
   */
  public export(params): void {
    window.open(
      `${String(
        process.env.REACT_APP_API_URL
      ).trim()}/employee/employee/export${qs.stringify(params, {
        addQueryPrefix: true
      })}`
    );
  }
}
