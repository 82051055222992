import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import { availabilityData } from '../../../features/clients/_mockdata';

export const getAvailability = createAsyncThunk(
  'getAvailabilityAction',
  async () => {
    try {
      const data: any = await delay(500, availabilityData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const clientAvailabilitySlice = createSlice({
  name: 'clientAvailabilitySlice',
  initialState: {
    getAvailability: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvailability.pending, (state) => {
      state.getAvailability.loading = true;
      state.getAvailability.data = null;
      state.getAvailability.error = null;
    });
    builder.addCase(getAvailability.fulfilled, (state, { payload }) => {
      state.getAvailability.loading = false;
      state.getAvailability.data = payload;
      state.getAvailability.error = null;
    });
    builder.addCase(getAvailability.rejected, (state) => {
      state.getAvailability.loading = false;
      state.getAvailability.data = null;
      state.getAvailability.error = 'Something went wrong';
    });
  }
});

export default clientAvailabilitySlice.reducer;
