import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import {
  Checkbox,
  NumberField,
  SelectField,
  TextField,
  PhoneField,
  StateAutocompleteField
} from '../../components/fields';
import { get } from 'lodash';
import { memo } from 'react';
import { CityAutocompleteField } from '../../components/fields';

export const ClientContactForm = memo(
  ({
    required,
    isLoading,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
    disabledAll
  }: any) => {
    const getFieldValue = (fieldName: string) => {
      return get(values, fieldName);
    };
    const getFieldTouchedError = (fieldName: string) => {
      return get(touched, fieldName) && get(errors, fieldName);
    };

    const getFieldProps = (fieldName: string) => {
      return {
        disabled: isLoading,
        errors: getFieldTouchedError(fieldName),
        value: getFieldValue(fieldName),
        onChange: (value) => {
          setFieldTouched(fieldName, true);
          setFieldValue(fieldName, value);
        },
        onBlur: () => {
          handleBlur(fieldName);
        }
      };
    };

    return (
      <Box>
        <Grid container spacing={2} mb={1}>
          <Grid item md={3} xs={12}>
            <SelectField
              required={required}
              disabled={isLoading || disabledAll}
              placeholder="Select"
              label="Relation to Child"
              items={[
                {
                  label: 'Parent',
                  value: 'parent'
                },
                {
                  label: 'Foster parent',
                  value: 'foster_parent'
                },
                {
                  label: 'Grand parent',
                  value: 'grand_parent'
                },
                {
                  label: 'Aunt',
                  value: 'aunt'
                },
                {
                  label: 'Uncle',
                  value: 'uncle'
                },
                {
                  label: 'Guardian',
                  value: 'guardian'
                },
                {
                  label: 'Family friend',
                  value: 'family_friend'
                },
                {
                  label: 'Other',
                  value: 'other'
                }
              ]}
              {...getFieldProps('relationToChild')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required={required}
              disabled={isLoading || disabledAll}
              label="First Name"
              {...getFieldProps('firstName')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required={required}
              disabled={isLoading || disabledAll}
              label="Last Name"
              {...getFieldProps('lastName')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required={required}
              disabled={isLoading || disabledAll}
              label="Email"
              {...getFieldProps('email')}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={isLoading || disabledAll}
              label="Address Line 1"
              required={required}
              {...getFieldProps('addressLine1')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={isLoading || disabledAll}
              label="Address Line 2"
              {...getFieldProps('addressLine2')}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item md={3} xs={12}>
            <CityAutocompleteField
              required
              label="City"
              setFieldValue={setFieldValue}
              {...getFieldProps('cityUuid')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <StateAutocompleteField
              required
              label="State"
              setFieldValue={setFieldValue}
              {...getFieldProps('stateUuid')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <NumberField
              required={required}
              maxLength={5}
              label="Zip Code"
              disabled={isLoading || disabledAll}
              {...getFieldProps('zipCode')}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item md={3} xs={12}>
            <PhoneField
              label="Primary Phone Number"
              required={required}
              disabled={isLoading || disabledAll}
              {...getFieldProps('primaryPhoneNumber')}
              value={getFieldValue('primaryPhoneNumber')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <PhoneField
              label="Secondary Phone Number"
              disabled={isLoading || disabledAll}
              {...getFieldProps('secondaryPhoneNumber')}
              value={getFieldValue('secondaryPhoneNumber')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <PhoneField
              label="Work Phone Number"
              required={required}
              disabled={isLoading || disabledAll}
              {...getFieldProps('workPhoneNumber')}
              value={getFieldValue('workPhoneNumber')}
            />
          </Grid>
        </Grid>
        <Divider />
        {values.type === 'primary' && (
          <Grid container mt={3}>
            <Grid item>
              <Typography color="common.grey" variant="subtitle1" mb={2}>
                Notifications
              </Typography>
              <Stack spacing={2} direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="body3">
                      Enable Text Notifications
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={getFieldValue('isTextNotificationsEnabled')}
                      onChange={(e, checked) =>
                        setFieldValue('isTextNotificationsEnabled', checked)
                      }
                    />
                  }
                />
                <FormControlLabel
                  label={
                    <Typography variant="body3">
                      Enable Email Notifications
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={getFieldValue('isEmailNotificationsEnabled')}
                      onChange={(e, checked) =>
                        setFieldValue('isEmailNotificationsEnabled', checked)
                      }
                    />
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
);

ClientContactForm.displayName = 'ClientContactForm';
