import { makeStyles } from '@mui/styles';
import background from '../../assets/background.png';

const options = {
  name: 'availability-view-header'
};
const useStyles = makeStyles(
  () => ({
    root: {},
    table: {},
    therapistSelectStack: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    selectBoxOpened: {
      backgroundColor: '#172B4D !important;',
      cursor: 'pointer',
      zIndex: 99999999999
    },
    selectBox: {
      zIndex: 99999999999,
      width: '100%',
      height: 43,
      '&:hover': {
        backgroundColor: 'rgba(0, 82, 204, 0.7) !important;',
        '& $selectTherapist': {
          display: 'block'
        },
        '& $timeBoxTime': {
          display: 'none'
        },
        '& $rootStack': {
          margin: '0 auto'
        }
      }
    },
    timeBox: {
      backgroundColor: '#A7E0F2 !important;',
      zIndex: 11
    },
    timeBoxBusy: {
      cursor: 'pointer',
      zIndex: 11,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.2,
        backgroundColor: '#A7E0F2',
        backgroundImage: `url(${background})`
      }
    },
    rootStack: {
      margin: 'unset'
    },
    timeBoxTime: {
      display: 'block'
    },
    selectTherapist: {
      display: 'none',
      color: '#fff',
      textAlign: 'center'
    },
    tableHeader: {
      backgroundColor: '#F9FBFE',
      height: 40,
      '& td:first-child': {
        width: 196
        // borderRight: '1px solid #ECF0F7'
      }
    },
    hourCell: {}
  }),
  options
);

export default useStyles;
