import { ProfileEdit } from '../../features/profile/ProfileEdit';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const ProfileEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Profile'
  })
)(ProfileEdit);
