import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { DURATIONS } from '../../../constants/data.constants';

export const DurationField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const selectItems = map(DURATIONS, (value, key) => ({
    value: key,
    label: value
  }));

  return (
    <SelectField
      label="Duration"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
