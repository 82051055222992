import { ComponentType, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { get, isEmpty } from 'lodash';
import { Box, Button, Stack, Tooltip, IconButton } from '@mui/material';
import { useDialog } from '../../../core/hooks/useDialog';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientServiceAuthorizationApi } from '../../../core/http';
import { url } from '../../../core';
import {
  ROUTE_CLIENT_SERVICE_AUTHORIZATION_CREATE,
  ROUTE_CLIENT_SERVICE_AUTHORIZATION_EDIT
} from '../../../constants/route.paths';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { TableView } from '../../../components/table-view';
import { SearchField } from '../../../components/fields';
import { IconPen, IconTrash } from '../../../components/icons';
import { clientAuthorizationsColumns } from '../_mockdata';
import { PermissionView } from '../../../components/permission-view';

const clientServiceAuthorizationApi = new ClientServiceAuthorizationApi();

export const ServiceAuthorization: ComponentType = () => {
  const { id } = useParams();
  const [openConfirmDialog, closeConfirmDialog] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const [data, setData] = useState<any>();
  const navigate = useNavigate();

  const {
    data: serviceAuthorizationData,
    isLoading,
    fetchData,
    pagination,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(clientServiceAuthorizationApi, {
    defaultParams: { clientUuid: id }
  });

  const handleDelete = (uuid) => () => {
    openConfirmDialog({
      title: 'Delete Service Authorization',
      message: 'Do you really want to delete this record ? ',
      onConfirm: () => {
        clientServiceAuthorizationApi
          .delete({ uuid, clientUuid: id })
          .then(() => {
            closeConfirmDialog();
            showMessage('Service Authorization was successfully deleted');
            fetchData();
          })
          .catch(() => {
            closeConfirmDialog();
            showMessage('Something went wron, please try again later');
          });
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(serviceAuthorizationData)) {
      const authorizationsData = get(serviceAuthorizationData, 'items', []);
      const authorizations = [];
      authorizationsData.forEach((item) => {
        const fundingSource = get(item, 'fundingSource', []);
        item.authorizations.forEach((authorization) => {
          authorizations.push({
            createdAt: item.createdAt,
            uuid: item.uuid,
            authorization,
            fundingSource
          });
        });
      });
      setData(authorizations);
    }
  }, [serviceAuthorizationData]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyServiceAuthorizations">
          <Button
            onClick={() =>
              navigate(
                url(ROUTE_CLIENT_SERVICE_AUTHORIZATION_CREATE, {
                  clientUuid: id
                })
              )
            }
          >
            Add new
          </Button>
        </PermissionView>
        <SearchField
          sx={{ width: 400 }}
          size="small"
          placeholder="Search"
          onChange={(value) => handleChangeParams('search', value)}
        />
      </Stack>
      <Box>
        <TableView
          showEmptyState
          pagination={pagination}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          loading={isLoading}
          showTablePerPage={true}
          showPagination={true}
          dataSource={data}
          keyExtractor={(item) => item.uuid}
          columns={[
            ...clientAuthorizationsColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              cellProps: {
                width: 50
              },
              render: (_, { uuid }) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <PermissionView permission="modifyServiceAuthorizations">
                    <Tooltip title="Edit" placement="bottom-end">
                      <IconButton
                        size="small"
                        onClick={() =>
                          navigate(
                            url(ROUTE_CLIENT_SERVICE_AUTHORIZATION_EDIT, {
                              clientUuid: id,
                              uuid: uuid
                            })
                          )
                        }
                      >
                        <IconPen />
                      </IconButton>
                    </Tooltip>
                  </PermissionView>
                  <PermissionView permission="modifyServiceAuthorizations">
                    <Tooltip title="Delete" placement="bottom-end">
                      <IconButton size="small" onClick={handleDelete(uuid)}>
                        <IconTrash />
                      </IconButton>
                    </Tooltip>
                  </PermissionView>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
