import BoldIcon from './icons/bold@2x.png';
import ItalicIcon from './icons/italic@2x.png';
import UnderlineIcon from './icons/underline@2x.png';
import StrikeThroughIcon from './icons/strikeThrough@2x.png';
import UnorderedIcon from './icons/unordered@2x.png';
import OrderedIcon from './icons/ordered@2x.png';
import IndentIcon from './icons/indent@2x.png';
import OutdentIcon from './icons/outdent@2x.png';
import UndoIcon from './icons/undo@2x.png';
import RedoIcon from './icons/redo@2x.png';
import LinkIcon from './icons/insertLink@2x.png';
import SmileIcon from './icons/smile@2x.png';
import InsertImageIcon from './icons/insertImage@2x.png';
import TextColorIcon from './icons/textColor@2x.png';

export const TOOLBAR_CONFIG = {
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { icon: BoldIcon },
    italic: { icon: ItalicIcon },
    underline: { icon: UnderlineIcon },
    strikethrough: { icon: StrikeThroughIcon }
  },
  colorPicker: {
    icon: TextColorIcon,
    className: 'demo-option-custom',
    popupClassName: 'demo-popup-custom'
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { icon: UnorderedIcon },
    ordered: { icon: OrderedIcon },
    indent: { icon: IndentIcon },
    outdent: { icon: OutdentIcon }
  },
  link: {
    popupClassName: 'demo-popup-custom',
    link: { icon: LinkIcon },
    unlink: { icon: LinkIcon }
  },
  emoji: {
    className: 'sw-toolbar-btn',
    popupClassName: 'demo-popup-custom',
    icon: SmileIcon
  },
  embedded: {
    className: 'sw-toolbar-btn',
    popupClassName: 'demo-popup-custom'
  },
  image: {
    icon: InsertImageIcon,
    className: 'sw-toolbar-btn',
    popupClassName: 'demo-popup-custom'
  },
  history: {
    undo: { icon: UndoIcon },
    redo: { icon: RedoIcon }
  },

  options: [
    'inline',
    'emoji',
    'colorPicker',
    'list',
    'image',
    'link',
    // 'embedded',
    'history'
  ]
};
