import { ComponentType, useState, useMemo } from 'react';
import SimpleBar from 'simplebar-react';
import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  Toolbar,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { map } from 'lodash';
import { NavLink, useLocation, Link, useNavigate } from 'react-router-dom';
import { url } from '../../core/utils/route.utils';
import { protectedRoutes } from '../../routes';
import { startsWith } from 'lodash';
import {
  ROUTE_HOME,
  ROUTE_DASHBOARDS,
  ROUTE_FILES,
  ROUTE_CLIENTS,
  ROUTE_EMPLOYEES,
  ROUTE_APPOINTMENTS,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_REPORTS,
  ROUTE_TIMESHEET,
  ROUTE_BILLING,
  ROUTE_REMINDERS,
  ROUTE_NEWS,
  ROUTE_MASTER_DATA,
  ROUTE_ROLES,
  ROUTE_SIGN_IN,
  ROUTE_SUPER_ADMIN_COMPANIES
} from '../../constants/route.paths';
import useStyles from './styles';
import {
  IconAnnouncements,
  IconAppointments,
  IconBilling,
  IconClients,
  IconDashboard,
  IconEmployees,
  IconFile,
  IconLogo,
  IconLogoMin,
  IconLogout,
  IconMasterData,
  IconNews,
  IconReminder,
  IconReport,
  IconRoles,
  IconSidebarToggle,
  IconTimesheet
} from '../../components/icons';
import clsx from 'clsx';
import { useUser } from '../../core/hooks/useUser';
import { DEV_MODE } from '../../constants/app.constants';
import { useDispatch } from 'react-redux';
import { logout } from '../../core/store/slices/appSlice';

const drawerWidth = 240;

const NAV_ITEMS = [
  { label: 'Dashboards', path: ROUTE_DASHBOARDS, icon: <IconDashboard /> },
  { label: 'Clients', path: ROUTE_CLIENTS, icon: <IconClients /> },
  { label: 'Employees', path: ROUTE_EMPLOYEES, icon: <IconEmployees /> },
  {
    label: 'Appointments',
    path: ROUTE_APPOINTMENTS,
    icon: <IconAppointments />
  },
  {
    label: 'Announcements',
    path: ROUTE_ANNOUNCEMENTS,
    icon: <IconAnnouncements />
  },
  { label: 'Files', path: ROUTE_FILES, icon: <IconFile /> },
  { label: 'Reports', path: ROUTE_REPORTS, icon: <IconReport /> },
  { label: 'Timesheets', path: ROUTE_TIMESHEET, icon: <IconTimesheet /> },
  { label: 'Billing', path: ROUTE_BILLING, icon: <IconBilling /> },
  { label: 'Reminders', path: ROUTE_REMINDERS, icon: <IconReminder /> },
  { label: 'News', path: ROUTE_NEWS, icon: <IconNews /> }
];

const ADMIN_ITEMS = [
  { label: 'Master Data', path: ROUTE_MASTER_DATA, icon: <IconMasterData /> },
  { label: 'Roles', path: ROUTE_ROLES, icon: <IconRoles /> },
  {
    label: 'Super Admin',
    path: ROUTE_SUPER_ADMIN_COMPANIES,
    icon: <IconMasterData />
  }
];

export const Sidebar: ComponentType = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();

  const NAV_ITEMS_ENHANCED = useMemo(
    () =>
      NAV_ITEMS.map((navItem) => ({
        ...navItem,
        permission:
          !DEV_MODE &&
          protectedRoutes.find((route) => route.path === navItem.path)
            .permission
      })),
    []
  );

  const ADMIN_ITEMS_ENHANCED = useMemo(
    () =>
      ADMIN_ITEMS.map((navItem) => ({
        ...navItem,
        permission:
          !DEV_MODE &&
          protectedRoutes.find((route) => route.path === navItem.path)
            .permission
      })),
    []
  );

  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const isSelected = (path: string) => {
    return startsWith(pathname, path);
  };

  const ListItemProps = (path: string): any => {
    return {
      button: true,
      selected: isSelected(path),
      classes: { root: classes.navItem, selected: classes.navItemSelected },
      component: NavLink,
      to: path
    };
  };

  const drawer = (
    <>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.toolbarShift]: !open
        })}
      >
        {open ? (
          <>
            <Link to={ROUTE_HOME}>
              <IconLogo sx={{ width: 168, height: 28 }} />
            </Link>
            <IconButton
              size="small"
              onClick={handleDrawerToggle}
              disableRipple
              className={classes.toggleBtn}
            >
              <IconSidebarToggle />
            </IconButton>
          </>
        ) : (
          <>
            <Link to={ROUTE_HOME}>
              <IconLogoMin sx={{ width: 28, height: 28 }} />
            </Link>
            <IconButton
              size="small"
              onClick={handleDrawerToggle}
              className={clsx(classes.miniToggleButton, classes.toggleBtn)}
              disableRipple
            >
              <IconSidebarToggle sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </>
        )}
      </Toolbar>
      <List className={classes.nav} disablePadding component="div">
        {map(NAV_ITEMS_ENHANCED, (item, index) => {
          const currentPermission = user.userRole.permissions[item.permission];
          if (!item.permission || currentPermission) {
            return (
              <ListItem {...ListItemProps(item.path)} key={index}>
                <Box className={classes.navItemIcon}>{item.icon}</Box>
                {open && (
                  <Typography
                    variant="button"
                    className={classes.navItemLabel}
                    component="span"
                  >
                    {item.label}
                  </Typography>
                )}
              </ListItem>
            );
          }
          return null;
        })}
      </List>
      <List
        className={classes.nav}
        disablePadding
        component="div"
        sx={{ mt: 3 }}
      >
        {open && (
          <Typography className={classes.navTitle}>Admin settings</Typography>
        )}

        {map(ADMIN_ITEMS_ENHANCED, (item, index) => {
          const currentPermission = user.userRole.permissions[item.permission];
          if (!item.permission || currentPermission) {
            return (
              <ListItem {...ListItemProps(item.path)} key={index}>
                <Box className={classes.navItemIcon}>{item.icon}</Box>
                {open && (
                  <Typography
                    variant="button"
                    className={classes.navItemLabel}
                    component="span"
                  >
                    {item.label}
                  </Typography>
                )}
              </ListItem>
            );
          }
          return null;
        })}
      </List>
      <List
        className={classes.nav}
        disablePadding
        component="div"
        sx={{
          mt: 'auto',
          mb: 1.5
        }}
      >
        <ListItem
          button
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          onClick={async () => {
            localStorage.removeItem('login');
            await dispatch(logout());
            navigate(url(ROUTE_SIGN_IN));
          }}
        >
          <Box className={classes.navItemIcon}>
            <IconLogout />
          </Box>
          {open && (
            <Typography
              variant="button"
              className={classes.navItemLabel}
              component="span"
            >
              Logout
            </Typography>
          )}
        </ListItem>
      </List>
    </>
  );
  return (
    <Box component="nav" className={classes.root}>
      <MuiDrawer
        variant="permanent"
        open={open}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper
        }}
        sx={(theme) => ({
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            }),
            boxSizing: 'border-box',
            ...(!open
              ? {
                  overflowX: 'hidden',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                  }),
                  width: 64
                }
              : {})
          }
        })}
      >
        <SimpleBar className={classes.simpleBar}>{drawer}</SimpleBar>
      </MuiDrawer>
    </Box>
  );
};
