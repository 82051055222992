import { ComponentType, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { TextField } from '../../../components/fields';
import * as yup from 'yup';
import { useEditResource } from '../../../core/hooks/useEditResource';
import { MessagesApi } from '../../../core/http';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from '../../../core/hooks/useSnackbar';

const messagesApi = new MessagesApi();

interface Props extends DialogProps {
  onClose?: () => void;
}

export const AddMessageDialog: ComponentType<Props> = ({ popDialog }) => {
  const { showMessage } = useSnackbar();
  const [createUuid] = useState(uuidv4());
  const { handleSubmit, handleChange, values, getFieldTouchedError } =
    useEditResource(messagesApi, {
      createUuid,
      uuid: null,
      initialValues: {
        message: ''
      },
      validationSchema: yup.object().shape({
        message: yup.string().required('This field is required')
      }),
      onSuccess: () => {
        showMessage('Message created successfully');
        popDialog();
      },
      onError: () => {
        showMessage('There was a problem creating the message', 'error');
      }
    });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 650
        }
      }}
    >
      <DialogTitle>Add new message</DialogTitle>
      <DialogContent>
        <TextField
          label="Message"
          required
          multiline={true}
          rows={5.6}
          placeholder="Text here"
          onChange={handleChange('message')}
          errors={getFieldTouchedError('message')}
          value={values.message}
        />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
