import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { useAuth } from '../../../core/hooks/useAuth';
import { useNavigate } from 'react-router';
import { ROUTE_SIGN_IN } from '../../../constants/route.paths';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const LogoutDialog: ComponentType<Props> = ({ popDialog }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleConfirm = async () => {
    await logout();
    navigate(ROUTE_SIGN_IN);
    popDialog();
  };
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 400
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        }
      }}
    >
      <DialogTitle>Logout</DialogTitle>
      <DialogContent>
        <Typography variant="body3">
          Do you really want to log out of your account?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleConfirm()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
