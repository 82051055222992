import { Expose } from 'class-transformer';

export class ReporsBillingDto {
  @Expose()
  uuid: string;

  @Expose()
  authorization: any;

  @Expose()
  payer: any;

  @Expose()
  appointment: any;

  @Expose()
  startDateOfService: any;

  @Expose()
  endDateOfService: any;

  @Expose()
  unitOfService: any;

  @Expose()
  clientSignatureFile: any;

  @Expose()
  providerSignatureFile: any;

  @Expose()
  serviceAuthorizationFile: any;
}
