import React, { useEffect, useState } from 'react';
import { Button, Divider, Link } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { Badge } from '../../components/badge';
import { Alert } from '../../components/alert/Alert';
import { useLayout } from '../../core/hooks/useLayout';
import { ROUTE_CLIENT_DETAILS } from '../../constants/route.paths';
import { url } from '../../core';
import { useParams } from 'react-router';
import { useClientDetail } from '../../core/hooks/useClientDetail';
import { useNavigate } from 'react-router-dom';
import { AppointmentGeneralForm } from '../appointments/AppointmentGeneralForm';
import { useAppointmentEdit } from '../../core/hooks/useAppointmentEdit';

export const ClientAppointmentEdit = () => {
  const navigate = useNavigate();
  const { clientId, uuid } = useParams();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const backUrl =
    url(ROUTE_CLIENT_DETAILS, {
      id: clientId
    }) + '#appointments';
  const { data: client } = useClientDetail({ uuid: clientId });
  const {
    isLoading,
    isEdit,
    handleSubmit,
    values,
    setFieldValue,
    data: appointment,
    getFieldTouchedError,
    handleDelete,
    handleConvert,
    handleCancel,
    handleBlur,
    setFieldTouched
  } = useAppointmentEdit(uuid, selectedCity, selectedState, backUrl);
  const pageTitle = isEdit ? 'Edit Appointment' : 'Add Appointment';

  useEffect(() => {
    setFieldValue('clientUuid', clientId);
  }, []);
  useLayout({
    backButton: {
      title: client?.displayName,
      path: backUrl
    }
  });

  return (
    <>
      <PageHeader
        title={pageTitle}
        meta={
          isEdit && (
            <>
              {client?.id && <Badge label={client?.id} variant={'secondary'} />}
              {appointment?.status && (
                <Badge
                  label={appointment?.status}
                  variant={appointment?.status}
                />
              )}
            </>
          )
        }
      >
        {isEdit && (
          <>
            {appointment?.canConvert && (
              <Button
                color="secondary"
                onClick={() => handleConvert(appointment)}
                disabled={isLoading}
              >
                Convert
              </Button>
            )}
            {appointment?.canDelete && (
              <Button
                color="secondary"
                onClick={() => handleDelete(appointment)}
                disabled={isLoading}
              >
                Delete
              </Button>
            )}
            {appointment?.canCancel && (
              <Button
                color="secondary"
                onClick={() => handleCancel(appointment)}
                disabled={isLoading}
              >
                Cancel appointment
              </Button>
            )}
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                height: 32,
                borderColor: '#F6F9FF!important',
                margin: '0 8px 0 16px!important'
              }}
            />
          </>
        )}
        <Button onClick={() => handleSubmit()}>
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button color="third" onClick={() => navigate(backUrl)}>
          Cancel
        </Button>
      </PageHeader>
      {isEdit && (
        <Alert mb={1.25}>
          This appointment is for 2:30 Hour(s) and occurs weekly on MON,WED,FRI
          02/24/2021 through 01/06/2023.
          <Link variant="body2" color="primary" underline="none">
            Recurring Details
          </Link>
        </Alert>
      )}
      <AppointmentGeneralForm
        {...{
          clientName: client?.displayName,
          values,
          isLoading,
          setFieldValue,
          getFieldTouchedError,
          handleSubmit,
          handleBlur,
          setFieldTouched,
          setSelectedCity,
          setSelectedState
        }}
      />
    </>
  );
};
