import 'reflect-metadata';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './core/theme/theme-provider';
import store from './core/store/store';
import { Provider } from 'react-redux';
import { DialogProvider } from './core/providers/dialog.provider';
import { SnackbarProvider } from './core/providers/snackbar';
import { LayoutProvider } from './core/providers/layout.provider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <LayoutProvider>
        <Provider store={store}>
          <SnackbarProvider>
            <DialogProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </DialogProvider>
          </SnackbarProvider>
        </Provider>
      </LayoutProvider>
    </ThemeProvider>
  </BrowserRouter>
);
