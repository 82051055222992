import { ClientDetails } from '../../../features/clients/ClientDetails';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const ClientDetailsPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Clients'
  })
)(ClientDetails);
