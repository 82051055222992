import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import omit from 'lodash/get';
import { EmployeeApi, EmployeeContactApi } from '../../core/http';
import {
  transformToBackendDate,
  normalizePhone
} from '../../core/utils/data.utils';
import { usPhoneNumber } from '../../core/utils/format.utils';

interface Options {
  uuid?: string;
  onSuccess?: (values?: FormikValues) => void;
  onError?: (error: string) => void;
}

const employeeApi = new EmployeeApi();
const employeeContactApi = new EmployeeContactApi();

export const useEditEmployeeResource = ({
  uuid,
  onSuccess,
  onError
}: Options) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [touched, setTouched] = useState<any>({});
  const [data, setData] = useState<Record<string, any>>({});

  const employeeContactInfoValues = {
    addressLine: '',
    addressLineSecond: '',
    cityUuid: '',
    stateUuid: '',
    phoneNumberPrimary: '',
    phoneNumberSecondary: '',
    phoneNumberWork: '',
    zipCode: '',
    notifications: false
  };

  const initialValues = {
    status: '',
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    hireDate: '',
    endWorkDate: '',
    availableHours: '',
    hourlyRate: '',
    targetedHour: '',
    hrid: '',
    roleUuid: '',
    pto: '',
    primaryLanguageUuid: '',
    secondaryLanguageUuid: '',
    profitCenterUuid: '',
    supervisorUuid: '',
    memberId: '',
    titleUuid: '',
    titleCustom: '',
    notes: '',
    skillsUuids: [],
    serviceTypes: [],
    groups: [],
    types: [],
    contactInfo: employeeContactInfoValues
  };
  const min_Date = new Date('01/01/1950');
  const employeeValidationScheme = yup.object().shape(
    {
      firstName: yup
        .string()
        .test(
          'spaces',
          'First Name must contain at least one character.',
          (value) => {
            return !!value?.trim();
          }
        )
        .matches(
          /^[aA-zZ\s]+$/,
          'First Name must contain only letters and backspaces.'
        ),
      lastName: yup
        .string()
        .test(
          'spaces',
          'Last Name must contain at least one character.',
          (value) => {
            return !!value?.trim();
          }
        )
        .matches(
          /^[aA-zZ\s]+$/,
          'Last Name must contain only letters and backspaces. '
        ),
      gender: yup.string().required('Please select one of the options.'),
      hireDate: yup
        .date()
        .required('Please select hire date.')
        .typeError('Please enter valid date.')
        .min(min_Date, 'Hire date must be later than 01/01/1950.')
        .when('endWorkDate', {
          is: yup.date().isValid('Please enter valid date.'),
          then: yup
            .date()
            .max(
              yup.ref('endWorkDate.'),
              'Hire date must be earlier than End work date.'
            )
            .typeError('Please enter valid date.'),
          otherwise: yup.date().nullable().typeError('Please enter valid date.')
        }),
      endWorkDate: yup
        .date()
        .nullable()
        .notRequired()
        .typeError('Invalid date')
        .min(
          yup.ref('hireDate'),
          'End work date must be later then Hire date.'
        ),
      availableHours: yup
        .number()
        .required('Please enter available hours.')
        .typeError('Available hours must contain only numbers.')
        .nullable(),
      hrid: yup
        .number()
        .required('Please enter hrid')
        .typeError('Available hours must contain only numbers.'),
      roleUuid: yup.string().required('Please select role'),
      hourlyRate: yup
        .number()
        .typeError('Available hours must contain only numbers.')
        .nullable(),
      targetedHour: yup
        .number()
        .typeError('Available hours must contain only numbers.')
        .nullable(),
      pto: yup
        .number()
        .typeError('Available hours must contain only numbers.')
        .nullable(),
      type: yup.string(),
      email: yup
        .string()
        .email('Please enter a valid email address.')
        .required('Please enter email address.'),
      primaryLanguageUuid: yup
        .string()
        .required('Please select one of the options.'),
      secondaryLanguageUuid: yup.string(),
      profitCenterUuid: yup
        .string()
        .required('Please select one of the options.'),
      supervisorUuid: yup.string(),
      notes: yup.string(),
      titleUuid: yup.string().when('titleCustom', {
        is: '',
        then: yup.string().min(1, 'Pick at least 1 item'),
        otherwise: yup.string()
      }),
      titleCustom: yup.string().when('titleUuid', {
        is: (titleUuid) => !titleUuid,
        then: yup.string().required('This field is required.').nullable(),
        otherwise: yup.string().nullable()
      })
    },
    [['titleUuid', 'titleCustom']]
  );

  const employeeContactInfoValidationScheme = yup.object().shape({
    addressLine: yup.string().required('Please enter address 1.'),
    addressLineSecond: yup.string(),
    cityUuid: yup.string().required('Please enter city.'),
    stateUuid: yup.string().required('Please enter state.'),
    zipCode: yup.string().required('Please enter zip code.'),
    phoneNumberPrimary: yup
      .string()
      .required('Please enter a primary phone number')
      .transform((value) => (value ? normalizePhone(value) : value))
      .length(10, 'Please enter valid phone number.'),
    phoneNumberSecondary: yup
      .string()
      .transform((value) => (value ? normalizePhone(value) : value))
      .length(10, 'Please enter valid phone number.'),
    phoneNumberWork: yup
      .string()
      .transform((value) => (value ? normalizePhone(value) : value))
      .length(10, 'Please enter valid phone number.'),
    notifications: yup.boolean()
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    setFieldValue,
    setFieldTouched,
    touched: validationTouched,
    handleBlur
  } = useFormik({
    initialValues,
    validationSchema: employeeValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (Object.keys(employeeContactInfoProps.errors).length > 0) return;
      setIsLoading(true);
      setErrors({});
      let actionPromise: Promise<void>;
      const employeeUuid = uuidv4();
      omit(values, ['contactInfo']);
      if (isEdit) {
        actionPromise = employeeApi
          .update({
            uuid,
            ...values,
            hireDate: transformToBackendDate(values.hireDate),
            endWorkDate: transformToBackendDate(values.endWorkDate),
            availableHours: +values.availableHours,
            hrid: +values.hrid,
            secondaryLanguageUuid: values.secondaryLanguageUuid || ''
          })
          .then(() => {
            employeeContactApi.update({
              ...values.contactInfo,
              phoneNumberPrimary: normalizePhone(
                values.contactInfo.phoneNumberPrimary
              ),
              phoneNumberSecondary: normalizePhone(
                values.contactInfo.phoneNumberSecondary
              ),
              phoneNumberWork: normalizePhone(
                values.contactInfo.phoneNumberWork
              )
            });
          });
      } else {
        actionPromise = employeeApi
          .create({
            uuid: employeeUuid,
            ...values,
            hireDate: transformToBackendDate(values.hireDate),
            endWorkDate: transformToBackendDate(values.endWorkDate),
            availableHours: +values.availableHours,
            hrid: +values.hrid,
            phoneNumberPrimary: normalizePhone(
              values.contactInfo.phoneNumberPrimary
            ),
            phoneNumberSecondary: normalizePhone(
              values.contactInfo.phoneNumberSecondary
            ),
            phoneNumberWork: normalizePhone(values.contactInfo.phoneNumberWork)
          })
          .then(() => {
            employeeContactApi.create({
              ...values.contactInfo,
              uuid: uuidv4(),
              employeeUuid: employeeUuid,
              phoneNumberPrimary: normalizePhone(
                values.contactInfo.phoneNumberPrimary
              ),
              phoneNumberSecondary: normalizePhone(
                values.contactInfo.phoneNumberSecondary
              ),
              phoneNumberWork: normalizePhone(
                values.contactInfo.phoneNumberWork
              )
            });
          });
      }

      actionPromise
        .then(() => {
          setIsLoading(false);
          onSuccess && onSuccess(values);
        })
        .catch((err) => {
          const errorDescription = get(
            err,
            'response.data.errorDescription.fields'
          );
          const errorMessage = get(err, 'response.data.errorMessage');

          if (errorDescription) {
            setErrors(errorDescription);
          } else if (errorMessage) {
            setErrors(errorDescription);
          }

          setIsLoading(false);
          onError && onError(err);
        });
    }
  });

  const employeeContactInfoProps = useFormik({
    initialValues: employeeContactInfoValues,
    validationSchema: employeeContactInfoValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {
      return;
    }
  });

  useEffect(() => {
    employeeContactInfoProps.validateForm();
    Object.keys(employeeContactInfoValues).forEach((key) => {
      employeeContactInfoProps.setFieldTouched(key, false);
    });
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      contactInfo: employeeContactInfoProps.values
    });
  }, [employeeContactInfoProps.values]);

  useEffect(() => {
    setErrors({
      ...validationErrors,
      contactInfo: employeeContactInfoProps.errors
    });
  }, [employeeContactInfoProps.errors, validationErrors]);

  useEffect(() => {
    setTouched({
      ...validationTouched,
      contactInfo: employeeContactInfoProps.touched
    });
  }, [employeeContactInfoProps.touched, validationTouched]);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);
      employeeApi
        .findOne(uuid)
        .then((data) => {
          setData(data);
          setValues({
            ...data,
            hireDate: (data?.hireDate || '').substring(0, 19),
            endWorkDate: (data?.endWorkDate || '').substring(0, 19)
          });
          employeeContactInfoProps.setValues(
            data?.contactInfo || employeeContactInfoValues
          );
          employeeContactInfoProps.setFieldValue(
            'phoneNumberPrimary',
            usPhoneNumber(data?.contactInfo?.phoneNumberPrimary)
          );
          employeeContactInfoProps.setFieldValue(
            'phoneNumberSecondary',
            usPhoneNumber(data?.contactInfo?.phoneNumberSecondary || '')
          );
          employeeContactInfoProps.setFieldValue(
            'phoneNumberWork',
            usPhoneNumber(data?.contactInfo?.phoneNumberWork || '')
          );
          employeeContactInfoProps.setFieldValue(
            'stateUuid',
            data?.contactInfo?.state?.uuid || ''
          );
          employeeContactInfoProps.setFieldValue(
            'cityUuid',
            data?.contactInfo?.city?.uuid || ''
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid]);

  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    employeeContactInfoProps
  };
};
