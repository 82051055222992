import { plainToClass } from 'class-transformer';
import {
  API_CLIENT_FAMILY_COMMITMENT_FIND_ONE,
  API_CLIENT_FAMILY_COMMITMENT_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { ClientFamilyCommitmentDto } from '../dto';
import { FindAllResponse } from '../types';

export class ClientFamilyCommitmentApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<ClientFamilyCommitmentDto>> {
    return this.httpClient
      .get(`/api/v1/clients/${params.clientUuid}/family-commitment`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ClientFamilyCommitmentDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<ClientFamilyCommitmentDto> {
    return this.httpClient
      .get(API_CLIENT_FAMILY_COMMITMENT_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(ClientFamilyCommitmentDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<ClientFamilyCommitmentDto> {
    return this.httpClient
      .post(`/api/v1/clients/${data.clientUuid}/family-commitment`, data)
      .then(({ data }) => {
        return plainToClass(ClientFamilyCommitmentDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<ClientFamilyCommitmentDto> {
    return this.httpClient
      .put(API_CLIENT_FAMILY_COMMITMENT_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(ClientFamilyCommitmentDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(params, uuid): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/clients/${params.clientUuid}/family-commitment/${uuid}`,
      {
        pathParams: { uuid }
      }
    );
  }
}
