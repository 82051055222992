import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';
import { ClientDto } from './client.dto';

export class ClientReminderDto {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  dueDate: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'client.uuid'))
  clientUuid: string;

  @Expose()
  client: ClientDto;

  @Expose()
  notes: string;

  @Expose()
  isTextNotificationsEnabled: boolean;

  @Expose()
  isEmailNotificationsEnabled: boolean;
}
