import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Stack,
  Typography
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { PasswordField } from '../../../components/fields';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserApi } from '../../../core/http';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { useUser } from '../../../core/hooks/useUser';

interface Props extends DialogProps {
  onClose?: () => void;
}
const userApi = new UserApi();

export const Reset2FADialog: ComponentType<Props> = ({ popDialog }) => {
  const { showMessage } = useSnackbar();
  const { user } = useUser();
  const { handleChange, handleSubmit, values, touched, errors } = useFormik({
    initialValues: {
      currentPassword: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      currentPassword: yup
        .string()
        .required('This field is required.')
        .min(8, 'Password must be at least 8 characters long')
    }),
    onSubmit: () => {
      userApi
        .reset2FA({
          password: values.currentPassword,
          userUuid: user.uuid
        })
        .then(() => {
          popDialog();
          showMessage('2FA has beed resetted successfully.');
        });
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 400
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        }
      }}
    >
      <DialogTitle>Reset 2-factor authentication</DialogTitle>

      <DialogContent>
        <Typography variant="body3">
          Enter your password below to disable 2-factor authentication
        </Typography>
        <Stack spacing={1} mt={2}>
          <PasswordField
            label="Current password"
            fullWidth
            value={values.currentPassword}
            onChange={handleChange('currentPassword')}
            helperText={touched.currentPassword && errors.currentPassword}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
