import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'master-data-menu'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flexWrap: 'wrap',
      margin: theme.spacing(-0.4),
      '&> a': {
        padding: theme.spacing(0.4),
        flex: '1 1 25%',
        maxWidth: '25%',
        textDecoration: 'none',
        display: 'block'
      },
      '&>a>div': {
        padding: theme.spacing(3),
        backgroundColor: '#F9FBFE',
        borderRadius: 4
      },
      '& a:hover > div': {
        backgroundColor: '#ECF4FF'
      }
    }
  }),
  options
);

export default useStyles;
