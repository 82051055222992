import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { SelectField } from '../../../components/fields';
import { Box } from '@mui/material';
import qs from 'qs';

const baseURL = process.env.REACT_APP_API_URL;

export const ExportTimesheetDialog = ({
  popDialog,
  selectedUuids,
  onSuccess
}) => {
  const [selectedType, setSelectedType] = useState();
  const [error, setError] = useState<string>();
  const queryParams = qs.stringify(
    { uuids: selectedUuids },
    { arrayFormat: 'brackets', addQueryPrefix: true }
  );

  const handleExport = () => {
    if (selectedType !== 'csv') {
      setError('Please select export type.');
    } else {
      setError('');
      window.location.href = `${baseURL}/appointment/timesheet/export/timesheet.csv${queryParams}`;
      onSuccess?.();
    }
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 480 } }}
    >
      <DialogTitle>Select export type</DialogTitle>
      <DialogContent>
        <SelectField
          errors={error}
          helperText={error}
          required
          label="Export type "
          value={selectedType}
          items={[
            {
              label: 'CSV',
              value: 'csv'
            }
          ]}
          placeholder="Select"
          onChange={(e) => setSelectedType(e)}
        />
        <Box mb={1} />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleExport()}>Export</Button>
      </DialogActions>
    </Dialog>
  );
};
