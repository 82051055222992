import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { IconArrowDown } from '../../icons';
import { Box, Chip, Stack, TextField, Typography } from '@mui/material';
import useStyles from '../select-field/styles';
import { Checkbox } from '../checkbox';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

interface dataSourceType {
  label: string;
  value: any;
}

interface Props {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (values: string[]) => void;
  value?: string[];
  errors?: string;
  dataSource: dataSourceType[];
}

export const MultipleSelectField: FC<Props> = ({
  label,
  disabled,
  placeholder,
  onChange,
  value = [],
  dataSource = []
}) => {
  const itemsByUuid = keyBy(dataSource, 'value');
  const classes = useStyles();

  const handleChange = (e: SelectChangeEvent) => {
    onChange && onChange(e.target.value as any);
  };

  const handleDelete = (uuid: string) => () => {
    const filteredValues = value.filter((v: string) => v !== uuid);
    onChange && onChange(filteredValues);
  };

  const chipLabel = (value: string) => {
    const item: dataSourceType | Record<string, unknown> = get(
      itemsByUuid,
      value,
      {}
    );
    return `${item.label}`;
  };

  return (
    <TextField
      disabled={disabled}
      placeholder={placeholder}
      select
      label={label}
      value={value}
      classes={{ root: classes.root }}
      SelectProps={{
        IconComponent: IconArrowDown,
        multiple: true,
        onChange: handleChange,
        renderValue: (selected: string[]) => {
          if (selected.length === 0) {
            return <>{placeholder}</>;
          }
          return (
            <Box className={classes.chipContainer}>
              {selected.map((uuid: string) => (
                <Chip
                  key={uuid}
                  label={chipLabel(uuid)}
                  size="small"
                  color="primary"
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={handleDelete(uuid)}
                />
              ))}
            </Box>
          );
        }
      }}
    >
      {dataSource.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected
          }}
        >
          <Stack direction="row" spacing={1}>
            <Checkbox checked={value.includes(option.value)} />
            <Typography variant="body3">{option.label}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  );
};
