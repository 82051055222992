import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface UseCancellationReportResult {
  data: any;
  isLoading: boolean;
  error: any;
  fetchData: () => void;
  handleChangeParams: (filter: any) => void;
}

export const useCancellationReport = (
  api,
  options = { defaultParams: {} }
): UseCancellationReportResult => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { defaultParams } = options;

  const [params, setParams] = useState<any>({
    ...defaultParams
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    api
      .findAll(params)
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [params]);

  const handleChangeParams = (filter: any) => {
    setParams((prevState) => ({
      ...prevState,
      ...filter
    }));
  };

  useDeepCompareEffect(() => {
    fetchData();
  }, [params]);

  return {
    data,
    isLoading,
    error,
    fetchData,
    handleChangeParams
  };
};
