import { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';
import { useStyles } from './styles';
import twoFactor from '../../assets/two-factor.svg';
import {
  ROUTE_SIGN_IN,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';

export const TwoFactorSetupSuccess: ComponentType = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Box className={classes.twoFactorSetupSuccessRoot}>
        <Box className={classes.block}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item>
              <img src={twoFactor} />
            </Grid>
            <Grid item>
              <Typography variant="h2" mt={3} textAlign="center">
                2-Factor Authorization was successfully set up! We have
                generated your recovery code
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2" textAlign="center">
                Please, proceed with saving this 16-digit recovery code.It can
                be used in case of the need to recover your 2-factor
                authentication. It will not be shown again, however, you are
                able to generate a new one anytime. We do not recommend taking a
                screenshot of it.
              </Typography>
            </Grid>
            <Grid item>
              <Box className={classes.recoveryCode}>
                <Typography variant="h4">5TB5-45FR-GBFE-14RGV</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Button
                size="medium"
                onClick={() => navigate(url(ROUTE_SIGN_IN))}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </>
  );
};
