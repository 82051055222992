import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'week-range-picker'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: '#F9FBFE',
      padding: theme.spacing(0.5),
      borderRadius: 4
    },
    button: {
      width: 24,
      height: 24,
      background: '#fff',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: 4
    },
    date: {
      minWidth: 118,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      color: '#172B4D',
      textAlign: 'center'
    }
  }),
  options
);

export default useStyles;
