import { plainToClass } from 'class-transformer';
import {
  API_APPOINTMENT_CANCEL_TYPE_STATUS_CREATE,
  API_APPOINTMENT_CANCEL_TYPE_STATUS_DELETE,
  API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ALL,
  API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ONE,
  API_APPOINTMENT_CANCEL_TYPE_STATUS_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { AppointmentCancelTypeStatusDto } from '../dto';
import { FindAllResponse } from '../types';

export class AppointmentCancelTypeStatusApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(
    params
  ): Promise<FindAllResponse<AppointmentCancelTypeStatusDto>> {
    return this.httpClient
      .get(API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(AppointmentCancelTypeStatusDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<AppointmentCancelTypeStatusDto> {
    return this.httpClient
      .get(API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ONE, {
        pathParams: { uuid }
      })
      .then(({ data }) => {
        return plainToClass(AppointmentCancelTypeStatusDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<AppointmentCancelTypeStatusDto> {
    return this.httpClient
      .post(API_APPOINTMENT_CANCEL_TYPE_STATUS_CREATE, data)
      .then(({ data }) => {
        return plainToClass(AppointmentCancelTypeStatusDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<AppointmentCancelTypeStatusDto> {
    return this.httpClient
      .put(API_APPOINTMENT_CANCEL_TYPE_STATUS_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(AppointmentCancelTypeStatusDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_APPOINTMENT_CANCEL_TYPE_STATUS_DELETE, {
      pathParams: { uuid }
    });
  }
}
