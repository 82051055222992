import { Card } from '../../../components/cards';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import {
  ClientAutocompleteField,
  DatePicker,
  ServiceCodeMultipleField
} from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { clientAppointmentsColumns } from '../_mockdata';
import { IconView } from '../../../components/icons';
import get from 'lodash/get';
import { useListResource } from '../../../core/hooks/useListResource';
import { AppointmentApi } from '../../../core/http';
import { useFilter } from '../../../core/hooks/useFilter';

const appointmentApi = new AppointmentApi();

export const ClientAppointments = () => {
  const {
    data,
    isLoading,
    pagination,
    handleChangePage,
    handleChangeParams,
    handleChangePerPage,
    handleChangeSort
  } = useListResource(appointmentApi);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });
  return (
    <>
      <Card mb={3}>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <Box flex="0 0 150px">
            <DatePicker
              disableInput
              label="From"
              size="small"
              helperText={null}
              value={getFilterValue('filter[startDate][gt]') || null}
              onChange={setFilterValue('filter[startDate][gt]')}
              maxDate={
                getFilterValue('filter[endDate][lt]') &&
                new Date(getFilterValue('filter[endDate][lt]'))
              }
            />
          </Box>
          <Box flex="0 0 150px">
            <DatePicker
              disableInput
              label="To"
              size="small"
              helperText={null}
              value={getFilterValue('filter[endDate][lt]') || null}
              onChange={setFilterValue('filter[endDate][lt]')}
              minDate={new Date(getFilterValue('filter[startDate][gt]'))}
            />
          </Box>
          <ServiceCodeMultipleField
            label="Service Code"
            size="small"
            placeholder="Search"
            helperText={null}
            value={getFilterValue('filter[serviceCode.uuid][in]')}
            onChange={setFilterValue('filter[serviceCode.uuid][in]')}
          />
          <ClientAutocompleteField
            limitTags={3}
            label="Client"
            placeholder="Search"
            size="small"
            helperText={null}
            value={getFilterValue('filter[client.uuid][eq]')}
            onChange={setFilterValue('filter[client.uuid][eq]')}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Stack>
      </Card>
      <TableView
        TableProps={{
          sx: {
            minWidth: 2000,
            overflowX: 'auto'
          }
        }}
        loading={isLoading}
        pagination={pagination}
        keyExtractor={(item) => item.uuid}
        dataSource={get(data, 'items', [])}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        columns={[
          ...clientAppointmentsColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: () => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton size="small">
                    <IconView />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
