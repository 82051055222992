import { Box, Stack } from '@mui/material';
import { Badge } from '../badge';
import useStyles from './styles';
import { LabeledRecord } from '../labeled-record';
import { AppointmentDto } from '../../core/http';
import { FC } from 'react';
import { normalizeDate, timeConverter } from '../../core';

interface Props {
  item: AppointmentDto;
}

export const AppointmentCalendarDrawerCard: FC<Props> = ({ item }) => {
  const classes = useStyles();
  const durationInMinutes = item.durationTime
    ? Math.round(item.durationTime / 60000)
    : null;

  return (
    <Stack flex={1} alignItems="flex-start" width="100%">
      {item?.status && (
        <Badge label={item.status} variant={item.status} sx={{ mb: 1.5 }} />
      )}

      {item.cancelType && (
        <>
          <LabeledRecord
            label="Cancellation Type:"
            value={item?.cancelType?.title}
            mb={1}
          />
          <LabeledRecord label="Reason:" value={item?.cancelReason} mb={1} />
        </>
      )}

      <Box className={classes.card} mb={1.5} ml={-1}>
        <LabeledRecord
          label="Date:"
          value={normalizeDate(item.startDate)}
          mb={1}
        />
        <LabeledRecord
          label="Time:"
          value={timeConverter(item.startDate, item.endDate)}
          mb={1}
        />
        <LabeledRecord
          label="Duration:"
          value={`${durationInMinutes} min`}
          mb={1}
        />
      </Box>
      <LabeledRecord mb={1.5} label="Type:" value={item?.type?.title || '-'} />
      <LabeledRecord
        mb={1.5}
        label="Client:"
        value={item?.client.displayName || '-'}
      />
      <LabeledRecord
        mb={1.5}
        label="Therapist:"
        value={item?.primaryTherapist?.displayName || '-'}
      />
      <LabeledRecord mb={1.5} label="Provider:" value={'-'} />
      <LabeledRecord
        mb={1.5}
        label="Service Code/Description:"
        value={`${
          item?.authorizations[0]
            ? item?.authorizations[0]?.serviceCode?.code || '-'
            : '-'
        } / ${
          item?.authorizations[0]
            ? item?.authorizations[0]?.serviceCode?.description || '-'
            : '-'
        }`}
      />
      <LabeledRecord
        label="Location:"
        value={item?.location?.name || item?.locationString || '-'}
      />
    </Stack>
  );
};
