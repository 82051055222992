import { ServiceTypeContainer } from '../../features/master-data/service-type/ServiceTypeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataServiceType = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Service Types'
  })
)(ServiceTypeContainer);
