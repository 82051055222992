import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'table-per-page'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    showByText: {
      fontSize: 14,
      lineHeight: '24px',
      color: '#172B4D'
    },
    select: {
      backgroundColor: '#F6F9FF',
      lineHeight: '24px',
      '& .MuiSelect-select': {
        minHeight: 24,
        minWidth: 26,
        padding: theme.spacing(0.5, 1),
        borderRadius: 3,
        color: '#172B4D',
        fontSize: 14
      }
    },
    totalText: {
      fontSize: 12,
      lineHeight: '16px',
      color: '#6B778C'
    }
  }),
  options
);

export default useStyles;
