import { plainToClass } from 'class-transformer';
import {
  API_LEAVE_DAY_CREATE,
  API_LEAVE_DAY_DELETE,
  API_LEAVE_DAY_FIND_ONE,
  API_LEAVE_DAY_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { LeaveDayDto } from '../dto';
import { FindAllResponse } from '../types';

export class LeaveDayApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<LeaveDayDto>> {
    return this.httpClient
      .get(`/api/v1/employees/${params.employeeUuid}/leave-days`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(LeaveDayDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param employeeUuid
   * @param uuid
   */
  public findOne(employeeUuid, extraParams: any): Promise<LeaveDayDto> {
    return this.httpClient
      .get(`/api/v1/employees/${employeeUuid}/leave-days/${extraParams.uuid}`)
      .then(({ data }) => {
        return plainToClass(LeaveDayDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<LeaveDayDto> {
    return this.httpClient
      .post(API_LEAVE_DAY_CREATE, data, {
        pathParams: { employeeUuid: data.employeeUuid }
      })
      .then(({ data }) => {
        return plainToClass(LeaveDayDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<LeaveDayDto> {
    return this.httpClient
      .put(`/api/v1/employees/${data.employeeUuid}/leave-days`, data)
      .then(({ data }) => {
        return plainToClass(LeaveDayDto, data);
      });
  }

  /**
   * Delete
   * @param employeeUuid
   * @param uuid
   */
  public delete(params, uuid): Promise<void> {
    return this.httpClient.delete(
      `/api/v1/employees/${params.employeeUuid}/leave-days/${uuid}`
    );
  }
}
