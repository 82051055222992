import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';

interface IcreateOrganizationValues {
  uuid: string;
  name: string;
  authId: number;
}

export const deleteOrganization = createAsyncThunk(
  'deleteOrganizationAction',
  async (uuid: string) => {
    try {
      const data = await authService.delete(`organization/${uuid}`);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const createOrganization = createAsyncThunk(
  'createFundingSource',
  async (values: IcreateOrganizationValues) => {
    try {
      const data = await authService.post('/organization', values);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getOrganizationsList = createAsyncThunk(
  'getOrganizationsListAction',
  async () => {
    try {
      const data = await authService.get('organization');
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const fundingSourceSlice = createSlice({
  name: 'fundingSourceSlice',
  initialState: {
    getOrganizationsList: {
      loading: false,
      data: null,
      error: null
    },
    createOrganization: {
      loading: false,
      data: null,
      error: null
    },
    deleteOrganization: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizationsList.pending, (state) => {
      state.getOrganizationsList.loading = true;
      state.getOrganizationsList.data = null;
      state.getOrganizationsList.error = null;
    });
    builder.addCase(getOrganizationsList.fulfilled, (state, { payload }) => {
      state.getOrganizationsList.loading = false;
      state.getOrganizationsList.data = payload.data;
      state.getOrganizationsList.error = null;
    });
    builder.addCase(getOrganizationsList.rejected, (state) => {
      state.getOrganizationsList.loading = false;
      state.getOrganizationsList.data = null;
      state.getOrganizationsList.error = 'Something went wrong';
    });
    builder.addCase(createOrganization.pending, (state) => {
      state.createOrganization.loading = true;
      state.createOrganization.data = null;
      state.createOrganization.error = null;
    });
    builder.addCase(createOrganization.fulfilled, (state, { payload }) => {
      state.createOrganization.loading = false;
      state.createOrganization.data = payload.data;
      state.createOrganization.error = null;
    });
    builder.addCase(createOrganization.rejected, (state) => {
      state.createOrganization.loading = false;
      state.createOrganization.data = null;
      state.createOrganization.error = 'Something went wrong';
    });
    builder.addCase(deleteOrganization.pending, (state) => {
      state.deleteOrganization.loading = true;
      state.deleteOrganization.data = null;
      state.deleteOrganization.error = null;
    });
    builder.addCase(deleteOrganization.fulfilled, (state, { payload }) => {
      state.deleteOrganization.loading = false;
      state.deleteOrganization.data = payload.data;
      state.deleteOrganization.error = null;
    });
    builder.addCase(deleteOrganization.rejected, (state) => {
      state.deleteOrganization.loading = false;
      state.deleteOrganization.data = null;
      state.deleteOrganization.error = 'Something went wrong';
    });
  }
});

export default fundingSourceSlice.reducer;
