import { InternalWorkTypeContainer } from '../../features/master-data/internal-work-type/InternalWorkTypeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataInternalWorkType = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Internal Work Types'
  })
)(InternalWorkTypeContainer);
