import { AuthLayout } from '../../../layouts/auth-layout';
import { ResetPasswordSuccess } from '../../../features/auth/ResetPasswordSuccess';
import { Helmet } from 'react-helmet';

export const ResetPasswordSuccessPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Reset Password</title>
      </Helmet>
      <ResetPasswordSuccess />
    </AuthLayout>
  );
};
