import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  Skeleton,
  Tooltip
} from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ROUTE_SIGN_IN,
  ROUTE_TWO_FACTOR_SETUP_SUCCESS
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import googleAuthenticatorImage from '../../assets/google-authenticator.png';
import appStoreImage from '../../assets/appstore.png';
import googlePlayImage from '../../assets/googleplay.png';
import { AppState } from '../../core/store/store';
import { get2fa, verify2fa } from '../../core/store/slices/twoFactorSetupSlice';

export const TwoFactorSetupStepTwo: ComponentType = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const get2faData = useSelector(
    (store: AppState) => store.twoFactorSetup.get2fa.data
  );
  const get2faLoading = useSelector(
    (store: AppState) => store.twoFactorSetup.get2fa.loading
  );
  const verify2faLoading = useSelector(
    (store: AppState) => store.twoFactorSetup.verify2fa.loading
  );
  const verify2faError = useSelector(
    (store: AppState) => store.twoFactorSetup.verify2fa.error
  );

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: {
        authCode: ''
      },
      validateOnBlur: true,
      validationSchema: yup.object().shape({
        authCode: yup
          .string()
          .min(6, ({ min }) => `Verify code must be at least ${min} characters`)
          .required('This field is required')
      }),
      onSubmit: (values) => {
        dispatch(
          verify2fa({
            values,
            options: {
              onSuccess: () => navigate(url(ROUTE_TWO_FACTOR_SETUP_SUCCESS))
            }
          })
        );
      }
    });

  useEffect(() => {
    dispatch(get2fa());
  }, []);

  const copy = async () => {
    await navigator.clipboard.writeText(get2faData?.setupCode);
  };

  return (
    <Box className={classes.twoFactorSetupStepTwoRoot}>
      <Box className={classes.twoFactorSetupStepTwoWaves} />
      <Box className={classes.twoFactorSetupStepTwoBlock}>
        <Typography variant="h3" textAlign="center">
          Setup Google Authenticator
        </Typography>
        <Typography
          variant="subtitle2"
          textAlign="center"
          className={classes.twoFactorSetupStepTwoDescriptionText}
          mt={1}
          mb={2}
        >
          SplashWorks strongly recommend to set up 2-factor authorization with
          Google Autheticator to secure your account
        </Typography>
        <Stack
          className={classes.twoFactorSetupStepTwoStepsContainer}
          spacing={1}
          direction="row"
          mb={2}
        >
          <Box className={classes.twoFactorSetupStepTwoStepBlock}>
            <Typography variant="subtitle1">Step 1</Typography>
            <Typography
              variant="h4"
              mt={0.5}
              mb={2}
              className={classes.twoFactorSetupStepTwoStepHeaderText}
            >
              Download & install Google Autheticator on your mobile device.
            </Typography>
            <img src={googleAuthenticatorImage} />
            <Stack direction="row" spacing={2} mt={3}>
              <img
                src={appStoreImage}
                className={classes.twoFactorSetupStepTwoDownloadButton}
              />
              <img
                src={googlePlayImage}
                className={classes.twoFactorSetupStepTwoDownloadButton}
              />
            </Stack>
          </Box>
          <Box className={classes.twoFactorSetupStepTwoStepBlock}>
            <Typography variant="subtitle1">Step 2</Typography>
            <Typography
              variant="h4"
              mt={0.5}
              mb={2}
              className={classes.twoFactorSetupStepTwoStepHeaderText}
            >
              Add your account to Google Autheticator
            </Typography>
            <Typography variant="subtitle1" mb={2}>
              After clicking the “+” icon in Google Authticator, Use the camera
              to scan the QR code on the scree.
            </Typography>
            {get2faLoading || !get2faData ? (
              <Skeleton variant="rectangular" width={140} height={140} />
            ) : (
              <img
                alt="Please wait..."
                className={classes.twoFactorSetupStepTwoQrCodeImage}
                src={`data:image/png;base64,${get2faData?.qrCode}`}
              />
            )}

            <Typography variant="body2" mt={2} mb={2}>
              Or enter 2FA code in Google Authenticator app:
            </Typography>
            {get2faLoading ? (
              <Skeleton variant="rectangular" width={305} height={40} />
            ) : (
              <>
                <Tooltip title="Copy" placement="top">
                  <Button
                    className={classes.recoveryCode}
                    sx={{
                      '&.MuiButton-containedThird': {
                        border: 'none',
                        color: 'red',

                        '&:hover': {
                          borderColor: '#85B6FF'
                        },
                        '&:active': {
                          borderColor: 'none',
                          backgroundColor: '#E0EDFF',
                          color: '#0052CC'
                        }
                      }
                    }}
                    variant="outlined"
                    onClick={() => copy()}
                  >
                    <Typography variant="h4">
                      {get2faData?.setupCode?.slice(0, 25)}
                    </Typography>
                  </Button>
                </Tooltip>

                {navigator.clipboard.readText() === get2faData?.setupCode && (
                  <Typography variant="body3">
                    navigator.clipboard.readText()
                  </Typography>
                )}
              </>
            )}
          </Box>
          <Box className={classes.twoFactorSetupStepTwoStepBlock}>
            <Typography variant="subtitle1">Step 3</Typography>
            <Typography
              variant="h4"
              mt={0.5}
              mb={2}
              className={classes.twoFactorSetupStepTwoStepHeaderText}
            >
              Verify your device
            </Typography>
            <Typography variant="subtitle1" mt={2} mb={2}>
              Enter 6-digit code bellow from Google Autheticator.
            </Typography>
            <TextField
              name="code"
              label=""
              helperText={
                (touched.authCode && errors.authCode) || verify2faError
              }
              value={values.authCode}
              onChange={handleChange('authCode')}
              onBlur={handleBlur('authCode')}
              onKeyPress={(event) => {
                if (
                  !(
                    event.charCode >= 8 &&
                    event.charCode <= 57 &&
                    event.charCode != 43 &&
                    event.charCode != 45 &&
                    event.charCode != 32
                  )
                ) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 6 }}
            />
            <Button
              sx={{ mt: 1 }}
              fullWidth
              disabled={verify2faLoading}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Box>
        </Stack>
        <Button
          size="medium"
          variant="text"
          className={classes.twoFactorSetupStepTwoBackToLoginButton}
          onClick={() => navigate(ROUTE_SIGN_IN)}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  );
};
