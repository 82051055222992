import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  Typography,
  Button
} from '@mui/material';
import { FC } from 'react';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { UserRecord } from '../../../components/user-record';
import { TableView } from '../../../components/table-view';
import {
  serviceAuthorizationsViewColumns,
  serviceAuthorizationsViewData
} from '../_mockdata';
import { Card } from '../../../components/cards';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const ServiceAuthorizationViewDialog: FC<Props> = ({ popDialog }) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 900
        }
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h3" mb={0.25}>
              Service Code: 805 01 Early Intervention
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <UserRecord
                user={{
                  name: 'Theresa Webb',
                  avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
                }}
                NameProps={{
                  color: 'primary',
                  variant: 'body3'
                }}
              />
              <Typography color="common.grey" variant="body3">
                Date range:{' '}
                <Typography
                  variant="body3"
                  component="span"
                  color="common.dark"
                >
                  07/04/2021 - 02/28/2022
                </Typography>
              </Typography>
            </Stack>
          </Box>
          <Button onClick={popDialog} color="third">
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Card bgcolor="#F6F9FF" p={1}>
          <Stack direction="row" spacing={2}>
            <Typography variant="body3" color="common.grey">
              Total hours:{' '}
              <Typography variant="body3" color="common.dark">
                13.4
              </Typography>
            </Typography>
            <Typography variant="body3" color="common.grey">
              Remaining hours:{' '}
              <Typography variant="body3" color="common.dark">
                12.2
              </Typography>
            </Typography>
          </Stack>
        </Card>
        <TableView
          pagination={{
            page: 1,
            perPage: 12,
            totalPages: 10,
            total: 100
          }}
          keyExtractor={(item) => item.uuid}
          columns={serviceAuthorizationsViewColumns}
          dataSource={serviceAuthorizationsViewData}
        />
      </DialogContent>
    </Dialog>
  );
};
