import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { TaskTypeDto } from '../../../core/http';
import { useTaskType } from '../../../core/hooks/useTaskType';

export const TaskTypeField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useTaskType();

  const selectItems = map(items, (item: TaskTypeDto) => ({
    value: item.uuid,
    label: item.title
  }));

  return (
    <SelectField
      label="Location"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
