import { FC, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  TextField,
  Autocomplete as MuiAutocomplete,
  Chip
} from '@mui/material';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import useStyles from './styles';

interface Item {
  label: string;
  value: string;
}

interface Props {
  label: string;
  placeholder: string;
  value: string[];
  onChange?: (value: string[]) => void;
  dataSource: Item[];
  popUpIconEnabled?: boolean;
  buttonEnabled?: boolean;
  tagsInsideInput?: boolean;
  disabled?: boolean;
}

export const Autocomplete: FC<Props> = ({
  value: baseValue,
  label,
  placeholder,
  onChange,
  dataSource,
  disabled = false,
  popUpIconEnabled = false,
  buttonEnabled = true,
  tagsInsideInput = false
}) => {
  const [value, setValue] = useState<Item | null>(null);
  const [items, setItems] = useState<Item[]>([]);
  const classes = useStyles();

  const handleKeyPress = (event: any) => {
    if (event?.key === 'Enter') {
      setItems([...(items as Item[]), value as Item]);
      setValue(null);
    }
  };
  useEffect(() => {
    if (!isEmpty(baseValue)) {
      const selectedUsers = map(baseValue, (value: string) =>
        dataSource.find((item) => item.value === value)
      ).filter(Boolean);

      setItems(selectedUsers as Item[]);
    }
  }, []);

  useEffect(() => {
    onChange && onChange(map(items, 'value'));
  }, [items]);

  const handleAdd = () => {
    if (value) {
      setItems([...(items as Item[]), value as Item]);
      setValue(null);
    }
  };

  const handleDeleted = (item: Item) => () => {
    setItems(items.filter((i) => i.value !== item.value));
  };

  const selectedIds = map(items, 'value');

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="flex-end">
        {tagsInsideInput ? (
          <MuiAutocomplete
            multiple
            fullWidth
            options={dataSource}
            placeholder={placeholder}
            getOptionLabel={(option: Item) => option.label}
            forcePopupIcon={popUpIconEnabled}
            getOptionDisabled={(option) => {
              return selectedIds.includes(option.value);
            }}
            onChange={(event, newValue: any) => {
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                disabled={disabled}
                {...params}
                {...{ label, placeholder }}
                helperText={null}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            )}
            renderTags={(value: any, getTagProps) =>
              map(value, (item, index: any) => (
                <Chip
                  label={item.label}
                  color="primary"
                  {...getTagProps({ index })}
                  onDelete={handleDeleted(item)}
                  key={item.value}
                />
              ))
            }
          />
        ) : (
          <MuiAutocomplete
            fullWidth
            options={dataSource}
            value={value}
            placeholder={placeholder}
            getOptionLabel={(option: Item) => option.label}
            forcePopupIcon={popUpIconEnabled}
            getOptionDisabled={(option) => {
              return selectedIds.includes(option.value);
            }}
            onChange={(event, newValue: any) => {
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                disabled={disabled}
                {...params}
                {...{ label, placeholder }}
                helperText={null}
              />
            )}
          />
        )}

        {buttonEnabled && (
          <Button onClick={handleAdd} sx={{ height: 40 }}>
            Add
          </Button>
        )}
      </Stack>
      {!tagsInsideInput && (
        <Stack
          direction="row"
          mt={1}
          spacing={0.5}
          className={classes.selectedItems}
        >
          {map(items, (item) => (
            <Chip
              key={item.value}
              label={item.label}
              color="primary"
              onDelete={handleDeleted(item)}
            />
          ))}
        </Stack>
      )}
    </>
  );
};
