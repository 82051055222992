import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';

interface Options {
  createUuid?: string;
  uuid?: string;
  validationSchema?: any | (() => any);
  initialValues?: FormikValues;
  onSuccess?: (values: FormikValues) => void;
  onError?: (error: any) => void;
  normalizeValues?: (values: FormikValues) => any;
  normalizeSetValues?: (values: FormikValues) => any;
  onSubmit?: (values: FormikValues) => any;
  extraParams?: any;
}

export const useEditResource = (
  api,
  {
    createUuid,
    uuid,
    validationSchema,
    initialValues,
    onSuccess,
    onError,
    normalizeValues = (values) => values,
    normalizeSetValues = (values) => values,
    onSubmit,
    extraParams
  }: Options
) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<Record<string, any>>({});

  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: initialValues || {},
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: onSubmit
      ? onSubmit
      : async (values) => {
          setIsLoading(true);
          setErrors({});

          const normalizedValues = normalizeValues(values);

          let actionPromise: Promise<void>;
          const payload = {
            uuid: isEdit ? uuid : createUuid ? createUuid : uuidv4(),
            ...normalizedValues
          };

          if (isEdit) {
            actionPromise = api.update(payload);
          } else {
            actionPromise = api.create(payload);
          }

          actionPromise
            .then(() => {
              setIsLoading(false);
              onSuccess && onSuccess(payload);
            })
            .catch((err) => {
              const errorDescription = get(
                err,
                'response.data.errorDescription.fields'
              );

              if (errorDescription) {
                setErrors(errorDescription);
              }

              setIsLoading(false);
              onError && onError(err);
            });
        }
  });

  useEffect(() => {
    setErrors(validationErrors as Record<string, string>);
  }, [validationErrors]);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);
      api
        .findOne(uuid, extraParams)
        .then((data) => {
          setData(data);
          setValues(normalizeSetValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid]);

  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };

  const getFieldProps = (fieldName: string) => {
    return {
      errors: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      disabled: isLoading,
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  const getFieldTouchedError = (fieldName: string | string[] | number[]) => {
    return get(touched, fieldName, false) && get(errors, fieldName);
  };

  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    touched,
    setFieldValue,
    getFieldTouchedError,
    setErrors,
    getFieldProps,
    getFieldValue,
    setFieldTouched
  };
};
