import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { find, get, map } from 'lodash';
import { addMinutes, format } from 'date-fns';
import { Card } from '../../components/cards';
import {
  AppointmentTypeField,
  ClientField,
  DatePicker,
  DurationField,
  EmployeeField,
  PlaceServiceField,
  TextField,
  TimePicker
} from '../../components/fields';
import { AuthorizationForm } from './AuthorizationForm';
import isEmpty from 'lodash/isEmpty';
import { SelectField } from '../../components/fields/select-field/SelectField';
import { LocationApi } from '../../core/http/requests/location.api';
import { useClientLocations } from '../../core/hooks/useClientLocations';

const locationApi = new LocationApi();

interface Props {
  values?: any;
  setFieldValue: (name: string, value: any) => void;
  getFieldTouchedError?: (field: string) => string;
  clientName?: string;
  isLoading?: boolean;
  setFieldTouched?: (name: string, value: any) => void;
  handleBlur?: (name: string) => void;
  setSelectedCity?: (arg: any) => void;
  setSelectedState?: (arg: any) => void;
}

export const AppointmentGeneralForm: FC<Props> = ({
  values,
  getFieldTouchedError,
  setFieldValue,
  isLoading,
  setFieldTouched,
  handleBlur,
  setSelectedCity,
  setSelectedState,
  clientName
}) => {
  const [locations, setLocations] = useState<any>();
  const {
    startDate,
    duration,
    startTime,
    servicePlaceUuid,
    clientUuid,
    location
  } = values;

  const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  useEffect(() => {
    if (duration && startDate && startTime) {
      setFieldValue(
        'endTime',
        format(addMinutes(new Date(startDate), duration), 'hh:mm a')
      );
    }
  }, [duration]);

  const handleChangeDuration = (duration) => {
    if (duration) {
      setFieldValue('duration', duration);
    }
    setFieldValue('startTime', startDate);
    setFieldValue('endDate', addMinutes(startDate, duration));

    if (duration) {
      try {
        setFieldValue(
          'endTime',
          format(addMinutes(startDate, duration), 'hh:mm a')
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };

  const getFieldProps = (fieldName: string) => {
    return {
      disabled: isLoading,
      errors: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  const { items: clientLocationsData } = useClientLocations(clientUuid);

  useEffect(() => {
    if (!isEmpty(servicePlaceUuid)) {
      if (
        servicePlaceUuid === '32c426a9-36fa-496e-9004-f08890c1cb05' &&
        clientUuid
      ) {
        setLoadingLocations(true);
        const transformedLocations = map(
          clientLocationsData,
          ({ city, state }) => {
            return {
              label: `${state}-${city}`,
              value: `${state}-${city}`
            };
          }
        );
        setLoadingLocations(false);
        setLocations(transformedLocations);
        setIsInit(true);
      } else {
        setLoadingLocations(true);
        locationApi.findAll({ limit: 1000 }).then(({ items }) => {
          const transformedLocations = map(
            items,
            ({ state, region, city, uuid, zipCode }) => {
              return {
                label: `${state}-${city}, ${region}, ${zipCode}`,
                value: uuid
              };
            }
          );
          setLocations(transformedLocations);
          setLoadingLocations(false);
          setIsInit(true);
        });
      }
    }
    setLoadingLocations(false);
  }, [servicePlaceUuid, clientLocationsData]);

  useEffect(() => {
    if (isInit && location) {
      if (
        servicePlaceUuid === '32c426a9-36fa-496e-9004-f08890c1cb05' &&
        clientUuid
      ) {
        const correctLocation = location.split('Home, ');
        if (correctLocation && correctLocation[1]) {
          setFieldValue('locationUuid', '');
          setFieldValue('locationString', correctLocation[1]);
          const [state, city] = correctLocation[1].split('-');
          setSelectedState(state);
          setSelectedCity(city);
        }
      } else {
        const correctLocation = locations.find(
          (item) => item.label === location
        );
        if (correctLocation) {
          setFieldValue('locationString', '');
          setFieldValue('locationUuid', correctLocation.value);
        }
      }
    }
  }, [location, isInit]);

  const onChangeLocation = (location) => {
    if (!location) return;
    if (servicePlaceUuid === '32c426a9-36fa-496e-9004-f08890c1cb05') {
      setFieldValue('locationString', location);
      setFieldValue('locationUuid', '');
      const [state, city] = location.split('-');
      setSelectedState(state);
      setSelectedCity(city);
    } else {
      setFieldValue('locationUuid', location);
      setFieldValue('locationString', '');
      const selectedLocation = find(
        locations,
        ({ value }) => value === location
      );
      const [state, city] = selectedLocation.label.split(',');
      setSelectedState(state);
      setSelectedCity(city);
    }
  };

  return (
    <>
      <Card title="General Information">
        <Grid container columnSpacing={1}>
          <Grid item md={2}>
            <DatePicker label="Date*" {...getFieldProps('startDate')} />
          </Grid>
          <Grid item md={2}>
            <TimePicker
              label="Start Time"
              disabled={isLoading}
              value={values.startDate}
              onChange={(value) => {
                setFieldValue('startDate', value);
                handleChangeDuration(values.duration);
              }}
              errors={getFieldTouchedError('startDate')}
            />
          </Grid>
          <Grid item md={2}>
            <DurationField
              disabled={isLoading}
              label="Duration*"
              value={values.duration}
              onChange={handleChangeDuration}
              errors={getFieldTouchedError('duration')}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="End time"
              {...getFieldProps('endTime')}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={4}>
            {clientName ? (
              <TextField label="Client" value={clientName} disabled />
            ) : (
              <ClientField label="Client*" {...getFieldProps('clientUuid')} />
            )}
          </Grid>
          <Grid item md={4}>
            <EmployeeField
              status="active"
              placeholder="Select"
              label="First Therapist"
              required
              {...getFieldProps('primaryTherapistUuid')}
            />
          </Grid>
          <Grid item md={4}>
            <EmployeeField
              status="active"
              placeholder="Select"
              label="Secondary Therapist"
              {...getFieldProps('secondaryTherapistUuid')}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={4}>
            <AppointmentTypeField
              label="Appointment Type*"
              {...getFieldProps('typeUuid')}
            />
          </Grid>
          <Grid item md={4}>
            <PlaceServiceField
              label="Place of Service*"
              {...getFieldProps('servicePlaceUuid')}
            />
          </Grid>
          <Grid item md={4}>
            <SelectField
              label="Location"
              required
              disabled={loadingLocations}
              items={locations || []}
              placeholder="Select"
              value={values.locationUuid || values.locationString}
              onChange={onChangeLocation}
              errors={getFieldTouchedError('location')}
            />
          </Grid>
        </Grid>
      </Card>
      <AuthorizationForm {...{ values, setFieldValue }} />
      <Card>
        <TextField
          label="Note"
          multiline
          rows={4}
          helperText={null}
          {...getFieldProps('note')}
        />
      </Card>
    </>
  );
};
