import React, { ComponentType, useState, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { IconSearchAppointments } from '../../components/icons';
import { PageHeader } from '../../components/page-header';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core/utils/route.utils';
import {
  ROUTE_APPOINTMENTS_LIST,
  ROUTE_MAKE_UP_APPOINTMENTS
} from '../../constants/route.paths';
import { useDialog } from '../../core/hooks/useDialog';
import { AddNewAppointmentDialog } from './dialogs/AddNewAppointmentDialog';
import { ClientApi } from '../../core/http';
import useDebounce from '../../core/hooks/useDebounce';
import { SearchAutocomplete } from '../../components/fields';
import { PermissionView } from '../../components/permission-view';

export const AppointmentsSearch: ComponentType = () => {
  const navigate = useNavigate();
  const clientApi = new ClientApi();

  const [openAddAppointment] = useDialog(AddNewAppointmentDialog);
  const [searchValue, setSearchValue] = useState<string>('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClientUuid, setSelectedClientUuid] = useState<string>('');
  const [error, setError] = useState<string>('');
  const debouncedValue: string = useDebounce<string>(searchValue, 200);

  const handleAutocompleteChange = (event, item) => {
    if (item != null) {
      setSelectedClientUuid(item.value);
    }
  };

  const handleOpenCalendar = () => () => {
    if (selectedClientUuid) {
      navigate(url(ROUTE_APPOINTMENTS_LIST, { id: selectedClientUuid }));
    } else {
      setError('You have to select client from the list');
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      setLoading(true);
      clientApi.findAll({ search: debouncedValue }).then((response) => {
        const data = response.items.map((item) => {
          return {
            label: item.firstName + ' ' + item.lastName,
            value: item.uuid
          };
        });
        setLoading(false);
        setData(data);
      });
    }
  }, [debouncedValue]);

  return (
    <>
      <PageHeader title="Appointments" divider={false}>
        <PermissionView permission="modifyAppointments">
          <Button onClick={openAddAppointment}>Add new appointment</Button>
        </PermissionView>
        <Button
          color="secondary"
          onClick={() => {
            navigate(ROUTE_MAKE_UP_APPOINTMENTS);
          }}
        >
          Make Up appointment
        </Button>
      </PageHeader>
      <Box
        sx={{
          display: 'flex',
          pt: 5,
          justifyContent: 'center',
          height: 'calc(100vh - 180px)',
          backgroundColor: '#F9FBFE'
        }}
      >
        <Stack alignItems="center" spacing={2}>
          <Box
            sx={{
              '& .MuiSvgIcon-root': {
                width: 164,
                height: 164
              }
            }}
          >
            <IconSearchAppointments />
          </Box>
          <Typography
            variant="h4"
            sx={{ width: 244, textAlign: 'center' }}
            mt={2}
          >
            Search therapist/client to show appointment calendar
          </Typography>
          <SearchAutocomplete
            errors={error}
            helperText={error}
            placeholder="Search by Client Name, Employee Name, ID"
            onAutocompleteChange={handleAutocompleteChange}
            dataSource={data}
            loading={loading}
            getOptionLabel={(data) => data.label}
            onTextChange={(text) => {
              setSelectedClientUuid(null);
              setSearchValue(text);
            }}
          />
          <Button fullWidth size="medium" onClick={handleOpenCalendar()}>
            Show calendar
          </Button>
        </Stack>
      </Box>
    </>
  );
};
