import { AppointmentCancelTypeContainer } from '../../features/master-data/appointment-cancel-type/AppointmentCancelTypeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataAppointmentCancelType = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Appointment Cancel Types'
  })
)(AppointmentCancelTypeContainer);
