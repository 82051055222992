import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack, Button } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ROUTE_RESET_PASSWORD_SUCCESS,
  ROUTE_SIGN_IN,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { resetPassword } from '../../core/store/slices/resetPasswordSlice';
import { AppState } from '../../core/store/store';
import { BackButton } from '../../components/back-button';
import { TextField } from '../../components/fields';

export const ResetPassword: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetPasswordLoading = useSelector(
    (store: AppState) => store.resetPassword.loading
  );

  const resetPasswordError = useSelector(
    (store: AppState) => store.resetPassword.error
  );

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldError
  } = useFormik({
    initialValues: {
      email: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Email must be valid')
        .required('Email is required')
    }),
    onSubmit: (values) => {
      dispatch(
        resetPassword({
          values,
          options: {
            onSuccess: () => navigate(url(ROUTE_RESET_PASSWORD_SUCCESS)),
            onFail: () =>
              setFieldError(
                'email',
                'Please enter a valid email address or try again later.'
              )
          }
        })
      );
    }
  });
  return (
    <>
      <Box className={classes.resetPasswordRoot}>
        <Box className={classes.block}>
          <BackButton title="Login" onClick={() => navigate(ROUTE_SIGN_IN)} />
          <Typography variant="h3" mb={2} mt={2}>
            Reset password
          </Typography>
          <Typography variant="body2">
            Enter your email below to receive reset link.
          </Typography>
          <Box component="form" mt={2}>
            <TextField
              name="email"
              helperText={(touched.email && errors.email) || resetPasswordError}
              errors={(touched.email && errors.email) || resetPasswordError}
              type="string"
              value={values.email}
              onBlur={handleBlur('email')}
              onChange={handleChange('email')}
            />

            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Button
                onClick={() => handleSubmit()}
                disabled={resetPasswordLoading}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </>
  );
};
