import { PageHeader } from '../../../components/page-header';
import { TableView } from '../../../components/table-view';
import { locationColumns } from './_mockdata';
import { Button, IconButton, Stack } from '@mui/material';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { LocationEditDialog } from './LocationEditDialog';
import { IconPen, IconTrash } from '../../../components/icons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useLayout } from '../../../core/hooks/useLayout';
import useDebounce from '../../../core/hooks/useDebounce';
import { ROUTE_MASTER_DATA } from '../../../constants/route.paths';
import { LocationApi } from '../../../core/http';
import { useListResource } from '../../../core/hooks/useListResource';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { forceFetchLocation } from '../../../core/store/slices/locationSlice';
import { useEffect, useState } from 'react';

const locationApi = new LocationApi();

export const LocationContainer = () => {
  const [openEdit, closeEdit] = useDialog(LocationEditDialog);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const debouncedValue: string = useDebounce<string>(search, 200);

  useLayout({
    backButton: {
      title: 'Master Data',
      path: ROUTE_MASTER_DATA
    }
  });

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams
  } = useListResource(locationApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          dispatch(forceFetchLocation());
          closeConfirm();
        });
      }
    });
  };

  const handleOpenEdit =
    (uuid = '') =>
    () => {
      openEdit({
        uuid,
        onSuccess: () => {
          fetchData();
          dispatch(forceFetchLocation());
          closeEdit();
        }
      });
    };

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams('search', search || null);
    }
  }, [debouncedValue]);

  return (
    <>
      <PageHeader title="Locations" />

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Button onClick={handleOpenEdit()}>Add new</Button>
        <SearchField
          placeholder="Search"
          sx={{ maxWidth: 400 }}
          onChange={(value) => setSearch(value)}
        />
      </Stack>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...locationColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <IconButton size="small" onClick={handleOpenEdit(uuid)}>
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
