import { AppointmentView } from '../appointments/AppointmentView';
import { Badge } from '../../components/badge';
import { Button, Link } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import React from 'react';
import { useAppointmentDetail } from '../../core/hooks/useAppointmentDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { useClientDetail } from '../../core/hooks/useClientDetail';
import { AppointmentDto } from '../../core/http';
import { url } from '../../core';
import {
  ROUTE_CLIENT_DETAILS,
  ROUTE_MAKE_UP_APPOINTMENTS_CREATE
} from '../../constants/route.paths';
import { useLayout } from '../../core/hooks/useLayout';
import { Alert } from '../../components/alert/Alert';

export const ClientAppointmentView = () => {
  const navigate = useNavigate();
  const { clientId, uuid } = useParams();

  const { data: client } = useClientDetail({ uuid: clientId });
  const { data: appointment, isLoading } = useAppointmentDetail({
    uuid
  });

  const backUrl =
    url(ROUTE_CLIENT_DETAILS, {
      id: clientId
    }) + '#appointments';

  useLayout({
    backButton: {
      title: client?.displayName,
      path: backUrl
    }
  });

  const handleMakeUp = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_MAKE_UP_APPOINTMENTS_CREATE, {
        uuid: appointment.uuid,
        clientId
      })
    );
  };

  return (
    <>
      <PageHeader
        title="Appointment"
        meta={
          <>
            {client?.id && <Badge label={client?.id} variant={'secondary'} />}
            {appointment?.status && (
              <Badge
                label={appointment?.status}
                variant={appointment?.status}
              />
            )}
          </>
        }
      >
        {appointment?.canMakeUp && (
          <Button
            color="secondary"
            onClick={() => handleMakeUp(appointment)}
            disabled={isLoading}
          >
            Make Up
          </Button>
        )}
      </PageHeader>

      {appointment && (
        <Alert mb={1.25}>
          This appointment is for 2:30 Hour(s) and occurs weekly on MON,WED,FRI
          02/24/2021 through 01/06/2023.
          <Link variant="body2" color="primary" underline="none">
            Recurring Details
          </Link>
        </Alert>
      )}

      <AppointmentView appointment={appointment} />
    </>
  );
};
