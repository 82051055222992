import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'user-menu'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      textAlign: 'left',
      color: 'black',
      justifyContent: 'flex-start',
      fontWeight: 'normal'
    },
    selected: {
      color: 'blue'
    },
    avatar: {
      height: 32,
      width: 32,
      border: '2px solid #0052CC',
      marginRight: theme.spacing(1.5)
    }
  }),
  options
);

export default useStyles;
