import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DatePicker, TextField } from '../../../components/fields';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { HolidayApi } from '../../../core/http';
import { parseDate } from '../../../core/utils/data.utils';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const holidayApi = new HolidayApi();

export const HolidayEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(holidayApi, {
    uuid,
    initialValues: {
      title: '',
      startDate: null,
      endDate: null
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is a required field'),
      startDate: yup
        .date()
        .nullable()
        .required('Start date is a required field'),
      endDate: yup.date().nullable().required('End date is a required field')
    }),
    onSuccess,
    onError
  });

  return (
    <Dialog open onClose={popDialog}>
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
          errors={getFieldTouchedError('title')}
        />
        <Grid container columnSpacing={1}>
          <Grid item md={6}>
            <DatePicker
              label="Start date"
              maxDate={values.endDate ? parseDate(values.endDate) : null}
              value={values.startDate}
              onChange={handleChange('startDate')}
              errors={getFieldTouchedError('startDate')}
            />
          </Grid>
          <Grid item md={6}>
            <DatePicker
              label="Start date"
              minDate={values.startDate ? parseDate(values.startDate) : null}
              value={values.endDate}
              onChange={handleChange('endDate')}
              errors={getFieldTouchedError('endDate')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
