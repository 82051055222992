import { Card } from '../../../components/cards';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import {
  DatePicker,
  EmployeeAutocompleteField
} from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { timeCardColumns, timeCardsData } from '../_mockdata';
import { IconView } from '../../../components/icons';
import { useState } from 'react';
import get from 'lodash/get';
import { useDialog } from '../../../core/hooks/useDialog';
import { TimeCardViewDialog } from '../dialogs/TimeCardViewDialog';

export const TimeCards = () => {
  const [filter, setFilter] = useState({});
  const [openViewDialog] = useDialog(TimeCardViewDialog);

  const handleFilterChange = (field) => (value) => {
    setFilter({
      ...filter,
      [field]: value
    });
  };

  return (
    <>
      <Card mb={3}>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <Box width={380}>
            <EmployeeAutocompleteField
              label="Employee"
              placeholder="Search"
              size="small"
              helperText={null}
              value={get(filter, 'employee')}
              onChange={handleFilterChange('employee')}
            />
          </Box>
          <Box width={200}>
            <DatePicker
              label="From"
              size="small"
              helperText={null}
              value={get(filter, 'from', null)}
              onChange={handleFilterChange('from')}
            />
          </Box>
          <Box width={200}>
            <DatePicker
              label="To"
              size="small"
              helperText={null}
              value={get(filter, 'to', null)}
              onChange={handleFilterChange('to')}
            />
          </Box>
          <Button>Search</Button>
        </Stack>
      </Card>
      <TableView
        pagination={{
          page: 1,
          perPage: 12,
          totalPages: 10,
          total: 100
        }}
        keyExtractor={(item) => item.uuid}
        dataSource={timeCardsData}
        columns={[
          ...timeCardColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: () => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton size="small" onClick={openViewDialog}>
                    <IconView />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
