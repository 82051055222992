import { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTE_SIGN_IN } from '../../constants/route.paths';

const baseURL = process.env.REACT_APP_API_URL;

export const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  timeout: 30000,
  baseURL,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  const navigate = useNavigate();
  if (error.response.status === 401) {
    localStorage.removeItem('login');
    navigate(ROUTE_SIGN_IN);
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
