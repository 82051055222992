import { AuthLayout } from '../../../layouts/auth-layout';
import { SignIn } from '../../../features/auth/SignIn';
import { Helmet } from 'react-helmet';
export const SignInPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Sign In</title>
      </Helmet>
      <SignIn />
    </AuthLayout>
  );
};
