import get from 'lodash/get';
import { PAYABLE_TYPE, SERVICE_CODE_TYPE } from '../../../constants/enums';

export const serviceCodeColumns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    cellProps: {
      sx: {
        width: 50
      }
    },
    render: (id) => id || '-'
  },
  {
    title: 'Service Code',
    key: 'code',
    dataIndex: 'code'
  },
  {
    title: 'Service Description',
    key: 'description',
    dataIndex: 'description'
  },
  {
    title: 'Code Type',
    key: 'type',
    dataIndex: 'type',
    render: (text) => get(SERVICE_CODE_TYPE, text, '-')
  },
  {
    title: 'Payable Type',
    key: 'payableType',
    dataIndex: 'payableType',
    render: (text) => get(PAYABLE_TYPE, text, '-')
  },
  {
    title: 'Service Type',
    key: 'serviceType',
    dataIndex: 'serviceType',
    render: (serviceType) => get(serviceType, 'title', '-')
  },
  {
    title: 'Default Rate',
    key: 'defaultServiceRate',
    dataIndex: 'defaultServiceRate'
  }
];

export const serviceCodeData = [
  {
    id: 1,
    code: '97151',
    description: 'ABA Assessment',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 2,
    code: '97151',
    description: 'ABA Assessment',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 3,
    code: '97151',
    description: 'ABA Assessment',
    type: 'Non-billiable',
    payableType: 'Payable',
    serviceType: 'ABA Sup/PT/Assess',
    defaultRate: '1.0'
  },
  {
    id: 4,
    code: '97151',
    description: 'Early Intervention',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 5,
    code: 'x4303',
    description: 'Speech & Language',
    type: 'Non-billiable',
    payableType: 'Non-Payable',
    serviceType: 'ABA Sup/PT/Assess',
    defaultRate: '1.0'
  },
  {
    id: 6,
    code: 'x4303',
    description: 'Speech & Language',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '2.0'
  },
  {
    id: 7,
    code: '97151',
    description: 'ABA Assessment',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 8,
    code: '97151',
    description: 'Early Intervention',
    type: 'Billiable',
    payableType: 'Non-Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 9,
    code: '805 01',
    description: 'ABA Assessment',
    type: 'Non-billiable',
    payableType: 'Payable',
    serviceType: 'ABA Sup/PT/Assess',
    defaultRate: '1.0'
  },
  {
    id: 10,
    code: '97151',
    description: 'Speech & Language',
    type: 'Billiable',
    payableType: 'Payable',
    serviceType: 'SARC 805',
    defaultRate: '1.0'
  },
  {
    id: 11,
    code: '97151',
    description: 'Speech & Language',
    type: 'Non-billiable',
    payableType: 'Payable',
    serviceType: 'ABA Sup/PT/Assess',
    defaultRate: '1.0'
  },
  {
    id: 12,
    code: '805 01',
    description: 'Speech & Language',
    type: 'Billiable',
    payableType: 'Non-Payable',
    serviceType: 'SARC 805',
    defaultRate: '2.0'
  }
];
