import { AuthLayout } from '../../../layouts/auth-layout';
import { TwoFactor } from '../../../features/auth/TwoFactor';
import { Helmet } from 'react-helmet';

export const TwoFactorPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Two Factor Authentication</title>
      </Helmet>
      <TwoFactor />
    </AuthLayout>
  );
};
