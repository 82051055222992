import { DataTableColumn } from '../../components/table-view/TableView';

export const serviceAuthorizationColumns: DataTableColumn[] = [
  {
    title: 'Invoice #',
    dataIndex: 'invoice',
    key: 'invoice',
    sortable: true
  },
  {
    title: 'UCI #',
    dataIndex: 'uci',
    key: 'uci',
    sortable: true
  },
  {
    title: 'First Name',
    dataIndex: 'client.firstName',
    key: 'firstName',
    sortable: true,
    cellProps: {
      sx: {
        color: '#0052CC'
      }
    }
  },
  {
    title: 'Last Name',
    dataIndex: 'client.lastName',
    key: 'lastName',
    sortable: true,
    cellProps: {
      sx: {
        color: '#0052CC'
      }
    }
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode.name',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription.name',
    key: 'serviceDescription'
  },
  {
    title: 'Service M/Y',
    dataIndex: 'serviceDate',
    key: 'sserviceDate',
    sortable: true
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    sortable: true
  }
];
export const serviceAuthorizationData = [
  {
    uuid: 1,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  },
  {
    uuid: 2,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  },
  {
    uuid: 3,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  },

  {
    uuid: 4,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Jerome',
      lastName: 'Bell'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  },
  {
    uuid: 5,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Floyd',
      lastName: 'Miles'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  },
  {
    uuid: 6,
    invoice: '43423423',
    uci: '43423423',
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Jenny',
      lastName: 'Wilson'
    },
    serviceDate: '03/2022',
    invoiceDate: '03/06/2022'
  }
];

export const serviceProviderBillingDetailsColumns: DataTableColumn[] = [
  {
    title: 'Line #',
    dataIndex: 'line',
    key: 'line',
    sortable: true
  },
  {
    title: 'UCI #',
    dataIndex: 'uci',
    key: 'uci',
    sortable: true
  },
  {
    title: 'Auth #',
    dataIndex: 'auth',
    key: 'auth',
    sortable: true
  },
  {
    title: 'Auth Date',
    dataIndex: 'authDate',
    key: 'authDate',
    sortable: true
  },
  {
    title: 'Unit Type',
    dataIndex: 'unitType',
    key: 'unitType'
  },
  {
    title: 'Unit Billed',
    dataIndex: 'unitBilled',
    key: 'unitBilled'
  },
  {
    title: 'Days Attended',
    dataIndex: 'daysAttended',
    key: 'daysAttended'
  },
  {
    title: 'Gross Amount',
    dataIndex: 'grossAmount',
    key: 'grossAmount'
  },
  {
    title: 'Net Amount',
    dataIndex: 'netAmount',
    key: 'netAmount'
  }
];
export const serviceProviderBillindDetailsData = [
  {
    uuid: 1,
    line: 1,
    uci: '43423423',
    auth: '43423423',
    authDate: '03/06/2022 - 04/06/202',
    unitType: 'HD',
    unitBilled: '2.0',
    daysAttended: '2',
    grossAmount: '156.58',
    netAmount: '-'
  },

  {
    uuid: 2,
    line: 2,
    uci: '43423423',
    auth: '43423423',
    authDate: '03/2022',
    unitType: 'HD',
    unitBilled: '2.0',
    daysAttended: '2',
    grossAmount: '156.58',
    netAmount: '-'
  }
];
