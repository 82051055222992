import { useState } from 'react';

const MENU_ITEMS = [12, 24, 36, 48, 60];
export const useTablePerPage = () => {
  const [perPage, setPerPage] = useState(12);

  const onChange = (value: number) => {
    setPerPage(value);
  };

  return {
    items: MENU_ITEMS,
    perPage,
    onChange
  };
};
