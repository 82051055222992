import { NotFound } from '../components/not-found/NotFound';
import { compose, withAuthorized, withBaseLayout, withHelmet } from '../core';

export const NotFoundPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Page not found'
  })
)(NotFound);
