import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { SelectField } from '../../components/fields';
import { Card } from '../../components/cards';
import { AuthorizationApi } from '../../core/http';
import { addMinutes, format, isDate } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';

const authorizationApi = new AuthorizationApi();

export const AuthorizationForm = ({ setFieldValue, values }) => {
  const dateStart = get(values, 'dateStart');

  const handleDeleteAuthCode =
    (index: number | string, authorization) => () => {
      const authUuid = get(authorization, 'uuid');
      if (authUuid) {
        authorizationApi.delete(authUuid).then(() => {
          return;
        });
      }

      setFieldValue(
        'authorizations',
        values.authorizations.filter((_, i) => i !== index)
      );
    };

  const authorizations = values?.authorizations;

  if (isEmpty(authorizations)) {
    return null;
  }

  return (
    <Card title="Authorization & Billing">
      {map(authorizations, (code, index) => (
        <Card key={index} p={1.5} mb={0.5}>
          <Grid container alignItems="flex-end" columnSpacing={1}>
            <Grid item md={2.5}>
              <Typography variant="body2" color="#496492">
                Auth/Code
              </Typography>
              <Typography variant="body3" component="div">
                {get(code, 'serviceCode.code')} -{' '}
                {get(code, 'serviceCode.description')}
              </Typography>
              <Typography variant="body2" color="#496492" component="div">
                {isDate(dateStart)
                  ? format(dateStart, 'MM/dd/yyyy') +
                    ' - ' +
                    format(addMinutes(dateStart, code.duration), 'MM/dd/yyyy')
                  : ' '}
              </Typography>
            </Grid>
            <Grid item md={2.5}>
              <SelectField
                label="Duration*"
                items={[
                  { label: '1 hour', value: 60 },
                  { label: '30 minutes', value: 30 },
                  { label: '15 minutes', value: 15 }
                ]}
                helperText={null}
                value={code.duration}
                onChange={(value) => {
                  setFieldValue(`authorizations[${index}].duration`, value);
                }}
              />
            </Grid>
            <Grid>
              <Typography sx={{ lineHeight: '40px', ml: 1 }} variant="body3">
                {isDate(dateStart)
                  ? format(dateStart, 'hh:mm a') +
                    ' - ' +
                    format(addMinutes(dateStart, code.duration), 'hh:mm a')
                  : ''}
              </Typography>
            </Grid>
            <Grid marginLeft="auto">
              {values.authorizations.length > 1 && (
                <Button
                  color="third"
                  size="small"
                  onClick={handleDeleteAuthCode(index, code)}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        </Card>
      ))}
      {/*<Typography variant="body3" my={2} component="div">*/}
      {/*  Choose a single, or add multiple codes and authorizations along with*/}
      {/*  their estimated time spent for this appointment.*/}
      {/*</Typography>*/}
      {/*<Button onClick={handleAddMoreAuthCode}>+ Add new</Button>*/}
    </Card>
  );
};
