import { ComponentType, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Skeleton } from '@mui/material';
import { url } from '../../core';
import { useDialog } from '../../core/hooks/useDialog';
import { useLayout } from '../../core/hooks/useLayout';
import { Tabs } from '../../components/tabs';
import { PageHeader } from '../../components/page-header';
import { IconInfo } from '../../components/icons';
import { Badge } from '../../components/badge';
import { Dashboard } from './tabs/Dashboard';
import { ServiceAuthorization } from './tabs/ServiceAuthorization';
import { Appointments } from './tabs/Appointments';
import { Availability } from './tabs/Availability';
import { Files } from './tabs/Files';
import { Messages } from './tabs/Messages';
import { CareTeamMembers } from './tabs/CareTeamMembers';
import { Profile } from './tabs/Profile';
import { Logs } from './tabs/Logs';
import { TerminateUserDialog } from './dialogs/TerminateUserDialog';
import { useClientDetail } from '../../core/hooks/useClientDetail';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import {
  ROUTE_CLIENT_PROFILE_EDIT,
  ROUTE_CLIENTS
} from '../../constants/route.paths';
import { FamilyCommitment } from './tabs/FamilyCommitment';
import { PermissionView } from '../../components/permission-view';
import { useUser } from '../../core/hooks/useUser';
import { DEV_MODE } from '../../constants/app.constants';

export const ClientDetails: ComponentType = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { user } = useUser();
  const { id } = useParams();

  useLayout({
    backButton: {
      title: 'Clients',
      path: ROUTE_CLIENTS
    }
  });
  const [openTerminateUserDialog] = useDialog(TerminateUserDialog);

  const { isLoading, data } = useClientDetail({
    uuid: id
  });
  const [clientStatus, setClientStatus] = useState(data?.status || '');

  useEffect(() => {
    data && setClientStatus(data?.status);
  }, [data]);

  const headerProps = {
    divider: false,
    title:
      isLoading || !data ? (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 120 }} />
        </Stack>
      ) : (
        data.displayName
      ),
    meta:
      isLoading || !data ? (
        <>
          <Skeleton variant="text" sx={{ height: 32, width: 32 }} />
          <Skeleton variant="text" sx={{ height: 32, width: 80 }} />
        </>
      ) : (
        <>
          <Badge label={data.id} variant={'secondary'} />
          <Badge
            label={data.status}
            variant={data.status}
            RightIcon={<IconInfo />}
          />
        </>
      )
  };

  const tabs = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      content: <Dashboard />,
      permission: 'viewClients'
    },
    {
      key: 'profile',
      label: 'Profile',
      content: <Profile />,
      permission: 'viewClients'
    },
    {
      key: 'appointments',
      label: 'Appointments',
      content: <Appointments status={data?.status} />,
      permission: 'viewAppointments'
    },
    {
      key: 'clientLog',
      label: 'Client Log',
      content: <Logs />,
      permission: 'viewClients'
    },
    {
      key: 'availability',
      label: 'Availability',
      content: <Availability />,
      permission: 'viewClients'
    },
    {
      key: 'familyCommitment',
      label: 'Family Commitment',
      content: <FamilyCommitment />,
      permission: 'viewClients'
    },
    {
      key: 'serviceAuthorization',
      label: 'Service Authorization',
      content: <ServiceAuthorization />,
      permission: 'viewServiceAuthorizations'
    },
    {
      key: 'careTeamMembers',
      label: 'Care Team Members',
      content: <CareTeamMembers status={clientStatus} />,
      permission: 'viewClients'
    },
    {
      key: 'files',
      label: 'Files',
      content: <Files />,
      permission: 'viewUploadNewFiles'
    },

    {
      key: 'messages',
      label: 'Messages',
      content: <Messages />,
      permission: 'viewClients'
    }
  ];

  const enhancedTabs = tabs.filter(
    (item) => DEV_MODE || user.userRole.permissions[item.permission]
  );

  return (
    <>
      <PageHeader {...headerProps}>
        <PermissionView permission="modifyClients">
          <Button
            disabled={isLoading || data?.status === 'terminated'}
            onClick={() => navigate(url(ROUTE_CLIENT_PROFILE_EDIT, { id: id }))}
          >
            Edit profile
          </Button>
        </PermissionView>
        <PermissionView permission="modifyClients">
          <Button
            disabled={isLoading || data?.status === 'terminated'}
            color="secondary"
            onClick={() => {
              openTerminateUserDialog({
                uuid: id,
                userName: data.displayName,
                onSuccess: () => {
                  showMessage(
                    `Client ${data.displayName} terminated successfully`
                  );
                  navigate(ROUTE_CLIENTS);
                }
              });
            }}
          >
            Terminate
          </Button>
        </PermissionView>
      </PageHeader>
      <Tabs isDisabled={isLoading} hashed items={enhancedTabs} />
    </>
  );
};
