import { useEffect, useState } from 'react';
import { AppointmentApi } from '../http';
import { get } from 'lodash';

interface Options {
  profile: any; //FIXME
  onSuccess?: (values: any) => void;
  onError?: (error: any) => void;
}

export const useAppointment = ({ profile }: Options) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<any>();

  const appointmentApi = new AppointmentApi();

  useEffect(() => {
    setIsLoading(true);
    appointmentApi
      .findAll(profile)
      .then((response: any) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setErrors(e.errorMessage);
      });
  }, []);

  const pagination = {
    page: get(data, 'currentPage', 1),
    perPage: get(data, 'recordsPerPage', 10),
    totalPages: get(data, 'totalPages', 0),
    total: get(data, 'total', 0)
  };

  return {
    data: get(data, 'items', []),
    isLoading,
    errors,
    pagination
  };
};
