import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { API_CANCELLATION_REPORT } from '../api.routes';
import { CancellationReportDto } from '../dto/cancellation-report.dto';

export class CancellationReportApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<CancellationReportDto>> {
    return this.httpClient
      .get(API_CANCELLATION_REPORT, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(CancellationReportDto, data.items)
        };
      });
  }
}
