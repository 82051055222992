import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchTaskType } from '../store/slices/taskTypeSlice';

export const useTaskType = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.taskType);

  useEffect(() => {
    dispatch(fetchTaskType({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
