import React, { ComponentType, useState } from 'react';
import SimpleBar from 'simplebar-react';
import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  Toolbar,
  Typography,
  Box,
  IconButton
} from '@mui/material';
import { map } from 'lodash';
import { NavLink, useLocation, Link, useNavigate } from 'react-router-dom';
import { url } from '../../core/utils/route.utils';
import { startsWith } from 'lodash';
import {
  ROUTE_HOME,
  ROUTE_SUPER_ADMIN_COMPANIES,
  ROUTE_SUPER_ADMIN_ADMINISTRATORS,
  ROUTE_SUPER_ADMIN_USERS,
  ROUTE_SUPER_ADMIN_ROLES,
  ROUTE_SIGN_IN
} from '../../constants/route.paths';
import useStyles from './styles';
import {
  IconDashboard,
  IconRoles,
  IconSidebarToggle,
  IconClients,
  IconLogo,
  IconLogoMin,
  IconLogout
} from '../../components/icons';
import clsx from 'clsx';

const drawerWidth = 240;

const NAV_ITEMS = [
  {
    label: 'Companies',
    path: ROUTE_SUPER_ADMIN_COMPANIES,
    icon: <IconDashboard />
  },
  {
    label: 'Administrators',
    path: ROUTE_SUPER_ADMIN_ADMINISTRATORS,
    icon: <IconClients />
  }
];

const ADMIN_ITEMS = [
  {
    label: 'Super Admin Users',
    path: ROUTE_SUPER_ADMIN_USERS,
    icon: <IconClients />
  },
  {
    label: 'Super Admin Roles',
    path: ROUTE_SUPER_ADMIN_ROLES,
    icon: <IconRoles />
  }
];

export const SuperAdminSidebar: ComponentType = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const isSelected = (path: string) => {
    return startsWith(pathname, path);
  };

  const ListItemProps = (path: string): any => {
    return {
      button: true,
      selected: isSelected(path),
      classes: { root: classes.navItem, selected: classes.navItemSelected },
      component: NavLink,
      to: path
    };
  };

  const drawer = (
    <>
      <Toolbar
        className={clsx(classes.toolbar, {
          [classes.toolbarShift]: !open
        })}
      >
        {open ? (
          <>
            <Link to={ROUTE_HOME}>
              <IconLogo sx={{ width: 168, height: 28 }} />
            </Link>
            <IconButton
              size="small"
              onClick={handleDrawerToggle}
              disableRipple
              className={classes.toggleBtn}
            >
              <IconSidebarToggle />
            </IconButton>
          </>
        ) : (
          <>
            <Link to={ROUTE_HOME}>
              <IconLogoMin sx={{ width: 28, height: 28 }} />
            </Link>
            <IconButton
              size="small"
              onClick={handleDrawerToggle}
              className={clsx(classes.miniToggleButton, classes.toggleBtn)}
              disableRipple
            >
              <IconSidebarToggle sx={{ transform: 'rotate(180deg)' }} />
            </IconButton>
          </>
        )}
      </Toolbar>
      <List className={classes.nav} disablePadding component="div">
        {map(NAV_ITEMS, (item, index) => (
          <ListItem {...ListItemProps(item.path)} key={index}>
            <Box className={classes.navItemIcon}>{item.icon}</Box>
            {open && (
              <Typography
                variant="button"
                className={classes.navItemLabel}
                component="span"
              >
                {item.label}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
      <List
        className={classes.nav}
        disablePadding
        component="div"
        sx={{ mt: 3 }}
      >
        {open && (
          <Typography className={classes.navTitle}>Admin settings</Typography>
        )}

        {map(ADMIN_ITEMS, (item, index) => (
          <ListItem {...ListItemProps(item.path)} key={index}>
            <Box className={classes.navItemIcon}>{item.icon}</Box>
            {open && (
              <Typography
                variant="button"
                className={classes.navItemLabel}
                component="span"
              >
                {item.label}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
      <List
        className={classes.nav}
        disablePadding
        component="div"
        sx={{
          mt: 'auto',
          mb: 1.5
        }}
      >
        <ListItem
          button
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          onClick={() => {
            localStorage.removeItem('login');
            navigate(url(ROUTE_SIGN_IN));
          }}
        >
          <Box className={classes.navItemIcon}>
            <IconLogout />
          </Box>
          {open && (
            <Typography
              variant="button"
              className={classes.navItemLabel}
              component="span"
            >
              Logout
            </Typography>
          )}
        </ListItem>
      </List>
    </>
  );
  return (
    <Box component="nav" className={classes.root}>
      <MuiDrawer
        variant="permanent"
        open={open}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper
        }}
        sx={(theme) => ({
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            }),
            boxSizing: 'border-box',
            ...(!open
              ? {
                  overflowX: 'hidden',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                  }),
                  width: 64
                }
              : {})
          }
        })}
      >
        <SimpleBar className={classes.simpleBar}>{drawer}</SimpleBar>
      </MuiDrawer>
    </Box>
  );
};
