import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ComponentType, useRef } from 'react';
import Button from '@mui/material/Button';
import {
  ClientField,
  FileAttach,
  TextField,
  TimePicker
} from '../../../components/fields';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { Box, Divider, FormHelperText, Grid } from '@mui/material';
import { Card } from '../../../components/cards';
import { SignaturePad } from '../../../components/signature-pad';
import ReactResizeDetector from 'react-resize-detector';
import SignatureCanvas from 'react-signature-canvas';
import { useTimesheetEdit } from '../../../core/hooks/useTimesheetEdit';
import { format } from 'date-fns';
import get from 'lodash/get';

interface Props extends DialogProps {
  uuid?: string;
  onClose?: () => void;
  title?: string;
  onSuccess?: () => void;
}

export const EditTimesheetDialog: ComponentType<Props> = ({
  popDialog,
  uuid,
  title = 'Edit Timesheet',
  onSuccess
}) => {
  const refClientSign = useRef<SignatureCanvas>();
  const refProviderSign = useRef<SignatureCanvas>();

  const {
    values,
    getFieldTouchedError,
    setFieldValue,
    handleSubmit,
    appointment,
    isLoading
  } = useTimesheetEdit({
    uuid,
    refClientSign,
    refProviderSign,
    onSuccess
  });

  const clientSignatureFileUrl = get(values, 'clientSignatureFile.url');
  const clientSignatureFileUuid = get(values, 'clientSignatureFile.uuid');
  const providerSignatureFileUrl = get(values, 'providerSignatureFile.url');
  const providerSignatureFileUuid = get(values, 'providerSignatureFile.uuid');

  const handleUpdateClientSignature = () => {
    if (clientSignatureFileUuid) {
      setFieldValue('clientSignatureFileUuid', null);
      setFieldValue('clientSignatureFile', null);
      setFieldValue('clientSignatureFilled', false);
    } else if (refClientSign.current) {
      refClientSign.current && refClientSign.current.clear();
    }
  };

  const handleUpdateProviderSignature = () => {
    if (providerSignatureFileUuid) {
      setFieldValue('providerSignatureFileUuid', null);
      setFieldValue('providerSignatureFile', null);
      setFieldValue('providerSignatureFilled', false);
    } else if (refProviderSign.current) {
      refProviderSign.current && refProviderSign.current.clear();
    }
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 1180,
          maxHeight: 840
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        },
        '& .MuiOutlinedInput-root': {
          '& .Mui-disabled': {
            color: '#172B4D',
            WebkitTextFillColor: '#172B4D'
          }
        }
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={false}>
        <Card
          title={
            values.serviceName ? `Service 1  - ${values.serviceName}` : ' '
          }
        >
          <Grid container spacing={1}>
            <Grid item md={4}>
              <ClientField
                disabled
                label="Payor"
                required
                value={values.payerUuid}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <TextField
                disabled
                required
                label="Provider"
                value={values.providerName}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                disabled
                required
                label="Date of Service"
                value={values.dateOfService}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                required
                disabled
                label="Service Code"
                value={values.serviceName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={2}>
              <TimePicker
                required
                label="Time Worked"
                value={values.startDateOfService}
                onChange={(value) => setFieldValue('startDateOfService', value)}
                errors={getFieldTouchedError('startDateOfService')}
              />
            </Grid>
            <Grid item md={2} display="flex" alignItems="flex-end">
              <TimePicker
                label=" "
                value={values.endDateOfService}
                onChange={(value) => setFieldValue('endDateOfService', value)}
                errors={getFieldTouchedError('endDateOfService')}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                required
                maxLength={5}
                label="Units of Service"
                onChange={(value) => setFieldValue('unitOfService', value)}
                errors={getFieldTouchedError('unitOfService')}
                value={values.unitOfService}
              />
            </Grid>
            <Grid item md={2}>
              <TextField required disabled label="Hours" value={values.hours} />
            </Grid>
          </Grid>
          <Divider />
          <Grid container columnSpacing={1} mt={2} rowSpacing={0}>
            <Grid item md={4}>
              <TextField
                disabled
                label="Client Signature"
                value={values.clientName}
              />
              <Box mb={2}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={handleUpdateClientSignature}
                >
                  Update Signature
                </Button>
              </Box>
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <>
                    <Card height={128} p={0}>
                      {clientSignatureFileUrl ? (
                        <img
                          src={clientSignatureFileUrl}
                          alt="Client Signature"
                          width={width}
                          height={height}
                        />
                      ) : (
                        <>
                          <SignaturePad
                            penColor="black"
                            {...{ width, height, ref: refClientSign }}
                            onEnd={() => {
                              setFieldValue('clientSignatureFilled', true);
                            }}
                          />
                          <FormHelperText>
                            {getFieldTouchedError('clientSignatureFilled')}
                          </FormHelperText>
                        </>
                      )}
                    </Card>
                  </>
                )}
              </ReactResizeDetector>
            </Grid>
            <Grid item md={4} ml={4}>
              <TextField
                required
                disabled
                label="Provider Signature"
                value={values.providerName}
              />
              <Box mb={2}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={handleUpdateProviderSignature}
                >
                  Update Signature
                </Button>
              </Box>
              <ReactResizeDetector handleWidth handleHeight>
                {({ width, height }) => (
                  <Card height={128} p={0}>
                    {providerSignatureFileUrl ? (
                      <img
                        src={providerSignatureFileUrl}
                        alt="Provider Signature"
                        width={width}
                        height={height}
                      />
                    ) : (
                      <>
                        <SignaturePad
                          penColor="black"
                          {...{ width, height, ref: refProviderSign }}
                          onEnd={() => {
                            setFieldValue('providerSignatureFilled', true);
                          }}
                        />
                        <FormHelperText>
                          {getFieldTouchedError('providerSignatureFilled')}
                        </FormHelperText>
                      </>
                    )}
                  </Card>
                )}
              </ReactResizeDetector>
            </Grid>
          </Grid>
          <Divider />
          <FileAttach
            sx={{ mt: 2, background: '#F9FBFE !important' }}
            upload
            errors={getFieldTouchedError('serviceAuthorizationFileUuid')}
            createPayload={{
              description: '',
              expirationDate: format(new Date(), 'yyyy-MM-dd'),
              noExpirationDate: true,
              type: 'licence',
              rootId: appointment?.primaryTherapist?.uuid,
              rootType: 'employee'
            }}
            value={values.serviceAuthorizationFileUuid}
            onChange={(guid) => {
              setFieldValue('serviceAuthorizationFileUuid', guid);
            }}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
