import { AppointmentDto } from '../../core/http';
import { FC } from 'react';
import { Card } from '../../components/cards';
import { Grid } from '@mui/material';
import { LabeledRecord } from '../../components/labeled-record';
import {
  normalizeDate,
  normalizeDuration,
  normalizeTime,
  timeConverter
} from '../../core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

interface Props {
  appointment: AppointmentDto;
}

export const AppointmentView: FC<Props> = ({ appointment }) => {
  if (!appointment) return null;

  const durationInMinutes = appointment.durationTime
    ? Math.round(appointment.durationTime / 60000)
    : null;

  return (
    <>
      <Card title="General Information">
        <Grid container columnSpacing={1}>
          <Grid item md={2.5}>
            <LabeledRecord
              label="Date:"
              value={normalizeDate(appointment.startDate)}
            />
          </Grid>
          <Grid item md={2.5}>
            <LabeledRecord
              label="Start time:"
              value={normalizeTime(appointment.startDate)}
            />
          </Grid>
          <Grid item md={2.5}>
            <LabeledRecord label="Duration:" value={durationInMinutes} />
          </Grid>
          <Grid item md={4.5}>
            <LabeledRecord
              label="End Time:"
              value={normalizeTime(appointment.endDate)}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={2.5}>
            <LabeledRecord
              label="Client:"
              value={appointment?.client?.displayName}
            />
          </Grid>
          <Grid item md={2.5}>
            <LabeledRecord
              label="First Therapist:"
              value={appointment?.primaryTherapist?.displayName}
            />
          </Grid>
          <Grid item md={4}>
            <LabeledRecord
              label="Secondary Therapist:"
              value={appointment?.secondaryTherapist?.displayName}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={2.5}>
            <LabeledRecord
              label="Appointment Type:"
              value={get(appointment, 'type.title', '-')}
            />
          </Grid>
          <Grid item md={2.5}>
            <LabeledRecord
              label="Place of Service:"
              value={get(appointment, 'servicePlace.code', '-')}
            />
          </Grid>
          <Grid item md={7}>
            <LabeledRecord label="Conference Link:" value="-" />
          </Grid>
        </Grid>
      </Card>
      {!isEmpty(appointment.authorizations) && (
        <Card title="Authorization & Billing">
          {map(appointment.authorizations, (auth) => (
            <Grid container columnSpacing={1} key={auth.uuid}>
              <Grid item md={2.5}>
                <LabeledRecord
                  label="Auth/Code:"
                  value={
                    <>
                      {get(auth, 'serviceCode.code')} -{' '}
                      {get(auth, 'serviceCode.description')}
                    </>
                  }
                />
              </Grid>
              <Grid item md={2.5}>
                <LabeledRecord
                  label="Auth Dates:"
                  value={
                    <>
                      {normalizeDate(auth.startDate)} -{' '}
                      {normalizeDate(auth.endDate)}
                    </>
                  }
                />
              </Grid>
              <Grid item md={2.5}>
                <LabeledRecord
                  label="Duration:"
                  value={normalizeDuration(auth.startDate, auth.endDate)}
                />
              </Grid>
              <Grid item md={4.5}>
                <LabeledRecord
                  label="Time:"
                  value={timeConverter(auth.startDate, auth.endDate)}
                />
              </Grid>
            </Grid>
          ))}
        </Card>
      )}
      <Card>
        <LabeledRecord label="Notes:" value={get(appointment, 'note', '-')} />
        <LabeledRecord
          label="Cancellation Type:"
          value={get(appointment, 'cancelType.title', '-')}
        />
        <LabeledRecord
          label="Cancellation Reason:"
          value={get(appointment, 'cancelReason', '-')}
        />
      </Card>
    </>
  );
};
