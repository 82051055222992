import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { NewsForm } from '../../features/news/NewsForm';

export const NewsEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: News'
  })
)(NewsForm);
