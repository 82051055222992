import { Button, IconButton, Stack } from '@mui/material';
import { SearchField } from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { filesColumns } from '../_mockdata';
import { IconTrash } from '../../../components/icons';
import { useDialog } from '../../../core/hooks/useDialog';
import { UploadNewFileDialog } from '../dialogs/UploadNewFileDialog';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useListResource } from '../../../core/hooks/useListResource';
import { FileApi } from '../../../core/http';
import { useParams } from 'react-router';
import { get } from 'lodash';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { PermissionView } from '../../../components/permission-view';

const fileApi = new FileApi();

export const Files = () => {
  const { id } = useParams();
  const [openUploadDialog, closeUploadDialog] = useDialog(UploadNewFileDialog);
  const [openConfirmDialog, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const {
    data: filesResponse,
    isLoading,
    fetchData,
    deleteRecord,
    handleChangeParams,
    handleChangeSort
  } = useListResource(fileApi, {
    defaultParams: {
      rootType: 'client',
      rootGuid: id
    }
  });

  const filesData = get(filesResponse, 'items', []);

  const handleDelete = (uuid: string) => () => {
    openConfirmDialog({
      title: 'Delete file',
      message: 'Do you really want to delete this file?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          showMessage('File is deleted successfully');
          closeConfirm();
          fetchData();
        });
      }
    });
  };
  const handleCreate = () => () => {
    openUploadDialog({
      clientUuid: id,
      onSuccess: () => {
        closeUploadDialog();
        fetchData();
        showMessage('File was uploaded Successfully');
      },
      onError: () => {
        closeUploadDialog();
        showMessage('Something went wrong, please try again later.');
      }
    });
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <PermissionView permission="modifyUploadNewFiles">
          <Button onClick={handleCreate()}>Add new</Button>
        </PermissionView>
        <SearchField
          sx={{ width: 400 }}
          size="small"
          placeholder="Search"
          onChange={(value) => handleChangeParams('search', value)}
        />
      </Stack>
      <TableView
        onChangeSort={handleChangeSort}
        loading={isLoading}
        showEmptyState
        onRowClick={(row) => window.open(row.url, '_blank')}
        EmptyStateProps={{
          description: 'null'
        }}
        columns={[
          ...filesColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 50
            },
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <PermissionView permission="modifyUploadNewFiles">
                  <IconButton size="small" onClick={handleDelete(uuid)}>
                    <IconTrash />
                  </IconButton>
                </PermissionView>
              </Stack>
            )
          }
        ]}
        dataSource={filesData}
      />
    </>
  );
};
