import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import * as yup from 'yup';
import { ClientAvailabilityApi } from '../http/requests/client-availability.api';

interface Options {
  createUuid?: string;
  uuid?: string;
  clientUuid?: string;
  validationSchema?: any | (() => any);
  initialValues?: FormikValues;
  onSuccess?: (values: FormikValues) => void;
  onError?: (error: any) => void;
  normalizeValues?: (values: FormikValues) => any;
  normalizeSetValues?: (values: FormikValues) => any;
  onSubmit?: (values: FormikValues) => any;
}
const api = new ClientAvailabilityApi();

export const useClientAvailabilityEdit = ({
  createUuid,
  uuid,
  clientUuid,
  onSuccess,
  onError,
  normalizeValues = (values) => values,
  normalizeSetValues = (values) => values,
  onSubmit
}: Options) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<Record<string, any>>({});

  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    touched,
    handleBlur,
    setFieldValue
  } = useFormik({
    initialValues: {
      clientUuid: clientUuid,
      dateFrom: '',
      dateTo: '',
      startTime: '',
      endTime: '',
      weekday: [],
      teletherapyMode: false,
      inHomeMode: false,
      note: ''
    },
    validationSchema: yup.object().shape({
      dateFrom: yup
        .date()
        .required('Please select Start date.')
        .typeError('This is not valid date.'),
      dateTo: yup
        .date()
        .required('Please select End date.')
        .typeError('This is not valid date.'),
      startTime: yup.date().required('Please select Start time.'),
      endTime: yup.date().required('Please select End time.')
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: onSubmit
      ? onSubmit
      : async (values) => {
          setIsLoading(true);
          setErrors({});
          const normalizedValues = normalizeValues(values);
          let actionPromise: Promise<any>;
          const payload = {
            uuid: isEdit ? uuid : createUuid ? createUuid : uuidv4(),
            ...normalizedValues
          };
          if (isEdit) {
            actionPromise = api.update(payload);
          } else {
            actionPromise = api.create(payload);
          }

          actionPromise
            .then(() => {
              setIsLoading(false);
              onSuccess && onSuccess(payload);
            })
            .catch((err) => {
              const errorDescription = get(
                err,
                'response.data.errorDescription.fields'
              );

              if (errorDescription) {
                setErrors(errorDescription);
              }

              setIsLoading(false);
              onError && onError(err);
            });
        }
  });

  useEffect(() => {
    setErrors(validationErrors as Record<string, string>);
  }, [validationErrors]);

  useEffect(() => {
    if (uuid && clientUuid) {
      setIsLoading(true);
      api
        .findOne(clientUuid, uuid)
        .then((data) => {
          setData(data);
          setValues(normalizeSetValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid, clientUuid]);

  const getFieldTouchedError = (fieldName: string | string[] | number[]) => {
    return get(touched, fieldName, false) && get(errors, fieldName);
  };

  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    touched,
    setFieldValue,
    getFieldTouchedError,
    setErrors
  };
};
