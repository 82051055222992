import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { ClientApi, ClientDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const clintApi = new ClientApi();

export const fetchClientAsync = createFetchAsync(
  'client',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await clintApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchActiveClientAsync = createFetchAsync(
  'client',
  'findAllActive',
  async (params, { rejectWithValue }) => {
    try {
      return await clintApi.findAll({
        ...params,
        'filter[status][eq]': 'active'
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchClient = fetchClientAsync.asyncThunk;
export const forceFetchActiveClient = fetchActiveClientAsync.asyncThunk;

export const fetchClient = (payload) => (dispatch, getState) => {
  const {
    client: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchClient(payload));
  }
};
export const fetchActiveClient = (payload) => (dispatch, getState) => {
  const {
    client: { findAllActive }
  } = getState();

  if (!findAllActive.payload && findAllActive.loading == false) {
    dispatch(forceFetchActiveClient(payload));
  }
};

type ClientState = {
  findAll: AsyncState<FindAllResponse<ClientDto>>;
  findAllActive: AsyncState<FindAllResponse<ClientDto>>;
};

const initialState: ClientState = {
  findAll: fetchClientAsync.initialState,
  findAllActive: fetchClientAsync.initialState
};

const client = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchClientAsync.extraReducers,
    ...fetchActiveClientAsync.extraReducers
  }
});

export default client.reducer;
