import { DataTableColumn } from '../../components/table-view/TableView';
import {
  normalizeDate,
  normalizeDuration,
  timeConverter
} from '../../core/utils/data.utils';
import { addHours } from 'date-fns';
import { UserRecord } from '../../components/user-record';
import { Badge } from '../../components/badge';
import get from 'lodash/get';
import { STATUS_LABEL_BY_CODE, STATUS_VARIANT } from '../../constants/enums';
import { find } from 'lodash';

export const serviceAuthorizationColumns: DataTableColumn[] = [
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sortable: true,
    cellProps: {
      sx: {
        color: '#0052CC'
      }
    }
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sortable: true,
    cellProps: {
      sx: {
        color: '#0052CC'
      }
    }
  },
  {
    title: 'Funding Source',
    dataIndex: 'fundingSource',
    key: 'fundingSource'
  },
  {
    title: 'Unit Length',
    dataIndex: 'unitLength',
    key: 'unitLength',
    sortable: true
  },
  {
    title: 'Max (h)',
    dataIndex: 'maxHour',
    key: 'maxHour',
    sortable: true,
    render: (value) => value || '-'
  },
  {
    title: 'Work (h)',
    dataIndex: 'workHours',
    key: 'workHour',
    sortable: true
  },
  {
    title: 'Left (h)',
    dataIndex: 'leftHours',
    key: 'leftHour',
    sortable: true
  }
];

export const serviceAuthorizationsViewColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'End Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (user: any) => (
      <UserRecord
        user={{
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.avatar
        }}
        color="primary"
      />
    )
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    sortable: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (status) => (
      <Badge
        label={get(STATUS_LABEL_BY_CODE, status)}
        variant={get(STATUS_VARIANT, status)}
        size="small"
      />
    )
  }
];

export const timeCardColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sortable: true,
    render: (value) => normalizeDate(value),
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    key: 'employee',
    render: (user: any) => (
      <UserRecord
        user={{
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.avatar
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    cellProps: {
      width: 170
    },
    render: (status) => (
      <Badge
        label={get(STATUS_LABEL_BY_CODE, status)}
        variant={get(STATUS_VARIANT, status)}
        size="small"
      />
    )
  },
  {
    title: 'Total (h/day)',
    dataIndex: 'total',
    key: 'total',
    sortable: true,
    cellProps: {
      width: 120
    }
  },
  {
    title: 'Billable (h/day)',
    dataIndex: 'billable',
    key: 'billable',
    sortable: true,
    cellProps: {
      width: 135
    }
  },
  {
    title: 'Non-billable (h/day)',
    dataIndex: 'nonBillable',
    key: 'nonBillable',
    sortable: true,
    cellProps: {
      width: 165
    }
  },
  {
    title: 'OT1',
    dataIndex: 'oti1',
    key: 'oti1',
    sortable: true,
    cellProps: {
      width: 100
    }
  },
  {
    title: 'OT2',
    dataIndex: 'oti2',
    key: 'oti2',
    sortable: true,
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Unconverted (h)',
    dataIndex: 'unConverted',
    key: 'unConverted',
    sortable: true,
    cellProps: {
      width: 140
    }
  }
];

export const timeCardViewColumns: DataTableColumn[] = [
  {
    title: 'Appointment Type',
    dataIndex: 'appointmentType.name',
    key: 'appointmentType'
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode.name',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription.name',
    key: 'serviceDescription'
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    sortable: true,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'Hrs',
    dataIndex: 'hours',
    key: 'hours',
    sortable: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (status) => (
      <Badge
        label={get(STATUS_LABEL_BY_CODE, status)}
        variant={get(STATUS_VARIANT, status)}
        size="small"
      />
    )
  }
];

export const billingColumns: DataTableColumn[] = [
  {
    title: 'Therapist',
    dataIndex: 'appointment.primaryTherapist',
    key: 'therapist',
    cellProps: {
      width: 224
    },
    render: (user) => (
      <UserRecord
        user={{
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.avatar
        }}
        color="primary"
      />
    )
  },
  {
    title: 'Client ID',
    dataIndex: 'appointment.client.memberId',
    key: 'payer.memberId',
    sortable: true,
    cellProps: {
      width: 100
    }
  },
  {
    title: 'First Name',
    dataIndex: 'appointment.client.firstName',
    key: 'clientFirstName',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Last Name',
    dataIndex: 'appointment.client.lastName',
    key: 'clientLastName',
    cellProps: {
      width: 120
    }
  },
  {
    title: 'Funding Source',
    dataIndex: 'payer.fundingSource.name',
    key: 'fundingSource',
    cellProps: {
      width: 205
    }
  },
  {
    title: 'Service Code',
    dataIndex: 'authorization.serviceCode.code',
    key: 'serviceCode',
    cellProps: {
      width: 90
    }
  },
  {
    title: 'Service Description',
    dataIndex: 'authorization.serviceCode.description',
    key: 'serviceDescription',
    cellProps: {
      width: 150
    }
  },
  {
    title: 'Appointment Type',
    dataIndex: 'appointment.type.title',
    key: 'appointmentType',
    cellProps: {
      width: 205
    }
  },
  {
    title: 'Provider',
    dataIndex: 'payer.profitCenter.officeName',
    key: 'provider',
    cellProps: {
      width: 205
    }
  },
  {
    title: 'Date of Service',
    dataIndex: 'startDateOfService',
    key: 'dateOfService',
    render: (value) => normalizeDate(value),
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Worked From',
    dataIndex: 'workedFrom',
    key: 'workedFrom',
    sortable: true,
    render: (value) => normalizeDate(value),
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Worked To',
    dataIndex: 'workedTo',
    key: 'workedTo',
    sortable: true,
    render: (value) => normalizeDate(value),
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Worked (h)',
    dataIndex: 'workedHour',
    key: 'workedHour',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Status',
    dataIndex: 'appointment.status',
    key: 'status',
    sortable: true,
    cellProps: {
      width: 130
    },
    render: (status) => (
      <Badge
        label={get(STATUS_LABEL_BY_CODE, status)}
        variant={get(STATUS_VARIANT, status)}
        size="small"
      />
    )
  },
  {
    title: 'Cancel Type',
    dataIndex: 'appointment.cancelType.title',
    key: 'cancelType',
    sortable: true,
    cellProps: {
      width: 110
    },
    render: (value) => value || '-'
  },
  {
    title: 'Make Up ID',
    dataIndex: 'makeUpId',
    key: 'makeUpId',
    sortable: true,
    cellProps: {
      width: 110
    },
    render: (value) => value || '-'
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    sortable: true,
    cellProps: {
      width: 120
    },
    render: (value) => value || '-'
  }
];

export const caseLoadTackerColumns: DataTableColumn[] = [
  {
    title: 'Employee',
    dataIndex: 'employeeName',
    key: 'employeeName',
    render: (employeeName: string) => (
      <UserRecord
        user={{
          name: employeeName,
          avatar: null
        }}
        color="primary"
      />
    )
  },
  {
    title: 'Scheduled',
    dataIndex: 'hoursScheduled',
    key: 'active_hours',
    sortable: true,
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Converted',
    dataIndex: 'hoursConverted',
    key: 'converted_hours',
    sortable: true,
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Unconverted',
    dataIndex: 'hoursUnconverted',
    key: 'unconverted_hours',
    sortable: true,
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Cancellations',
    dataIndex: 'hoursCancelled',
    key: 'cancelled_hours',
    sortable: true,
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Staff Cancellations',
    dataIndex: 'staffCancellation',
    key: 'staff_cancelled_hours',
    sortable: true,
    cellProps: {
      width: 152
    }
  },
  {
    title: 'Parent Cancellations',
    dataIndex: 'parentCancellation',
    key: 'parent_cancelled_hours',
    sortable: true,
    cellProps: {
      width: 161
    }
  },
  {
    title: 'Completion Rate',
    dataIndex: 'completionRate',
    key: 'completion_rate',
    sortable: true,
    cellProps: {
      width: 140
    }
  }
];

export const clientAppointmentsColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode',
    render: (value) => value || '-'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription',
    render: (value) => value || '-'
  },
  {
    title: 'Therapist',
    dataIndex: 'primaryTherapist',
    key: 'therapist',
    render: ({ displayName }) => (
      <UserRecord
        user={{
          avatar: 'none',
          name: displayName
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'First Name',
    dataIndex: 'client.firstName',
    key: 'clientFirstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'client.lastName',
    key: 'clientLastName'
  },
  {
    title: 'Email address',
    dataIndex: 'client',
    key: 'clientEmail',
    render: (client) => {
      return (
        find(client.contacts, ({ type }) => type === 'primary')?.email || '-'
      );
    }
  },
  {
    title: 'Place of Service',
    dataIndex: 'servicePlace',
    key: 'servicePlace',
    render: (value) => value.code
  },
  {
    title: 'Duration (h)',
    dataIndex: 'duration',
    key: 'duration',
    render: (_, { startDate, endDate }) =>
      normalizeDuration(startDate, endDate),
    sortable: true
  },
  {
    title: 'Unit Length',
    dataIndex: 'unitLength',
    key: 'unitLength',
    sortable: true
  }
];

export const serviceAuthorizationData = [
  {
    uuid: 1,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '2.4',
    maxHour: '2.4',
    workHour: '2.4',
    leftHour: '2.4'
  },
  {
    uuid: 2,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.2',
    maxHour: '0.2',
    workHour: '0.2',
    leftHour: '0.2'
  },
  {
    uuid: 3,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.6',
    maxHour: '0.7',
    workHour: '0.5',
    leftHour: '0.6'
  },
  {
    uuid: 4,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Early Intervention'
    },
    client: {
      firstName: 'Jerome',
      lastName: 'Bell'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '1.6',
    maxHour: '0.3',
    workHour: '2.5',
    leftHour: '1.3'
  },
  {
    uuid: 5,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '2.4',
    maxHour: '2.4',
    workHour: '2.4',
    leftHour: '2.4'
  },
  {
    uuid: 6,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.2',
    maxHour: '0.2',
    workHour: '0.2',
    leftHour: '0.2'
  },
  {
    uuid: 7,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.6',
    maxHour: '0.7',
    workHour: '0.5',
    leftHour: '0.6'
  },
  {
    uuid: 8,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Early Intervention'
    },
    client: {
      firstName: 'Cameron',
      lastName: 'Bell'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '1.6',
    maxHour: '0.3',
    workHour: '2.5',
    leftHour: '1.3'
  },
  {
    uuid: 9,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Williamson'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '2.4',
    maxHour: '2.4',
    workHour: '2.4',
    leftHour: '2.4'
  },
  {
    uuid: 10,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Jacob',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.2',
    maxHour: '0.2',
    workHour: '0.2',
    leftHour: '0.2'
  },
  {
    uuid: 11,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Jane',
      lastName: 'Cooper'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '0.6',
    maxHour: '0.7',
    workHour: '0.5',
    leftHour: '0.6'
  },
  {
    uuid: 12,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Early Intervention'
    },
    client: {
      firstName: 'Leslie',
      lastName: 'Leslie'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    unitLength: '1.6',
    maxHour: '0.3',
    workHour: '2.5',
    leftHour: '1.3'
  }
];

export const serviceAuthorizationsViewData = [
  {
    uuid: 1,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    duration: '2.4',
    status: 'converted'
  },
  {
    uuid: 2,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '1.3',
    status: 'converted'
  },
  {
    uuid: 3,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '0.2',
    status: 'notConverted'
  },
  {
    uuid: 4,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg'
    },
    duration: '0.7',
    status: 'notConverted'
  },
  {
    uuid: 5,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    duration: '2.4',
    status: 'converted'
  },
  {
    uuid: 6,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '1.3',
    status: 'converted'
  },
  {
    uuid: 7,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '0.4',
    status: 'notConverted'
  },
  {
    uuid: 8,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg'
    },
    duration: '1.7',
    status: 'notConverted'
  },
  {
    uuid: 9,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    duration: '3.4',
    status: 'converted'
  },
  {
    uuid: 10,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Robert',
      lastName: 'Fox',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '1.3',
    status: 'converted'
  },
  {
    uuid: 11,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Marvin',
      lastName: 'McKinney',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    duration: '0.2',
    status: 'notConverted'
  },
  {
    uuid: 12,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg'
    },
    duration: '0.7',
    status: 'notConverted'
  }
];

export const timeCardsData = [
  {
    uuid: 1,
    date: new Date().toISOString(),
    employee: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    status: 'converted',
    total: '2.4',
    billable: '2.4',
    nonBillable: '2.4',
    oti1: '2.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 2,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    status: 'converted',
    total: '2.4',
    billable: '1.4',
    nonBillable: '2.1',
    oti1: '1.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 3,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    status: 'notConverted',
    total: '2.4',
    billable: '2.4',
    nonBillable: '7.4',
    oti1: '7.4',
    oti2: '7.4',
    unConverted: '2.4'
  },
  {
    uuid: 4,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    status: 'notConverted',
    total: '6.4',
    billable: '2.4',
    nonBillable: '2.4',
    oti1: '2.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 5,
    date: new Date().toISOString(),
    employee: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    status: 'converted',
    total: '2.4',
    billable: '2.3',
    nonBillable: '2.4',
    oti1: '3.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 6,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Ronald',
      lastName: 'Richards',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    status: 'converted',
    total: '0.4',
    billable: '9.3',
    nonBillable: '4.4',
    oti1: '5.2',
    oti2: '2.4',
    unConverted: '2.1'
  },
  {
    uuid: 7,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg'
    },
    status: 'notConverted',
    total: '6.4',
    billable: '2.6',
    nonBillable: '2.3',
    oti1: '1.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 8,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    status: 'notConverted',
    total: '1.4',
    billable: '1.1',
    nonBillable: '2.2',
    oti1: '2.4',
    oti2: '4.4',
    unConverted: '2.4'
  },
  {
    uuid: 9,
    date: new Date().toISOString(),
    employee: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    status: 'converted',
    total: '1.4',
    billable: '3.4',
    nonBillable: '2.1',
    oti1: '4.6',
    oti2: '2.4',
    unConverted: '2.1'
  },
  {
    uuid: 10,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Ronald',
      lastName: 'Richards',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    status: 'converted',
    total: '2.4',
    billable: '6.4',
    nonBillable: '2.4',
    oti1: '3.4',
    oti2: '2.4',
    unConverted: '2.4'
  },
  {
    uuid: 11,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg'
    },
    status: 'notConverted',
    total: '0.4',
    billable: '1.4',
    nonBillable: '3.4',
    oti1: '2.4',
    oti2: '3.4',
    unConverted: '1.4'
  },
  {
    uuid: 12,
    date: new Date().toISOString(),
    employee: {
      firstName: 'Savannah',
      lastName: 'Nguyen',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    status: 'notConverted',
    total: '1.4',
    billable: '2.4',
    nonBillable: '2.4',
    oti1: '4.4',
    oti2: '2.4',
    unConverted: '2.4'
  }
];

export const timeCardsViewData = [
  {
    uuid: 1,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 2,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Report Review'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 3,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'POD check-in meeting'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Early Intervention'
    },
    hours: '1.0',
    status: 'notConverted'
  },
  {
    uuid: 4,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Assessment'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.3',
    status: 'converted'
  },
  {
    uuid: 5,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 6,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Report Review'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 7,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'POD check-in meeting'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Early Intervention'
    },
    hours: '1.0',
    status: 'notConverted'
  },
  {
    uuid: 8,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Assessment'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    hours: '1.3',
    status: 'notConverted'
  },
  {
    uuid: 9,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 10,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: '02/28/2022'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    hours: '1.0',
    status: 'converted'
  },
  {
    uuid: 11,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Data Entry'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.0',
    status: 'notConverted'
  },
  {
    uuid: 12,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    appointmentType: {
      name: 'Supervision'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    hours: '1.3',
    status: 'notConverted'
  }
];

export const billingData = [
  {
    uuid: 1,
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    client: {
      id: 20796,
      firstName: 'Shane',
      lastName: 'Flores'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 2,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    client: {
      id: 45904,
      firstName: 'Marjorie',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'POD check-in meeting'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 3,
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Philip',
      lastName: 'McCoy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'canceled',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 4,
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Arthur',
      lastName: 'Miles'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 5,
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    client: {
      id: 20796,
      firstName: 'Shane',
      lastName: 'Flores'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 6,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    client: {
      id: 45904,
      firstName: 'Marjorie',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'POD check-in meeting'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 7,
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Philip',
      lastName: 'McCoy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'canceled',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 8,
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Arthur',
      lastName: 'Miles'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 9,
    therapist: {
      firstName: 'John',
      lastName: 'Doe',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    client: {
      id: 20796,
      firstName: 'Shane',
      lastName: 'Flores'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Emails/Phones Calls/ Scheduling'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 10,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    client: {
      id: 45904,
      firstName: 'Marjorie',
      lastName: 'Murphy'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'POD check-in meeting'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 11,
    therapist: {
      firstName: 'Marvin',
      lastName: 'McKinney',
      avatar: 'https://randomuser.me/api/portraits/men/12.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Cooper',
      lastName: 'Jane'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  },
  {
    uuid: 12,
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg'
    },
    client: {
      id: 95554,
      firstName: 'Arthur',
      lastName: 'Miles'
    },
    fundingSource: {
      name: 'San Jose Regional Center'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    appointmentType: {
      name: 'Assessment'
    },
    provider: {
      name: 'San Jose Regional Center'
    },
    dateOfService: new Date().toISOString(),
    workedFrom: new Date().toISOString(),
    workedTo: addHours(new Date(), 1).toISOString(),
    workedHour: '1.0',
    status: 'converted',
    cancelType: null,
    makeUpId: null,
    rate: 35.5
  }
];

export const caseLoadTrackerData = [
  {
    uuid: 1,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg'
    },
    scheduled: '53.3',
    converted: '64.3',
    unConverted: '33.3',
    cancellations: '2.4',
    staffCancellations: '0.4',
    parentCancellations: '2.4',
    completionRate: '100%'
  },
  {
    uuid: 2,
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    scheduled: '53.6',
    converted: '23.3',
    unConverted: '67.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.4',
    completionRate: '45%'
  },
  {
    uuid: 3,
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg'
    },
    scheduled: '33.7',
    converted: '76.2',
    unConverted: '53.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.8',
    completionRate: '67%'
  },
  {
    uuid: 4,
    therapist: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg'
    },
    scheduled: '23.3',
    converted: '44.9',
    unConverted: '10.9',
    cancellations: '2.1',
    staffCancellations: '2.9',
    parentCancellations: '2.4',
    completionRate: '77%'
  },
  {
    uuid: 5,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/7.jpg'
    },
    scheduled: '53.3',
    converted: '64.3',
    unConverted: '33.3',
    cancellations: '2.4',
    staffCancellations: '0.4',
    parentCancellations: '2.4',
    completionRate: '100%'
  },
  {
    uuid: 6,
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg'
    },
    scheduled: '53.6',
    converted: '23.3',
    unConverted: '67.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.4',
    completionRate: '45%'
  },
  {
    uuid: 7,
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/8.jpg'
    },
    scheduled: '33.7',
    converted: '76.2',
    unConverted: '53.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.8',
    completionRate: '67%'
  },
  {
    uuid: 8,
    therapist: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/men/12.jpg'
    },
    scheduled: '23.3',
    converted: '44.9',
    unConverted: '10.9',
    cancellations: '2.1',
    staffCancellations: '2.9',
    parentCancellations: '2.4',
    completionRate: '77%'
  },
  {
    uuid: 9,
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg'
    },
    scheduled: '53.3',
    converted: '64.3',
    unConverted: '33.3',
    cancellations: '2.4',
    staffCancellations: '0.4',
    parentCancellations: '2.4',
    completionRate: '100%'
  },
  {
    uuid: 10,
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/men/8.jpg'
    },
    scheduled: '53.6',
    converted: '23.3',
    unConverted: '67.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.4',
    completionRate: '45%'
  },
  {
    uuid: 11,
    therapist: {
      firstName: 'Leslie',
      lastName: 'Alexander',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg'
    },
    scheduled: '33.7',
    converted: '76.2',
    unConverted: '53.3',
    cancellations: '1.4',
    staffCancellations: '2.4',
    parentCancellations: '2.8',
    completionRate: '67%'
  },
  {
    uuid: 12,
    therapist: {
      firstName: 'Albert',
      lastName: 'Flores',
      avatar: 'https://randomuser.me/api/portraits/men/9.jpg'
    },
    scheduled: '23.3',
    converted: '44.9',
    unConverted: '10.9',
    cancellations: '2.1',
    staffCancellations: '2.9',
    parentCancellations: '2.4',
    completionRate: '77%'
  }
];

export const clientAppointmentsData = [
  {
    uuid: 1,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores',
      email: 'felicia.reid@example.com',
      address: '4517 Washington Ave. Manchester, Kentucky 39495'
    },
    duration: '2.4',
    unitLength: '2.4'
  },
  {
    uuid: 2,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/women/12.jpg'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy',
      email: 'michael.mitc@example.com',
      address: '6391 Elgin St. Celina, Delaware 10299'
    },
    duration: '1.6',
    unitLength: '3.1'
  },
  {
    uuid: 3,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Floyd',
      lastName: 'Miles',
      email: 'sara.cruz@example.com',
      address: '3891 Ranchview Dr. Richardson, California 62639'
    },
    duration: '3.7',
    unitLength: '6.1'
  },
  {
    uuid: 4,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Brooklyn',
      lastName: 'Simmons',
      avatar: 'https://randomuser.me/api/portraits/men/13.jpg'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Savannah',
      lastName: 'Nguyen',
      email: 'kenzi.lawson@example.com',
      address: '2464 Royal Ln. Mesa, New Jersey 45463'
    },
    duration: '0.9',
    unitLength: '1.7'
  },
  {
    uuid: 5,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores',
      email: 'felicia.reid@example.com',
      address: '4517 Washington Ave. Manchester, Kentucky 39495'
    },
    duration: '2.4',
    unitLength: '2.4'
  },
  {
    uuid: 6,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/women/12.jpg'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy',
      email: 'michael.mitc@example.com',
      address: '6391 Elgin St. Celina, Delaware 10299'
    },
    duration: '1.6',
    unitLength: '3.1'
  },
  {
    uuid: 7,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Floyd',
      lastName: 'Miles',
      email: 'sara.cruz@example.com',
      address: '3891 Ranchview Dr. Richardson, California 62639'
    },
    duration: '3.7',
    unitLength: '6.1'
  },
  {
    uuid: 8,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Brooklyn',
      lastName: 'Simmons',
      avatar: 'https://randomuser.me/api/portraits/men/13.jpg'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Savannah',
      lastName: 'Nguyen',
      email: 'kenzi.lawson@example.com',
      address: '2464 Royal Ln. Mesa, New Jersey 45463'
    },
    duration: '0.9',
    unitLength: '1.7'
  },
  {
    uuid: 9,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Annette',
      lastName: 'Black',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    client: {
      firstName: 'Albert',
      lastName: 'Flores',
      email: 'felicia.reid@example.com',
      address: '4517 Washington Ave. Manchester, Kentucky 39495'
    },
    duration: '2.4',
    unitLength: '2.4'
  },
  {
    uuid: 10,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Theresa',
      lastName: 'Webb',
      avatar: 'https://randomuser.me/api/portraits/women/12.jpg'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy',
      email: 'michael.mitc@example.com',
      address: '6391 Elgin St. Celina, Delaware 10299'
    },
    duration: '1.6',
    unitLength: '3.1'
  },
  {
    uuid: 11,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Dianne',
      lastName: 'Russell',
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Floyd',
      lastName: 'Miles',
      email: 'sara.cruz@example.com',
      address: '3891 Ranchview Dr. Richardson, California 62639'
    },
    duration: '3.7',
    unitLength: '6.1'
  },
  {
    uuid: 12,
    startDate: new Date().toISOString(),
    endDate: addHours(new Date(), 1).toISOString(),
    therapist: {
      firstName: 'Brooklyn',
      lastName: 'Simmons',
      avatar: 'https://randomuser.me/api/portraits/men/13.jpg'
    },
    serviceCode: {
      name: '92507'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    client: {
      firstName: 'Savannah',
      lastName: 'Nguyen',
      email: 'kenzi.lawson@example.com',
      address: '2464 Royal Ln. Mesa, New Jersey 45463'
    },
    duration: '0.9',
    unitLength: '1.7'
  }
];
