import { useState, useEffect, FC } from 'react';
import { useClient } from '../../../core/hooks/useClient';
import {
  OptionsAutocomplete,
  OptionsAutocompleteProps,
  Options
} from '../options-autocomplete';

type Props = {
  label?: string;
} & Omit<OptionsAutocompleteProps, 'options'>;

export const ClientAutocompleteField: FC<Props> = (props) => {
  const [options, setOptions] = useState<Options[]>([]);

  const { items, itemsLoading } = useClient();

  useEffect(() => {
    setOptions(
      items.map((item) => ({ label: item.displayName, value: item.uuid }))
    );
  }, [items]);

  return (
    <OptionsAutocomplete {...{ loading: itemsLoading, options }} {...props} />
  );
};
