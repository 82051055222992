import React, { ComponentType, useEffect, useState } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from './TabPanel';
import { Divider, IconButton, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconClose } from '../icons';
import { url } from '../../core/utils/route.utils';
import { ROUTE_DASHBOARDS_SELECTED } from '../../constants/route.paths';
import { filter, map } from 'lodash';

export type TabType = {
  key: string;
  label: string;
  content: any;
  isHiddenTab?: boolean;
};

interface Props {
  items: TabType[];
  divider?: boolean;
  onChange?: (key: string) => void;
  activeTabIndex?: number;
  hashed?: boolean;
  isRemovable?: boolean;
  onRemove?: (arg: any) => void;
  isDisabled?: boolean;
}

export const RemovableTabs: ComponentType<Props> = ({
  onChange,
  divider = true,
  items,
  hashed = false,
  onRemove,
  isDisabled = false
}) => {
  const navigate = useNavigate();
  const { tabs } = useParams();
  const [tabsKeys, setTabsKeys] = useState(tabs?.split(','));
  const [value, setValue] = React.useState<number>(0);
  const [data, setData] = useState(items || []);
  const { hash, pathname } = useLocation();

  const handleChange = (event: any, newValue: number) => {
    const key = data[newValue].key;
    setValue(newValue);
    onChange?.(key);
    if (hashed) {
      navigate(pathname + `#${key}`);
    }
  };

  const handleRemove = (key: string) => () => {
    const filteredData = filter(data, (item) => item.key !== key);
    const filteredKeys = map(filteredData, ({ key }) => key);
    setTabsKeys(filteredKeys);
    setData(filteredData);

    if (onRemove) {
      onRemove(key);
    }
  };

  useEffect(() => {
    setData(items);
  }, [items]);

  useEffect(() => {
    if (tabsKeys) {
      const path = url(ROUTE_DASHBOARDS_SELECTED, { tabs: tabsKeys.join(',') });
      navigate(path);
    }
  }, [tabsKeys]);

  useEffect(() => {
    if (hash && hashed) {
      const index = data.findIndex((item) => item.key === hash.slice(1));
      if (index !== -1) {
        handleChange(null, index);
      }
    }
  }, [hash]);

  const normalize = (str: string) => {
    const match = str.match(/{(.*?)}/);
    if (match && match[0]) {
      return str.replace(match[0], 'Pogosio Petrosyan');
    }
    return str;
  };

  return (
    <>
      <Box>
        <MuiTabs value={value} onChange={handleChange}>
          {data.map(({ key, label }, index) => {
            return (
              <MuiTab
                disabled={isDisabled}
                key={index}
                label={
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box>{normalize(label)}</Box>
                    <IconButton onClick={handleRemove(key)}>
                      <IconClose />
                    </IconButton>
                  </Stack>
                }
                disableRipple
              />
            );
          })}
        </MuiTabs>
        {divider && <Divider sx={{ mx: -3, mt: '-2px' }} />}
      </Box>
      {data.map((item, index) => (
        <TabPanel key={index} value={value} index={index}>
          {typeof item.content === 'function'
            ? item.content(pathname.split(':')[1])
            : item.content}
        </TabPanel>
      ))}
    </>
  );
};
