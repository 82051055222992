import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import {
  productivityData,
  caseLoadData,
  appointmentsData,
  clientsData,
  filesData
} from '../../../features/employees/_mockdata';

export const getProductivity = createAsyncThunk(
  'getProductivityAction',
  async () => {
    try {
      const data: any = await delay(500, productivityData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getAppointments = createAsyncThunk(
  'getEmployeeAppointments',
  async () => {
    try {
      const data: any = await delay(500, appointmentsData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getCaseLoad = createAsyncThunk('getCaseLoadAction', async () => {
  try {
    const data: any = await delay(500, caseLoadData);
    return data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const getClients = createAsyncThunk(
  'getEmployeeClientsAction',
  async () => {
    try {
      const data: any = await delay(500, clientsData);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getFiles = createAsyncThunk('getFilesAction', async () => {
  try {
    const data: any = await delay(500, filesData);
    return data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const employeeDashboardSlice = createSlice({
  name: 'employeeDashboardSlice',
  initialState: {
    getProductivity: {
      loading: false,
      data: null,
      error: null
    },
    getCaseLoad: {
      loading: false,
      data: null,
      error: null
    },
    getAppointments: {
      loading: false,
      data: null,
      error: null
    },
    getClients: {
      loading: false,
      data: null,
      error: null
    },
    getFiles: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductivity.pending, (state) => {
      state.getProductivity.loading = true;
      state.getProductivity.data = null;
      state.getProductivity.error = null;
    });
    builder.addCase(getProductivity.fulfilled, (state, { payload }) => {
      state.getProductivity.loading = false;
      state.getProductivity.data = payload;
      state.getProductivity.error = null;
    });
    builder.addCase(getProductivity.rejected, (state) => {
      state.getProductivity.loading = false;
      state.getProductivity.data = null;
      state.getProductivity.error = 'Something went wrong';
    });
    builder.addCase(getAppointments.pending, (state) => {
      state.getAppointments.loading = true;
      state.getAppointments.data = null;
      state.getAppointments.error = null;
    });
    builder.addCase(getAppointments.fulfilled, (state, { payload }) => {
      state.getAppointments.loading = false;
      state.getAppointments.data = payload;
      state.getAppointments.error = null;
    });
    builder.addCase(getAppointments.rejected, (state) => {
      state.getAppointments.loading = false;
      state.getAppointments.data = null;
      state.getAppointments.error = 'Something went wrong';
    });
    builder.addCase(getCaseLoad.pending, (state) => {
      state.getCaseLoad.loading = true;
      state.getCaseLoad.data = null;
      state.getCaseLoad.error = null;
    });
    builder.addCase(getCaseLoad.fulfilled, (state, { payload }) => {
      state.getCaseLoad.loading = false;
      state.getCaseLoad.data = payload;
      state.getCaseLoad.error = null;
    });
    builder.addCase(getCaseLoad.rejected, (state) => {
      state.getCaseLoad.loading = false;
      state.getCaseLoad.data = null;
      state.getCaseLoad.error = 'Something went wrong';
    });
    builder.addCase(getClients.pending, (state) => {
      state.getClients.loading = true;
      state.getClients.data = null;
      state.getClients.error = null;
    });
    builder.addCase(getClients.fulfilled, (state, { payload }) => {
      state.getClients.loading = false;
      state.getClients.data = payload;
      state.getClients.error = null;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.getClients.loading = false;
      state.getClients.data = null;
      state.getClients.error = 'Something went wrong';
    });
    builder.addCase(getFiles.pending, (state) => {
      state.getFiles.loading = true;
      state.getFiles.data = null;
      state.getFiles.error = null;
    });
    builder.addCase(getFiles.fulfilled, (state, { payload }) => {
      state.getFiles.loading = false;
      state.getFiles.data = payload;
      state.getFiles.error = null;
    });
    builder.addCase(getFiles.rejected, (state) => {
      state.getFiles.loading = false;
      state.getFiles.data = null;
      state.getFiles.error = 'Something went wrong';
    });
  }
});

export default employeeDashboardSlice.reducer;
