export const placeOfServiceColumns = [
  {
    title: 'Code',
    key: 'code',
    dataIndex: 'code',
    cellProps: {
      sx: {
        width: '20%'
      }
    }
  },
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title'
  }
];

export const placeOfServiceData = [
  { id: 1, code: 13, location: 'SARC - San Andreas Regional Center' },
  { id: 2, code: 48, location: 'GT - Telepractice' },
  { id: 3, code: 11, location: '12 - Home' },
  { id: 4, code: 10, location: '1 - Office' }
];
