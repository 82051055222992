import { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-header';
import { useLayout } from '../../core/hooks/useLayout';
import {
  ROUTE_APPOINTMENTS,
  ROUTE_APPOINTMENTS_CREATE,
  ROUTE_CLIENT_DETAILS
} from '../../constants/route.paths';
import { HorizontalStepper } from '../../components/horizontal-stepper/HorizontalStepper';
import {
  Box,
  Button,
  Stack,
  Skeleton,
  TableCell,
  TableRow
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { AvailabilityView } from '../../components/availability-view/AvailabilityView';
import { url, urlParamsToObject } from '../../core';
import { get, map, times, split, isEmpty } from 'lodash';
import { ClientApi, EmployeeApi, EmployeeDto } from '../../core/http';
import { addHours, addMinutes } from 'date-fns';
import { EmployeeAvailabilityApi } from '../../core/http/requests/employee-availability.api';

const employeeApi = new EmployeeApi();
const clientApi = new ClientApi();
const availabilityApi = new EmployeeAvailabilityApi();

export const CompareAvailability = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [primaryTherapist, setPrimaryTherapist] = useState(null);
  const [secondaryTherapist, setSecondaryTherapist] = useState(null);
  const [availabilityData, setAvailabilityData] = useState<any>();

  const params = urlParamsToObject(search);

  const clientUuid = get(params, 'clientUuid', '');
  const members = get(params, 'careTeamMembers', '');
  const employees = split(members, ',');

  useEffect(() => {
    if (search) {
      const careTeamMembers = get(params, 'careTeamMembers', '');
      const clientUuid = get(params, 'clientUuid', '');
      const durationInMinutes = get(params, 'duration', '');
      const employeeIds = split(careTeamMembers, ',');
      const startDate = get(params, 'startDate', '');
      const endDate = get(params, 'endDate', '');

      if (!isEmpty(employeeIds)) {
        setLoading(true);
        Promise.all(
          map(employeeIds, (employeeUuid) =>
            availabilityApi.findAll({ employeeUuid, clientUuid })
          )
        ).then((availabilities) => {
          Promise.all(
            map(employeeIds, (employeeUuid) => {
              return employeeApi.findOne(employeeUuid);
            })
          ).then((employees: EmployeeDto[]) => {
            clientApi.findOne(clientUuid).then((clientData) => {
              setAvailabilityData({
                duration: durationInMinutes,
                startDate,
                endDate,
                clientName: clientData.displayName,
                therapists: map(employees, (x, key) => {
                  const availability = get(availabilities, [key, 'items']);
                  const availabilityItems = map(availability, (item) => {
                    const dateFrom = new Date(item.dateFrom);
                    const dateTo = new Date(item.dateTo);
                    const [hourStart, minuteStart] = split(item.startTime, ':');
                    const [hourEnd, minuteEnd] = split(item.endTime, ':');
                    return {
                      from: addHours(
                        addMinutes(new Date(dateFrom), +minuteStart),
                        +hourStart
                      ),
                      to: addHours(
                        addMinutes(new Date(dateTo), +minuteEnd),
                        +hourEnd
                      ),
                      isBusy: item.isBusy
                    };
                  });

                  return {
                    uuid: x.uuid,
                    name: x.displayName,
                    availability: availabilityItems
                  };
                })
              });
              setLoading(false);
            });
          });
        });
      } else {
        navigate(ROUTE_APPOINTMENTS);
      }
    }
  }, []);

  useLayout({
    backButton: {
      title: 'Appointments',
      path: ROUTE_APPOINTMENTS
    }
  });

  const handleConfirm = () => {
    const primaryTherapistUuid = primaryTherapist
      ? primaryTherapist.uuid
      : null;
    const secondaryTherapistUuid = secondaryTherapist
      ? secondaryTherapist.uuid
      : null;
    navigate(
      ROUTE_APPOINTMENTS_CREATE +
        search +
        `&primaryTherapistUuid=${primaryTherapistUuid}&secondaryTherapistUuid=${secondaryTherapistUuid}`
    );
  };

  const handleEditAvailability = () => () => {
    navigate(url(ROUTE_CLIENT_DETAILS, { id: clientUuid }) + '#availability');
  };

  return (
    <>
      <PageHeader
        title="New Appointment"
        meta={
          <HorizontalStepper
            ml={3}
            activeStep={1}
            items={[
              'Add criteria',
              'Compare availability',
              'Confirm the appointment information'
            ]}
          />
        }
      >
        <Button
          color="third"
          onClick={() => {
            navigate(ROUTE_APPOINTMENTS);
          }}
        >
          Cancel
        </Button>
      </PageHeader>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <Button onClick={() => handleConfirm()}>Confirm appointment</Button>
        <Button color="secondary" onClick={handleEditAvailability()}>
          Edit client availability
        </Button>
      </Stack>
      <Box>
        {loading ? (
          <>
            <TableRow>
              <TableCell>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={12}
                  sx={{ ml: 0.875 }}
                />
              </TableCell>
              {times(10, (column) => {
                return (
                  <TableCell key={column}>
                    <Skeleton variant="text" width={74} />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={12}
                  sx={{ ml: 0.875 }}
                />
              </TableCell>
              {times(10, (column) => {
                return (
                  <TableCell key={column}>
                    <Skeleton variant="text" width={74} />
                  </TableCell>
                );
              })}
            </TableRow>
            {map(employees, () => (
              <TableRow>
                <TableCell>
                  <Skeleton
                    variant="rectangular"
                    width={120}
                    height={12}
                    sx={{ ml: 0.875 }}
                  />
                </TableCell>
                {times(10, (column) => {
                  return (
                    <TableCell key={column}>
                      <Skeleton variant="text" width={74} />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </>
        ) : (
          <>
            {availabilityData && (
              <AvailabilityView
                data={availabilityData}
                setPrimaryTherapist={setPrimaryTherapist}
                setSecondaryTherapist={setSecondaryTherapist}
                primaryTherapist={primaryTherapist}
                secondaryTherapist={secondaryTherapist}
                loading={loading}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};
