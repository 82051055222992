import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInputBase-input': {
      minHeight: 24,
      lineHeight: '24px',
      '&::placeholder': {
        color: 'black',
        opacity: 1
      }
    }
  },
  loading: {
    '& .MuiInputBase-input': {
      cursor: 'default',
      backgroundColor: '#FAFBFC',
      '&::placeholder': {
        opacity: 0.5
      }
    }
  }
}));

export default useStyles;
