import { AuthLayout } from '../../../layouts/auth-layout';
import { ConnectToAdministrator } from '../../../features/auth/ConnectToAdministrator';
import { Helmet } from 'react-helmet';

export const ConnectToAdministratorPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Connect to Administrator</title>
      </Helmet>
      <ConnectToAdministrator />
    </AuthLayout>
  );
};
