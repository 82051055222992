import { Expose, Transform } from 'class-transformer';
import { get } from 'lodash';

export class ClientServiceAuthorizationDto {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'fundingSource.uuid'))
  fundingSourceUuid: string;
}
