import { BoxProps } from '@mui/material';
import { Box } from '@mui/system';
import { ComponentType, ReactNode } from 'react';
import useStyles from './styles';

interface Props {
  label: ReactNode;
  value?: ReactNode;
  direction?: string;
  mb?: number;
  labelProps?: BoxProps;
  valueProps?: BoxProps;
}

export const LabeledRecord: ComponentType<Props> = ({
  label,
  value,
  direction = 'column',
  labelProps,
  valueProps,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      sx={{
        display: 'flex',
        flexDirection: direction
      }}
      mb={3}
      {...props}
    >
      <Box
        className={classes.label}
        sx={{ marginRight: direction === 'row' ? '8px' : 0 }}
        {...labelProps}
      >
        {label}
      </Box>
      <Box className={classes.value} {...valueProps}>
        {value}
      </Box>
    </Box>
  );
};
