import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { AppointmentTypeDto } from '../../../core/http';
import { useAppointmentCancelType } from '../../../core/hooks/useAppointmentCancelType';

export const AppointmentCancelTypeField: FC<
  Omit<SelectFieldProps, 'items'>
> = ({ value, ...props }) => {
  const { items } = useAppointmentCancelType();

  const selectItems = map(items, (item: AppointmentTypeDto) => ({
    value: item.uuid,
    label: item.title
  }));

  return <SelectField value={value || ''} items={selectItems} {...props} />;
};
