import { Chart } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const RevenueAndMarginChart = () => {
  const labels = [
    'Dec 21 Wk1',
    'Dec 21 Wk2',
    'Dec 21 Wk3',
    'Dec 21 Wk4',
    'Jan 22 Wk1',
    'Jan 22 Wk2',
    'Jan 22 Wk3',
    'Feb 22 Wk1',
    'Feb 22 Wk2',
    'Previous M2D',
    'Current M2D'
  ];
  const fakeData = [...Array(11)].map(() => Math.random() * 190000);
  const data = {
    labels: labels,
    datasets: [
      {
        type: 'line' as const,
        data: fakeData,
        fill: false,
        borderColor: '#FFCB7E',
        borderWidth: 3
      },
      {
        type: 'bar' as const,
        label: 'Actual',
        data: fakeData,
        backgroundColor: Array(11).fill('#A7E0F2')
      }
    ]
  };

  return (
    <Card
      height={640}
      title="Revenue & Margin"
      width="100%"
      ContentProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      <Chart
        type="bar"
        data={data}
        options={options}
        style={{ maxHeight: 608, width: '100%' }}
      />
    </Card>
  );
};
