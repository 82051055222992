import { FC, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DialogProps } from '../../core/providers/dialog.provider';
import { DatePicker, FileAttach, TextField } from '../../components/fields';
import { Grid } from '@mui/material';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useEditResource } from '../../core/hooks/useEditResource';
import { AnnouncementApi } from '../../core/http/requests/announcement.api';
import { get } from 'lodash';
import { EmployeeGroupField } from '../../components/fields/employee-group-field/EmployeeGroupField';

const announcementApi = new AnnouncementApi();

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}
export const AnnouncementsEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const isEdit = !!uuid;
  const [createUuid] = useState(uuidv4());
  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
    setFieldTouched,
    isLoading
  } = useEditResource(announcementApi, {
    createUuid,
    uuid,
    initialValues: {
      uuid: uuid || createUuid,
      name: '',
      description: '',
      until: '',
      end: '',
      showToUuid: '',
      startOn: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Required field'),
      startOn: yup.string().required('Required field'),
      until: yup.string().required('Required field'),
      showToUuid: yup.string().required('Required field'),
      description: yup.string().required('Required field')
    }),
    onSuccess: (values) => onSuccess && onSuccess(values),
    onError: (values) => onError && onError(values)
  });

  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };
  const getFieldTouchedError = (fieldName: string) => {
    return get(touched, fieldName) && get(errors, fieldName);
  };

  const getFieldProps = (fieldName: string) => {
    return {
      disabled: isLoading,
      errors: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 680 },
        '& .MuiFormHelperText-root': {
          minHeight: '24px !important'
        }
      }}
    >
      <DialogTitle>
        {isEdit ? 'Edit announcement' : 'Add new announcement'}
      </DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField label="Name*" {...getFieldProps('name')} />
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <DatePicker label="Start on*" {...getFieldProps('startOn')} />
          </Grid>
          <Grid item xs={6}>
            <DatePicker label="Until*" {...getFieldProps('until')} />
          </Grid>
        </Grid>
        <EmployeeGroupField
          label="Show to*"
          placeholder="Select"
          {...getFieldProps('showToUuid')}
        />
        <FileAttach mb={3} />
        <TextField
          label="Description*"
          multiline
          rows={5}
          {...getFieldProps('description')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
