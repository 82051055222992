import { Transform, Expose } from 'class-transformer';
import { get } from 'lodash';
import { CityDto } from './city.dto';
import { StateDto } from './state.dto';

export class ClientContactDto {
  @Expose()
  uuid: string;

  @Expose()
  clientUuid: string;

  @Expose()
  type: string;

  @Expose()
  relationToChild: string;

  @Expose()
  gender: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  email: string;

  @Expose()
  addressLine1: string;

  @Expose()
  addressLine2: string;

  @Expose()
  city: CityDto;

  @Expose()
  state: StateDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'city.uuid'))
  cityUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'state.uuid'))
  stateUuid: string;

  @Expose()
  zipCode: string;

  @Expose()
  primaryPhoneNumber: string;

  @Expose()
  secondaryPhoneNumber: string;

  @Expose()
  workPhoneNumber: string;

  @Expose()
  isTextNotificationsEnabled: boolean;

  @Expose()
  isEmailNotificationsEnabled: boolean;

  @Expose()
  subscriberId: string;

  @Expose()
  groupName: string;

  @Expose()
  planName: string;

  @Expose()
  payerUuid: string;

  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  isEnabledTextNotifications: boolean;
}
