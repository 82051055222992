import { DataTableColumn } from '../../components/table-view/TableView';
import { Badge } from '../../components/badge';

export const administratorsColumn: DataTableColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true
  },
  {
    title: 'Administrator Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: ({ label, value }) => (
      <Badge
        label={label}
        variant={value}
        sx={{
          fontWeight: '500 !important'
        }}
      />
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  }
];

export const administratorsData = [
  {
    id: '257229',
    name: 'Theresa Webb',
    status: { label: 'Active', value: 'active' },
    email: 'theresa.webb@gmail.com',
    phone: '(704) 555-0127'
  },
  {
    id: '321846',
    name: 'Anette Black',
    status: { label: 'Inactive', value: 'inactive' },
    email: 'anette.black@gmail.com',
    phone: '(704) 555-0227'
  },
  {
    id: '233222',
    name: 'Leslie Alexander',
    status: { label: 'Active', value: 'active' },
    email: 'theresa.webb@gmail.com',
    phone: '(704) 355-0127'
  }
];
