import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const ConvertedVsSheduled = ({
  weeklyConvertedRevenue,
  weeklyScheduledRevenue
}) => {
  const data = {
    labels: Object.keys(weeklyConvertedRevenue),
    datasets: [
      {
        axis: 'y',
        label: 'Scheduled',
        data: Object.values(weeklyScheduledRevenue),
        fill: false,
        backgroundColor: '#A8AFF2',
        borderWidth: 0
      },
      {
        axis: 'y',
        label: 'Converted',
        data: Object.values(weeklyConvertedRevenue),
        fill: false,
        backgroundColor: '#A7E0F2',
        borderWidth: 0
      }
    ]
  };

  return (
    <Card height={351} title="$ Converted vs Scheduled">
      <Box sx={{ width: '100%', height: 205 }}>
        <Bar
          options={options}
          data={data}
          style={{ maxHeight: 205, width: '100%' }}
        />
      </Box>
    </Card>
  );
};
