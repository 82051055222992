import { useEffect, useState } from 'react';
import { ClientApi, ClientDto } from '../http';

interface Options {
  uuid: string;
  onSuccess?: (values: any) => void;
  onError?: (error: any) => void;
}

export const useClientDetail = ({ uuid }: Options) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<ClientDto>();
  const clientApi = new ClientApi();

  useEffect(() => {
    setIsLoading(true);
    clientApi
      .findOne(uuid)
      .then((response: ClientDto) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setErrors(e.errorMessage);
      });
  }, []);

  return {
    data,
    isLoading,
    errors
  };
};
