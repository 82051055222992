import { MakeUpAppointmentForm } from '../../features/appointments/MakeUpAppointmentForm';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MakeUpAppointmentsFormPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Make up Appointments'
  })
)(MakeUpAppointmentForm);
