import { FundingSourceContainer } from '../../features/master-data/funding-source/FundingSourceContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataFundingSource = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Funding Sources'
  })
)(FundingSourceContainer);
