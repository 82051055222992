import { AppointmentCreate } from '../../features/appointments/AppointmentCreate';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const AppointmentCreatePage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Appointments'
  })
)(AppointmentCreate);
