import { makeStyles } from '@mui/styles';

const options = {
  name: 'date-picker'
};

const useStyles = makeStyles(
  () => ({
    root: {},
    paper: {
      border: 'none',
      boxShadow: '0px 4px 8px rgba(73, 100, 146, 0.24)',
      borderRadius: 4
    },
    popper: {
      '& .MuiDialogActions-root': {
        border: 'none',
        margin: 0,
        padding: 0
      },
      '& .MuiCalendarPicker-root>div:first-child>div:first-child': {
        fontSize: 14
      },

      '& .MuiCalendarPicker-root>div:nth-child(2)>div>div:first-child': {
        '&>span': {
          position: 'relative',
          '&:after': {
            position: 'absolute',
            lineHeight: '40px',
            background: '#fff',
            inset: 0,
            fontSize: 10,
            fontWeight: 500,
            color: '#647593'
          }
        },
        '&>span:nth-child(1):after': {
          content: '"Sun"'
        },
        '&>span:nth-child(2):after': {
          content: '"Mon"'
        },
        '&>span:nth-child(3):after': {
          content: '"Tue"'
        },
        '&>span:nth-child(4):after': {
          content: '"Wed"'
        },
        '&>span:nth-child(5):after': {
          content: '"Thu"'
        },
        '&>span:nth-child(6):after': {
          content: '"Fri"'
        },
        '&>span:nth-child(7):after': {
          content: '"Sat"'
        }
      },

      '& [role=cell] .MuiButtonBase-root ': {
        fontSize: 12,
        fontWeight: 500
      }
    },
    icon: {
      width: 24,
      height: 24,
      paddingLeft: 4,
      marginRight: -4,
      border: 0,
      borderLeftWidth: 1,
      borderColor: '#E3EAF3',
      borderStyle: 'solid'
    }
  }),
  options
);

export default useStyles;
