import { Expose, Transform } from 'class-transformer';
import { AppointmentDto, AuthorizationDto, ClientDto, FileDto } from '.';
import get from 'lodash/get';

export class TimesheetDto {
  @Expose()
  uuid: string;

  @Expose()
  authorization: AuthorizationDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'authorization.uuid'))
  authorizationUuid: string;

  @Expose()
  appointment: AppointmentDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'appointment.uuid'))
  appointmentUuid: string;

  @Expose()
  payer: ClientDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'payer.uuid'))
  payerUuid: string;

  @Expose()
  startDateOfService: string;

  @Expose()
  endDateOfService: string;

  @Expose()
  unitOfService: number;

  @Expose()
  clientSignatureFile: FileDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'clientSignatureFile.uuid'))
  clientSignatureFileUuid: string;

  @Expose()
  providerSignatureFile: FileDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'providerSignatureFile.uuid'))
  providerSignatureFileUuid: string;

  @Expose()
  serviceAuthorizationFile: FileDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'serviceAuthorizationFile.uuid'))
  serviceAuthorizationFileUuid: string;
}
