import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import {
  Checkbox,
  FileAttach,
  DatePicker,
  SelectField,
  TextField
} from '../../../components/fields';
import * as yup from 'yup';
import { FileApi } from '../../../core/http';
import { useEditResource } from '../../../core/hooks/useEditResource';
import { v4 as uuidv4 } from 'uuid';

const fileApi = new FileApi();

export const UploadNewFileDialog = ({
  popDialog,
  employeeUuid,
  onSuccess,
  onError,
  uuid
}) => {
  const [createUuid] = useState(uuidv4());
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    getFieldTouchedError,
    isLoading,
    isEdit
  } = useEditResource(fileApi, {
    createUuid,
    uuid,
    initialValues: {
      fileName: '',
      uuid: uuidv4(),
      guid: uuidv4(),
      type: '',
      expirationDate: '',
      noExpirationDate: false,
      description: '',
      rootId: employeeUuid,
      rootType: 'employee'
    },
    normalizeSetValues: (values) => {
      return { ...values, rootType: 'employee', file: values.file };
    },
    normalizeValues: (values) => {
      return { ...values, rootType: 'employee', file: values.file };
    },
    validationSchema: yup.object().shape({
      uuid: yup.string().required('File is a required field'),
      fileName: yup.string().required('File name is a required field'),
      type: yup.string().required('File type is a required field'),
      expirationDate: yup
        .string()
        .required('Expiration date is a required field')
    }),
    onSuccess: onSuccess,
    onError: onError
  });
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>{isEdit ? 'Update file' : 'Upload new file'}</DialogTitle>
      <DialogContent>
        <FileAttach
          canRemove={false}
          value={values.uuid}
          sx={{ mb: 2 }}
          errors={getFieldTouchedError('uuid')}
          onChange={(_, file) => setFieldValue('file', file)}
        />
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item md={4}>
            <SelectField
              disabled={isLoading}
              value={values.type}
              placeholder="Select type"
              label="Type"
              required
              items={[
                {
                  label: 'Certificate',
                  value: 'certificate'
                },
                {
                  label: 'Licence',
                  value: 'licence'
                }
              ]}
              errors={getFieldTouchedError('type')}
              onChange={handleChange('type')}
            />
          </Grid>
          <Grid item md={4}>
            <DatePicker
              disabled={isLoading}
              value={values.expirationDate}
              label="Expiration Date"
              required
              onChange={handleChange('expirationDate')}
              errors={getFieldTouchedError('expirationDate')}
            />
          </Grid>
          <Grid item md={4}>
            <FormControlLabel
              disabled={isLoading}
              control={
                <Checkbox
                  onChange={() =>
                    setFieldValue('noExpirationDate', !values.noExpirationDate)
                  }
                />
              }
              label={<Typography variant="body3">No Exp. Date</Typography>}
              sx={{ mt: 3.2, ml: 0 }}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              helperText={getFieldTouchedError('fileName')}
              errors={getFieldTouchedError('fileName')}
              disabled={isLoading}
              label="File name"
              required
              value={values.fileName}
              onChange={handleChange('fileName')}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              helperText={getFieldTouchedError('description')}
              errors={getFieldTouchedError('description')}
              disabled={isLoading}
              label="Description"
              multiline={true}
              rows={3}
              value={values.description}
              onChange={handleChange('description')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} disabled={isLoading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
