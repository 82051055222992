import { Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const CurrentM2dCancellations = () => {
  const data = {
    labels: [
      'Converted               ',
      'X - Rescheduled     ',
      'Cancellation            '
    ],
    datasets: [
      {
        data: [80822, 7822, 13032],
        backgroundColor: ['#FEEF68', ' #FFCB7E', '#FF967E'],
        borderWidth: 0
      }
    ]
  };
  return (
    <Card
      height={483}
      title="Current M2D Cancellation"
      width="100%"
      ContentProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      <Box
        sx={{ width: '50%', height: 483 }}
        justifyContent="center"
        display="flex"
      >
        <Doughnut
          data={data}
          options={options}
          style={{ maxHeight: 300, width: '100%' }}
        />
      </Box>
    </Card>
  );
};
