import { plainToClass } from 'class-transformer';
import {
  API_EMPLOYEE_GROUP_CREATE,
  API_EMPLOYEE_GROUP_DELETE,
  API_EMPLOYEE_GROUP_FIND_ALL,
  API_EMPLOYEE_GROUP_FIND_ONE,
  API_EMPLOYEE_GROUP_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { EmployeeGroupDto } from '../dto';
import { FindAllResponse } from '../types';

export class EmployeeGroupApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<EmployeeGroupDto>> {
    return this.httpClient
      .get(API_EMPLOYEE_GROUP_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(EmployeeGroupDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<EmployeeGroupDto> {
    return this.httpClient
      .get(API_EMPLOYEE_GROUP_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(EmployeeGroupDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<EmployeeGroupDto> {
    return this.httpClient
      .post(API_EMPLOYEE_GROUP_CREATE, data)
      .then(({ data }) => {
        return plainToClass(EmployeeGroupDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<EmployeeGroupDto> {
    return this.httpClient
      .put(API_EMPLOYEE_GROUP_UPDATE, data)
      .then(({ data }) => {
        return plainToClass(EmployeeGroupDto, data);
      });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_EMPLOYEE_GROUP_DELETE, {
      pathParams: { uuid }
    });
  }
}
