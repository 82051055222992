import useStyles from './styles';
import { FC } from 'react';
import { Typography } from '@mui/material';
import format from 'date-fns/format';

interface Props {
  date: Date;
}

export const TimeSlot: FC<Props> = ({ date }) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1">{format(date, 'hh:00 a')}</Typography>
    </div>
  );
};
