import { Expose } from 'class-transformer';

export class NewsDto {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  body: string;

  @Expose()
  visibility: string;
}
