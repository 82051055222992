import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import map from 'lodash/map';
import useStyles from './styles';
import { FC } from 'react';
import { TypographyProps } from '@mui/material/Typography';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  layout: {
    padding: {
      top: 50
    }
  },
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      rotation: -90,
      align: 'top',
      anchor: 'end',
      color: '#172B4D',
      font: {
        family: 'Inter',
        size: 12,
        weight: 500
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  scales: {
    x: {
      color: 'red',
      legend: {
        display: false
      },
      grid: {
        drawBorder: false,
        display: false
      },
      stacked: true
    },
    y: {
      display: false,
      grid: {
        display: false,
        drawBorder: false
      },
      gridLines: {
        drawBorder: false
      },
      stacked: true
    }
  }
};

const categoryPercentage = 0.5;
const barPercentage = 1;

interface Item {
  label: string;
  color: string;
  stack: string;
  data: number[];
  datalabels?: any;
}

interface Props {
  items: Item[];
  labels: string[];
  WrapProps?: BoxProps;
  ChartWrapProps?: BoxProps;
  LabelProps?: TypographyProps;
}

export const GroupedBarChart: FC<Props> = ({
  labels,
  items,
  WrapProps,
  ChartWrapProps,
  LabelProps
}) => {
  const classes = useStyles();

  const data = {
    labels,
    datasets: map(items, ({ color, ...item }) => ({
      ...item,
      backgroundColor: color,
      categoryPercentage,
      barPercentage
    }))
  };

  return (
    <Box className={classes.root} {...WrapProps}>
      <Box height={336} width="100%" mb={3} {...ChartWrapProps}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Bar options={options} data={data} width={1000} height={374} />
      </Box>

      <Stack direction="row" spacing={1} justifyContent="center">
        {map(items, (item, index) => (
          <Stack key={index} spacing={1} direction="row" alignItems="center">
            <Box
              className={classes.indicator}
              sx={{ backgroundColor: item.color }}
            />
            <Typography
              variant="body2"
              color="#647593"
              minWidth={160}
              fontWeight={500}
              {...LabelProps}
            >
              {item.label}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
