import { IconNotFound } from '../icons';
import { Box, Typography } from '@mui/material';

export const NotFound = () => {
  return (
    <Box
      sx={{
        width: 640,
        margin: '0 auto',
        display: 'flex',
        position: 'absolute',
        inset: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <IconNotFound sx={{ width: 256, height: 256, my: 3 }} />
      <Typography variant="h2" mb={1} textAlign="center">
        404 PAGE NOT FOUND
      </Typography>
      <Typography variant="body3" color="#496492" textAlign="center">
        This page doesn’t exist or was removed.
      </Typography>
    </Box>
  );
};
