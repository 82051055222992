export const API_PLACE_SERVICE_FIND_ALL = '/api/v1/appointment/service-place';
export const API_PLACE_SERVICE_FIND_ONE =
  '/api/v1/appointment/service-place/:uuid';
export const API_PLACE_SERVICE_CREATE = '/api/v1/appointment/service-place';
export const API_PLACE_SERVICE_UPDATE = '/api/v1/appointment/service-place';
export const API_PLACE_SERVICE_DELETE =
  '/api/v1/appointment/service-place/:uuid';

export const API_SERVICE_TYPE_FIND_ALL = '/api/v1/employee/service-type';
export const API_SERVICE_TYPE_FIND_ONE = '/api/v1/employee/service-type/:uuid';
export const API_SERVICE_TYPE_CREATE = '/api/v1/employee/service-type';
export const API_SERVICE_TYPE_UPDATE = '/api/v1/employee/service-type';
export const API_SERVICE_TYPE_DELETE = '/api/v1/employee/service-type/:uuid';

export const API_SERVICE_CODE_FIND_ALL = '/api/v1/appointment/service-code';
export const API_SERVICE_CODE_FIND_ONE =
  '/api/v1/appointment/service-code/:uuid';
export const API_SERVICE_CODE_CREATE = '/api/v1/appointment/service-code';
export const API_SERVICE_CODE_UPDATE = '/api/v1/appointment/service-code';
export const API_SERVICE_CODE_DELETE = '/api/v1/appointment/service-code/:uuid';

export const API_SERVICE_DESCRIPTION_FIND_ALL =
  '/api/v1/employee/service-description';
export const API_SERVICE_DESCRIPTION_FIND_ONE =
  '/api/v1/employee/service-description/:uuid';
export const API_SERVICE_DESCRIPTION_CREATE =
  '/api/v1/employee/service-description';
export const API_SERVICE_DESCRIPTION_UPDATE =
  '/api/v1/employee/service-description';
export const API_SERVICE_DESCRIPTION_DELETE =
  '/api/v1/employee/service-description/:uuid';

export const API_LOCATION_FIND_ALL = '/api/v1/location';
export const API_LOCATION_FIND_ONE = '/api/v1/location/:uuid';
export const API_LOCATION_CREATE = '/api/v1/location';
export const API_LOCATION_UPDATE = '/api/v1/location';
export const API_LOCATION_DELETE = '/api/v1/location/:uuid';

export const API_HOLIDAY_FIND_ALL = '/api/v1/holiday';
export const API_HOLIDAY_FIND_ONE = '/api/v1/holiday/:uuid';
export const API_HOLIDAY_CREATE = '/api/v1/holiday';
export const API_HOLIDAY_UPDATE = '/api/v1/holiday';
export const API_HOLIDAY_DELETE = '/api/v1/holiday/:uuid';

export const API_INTERNAL_WORK_TYPE_FIND_ALL =
  '/api/v1/employee/internal-work-type';
export const API_INTERNAL_WORK_TYPE_FIND_ONE =
  '/api/v1/employee/internal-work-type/:uuid';
export const API_INTERNAL_WORK_TYPE_CREATE =
  '/api/v1/employee/internal-work-type';
export const API_INTERNAL_WORK_TYPE_UPDATE =
  '/api/v1/employee/internal-work-type';
export const API_INTERNAL_WORK_TYPE_DELETE =
  '/api/v1/employee/internal-work-type/:uuid';

export const API_EMPLOYEE_GROUP_FIND_ALL = '/api/v1/employee/employee_group';
export const API_EMPLOYEE_GROUP_FIND_ONE =
  '/api/v1/employee/employee_group/:uuid';
export const API_EMPLOYEE_GROUP_CREATE = '/api/v1/employee/employee_group';
export const API_EMPLOYEE_GROUP_UPDATE = '/api/v1/employee/employee_group';
export const API_EMPLOYEE_GROUP_DELETE =
  '/api/v1/employee/employee_group/:uuid';

export const API_EMPLOYEE_AVAILABILITY_FIND_ALL =
  '/api/v1/employees/:employeeUuid/availability';
export const API_EMPLOYEE_AVAILABILITY_UPDATE =
  '/api/v1/employees/:employeeUuid/availability';
export const API_EMPLOYEE_AVAILABILITY_CREATE =
  '/api/v1/employees/:employeeUuid/availability';
export const API_EMPLOYEE_AVAILABILITY_FIND_ONE =
  '/api/v1/employees/:employeeUuid/availability/:uuid';
export const API_EMPLOYEE_AVAILABILITY_DELETE =
  '/api/v1/employees/:employeeUuid/availability/:uuid';

export const API_CLIENT_AVAILABILITY_FIND_ALL =
  '/api/v1/clients/:clientUuid/availability';
export const API_CLIENT_AVAILABILITY_UPDATE =
  '/api/v1/clients/:clientUuid/availability';
export const API_CLIENT_AVAILABILITY_CREATE =
  '/api/v1/clients/:clientUuid/availability';
export const API_CLIENT_AVAILABILITY_FIND_ONE =
  '/api/v1/clients/:clientUuid/availability/:uuid';
export const API_CLIENT_AVAILABILITY_DELETE =
  '/api/v1/clients/:clientUuid/availability/:uuid';

export const API_MESSAGE_FIND_ALL = '/api/v1/clients/:clientUuid/messages';
export const API_MESSAGE_UPDATE = '/api/v1/clients/:clientUuid/messages';
export const API_MESSAGE_CREATE = '/api/v1/clients/:clientUuid/messages';
export const API_MESSAGE_FIND_ONE =
  '/api/v1/clients/:clientUuid/messages/:uuid';
export const API_MESSAGE_DELETE = '/api/v1/clients/:clientUuid/messages/:uuid';

export const API_APPOINTMENT_TYPE_FIND_ALL = '/api/v1/appointment/type';
export const API_APPOINTMENT_TYPE_FIND_ONE = '/api/v1/appointment/type/:uuid';
export const API_APPOINTMENT_TYPE_CREATE = '/api/v1/appointment/type';
export const API_APPOINTMENT_TYPE_UPDATE = '/api/v1/appointment/type';
export const API_APPOINTMENT_TYPE_DELETE = '/api/v1/appointment/type/:uuid';

export const API_APPOINTMENT_CANCEL_TYPE_FIND_ALL =
  '/api/v1/appointment/cancel-type';
export const API_APPOINTMENT_CANCEL_TYPE_FIND_ONE =
  '/api/v1/appointment/cancel-type/:uuid';
export const API_APPOINTMENT_CANCEL_TYPE_CREATE =
  '/api/v1/appointment/cancel-type';
export const API_APPOINTMENT_CANCEL_TYPE_UPDATE =
  '/api/v1/appointment/cancel-type';
export const API_APPOINTMENT_CANCEL_TYPE_DELETE =
  '/api/v1/appointment/cancel-type/:uuid';

export const API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ALL =
  '/api/v1/appointment/cancel-type-status';
export const API_APPOINTMENT_CANCEL_TYPE_STATUS_FIND_ONE =
  '/api/v1/appointment/cancel-type-status/:uuid';
export const API_APPOINTMENT_CANCEL_TYPE_STATUS_CREATE =
  '/api/v1/appointment/cancel-type-status';
export const API_APPOINTMENT_CANCEL_TYPE_STATUS_UPDATE =
  '/api/v1/appointment/cancel-type-status';
export const API_APPOINTMENT_CANCEL_TYPE_STATUS_DELETE =
  '/api/v1/appointment/cancel-type-status/:uuid';

export const API_APPOINTMENT_FIND_ALL = '/api/v1/appointment/appointment';
export const API_APPOINTMENT_FIND_ONE = '/api/v1/appointment/appointment/:uuid';
export const API_APPOINTMENT_CREATE = '/api/v1/appointment/appointment';
export const API_APPOINTMENT_UPDATE = '/api/v1/appointment/appointment';
export const API_APPOINTMENT_DELETE = '/api/v1/appointment/appointment/:uuid';
export const API_APPOINTMENT_CANCEL =
  '/api/v1/appointment/appointment/:uuid/cancel';
export const API_APPOINTMENT_CONVERT =
  '/api/v1/appointment/appointment/:uuid/convert';

export const API_APPOINTMENT_CARE_TEAM_MEMBERS =
  '/api/v1/appointment/appointment/careTeam';

export const API_AUTHORIZATION_FIND_ALL = '/api/v1/appointment/authorization';
export const API_AUTHORIZATION_FIND_ONE =
  '/api/v1/appointment/authorization/:uuid';
export const API_AUTHORIZATION_CREATE = '/api/v1/appointment/authorization';
export const API_AUTHORIZATION_UPDATE = '/api/v1/appointment/authorization';
export const API_AUTHORIZATION_DELETE =
  '/api/v1/appointment/authorization/:uuid';

export const API_FUNDING_SOURCE_FIND_ALL = '/api/v1/fundingSource';
export const API_FUNDING_SOURCE_FIND_ONE = '/api/v1/fundingSource/:uuid';
export const API_FUNDING_SOURCE_CREATE = '/api/v1/fundingSource';
export const API_FUNDING_SOURCE_UPDATE = '/api/v1/fundingSource';
export const API_FUNDING_SOURCE_DELETE = '/api/v1/fundingSource/:uuid';

export const API_FUNDING_SOURCE_CONTRACT_FIND_ALL =
  '/api/v1/fundingSourceContract';
export const API_FUNDING_SOURCE_CONTRACT_FIND_ONE =
  '/api/v1/fundingSourceContract/:uuid';
export const API_FUNDING_SOURCE_CONTRACT_CREATE =
  '/api/v1/fundingSourceContract';
export const API_FUNDING_SOURCE_CONTRACT_UPDATE =
  '/api/v1/fundingSourceContract';
export const API_FUNDING_SOURCE_CONTRACT_DELETE =
  '/api/v1/fundingSourceContract/:uuid';

export const API_CLIENT_FIND_ALL = '/api/v1/client';
export const API_CLIENT_FIND_ONE = '/api/v1/client/:uuid';
export const API_CLIENT_CREATE = '/api/v1/client';
export const API_CLIENT_DELETE = '/api/v1/client/:uuid';
export const API_CLIENT_UPDATE = '/api/v1/client';
export const API_CLIENT_EXPORT = '/api/v1/client/export';

export const API_CLIENT_CHANGES = '/api/v1/changeHistory';

export const API_ORGANIZATION_FIND_ALL = '/api/v1/organization';
export const API_ORGANIZATION_FIND_ONE = '/api/v1/organization/:uuid';
export const API_ORGANIZATION_FIND_BY_CODE =
  '/api/v1/organization/by-auth-id/:code';
export const API_ORGANIZATION_CREATE = '/api/v1/organization';
export const API_ORGANIZATION_DELETE = '/api/v1/organization/:uuid';
export const API_ORGANIZATION_UPDATE = '/api/v1/organization';

export const API_LANGUAGE_FIND_ALL = '/api/v1/language';
export const API_LANGUAGE_FIND_ONE = '/api/v1/language/:uuid';
export const API_LANGUAGE_CREATE = '/api/v1/language';
export const API_LANGUAGE_DELETE = '/api/v1/language/:uuid';
export const API_LANGUAGE_UPDATE = '/api/v1/language';

export const API_TITLE_FIND_ALL = '/api/v1/employee/title';
export const API_TITLE_FIND_ONE = '/api/v1/employee/title/:uuid';
export const API_TITLE_CREATE = '/api/v1/employee/title';
export const API_TITLE_DELETE = '/api/v1/employee/title/:uuid';
export const API_TITLE_UPDATE = '/api/v1/employee/title';

export const API_PROFIT_CENTER_FIND_ALL = '/api/v1/profit_centre';
export const API_PROFIT_CENTER_FIND_ONE = '/api/v1/profit_centre/:uuid';
export const API_PROFIT_CENTER_CREATE = '/api/v1/profit_centre';
export const API_PROFIT_CENTER_DELETE = '/api/v1/profit_centre/:uuid';
export const API_PROFIT_CENTER_UPDATE = '/api/v1/profit_centre';

export const API_EMPLOYEE_FIND_ALL = '/api/v1/employee/employee';
export const API_EMPLOYEE_FIND_ONE = '/api/v1/employee/employee/:uuid';
export const API_EMPLOYEE_CREATE = '/api/v1/employee/employee';
export const API_EMPLOYEE_DELETE = '/api/v1/employee/employee/:uuid';
export const API_EMPLOYEE_UPDATE = '/api/v1/employee/employee';
export const API_EMPLOYEE_FIND_EXPIRED = '/api/v1/employee/employee-expired';

export const API_REPORTS_BILLING_FIND_ALL = '/api/v1/reports/billing';

export const API_CLIENT_CONTACT_FIND_ALL = '/api/v1/clientContact';
export const API_CLIENT_CONTACT_FIND_ONE = '/api/v1/clientContact/:uuid';
export const API_CLIENT_CONTACT_CREATE = '/api/v1/clientContact';
export const API_CLIENT_CONTACT_DELETE = '/api/v1/clientContact/:uuid';
export const API_CLIENT_CONTACT_UPDATE = '/api/v1/clientContact';
export const API_CLIENT_INSURED_PERSON_CREATE =
  '/api/v1/clientContactPersonDetail';
export const API_CLIENT_INSURED_PERSON_UPDATE =
  '/api/v1/clientContactPersonDetail';

export const API_EMPLOYEE_CONTACT_FIND_ALL = '/api/v1/employee/contact-info';
export const API_EMPLOYEE_CONTACT_FIND_ONE =
  '/api/v1/employee/contact-info/:uuid';
export const API_EMPLOYEE_CONTACT_CREATE = '/api/v1/employee/contact-info';
export const API_EMPLOYEE_CONTACT_DELETE =
  '/api/v1/employee/contact-info/:uuid';
export const API_EMPLOYEE_CONTACT_UPDATE = '/api/v1/employee/contact-info';

export const API_TIMESHEET_FIND_ALL = '/api/v1/appointment/timesheet';
export const API_TIMESHEET_FIND_ONE = '/api/v1/appointment/timesheet/:uuid';
export const API_TIMESHEET_CREATE = '/api/v1/appointment/timesheet';
export const API_TIMESHEET_UPDATE = '/api/v1/appointment/timesheet';
export const API_TIMESHEET_DELETE = '/api/v1/appointment/timesheet/:uuid';
export const API_TIMESHEET_EXPORT =
  '/api/v1/appointment/timesheet/export/timesheet.csv';

export const API_USER_ME = '/api/v1/user/me';
export const API_USER_RESET_PASSWORD_SEND_LINK =
  '/api/v1/set-link-to-reset-password';
export const API_USER_CHANGE_PASSWORD = '/api/v1/change-password';
export const API_USER_RESET_2FA = '/api/v1/user/reset-2fa';
export const API_USER_FIND_ALL = '/api/v1/user';
export const API_USER_FIND_ONE = '/api/v1/user/:uuid';
export const API_USER_CREATE = '/api/v1/user';
export const API_USER_UPDATE = '/api/v1/user';
export const API_USER_DELETE = '/api/v1/user/:uuid';

export const API_REGION_FIND_ALL = '/api/v1/region';
export const API_REGION_FIND_ONE = '/api/v1/region/:uuid';
export const API_REGION_CREATE = '/api/v1/region';
export const API_REGION_UPDATE = '/api/v1/region';
export const API_REGION_DELETE = '/api/v1/region/:uuid';
export const API_USER_LOGOUT = '/api/v1/logout';

export const API_FILE_FIND_ALL = '/api/v1/files';
export const API_FILE_FIND_ONE = '/api/v1/files/:uuid';
export const API_FILE_CREATE = '/api/v1/files';
export const API_FILE_UPDATE = '/api/v1/files';
export const API_FILE_DELETE = '/api/v1/files/:uuid';

export const API_CLIENT_INFO_THERAPY_TITLE_FIND_ALL =
  '/api/v1/client-info-therapy-title';
export const API_CLIENT_INFO_THERAPY_TITLE_FIND_ONE =
  '/api/v1/client-info-therapy-title/:uuid';
export const API_CLIENT_INFO_THERAPY_TITLE_CREATE =
  '/api/v1/client-info-therapy-title';
export const API_CLIENT_INFO_THERAPY_TITLE_UPDATE =
  '/api/v1/client-info-therapy-title';
export const API_CLIENT_INFO_THERAPY_TITLE_DELETE =
  '/api/v1/client-info-therapy-title/:uuid';

export const API_NOTIFICATION_MESSAGE_FIND_ALL = '/api/v1/notification-message';
export const API_NOTIFICATION_MESSAGE_FIND_ONE =
  '/api/v1/notification-message/:uuid';
export const API_NOTIFICATION_MESSAGE_CREATE = '/api/v1/notification-message';
export const API_NOTIFICATION_MESSAGE_UPDATE = '/api/v1/notification-message';
export const API_NOTIFICATION_MESSAGE_DELETE =
  '/api/v1/notification-message/:uuid';

export const API_REFERRAL_TYPE_FIND_ALL = '/api/v1/referral-type';
export const API_REFERRAL_TYPE_FIND_ONE = '/api/v1/referral-type/:uuid';
export const API_REFERRAL_TYPE_CREATE = '/api/v1/referral-type';
export const API_REFERRAL_TYPE_UPDATE = '/api/v1/referral-type';
export const API_REFERRAL_TYPE_DELETE = '/api/v1/referral-type/:uuid';

export const API_TASK_TYPE_FIND_ALL = '/api/v1/task-type';
export const API_TASK_TYPE_FIND_ONE = '/api/v1/task-type/:uuid';
export const API_TASK_TYPE_CREATE = '/api/v1/task-type';
export const API_TASK_TYPE_UPDATE = '/api/v1/task-type';
export const API_TASK_TYPE_DELETE = '/api/v1/task-type/:uuid';

export const API_LEAVE_DAY_FIND_ALL =
  '/api/v1/employees/:employeeUuid/leave-days';
export const API_LEAVE_DAY_FIND_ONE =
  '/api/v1/employees/:employeeUuid/leave-days/:uuid';
export const API_LEAVE_DAY_CREATE =
  '/api/v1/employees/:employeeUuid/leave-days';
export const API_LEAVE_DAY_UPDATE =
  '/api/v1/employees/:employeeUuid/leave-days';
export const API_LEAVE_DAY_DELETE =
  '/api/v1/employees/:employeeUuid/leave-days/:uuid';

export const API_ROLE_FIND_ALL = '/api/v1/user-role';
export const API_ROLE_FIND_ONE = '/api/v1/user-role/:uuid';
export const API_ROLE_CREATE = '/api/v1/user-role';
export const API_ROLE_UPDATE = '/api/v1/user-role';
export const API_ROLE_DELETE = '/api/v1/user-role/:uuid';

export const API_STATE_FIND_ALL = '/api/v1/state';
export const API_STATE_FIND_ONE = '/api/v1/state/:uuid';
export const API_STATE_CREATE = '/api/v1/state';
export const API_STATE_UPDATE = '/api/v1/state';
export const API_STATE_DELETE = '/api/v1/state/:uuid';

export const API_CITY_FIND_ALL = '/api/v1/city';
export const API_CITY_FIND_ONE = '/api/v1/city/:uuid';
export const API_CITY_CREATE = '/api/v1/city';
export const API_CITY_UPDATE = '/api/v1/city';
export const API_CITY_DELETE = '/api/v1/city/:uuid';

export const API_CLIENT_SERVICE_AUTHORIZATION_FIND_ALL =
  '/api/v1/clients/:clientUuid/authorization-service';
export const API_CLIENT_SERVICE_AUTHORIZATION_FIND_ONE =
  '/api/v1/clients/:clientUuid/:uuid';
export const API_CLIENT_SERVICE_AUTHORIZATION_CREATE =
  '/api/v1/clients/:uuid/authorization-service';
export const API_CLIENT_SERVICE_AUTHORIZATION_UPDATE =
  '/api/v1/clients/:uuid/authorization-service';
export const API_CLIENT_SERVICE_AUTHORIZATION_DELETE =
  '/api/v1/clients/:uuid/authorization-service';

export const API_CLIENT_FAMILY_COMMITMENT_FIND_ALL =
  '/api/v1/clients/:clientUuid/family-commitment';
export const API_CLIENT_FAMILY_COMMITMENT_FIND_ONE =
  '/api/v1/clients/:clientUuid/family-commitment/:uuid';
export const API_CLIENT_FAMILY_COMMITMENT_CREATE =
  '/api/v1/clients/:clientUuid/family-commitment';
export const API_CLIENT_FAMILY_COMMITMENT_UPDATE =
  '/api/v1/clients/:clientUuid/family-commitment';
export const API_CLIENT_FAMILY_COMMITMENT_DELETE =
  '/api/v1/clients/:clientUuid/family-commitment/:uuid';

export const API_SKILL_FIND_ALL = '/api/v1/skill';
export const API_SKILL_FIND_ONE = '/api/v1/skill/:uuid';
export const API_SKILL_CREATE = '/api/v1/skill';
export const API_SKILL_UPDATE = '/api/v1/skill';
export const API_SKILL_DELETE = '/api/v1/skill/:uuid';

export const API_EMPLOYEE_REMINDER_FIND_ALL = '/api/v1/reminder/employee';
export const API_EMPLOYEE_REMINDER_FIND_ONE = '/api/v1/reminder/employee/:uuid';
export const API_EMPLOYEE_REMINDER_CREATE = '/api/v1/reminder/employee';
export const API_EMPLOYEE_REMINDER_UPDATE = '/api/v1/reminder/employee';
export const API_EMPLOYEE_REMINDER_DELETE = '/api/v1/reminder/employee/:uuid';

export const API_CLIENT_REMINDER_FIND_ALL = '/api/v1/reminder/client';
export const API_CLIENT_REMINDER_FIND_ONE = '/api/v1/reminder/client/:uuid';
export const API_CLIENT_REMINDER_CREATE = '/api/v1/reminder/client';
export const API_CLIENT_REMINDER_UPDATE = '/api/v1/reminder/client';
export const API_CLIENT_REMINDER_DELETE = '/api/v1/reminder/client/:uuid';

export const API_NEWS_FIND_ALL = '/api/v1/news';
export const API_NEWS_FIND_ONE = '/api/v1/news/:uuid';
export const API_NEWS_CREATE = '/api/v1/news';
export const API_NEWS_UPDATE = '/api/v1/news';
export const API_NEWS_DELETE = '/api/v1/news/:uuid';

export const API_SERVICE_AUTHORIZATION_REPORT =
  '/api/v1/report/service-authorization-report';

export const API_CASE_LOAD_TRACKER_REPORT =
  '/api/v1/report/case-load-tracker-report';

export const API_SARS_CANCELLATION_REPORT = '/api/v1/report/sarc-cancellation';
export const API_CANCELLATION_REPORT = '/api/v1/report/cancellation';

export const API_ANNOUNCEMENTS_FIND_ALL = '/api/v1/announcement';
export const API_ANNOUNCEMENTS_FIND_ONE = '/api/v1/announcement/:uuid';
export const API_ANNOUNCEMENTS_CREATE = '/api/v1/announcement';
export const API_ANNOUNCEMENTS_UPDATE = '/api/v1/announcement';
export const API_ANNOUNCEMENTS_DELETE = '/api/v1/announcement/:uuid';

export const API_LEADERSHIP_DASHBOARD = '/api/v1/leadership/dashboard';

export const API_EMPLOYEE_ABSENCE_SCHEDULE_FIND_ALL =
  '/api/v1/leadership/employee-absence';
