import { Box } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const MonthToDateRevenue = ({ monthToDateRevenue }) => {
  const data = {
    labels: monthToDateRevenue.map((item) => item.code),
    datasets: [
      {
        data: monthToDateRevenue.map((item) => +item.value),
        backgroundColor: ['#FFCB7E', '#FEEF68', '#A7E0F2', '#FF967E'],
        borderWidth: 0
      }
    ]
  };
  return (
    <Card
      height={483}
      title="Month to Date $ Revenue"
      width="100%"
      ContentProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      <Box sx={{ width: '50%' }} justifyContent="center" display="flex">
        <Doughnut data={data} options={options} />
      </Box>
    </Card>
  );
};
