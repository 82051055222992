import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';

interface Props extends Omit<SelectFieldProps, 'items'> {
  items: any;
}

export const EnumField: FC<Props> = ({ items: enumItems, value, ...props }) => {
  const items = map(enumItems, (label, value) => ({ label, value }));

  return <SelectField value={value || ''} items={items} {...props} />;
};
