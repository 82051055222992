import { useState } from 'react';
import get from 'lodash/get';

interface Props {
  initialValue?: Record<string, any>;
  onSearch: (values: Record<string, any>) => void;
}
export const useFilter = ({ onSearch, initialValue = {} }: Props) => {
  const [filters, setFilters] = useState(initialValue);

  const setFilterValue = (key) => (e) => {
    const targetValue = get(e, 'target.value');
    let value = null;
    if (targetValue || targetValue === '') {
      value = targetValue;
    } else {
      value = e;
    }

    setFilters((prevState) => ({ ...prevState, [key]: value || null }));
  };

  const getFilterValue = (key, defaultValue = undefined) =>
    get(filters, key, defaultValue);

  const handleSearch = () => {
    onSearch && onSearch(filters);
  };

  const getFilterFieldProps = (fieldName) => {
    return {
      value: getFilterValue(fieldName),
      onChange: setFilterValue(fieldName)
    };
  };

  return {
    filters,
    setFilterValue,
    getFilterValue,
    handleSearch,
    getFilterFieldProps
  };
};
