import { BillingDetails } from '../../features/billing/BillingDetails';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const BillingDetailsPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Billing'
  })
)(BillingDetails);
