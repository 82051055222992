import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';

export class ServiceCodeDto {
  @Expose()
  uuid: string;

  @Expose()
  type: string;

  @Expose()
  code: string;

  @Expose()
  description: string;

  @Expose()
  enforceRate: string;

  @Expose()
  calculationType: string;

  @Expose()
  defaultUnits: number;

  @Expose()
  minutesPerUnit: number;

  @Expose()
  round: number;

  @Expose()
  defaultServiceRate: number;

  @Expose()
  payableType: string;

  @Expose()
  defaultMinutes: number;

  @Expose()
  modifires: string;

  @Expose()
  providerSignature: string;

  @Expose()
  clientSignature: string;

  @Expose()
  clientSignatureFrom: string;

  @Expose()
  serviceLocation: string;

  @Expose()
  serviceAddress: string;

  @Expose()
  procedureInfo: string;

  @Expose()
  serviceNotes: string;

  @Expose()
  adminNotes: string;

  @Expose()
  requiresAuthorization: string;

  @Expose()
  enforceSingleTimesheetConversion: boolean;

  @Expose()
  requiresScheduleConversion: boolean;

  @Expose()
  restrictEarlyTimesheetConversion: boolean;

  @Expose()
  lockGracePeriod: number;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform((_, obj) =>
    get(obj, 'servicePlace.uuid', get(obj, 'servicePlaceUuid'))
  )
  servicePlaceUuid: string;

  @Expose()
  @Transform((_, obj) =>
    get(obj, 'serviceType.uuid', get(obj, 'serviceTypeUuid'))
  )
  serviceTypeUuid: string;

  @Expose()
  get displayName() {
    return `${this.code} - ${this.description}`;
  }
}
