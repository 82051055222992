import { FC } from 'react';
import { TableView } from '../../../components/table-view';
import { serviceAuthorizationColumns } from '../_mockdata';
import { Card } from '../../../components/cards';
import {
  ClientAutocompleteField,
  DatePicker,
  SelectField,
  ServiceCodeMultipleField
} from '../../../components/fields';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import get from 'lodash/get';
import { IconView } from '../../../components/icons';
import { useDialog } from '../../../core/hooks/useDialog';
import { ServiceAuthorizationViewDialog } from '../dialogs/ServiceAuthorizationViewDialog';
import { useListResource } from '../../../core/hooks/useListResource';
import { ServiceAuthorizationReportApi } from '../../../core/http/requests';
import { useFilter } from '../../../core/hooks/useFilter';

const serviceAuthorizationReportApi = new ServiceAuthorizationReportApi();

export const ServiceAuthorizations: FC = () => {
  const [openViewDialog] = useDialog(ServiceAuthorizationViewDialog);

  const {
    data,
    pagination,
    isLoading,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(serviceAuthorizationReportApi);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });
  return (
    <>
      <Card mb={3}>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <ClientAutocompleteField
            limitTags={3}
            multiple
            label="Clients"
            placeholder="Search"
            size="small"
            helperText={null}
            value={getFilterValue('filter[client.uuid][in]')}
            onChange={setFilterValue('filter[client.uuid][in]')}
          />
          <ServiceCodeMultipleField
            label="Service Code"
            size="small"
            helperText={null}
            value={getFilterValue('filter[serviceCode.uuid][in]')}
            onChange={setFilterValue('filter[serviceCode.uuid][in]')}
          />
          <Box flex="0 0 177px">
            <DatePicker
              disableInput
              label="Exp. date"
              size="small"
              helperText={null}
              value={getFilterValue('filter[expDate][eq]') || null}
              onChange={setFilterValue('filter[expDate][eq]')}
            />
          </Box>
          <Box flex="0 0 177px">
            <SelectField
              label="Status"
              items={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' }
              ]}
              size="small"
              helperText={null}
              value={getFilterValue('appointment.status')}
              onChange={setFilterValue('appointment.status')}
            />
          </Box>
          <Button onClick={handleSearch}>Search</Button>
        </Stack>
      </Card>
      <TableView
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        loading={isLoading}
        pagination={pagination}
        keyExtractor={(item) => item.uuid}
        columns={[
          ...serviceAuthorizationColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 50
            },
            render: () => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton size="small" onClick={openViewDialog}>
                    <IconView />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
        dataSource={get(data, 'items', [])}
      />
    </>
  );
};
