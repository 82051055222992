import React from 'react';
import {
  Box,
  Stack,
  Divider,
  Typography,
  Grid,
  useMediaQuery,
  Theme
} from '@mui/material';
import { useStyles } from './styles';
import logoText from '../../assets/logo-text.png';

export const AuthLayout: React.ComponentType = ({ children }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );
  return (
    <Grid container className={classes.root}>
      {!isMobile && (
        <Grid item xs={6} className={classes.cover}>
          <Box className={classes.waves} />
          <img src={logoText} />
          <Stack
            className={classes.bottomInfoBar}
            direction="row"
            divider={<Divider orientation="vertical" />}
            spacing={1.5}
          >
            <Typography variant="body2" color="common.white">
              Synergetic programming
            </Typography>
            <Typography variant="body2" color="common.white">
              Logistics
            </Typography>
            <Typography variant="body2" color="common.white">
              Administration
            </Typography>
            <Typography variant="body2" color="common.white">
              Schedulling
            </Typography>
            <Typography variant="body2" color="common.white">
              Human Resources
            </Typography>
          </Stack>
        </Grid>
      )}

      <Grid
        item
        xs={isMobile ? 12 : 6}
        component="main"
        className={classes.content}
      >
        {children}
      </Grid>
    </Grid>
  );
};
