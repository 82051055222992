import { makeStyles } from '@mui/styles';

const options = {
  name: 'roles-table'
};

const useStyles = makeStyles(
  () => ({
    table: {
      '& .MuiTableHead-root': {
        background: 'transparent'
      },
      '& .MuiTableCell-body': {
        border: 'none',
        padding: 7
      },
      '& .MuiTableCell-head': {
        padding: 4
      },
      '& .MuiTableRow-root th:first-child': {
        paddingLeft: 0
      },
      '& .MuiTableRow-root td:first-child': {
        paddingLeft: 0
      }
    }
  }),
  options
);

export default useStyles;
