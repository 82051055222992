import { ComponentType, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Stack, Button, FormControl } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ROUTE_CONNECT_TO_ADMINISTRATOR,
  ROUTE_SIGN_IN
} from '../../constants/route.paths';
import { PasswordField, TextField } from '../../components/fields';
import { url } from '../../core/utils/route.utils';
import { AppState } from '../../core/store/store';
import { urlParamsToObject } from '../../core';
import { isEmpty } from 'lodash';
import { OrganizationApi } from '../../core/http/requests/organization-api';
import { UserApi } from '../../core/http';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { OrganizationDto } from '../../core/http/dto/organization-dto';

const organizationApi = new OrganizationApi();
const userApi = new UserApi();

export const Invite: ComponentType = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { search } = useLocation();
  const [urlParams, setUrlParams] = useState<any>({});
  const { showMessage } = useSnackbar();
  const inviteLoading = useSelector((store: AppState) => store.invite.loading);
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: {
      organizationUuid: '',
      organizationTitle: '',
      email: '',
      password: '',
      passwordConfirmation: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Please enter valid email')
        .required('This field is required'),
      password: yup.string().required('This field is required'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('This field is required')
    }),
    onSubmit: (values) => {
      const uuid = uuidv4();
      const { hash } = urlParams;
      userApi
        .create({
          uuid: uuid,
          email: values.email,
          organizationUuid: values.organizationUuid,
          hash: hash,
          password: values.password,
          userName: values.email
        })
        .then(() => {
          navigate(url(ROUTE_SIGN_IN));
        })
        .catch((e) => {
          showMessage(e?.response?.data?.errorMessage, 'error');
        });
    }
  });

  useEffect(() => {
    if (search) {
      const params = urlParamsToObject(search);
      if (!isEmpty(params)) {
        setUrlParams(params);
        const { organizationUuid, email } = params as any;
        setValues((prev) => ({
          ...prev,
          organizationUuid: organizationUuid || '',
          email: email || ''
        }));
        organizationApi
          .findOne(organizationUuid)
          .then((organization: OrganizationDto) => {
            setFieldValue('organizationTitle', organization.name);
          });
      }
    }
  }, []);

  return (
    <>
      <Box className={classes.inviteRoot}>
        <Box className={classes.block}>
          <Typography variant="h3" mb={3}>
            Welcome to SplashWorks
          </Typography>
          <FormControl>
            <Stack spacing={2}>
              <TextField
                disabled
                label="Your Company"
                value={values.organizationTitle}
              />
              <TextField disabled label="Email" value={values.email} />
              <Box>
                <PasswordField
                  value={values.password}
                  helperText={touched.password && errors.password}
                  label="Password"
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                />
                <Typography variant="body2" color="#647593">
                  Password must contain at least 8 characters, including at
                  least one number, one lowercase and one uppercase
                </Typography>
              </Box>
              <PasswordField
                value={values.passwordConfirmation}
                helperText={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                label="Confirm Password"
                onChange={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
              />
              <Button
                onClick={() => handleSubmit()}
                disabled={inviteLoading}
                sx={{ width: 77 }}
              >
                Sign in
              </Button>
            </Stack>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </>
  );
};
