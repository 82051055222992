import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminRolesContainer } from '../../features/super-admin-roles/SuperAdminRolesContainer';

export const SuperAdminRolesPage = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Roles'
  })
)(SuperAdminRolesContainer);
