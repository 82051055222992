import { DataTableColumn } from '../../components/table-view/TableView';
import { format } from 'date-fns';
import { FULL_DATE_FORMAT } from '../../constants/app.constants';
import { UserRecord } from '../../components/user-record';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { NEWS_VISIBILITY } from '../../constants/enums';
import { clearHtml } from '../../core';

export const newsColumns: DataTableColumn[] = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Body',
    dataIndex: 'body',
    key: 'body',
    render: (text: string) => (
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: 200,
          whiteSpace: 'nowrap'
        }}
      >
        {clearHtml(text)}
      </Box>
    )
  },
  {
    title: 'Publisher',
    dataIndex: 'publisher',
    key: 'publisher',
    cellProps: {
      width: 225
    },
    render: (user: any) => (
      <UserRecord
        user={{
          name: `${user.firstName} ${user.lastName}`,
          avatar: user.avatar
        }}
        color="body"
      />
    )
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    cellProps: {
      width: 245
    },
    render: (value) => get(NEWS_VISIBILITY, value, '-')
  },
  {
    title: 'Publish Date',
    dataIndex: 'publishedAt',
    key: 'publishedAt',
    sortable: true,
    cellProps: {
      width: 165
    },
    render: (date) => {
      return date ? format(new Date(date), FULL_DATE_FORMAT) : '-';
    }
  }
];

export const newsData: any[] = [
  {
    uuid: 1,
    title: 'Happy Medical Day!',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
      name: 'Theresa Webb'
    },
    visibility: 'Employees',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 2,
    title: 'Birthday celebration',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
      name: 'Annette Black'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 3,
    title: 'Manage all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
      name: 'Leslie Alexander'
    },
    visibility: 'Management',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 4,
    title: 'Convert all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
      name: 'Ronald Richards'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 5,
    title: 'Happy Medical Day!',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
      name: 'Theresa Webb'
    },
    visibility: 'Employees',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 6,
    title: 'Birthday celebration',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
      name: 'Annette Black'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 7,
    title: 'Manage all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
      name: 'Leslie Alexander'
    },
    visibility: 'Management',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 8,
    title: 'Convert all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
      name: 'Ronald Richards'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 9,
    title: 'Happy Medical Day!',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
      name: 'Theresa Webb'
    },
    visibility: 'Employees',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 10,
    title: 'Birthday celebration',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
      name: 'Annette Black'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 11,
    title: 'Manage all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
      name: 'Leslie Alexander'
    },
    visibility: 'Management',
    publishedAt: new Date().toISOString()
  },
  {
    uuid: 12,
    title: 'Convert all appointments',
    publisher: {
      avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
      name: 'Ronald Richards'
    },
    visibility: 'Therapists',
    publishedAt: new Date().toISOString()
  }
];
