import { Chart } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const LossToCancellation = ({
  weeklyLossRevenue,
  weeklyCancellationRevenue
}) => {
  const totalLoss = Object.values(weeklyLossRevenue).reduce(
    (item: number, acc: number) => {
      acc += item;
      return acc;
    },
    0
  );

  const totalCancellation = Object.values(weeklyCancellationRevenue).reduce(
    (item: number, acc: number) => {
      acc += item;
      return acc;
    },
    0
  );

  const labels = [...Object.keys(weeklyLossRevenue), 'M2D'];
  const data = {
    labels: labels,
    datasets: [
      {
        type: 'line' as const,
        data: [...Object.values(weeklyLossRevenue), totalLoss],
        fill: false,
        borderColor: '#172B4D;',
        borderWidth: 3
      },
      {
        type: 'bar' as const,
        data: [...Object.values(weeklyCancellationRevenue), totalCancellation],
        fill: false,
        backgroundColor: Array(11).fill('#A8AFF2')
      }
    ]
  };

  return (
    <Card height={538} title="Loss to Cancellation">
      <Chart
        type="bar"
        options={options}
        data={data}
        style={{ maxHeight: 426, width: '100%' }}
      />
    </Card>
  );
};
