import { FC } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import {
  TimePicker as MuiTimePicker,
  TimePickerProps
} from '@mui/x-date-pickers/TimePicker';
import { IconCalendar } from '../../icons';
import get from 'lodash/get';
import useStyles from './styles';

interface Props extends Omit<TimePickerProps, 'renderInput'> {
  label?: string;
  Icon?: React.ReactNode;
  size?: 'small';
  onChange: (date: string | any) => void;
  onBlur?: (date: any) => void;
  errors?: any;
  helperText?: string;
  required?: boolean;
}

export const TimePicker: FC<Props> = ({
  Icon,
  errors,
  value,
  onBlur,
  helperText,
  required = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiTimePicker
      {...props}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={!!errors}
          helperText={errors || helperText}
          onBlur={onBlur}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            placeholder: 'Select',
            readOnly: true
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Box className={classes.icon}>
                <IconButton
                  {...get(
                    params,
                    'InputProps.endAdornment.props.children.props',
                    {}
                  )}
                  size="small"
                >
                  {Icon ? Icon : <IconCalendar />}
                </IconButton>
              </Box>
            )
          }}
        />
      )}
    />
  );
};
