import { FundingSourceEdit } from '../../features/master-data/funding-source/FundingSourceEdit';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataFundingSourceEdit = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Funding Sources'
  })
)(FundingSourceEdit);
