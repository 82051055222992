import { IconUnderConstruction } from '../icons';
import { Box, Typography } from '@mui/material';
import logoBigBlue from '../../assets/logo-big-blue.png';
import React from 'react';

export const UnderConstruction = () => {
  return (
    <Box
      sx={{
        width: 640,
        margin: '0 auto',
        display: 'flex',
        position: 'absolute',
        inset: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img src={logoBigBlue} width={203} height={48} />
      <IconUnderConstruction sx={{ width: 256, height: 256, my: 3 }} />
      <Typography variant="h2" mb={1} textAlign="center">
        SplashWorks is under construction
      </Typography>
      <Typography variant="body3" color="#496492" textAlign="center">
        Our website is under construction, but we are ready to go! We are
        preparing something amazing and exciting for you. For immediately
        contact inbox@splashworks.com
      </Typography>
    </Box>
  );
};
