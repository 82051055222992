import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import * as yup from 'yup';

interface Options {
  createUuid?: string;
  uuid?: string;
  clientUuid?: string;
  validationSchema?: any | (() => any);
  initialValues?: FormikValues;
  onSuccess?: (values: FormikValues) => void;
  onError?: (error: any) => void;
  normalizeValues?: (values: FormikValues) => any;
  normalizeSetValues?: (values: FormikValues) => any;
  onSubmit?: (values: FormikValues) => any;
}

export const useCareTeamMembersEdit = (
  api,
  {
    createUuid,
    uuid,
    clientUuid,
    onSuccess,
    onError,
    normalizeValues = (values) => values,
    normalizeSetValues = (values) => values,
    onSubmit
  }: Options
) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<Record<string, any>>({});

  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    touched,
    handleBlur,
    setFieldValue
  } = useFormik({
    initialValues: {
      clientUuid: clientUuid,
      employeeUuid: '',
      title: '',
      profitCenter: '',
      fundingSourceUuid: '',
      serviceCodes: []
    },
    validationSchema: yup.object().shape({
      employeeUuid: yup.string().required('Please select one of the options.'),
      title: yup
        .string()
        .typeError('Please use only alphabetic symbols and characters.'),
      profitCenter: yup
        .string()
        .typeError('Please use only alphabetic symbols and characters.'),
      fundingSourceUuid: yup
        .string()
        .required('Please select one of the options.'),
      serviceCodes: yup
        .array()
        .min(1, 'Please select at least one of the options.')
        .required('Please select one of the options.')
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: onSubmit
      ? onSubmit
      : async (values) => {
          setIsLoading(true);
          setErrors({});

          const normalizedValues = normalizeValues(values);

          let actionPromise: Promise<void>;
          const payload = {
            uuid: isEdit ? uuid : createUuid ? createUuid : uuidv4(),
            ...normalizedValues
          };
          if (isEdit) {
            actionPromise = api.update(payload);
          } else {
            actionPromise = api.create(payload);
          }

          actionPromise
            .then(() => {
              setIsLoading(false);
              onSuccess && onSuccess(payload);
            })
            .catch((err) => {
              const errorDescription = get(
                err,
                'response.data.errorDescription.fields'
              );

              if (errorDescription) {
                setErrors(errorDescription);
              }

              setIsLoading(false);
              onError && onError(err);
            });
        }
  });

  useEffect(() => {
    setErrors(validationErrors as Record<string, string>);
  }, [validationErrors]);

  useEffect(() => {
    if (uuid && clientUuid) {
      setIsLoading(true);
      api
        .findOne(clientUuid, uuid)
        .then((data) => {
          setData(data);
          setValues(normalizeSetValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid, clientUuid]);

  const getFieldTouchedError = (fieldName: string | string[] | number[]) => {
    return get(touched, fieldName, false) && get(errors, fieldName);
  };

  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    touched,
    setFieldValue,
    getFieldTouchedError,
    setErrors
  };
};
