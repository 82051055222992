import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { SelectField } from '../../../components/fields/select-field';

interface Props extends DialogProps {
  uuid: string;
  userName: string;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const SuperAdminDeactivateUserDialog: FC<Props> = ({ popDialog }) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>Confirm administrator deactivation</DialogTitle>
      <DialogContent dividers={false}>
        <Typography variant="body3">
          Select new administrator from the list below:
        </Typography>
        <SelectField
          required
          items={[
            { label: 'Debra Lane', value: '123' },
            { label: 'Franky Kolbasa', value: '213' }
          ]}
          placeholder="Select"
        />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={popDialog}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
