import React, { ComponentType, useCallback, useMemo, useState } from 'react';
import DialogContext from '../contexts/dialog.context';

export interface DialogProps {
  popDialog: () => void;
}

type StateDialogProps = [
  Component: ComponentType,
  props: any,
  resolve: any,
  reject: any
];

type DialogsState = StateDialogProps[];

export const DialogProvider: ComponentType = ({ children }) => {
  const [dialogs, setComponents] = useState<DialogsState>([]);

  const pushDialog = useCallback(
    (dialog: StateDialogProps) => {
      setComponents([...dialogs, dialog]);
    },
    [dialogs]
  );

  const popDialog = useCallback(() => {
    dialogs.pop();

    setComponents([...dialogs]);
  }, [dialogs]);

  const dialogContext = useMemo(() => {
    return { pushDialog, popDialog };
  }, [pushDialog, popDialog]);

  return (
    <DialogContext.Provider value={dialogContext}>
      {React.Children.only(children)}
      {dialogs.map(([component, componentProps], index) => {
        return React.createElement(component, {
          key: index,
          popDialog,
          ...componentProps
        });
      })}
    </DialogContext.Provider>
  );
};
