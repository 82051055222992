import { Expose } from 'class-transformer';
import { PermissionDto } from './permission.dto';

export class RoleDto {
  @Expose()
  id: number;

  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  permissions: PermissionDto;
}
