import { Typography, Box, BoxProps, Stack, StackProps } from '@mui/material';
import React, { ComponentType } from 'react';

interface Props extends Omit<BoxProps, 'variant'> {
  title?: any;
  meta?: React.ReactNode;
  HeaderProps?: StackProps;
  ContentProps?: BoxProps;
}

export const Card: ComponentType<Props> = ({
  title,
  children,
  meta,
  HeaderProps,
  ContentProps,
  ...props
}) => {
  return (
    <Box p={2} mb={2} border="1px solid #ECF0F7" borderRadius={0.5} {...props}>
      {(title || meta) && (
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          mb={2}
          {...HeaderProps}
        >
          {title && <Typography variant="h4">{title}</Typography>}
          {meta && <Box>{meta}</Box>}
        </Stack>
      )}
      <Box {...ContentProps}>{children}</Box>
    </Box>
  );
};
