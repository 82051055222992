import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import NumberFormat from 'react-number-format';
import { FC } from 'react';

interface Props extends Omit<TextFieldProps, 'error' | 'variant' | 'errors'> {
  errors?: any;
  value?: any;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  required?: boolean;
  maxLength?: number;
}

export const PhoneField: FC<Props> = ({
  errors,
  value,
  onChange,
  ...props
}) => {
  const handleChange = ({ value }) => {
    onChange?.(value);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NumberFormat
      {...props}
      error={!!errors}
      helperText={errors || props.helperText}
      value={value}
      customInput={MuiTextField}
      format="(###) ###-####"
      type="text"
      onValueChange={handleChange}
    />
  );
};
