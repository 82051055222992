import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { ServiceDescriptionApi, ServiceDescriptionDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const serviceDescriptionApi = new ServiceDescriptionApi();

export const fetchServiceDescriptionAsync = createFetchAsync(
  'serviceDescription',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await serviceDescriptionApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchServiceDescription =
  fetchServiceDescriptionAsync.asyncThunk;

export const fetchServiceDescription = (payload) => (dispatch, getState) => {
  const {
    serviceDescription: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchServiceDescription(payload));
  }
};

type ServiceDescriptionState = {
  findAll: AsyncState<FindAllResponse<ServiceDescriptionDto>>;
};

const initialState: ServiceDescriptionState = {
  findAll: fetchServiceDescriptionAsync.initialState
};

const serviceDescription = createSlice({
  name: 'serviceDescription',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchServiceDescriptionAsync.extraReducers
  }
});

export default serviceDescription.reducer;
