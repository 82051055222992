import { useContext, useEffect } from 'react';
import LayoutContext from '../contexts/layout.context';

interface Options {
  backButton?: {
    title: string;
    path?: string;
    onClick?: () => void;
  };
}

export const useLayout = (options?: Options) => {
  const { backButton, setBackButton } = useContext(LayoutContext);

  useEffect(() => {
    if (options?.backButton) {
      setBackButton(options.backButton);
    }

    return () => {
      setBackButton(null);
    };
  }, [options?.backButton.title]);

  return { backButton };
};
