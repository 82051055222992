import { Expose, Transform } from 'class-transformer';
import { AppointmentTypeDto } from './appointment-type.dto';
import get from 'lodash/get';

export class PlaceServiceDto {
  @Expose()
  uuid: string;

  @Expose()
  code: string;

  @Expose()
  title: string;

  @Expose()
  appointmentType: AppointmentTypeDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'appointmentType.uuid'))
  appointmentTypeUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'appointmentType.title'))
  appointmentTypeTitle: string;
}
