export const ROUTE_HOME = '/';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_EDIT = '/profile/:id/edit';
export const ROUTE_DASHBOARDS = '/dashboards';
export const ROUTE_DASHBOARDS_SELECTED = '/dashboards/:tabs';
export const ROUTE_ANNOUNCEMENTS = '/announcements';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_REPORTS_TIMESHEET_EDIT = '/reports/timesheet/:id/edit';
export const ROUTE_TIMESHEET = '/timesheet';
export const ROUTE_BILLING = '/billing';
export const ROUTE_BILLING_DETAILS = '/billing/:id';
export const ROUTE_REMINDERS = '/reminders';
export const ROUTE_EDIT_EMPLOYEE_REMINDER = '/reminders/employee/edit/:id';
export const ROUTE_CREATE_EMPLOYEE_REMINDER = '/reminders/employee/create';
export const ROUTE_EDIT_CLIENT_REMINDER = '/reminders/client/edit/:id';
export const ROUTE_CREATE_CLIENT_REMINDER = '/reminders/client/create';
export const ROUTE_NEWS = '/news';
export const ROUTE_NEWS_CREATE = '/news/create';
export const ROUTE_NEWS_EDIT = '/news/:id/edit';
export const ROUTE_MASTER_DATA = '/master-data';
export const ROUTE_MASTER_DATA_EMPLOYEE_GROUPS = '/master-data/employee-groups';
export const ROUTE_MASTER_DATA_NOTIFICATION_MESSAGES =
  '/master-data/notification-messages';
export const ROUTE_MASTER_DATA_TASK_TYPE = '/master-data/task-type';
export const ROUTE_MASTER_DATA_UPLOAD_HANDBOOK = '/master-data/upload-handbook';
export const ROUTE_MASTER_DATA_PROFIT_CENTER = '/master-data/profit-center';
export const ROUTE_MASTER_DATA_SERVICE_TYPE = '/master-data/service-type';
export const ROUTE_MASTER_DATA_SERVICE_DESCRIPTION =
  '/master-data/service-description';
export const ROUTE_MASTER_DATA_REFERRAL_TYPE = '/master-data/referral-type';
export const ROUTE_MASTER_DATA_THERAPY_TITLE =
  '/master-data/client-info-therapy-title';
export const ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE_STATUS =
  '/master-data/app-cancel-type-status';
export const ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE =
  '/master-data/app-cancel-type';
export const ROUTE_MASTER_DATA_APPOINTMENT_TYPE =
  '/master-data/appointment-type';
export const ROUTE_MASTER_DATA_HOLIDAY = '/master-data/holiday-details';
export const ROUTE_MASTER_DATA_PLACE_OF_SERVICE =
  '/master-data/place-of-service';
export const ROUTE_MASTER_DATA_LOCATION = '/master-data/location';
export const ROUTE_MASTER_DATA_REGION = '/master-data/region';
export const ROUTE_MASTER_DATA_SERVICE_CODE = '/master-data/service-codes';
export const ROUTE_MASTER_DATA_INTERNAL_WORK_TYPES =
  '/master-data/internal-work-types';
export const ROUTE_MASTER_DATA_EDIT_SERVICE_CODE =
  '/master-data/service-codes/edit/:uuid';
export const ROUTE_MASTER_DATA_CREATE_SERVICE_CODE =
  '/master-data/service-codes/create';
export const ROUTE_MASTER_DATA_FUNDING_SOURCE = '/master-data/funding-sources';
export const ROUTE_MASTER_DATA_EDIT_FUNDING_SOURCE =
  '/master-data/funding-sources/edit/:uuid';
export const ROUTE_MASTER_DATA_CREATE_FUNDING_SOURCE =
  '/master-data/funding-sources/create';

export const ROUTE_ROLES = '/roles';
export const ROUTE_ROLES_CREATE = '/roles/create';
export const ROUTE_ROLES_EDIT = '/roles/:id/edit';
export const ROUTE_SIGN_IN = '/sign-in';
export const ROUTE_TWO_FACTOR = '/two-factor';
export const ROUTE_TWO_FACTOR_RECOVERY = '/two-factor-recovery';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_CREATE_NEW_PASSWORD = '/set-password/hash/:hash';
export const ROUTE_RESET_PASSWORD_SUCCESS = '/reset-password-success';
export const ROUTE_INVITE = '/sign-up';
export const ROUTE_TWO_FACTOR_SETUP = '/two-factor-setup';
export const ROUTE_TWO_FACTOR_SETUP_SUCCESS = '/two-factor-setup-success';
export const ROUTE_CLIENTS = '/clients';
export const ROUTE_CLIENT_DETAILS = '/client/:id/details/';
export const ROUTE_CLIENT_PROFILE_EDIT = '/client/:id/edit';
export const ROUTE_CLIENT_PROFILE_CREATE = '/client/create';
export const ROUTE_CONNECT_TO_ADMINISTRATOR = '/connect-to-administrator';
export const ROUTE_CLIENT_SERVICE_AUTHORIZATION_EDIT =
  '/client/:clientUuid/service-authorization/edit/:uuid';
export const ROUTE_CLIENT_SERVICE_AUTHORIZATION_CREATE =
  '/client/:clientUuid/service-authorization/create';

export const ROUTE_CLIENT_APPOINTMENT_EDIT =
  '/client/:clientId/appointment/edit/:uuid';
export const ROUTE_CLIENT_APPOINTMENT_VIEW =
  '/client/:clientId/appointment/view/:uuid';
export const ROUTE_CLIENT_APPOINTMENT_CREATE =
  '/client/:clientId/appointment/create';
//
// export const ROUTE_CLIENT_APPOINTMENT_EDIT =
//   '/client/:id/appointemnts/edit/:therapist';
// export const ROUTE_EMPLOYEE_APPOINTMENT_EDIT =
//   '/employee/:id/appointemnts/edit/:therapist';
export const ROUTE_TWO_FACTOR_SETUP_2 = '/two-factor-setup-step-2';
export const ROUTE_FILES = '/files';
export const ROUTE_EMPLOYEES = '/employees';
export const ROUTE_EMPLOYEE_DETAILS = '/employee/:id/details/';
export const ROUTE_EMPLOYEE_APPOINTMENT_VIEW =
  '/employee/:employeeId/appointment/view/:uuid';
export const ROUTE_EMPLOYEE_APPOINTMENT_EDIT =
  '/employee/:employeeId/appointment/edit/:uuid';
export const ROUTE_EMPLOYEE_APPOINTMENT_CREATE =
  '/employee/:employeeId/appointment/create';

export const ROUTE_EMPLOYEE_PROFILE_EDIT = '/employee/:id/edit';
export const ROUTE_EMPLOYEE_PROFILE_CREATE = '/employee/create';
export const ROUTE_APPOINTMENTS = '/appointments';
export const ROUTE_APPOINTMENTS_CREATE = '/appointments/create';
export const ROUTE_APPOINTMENTS_COMPARE_AVAILABILITY =
  '/appointments/compare-availability';
export const ROUTE_MAKE_UP_APPOINTMENTS = '/appointments/make-up';
export const ROUTE_MAKE_UP_APPOINTMENTS_CREATE =
  '/appointments/make-up/create/:id';
export const ROUTE_MAKE_UP_APPOINTMENTS_EDIT = '/appointments/make-up/:id/edit';
export const ROUTE_APPOINTMENTS_LIST = '/appointments-list/:id';

export const ROUTE_SUPER_ADMIN_COMPANIES = '/super-admin/companies';
export const ROUTE_SUPER_ADMIN_COMPANY_PROFILE = '/super-admin/companies/:id';
export const ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT =
  '/super-admin/companies/:id/edit';
export const ROUTE_SUPER_ADMIN_COMPANY_PROFILE_CREATE =
  '/super-admin/companies/create';

export const ROUTE_SUPER_ADMIN_ADMINISTRATORS = '/super-admin/administrators';
export const ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE =
  '/super-admin/administrators/:id';
export const ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_EDIT =
  '/super-admin/administrators/:id/edit';
export const ROUTE_SUPER_ADMIN_ADMINISTRATOR_PROFILE_CREATE =
  '/super-admin/administrators/create';

export const ROUTE_SUPER_ADMIN_USERS = '/super-admin/users';
export const ROUTE_SUPER_ADMIN_USER_PROFILE = '/super-admin/users/:id';
export const ROUTE_SUPER_ADMIN_USER_PROFILE_EDIT =
  '/super-admin/users/:id/edit';
export const ROUTE_SUPER_ADMIN_USER_PROFILE_CREATE =
  '/super-admin/users/create';

export const ROUTE_SUPER_ADMIN_ROLES = '/super-admin/roles';
export const ROUTE_SUPER_ADMIN_ROLE_EDIT = '/super-admin/roles/:id/edit';
export const ROUTE_SUPER_ADMIN_ROLE_CREATE = '/super-admin/roles/create';
