import { FC, useState } from 'react';
import { TableView } from '../../../components/table-view';
import {
  serviceAuthorizationColumns,
  serviceAuthorizationData
} from '../_mockdata';
import { Card } from '../../../components/cards';
import {
  ClientAutocompleteField,
  DatePicker,
  TextField
} from '../../../components/fields';
import { Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import get from 'lodash/get';
import { ServiceCodeField } from '../../../components/fields/service-code-field';
// import { ServiceAuthorizationViewDialog } from '../dialogs/ServiceAuthorizationViewDialog';
import { IconArrowRight10 } from '../../../components/icons/index';
import { useNavigate } from 'react-router';
import { ROUTE_BILLING_DETAILS } from '../../../constants/route.paths';
import { url } from '../../../core';

export const EBillingInvoices: FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<any>({});
  // const [openViewDialog] = useDialog(ServiceAuthorizationViewDialog);

  const handleFilterChange = (field) => (value) => {
    setFilter({ ...filter, [field]: value });
  };

  return (
    <>
      <Card mb={3}>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <TextField label="Invoice #" size="small" />
          </Grid>
          <Grid item md={2}>
            <TextField label="UCI #" size="small" />
          </Grid>
          <Grid item md={8}>
            <ClientAutocompleteField
              limitTags={3}
              multiple
              label="Client Name"
              placeholder="Search"
              size="small"
              helperText={null}
              value={get(filter, 'clients')}
              onChange={handleFilterChange('clients')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item md={2}>
            <DatePicker
              label="Service M/Y"
              size="small"
              helperText={null}
              value={get(filter, 'serviceDate', null)}
              onChange={handleFilterChange('serviceDate')}
            />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              label="Invoice Date"
              size="small"
              helperText={null}
              value={get(filter, 'invoiceDate', null)}
              onChange={handleFilterChange('invoiceDate')}
            />
          </Grid>
          <Grid item md={6}>
            <ServiceCodeField
              label="Service Code"
              placeholder="Type of select"
              size="small"
              helperText={null}
              value={get(filter, 'serviceCodes')}
              onChange={handleFilterChange('serviceCodes')}
            />
          </Grid>
          <Grid item md={1}>
            <Button fullWidth>Search</Button>
          </Grid>
          <Grid item md={1}>
            <Button fullWidth color="secondary">
              Clear
            </Button>
          </Grid>
        </Grid>
      </Card>
      <TableView
        pagination={{
          page: 1,
          perPage: 12,
          totalPages: 10,
          total: 100
        }}
        keyExtractor={(item) => item.uuid}
        columns={[
          ...serviceAuthorizationColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 50
            },
            render: (_, data) => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton
                    size="small"
                    onClick={() =>
                      navigate(url(ROUTE_BILLING_DETAILS, { id: data.uuid }))
                    }
                  >
                    <IconArrowRight10 />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
        dataSource={serviceAuthorizationData}
      />
    </>
  );
};
