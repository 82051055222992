import { Expose, Transform } from 'class-transformer';
import { get } from 'lodash';

export class LocationDto {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  address: string;

  @Expose()
  city: string;

  @Expose()
  state: string;

  @Expose()
  zipCode: string;

  @Expose()
  region: string;

  @Expose()
  timeZone: string;

  @Expose()
  servicePlace: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'servicePlace.uuid'))
  servicePlaceUuid: string;
}
