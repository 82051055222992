import { SelectField } from '../select-field';
import map from 'lodash/map';
import { useFundingSource } from '../../../core/hooks/useFundingSource';
import { FundingSourceDto } from '../../../core/http';

export const FundingSourceField = ({ value, ...props }) => {
  const { items } = useFundingSource();
  const selectItems = map(items, (item: FundingSourceDto) => ({
    value: item.uuid,
    label: item.name
  }));

  const sortedItems = props.items
    ? props.items.sort((a, b) => (a.label > b.label ? 1 : -1))
    : selectItems
    ? selectItems.sort((a, b) => (a.label > b.label ? 1 : -1))
    : [];

  return (
    <SelectField
      label="Funding Source"
      value={value || ''}
      items={sortedItems}
      {...props}
    />
  );
};
