import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminAdministratorsContainer } from '../../features/super-admin-administrators/SuperAdminAdministratorsContainer';

export const SuperAdminAdministratorsPage = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Administrators'
  })
)(SuperAdminAdministratorsContainer);
