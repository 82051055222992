import { DataTableColumn } from '../../components/table-view/TableView';
import { UserRecord } from '../../components/user-record';
import { normalizeDate, normalizeTime } from '../../core';
import { differenceInMinutes } from 'date-fns';
import get from 'lodash/get';

export const timesheetsColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDateOfService',
    key: 'startDateOfService',
    cellProps: {
      width: 90
    },
    sortable: true,
    render: (date: string) => normalizeDate(date)
  },
  {
    title: 'Time',
    dataIndex: 'startDateOfService',
    key: 'time',
    cellProps: {
      width: 110
    },
    render: (date: string) => normalizeTime(date)
  },
  {
    title: 'Service Code',
    dataIndex: 'authorization.serviceCode.code',
    key: 'serviceCode',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Service Description',
    dataIndex: 'authorization.serviceCode.description',
    key: 'serviceDescription',
    cellProps: {
      width: 140
    }
  },
  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    cellProps: {
      width: 140
    },
    render: (_, data) => {
      const firstName = get(data, 'appointment.primaryTherapist.firstName');
      const lastName = get(data, 'appointment.primaryTherapist.lastName');

      return (
        firstName &&
        lastName && (
          <UserRecord
            user={{ name: `${firstName} ${lastName}`, avatar: 'none' }}
            color="common.dark"
          />
        )
      );
    }
  },
  {
    title: 'First Name',
    dataIndex: 'payer.firstName',
    key: 'payer.firstName',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Last Name',
    dataIndex: 'payer.lastName',
    key: 'payer.lastName',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Provider',
    dataIndex: 'payer.profitCenter.officeName',
    key: 'payer.profitCenter.officeName',
    cellProps: {
      width: 150
    }
  },
  {
    title: 'Hours',
    dataIndex: 'appointment',
    key: 'hours',
    cellProps: {
      width: 50
    },
    render: ({ startDate, endDate }) => {
      if (!startDate || !endDate) return null;

      const start = new Date(startDate);
      const end = new Date(endDate);
      const diff = differenceInMinutes(end, start);
      return (diff / 60).toFixed(2);
    }
  },
  {
    title: 'Units',
    dataIndex: 'unitOfService',
    key: 'unitOfService',
    cellProps: {
      width: 50
    },
    sortable: true
  },
  {
    title: 'Converted Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    cellProps: {
      width: 140
    },
    sortable: true,
    render: (value) => normalizeDate(value) || '-'
  }
];
