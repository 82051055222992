import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchActiveEmployee } from '../store/slices/employeeSlice';

export const useActiveEmployee = () => {
  const dispatch = useDispatch();
  const { findAllActive } = useSelector((state: AppState) => state.employee);

  useEffect(() => {
    dispatch(
      fetchActiveEmployee({ limit: 1000, 'filter[isDeleted][eq]': '0' })
    );
  }, []);

  return {
    items: get(findAllActive, 'payload.items', []),
    itemsLoading: get(findAllActive, 'loading', false)
  };
};
