import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton
} from '@mui/material';
import { FC } from 'react';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { UserRecord } from '../../../components/user-record';
import { TableView } from '../../../components/table-view';
import { timeCardsViewData, timeCardViewColumns } from '../_mockdata';
import { Card } from '../../../components/cards';
import { Badge } from '../../../components/badge';
import { LabeledRecord } from '../../../components/labeled-record';
import { IconAppointmentConfirm, IconView } from '../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../core';
import { ROUTE_REPORTS_TIMESHEET_EDIT } from '../../../constants/route.paths';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const TimeCardViewDialog: FC<Props> = ({ popDialog }) => {
  const navigate = useNavigate();

  const handleView = (uuid) => () => {
    popDialog();
    navigate(url(ROUTE_REPORTS_TIMESHEET_EDIT, { id: uuid }));
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 1080
        }
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box>
            <Typography variant="h3" mb={0.25}>
              Time Card
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <UserRecord
                user={{
                  name: 'Theresa Webb',
                  avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
                }}
                NameProps={{
                  color: 'primary',
                  variant: 'body3'
                }}
              />
              <Typography color="common.grey" variant="body3">
                Date:{' '}
                <Typography
                  variant="body3"
                  component="span"
                  color="common.dark"
                >
                  07/04/2021
                </Typography>
              </Typography>
              <Badge label="Not converted" variant="warning" size="small" />
            </Stack>
          </Box>
          <Button onClick={popDialog} color="third">
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Card bgcolor="#F6F9FF" p={1}>
          <Grid container>
            <Grid item xs={2}>
              <LabeledRecord label="Total (h/d):" value="8.0" mb={0} />
            </Grid>
            <Grid item xs={2}>
              <LabeledRecord label="Billable (h/d):" value="5.0" mb={0} />
            </Grid>
            <Grid item xs={2}>
              <LabeledRecord label="Non-Billable (h/d):" value="3.0" mb={0} />
            </Grid>
            <Grid item xs={2}>
              <LabeledRecord label="OT1:" value="0" mb={0} />
            </Grid>
            <Grid item xs={2}>
              <LabeledRecord label="OT2:" value="0" mb={0} />
            </Grid>
            <Grid item xs={2}>
              <LabeledRecord label="Unconverted (h):" value="0" mb={0} />
            </Grid>
          </Grid>
        </Card>
        <TableView
          pagination={{
            page: 1,
            perPage: 12,
            totalPages: 10,
            total: 100
          }}
          keyExtractor={(item) => item.uuid}
          columns={[
            ...timeCardViewColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, { status, uuid }) => (
                <Stack direction="row" alignItems="center">
                  {status === 'converted' ? (
                    <Tooltip
                      title="View"
                      placement="bottom-end"
                      onClick={handleView(uuid)}
                    >
                      <IconButton size="small">
                        <IconView />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Confirm" placement="bottom-end">
                      <IconButton size="small" onClick={handleView(uuid)}>
                        <IconAppointmentConfirm />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              )
            }
          ]}
          dataSource={timeCardsViewData}
        />
      </DialogContent>
    </Dialog>
  );
};
