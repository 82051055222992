import { ClientInfoTherapyTitleContainer } from '../../features/master-data/client-info-therapy-title/ClientInfoTherapyTitleContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataTherapyTitle = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Therapy Titles'
  })
)(ClientInfoTherapyTitleContainer);
