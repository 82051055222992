import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'alert'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(1.5),
      backgroundColor: '#F9FBFE',
      borderRadius: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',

      '& .MuiSvgIcon-root': {
        color: '#647593'
      }
    },

    info: {
      backgroundColor: '#EAF1FF'
    },
    warning: {
      backgroundColor: '#FFF6E0'
    },
    error: {
      backgroundColor: '#FFF0F0',

      '& .MuiSvgIcon-root': {
        color: '#FF5C5C'
      }
    },
    success: {}
  }),
  options
);

export default useStyles;
