import { TaskTypeContainer } from '../../features/master-data/task-type/TaskTypeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataTaskType = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Task Types'
  })
)(TaskTypeContainer);
