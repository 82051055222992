import { AuthLayout } from '../../../layouts/auth-layout';
import { CreateNewPassword } from '../../../features/auth/СreateNewPassword';
import { Helmet } from 'react-helmet';

export const CreateNewPasswordPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Create New Password</title>
      </Helmet>
      <CreateNewPassword />
    </AuthLayout>
  );
};
