import { EmployeeGroupContainer } from '../../features/master-data/employee-group/EmployeeGroupContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataEmployeeGroup = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Employee Groups'
  })
)(EmployeeGroupContainer);
