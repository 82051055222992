import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import {
  usersData,
  appointmentsData
} from '../../../features/appointments/_mockdata';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IUsersValues {
  name: string;
}

interface IGetUsersThunkArguments {
  values: IUsersValues;
  options?: Partial<IRequestOptions>;
}

export const getAppointments = createAsyncThunk('getAppointments', async () => {
  try {
    const data: any = await delay(500, appointmentsData);
    return data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const findUser = createAsyncThunk(
  'findUserAction',
  async ({ values, options }: IGetUsersThunkArguments) => {
    try {
      const users: any = await usersData.filter((item) => {
        if (item.name.includes(values.name)) {
          return item;
        }
      });
      const data: any = await delay(500, users);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const appointmentsSlice = createSlice({
  name: 'appointmentsSlice',
  initialState: {
    findUser: {
      loading: false,
      data: null,
      error: null
    },
    getAppointments: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppointments.pending, (state) => {
      state.getAppointments.loading = true;
      state.getAppointments.data = null;
      state.getAppointments.error = null;
    });
    builder.addCase(getAppointments.fulfilled, (state, { payload }) => {
      state.getAppointments.loading = false;
      state.getAppointments.data = payload;
      state.getAppointments.error = null;
    });
    builder.addCase(getAppointments.rejected, (state) => {
      state.getAppointments.loading = false;
      state.getAppointments.data = null;
      state.getAppointments.error = 'Something went wrong';
    });
    builder.addCase(findUser.pending, (state) => {
      state.findUser.loading = true;
      state.findUser.data = null;
      state.findUser.error = null;
    });
    builder.addCase(findUser.fulfilled, (state, { payload }) => {
      state.findUser.loading = false;
      state.findUser.data = payload;
      state.findUser.error = null;
    });
    builder.addCase(findUser.rejected, (state) => {
      state.findUser.loading = false;
      state.findUser.data = null;
      state.findUser.error = 'Something went wrong';
    });
  }
});

export default appointmentsSlice.reducer;
