import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { EnumField, FieldWysiwyg, TextField } from '../../../components/fields';
import { NOTIFICATION_MESSAGE_GROUP } from '../../../constants/enums';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { NotificationMessageApi } from '../../../core/http';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const notificationMessageApi = new NotificationMessageApi();

export const NotificationMessagesEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(notificationMessageApi, {
    uuid,
    initialValues: {
      title: '',
      group: '',
      message: ''
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is a required field')
    }),
    onSuccess,
    onError
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 640 } }}
    >
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
          errors={getFieldTouchedError('title')}
        />
        <EnumField
          label="Group"
          items={NOTIFICATION_MESSAGE_GROUP}
          value={values.group}
          onChange={handleChange('group')}
          errors={getFieldTouchedError('group')}
        />

        <FieldWysiwyg
          label="Message"
          value={values.message}
          onChange={handleChange('message')}
          errors={getFieldTouchedError('message')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
