import { ComponentType, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { clientLogsColumns } from '../_mockdata';
import { useDispatch, useSelector } from 'react-redux';
import { getClientLog } from '../../../core/store/slices/clientLogSlice';
import { AppState } from '../../../core/store/store';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { AddLogDialog } from '../dialogs/AddLogDialog';
import { PermissionView } from '../../../components/permission-view';

export const Logs: ComponentType = () => {
  const dispatch = useDispatch();
  const [openAddNewLogDialog] = useDialog(AddLogDialog);

  const { data: clientLogData, loading } = useSelector(
    (state: AppState) => state.clientLog.getClientLog
  );

  useEffect(() => {
    dispatch(getClientLog());
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyClients">
          <Button onClick={openAddNewLogDialog}>Add new log</Button>
        </PermissionView>
        <SearchField sx={{ width: 400 }} size="small" placeholder="Search" />
      </Stack>
      <Box>
        <TableView
          loading={loading}
          showTablePerPage={!loading}
          showPagination={true}
          pagination={{
            page: 0,
            perPage: 10,
            totalPages: 10,
            total: 12
          }}
          dataSource={clientLogData || []}
          columns={clientLogsColumns}
        />
      </Box>
    </>
  );
};
