import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Grid,
  FormControlLabel
} from '@mui/material';
import {
  DatePicker,
  TimePicker,
  CheckboxList,
  Checkbox,
  TextField
} from '../../../components/fields';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { EmployeeAvailabilityApi } from '../../../core/http/requests/employee-availability.api';
import { format } from 'date-fns';
import {
  dateFromTime,
  normalizeDate,
  transformToBackendDate
} from '../../../core';
import { get } from 'lodash';
import { useEmployeeAvailabilityEdit } from '../../../core/hooks/useEmployeeAvailabilityEdit';

const employeeAvailabilityApi = new EmployeeAvailabilityApi();

export const AddAvailabilityDialog = ({
  popDialog,
  fetchData,
  employeeUuid,
  uuid
}) => {
  const { showMessage } = useSnackbar();
  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldTouchedError,
    handleBlur
  } = useEmployeeAvailabilityEdit(employeeAvailabilityApi, {
    uuid: uuid,
    employeeUuid,
    normalizeValues: (values) => {
      const startTime = get(values, 'startTime', new Date());
      const endTime = get(values, 'endTime');
      return {
        ...values,
        dateFrom: transformToBackendDate(values.dateFrom),
        dateTo: transformToBackendDate(values.dateTo),
        startTime: format(startTime, 'HH:mm:ss'),
        endTime: format(endTime, 'HH:mm:ss')
      };
    },
    normalizeSetValues: (values) => {
      return {
        ...values,
        startTime: dateFromTime(values.startTime),
        endTime: dateFromTime(values.endTime)
      };
    },
    onSuccess: async () => {
      popDialog();
      fetchData();
      showMessage(
        uuid
          ? 'Availability was updated successfully'
          : 'Availability was created Successfully'
      );
    },
    onError: () => {
      popDialog();
      showMessage('Something went wrong, please try again later.', 'error');
    }
  });
  const weekDaysList = [
    {
      label: 'Sun',
      value: 'SUN'
    },
    {
      label: 'Mon',
      value: 'MON'
    },
    {
      label: 'Tue',
      value: 'TUE'
    },
    {
      label: 'Wed',
      value: 'WED'
    },
    {
      label: 'Thu',
      value: 'THU'
    },
    {
      label: 'Fri',
      value: 'FRI'
    },
    {
      label: 'Sat',
      value: 'SAT'
    }
  ];
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 600
        }
      }}
    >
      <DialogTitle>
        {uuid
          ? 'Update Availability for employee'
          : 'Add Availability for employee'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} rowSpacing={3.5}>
          <Grid item md={6}>
            <DatePicker
              required
              label="Starts on"
              value={normalizeDate(values.dateFrom) || ''}
              errors={getFieldTouchedError('dateFrom')}
              onChange={(value) =>
                setFieldValue('dateFrom', transformToBackendDate(value))
              }
              onBlur={handleBlur('dateFrom')}
            />
          </Grid>
          <Grid item md={6}>
            <DatePicker
              required
              label="Until"
              value={normalizeDate(values.dateTo) || ''}
              onChange={(value) =>
                setFieldValue('dateTo', transformToBackendDate(value))
              }
              errors={getFieldTouchedError('dateTo')}
              onBlur={handleBlur('dateTo')}
            />
          </Grid>
          <Grid item md={6}>
            <TimePicker
              required
              label="Starts time"
              value={dateFromTime(values.startTime) || ''}
              onChange={(value) => {
                setFieldValue('startTime', value);
              }}
              errors={getFieldTouchedError('startTime')}
              onBlur={handleBlur('startTime')}
            />
          </Grid>
          <Grid item md={6}>
            <TimePicker
              required
              label="End time"
              value={dateFromTime(values.endTime) || ''}
              onChange={(value) => setFieldValue('endTime', value)}
              errors={getFieldTouchedError('endTime')}
              onBlur={handleBlur('endTime')}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" mb={1.75} color="common.info">
              Repeat on *
            </Typography>
            <CheckboxList
              helperText={getFieldTouchedError('weekday')}
              value={values.weekday || []}
              onChange={(value) => setFieldValue('weekday', value)}
              items={weekDaysList}
            />
          </Grid>
          <Grid item md={12}>
            <Typography variant="subtitle1" mb={1.75} color="common.info">
              Mode
            </Typography>
            <FormControlLabel
              checked={values.teletherapyMode}
              sx={{ mr: 3 }}
              control={
                <Checkbox
                  value={values.teletherapyMode}
                  onChange={(_, value) =>
                    setFieldValue('teletherapyMode', value)
                  }
                />
              }
              label={<Typography variant="body3">Teletherapy</Typography>}
            />
            <FormControlLabel
              checked={values.inHomeMode}
              label={<Typography variant="body3">In-Home</Typography>}
              control={
                <Checkbox
                  value={values.inHomeMode}
                  onChange={(_, value) => setFieldValue('inHomeMode', value)}
                />
              }
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              multiline={true}
              rows={5.6}
              label="Note"
              value={values.note}
              onChange={(value) => setFieldValue('note', value)}
              errors={getFieldTouchedError('note')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
