import { format } from 'date-fns';
import { Box, Stack, Typography } from '@mui/material';
import { UserRecord } from '../user-record';
import clsx from 'clsx';
import useStyles from './styles';
import get from 'lodash/get';
import {
  IconAppointmentPending,
  IconAppointmentSuccess,
  IconAppointmentWarning
} from '../icons';
import { STATUS_VARIANT } from '../../constants/enums';
import { AppointmentDto } from '../../core/http';
import { FC } from 'react';

interface Props {
  item: AppointmentDto;
  selected?: boolean;
}

export const AppointmentCalendarCard: FC<Props> = ({
  item,
  selected = false
}) => {
  const start = format(new Date(item.startDate.replace('Z', '')), 'hh:mm a');
  const end = item.endDate
    ? format(new Date(item.endDate.replace('Z', '')), 'hh:mm a')
    : null;
  const classes = useStyles();
  const status = get(item, 'status', get(item, 'variant', 'pending'));
  const variant = get(STATUS_VARIANT, status);
  const isMinimal = end === null;
  const icon = () => {
    switch (variant) {
      case 'success':
        return <IconAppointmentSuccess />;
      case 'warning':
        return <IconAppointmentWarning />;
      default:
        return <IconAppointmentPending />;
    }
  };

  // if (isAllDay) {
  //   return <AllDayCard title={item.title} description={item.description} />;
  // }

  return (
    <Box
      className={clsx(classes.root, {
        [classes.minimal]: isMinimal,
        [classes.primary]: variant === 'primary',
        [classes.secondary]: variant === 'secondary',
        [classes.success]: variant === 'success',
        [classes.warning]: variant === 'warning',
        [classes.selected]: selected
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height={isMinimal ? 28 : 24}
        mb={isMinimal ? 0 : 0.375}
      >
        <Typography variant="body2" fontWeight={600}>
          {`${start} - ${end}`}
        </Typography>
        {icon()}
      </Stack>
      {item.primaryTherapist && (
        <>
          <UserRecord
            user={{
              name: item?.primaryTherapist?.displayName,
              avatar: 'none'
            }}
            color="common.dark"
            variant="small"
          />
          <Typography
            variant="body2"
            fontWeight={500}
            color="#647593"
            mt={0.375}
          >
            Speech Therapy
          </Typography>
        </>
      )}
    </Box>
  );
};
