export const regionColumns = [
  {
    title: 'Region name',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'Location',
    key: 'location',
    dataIndex: 'location',
    render: (l) => `${l.state}-${l.city}, ${l.region}, ${l.zipCode}`
  }
];
