import { PageHeader } from '../../../components/page-header';
import { TableView } from '../../../components/table-view';
import { appointmentTypeColumns } from './_mockdata';
import { Button, IconButton, Stack } from '@mui/material';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { AppointmentTypeEditDialog } from './AppointmentTypeEditDialog';
import { IconPen, IconTrash } from '../../../components/icons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useLayout } from '../../../core/hooks/useLayout';
import { ROUTE_MASTER_DATA } from '../../../constants/route.paths';
import { AppointmentTypeApi } from '../../../core/http';
import { useListResource } from '../../../core/hooks/useListResource';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { forceFetchAppointmentType } from '../../../core/store/slices/appointmentTypeSlice';

const appointmentTypeApi = new AppointmentTypeApi();

export const AppointmentTypeContainer = () => {
  const [openEdit, closeEdit] = useDialog(AppointmentTypeEditDialog);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const dispatch = useDispatch();

  useLayout({
    backButton: {
      title: 'Master Data',
      path: ROUTE_MASTER_DATA
    }
  });

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams
  } = useListResource(appointmentTypeApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          dispatch(forceFetchAppointmentType());
          closeConfirm();
        });
      }
    });
  };

  const handleOpenEdit =
    (uuid = '') =>
    () => {
      openEdit({
        uuid,
        onSuccess: () => {
          fetchData();
          dispatch(forceFetchAppointmentType());
          closeEdit();
        }
      });
    };

  return (
    <>
      <PageHeader title="Appointment type" />

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Button onClick={handleOpenEdit()}>Add new</Button>
        <SearchField
          placeholder="Search"
          sx={{ maxWidth: 400 }}
          onChange={(value) => {
            handleChangeParams('search', value || null);
          }}
        />
      </Stack>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...appointmentTypeColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <IconButton size="small" onClick={handleOpenEdit(uuid)}>
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
