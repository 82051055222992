import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { AppointmentTypeDto } from '../../../core/http';
import { useAppointmentType } from '../../../core/hooks/useAppointmentType';

export const AppointmentTypeField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useAppointmentType();

  const selectItems = map(items, (item: AppointmentTypeDto) => ({
    value: item.uuid,
    label: item.title
  }));

  return (
    <SelectField
      label="Location"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
