import { AppointmentsSearch } from '../../../features/appointments/AppointmentsSearch';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const AppointmentsSearchPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Appointments'
  })
)(AppointmentsSearch);
