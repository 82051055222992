import { BillingsContainer } from '../../features/billing/BillingsContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const BillingPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Billing'
  })
)(BillingsContainer);
