import { useEffect } from 'react';
import { SelectField } from '../select-field';
import map from 'lodash/map';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../core/store/store';
import { fetchEmployeeGroup } from '../../../core/store/slices/employeeGroupSlice';
import { EmployeeGroupDto } from '../../../core/http/dto/employee-group.dto';

export const EmployeeGroupField = ({ value, ...props }) => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.employeeGroup);

  useEffect(() => {
    dispatch(fetchEmployeeGroup({ limit: 1000 }));
  }, []);

  const items = get(findAll, 'payload.items', []);
  const loading = get(findAll, 'loading', false);
  const selectItems = map(items, (item: EmployeeGroupDto) => ({
    value: item.uuid,
    label: item.groupName
  }));

  return (
    <SelectField
      disabled={loading}
      label="Employee Group"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
