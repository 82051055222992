import React from 'react';
import { SuperAdminLayout } from '../../layouts/super-admin-layout';

// eslint-disable-next-line react/display-name
export const withSuperAdminLayout = (ComposedComponent) => (props) =>
  (
    <SuperAdminLayout>
      <ComposedComponent {...props} />
    </SuperAdminLayout>
  );
