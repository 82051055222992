import { useEditResource } from './useEditResource';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import { FileApi, TimesheetApi } from '../http';
import { has } from 'lodash';
import { differenceInMinutes, format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

const timesheetApi = new TimesheetApi();
const fileApi = new FileApi();
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import { useSnackbar } from './useSnackbar';

export const useTimesheetEdit = ({
  uuid,
  refClientSign,
  refProviderSign,
  onSuccess
}) => {
  const { showMessage } = useSnackbar();
  const {
    isEdit,
    isLoading,
    data: timesheet,
    handleSubmit,
    values,
    setFieldValue,
    getFieldTouchedError,
    errors
  } = useEditResource(timesheetApi, {
    uuid,
    initialValues: {
      payerUuid: '',
      serviceCode: '',
      startDateOfService: '',
      endDateOfService: '',
      unitOfService: '',
      hours: '',
      serviceAuthorizationFileUuid: '',
      clientSignatureFilled: false,
      providerSignatureFilled: false,
      providerName: '',
      clientName: ''
    },
    validationSchema: yup.object().shape({
      payerUuid: yup.string().required('Please select one of the options.'),
      startDateOfService: yup
        .date()
        .required('Please enter worked time.')
        .typeError('Please enter valid time.'),
      endDateOfService: yup
        .date()
        .required('Please enter worked time.')
        .typeError('Please enter valid time.'),
      unitOfService: yup
        .number()
        .required('Please enter unit of services.')
        .typeError('Units must contain only numbers.'),

      // clientSignatureFilled: yup
      // .boolean()
      // .test('is boolean', 'Please sign.', (value) => value === true),
      // .required('Please sign.'),
      providerSignatureFilled: yup
        .boolean()
        .test('is boolean', 'Please sign.', (value) => value === true)
        .required('Please sign.')
    }),
    normalizeSetValues: (values) => {
      const serviceDate = new Date(values.dateOfService);
      return {
        ...values,
        dateOfService: serviceDate,
        startDateOfService: new Date(values.startDateOfService),
        endDateOfService: new Date(values.endDateOfService),
        clientSignatureFilled: has(values, 'clientSignatureFileUuid'),
        providerSignatureFilled: has(values, 'providerSignatureFileUuid')
      };
    },
    onSubmit: async (values) => {
      let clientSignatureFileUuid = get(values, 'clientSignatureFileUuid');
      let providerSignatureFileUuid = get(values, 'providerSignatureFileUuid');

      if (refClientSign.current) {
        const clientFile = dataURLtoBlob(
          refClientSign.current.getCanvas().toDataURL('image/png')
        );

        clientSignatureFileUuid = uuidv4();

        await fileApi.create({
          guid: clientSignatureFileUuid,
          file: clientFile,
          fileName: 'client-signature',
          description: '',
          expirationDate: format(new Date(), 'yyyy-MM-dd'),
          noExpirationDate: true,
          type: 'licence',
          rootId: appointment.client.uuid,
          rootType: 'client'
        });
      }

      if (refProviderSign.current) {
        const providerFile = dataURLtoBlob(
          refProviderSign.current.getCanvas().toDataURL('image/png')
        );
        providerSignatureFileUuid = uuidv4();

        await fileApi.create({
          guid: providerSignatureFileUuid,
          file: providerFile,
          fileName: 'employee-signature',
          description: '',
          expirationDate: format(new Date(), 'yyyy-MM-dd'),
          noExpirationDate: true,
          type: 'licence',
          rootId: appointment.primaryTherapist.uuid,
          rootType: 'employee'
        });
      }

      timesheetApi
        .update({
          ...values,
          startDateOfService: format(
            values.startDateOfService,
            'yyyy-MM-dd HH:mm:ss'
          ),
          endDateOfService: format(
            values.endDateOfService,
            'yyyy-MM-dd HH:mm:ss'
          ),
          clientSignatureFileUuid,
          providerSignatureFileUuid
        })
        .then(() => {
          onSuccess && onSuccess();
        });
    },
    onSuccess: () => {
      showMessage(`Timesheet ${isEdit ? 'updated' : 'created'} successfully`);
    },
    onError: () => {
      showMessage('Something went wrong, please try again later.', 'error');
    }
  });

  const { appointment, authorization } = useMemo(() => {
    return {
      appointment: get(timesheet, 'appointment'),
      authorization: get(timesheet, 'authorization')
    };
  }, [timesheet]);

  useEffect(() => {
    if (!isEmpty(appointment)) {
      const providerName = `${appointment?.primaryTherapist?.firstName} ${appointment?.primaryTherapist?.lastName}`;
      const clientName = `${appointment?.client?.firstName} ${appointment?.client?.lastName}`;
      const payerName = get(
        get(appointment, 'client.contacts').find((x) => x.type === 'insured'),
        'payer.name',
        clientName
      );
      setFieldValue('payerName', payerName);
      setFieldValue('payerUuid', get(appointment, 'client.uuid', ''));

      setFieldValue('providerName', providerName);
      setFieldValue('clientName', clientName);

      const dateOfService = format(
        new Date(appointment?.startDate),
        'MM/dd/yyyy'
      );
      setFieldValue('dateOfService', dateOfService);
    }
  }, [appointment]);

  useEffect(() => {
    if (authorization) {
      const serviceCode = get(authorization, 'serviceCode.code');
      const serviceDescription = get(authorization, 'serviceCode.description');

      setFieldValue('serviceName', `${serviceCode} - ${serviceDescription}`);
    }
  }, [authorization]);

  useEffect(() => {
    if (values.startDateOfService && values.endDateOfService) {
      const diffInMinutes = differenceInMinutes(
        values.endDateOfService,
        values.startDateOfService
      );
      setFieldValue(
        'hours',
        diffInMinutes > 0 ? (diffInMinutes / 60).toFixed(1) : 0
      );

      if (diffInMinutes < 0) {
        setFieldValue('endDateOfService', values.startDateOfService);
      }
    }
  }, [values.startDateOfService, values.endDateOfService]);

  return {
    values,
    errors,
    handleSubmit,
    getFieldTouchedError,
    setFieldValue,
    appointment,
    isLoading
  };
};
