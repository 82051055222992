import { PageHeader } from '../../components/page-header';
import {
  Button,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@mui/material';
import { Card } from '../../components/cards';
import { Checkbox, TextField } from '../../components/fields';
import useStyles from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_ROLES } from '../../constants/route.paths';
import { useEditResource } from '../../core/hooks/useEditResource';
import * as yup from 'yup';
import { RoleApi } from '../../core/http';
import { useSnackbar } from '../../core/hooks/useSnackbar';

const roleApi = new RoleApi();

export const RolesForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { showMessage } = useSnackbar();

  const {
    isEdit,
    isLoading,
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(roleApi, {
    uuid: id,
    initialValues: {
      title: '',
      permissions: {
        viewAppointments: false,
        modifyAppointments: false,
        viewTargetedHoursCalendar: false,
        modifyTargetedHoursCalendar: false,
        viewOtherEmployeesAppointments: false,
        viewTimesheets: false,
        confirmTimesheets: false,
        unconfirmTimesheets: false,
        viewClientDashboard: false,
        viewEmployeeDashboard: false,
        viewBilledVsPlannedDashboard: false,
        viewDashboard: false,
        viewLeadershipDashboard: false,
        viewCaseloadManagementDashboard: false,
        viewMyReminders: false,
        modifyMyReminders: false,
        viewOtherEmployeeReminders: false,
        modifyOtherEmployeeReminders: false,
        viewAnnouncements: false,
        modifyAnnouncements: false,
        viewNews: false,
        modifyNews: false,
        viewMasterData: false,
        modifyMasterData: false,
        viewClients: false,
        modifyClients: false,
        viewEmployees: false,
        modifyEmployees: false,
        viewServiceAuthorizations: false,
        modifyServiceAuthorizations: false,
        viewUploadNewFiles: false,
        modifyUploadNewFiles: false,
        viewServiceAuthorizationReport: false,
        viewBillingReport: false,
        viewTimeCardReport: false,
        viewCancellationReport: false,
        viewCaseLoadTrackerReport: false,
        viewServiceCodes: false,
        modifyServiceCodes: false,
        viewFindingSource: false,
        modifyFindingSource: false,
        viewCreateRoleAndPermissions: false,
        modifyCreateRoleAndPermissions: false,
        modifyAssignRoleAndPermissions: false
      }
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is a required field')
    }),
    onSuccess: () => {
      showMessage(`User role ${isEdit ? 'updated' : 'created'} successfully`);
      navigate(ROUTE_ROLES);
    },
    onError: () => {
      showMessage('Something went wrong, please try again later.', 'error');
    }
  });

  const permissionsProps = (permission) => {
    return {
      checked: values.permissions[permission],
      onChange: (e, checked) =>
        setFieldValue(`permissions.${permission}`, checked)
    };
  };

  return (
    <>
      <PageHeader title={isEdit ? 'Edit User Role' : 'Create User Role'}>
        <Button
          disabled={isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
        <Button
          disabled={isLoading}
          color="third"
          onClick={() => navigate(ROUTE_ROLES)}
        >
          Cancel
        </Button>
      </PageHeader>

      <Card title="User role name">
        <TextField
          value={values.title}
          onChange={handleChange('title')}
          errors={getFieldTouchedError('title')}
          helperText={null}
        />
      </Card>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card title="Permissions" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Appointments</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewAppointments')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyAppointments')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Targeted Hours Calendar</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewTargetedHoursCalendar')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('modifyTargetedHoursCalendar')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Other employees appointments</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewOtherEmployeesAppointments')}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Timesheets" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Confirm</TableCell>
                  <TableCell width={100}>Unconfirm</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Timesheets</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewTimesheets')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('confirmTimesheets')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('unconfirmTimesheets')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Dashboards" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Dashboards Page</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewDashboard')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Client Dashboard</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewClientDashboard')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employee Dashboard</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewEmployeeDashboard')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Billed vs Planned Dashboard</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewBilledVsPlannedDashboard')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Leadership Dashboard</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewLeadershipDashboard')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Caseload Management Dashboard</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewCaseloadManagementDashboard')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Reminders" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>My reminders</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewMyReminders')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyMyReminders')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Other employees reminders</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewOtherEmployeeReminders')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('modifyOtherEmployeeReminders')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Announcements" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Announcements</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewAnnouncements')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyAnnouncements')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="News" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>News</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewNews')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyNews')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Master Data" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Master Data</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewMasterData')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyMasterData')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card title="Users" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Clients</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewClients')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyClients')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Employees</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewEmployees')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyEmployees')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Authorizations</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewServiceAuthorizations')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('modifyServiceAuthorizations')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Files" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Upload New Files</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewUploadNewFiles')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyUploadNewFiles')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          <Card title="Reports" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Service Authorization Report</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewServiceAuthorizationReport')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Billing Report</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewBillingReport')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Time Card Report</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewTimeCardReport')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cancellation Report</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewCancellationReport')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Case Load Tracker Report</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewCaseLoadTrackerReport')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
          {/* <Card title="Billing" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Service codes</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewServiceCodes')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyServiceCodes')} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Funding source</TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('viewFindingSource')} />
                  </TableCell>
                  <TableCell>
                    <Checkbox {...permissionsProps('modifyFindingSource')} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card> */}
          <Card title="User roles" mb={1}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Permission</TableCell>
                  <TableCell width={100}>View</TableCell>
                  <TableCell width={100}>Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Create Role and Permissions</TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('viewCreateRoleAndPermissions')}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('modifyCreateRoleAndPermissions')}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Assign Role and Permissions</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Checkbox
                      {...permissionsProps('modifyAssignRoleAndPermissions')}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
