import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import {
  Checkbox,
  NumberField,
  TextField,
  StateAutocompleteField,
  PhoneField
} from '../../components/fields';
import { get } from 'lodash';
import { CityAutocompleteField } from '../../components/fields';

export const EmployeeContactForm = ({
  isLoading,
  setFieldTouched,
  setFieldValue,
  handleBlur,
  values,
  errors,
  touched
}: any) => {
  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };
  const getFieldTouchedError = (fieldName: string) => {
    return get(touched, fieldName) && get(errors, fieldName);
  };

  const getFieldProps = (fieldName: string) => {
    return {
      disabled: isLoading,
      errors: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address Line 1"
            required
            {...getFieldProps('addressLine')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Address Line 2"
            {...getFieldProps('addressLineSecond')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item md={3} xs={12}>
          <CityAutocompleteField
            required
            label="City"
            setFieldValue={setFieldValue}
            {...getFieldProps('cityUuid')}
            errors={
              !getFieldValue('cityUuid') && getFieldTouchedError('cityUuid')
            }
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <StateAutocompleteField
            required
            label="State"
            setFieldValue={setFieldValue}
            {...getFieldProps('stateUuid')}
            errors={
              !getFieldValue('stateUuid') && getFieldTouchedError('stateUuid')
            }
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <NumberField
            label="Zip Code"
            {...getFieldProps('zipCode')}
            required
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2} mt={2}>
        <Grid item md={3} xs={12}>
          <PhoneField
            label="Primary Phone Number"
            required
            {...getFieldProps('phoneNumberPrimary')}
            errors={
              !getFieldValue('phoneNumberPrimary') &&
              getFieldTouchedError('phoneNumberPrimary')
            }
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <PhoneField
            label="Secondary Phone Number"
            {...getFieldProps('phoneNumberSecondary')}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <PhoneField
            label="Work Phone Number"
            {...getFieldProps('phoneNumberWork')}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container mt={3}>
        <Grid item>
          <Typography color="common.grey" variant="subtitle1" mb={2}>
            Notifications
          </Typography>
          <FormControlLabel
            label={
              <Typography variant="body3">Enable Text Notifications</Typography>
            }
            control={
              <Checkbox
                checked={getFieldValue('notifications')}
                onChange={(e, checked) =>
                  setFieldValue('notifications', checked)
                }
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

EmployeeContactForm.displayName = 'EmployeeContactForm';
