import { Expose } from 'class-transformer';

export class FundingSourceContractDto {
  @Expose()
  uuid: string;

  @Expose()
  fundingSourceUuid: string;

  @Expose()
  serviceCodeUuid: string;

  @Expose()
  serviceDescriptionUuid: string;

  @Expose()
  rate: number;

  @Expose()
  periodType: string;

  @Expose()
  procedureInfo: number;

  @Expose()
  unitLength: number;
}
