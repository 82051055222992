import { Badge } from '../../../components/badge';

export const fundingSourceColumns = [
  {
    title: 'Vendor Name',
    key: 'name',
    dataIndex: 'name',
    cellProps: {
      sx: {
        width: '40%'
      }
    }
  },
  {
    title: 'Service Code',
    key: 'serviceCode',
    dataIndex: 'serviceCode',
    render: (_, { contracts }) => (
      <>
        {contracts.map((item) => (
          <Badge
            key={item.uuid}
            label={item.serviceCode.code}
            sx={{ marginRight: '4px' }}
            variant="success"
          />
        ))}
      </>
    )
  },
  {
    title: 'Service Description',
    key: 'serviceDescription',
    dataIndex: 'serviceDescription',
    render: (_, { contracts }) => (
      <>
        {contracts.map((item) => (
          <Badge
            key={item.uuid}
            label={item.serviceCode.description}
            sx={{ marginRight: '4px' }}
            variant="success"
          />
        ))}
      </>
    )
  }
];

export const fundingSourceData = [
  {
    id: 1,
    vendor: 'Aetna',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 2,
    vendor: 'Anthem Blue Cross/Blue Shield',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 3,
    vendor: 'Anthem Medi-Cal',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 4,
    vendor: 'Beacon',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 5,
    vendor: 'Central California Alliance for Health',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 6,
    vendor: 'Cigna',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 7,
    vendor: 'Coastal',
    serviceCode: '97151',
    serviceDescription: 'Speech & Language'
  },
  {
    id: 8,
    vendor: 'Magellan',
    serviceCode: '97151',
    serviceDescription: 'Speech & Language'
  },
  {
    id: 9,
    vendor: 'San Andreas Regional Center',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 10,
    vendor: 'Santa Clara Family Health Plan',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 11,
    vendor: 'Central California Alliance for Health',
    serviceCode: '97151',
    serviceDescription: 'Speech & Language'
  },
  {
    id: 12,
    vendor: 'Anthem Blue Cross/Blue Shield',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment'
  }
];
