import React, { ComponentType, useEffect, useState } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from './TabPanel';
import { Divider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export type TabType = {
  key: string;
  label: string;
  content: any;
  isHiddenTab?: boolean;
};

interface Props {
  items: TabType[];
  divider?: boolean;
  onChange?: (key: string) => void;
  activeTabIndex?: number;
  hashed?: boolean;
  isRemovable?: boolean;
  onRemove?: (arg: any) => void;
  isDisabled?: boolean;
}

export const Tabs: ComponentType<Props> = ({
  onChange,
  divider = true,
  items,
  activeTabIndex = 0,
  hashed = false,
  isDisabled = false
}) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<number>(activeTabIndex);
  const [data, setData] = useState(items || []);
  const { hash, pathname } = useLocation();

  const handleChange = (event: any, newValue: number) => {
    const key = data[newValue].key;
    setValue(newValue);
    onChange?.(key);
    if (hashed) {
      navigate(pathname + `#${key}`);
    }
  };

  useEffect(() => {
    setData(items);
  }, [items]);

  useEffect(() => {
    if (hash && hashed) {
      const index = data.findIndex((item) => item.key === hash.slice(1));
      if (index !== -1) {
        handleChange(null, index);
      }
    }
  }, [hash]);

  return (
    <>
      <Box>
        <MuiTabs value={value} onChange={handleChange}>
          {data.map((item, index) => {
            return (
              <MuiTab
                disabled={isDisabled}
                key={index}
                label={item.label}
                disableRipple
              />
            );
          })}
        </MuiTabs>
        {divider && <Divider sx={{ mx: -3, mt: '-2px' }} />}
      </Box>
      {data.map((item, index) => (
        <TabPanel key={index} value={value} index={index}>
          {item.content}
        </TabPanel>
      ))}
    </>
  );
};
