import { DataTableColumn } from '../components/table-view/TableView';
import { normalizeDate, timeConverter } from '../core';
import { UserRecord } from '../components/user-record';
import { Badge } from '../components/badge';
import get from 'lodash/get';

export const clientAppointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'time',
    sortable: false,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'Therapist',
    dataIndex: 'primaryTherapist',
    key: 'therapist',
    render: ({ displayName }) => (
      <UserRecord
        user={{
          avatar: 'none',
          name: displayName
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (value) => <Badge label={value} variant={value} />
  },
  {
    title: 'Type',
    dataIndex: 'type.title',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location.name',
    key: 'location'
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  }
];

export const employeeAppointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'time',
    sortable: false,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'First Name',
    dataIndex: 'client',
    key: 'firstName',
    render: (client) => get(client, 'firstName', '-')
  },
  {
    title: 'Last Name',
    dataIndex: 'client',
    key: 'lastName',
    render: (client) => get(client, 'lastName', '-')
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (value) => {
      return <Badge label={value} variant={value} />;
    }
  },
  {
    title: 'Type',
    dataIndex: 'type.title',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location.name',
    key: 'location'
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  }
];
