import { useEffect, useState } from 'react';

export const useCalendarLayout = (subtractHeight?: number) => {
  const [contentHeight, setContentHeight] = useState(0);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const calculateHeight = () => {
      const height =
        window.innerHeight - (subtractHeight ? subtractHeight : 253);

      setContentHeight(height);
    };

    calculateHeight();

    window.addEventListener('resize', calculateHeight, true);

    return () => window.removeEventListener('resize', calculateHeight);
  }, []);

  return {
    contentHeight,
    open,
    setOpen
  };
};
