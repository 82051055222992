import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import Button from '@mui/material/Button';
import { SelectField } from '../../../components/fields/select-field';
import { TextField, PhoneField } from '../../../components/fields';

interface Props extends DialogProps {
  uuid: string;
  userName: string;
  onClose?: () => void;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const SuperAdminAddNewAdministratorDialog: FC<Props> = ({
  popDialog
}) => {
  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>Add new administrator</DialogTitle>
      <DialogContent dividers={false}>
        <SelectField
          label="Status"
          required
          items={[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' }
          ]}
          placeholder="Select"
        />
        <TextField label="First Name" required />
        <TextField label="Last Name" required />
        <TextField label="Email Address" required />
        <PhoneField label="Phone number" required />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={popDialog}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
