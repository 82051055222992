import { Box, Stack, Typography } from '@mui/material';
import { Checkbox } from '../../../components/fields';

export const DashboardCard = ({
  title,
  description,
  checked = false,
  onSelect
}) => {
  return (
    <Box
      px={2}
      py={5}
      borderRadius={0.5}
      alignItems="center"
      minWidth={282}
      justifyContent="center"
      sx={{
        backgroundColor: checked ? '#E5F0FF' : '#F9FBFE',
        cursor: 'pointer'
      }}
      onClick={onSelect}
    >
      <Stack direction="row">
        <Checkbox checked={checked} />
        <Box alignItems="center" justifyContent="center" ml={2}>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="subtitle2" color="#647593" mt={0.5}>
            {description}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
