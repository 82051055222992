import { SelectField } from '../select-field';
import { useEffect } from 'react';
import map from 'lodash/map';
import { ClientDto } from '../../../core/http';
import { fetchClient } from '../../../core/store/slices/clientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../core/store/store';
import { get } from 'lodash';

export const ClientField = ({ value, status = '', ...props }) => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.client);

  useEffect(() => {
    if (status) {
      dispatch(fetchClient({ limit: 1000, 'filter[status][eq]': status }));
    } else {
      dispatch(fetchClient({ limit: 1000 }));
    }
  }, [status]);

  const items = get(findAll, 'payload.items', []);
  const loading = get(findAll, 'loading', false);
  const selectItems = map(items, (item: ClientDto) => ({
    value: item.uuid,
    label: item.displayName
  }));

  return (
    <SelectField
      disabled={loading}
      label="Clients"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
