export const profitCenterColumns = [
  {
    title: 'Profit Center',
    key: 'profitCentre',
    dataIndex: 'profitCentre'
  },
  {
    title: 'Company/TAX ID',
    key: 'taxId',
    dataIndex: 'taxId'
  },
  {
    title: 'Office Name',
    key: 'officeName',
    dataIndex: 'officeName'
  }
];

export const profitCenterData = [
  { id: 1, name: 'Insurance OT', taxId: '113958', officeName: 'STS-Salinas' }
];
