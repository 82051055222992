import { ComponentType, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions
} from '@mui/material';
import { DialogProps } from '../../../../core/providers/dialog.provider';
import { useEditResource } from '../../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { StateApi } from '../../../../core/http/requests/state.api';

interface Props extends DialogProps {
  onClose?: () => void;
  onSuccess?: () => void;
}
const stateApi = new StateApi();

export const CreateStateDialog: ComponentType<Props> = ({
  popDialog,
  onSuccess,
  stateTitle
}: any) => {
  const [createUuid] = useState(uuidv4());
  const {
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError,
    isLoading
  } = useEditResource(stateApi, {
    createUuid,
    uuid: null,
    initialValues: {
      title: stateTitle || ''
    },
    normalizeValues: (values) => {
      return { ...values, guid: uuidv4() };
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('This field is required')
    }),
    onSuccess
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 400
        }
      }}
    >
      <DialogTitle>Create a new state</DialogTitle>
      <DialogContent>
        <TextField
          disabled={isLoading}
          label="Title"
          required
          onChange={handleChange('title')}
          value={values.title}
          error={getFieldTouchedError('title')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
