import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const ScheduledVsAuthorized = ({
  y2dAuthorizedRevenueByServiceCode,
  y2dScheduledRevenueByServiceCode
}) => {
  const labels = y2dAuthorizedRevenueByServiceCode.map((item) => item.code);

  const y2dTotalHoursByServiceCode = y2dAuthorizedRevenueByServiceCode.map(
    (item) => {
      const scheduled = y2dScheduledRevenueByServiceCode.find(
        (scheduled) => scheduled.code === item.code
      );
      if (scheduled) {
        return {
          ...item,
          value: +item.value + +scheduled.value
        };
      } else {
        return item;
      }
    }
  );

  const getTotalByIndex = (index) =>
    +y2dAuthorizedRevenueByServiceCode[index].value +
    +y2dScheduledRevenueByServiceCode[index].value +
    +y2dTotalHoursByServiceCode[index].value;

  const data = {
    labels,
    datasets: [
      {
        label: 'Y2D Authorized Hours',
        data: y2dAuthorizedRevenueByServiceCode.map(
          (item, index) => item.value / getTotalByIndex(index)
        ),
        expenditures: y2dAuthorizedRevenueByServiceCode.map(
          (item) => item.value
        ),
        backgroundColor: '#FF967E',
        borderWidth: 0,
        stack: 'Stack 0',
        categoryPercentage: 1.05
      },
      {
        label: 'Y2D Scheduled Hours',
        data: y2dScheduledRevenueByServiceCode.map(
          (item, index) => item.value / getTotalByIndex(index)
        ),
        expenditures: y2dScheduledRevenueByServiceCode.map(
          (item) => item.value
        ),
        backgroundColor: '#FFCB7E',
        borderWidth: 0,
        stack: 'Stack 0',
        categoryPercentage: 1.05
      },
      {
        label: 'Y2D Total Hours',
        data: y2dTotalHoursByServiceCode.map(
          (item, index) => item.value / getTotalByIndex(index)
        ),
        expenditures: y2dTotalHoursByServiceCode.map((item) => item.value),
        backgroundColor: '#FEEF68',
        borderWidth: 0,
        stack: 'Stack 0',
        categoryPercentage: 1.05
      }
    ]
  };
  return (
    <Card height={428} title="$ Scheduled vs Authorized" width="100%">
      <Bar
        data={data}
        options={options}
        style={{ maxHeight: 292, width: '100%' }}
      />
    </Card>
  );
};
