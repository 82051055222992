import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ROUTE_SIGN_IN } from '../constants/route.paths';

export const ProtectedRoute = () => {
  const auth = !!localStorage.getItem('login'); // determine if authorized, from context or however you're doing it

  return auth ? <Outlet /> : <Navigate to={ROUTE_SIGN_IN} />;
};
