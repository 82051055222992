export const notificationData = [
  {
    id: 1,
    title: 'Confirm all appointments',
    description: 'Due date/time 03/24/2022  6:00PM',
    date: '03/24/2022  6:00PM',
    readAt: null
  },
  {
    id: 2,
    title: 'Call to Kristin Mare',
    description: 'Talk about appointments on next week.',
    file: {
      name: 'Appoinment.pdf',
      url: 'https://google.com'
    },
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 3,
    title: 'Confirm all appointments',
    description: 'Due date/time 03/24/2022  6:00PM',
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 4,
    title: 'Call to Kristin Mare',
    description: 'Talk about appointments on next week.',
    file: {
      name: 'Appoinment.pdf',
      url: 'https://google.com'
    },
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 5,
    title: 'Confirm all appointments',
    description: 'Due date/time 03/24/2022  6:00PM',
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 6,
    title: 'Call to Kristin Mare',
    description: 'Talk about appointments on next week.',
    file: {
      name: 'Appoinment.pdf',
      url: 'https://google.com'
    },
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 7,
    title: 'Confirm all appointments',
    description: 'Due date/time 03/24/2022  6:00PM',
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 8,
    title: 'Call to Kristin Mare',
    description: 'Talk about appointments on next week.',
    file: {
      name: 'Appoinment.pdf',
      url: 'https://google.com'
    },
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  },
  {
    id: 9,
    title: 'Confirm all appointments',
    description: 'Due date/time 03/24/2022  6:00PM',
    date: '03/24/2022  6:00PM',
    readAt: new Date()
  }
];
