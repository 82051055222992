import { useState, useEffect, FC } from 'react';
import {
  OptionsAutocomplete,
  OptionsAutocompleteProps,
  Options
} from '../options-autocomplete';
import { useActiveClient } from '../../../core/hooks/useActiveClient';
import useStyles from './styles';
import clsx from 'clsx';

type Props = {
  label?: string;
} & Omit<OptionsAutocompleteProps, 'options'>;

export const ActiveClientAutocompleteField: FC<Props> = (props) => {
  const [options, setOptions] = useState<Options[]>([]);
  const classes = useStyles();

  const { items, itemsLoading } = useActiveClient();

  useEffect(() => {
    setOptions(
      items.map((item) => ({ label: item.displayName, value: item.uuid }))
    );
  }, [items]);

  return (
    <OptionsAutocomplete
      className={clsx(classes.root, { [classes.loading]: itemsLoading })}
      {...{
        loading: itemsLoading,
        options: options.sort((a, b) => (a.label > b.label ? 1 : -1))
      }}
      {...props}
      placeholder={itemsLoading ? 'Loading...' : props.placeholder}
    />
  );
};
