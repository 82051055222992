import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  options: {
    scales: {
      xAxis: {
        gridLines: {
          drawOnChartArea: false
        }
      }
    }
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      position: 'right' as const,
      labels: {
        font: {
          size: 12,
          weight: '500'
        }
      }
    },
    title: {
      display: false
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: false
      },
      ticks: {
        display: false,
        color: '#4B5663'
      }
    },
    yAxis: {
      grid: {
        drawBorder: false
      },
      scaleLabel: {
        display: false
      },
      min: 0,
      max: 100,
      ticks: {
        color: '#172B4D',
        stepSize: 20,
        font: {
          size: 10,
          weight: '500px'
        },
        callback: (value) => {
          return value + '%';
        }
      }
    }
  }
};
