import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { ProfitCenterApi } from '../../../core/http';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { TextField } from '../../../components/fields';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const profitCenterApi = new ProfitCenterApi();

export const ProfitCenterEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(profitCenterApi, {
    uuid,
    initialValues: {
      profitCentre: '',
      taxId: '',
      officeName: ''
    },
    validationSchema: yup.object().shape({
      profitCentre: yup.string().required('Profit center is a required field'),
      taxId: yup.number().required('Company Tax ID is a required field'),
      officeName: yup.string().required('Office Name is a required field')
    }),
    normalizeValues: (values) => {
      return {
        ...values,
        taxId: parseInt(values.taxId, 10) || values.taxId
      };
    },
    onSuccess,
    onError
  });

  return (
    <Dialog open onClose={popDialog}>
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField
          label="Profit Center"
          value={values.profitCentre}
          onChange={handleChange('profitCentre')}
          errors={getFieldTouchedError('profitCentre')}
        />
        <TextField
          label="Company Tax ID"
          type="number"
          value={values.taxId}
          onChange={handleChange('taxId')}
          errors={getFieldTouchedError('taxId')}
        />
        <TextField
          label="Office Name"
          value={values.officeName}
          onChange={handleChange('officeName')}
          errors={getFieldTouchedError('officeName')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
