import { API_USER_LOGOUT } from '../api.routes';
import { BaseApi } from './base.api';

export class AuthApi extends BaseApi {
  /**
   * Logout
   * @param uuid
   */
  public logout(): Promise<any> {
    return this.httpClient.get(API_USER_LOGOUT).catch(() => {
      return;
    });
  }
}
