import React from 'react';
import {
  Badge,
  BadgeProps,
  Box,
  IconButton,
  Popover,
  PopoverProps,
  styled
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { IconMail } from '../../components/icons';
import { NotificationList } from './NotificationList';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 7,
    border: '1px solid #fff',
    minWidth: 7,
    height: 7
  }
}));

const StyledPopover = styled(Popover)<PopoverProps>(() => ({
  '& .MuiPopover-paper': {
    border: 0,
    boxShadow: '0px 8px 16px rgba(23, 43, 77, 0.16)',
    borderRadius: 8,
    maxHeight: '100%!important'
  }
}));

export const NotificationDropdown = () => {
  return (
    <PopupState variant="popover" popupId="header-notification">
      {(popupState) => (
        <>
          <IconButton
            size="medium"
            {...bindTrigger(popupState)}
            sx={{
              backgroundColor: popupState.isOpen ? '#0052CC' : 'transparent',
              color: popupState.isOpen ? '#fff' : '#496492',
              '& .MuiBadge-badge': {
                backgroundColor: popupState.isOpen ? '#fff' : '#496492',
                borderColor: popupState.isOpen ? '#0052CC' : '#fff'
              }
            }}
          >
            <StyledBadge color="primary" variant="dot" badgeContent={1}>
              <IconMail />
            </StyledBadge>
          </IconButton>
          <StyledPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 41,
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Box p={2} minWidth={377}>
              <NotificationList close={popupState.close} />
            </Box>
          </StyledPopover>
        </>
      )}
    </PopupState>
  );
};
