import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  PlaceServiceField,
  StatesField,
  TextField,
  TimezoneField
} from '../../../components/fields';
import { useEditResource } from '../../../core/hooks/useEditResource';
import { LocationApi } from '../../../core/http';
import * as yup from 'yup';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const locationApi = new LocationApi();

export const LocationEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const { isEdit, isLoading, handleSubmit, getFieldProps } = useEditResource(
    locationApi,
    {
      uuid,
      initialValues: {
        name: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        region: '',
        timeZone: '',
        servicePlaceUuid: ''
      },
      validationSchema: yup.object().shape({
        name: yup.string().required('Name is a required field'),
        address: yup.string().required('Address is a required field'),
        city: yup.string().required('City is a required field'),
        state: yup.string().required('State is a required field'),
        zipCode: yup.string().required('Zip Code is a required field'),
        region: yup.string().required('Region is a required field'),
        timeZone: yup.string().required('Time Zone is a required field'),
        servicePlaceUuid: yup
          .string()
          .required('Place of Service is a required field')
      }),
      onSuccess,
      onError
    }
  );

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 640 } }}
    >
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField label="Location" {...getFieldProps('name')} />
        <TextField label="Address" {...getFieldProps('address')} />
        <TextField label="Region" {...getFieldProps('region')} />
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <TextField label="City" {...getFieldProps('city')} />
          </Grid>
          <Grid item xs={6}>
            <StatesField label="State" {...getFieldProps('state')} />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <TextField label="Zip Code" {...getFieldProps('zipCode')} />
          </Grid>
          <Grid item xs={6}>
            <TimezoneField label="Time Zone" {...getFieldProps('timeZone')} />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <PlaceServiceField
              label="Place of Service"
              {...getFieldProps('servicePlaceUuid')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
