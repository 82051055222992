import { plainToClass } from 'class-transformer';
import {
  API_FILE_CREATE,
  API_FILE_DELETE,
  API_FILE_FIND_ALL,
  API_FILE_FIND_ONE,
  API_FILE_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { FileDto } from '../dto';
import { FindAllResponse } from '../types';

export class FileApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<FileDto>> {
    return this.httpClient
      .get(API_FILE_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(FileDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<FileDto> {
    return this.httpClient
      .get(API_FILE_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(FileDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<FileDto> {
    return this.httpClient
      .post(API_FILE_CREATE, data, {
        formData: true
      })
      .then(({ data }) => {
        return plainToClass(FileDto, data);
      });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<FileDto> {
    return this.httpClient.put(API_FILE_UPDATE, data).then(({ data }) => {
      return plainToClass(FileDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_FILE_DELETE, {
      pathParams: { uuid }
    });
  }
}
