import React, { ComponentType } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {
  DataTableColumn,
  DataTableRowExpandable,
  DataTableRowSelection
} from './TableView';
import { Checkbox } from '../fields';
import get from 'lodash/get';

interface DataTableRowProps {
  item: Record<string, any>;
  columnsCount: number;
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onClick?: (item: Record<string, any>) => void;
  selectedRows: any[];
  setSelectedRows: (id: any) => void;

  expandable?: DataTableRowExpandable;
  renderProps: any;
  keyExtractor: (item: Record<string, any>) => string;
}

export const TableViewRow: ComponentType<DataTableRowProps> = ({
  columnsCount,
  rowSelection,
  item,
  columns,
  onClick,
  selectedRows,
  setSelectedRows,
  expandable,
  renderProps = {},
  keyExtractor
}) => {
  const id = keyExtractor(item);
  const isSelectable = !!rowSelection;
  const isSelected = selectedRows.includes(id);
  const isExpandable =
    expandable && expandable.rowExpandable && expandable.rowExpandable(item);

  const handleSelect = () => {
    setSelectedRows(id);
  };

  return (
    <>
      <TableRow
        onClick={() => {
          onClick && onClick(item);
        }}
        sx={{
          '&:hover': {
            backgroundColor: '#F4F6FF'
          }
        }}
      >
        {isSelectable && (
          <TableCell>
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={handleSelect}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = get(item, column.dataIndex);
          return (
            <TableCell
              key={keyExtractor(item) + '_' + column.key}
              {...column.cellProps}
            >
              {column.render ? column.render(value, item, renderProps) : value}
            </TableCell>
          );
        })}
      </TableRow>
      {isExpandable && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={columnsCount}>
            <Collapse
              timeout="auto"
              unmountOnExit
              sx={{ backgroundColor: 'background.light' }}
            >
              <Box sx={{ padding: 2 }}>
                {expandable.expandedRowRender &&
                  expandable.expandedRowRender(item)}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
