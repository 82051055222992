import { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import {
  DatePicker,
  EmployeeAutocompleteField,
  SelectField
} from '../../../components/fields';
import get from 'lodash/get';
import { Card } from '../../../components/cards';
import { DoughnutChart } from '../../../components/charts';
import { GroupedBarChart } from '../../../components/charts';
import { times } from 'lodash';
import { LabeledList } from '../../../components/labeled-list';
import { useCancellationReport } from '../../../core/hooks/useCancellationReport';
import { CancellationReportApi } from '../../../core/http/requests/cancellation-report.api';

const cancellationReportApi = new CancellationReportApi();

const random = (min: number, max: number) => {
  return parseFloat((Math.random() * (max - min + 1) + min).toFixed(2));
};

export const Cancellations = () => {
  const { data, isLoading, handleChangeParams } = useCancellationReport(
    cancellationReportApi
  );

  const [filter, setFilter] = useState<any>({
    graph: 'pie'
  });

  const handleFilterChange = (field) => (value) => {
    setFilter({ ...filter, [field]: value });
  };

  const handleFiltersApply = () => {
    handleChangeParams(filter);
  };

  return (
    <>
      <Card mb={3}>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <Box flex="0 0 150px">
            <DatePicker
              disableInput
              label="From"
              size="small"
              helperText={null}
              value={get(filter, 'filter[startDate][gt]', null)}
              onChange={handleFilterChange('filter[startDate][gt]')}
              maxDate={
                get(filter, 'filter[endDate][lt]', null) &&
                new Date(get(filter, 'filter[endDate][lt]', null))
              }
            />
          </Box>
          <Box flex="0 0 150px">
            <DatePicker
              disableInput
              label="To"
              size="small"
              helperText={null}
              value={get(filter, 'filter[endDate][lt]', null)}
              onChange={handleFilterChange('filter[endDate][lt]')}
              minDate={new Date(get(filter, 'filter[startDate][gt]', null))}
            />
          </Box>
          <SelectField
            label="Graph"
            size="small"
            helperText={null}
            items={[{ value: 'pie', label: 'Pie' }]}
            value={get(filter, 'graph', 'pie')}
            onChange={handleFilterChange('graph')}
          />
          <EmployeeAutocompleteField
            label="Provider"
            placeholder="Select"
            size="small"
            helperText={null}
            forcePopupIcon
            value={get(filter, 'filter[employee.uuid][eq]')}
            onChange={handleFilterChange('filter[employee.uuid][eq]')}
          />
          <Button onClick={() => handleFiltersApply()}>Search</Button>
        </Stack>
      </Card>
      {!isLoading && (
        <Card p={3}>
          {get(filter, 'graph') === 'pie' ? (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <DoughnutChart
                items={[
                  {
                    label: 'Missed MakeUp Hours',
                    color: '#3BD07A',
                    value: data.rateAmountCancelled,
                    displayValue: `${data.hoursCancelled - data.hoursMakeUp} h`
                  },
                  {
                    label: 'MakeUp Hours',
                    color: '#FFCD4D',
                    value: data.rateAmountMakeUp,
                    displayValue: `${data.hoursMakeUp} h`
                  }
                ]}
              />
              <LabeledList
                items={[
                  {
                    label: 'Hours Cancelled',
                    value: data.hoursCancelled
                  },
                  {
                    label: 'Staff Cancellations',
                    value: data.staffCancellation
                  },
                  {
                    label: 'Parent Cancellations',
                    value: data.parentCancellation
                  },
                  {
                    label: 'Hours MadeUp',
                    value: data.hoursMakeUp
                  },
                  {
                    label: 'Percentage of MadeUp Hours',
                    value: `${data.hoursMakeUp}%`
                  },
                  {
                    label: 'Rate Amt Cancelled',
                    value: `$${data.rateAmountCancelled}`
                  },
                  {
                    label: 'Rate Amt MadeUp',
                    value: `$${data.rateAmountMakeUp}`
                  }
                ]}
              />
            </Stack>
          ) : (
            <>
              <GroupedBarChart
                labels={[
                  '01/12/2022',
                  '01/13/2022',
                  '01/14/2022',
                  '01/15/2022',
                  '01/16/2022',
                  '01/17/2022',
                  '01/18/2022',
                  '01/19/2022',
                  '01/20/2022',
                  '01/21/2022',
                  '01/22/2022',
                  '01/23/2022',
                  '01/24/2022',
                  '01/25/2022'
                ]}
                items={[
                  {
                    label: 'Parent Cancellations Hrs',
                    color: '#3BD07A',
                    data: times(14, () => random(100.0, 120.0)),
                    stack: 'stack0',
                    datalabels: {
                      align: 'start',
                      anchor: 'end'
                    }
                  },
                  {
                    label: 'Staff Cancellations Hrs',
                    color: '#749AFD',
                    data: times(14, () => random(40, 30)),
                    stack: 'stack0'
                  },
                  {
                    label: 'Missed MakeUp Hours',
                    color: '#EAD733',
                    data: times(14, () => random(80, 100)),
                    stack: 'stack1'
                  },
                  {
                    label: 'MakeUp Hours',
                    color: '#EAA133',
                    data: times(14, () => random(10, 50)),
                    stack: 'stack2'
                  }
                ]}
              />

              <LabeledList
                WrapProps={{
                  mt: 5,
                  ml: 'auto',
                  mr: 'auto'
                }}
                items={[
                  {
                    label: 'Hours Cancelled',
                    value: '324'
                  },
                  {
                    label: 'Staff Cancellations',
                    value: '12'
                  },
                  {
                    label: 'Parent Cancellations',
                    value: '312'
                  },
                  {
                    label: 'Hours MadeUp',
                    value: '111.33'
                  },
                  {
                    label: 'Percentage of MadeUp Hours',
                    value: '34.25%'
                  },
                  {
                    label: 'Rate Amt Cancelled',
                    value: '$21,663.54'
                  },
                  {
                    label: 'Rate Amt MadeUp',
                    value: '$7,473.04'
                  }
                ]}
              />
            </>
          )}
        </Card>
      )}
    </>
  );
};
