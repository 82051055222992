import { useNavigate } from 'react-router';
import { Stack, Grid, Button } from '@mui/material';
import { Card } from '../../components/cards';
import { Badge } from '../../components/badge';
import { LabeledRecord } from '../../components/labeled-record';
import { PageHeader } from '../../components/page-header';
import { IconInfo } from '../../components/icons';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import {
  ROUTE_SUPER_ADMIN_COMPANIES,
  ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { useLayout } from '../../core/hooks/useLayout';
import { useDialog } from '../../core/hooks/useDialog';
import { SuperAdminChangeAdministratorDialog } from './dialogs/SuperAdminChangeAdministratorDialog';
import { SuperAdminAddNewAdministratorDialog } from './dialogs/SuperAdminAddNewAdministratorDialog';
import { SuperAdminHistoryDialog } from './dialogs/SuperAdminHistoryDialog';

const headerProps = {
  divider: false,
  title: 'Diffco',
  meta: (
    <>
      <Badge label={'312'} variant={'secondary'} />
      <Badge label={'Active'} variant="active" RightIcon={<IconInfo />} />
    </>
  )
};

const backUrl = url(ROUTE_SUPER_ADMIN_COMPANIES);

export const SuperAdminCompanyProfileContainer = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const [openChangeAdministratorDialog] = useDialog(
    SuperAdminChangeAdministratorDialog
  );
  const [openAddNewAdministratorDialog] = useDialog(
    SuperAdminAddNewAdministratorDialog
  );
  const [openHistoryDialog] = useDialog(SuperAdminHistoryDialog);
  const navigate = useNavigate();

  useLayout({
    backButton: {
      title: 'Companies',
      path: backUrl
    }
  });

  const handleConfirmResetPassword = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm reset password',
      message:
        'Do you really want to reset the password for Dianne Russel (Diffco)?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleConfirmReset2FA = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm reset 2-factor authentication',
      message: 'Do you really want to reset 2FA for Dianne Russel (Diffco)?',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleConfirmCompanyDeactivation = (uuid: string) => () => {
    openConfirm({
      title: 'Confirm company deactivation',
      message:
        'Do you really want to deactivate company profile? All company users will have an "INACTIVE" status',
      onConfirm: () => {
        console.log(uuid);
        closeConfirm();
      }
    });
  };

  const handleChangeAdministratorDialog = () => {
    openChangeAdministratorDialog();
  };

  const handleAddNewAdministratorDialog = () => {
    openAddNewAdministratorDialog();
  };

  const handleHistoryDialog = () => {
    openHistoryDialog();
  };

  return (
    <>
      <PageHeader {...headerProps}>
        <Button>Send invite again</Button>
        <Button
          color="secondary"
          onClick={() =>
            navigate(url(ROUTE_SUPER_ADMIN_COMPANY_PROFILE_EDIT, { id: '312' }))
          }
        >
          Edit
        </Button>
        <Button color="third" onClick={handleConfirmCompanyDeactivation('123')}>
          Deactivate
        </Button>
      </PageHeader>
      <Stack mt={1.5} spacing={1} direction="column">
        <Card title="Contact Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Address:"
                value="2118 Thornridge Cir. Syracuse"
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="City:" value="Sunnyvale" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Province/State:" value="California" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Zip code:" value="93043" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Primary Phone Number:"
                value="(465) 432-5432"
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value="(465) 432-5432"
              />
            </Grid>
          </Grid>
        </Card>
        <Card title="General Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Address:"
                value="2118 Thornridge Cir. Syracause"
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="City:" value="Sunnyvale" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Province/State:" value="California" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Zip code:" value="93043" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Primary Phone Number:"
                value="(465) 432-5432"
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value="(465) 432-5432"
              />
            </Grid>
          </Grid>
        </Card>
        <Card
          title="Administrator Information"
          meta={
            <Stack direction="row" spacing={1}>
              <Button
                color="secondary"
                onClick={handleAddNewAdministratorDialog}
              >
                Add new
              </Button>
              <Button
                color="secondary"
                onClick={handleChangeAdministratorDialog}
              >
                Change
              </Button>
              <Button color="third" onClick={handleConfirmReset2FA('123')}>
                Reset 2FA
              </Button>
              <Button color="third" onClick={handleConfirmResetPassword('123')}>
                Reset password
              </Button>
              <Button color="third" onClick={handleHistoryDialog}>
                History
              </Button>
            </Stack>
          }
        >
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord label="Name:" value="Debra Lane" />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Email address:"
                value="debra.lane@gmail.com"
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Phone Number:" value="(465) 432-5432" />
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};
