import { ComponentType } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { url } from '../../core';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import { SelectField } from '../../components/fields';
import { ROUTE_SUPER_ADMIN_ROLES } from '../../constants/route.paths';
import { useLayout } from '../../core/hooks/useLayout';
import { PermissionsContainer } from '../../components/permissions-container';

const companiesPermissions = [
  {
    name: 'Company Profile'
  },
  { name: 'Add company administrators' }
];
const splashWorksPermissions = [
  {
    name: 'Users'
  },
  { name: 'Roles' }
];

export const SuperAdminRoleEditContainer: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const headerProps = {
    divider: true,
    title: <Typography variant="h2">{id ? 'Edit' : 'Add new'} role</Typography>
  };

  const backUrl = url(ROUTE_SUPER_ADMIN_ROLES);

  useLayout({
    backButton: {
      title: 'Roles',
      path: backUrl
    }
  });

  return (
    <>
      <PageHeader title="" {...headerProps}>
        <Button onClick={() => navigate(ROUTE_SUPER_ADMIN_ROLES)} color="third">
          Cancel
        </Button>
        <Button>Update</Button>
      </PageHeader>
      <Card title="Role name">
        <Grid container mt={2} spacing={1}>
          <Grid item md={6}>
            <SelectField
              required
              items={[
                { label: 'Super Administrator', value: '123' },
                { label: 'Administrator', value: '124' },
                { label: 'Sales Manager', value: '126' }
              ]}
              placeholder="Select"
            />
          </Grid>
        </Grid>
      </Card>
      <Grid container mt={2} spacing={1}>
        <Grid item md={6}>
          <Card title="Companies">
            <PermissionsContainer items={companiesPermissions} />
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card title="SplashWorks permissions">
            <PermissionsContainer items={splashWorksPermissions} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
