import { ComponentType } from 'react';
import { Typography, Stack, Link, Grid, Divider } from '@mui/material';
import { Card } from '../../../components/cards';
import { LabeledRecord } from '../../../components/labeled-record';
import { normalizeDate } from '../../../core/utils/data.utils';
import { usPhoneNumber } from '../../../core/utils/format.utils';
import { useEmployeeDetail } from '../../../core/hooks/useEmployeeDetail';
import { useParams } from 'react-router-dom';

export const Profile: ComponentType = () => {
  const { id } = useParams();

  const { data } = useEmployeeDetail({
    uuid: id
  });

  return (
    <>
      <Stack spacing={2} direction="row">
        <Typography variant="subtitle1" color="common.info">
          Creation Date: 12/31/2022 17:21 PM (
          <Link component="button">John Smith</Link>)
        </Typography>
        <Typography variant="subtitle1" color="common.info">
          Last Update: 12/31/2022 17:21 PM (
          <Link component="button">Marta Kaufman</Link>)
        </Typography>
        <Link component="button">Changes history</Link>
      </Stack>
      <Stack mt={1.5} spacing={1} direction="column">
        <Card title="General Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord label="First Name:" value={data?.firstName} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Last Name:" value={data?.lastName} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Gender:" value={data?.gender} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Email:" value={data?.email} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              {
                <LabeledRecord
                  label="Hire Date:"
                  value={normalizeDate(data?.hireDate)}
                />
              }
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="End Work Date:"
                value={normalizeDate(data?.endWorkDate) || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Title:" value={data?.title} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="HRID:" value={data?.hrid} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Type of Service:"
                value={data?.serviceTypeNames?.join(', ') || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Group:"
                value={data?.groupNames?.join(', ') || '-'}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} mt={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Supervisor:"
                value={
                  data?.supervisor
                    ? `${data?.supervisor?.firstName} ${data?.supervisor?.lastName}`
                    : '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Profit center:"
                value={data?.profitCentre}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Hourly Rate:" value={data?.hourlyRate} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Targeted Hours:"
                value={data?.targetedHour}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={2.4}>
              <LabeledRecord label="PTO:" value={data?.pto} />
            </Grid>
          </Grid>
          <Divider />
          <Grid container mt={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Primary Language:"
                value={data?.primaryLanguage}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Secondary Language:"
                value={data?.secondaryLanguage || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Skills:"
                value={data?.skills.join(',') || '-'}
              />
            </Grid>
          </Grid>
        </Card>

        <Card title="Contact Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Address:"
                value={data?.contactInfo?.addressLine}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="City:"
                value={data?.contactInfo?.city.title}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Province/State:"
                value={data?.contactInfo?.state.title}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Zip Code:"
                value={data?.contactInfo?.zipCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Primary Phone Number:"
                value={usPhoneNumber(data?.contactInfo?.phoneNumberPrimary)}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value={
                  usPhoneNumber(data?.contactInfo?.phoneNumberSecondary) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Work Phone Number:"
                value={usPhoneNumber(data?.contactInfo?.phoneNumberWork) || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <LabeledRecord
                label="Enable Text Notifications:"
                value={data?.contactInfo?.notifications ? 'Yes' : 'No'}
              />
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};
