import { useParams } from 'react-router';
import {
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Box,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import {
  DatePicker,
  TimePicker,
  CheckboxList,
  FileAttach,
  Autocomplete,
  TextField,
  EmployeeField,
  Checkbox
} from '../../components/fields';
import { ROUTE_REMINDERS } from '../../constants/route.paths';
import { useEditResource } from '../../core/hooks/useEditResource';
import * as yup from 'yup';
import { EmployeeReminderApi } from '../../core/http';
import { TaskTypeField } from '../../components/fields/task-type-field';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { useLayout } from '../../core/hooks/useLayout';
import { useEmployee } from '../../core/hooks/useEmployee';
import map from 'lodash/map';
import {
  monthWeekItems,
  monthItems,
  weekItems
} from '../../constants/app.constants';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

const employeeReminderApi = new EmployeeReminderApi();

export const EditEmployeeReminder = () => {
  const navigation = useNavigate();
  const { showMessage } = useSnackbar();
  const { id } = useParams();
  const backUrl = ROUTE_REMINDERS + '/#employee';
  const { items: employeesData } = useEmployee();

  useLayout({
    backButton: {
      title: 'Reminders',
      path: backUrl
    }
  });

  const { isEdit, handleSubmit, setFieldValue, values, getFieldProps } =
    useEditResource(employeeReminderApi, {
      uuid: id,
      initialValues: {
        title: '',
        dueDateTime: new Date(),
        taskTypeUuid: '',
        employeeUuid: '',
        participantsUuids: [],
        notes: '',
        repeatIntervalDays: false,
        repeatIntervalMonths: false,
        repeatInterval: [],
        repeatsInDays: [],
        repeatsInWeeks: [],
        repeatsInMonth: [],
        isTextNotificationsEnabled: false,
        isEmailNotificationsEnabled: false,
        file: null
      },
      validationSchema: yup.object().shape({
        title: yup.string().required('Title is a required field')
      }),
      normalizeSetValues: (values) => {
        console.log({ values });
        return {
          ...values,
          dueDateTime: values.dueDate || new Date(),
          repeatsInDays: values.repeatsInDays || [],
          repeatsInWeeks: values.repeatsInWeeks || [],
          repeatsInMonth: values.repeatsInMonth || [],
          repeatIntervalDays: !isEmpty(values.repeatsInDays),
          repeatIntervalMonths: !isEmpty(values.repeatsInMonth)
        };
      },
      normalizeValues: (values) => {
        if (values.repeatIntervalDays || values.repeatIntervalMonths) {
          return omit(values, ['dueDateTime']);
        } else {
          return {
            ...values,
            dueDateTime: new Date(values.dueDateTime).toISOString()
          };
        }
      },
      onSuccess: () => {
        showMessage(
          `Client reminder ${isEdit ? 'updated' : 'created'} successfully`
        );
        navigation(backUrl);
      },
      onError: () => {
        showMessage('Something went wrong, please try again later', 'error');
      }
    });
  const isDisabledDueDate =
    values.repeatIntervalDays || values.repeatIntervalMonths;
  const participantsData = map(employeesData, ({ displayName, uuid }) => ({
    label: displayName,
    value: uuid
  }));

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit Employee Reminder' : 'Add Employee Reminder'}
      >
        <Button size="small" onClick={() => handleSubmit()}>
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => navigation(backUrl)}
        >
          Cancel
        </Button>
      </PageHeader>
      <Card>
        <Grid container columnSpacing={1}>
          <Grid item md={8}>
            <TextField label="Reminder Name*" {...getFieldProps('title')} />
          </Grid>
          <Grid item md={2}>
            <DatePicker
              {...getFieldProps('dueDateTime')}
              label="Due Date*"
              disabled={isDisabledDueDate}
              formatValue={false}
              value={!isDisabledDueDate ? values.dueDateTime : null}
            />
          </Grid>
          <Grid item md={2}>
            <TimePicker
              {...getFieldProps('dueDateTime')}
              disabled={isDisabledDueDate}
              label="Due Time"
              value={!isDisabledDueDate ? values.dueDateTime : null}
            />
          </Grid>
        </Grid>
        <TaskTypeField
          label="Task Type"
          {...getFieldProps('taskTypeUuid')}
          sx={{
            maxWidth: 368
          }}
        />
      </Card>
      <Card title="Participants">
        <EmployeeField
          {...getFieldProps('employeeUuid')}
          label="Employee"
          sx={{
            maxWidth: 556
          }}
        />
        <Autocomplete
          label="Participants"
          placeholder="Search"
          dataSource={participantsData}
          {...getFieldProps('participantsUuids')}
        />
      </Card>
      <Card title="Notes">
        <TextField
          {...getFieldProps('notes')}
          label="Reminder Notes"
          multiline
          rows={5}
        />
      </Card>
      <Card title="Repeats">
        <Stack direction="row" spacing={3} ml={1} mb={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.repeatIntervalDays}
                onChange={(e, checked) => {
                  setFieldValue('repeatIntervalDays', checked);
                  if (!checked) setFieldValue('repeatsInDays', []);
                }}
              />
            }
            label={<Typography variant="body3">Repeats in Days</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.repeatIntervalMonths}
                onChange={(e, checked) => {
                  setFieldValue('repeatIntervalMonths', checked);
                  if (!checked) setFieldValue('repeatsInMonths', []);
                }}
              />
            }
            label={<Typography variant="body3">Repeats in Month</Typography>}
          />
        </Stack>
        {(values.repeatIntervalDays || values.repeatIntervalMonths) && (
          <CheckboxList
            value={values.repeatsInDays}
            onChange={(value) => setFieldValue('repeatsInDays', value)}
            items={weekItems}
          />
        )}
        {values.repeatIntervalMonths && (
          <>
            <Box ml={2}>
              <Typography variant="body3">Recurring Week No</Typography>
            </Box>
            <CheckboxList
              value={values.repeatsInWeeks}
              onChange={(value) => setFieldValue('repeatsInWeeks', value)}
              items={monthWeekItems}
            />
            <CheckboxList
              value={values.repeatsInMonth}
              onChange={(value) => setFieldValue('repeatsInMonth', value)}
              items={monthItems}
            />
          </>
        )}
      </Card>
      <Card title="Notification Type">
        <Stack direction="row" spacing={3} ml={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isTextNotificationsEnabled}
                onChange={(e, checked) => {
                  setFieldValue('isTextNotificationsEnabled', checked);
                }}
              />
            }
            label={<Typography variant="body3">Text Message</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isEmailNotificationsEnabled}
                onChange={(e, checked) => {
                  setFieldValue('isEmailNotificationsEnabled', checked);
                }}
              />
            }
            label={<Typography variant="body3">Email</Typography>}
          />
        </Stack>
      </Card>
      <Card title="Add file">
        <FileAttach
          requestOnDeleted={false}
          value={values.file}
          onChange={(guid, file) => setFieldValue('file', file)}
        />
      </Card>
    </>
  );
};
