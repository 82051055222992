import { Stack, IconButton } from '@mui/material';
import { IconCaretBack, IconCaretNext } from '../../icons';
import { TextField } from '../';
import { format, add, sub } from 'date-fns';
import useStyles from './styles';

export const WeekArrowPicker = ({ dates, setDates }) => {
  const classes = useStyles();

  const handlePreviousWeek = () => {
    const newDates = dates.map((item) => {
      return sub(item, { weeks: 1 });
    });
    setDates(newDates);
  };

  const handleNextWeek = () => {
    const newDates = dates.map((item) => {
      return add(item, { weeks: 1 });
    });
    setDates(newDates);
  };

  return (
    <Stack className={classes.root}>
      <Stack className={classes.buttonsContainer} direction="row">
        <IconButton
          className={classes.roundIconButton}
          size="small"
          onClick={handlePreviousWeek}
        >
          <IconCaretBack />
        </IconButton>
        <IconButton
          className={classes.roundIconButton}
          size="small"
          onClick={handleNextWeek}
        >
          <IconCaretNext />
        </IconButton>
      </Stack>
      <TextField
        className={classes.datePicker}
        value={`${format(dates[0], 'MMM dd')} - ${format(
          dates[1],
          'dd'
        )}, ${format(dates[0], 'yyyy')}`}
        size="small"
      />
    </Stack>
  );
};
