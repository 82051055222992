import React, { ComponentType, createContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { breakpoints } from './breakpoints';
import { themeComponents } from './components';
import { Palette } from './palettes';
import { shadows } from './shadows';
import { shape } from './shape';
import { typography } from './typography';

const theme = createTheme({
  palette: Palette,
  typography,
  shape,
  shadows,
  breakpoints,
  components: themeComponents()
});

export const ThemeContext = createContext({});

export const ThemeProvider: ComponentType = ({ children }) => {
  const contextValue = {};

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
          </LocalizationProvider>
          <CssBaseline />
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
