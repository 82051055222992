import { MasterDataMenu } from '../../features/master-data/master-data-menu/MasterDataMenu';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Master-data'
  })
)(MasterDataMenu);
