import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import {
  ClientApi,
  ClientContactApi,
  ClientContactDto,
  ClientDto,
  ClientInsuredPersonApi,
  ClientInsuredPersonDto
} from '../http';
import {
  transformToBackendDate,
  normalizePhone,
  usPhoneNumber
} from '../utils';
import { has } from 'lodash';

interface Options {
  uuid?: string;
  onSuccess?: (values?: FormikValues) => void;
  onError?: (error: any) => void;
}

const clientApi = new ClientApi();
const clientContactApi = new ClientContactApi();
const clientInsuredPersonApi = new ClientInsuredPersonApi();

const min_date = new Date('01/01/1950');

const nameValidation = yup
  .string()
  .test(
    'spaces',
    'First Name must contain at least one character.',
    (value) => {
      return !!value?.trim();
    }
  )
  .matches(
    /^[aA-zZ&\s]+$/,
    'First Name must contain only letters and backspaces.'
  );

const nonRequiredNameValidation = yup
  .string()
  .matches(
    /^[aA-zZ&\s]+$/,
    'First Name must contain only letters and backspaces.'
  );

const clientValidationScheme = yup.object().shape({
  memberId: yup
    .number()
    .required('Please enter member id')
    .typeError('Member id must contain only numbers.'),
  firstName: nameValidation,
  lastName: nameValidation,
  birthDate: yup
    .date()
    .min(min_date, 'Birth date must be later than 01/01/1950')
    .max(new Date(), 'Future date not allowed')
    .required('Please select birth date.')
    .typeError('Please select valid date'),
  gender: yup
    .string()
    .required('Please select one of the options.')
    .typeError('Please select valid gender'),
  primaryLanguageUuid: yup
    .string()
    .required('Please select one of the options.'),
  secondaryLanguageUuid: yup.string(),
  profitCenterUuid: yup.string().required('Please select one of the options.'),
  fundingSourceUuid: yup.string().required('Please select one of the options.'),
  referralAcceptanceDate: yup
    .date()
    .required('Please select referral acceptance date.')
    .min(min_date, 'Hire date must be later than 01/01/1950')
    .typeError('Please select valid date'),
  notes: yup.string().nullable()
});

const contactValidationScheme = yup.object().shape({
  type: yup.string(),
  relationToChild: yup.string().required('Please select one of the options.'),
  firstName: nameValidation,
  lastName: nameValidation,
  email: yup
    .string()
    .email('Please enter a valid email address.')
    .required('Please enter email address.'),
  addressLine1: yup.string().required('Please enter address 1.'),
  addressLine2: yup.string().nullable(),
  cityUuid: yup.string().required('Please select one of the options.'),
  stateUuid: yup.string().required('Please select one of the options.'),
  zipCode: yup.string().required('Please enter zip code.'),
  primaryPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value))
    .length(10, 'Please enter valid phone number.'),
  secondaryPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value))
    .length(10, 'Please enter valid phone number.'),
  workPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value))
    .length(10, 'Please enter valid phone number.'),
  isTextNotificationsEnabled: yup.boolean(),
  isEmailNotificationsEnabled: yup.boolean().nullable()
});

const nonRequiredContactValidationScheme = yup.object().shape({
  type: yup.string(),
  relationToChild: yup.string(),
  firstName: nonRequiredNameValidation,
  lastName: nonRequiredNameValidation,
  email: yup.string().email('Please enter a valid email address.'),
  addressLine1: yup.string(),
  addressLine2: yup.string().nullable(),
  cityUuid: yup.string(),
  stateUuid: yup.string(),
  zipCode: yup.string(),
  primaryPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value)),
  secondaryPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value)),
  workPhoneNumber: yup
    .string()
    .transform((value) => (value ? normalizePhone(value) : value)),
  isTextNotificationsEnabled: yup.boolean(),
  isEmailNotificationsEnabled: yup.boolean().nullable()
});

const insuredPersonValidationScheme = yup.object().shape({
  firstName: nameValidation,
  lastName: nameValidation,
  birthDate: yup
    .date()
    .min(min_date, 'Birth date must be later than 01/01/1950.')
    .max(new Date(), 'Future date not allowed')
    .required('Please select birth date.')
    .typeError('Please select valid date'),
  gender: yup.string().required('Please select one of the options.'),
  addressLine1: yup.string().required('Please enter address 1.'),
  addressLine2: yup.string().nullable(),
  stateUuid: yup.string().required('Please select one of the options.'),
  cityUuid: yup.string().required('Please select one of the options.'),
  zipCode: yup.string().required('Please enter zip code.'),
  subscriberId: yup.string().required('Please enter subscriber id.'),
  groupName: yup.string().required('Please enter group.'),
  planName: yup.string().required('Please enter Plan name.'),
  payerUuid: yup.string().required('Please select one of this options.'),
  startDate: yup.date().required('Please select start date'),
  endDate: yup
    .date()
    .required('Please select end date.')
    .typeError('Please select valid date')
});

const primaryContactValues = {
  clientUuid: '',
  uuid: '',
  type: 'primary',
  relationToChild: '',
  firstName: '',
  lastName: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  cityUuid: '',
  stateUuid: '',
  zipCode: '',
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  workPhoneNumber: '',
  isTextNotificationsEnabled: true,
  isEmailNotificationsEnabled: false
};

const insuredPersonValues = {
  startDate: '',
  endDate: '',
  clientUuid: '',
  type: 'insured',
  firstName: '',
  lastName: '',
  gender: '',
  addressLine1: '',
  addressLine2: '',
  cityUuid: '',
  stateUuid: '',
  zipCode: '',
  subscriberId: '',
  groupName: '',
  planName: '',
  payerUuid: '',
  birthDate: '',
  isTextNotificationsEnabled: false,
  isEmailNotificationsEnabled: false,
  fillAsPrimaryContact: false
};

const secondaryContactValues = {
  clientUuid: '',
  uuid: '',
  type: 'secondary',
  relationToChild: '',
  firstName: '',
  lastName: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  cityUuid: '',
  stateUuid: '',
  zipCode: '',
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  workPhoneNumber: '',
  isTextNotificationsEnabled: true,
  isEmailNotificationsEnabled: false
};

const emergencyContactValues = {
  clientUuid: '',
  uuid: '',
  type: 'emergency',
  relationToChild: '',
  firstName: '',
  lastName: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  cityUuid: '',
  stateUuid: '',
  zipCode: '',
  primaryPhoneNumber: '',
  secondaryPhoneNumber: '',
  workPhoneNumber: '',
  isTextNotificationsEnabled: true,
  isEmailNotificationsEnabled: false
};

const initialValues = {
  id: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  gender: '',
  primaryLanguageUuid: '',
  secondaryLanguageUuid: '',
  profitCenterUuid: '',
  fundingSourceUuid: '',
  referralAcceptanceDate: '',
  memberId: '',
  notes: '',
  status: '',
  contacts: [
    primaryContactValues,
    secondaryContactValues,
    emergencyContactValues,
    insuredPersonValues
  ]
};

export const useEditClientResource = ({
  uuid,
  onSuccess,
  onError
}: Options) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [touched, setTouched] = useState<any>({});
  const [data, setData] = useState<Record<string, any>>({});

  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    setFieldValue,
    touched: validationTouched,
    setFieldTouched,
    handleBlur
  } = useFormik({
    initialValues,
    validationSchema: clientValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (Object.keys(primaryContactProps.errors).length > 0) return;
      if (Object.keys(secondaryContactProps.errors).length > 0) return;
      if (Object.keys(emergencyContactProps.errors).length > 0) return;
      if (Object.keys(insuredPersonProps.errors).length > 0) return;
      setIsLoading(true);
      setErrors({});
      let actionPromise: Promise<void>;

      const insuredPerson = values.contacts.find(
        (item: ClientInsuredPersonDto) => item.type === 'insured'
      );
      const clientContacts = values.contacts.filter(
        (item: ClientContactDto) => item.type !== 'insured'
      );

      const clientUuid = uuidv4();
      if (isEdit) {
        actionPromise = clientApi
          .update({
            uuid,
            ...values,
            referralAcceptanceDate: transformToBackendDate(
              values.referralAcceptanceDate
            ),
            memberId: +values.memberId
          })
          .then(() => {
            clientContacts.map((item: any) => {
              clientContactApi.update({
                ...item,
                primaryPhoneNumber: normalizePhone(item.primaryPhoneNumber),
                secondaryPhoneNumber: normalizePhone(item.secondaryPhoneNumber),
                workPhoneNumber: normalizePhone(item.workPhoneNumber)
              });
            });
            if (has(insuredPerson, 'uuid')) {
              clientInsuredPersonApi.update({
                fillAsPrimaryContact: false,
                ...insuredPerson,
                startDate: transformToBackendDate(
                  insuredPersonProps.values.startDate
                ),
                endDate: transformToBackendDate(
                  insuredPersonProps.values.endDate
                )
              });
            } else {
              clientInsuredPersonApi.create({
                fillAsPrimaryContact: false,
                ...insuredPerson,
                uuid: uuidv4(),
                clientUuid: uuid,
                startDate: transformToBackendDate(
                  insuredPersonProps.values.startDate
                ),
                endDate: transformToBackendDate(
                  insuredPersonProps.values.endDate
                )
              });
            }
          });
      } else {
        actionPromise = clientApi
          .create({
            uuid: clientUuid,
            ...values,
            referralAcceptanceDate: transformToBackendDate(
              values.referralAcceptanceDate
            ),
            memberId: +values.memberId
          })
          .then(() => {
            clientContacts.map((item: any) => {
              clientContactApi.create({
                ...item,
                uuid: uuidv4(),
                clientUuid: clientUuid,
                primaryPhoneNumber: normalizePhone(item.primaryPhoneNumber),
                secondaryPhoneNumber: normalizePhone(item.secondaryPhoneNumber),
                workPhoneNumber: normalizePhone(item.workPhoneNumber)
              });
            });
            clientInsuredPersonApi.create({
              fillAsPrimaryContact: false,
              ...insuredPerson,
              uuid: uuidv4(),
              clientUuid: clientUuid,
              startDate: transformToBackendDate(
                insuredPersonProps.values.startDate
              ),
              endDate: transformToBackendDate(insuredPersonProps.values.endDate)
            });
          });
      }

      actionPromise
        .then(() => {
          setIsLoading(false);
          onSuccess && onSuccess(values);
        })
        .catch((err) => {
          const errorDescription = get(
            err,
            'response.data.errorDescription.fields'
          );

          if (errorDescription) {
            setErrors(errorDescription);
          }

          setIsLoading(false);
          onError && onError(err?.response?.data?.errorMessage);
        });
    }
  });

  const primaryContactProps = useFormik({
    initialValues: primaryContactValues,
    validationSchema: contactValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {
      return;
    }
  });

  const secondaryContactProps = useFormik({
    initialValues: secondaryContactValues,
    validationSchema: nonRequiredContactValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {
      return;
    }
  });

  const emergencyContactProps = useFormik({
    initialValues: emergencyContactValues,
    validationSchema: contactValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {
      return;
    }
  });

  const insuredPersonProps = useFormik({
    initialValues: insuredPersonValues,
    validationSchema: insuredPersonValidationScheme,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: () => {
      return;
    }
  });

  useEffect(() => {
    primaryContactProps.validateForm();
    secondaryContactProps.validateForm();
    emergencyContactProps.validateForm();
    insuredPersonProps.validateForm();

    Object.keys(primaryContactValues).forEach((key) => {
      primaryContactProps.setFieldTouched(key, false);
    });
    Object.keys(secondaryContactValues).forEach((key) => {
      secondaryContactProps.setFieldTouched(key, false);
    });
    Object.keys(primaryContactValues).forEach((key) => {
      primaryContactProps.setFieldTouched(key, false);
    });
    Object.keys(insuredPersonValues).forEach((key) => {
      insuredPersonProps.setFieldTouched(key, false);
    });
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      contacts: [
        primaryContactProps.values,
        secondaryContactProps.values,
        emergencyContactProps.values,
        insuredPersonProps.values
      ]
    });
  }, [
    primaryContactProps.values,
    secondaryContactProps.values,
    emergencyContactProps.values,
    insuredPersonProps.values
  ]);

  useEffect(() => {
    setErrors({
      ...validationErrors,
      primary: primaryContactProps.errors,
      secondary: secondaryContactProps.errors,
      emergency: emergencyContactProps.errors,
      insured: insuredPersonProps.errors
    });
  }, [
    primaryContactProps.errors,
    secondaryContactProps.errors,
    emergencyContactProps.errors,
    insuredPersonProps.errors,
    validationErrors
  ]);

  useEffect(() => {
    setTouched({
      ...validationTouched,
      primary: primaryContactProps.touched,
      secondary: secondaryContactProps.touched,
      emergency: emergencyContactProps.touched,
      insured: insuredPersonProps.touched
    });
  }, [
    primaryContactProps.touched,
    secondaryContactProps.touched,
    emergencyContactProps.touched,
    insuredPersonProps.touched,
    validationTouched
  ]);

  useEffect(() => {
    if (uuid) {
      setIsLoading(true);
      clientApi
        .findOne(uuid)
        .then((data: ClientDto) => {
          setData(data);
          setValues(data);

          primaryContactProps.setValues(
            data.contacts.find(
              (item: ClientContactDto) => item.type === 'primary'
            ) || {
              ...primaryContactValues
            }
          );
          secondaryContactProps.setValues(
            data.contacts.find(
              (item: ClientContactDto) => item.type === 'secondary'
            ) || {
              ...secondaryContactValues
            }
          );

          emergencyContactProps.setValues(
            data.contacts.find(
              (item: ClientContactDto) => item.type === 'emergency'
            ) || {
              ...emergencyContactValues
            }
          );
          insuredPersonProps.setValues(
            data.contacts.find(
              (item: ClientInsuredPersonDto) => item.type === 'insured'
            ) || {
              ...insuredPersonValues
            }
          );
          insuredPersonProps.setFieldValue(
            'payerUuid',
            data.contacts.find(
              (item: ClientInsuredPersonDto) => item.type === 'insured'
            )?.payer?.uuid || ''
          );

          data.contacts.forEach((item: ClientContactDto) => {
            if (item.type === 'primary') {
              primaryContactProps.setFieldValue(
                'primaryPhoneNumber',
                usPhoneNumber(item.primaryPhoneNumber)
              );
              primaryContactProps.setFieldValue(
                'secondaryPhoneNumber',
                usPhoneNumber(item.secondaryPhoneNumber)
              );
              primaryContactProps.setFieldValue(
                'workPhoneNumber',
                usPhoneNumber(item.workPhoneNumber)
              );
              primaryContactProps.setFieldValue('cityUuid', item.city.uuid);
              primaryContactProps.setFieldValue('stateUuid', item.state.uuid);
            } else if (item.type === 'secondary') {
              secondaryContactProps.setFieldValue(
                'primaryPhoneNumber',
                usPhoneNumber(item.primaryPhoneNumber)
              );
              secondaryContactProps.setFieldValue(
                'secondaryPhoneNumber',
                usPhoneNumber(item.secondaryPhoneNumber)
              );
              secondaryContactProps.setFieldValue(
                'workPhoneNumber',
                usPhoneNumber(item.workPhoneNumber)
              );
              secondaryContactProps.setFieldValue('cityUuid', item.city.uuid);
              secondaryContactProps.setFieldValue('stateUuid', item.state.uuid);
            } else if (item.type === 'emergency') {
              emergencyContactProps.setFieldValue(
                'primaryPhoneNumber',
                usPhoneNumber(item.primaryPhoneNumber)
              );
              emergencyContactProps.setFieldValue(
                'secondaryPhoneNumber',
                usPhoneNumber(item.secondaryPhoneNumber)
              );
              emergencyContactProps.setFieldValue(
                'workPhoneNumber',
                usPhoneNumber(item.workPhoneNumber)
              );
              emergencyContactProps.setFieldValue('cityUuid', item.city.uuid);
              emergencyContactProps.setFieldValue('stateUuid', item.state.uuid);
            } else if (item.type === 'insured') {
              insuredPersonProps.setFieldValue('cityUuid', item.city.uuid);
              insuredPersonProps.setFieldValue('stateUuid', item.state.uuid);
            }
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid]);
  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    values,
    errors,
    touched,
    setFieldValue,
    primaryContactProps,
    secondaryContactProps,
    emergencyContactProps,
    insuredPersonProps
  };
};
