import { Box, Divider, Grid } from '@mui/material';
import {
  CityAutocompleteField,
  DatePicker,
  FundingSourceField,
  NumberField,
  SelectField,
  StateAutocompleteField,
  TextField
} from '../../components/fields';
import { normalizeDate } from '../../core';
import { get } from 'lodash';
import { memo } from 'react';

export const ClientInsuredContactForm = memo(
  ({
    isLoading,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
    fillAsPrimary
  }: any) => {
    const getFieldValue = (fieldName: string) => {
      return get(values, fieldName);
    };

    const getFieldTouchedError = (fieldName: string) => {
      return get(touched, fieldName) && get(errors, fieldName);
    };

    const getFieldProps = (fieldName: string) => {
      return {
        errors: getFieldTouchedError(fieldName),
        value: getFieldValue(fieldName),
        onChange: (value) => {
          setFieldTouched(fieldName, true);
          setFieldValue(fieldName, value);
        },
        onBlur: () => {
          handleBlur(fieldName);
        }
      };
    };

    return (
      <Box>
        <Grid container spacing={2} mb={2}>
          <Grid item md={3} xs={12}>
            <TextField
              required
              disabled={isLoading || fillAsPrimary}
              label="First Name"
              {...getFieldProps('firstName')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required
              disabled={isLoading || fillAsPrimary}
              label="Last Name"
              {...getFieldProps('lastName')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <DatePicker
              required
              disabled={isLoading}
              label="Birth Date"
              {...getFieldProps('birthDate')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectField
              required
              placeholder="Select"
              disabled={isLoading}
              label="Gender"
              items={[
                {
                  label: 'Male',
                  value: 'male'
                },
                {
                  label: 'Female',
                  value: 'female'
                }
              ]}
              {...getFieldProps('gender')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={isLoading || fillAsPrimary}
              label="Address Line 1"
              required
              {...getFieldProps('addressLine1')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={isLoading || fillAsPrimary}
              label="Address Line 2"
              {...getFieldProps('addressLine2')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={3} xs={12}>
            <CityAutocompleteField
              required
              label="City"
              setFieldValue={setFieldValue}
              {...getFieldProps('cityUuid')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <StateAutocompleteField
              required
              label="State"
              setFieldValue={setFieldValue}
              {...getFieldProps('stateUuid')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <NumberField
              disabled={isLoading || fillAsPrimary}
              label="Zip Code"
              required
              maxLength={5}
              {...getFieldProps('zipCode')}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2} mt={2}>
          <Grid item md={3} xs={12}>
            <TextField
              required
              disabled={isLoading}
              label="Subscriber ID"
              {...getFieldProps('subscriberId')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required
              disabled={isLoading}
              label="Group"
              {...getFieldProps('groupName')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              required
              disabled={isLoading}
              label="Plan Name"
              {...getFieldProps('planName')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <FundingSourceField
              required
              placeholder="Select"
              disabled={isLoading}
              label="Funding Source"
              {...getFieldProps('payerUuid')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <DatePicker
              required
              disabled={isLoading}
              label="Start Date"
              {...getFieldProps('startDate')}
              value={normalizeDate(getFieldValue('startDate')) || ''}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <DatePicker
              required
              disabled={isLoading}
              label="End Date"
              {...getFieldProps('endDate')}
              value={normalizeDate(getFieldValue('endDate')) || ''}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
);

ClientInsuredContactForm.displayName = 'ClientInsuredContactForm';
