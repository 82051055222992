import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from '../../utils/delay';
import { clientLogsData } from '../../../features/clients/_mockdata';

export const getClientLog = createAsyncThunk('getClientLogs', async () => {
  try {
    const data: any = await delay(500, clientLogsData);
    return data;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const clientAvailabilitySlice = createSlice({
  name: 'clientAvailabilitySlice',
  initialState: {
    getClientLog: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientLog.pending, (state) => {
      state.getClientLog.loading = true;
      state.getClientLog.data = null;
      state.getClientLog.error = null;
    });
    builder.addCase(getClientLog.fulfilled, (state, { payload }) => {
      state.getClientLog.loading = false;
      state.getClientLog.data = payload;
      state.getClientLog.error = null;
    });
    builder.addCase(getClientLog.rejected, (state) => {
      state.getClientLog.loading = false;
      state.getClientLog.data = null;
      state.getClientLog.error = 'Something went wrong';
    });
  }
});

export default clientAvailabilitySlice.reducer;
