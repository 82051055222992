import { Expose } from 'class-transformer';
import { ClientDto } from './client.dto';

export class ClientFamilyCommitmentDto {
  @Expose()
  uuid: string;

  @Expose()
  client: ClientDto;

  @Expose()
  familyAvailable: number;

  @Expose()
  authHours: number;

  @Expose()
  familyAvailablePerHour: number;

  @Expose()
  notes: string;
}
