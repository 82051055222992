import { Expose } from 'class-transformer';

export class ProfitCenterDto {
  @Expose()
  uuid: string;

  @Expose()
  officeName: string;

  @Expose()
  profitCentre: string;

  @Expose()
  taxId: string;

  @Expose()
  createdAt: string;
}
