import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_NEWS } from '../../constants/route.paths';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import {
  EnumField,
  FieldWysiwyg,
  FileAttach,
  TextField
} from '../../components/fields';
import { NEWS_VISIBILITY } from '../../constants/enums';
import { useEditResource } from '../../core/hooks/useEditResource';
import * as yup from 'yup';
import { NewsApi } from '../../core/http/requests/news.api';
import { useSnackbar } from '../../core/hooks/useSnackbar';

const newsApi = new NewsApi();

export const NewsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const { isEdit, handleSubmit, handleChange, values, getFieldTouchedError } =
    useEditResource(newsApi, {
      uuid: id,
      initialValues: {
        title: '',
        body: '',
        visibility: ''
      },
      validationSchema: yup.object().shape({
        title: yup.string().required('Title is a required field'),
        body: yup.string().required('Body is a required field'),
        visibility: yup.string().required('Visibility is a required field')
      }),
      onSuccess: () => {
        showMessage(`News ${isEdit ? 'updated' : 'created'} successfully`);
        navigate(ROUTE_NEWS);
      },
      onError: () => {
        return;
      }
    });

  return (
    <>
      <PageHeader title={isEdit ? 'Edit News' : 'Create News'}>
        <Button onClick={() => handleSubmit()}>
          {isEdit ? 'Update' : 'Add'}
        </Button>
        <Button color="third" onClick={() => navigate(ROUTE_NEWS)}>
          Cancel
        </Button>
      </PageHeader>
      <Card>
        <TextField
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
          errors={getFieldTouchedError('title')}
        />
        <EnumField
          label="Visibility"
          placeholder="Select"
          helperText={null}
          sx={{ width: 300 }}
          items={NEWS_VISIBILITY}
          value={values.visibility}
          onChange={handleChange('visibility')}
          errors={getFieldTouchedError('visibility')}
        />
        <FileAttach mt={4} mb={3} />
        <FieldWysiwyg
          label="Description"
          editorStyle={{
            minHeight: 300
          }}
          value={values.body}
          onChange={handleChange('body')}
          errors={getFieldTouchedError('body')}
        />
      </Card>
    </>
  );
};
