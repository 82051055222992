import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const labels = ['WK1', 'WK2', 'WK3', 'M2D'];

export const options = {
  indexAxis: 'y' as const,
  responsive: true,
  options: {
    scales: {
      yAxis: {
        gridLines: {
          drawOnChartArea: false
        }
      }
    }
  },

  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    },
    datalabels: {
      display: true,
      align: 'end' as const,
      anchor: 'end' as const,
      formatter: (value) => currencyFormatter(value),
      font: {
        size: 12,
        weight: 700
      }
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: true,
        drawBorder: false
      },
      ticks: {
        display: true,
        color: '#4B5663'
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: false
      },
      min: 0,
      max: 300000.0,
      ticks: {
        color: '#172B4D',
        stepSize: 50000.0,
        font: {
          size: 10,
          weight: '500'
        }
      }
    }
  }
};
