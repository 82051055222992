import { Stack, Typography, Grid, Divider } from '@mui/material';
import { Card } from '../../../components/cards';
import { SelectField } from '../../../components/fields';
import { LabeledRecord } from '../../../components/labeled-record';
import { currencyFormatter } from '../../../core';

export const RevenueVsAuthorizedHours = ({
  currentRevenue,
  scheduledRevenue,
  monthToDateRevenue,
  currentAuthorizedHours,
  period,
  setPeriod
}) => {
  return (
    <Card height={483}>
      <Stack direction="row" mb={3} justifyContent="space-between">
        <Stack direction="row" spacing={3}>
          <Typography variant="h4">
            Current Revenue vs Current Authorized Hours
          </Typography>
        </Stack>
        <SelectField
          placeholder="Select period"
          items={[
            {
              value: 'thisMonth',
              label: 'This month'
            },
            {
              value: 'thisQuarter',
              label: 'This quarter'
            },
            {
              value: 'thisYear',
              label: 'This year'
            }
          ]}
          size="small"
          onChange={(v) => setPeriod(v)}
          value={period}
          sx={{ width: 139, height: 32 }}
        />
      </Stack>
      <Grid container direction="column" spacing={2}>
        <Grid item sx={{ width: '100%' }}>
          <Typography variant="body3" fontWeight={600} mb={1}>
            Current Revenue
          </Typography>
          <Grid
            container
            flexWrap="nowrap"
            sx={{ width: '100%', overflowX: 'auto' }}
          >
            {currentRevenue
              .sort((a, b) => a.code - b.code)
              .map((item) => (
                <>
                  <Grid item md={3} sx={{ minWidth: '100px' }}>
                    <LabeledRecord
                      label={item.code}
                      value={currencyFormatter(+item.value)}
                    />
                  </Grid>
                </>
              ))}
          </Grid>
          <Divider />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Typography variant="body3" fontWeight={600} mb={1}>
            Current Schedule
          </Typography>
          <Grid
            container
            flexWrap="nowrap"
            sx={{ width: '100%', overflowX: 'auto' }}
          >
            {scheduledRevenue
              .sort((a, b) => a.code - b.code)
              .map((item) => (
                <>
                  <Grid item md={3} sx={{ minWidth: '100px' }}>
                    <LabeledRecord
                      label={item.code}
                      value={currencyFormatter(item.value)}
                    />
                  </Grid>
                </>
              ))}
          </Grid>
          <Divider />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Typography variant="body3" fontWeight={600} mb={1}>
            Current Authorized Hours
          </Typography>
          <Grid
            container
            flexWrap="nowrap"
            sx={{ width: '100%', overflowX: 'auto' }}
          >
            {currentAuthorizedHours
              .sort((a, b) => a.code - b.code)
              .map((item) => (
                <>
                  <Grid item md={3} sx={{ minWidth: '100px' }}>
                    <LabeledRecord
                      label={item.code}
                      value={currencyFormatter(item.value)}
                    />
                  </Grid>
                </>
              ))}
          </Grid>
          <Divider />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Typography variant="body3" fontWeight={600} mb={1}>
            Month to Date Revenue
          </Typography>
          <Grid
            container
            flexWrap="nowrap"
            sx={{ width: '100%', overflowX: 'auto' }}
          >
            {monthToDateRevenue
              .sort((a, b) => a.code - b.code)
              .map((item) => (
                <>
                  <Grid item md={3} sx={{ minWidth: '100px' }}>
                    <LabeledRecord
                      label={item.code}
                      value={currencyFormatter(item.value)}
                    />
                  </Grid>
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
