import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';

export class RegionDto {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'location.uuid'))
  locationUuid: string;
}
