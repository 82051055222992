import React, { FC } from 'react';
import { IconButton, TextField, Box } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps
} from '@mui/x-date-pickers/DatePicker';
import { IconCalendar } from '../../icons';
import get from 'lodash/get';
import useStyles from './styles';
import format from 'date-fns/format';
import { isValid, parse } from 'date-fns';

interface Props extends Omit<DatePickerProps, 'renderInput' | 'onChange'> {
  label?: string;
  disabled?: boolean;
  disableInput?: boolean;
  Icon?: React.ReactNode;
  size?: 'small';
  onChange?: (date: string | any) => void;
  onBlur?: (date: any) => void;
  errors?: any;
  helperText?: string;
  formatValue?: boolean;
  required?: boolean;
}

export const DatePicker: FC<Props> = ({
  Icon,
  disabled = false,
  disableInput = false,
  size,
  onChange,
  errors,
  value,
  onBlur,
  helperText,
  formatValue = true,
  required = false,
  ...props
}) => {
  const classes = useStyles();

  const handleChange = (date: Date, keyboardInputValue: string) => {
    if (isValid(date)) {
      onChange(formatValue ? format(date, 'yyyy-MM-dd') : date);
    } else {
      onChange(keyboardInputValue || '');
    }
  };

  const normalizeValue = (value) => {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());

    if (isValid(parsedDate)) {
      return format(parsedDate, 'yyyy-MM-dd');
    }
    return value;
  };

  const handleDisabledInput = (evt) => {
    evt.preventDefault();
  };

  return (
    <MuiDatePicker
      disabled={disabled}
      className={classes.root}
      PaperProps={{
        className: classes.paper
      }}
      PopperProps={{
        className: classes.popper
      }}
      onChange={handleChange}
      value={normalizeValue(value)}
      {...props}
      renderInput={(params) => {
        return (
          <TextField
            onKeyDown={disableInput && handleDisabledInput}
            {...params}
            required={required}
            onBlur={onBlur}
            error={!!errors}
            helperText={errors || helperText}
            label={props.label}
            inputProps={{ ...params.inputProps, placeholder: 'MM/DD/YYYY' }}
            size={size}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box className={classes.icon}>
                  <IconButton
                    {...get(
                      params,
                      'InputProps.endAdornment.props.children.props',
                      {}
                    )}
                    size="small"
                  >
                    {Icon ? Icon : <IconCalendar />}
                  </IconButton>
                </Box>
              )
            }}
          />
        );
      }}
    />
  );
};
