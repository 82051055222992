import { PageHeader } from '../../components/page-header';
import { Box, Button } from '@mui/material';
import { Tabs } from '../../components/tabs';
import { EmptyState } from '../../components/empty-state';
import { EBillingInvoices } from './tabs/EBillingInvoices';

export const BillingsContainer = () => {
  return (
    <>
      <PageHeader title="Billing Services" divider={false}>
        <Button>Export</Button>
      </PageHeader>
      <Box>
        <Tabs
          hashed
          items={[
            {
              key: 'e-billing-Invoices',
              label: 'E-billing Invoices',
              content: <EBillingInvoices />
            },
            {
              key: 'invoices-hstory',
              label: 'Invoices History',
              content: <EmptyState title="Invoices History" />
            },
            {
              key: 'office-Ally-claims',
              label: 'Office Ally Claims',
              content: <EmptyState title="Office Ally Claims" />
            }
          ]}
        />
      </Box>
    </>
  );
};
