import { EditEmployeeReminder } from '../../../features/reminders/EditEmployeeReminder';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const CreateEmployeeReminderPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Reminders'
  })
)(EditEmployeeReminder);
