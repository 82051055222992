import { DataTableColumn } from '../../../../components/table-view/TableView';
import { UserRecord } from '../../../../components/user-record';
import { Badge } from '../../../../components/badge';
import { Box } from '@mui/material';
import { format, add, differenceInDays, startOfDay } from 'date-fns';

const renderBadge = (leaveDates, currentDate, record) => {
  if (record.type === 'total') {
    if (!isNaN(+leaveDates))
      return (
        <Box sx={{ fontWeight: 600 }}>{record[format(currentDate, 'E')]}</Box>
      );
  }

  if (record.type === 'days') {
    if (!isNaN(+leaveDates))
      return <Box>{record[format(currentDate, 'd')]}</Box>;
  }

  const leaveDate = leaveDates.find((item) => {
    return differenceInDays(startOfDay(new Date(item.date)), currentDate) === 0;
  });
  if (leaveDate) {
    if (leaveDate.type === 'vacation') {
      return <Badge variant="success" label={'V'} />;
    } else if (leaveDate.type === 'sick') {
      return <Badge variant="warning" label={'S'} />;
    } else if (leaveDate.type === 'personal') {
      return <Badge label={'P'} />;
    } else if (leaveDate.type === 'optional') {
      return <Badge label={'O'} />;
    }
  }

  return '-';
};

export const employeeAbsenceScheduleColumns: DataTableColumn[] = [
  {
    title: 'Employee',
    dataIndex: 'employeeName',
    key: 'employeeName',
    render: (employeeName, { employeeAvatarUrl, type }) => {
      if (type === 'total') {
        return <Box sx={{ fontWeight: 600 }}>TOTAL</Box>;
      }
      if (type === 'days') {
        return <Box>Days</Box>;
      }
      return (
        <UserRecord
          user={{ name: `${employeeName}`, avatar: employeeAvatarUrl }}
          color="primary"
        />
      );
    },
    cellProps: {
      sx: {
        width: 180
      }
    }
  }
];

export const createEmployeeAbsenceScheduleColumns = (data, dates) => {
  const newCols = [...employeeAbsenceScheduleColumns];

  for (let i = 0; i < 7; i++) {
    const currentDate = startOfDay(add(new Date(dates[0]), { days: i }));
    newCols.push({
      title: format(currentDate, 'E'),
      dataIndex: 'leaveDays',
      key: 'leaveDays',
      render: (v, record) => {
        return renderBadge(v || [], currentDate, record);
      }
    });
  }
  newCols.push({
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (v) => <Box sx={{ fontWeight: 600 }}>{v}</Box>
  });
  return newCols;
};

export const getEmployeeAbsenceScheduleDays = (data, dates) => {
  const res = { type: 'days' };
  for (let i = 0; i < 7; i++) {
    const currentDate = startOfDay(add(new Date(dates[0]), { days: i }));
    res[format(currentDate, 'd')] = format(currentDate, 'd');
  }

  return res;
};

export const getEmployeeAbsenceScheduleTotals = (data, dates) => {
  const res = { type: 'total' };
  for (let i = 0; i < 7; i++) {
    const currentDate = startOfDay(add(new Date(dates[0]), { days: i }));
    res[format(currentDate, 'E')] = data.reduce((acc, item) => {
      const leaveDays = item.leaveDays.find(
        (leaveDate) =>
          differenceInDays(
            startOfDay(new Date(leaveDate.date)),
            currentDate
          ) === 0
      );
      if (leaveDays) {
        acc++;
      }
      return acc;
    }, 0);
  }

  return res;
};
