import { Bar } from 'react-chartjs-2';
import { Card } from '../../../../components/cards';
import { options } from './chart.options';

export const InventoryPercentOfWorking = ({
  inventoryY2dAuthorizedHours,
  inventoryY2dCommittedHours,
  inventoryY2dScheduledHours
}) => {
  const labels = inventoryY2dScheduledHours.map((item) => item.code);

  const inventoryY2dAuthorizedLeftHours = inventoryY2dAuthorizedHours.map(
    (item) => {
      const scheduled = inventoryY2dScheduledHours.find(
        (scheduled) => item.code === scheduled.code
      );
      console.log(item, scheduled);
      if (scheduled) {
        return { ...item, value: +item.value - +scheduled.hours };
      } else {
        return item;
      }
    }
  );

  const data = {
    labels: labels,
    datasets: [
      {
        categoryPercentage: 1,
        label: 'Total Authorized Hrs left',
        data: inventoryY2dAuthorizedLeftHours.map((item) => item.value),
        fill: false,
        backgroundColor: '#4481DD'
      },
      {
        categoryPercentage: 1,
        label: 'Y2D Scheduled Hrs',
        data: inventoryY2dScheduledHours.map((item) => item.hours),
        fill: false,
        backgroundColor: '#3BD07A'
      },
      {
        categoryPercentage: 1,
        label: 'Y2D Commited Hrs',
        data: inventoryY2dCommittedHours.map((item) => item.value),
        backgroundColor: '#FEEF68'
      },
      {
        categoryPercentage: 1,
        label: 'Y2D Authorized Hrs ',
        data: inventoryY2dAuthorizedHours.map((item) => item.value),
        fill: false,
        backgroundColor: '#FFCB7E'
      }
    ]
  };

  return (
    <Card height={558} title="Inventory % of working">
      <Bar
        options={options}
        data={data}
        style={{ maxHeight: 402, width: '100%' }}
      />
    </Card>
  );
};
