import React, { ComponentType, useState } from 'react';
import {
  TextField,
  Autocomplete as MuiAutocomplete,
  InputAdornment,
  ClickAwayListener
} from '@mui/material';
import useStyles from './styles';
import { IconSearch } from '../../icons';

interface Item {
  label: string;
  value: number;
}

interface Props {
  label?: string;
  placeholder?: string;
  Icon?: React.ReactNode;
  errors?: any;
  helperText?: string;
  loading?: boolean;
  onAutocompleteChange?: (event: React.SyntheticEvent, value: string) => void;
  dataSource: Item[];
  popUpIconEnabled?: boolean;
  getOptionLabel: (dataSource) => string;
  onTextChange?: (text: string) => void;
  onSearchClick?: () => void;
}

export const SearchAutocomplete: ComponentType<Props> = ({
  placeholder,
  errors,
  helperText,
  loading,
  Icon,
  onAutocompleteChange,
  onTextChange,
  onSearchClick,
  dataSource,
  getOptionLabel
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTextChange = (text: string) => {
    if (onTextChange) {
      onTextChange(text);
    }
    setOpen(true);
  };

  const handleSearchClick = (event) => () => {
    event.stopPropagation();
    if (onSearchClick) onSearchClick();
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MuiAutocomplete
        loading={loading}
        className={classes.root}
        onChange={onAutocompleteChange}
        open={open}
        options={dataSource}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={!!errors}
              helperText={errors || helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position={'end'}
                    onClick={(e) => handleSearchClick(e)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {Icon ? Icon : <IconSearch fontSize="small" />}
                  </InputAdornment>
                )
              }}
              placeholder={placeholder}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: '#fff',
                  border: '1px solid #ECF0F7',
                  borderRadius: 0.5,
                  paddingRight: '8px !important',
                  paddingLeft: '0 !important'
                }
              }}
              onChange={(e) => {
                handleTextChange(e.target.value);
              }}
            />
          );
        }}
      />
    </ClickAwayListener>
  );
};
