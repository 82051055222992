import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { RevenueVsAuthorizedHours } from '../widgets/RevenueVsAuthoirizedHours';
import { MonthToDateRevenue } from '../widgets/month-to-date-revenue/MonthToDateRevenue';
import { ConvertedVsSheduled } from '../widgets/converted-vs-sheduled/ConvertedVsSheduled';
import { WeeklyConvertedVsSheduled } from '../widgets/weekly-converted-vs-scheduled/WeeklyConvertedVsScheduled';
import { WeeklyActualVsForecast } from '../widgets/weekly-actual-vs-forecast/WeeklyActualVsForecast';
import { LossOfRevenueVsForecast } from '../widgets/loss-f-revenue-vs-forecast/LossOfRevenueVsForecast';
import { InventoryPercentOfWorking } from '../widgets/inventory-percent-of-working/InventoryPercentOfWorking';
import { ScheduledVsAuthorized } from '../widgets/scheduled-vs-authorized/ScheduledVsAuthorized';
import { CancelattionVsM2DRevenue } from '../widgets/cancellation-vs-m2d-revenue/CancelattionVsM2DRevenue';
import { LossToCancellation } from '../widgets/loss-to-cancellation/LossToCancellation';
import { EmployeeAbsenceSchedule } from '../widgets/employee-absence-schedule/EmployeeAbsenceSchedule';
import { useDashboard } from '../../../core/hooks/useDashboard';
import { LeadershipDashboardApi } from '../../../core/http/requests/leadership-dashboard.api';
import { endOfMonth, startOfMonth, sub, startOfDay, endOfDay } from 'date-fns';

const leadershipDashboardApi = new LeadershipDashboardApi();

export const LeadershipDashboard: FC = () => {
  const [period, setPeriod] = useState('thisMonth');
  const { data, isLoading, handleChangeParams } = useDashboard(
    leadershipDashboardApi,
    {
      defaultParams: {
        dateFrom: startOfMonth(startOfDay(new Date())),
        dateTo: endOfMonth(endOfDay(new Date()))
      }
    }
  );

  useEffect(() => {
    if (period === 'thisMonth') {
      const from = startOfMonth(startOfDay(new Date()));
      const to = endOfMonth(endOfDay(new Date()));
      handleChangeParams({ dateFrom: from, dateTo: to });
    } else if (period === 'thisQuarter') {
      const from = startOfMonth(startOfDay(sub(new Date(), { months: 2 })));
      const to = endOfMonth(endOfDay(new Date()));
      handleChangeParams({ dateFrom: from, dateTo: to });
    } else if (period === 'thisYear') {
      const from = startOfMonth(startOfDay(sub(new Date(), { months: 5 })));
      const to = endOfMonth(endOfDay(new Date()));
      handleChangeParams({ dateFrom: from, dateTo: to });
    }
  }, [period]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        <Grid item md={6}>
          <RevenueVsAuthorizedHours
            currentRevenue={data.currentRevenue}
            scheduledRevenue={data.scheduledRevenue}
            monthToDateRevenue={data.monthToDateRevenue}
            currentAuthorizedHours={data.currentAuthorizedHours}
            period={period}
            setPeriod={setPeriod}
          />
        </Grid>
        <Grid item md={6}>
          <MonthToDateRevenue monthToDateRevenue={data.monthToDateRevenue} />
        </Grid>
        <Grid item md={12}>
          <ConvertedVsSheduled
            weeklyConvertedRevenue={data.weeklyConvertedRevenue}
            weeklyScheduledRevenue={data.weeklyScheduledRevenue}
          />
        </Grid>
        <Grid item md={12}>
          <WeeklyConvertedVsSheduled
            weeklyConvertedRevenueByServiceCode={
              data.weeklyConvertedRevenueByServiceCode
            }
            weeklyScheduledRevenueByServiceCode={
              data.weeklyScheduledRevenueByServiceCode
            }
          />
        </Grid>
        <Grid item md={12}>
          <WeeklyActualVsForecast
            weeklyActualRevenueByServiceCode={
              data.weeklyActualRevenueByServiceCode
            }
            weeklyForecastRevenueByServiceCode={
              data.weeklyForecastRevenueByServiceCode
            }
          />
        </Grid>
        <Grid item md={12}>
          <LossOfRevenueVsForecast
            convertedM2DRevenue={data.convertedM2DRevenue}
            lossM2DRevenue={data.lossM2DRevenue}
            forecastM2DRevenue={data.forecastM2DRevenue}
          />
        </Grid>
        <Grid item md={12}>
          <InventoryPercentOfWorking
            inventoryY2dAuthorizedHours={data.inventoryY2dAuthorizedHours}
            inventoryY2dCommittedHours={data.inventoryY2dCommittedHours}
            inventoryY2dScheduledHours={data.inventoryY2dScheduledHours}
          />
        </Grid>
        <Grid item md={12}>
          <ScheduledVsAuthorized
            y2dScheduledRevenueByServiceCode={
              data.y2dScheduledRevenueByServiceCode
            }
            y2dAuthorizedRevenueByServiceCode={
              data.y2dAuthorizedRevenueByServiceCode
            }
          />
        </Grid>
        <Grid item md={6}>
          <CancelattionVsM2DRevenue
            m2dRevenue={data.m2dRevenue}
            parentCancelledRevenue={data.parentCancelledRevenue}
            parentCancelledRescheduledRevenue={
              data.parentCancelledRescheduledRevenue
            }
            therapistCancelledRevenue={data.therapistCancelledRevenue}
            therapistCancelledRescheduledRevenue={
              data.therapistCancelledRescheduledRevenue
            }
          />
        </Grid>
        <Grid item md={6}>
          <LossToCancellation
            weeklyLossRevenue={data.weeklyLossRevenue}
            weeklyCancellationRevenue={data.weeklyCancellationRevenue}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <EmployeeAbsenceSchedule />
        </Grid>
      </Grid>
    </>
  );
};
