import { makeStyles } from '@mui/styles';
import waves from '../../assets/waves.png';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  cover: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
      'radial-gradient(115.93% 96.4% at 86.99% 22.26%, #0052CC 0%, #7200CC 100%)'
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  waves: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    flex: 1,
    backgroundImage: `url(${waves})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },

  bottomInfoBar: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    right: 0,
    bottom: 40
  }
}));
