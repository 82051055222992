import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'calendar-view-header'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: '#FBFCFF',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      padding: theme.spacing(0.5, 1.5),
      textAlign: 'left'
    }
  }),
  options
);

export default useStyles;
