import { useState } from 'react';
import { PageHeader } from '../../components/page-header';
import { useLayout } from '../../core/hooks/useLayout';
import { ROUTE_APPOINTMENTS } from '../../constants/route.paths';
import { HorizontalStepper } from '../../components/horizontal-stepper/HorizontalStepper';
import { Button } from '@mui/material';
import { AppointmentGeneralForm } from './AppointmentGeneralForm';
import { useLocation, useNavigate } from 'react-router';
import { urlParamsToObject } from '../../core';
import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAppointmentEdit } from '../../core/hooks/useAppointmentEdit';
import { ServiceCodeApi } from '../../core/http';
import map from 'lodash/map';
import split from 'lodash/split';

const serviceCodeApi = new ServiceCodeApi();

export const AppointmentCreate = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useLayout({
    backButton: {
      title: 'Appointments',
      path: ROUTE_APPOINTMENTS
    }
  });
  const uuid = null;
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    getFieldTouchedError,
    setValues,
    isLoading,
    setFieldTouched,
    handleBlur
  } = useAppointmentEdit(uuid, selectedCity, selectedState);
  useEffect(() => {
    if (search) {
      const params = urlParamsToObject(search);
      if (!isEmpty(params)) {
        const { startDate, endDate, serviceCodeUuids, ...values } =
          params as any;
        if (!serviceCodeUuids) {
          navigate(ROUTE_APPOINTMENTS);
        }
        setValues((prev) => ({
          ...prev,
          startDate: new Date(startDate) || '',
          startTime: new Date(startDate) || '',
          endDate: new Date(endDate) || '',
          dateStart: new Date(startDate) || '',
          dateEnd: new Date(endDate) || '',
          authorizations: [],
          ...values
        }));
        Promise.all(
          map(split(serviceCodeUuids, ','), (uuid) =>
            serviceCodeApi.findOne(uuid)
          )
        ).then((serviceCodes) => {
          setValues((prev) => ({
            ...prev,
            authorizations: serviceCodes.map((serviceCode) => ({
              serviceCodeUuid: serviceCode.uuid,
              serviceCode,
              duration: 60
            })),
            ...values
          }));
        });
      }
    }
  }, []);

  return (
    <>
      <PageHeader
        title="New Appointment"
        meta={
          <HorizontalStepper
            ml={3}
            activeStep={2}
            items={[
              'Add criteria',
              'Compare availability',
              'Confirm the appointment information'
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()}>Confirm appointment</Button>
        <Button
          color="third"
          onClick={() => {
            navigate(ROUTE_APPOINTMENTS);
          }}
        >
          Cancel
        </Button>
      </PageHeader>

      <AppointmentGeneralForm
        {...{
          errors,
          values,
          isLoading,
          setFieldTouched,
          handleBlur,
          setFieldValue,
          getFieldTouchedError,
          handleSubmit,
          setSelectedCity,
          setSelectedState
        }}
      />
    </>
  );
};
