import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { TitleApi, TitleDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const titleApi = new TitleApi();

export const fetchTitleAsync = createFetchAsync(
  'title',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await titleApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchTitle = fetchTitleAsync.asyncThunk;

export const fetchTitle = (payload) => (dispatch, getState) => {
  const {
    title: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchTitle(payload));
  }
};

type TitleState = {
  findAll: AsyncState<FindAllResponse<TitleDto>>;
};

const initialState: TitleState = {
  findAll: fetchTitleAsync.initialState
};

const title = createSlice({
  name: 'title',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchTitleAsync.extraReducers
  }
});

export default title.reducer;
