import { TimeSheetEditor } from '../../features/reports/TimeSheetEditor';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const ReportsTimeSheetEditorPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Reports'
  })
)(TimeSheetEditor);
