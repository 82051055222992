import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminCompanyProfileContainer } from '../../features/super-admin-companies/SuperAdminCompanyProfile';

export const SuperAdminCompanyProfile = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Companies'
  })
)(SuperAdminCompanyProfileContainer);
