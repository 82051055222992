import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';

interface IRequestOptions {
  onSuccess?: (...args: any[]) => void;
  onFail?: (...args: any[]) => void;
}

interface IClientValues {
  uuid: string;
  memberId?: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  primaryLanguageUuid: string;
  secondaryLanguageUuid: string;
  fundingSourceUuid: string;
  profitCenterUuid: string;
  notes?: string;
  referralAcceptanceDate: string;
}

interface IContactValues {
  uuid: string;
  clientUuid: string;
  type: string;
  relationToChild: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  email: string;
  city: string;
  state: string;
  zipCode: string;
  isTextNotificationsEnabled: true;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  workPhoneNumber: string;
}

interface IDeleteProfileThunkArguments {
  uuid: string;
  options?: Partial<IRequestOptions>;
}

export const getProfile = createAsyncThunk(
  'getProfileActoion',
  async (uuid: string) => {
    try {
      const data: any = await authService.get(`client/${uuid}`);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const createProfile = createAsyncThunk(
  'createProfileAction',
  async (values: IClientValues) => {
    try {
      const data = await authService.post('client', values);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const updateProfile = createAsyncThunk(
  'updateProfileAction',
  async (values: IClientValues) => {
    try {
      const data: any = await authService.put('client', values);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const deleteProfile = createAsyncThunk(
  'deleteProfileAction',
  async ({ uuid, options }: IDeleteProfileThunkArguments) => {
    try {
      const data = authService.delete('client', {
        data: {
          uuid: uuid
        }
      });
      if (options?.onSuccess) {
        options.onSuccess();
      }
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const createContacts = createAsyncThunk(
  'createContactsAction',
  async (values: IContactValues) => {
    try {
      const data: any = await authService.post('client/contact', values);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const updateContacts = createAsyncThunk(
  'updateContactsAction',
  async (values: IContactValues) => {
    try {
      const data: any = await authService.put('clientContact', values);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const getProfileContacts = createAsyncThunk(
  'getProfileContactsAction',
  async (uuid: string) => {
    try {
      const data: any = await authService.get(`clientContact/${uuid}`);
      return data;
    } catch (e) {
      throw new Error('Something went wrong');
    }
  }
);

export const clientProfileSlice = createSlice({
  name: 'clientProfileSlice',
  initialState: {
    createProfile: {
      loading: false,
      data: null,
      error: null
    },
    getProfile: {
      loading: false,
      data: null,
      error: null
    },
    updateProfile: {
      loading: false,
      data: null,
      error: null
    },
    deleteProfile: {
      loading: false,
      data: null,
      error: null
    },
    updateContacts: {
      loading: false,
      data: null,
      error: null
    },
    createContacts: {
      loading: false,
      data: null,
      error: null
    },
    getProfileContacts: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProfile.pending, (state) => {
      state.createProfile.loading = true;
      state.createProfile.data = null;
      state.createProfile.error = null;
    });
    builder.addCase(createProfile.fulfilled, (state, { payload }) => {
      state.createProfile.loading = false;
      state.createProfile.data = payload;
      state.createProfile.error = null;
    });
    builder.addCase(createProfile.rejected, (state) => {
      state.createProfile.loading = false;
      state.createProfile.data = null;
      state.createProfile.error = 'Something went wrong';
    });
    builder.addCase(getProfile.pending, (state) => {
      state.getProfile.loading = true;
      state.getProfile.data = null;
      state.getProfile.error = null;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.getProfile.loading = false;
      state.getProfile.data = payload.data;
      state.getProfile.error = null;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.getProfile.loading = false;
      state.getProfile.data = null;
      state.getProfile.error = 'Something went wrong';
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfile.loading = true;
      state.updateProfile.data = null;
      state.updateProfile.error = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.updateProfile.loading = false;
      state.updateProfile.data = payload;
      state.updateProfile.error = null;
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.updateProfile.loading = false;
      state.updateProfile.data = null;
      state.updateProfile.error = 'Something went wrong';
    });

    builder.addCase(deleteProfile.pending, (state) => {
      state.deleteProfile.loading = true;
      state.deleteProfile.data = null;
      state.deleteProfile.error = null;
    });
    builder.addCase(deleteProfile.fulfilled, (state, { payload }) => {
      state.deleteProfile.loading = false;
      state.deleteProfile.data = payload;
      state.deleteProfile.error = null;
    });
    builder.addCase(deleteProfile.rejected, (state) => {
      state.deleteProfile.loading = false;
      state.deleteProfile.data = null;
      state.deleteProfile.error = 'Something went wrong';
    });
    builder.addCase(createContacts.pending, (state) => {
      state.createContacts.loading = true;
      state.createContacts.data = null;
      state.createContacts.error = null;
    });
    builder.addCase(createContacts.fulfilled, (state, { payload }) => {
      state.createContacts.loading = false;
      state.createContacts.data = payload;
      state.createContacts.error = null;
    });
    builder.addCase(createContacts.rejected, (state) => {
      state.createContacts.loading = false;
      state.createContacts.data = null;
      state.createContacts.error = 'Something went wrong';
    });

    builder.addCase(updateContacts.pending, (state) => {
      state.updateContacts.loading = true;
      state.updateContacts.data = null;
      state.updateContacts.error = null;
    });
    builder.addCase(updateContacts.fulfilled, (state, { payload }) => {
      state.updateContacts.loading = false;
      state.updateContacts.data = payload;
      state.updateContacts.error = null;
    });
    builder.addCase(updateContacts.rejected, (state) => {
      state.updateContacts.loading = false;
      state.updateContacts.data = null;
      state.updateContacts.error = 'Something went wrong';
    });
    builder.addCase(getProfileContacts.pending, (state) => {
      state.getProfileContacts.loading = true;
      state.getProfileContacts.data = null;
      state.getProfileContacts.error = null;
    });
    builder.addCase(getProfileContacts.fulfilled, (state, { payload }) => {
      state.getProfileContacts.loading = false;
      state.getProfileContacts.data = payload;
      state.getProfileContacts.error = null;
    });
    builder.addCase(getProfileContacts.rejected, (state) => {
      state.getProfileContacts.loading = false;
      state.getProfileContacts.data = null;
      state.getProfileContacts.error = 'Something went wrong';
    });
  }
});

export default clientProfileSlice.reducer;
