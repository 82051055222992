import { FC } from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import { IconClock } from '../icons';

interface Props extends BoxProps {
  severity?: 'info' | 'warning' | 'error' | 'success';
}

//TODO: add severity styles and icons
export const Alert: FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      {...props}
      className={clsx(classes.root, {
        [classes.info]: props.severity === 'info',
        [classes.warning]: props.severity === 'warning',
        [classes.error]: props.severity === 'error',
        [classes.success]: props.severity === 'success'
      })}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <IconClock />
        {children}
      </Stack>
    </Box>
  );
};
