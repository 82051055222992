import { useParams } from 'react-router';
import Grid from '@mui/material/Grid';
import { PageHeader } from '../../../components/page-header';
import { useLayout } from '../../../core/hooks/useLayout';
import { ROUTE_MASTER_DATA_SERVICE_CODE } from '../../../constants/route.paths';
import { Card } from '../../../components/cards';
import {
  Checkbox,
  EnumField,
  PlaceServiceField,
  ServiceTypeField,
  TextField
} from '../../../components/fields';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { ServiceCodeApi } from '../../../core/http';
import {
  AUTHORIZATION,
  CALCULATION_TYPE,
  ENFORCE_RATE,
  PAYABLE_TYPE,
  ROUND_TYPE,
  SERVICE_CODE_TYPE,
  SIGNATURE_FORM,
  VALIDATION_OPTIONAL_TYPE,
  VALIDATION_TYPE
} from '../../../constants/enums';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { useDispatch } from 'react-redux';
import { forceFetchServiceCode } from '../../../core/store/slices/serviceCodeSlice';

const serviceCodeApi = new ServiceCodeApi();

export const ServiceCodeEdit = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { showMessage } = useSnackbar();

  useLayout({
    backButton: {
      title: 'Service Codes',
      path: ROUTE_MASTER_DATA_SERVICE_CODE
    }
  });

  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(serviceCodeApi, {
    uuid,
    initialValues: {
      type: '',
      code: '',
      description: '',
      enforceRate: '',
      calculationType: '',
      servicePlaceUuid: '',
      defaultUnits: '',
      minutesPerUnit: '',
      round: '',
      serviceTypeUuid: '',
      defaultServiceRate: '',
      payableType: '',
      defaultMinutes: '',
      modifires: '',
      providerSignature: '',
      clientSignature: '',
      clientSignatureFrom: '',
      serviceLocation: '',
      serviceAddress: '',
      procedureInfo: '',
      serviceNotes: '',
      adminNotes: '',
      requiresAuthorization: null,
      enforceSingleTimesheetConversion: false,
      requiresScheduleConversion: false,
      restrictEarlyTimesheetConversion: false,
      lockGracePeriod: ''
    },
    validationSchema: yup.object().shape({
      type: yup.string().required('Code Type is a required field'),
      enforceRate: yup.string().required('Enforce Rate is a required field'),
      servicePlaceUuid: yup
        .string()
        .required('Default Place of Service is a required field'),
      serviceTypeUuid: yup
        .string()
        .required('Service Type is a required field'),
      round: yup.string().required('Round is a required field'),
      payableType: yup.string().required('Payable Type is a required field'),
      description: yup
        .string()
        .required('Description Type is a required field'),
      calculationType: yup
        .string()
        .required('Calculation Type is a required field'),
      defaultUnits: yup.string().required('Default Units is a required field'),

      providerSignature: yup
        .string()
        .required('Provider Signature is a required field'),
      clientSignature: yup
        .string()
        .required('Client Signature is a required field'),
      clientSignatureFrom: yup
        .string()
        .required('Client Signature from is a required field'),
      serviceLocation: yup
        .string()
        .required('Service Location is a required field'),
      serviceAddress: yup
        .string()
        .required('Service Address is a required field'),
      procedureInfo: yup
        .string()
        .required('Procedure Info is a required field'),
      serviceNotes: yup.string().required('Service Notes is a required field'),
      adminNotes: yup.string().required('Admin Notes is a required field')
    }),
    onSuccess: () => {
      navigation(ROUTE_MASTER_DATA_SERVICE_CODE);
      dispatch(forceFetchServiceCode());
      showMessage(`Record successfully ${isEdit ? 'updated' : 'created'}`);
    },
    onError: () => {
      // showMessage('Something went wrong', 'error');
    }
  });

  return (
    <>
      <PageHeader title={isEdit ? 'Edit Service Code' : 'Add Service Code'}>
        <Button
          size="small"
          disabled={isLoading}
          onClick={() => handleSubmit()}
        >
          {isEdit ? 'Update' : 'Save'}
        </Button>
        <Button
          size="small"
          color="secondary"
          onClick={() => navigation(ROUTE_MASTER_DATA_SERVICE_CODE)}
        >
          Cancel
        </Button>
      </PageHeader>

      <Card py={3}>
        <Grid container columnSpacing={1}>
          <Grid item md={6}>
            <Grid container columnSpacing={1}>
              <Grid item md={6}>
                <EnumField
                  label="Code Type*"
                  placeholder="Select"
                  items={SERVICE_CODE_TYPE}
                  value={values.type}
                  onChange={handleChange('type')}
                  errors={getFieldTouchedError('type')}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Code"
                  value={values.code}
                  onChange={handleChange('code')}
                  errors={getFieldTouchedError('code')}
                />
              </Grid>
            </Grid>

            <EnumField
              label="Enforce Rate*"
              placeholder="Select"
              items={ENFORCE_RATE}
              value={values.enforceRate}
              onChange={handleChange('enforceRate')}
              errors={getFieldTouchedError('enforceRate')}
            />

            <PlaceServiceField
              label="Default Place of Service*"
              value={values.servicePlaceUuid}
              onChange={handleChange('servicePlaceUuid')}
              errors={getFieldTouchedError('servicePlaceUuid')}
            />

            <Grid container columnSpacing={1}>
              <Grid item md={6}>
                <ServiceTypeField
                  label="Service Type*"
                  value={values.serviceTypeUuid}
                  onChange={handleChange('serviceTypeUuid')}
                  errors={getFieldTouchedError('serviceTypeUuid')}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Default Service Rate"
                  type="number"
                  value={values.defaultServiceRate}
                  onChange={handleChange('defaultServiceRate')}
                  errors={getFieldTouchedError('defaultServiceRate')}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={1}>
              <Grid item md={6}>
                <EnumField
                  label="Payable Type*"
                  placeholder="Select"
                  items={PAYABLE_TYPE}
                  value={values.payableType}
                  onChange={handleChange('payableType')}
                  errors={getFieldTouchedError('payableType')}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Default Min"
                  type="number"
                  value={values.defaultMinutes}
                  onChange={handleChange('defaultMinutes')}
                  errors={getFieldTouchedError('defaultMinutes')}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} alignItems="flex-end">
              <Grid item md={3}>
                <TextField
                  label="Modifires"
                  value={values.modifires}
                  onChange={handleChange('modifires')}
                  errors={getFieldTouchedError('modifires')}
                />
              </Grid>
              <Grid item md={3}>
                <TextField />
              </Grid>
              <Grid item md={3}>
                <TextField />
              </Grid>
              <Grid item md={3}>
                <TextField />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6}>
            <TextField
              label="Description*"
              value={values.description}
              onChange={handleChange('description')}
              errors={getFieldTouchedError('description')}
            />

            <EnumField
              label="Calculation Type*"
              placeholder="Select"
              items={CALCULATION_TYPE}
              value={values.calculationType}
              onChange={handleChange('calculationType')}
              errors={getFieldTouchedError('calculationType')}
            />

            <Grid container columnSpacing={1} alignItems="flex-end">
              <Grid item md={3}>
                <TextField
                  label="Default Units*"
                  value={values.defaultUnits}
                  onChange={handleChange('defaultUnits')}
                  errors={getFieldTouchedError('defaultUnits')}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label="Min/Unit"
                  value={values.minutesPerUnit}
                  onChange={handleChange('minutesPerUnit')}
                  errors={getFieldTouchedError('minutesPerUnit')}
                />
              </Grid>
              <Grid item md={6}>
                <EnumField
                  items={ROUND_TYPE}
                  value={values.round}
                  onChange={handleChange('round')}
                  errors={getFieldTouchedError('round')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Card title="Validation" py={3}>
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <EnumField
              label="Provider Signature*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.providerSignature}
              onChange={handleChange('providerSignature')}
              errors={getFieldTouchedError('providerSignature')}
            />
          </Grid>
          <Grid item md={3}>
            <EnumField
              label="Client Signature*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.clientSignature}
              onChange={handleChange('clientSignature')}
              errors={getFieldTouchedError('clientSignature')}
            />
          </Grid>
          <Grid item md={3}>
            <EnumField
              label="Client Signature from*"
              placeholder="Select"
              items={SIGNATURE_FORM}
              value={values.clientSignatureFrom}
              onChange={handleChange('clientSignatureFrom')}
              errors={getFieldTouchedError('clientSignatureFrom')}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <EnumField
              label="Service Location*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.serviceLocation}
              onChange={handleChange('serviceLocation')}
              errors={getFieldTouchedError('serviceLocation')}
            />
          </Grid>
          <Grid item md={3}>
            <EnumField
              label="Service Address*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.serviceAddress}
              onChange={handleChange('serviceAddress')}
              errors={getFieldTouchedError('serviceAddress')}
            />
          </Grid>
          <Grid item md={3}>
            <EnumField
              label="Procedure Info*"
              placeholder="Select"
              items={VALIDATION_OPTIONAL_TYPE}
              value={values.procedureInfo}
              onChange={handleChange('procedureInfo')}
              errors={getFieldTouchedError('procedureInfo')}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <EnumField
              label="Service Notes*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.serviceNotes}
              onChange={handleChange('serviceNotes')}
              errors={getFieldTouchedError('serviceNotes')}
            />
          </Grid>
          <Grid item md={3}>
            <EnumField
              label="Admin Notes*"
              placeholder="Select"
              items={VALIDATION_TYPE}
              value={values.adminNotes}
              onChange={handleChange('adminNotes')}
              errors={getFieldTouchedError('adminNotes')}
            />
          </Grid>
        </Grid>
      </Card>

      <Card title="Restrictions" py={3}>
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <EnumField
              label="Requires Authorization"
              placeholder="Select"
              items={AUTHORIZATION}
              value={values.requiresAuthorization}
              onChange={handleChange('requiresAuthorization')}
              errors={getFieldTouchedError('requiresAuthorization')}
            />
          </Grid>
        </Grid>
        <Box>
          <Stack direction="row" spacing={3}>
            <FormControlLabel
              checked={values.enforceSingleTimesheetConversion}
              value={values.enforceSingleTimesheetConversion}
              control={
                <Checkbox
                  onChange={handleChange('enforceSingleTimesheetConversion')}
                />
              }
              label={
                <Typography variant="body3">
                  Enforce Single Timesheet Conversion
                </Typography>
              }
            />
            <FormControlLabel
              checked={values.requiresScheduleConversion}
              value={values.requiresScheduleConversion}
              control={
                <Checkbox
                  onChange={handleChange('requiresScheduleConversion')}
                />
              }
              label={
                <Typography variant="body3">
                  Requires Schedule Conversion
                </Typography>
              }
            />
            <FormControlLabel
              checked={values.restrictEarlyTimesheetConversion}
              value={values.restrictEarlyTimesheetConversion}
              control={
                <Checkbox
                  onChange={handleChange('restrictEarlyTimesheetConversion')}
                />
              }
              label={
                <Typography variant="body3">
                  Restrict Early Timesheet Conversion
                </Typography>
              }
            />
          </Stack>
          <FormHelperText> </FormHelperText>
        </Box>

        {/*<CheckboxList*/}
        {/*  value={[]}*/}
        {/*  onChange={(values) => {*/}
        {/*    handleChange('enforceSingleTimesheetConversion')(*/}
        {/*      +values.includes('enforceSingleTimesheetConversion')*/}
        {/*    );*/}
        {/*    handleChange('restrictEarlyTimesheetConversion')(*/}
        {/*      +values.includes('restrictEarlyTimesheetConversion')*/}
        {/*    );*/}
        {/*    handleChange('requiresScheduleConversion')(*/}
        {/*      +values.includes('requiresScheduleConversion')*/}
        {/*    );*/}
        {/*  }}*/}
        {/*  items={[*/}
        {/*    {*/}
        {/*      label: 'Enforce Single Timesheet Conversion',*/}
        {/*      value: 'enforceSingleTimesheetConversion',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      label: 'Requires Schedule Conversion',*/}
        {/*      value: 'requiresScheduleConversion',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      label: 'Restrict Early Timesheet Conversion',*/}
        {/*      value: 'restrictEarlyTimesheetConversion',*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
        <Grid container columnSpacing={1}>
          <Grid item md={3}>
            <TextField
              label="Lock Grace Period"
              type="number"
              value={values.lockGracePeriod}
              onChange={handleChange('lockGracePeriod')}
              errors={getFieldTouchedError('lockGracePeriod')}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
