import { Expose } from 'class-transformer';

export class CaseLoadTrackerReportDto {
  @Expose()
  employeeUuid: string;

  @Expose()
  employeeName: string;

  @Expose()
  hoursScheduled: number;

  @Expose()
  hoursConverted: number;

  @Expose()
  hoursUnconverted: number;

  @Expose()
  hoursCancelled: number;

  @Expose()
  staffCancellation: number;

  @Expose()
  parentCancellation: number;

  @Expose()
  completionRate: number;
}
