import { ComponentType } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Avatar, Typography, Grid } from '@mui/material';
import { normalizePhone, url, usPhoneNumber } from '../../core';
import { useDialog } from '../../core/hooks/useDialog';
import { PageHeader } from '../../components/page-header';
import { UserApi } from '../../core/http';
import { Card } from '../../components/cards';
import { PhoneField, TextField } from '../../components/fields';
import { ROUTE_PROFILE } from '../../constants/route.paths';
import { useEditResource } from '../../core/hooks/useEditResource';
import * as yup from 'yup';
import { RoleField } from '../../components/fields/role-field';
import { UploadPhotoDialog } from './dialogs/UpoladPhotoDialog';

const userApi = new UserApi();

export const ProfileEdit: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openUploadPhotoDialog] = useDialog(UploadPhotoDialog);

  const headerProps = {
    divider: true,
    title: <Typography variant="h2">Edit Profile</Typography>
  };

  const {
    isLoading,
    handleSubmit,
    handleChange,
    handleBlur,
    getFieldTouchedError,
    values
  } = useEditResource(userApi, {
    uuid: id,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      roleUuid: ''
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required('Please enter first name.'),
      lastName: yup.string().required('Please enter last name.'),
      email: yup
        .string()
        .email('Please enter valid email address.')
        .required('Required field'),
      phoneNumber: yup
        .string()
        .transform((value) => (value ? normalizePhone(value) : value))
        .length(10, 'Please enter valid phone number.'),
      roleUuid: yup
        .string()
        .nullable()
        .required('Please select one of the options below.')
    }),
    onSuccess: () => {
      navigate(url(`${ROUTE_PROFILE}?rf=1`));
    }
  });

  return (
    <>
      <PageHeader title="" {...headerProps}>
        <Button onClick={() => navigate(ROUTE_PROFILE)} color="third">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </PageHeader>
      <Card title="General Information">
        <Stack spacing={2} direction="row">
          <Avatar src="" />
          <Button variant="text" onClick={openUploadPhotoDialog}>
            Change photo
          </Button>
        </Stack>
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <TextField
              required
              label="First Name"
              disabled={isLoading}
              errors={getFieldTouchedError('firstName')}
              value={values.firstName}
              onChange={handleChange('firstName')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              required
              label="Last Name"
              disabled={isLoading}
              errors={getFieldTouchedError('lastName')}
              value={values.lastName}
              onChange={handleChange('lastName')}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              required
              label="Email"
              disabled={isLoading}
              errors={getFieldTouchedError('email')}
              value={values.email}
              onChange={handleChange('email')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <PhoneField
              label="Phone Number"
              required
              value={usPhoneNumber(values?.phoneNumber || '')}
              errors={getFieldTouchedError('phoneNumber')}
              helperText={getFieldTouchedError('phoneNumber')}
              onChange={handleChange('phoneNumber')}
              onBlur={handleBlur('phoneNumber')}
              disabled={isLoading}
            />
          </Grid>
          <Grid item md={3}>
            <RoleField
              required
              label="User Role"
              disabled={isLoading}
              errors={getFieldTouchedError('roleUuid')}
              helperText={getFieldTouchedError('roleUuid')}
              onChange={handleChange('roleUuid')}
              onBlur={handleBlur('roleUuid')}
              value={values.roleUuid}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
