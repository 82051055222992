import { EmployeeAppointmentEdit } from '../../../features/employees/EmployeeAppointmentEdit';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const EmployeeAppointmentEditPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Employees'
  })
)(EmployeeAppointmentEdit);
