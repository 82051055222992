import { ComponentType } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { messagesColumns } from '../_mockdata';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { AddMessageDialog } from '../dialogs/AddMessageDialog';
import { PermissionView } from '../../../components/permission-view';
import { useListResource } from '../../../core/hooks/useListResource';
import { MessagesApi } from '../../../core/http';
import get from 'lodash/get';

const messagesApi = new MessagesApi();

export const Messages: ComponentType = () => {
  const [openAddNewMessageDialog] = useDialog(AddMessageDialog);

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    handleChangeParams,
    handleChangeSort
  } = useListResource(messagesApi);

  const messagesData = get(data, 'items', []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyClients">
          <Button onClick={openAddNewMessageDialog}>Add new</Button>
        </PermissionView>
        <SearchField sx={{ width: 400 }} size="small" placeholder="Search" />
      </Stack>
      <Box>
        <TableView
          loading={isLoading}
          showPagination={true}
          pagination={{
            page: 0,
            perPage: 10,
            totalPages: 10,
            total: 12
          }}
          dataSource={messagesData || []}
          columns={messagesColumns}
        />
      </Box>
    </>
  );
};
