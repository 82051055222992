import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  indexAxis: 'x' as const,
  responsive: true,

  options: {
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    },
    datalabels: {
      display: true,
      formatter: (value) => currencyFormatter(value)
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: false,
        drawBorder: false
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: false
      },
      scaleLabel: {
        display: false
      },
      max: 300000,
      // min: 0,
      ticks: {
        display: false
      }
    }
  }
};
