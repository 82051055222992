import React, { ComponentType } from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface Props {
  items: {
    key: string;
    label: string;
  }[];
  onChange?: (key: number) => void;
}

export const FilterableTabs: ComponentType<Props> = ({
  onChange,
  items,
  children
}) => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs value={value} onChange={handleChange}>
          {items.map((item, index) => (
            <MuiTab key={index} label={item.label} disableRipple />
          ))}
        </MuiTabs>
      </Box>
      <Box sx={{ pt: 3 }}>{children}</Box>
    </>
  );
};
