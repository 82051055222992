import { AppointmentDto } from '../../core/http';
import { IconButton, Stack, Tooltip } from '@mui/material';
import {
  IconAppointmentCancel,
  IconAppointmentConfirm,
  IconAppointmentRefresh,
  IconPen,
  IconTrash,
  IconView
} from '../icons';
import { FC } from 'react';
import { PermissionView } from '../permission-view';

interface Props {
  appointment: AppointmentDto;
  handleEdit: (appointment: AppointmentDto) => void;
  handleDelete: (appointment: AppointmentDto) => void;
  handleCancel: (appointment: AppointmentDto) => void;
  handleMakeUp: (appointment: AppointmentDto) => void;
  handleConvert: (appointment: AppointmentDto) => void;
  handleView: (appointment: AppointmentDto) => void;
}

export const AppointmentActionButtons: FC<Props> = ({
  appointment,
  handleDelete,
  handleEdit,
  handleCancel,
  handleConvert,
  handleView,
  handleMakeUp
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      onClick={(e) => e.stopPropagation()}
    >
      {appointment.canConvert && (
        <PermissionView permission="modifyAppointments">
          <Tooltip title="Convert" placement="bottom-end">
            <IconButton size="small" onClick={() => handleConvert(appointment)}>
              <IconAppointmentConfirm />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
      {appointment.canEdit && (
        <PermissionView permission="modifyAppointments">
          <Tooltip title="Edit" placement="bottom-end">
            <IconButton size="small" onClick={() => handleEdit(appointment)}>
              <IconPen />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
      {appointment.canDelete && (
        <PermissionView permission="modifyAppointments">
          <Tooltip title="Delete" placement="bottom-end">
            <IconButton size="small" onClick={() => handleDelete(appointment)}>
              <IconTrash />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
      {appointment.canCancel && (
        <PermissionView permission="modifyAppointments">
          <Tooltip title="Cancel" placement="bottom-end">
            <IconButton size="small" onClick={() => handleCancel(appointment)}>
              <IconAppointmentCancel />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
      {appointment.canView && (
        <PermissionView permission="viewAppointments">
          <Tooltip title="View" placement="bottom-end">
            <IconButton size="small" onClick={() => handleView(appointment)}>
              <IconView />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
      {appointment.canMakeUp && (
        <PermissionView permission="modifyAppointments">
          <Tooltip title="Make Up" placement="bottom-end">
            <IconButton size="small" onClick={() => handleMakeUp(appointment)}>
              <IconAppointmentRefresh />
            </IconButton>
          </Tooltip>
        </PermissionView>
      )}
    </Stack>
  );
};
