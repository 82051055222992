import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';
import { EmployeeAppointmentView } from '../../../features/employees/EmployeeAppointmentView';

export const EmployeeAppointmentViewPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Employees'
  })
)(EmployeeAppointmentView);
