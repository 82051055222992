import { ServiceCodeEdit } from '../../features/master-data/service-code/ServiceCodeEdit';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataEditServiceCode = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Service Codes'
  })
)(ServiceCodeEdit);
