import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { FundingSourceApi, FundingSourceDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const fundingSourceApi = new FundingSourceApi();

export const fetchFundingSourceAsync = createFetchAsync(
  'fundingSource',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await fundingSourceApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchFundingSource = fetchFundingSourceAsync.asyncThunk;

export const fetchFundingSource = (payload) => (dispatch, getState) => {
  const {
    fundingSource: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchFundingSource(payload));
  }
};

type FundingSourceState = {
  findAll: AsyncState<FindAllResponse<FundingSourceDto>>;
};

const initialState: FundingSourceState = {
  findAll: fetchFundingSourceAsync.initialState
};

const fundingSource = createSlice({
  name: 'fundingSsource',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchFundingSourceAsync.extraReducers
  }
});

export default fundingSource.reducer;
