import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack, Button } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { url } from '../../core/utils/route.utils';
import { NumberField } from '../../components/fields';
import {
  ROUTE_SIGN_IN,
  ROUTE_HOME,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import { twoFactorRecover } from '../../core/store/slices/twoFactorRecoverySlice';
import { AppState } from '../../core/store/store';
import { BackButton } from '../../components/back-button';
import { fetchUserMe } from '../../core/store/slices/userSlice';

export const TwoFactorRecovery: ComponentType = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const twoFactorRecoverLoding = useSelector(
    (state: AppState) => state.twoFactorRecovery.twoFactorRecover.loading
  );
  const twoFactorRecoverData = useSelector(
    (state: AppState) => state.twoFactorRecovery.twoFactorRecover.data
  );
  const twoFactorRecoverError = useSelector(
    (state: AppState) => state.twoFactorRecovery.twoFactorRecover.error
  );

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: {
        authCode: ''
      },
      validateOnBlur: true,
      validationSchema: yup.object().shape({
        authCode: yup
          .string()
          .min(4, ({ min }) => `Code must be at least ${min} characters`)
          .required('Please enter your google authenticator code')
      }),
      onSubmit: (values) => {
        dispatch(
          twoFactorRecover({
            values
          })
        );
      }
    });

  useEffect(() => {
    if (!twoFactorRecoverData) return;
    if (twoFactorRecoverData.is2faSetupCompleted) {
      redirectToHome();
    }
  }, [twoFactorRecoverData]);

  const redirectToHome = async () => {
    await dispatch(fetchUserMe());
    localStorage.setItem('login', '1');
    setTimeout(() => {
      navigate(url(ROUTE_HOME));
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.twoFactorRecoveryRoot}>
        <Box className={classes.block}>
          <BackButton title="Login" onClick={() => navigate(ROUTE_SIGN_IN)} />
          <Typography variant="h3" mb={2} mt={2}>
            2-Factor Authorization
          </Typography>
          <Typography variant="body2">Enter your code below</Typography>
          <Box mt={2}>
            <NumberField
              helperText={
                (touched.authCode && errors.authCode) || twoFactorRecoverError
              }
              value={values.authCode}
              onChange={handleChange('authCode')}
              onBlur={handleBlur('authCode')}
            />

            <Stack direction="row" justifyContent="space-between" mt={2}>
              <Button type="submit" disabled={twoFactorRecoverLoding}>
                Submit
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </form>
  );
};
