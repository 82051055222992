import { useCallback, useState } from 'react';
import get from 'lodash/get';
import { isObject } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

const DEFAULT_LIMIT = 12;

interface UseListResourceResult {
  params: Params;
  data: any;
  isLoading: boolean;
  newParamsLoading: boolean;
  error: any;
  fetchData: () => void;
  exportData?: () => void;
  deleteRecord: (id: string) => Promise<void>;
  pagination: {
    page: number;
    perPage: number;
    totalPages: number;
    total: number;
  };
  handleChangePage: (page: number) => void;
  handleChangePerPage: (perPage: number) => void;
  handleChangeParams: (
    key: string | Record<string, string | number | boolean> | any,
    value?: string | number | boolean,
    isNewParams?: boolean
  ) => void;
  handleChangeSort: (sortState: Record<string, 'desc' | 'asc'>) => void;
}

interface Params {
  limit: number;
  page: number;
}

export const useListResource = (
  api,
  options = { defaultParams: {} }
): UseListResourceResult => {
  const { defaultParams } = options;

  const [params, setParams] = useState<Params>({
    limit: DEFAULT_LIMIT,
    page: 1,
    ...defaultParams
  });

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newParamsLoading, setNewParamsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    api
      .findAll(params)
      .then((response) => {
        setData(response);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setNewParamsLoading(false);
      });
  }, [params]);

  const exportData = useCallback(() => {
    api.export(params);
  }, [params]);

  useDeepCompareEffect(() => {
    fetchData();
  }, [params]);

  const deleteRecord = useCallback(
    (id) => {
      setIsLoading(true);
      return api
        .delete(id)
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [data]
  );

  const handleChangePage = useCallback(
    (page) => {
      setParams({
        ...params,
        page
      });
    },
    [params]
  );

  const handleChangePerPage = useCallback(
    (perPage) => {
      setParams({
        ...params,
        limit: perPage,
        page: 1
      });
    },
    [params]
  );

  const handleChangeSort = useCallback((sortState) => {
    const sortParams = Object.keys(sortState).reduce(
      (acc, key) => ({
        ...acc,
        [`sort[${key}]`]: sortState[key]
      }),
      {}
    );

    setParams((prevParams) => ({
      ...prevParams,
      ...sortParams
    }));
  }, []);

  const handleChangeParams = (
    key: string | Record<string, string | number | boolean> | any,
    value: string | number | boolean,
    isNewParams: boolean
  ) => {
    setNewParamsLoading(true);
    if (isNewParams) {
      setParams({
        page: params.page,
        limit: params.limit,
        ...key
      });
    } else if (isObject(key)) {
      setParams((prevState) => ({ ...prevState, ...key }));
    } else {
      setParams((prevState) => ({
        ...prevState,
        page: 1,
        [key]: value
      }));
    }
  };

  const pagination = {
    page: get(params, 'page', 1),
    perPage: get(data, 'recordsPerPage', 10),
    totalPages: get(data, 'totalPages', 0),
    total: get(data, 'totalRecords', 0)
  };

  return {
    params,
    data,
    isLoading,
    error,
    fetchData,
    exportData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    newParamsLoading,
    handleChangeSort
  };
};
