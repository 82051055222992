import { Expose } from 'class-transformer';

export class PermissionDto {
  @Expose()
  viewAppointments: boolean;
  @Expose()
  modifyAppointments: boolean;
  @Expose()
  viewTimesheets: boolean;
  @Expose()
  confirmTimesheets: boolean;
  @Expose()
  unconfirmTimesheets: boolean;
  @Expose()
  viewClientDashboard: boolean;
  @Expose()
  viewEmployeeDashboard: boolean;
  @Expose()
  viewBilledVsPlannedDashboard: boolean;
  @Expose()
  viewDashboard: boolean;
  @Expose()
  viewLeadershipDashboard: boolean;
  @Expose()
  viewCaseloadManagementDashboard: boolean;
  @Expose()
  viewMyReminders: boolean;
  @Expose()
  modifyMyReminders: boolean;
  @Expose()
  viewOtherEmployeeReminders: boolean;
  @Expose()
  modifyOtherEmployeeReminders: boolean;
  @Expose()
  viewAnnouncements: boolean;
  @Expose()
  modifyAnnouncements: boolean;
  @Expose()
  viewNews: boolean;
  @Expose()
  modifyNews: boolean;
  @Expose()
  viewMasterData: boolean;
  @Expose()
  modifyMasterData: boolean;
  @Expose()
  viewClients: boolean;
  @Expose()
  modifyClients: boolean;
  @Expose()
  viewEmployees: boolean;
  @Expose()
  modifyEmployees: boolean;
  @Expose()
  viewServiceAuthorizations: boolean;
  @Expose()
  modifyServiceAuthorizations: boolean;
  @Expose()
  viewUploadNewFiles: boolean;
  @Expose()
  modifyUploadNewFiles: boolean;
  @Expose()
  viewServiceAuthorizationReport: boolean;
  @Expose()
  viewBillingReport: boolean;
  @Expose()
  viewTimeCardReport: boolean;
  @Expose()
  viewCancellationReport: boolean;
  @Expose()
  viewCaseLoadTrackerReport: boolean;
  @Expose()
  viewServiceCodes: boolean;
  @Expose()
  modifyServiceCodes: boolean;
  @Expose()
  viewFindingSource: boolean;
  @Expose()
  modifyFindingSource: boolean;
  @Expose()
  viewCreateRoleAndPermissions: boolean;
  @Expose()
  modifyCreateRoleAndPermissions: boolean;
  @Expose()
  modifyAssignRoleAndPermissions: boolean;
}
