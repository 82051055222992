import { useEditResource } from './useEditResource';
import * as yup from 'yup';
import get from 'lodash/get';
import { addMinutes, format } from 'date-fns';
import map from 'lodash/map';
import { v4 as uuidv4 } from 'uuid';
import { ROUTE_APPOINTMENTS_LIST } from '../../constants/route.paths';
import { AppointmentApi, AuthorizationApi } from '../http';
import { useSnackbar } from './useSnackbar';
import { useNavigate } from 'react-router-dom';
import { normalizeTime, url } from '../utils';
import { useDialog } from './useDialog';
import { ConvertToTimesheetDialog } from '../../features/appointments/dialogs/ConvertToTimesheetDialog';
import { CancelAppointmentDialog } from '../../features/appointments/dialogs/CancelAppointmentDialog';
import { DeleteAppointmentDialog } from '../../features/appointments/dialogs/DeleteAppointmentDialog';

const appointmentApi = new AppointmentApi();
const authorizationApi = new AuthorizationApi();

export const useAppointmentEdit = (
  uuid,
  selectedCity = '',
  selectedState = '',
  backUrl = ''
) => {
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const [openConvertToTimesheet, closeConvertToTimesheet] = useDialog(
    ConvertToTimesheetDialog
  );
  const [openCancelDialog, closeCancelDialog] = useDialog(
    CancelAppointmentDialog
  );
  const [openDeleteDialog, closeDeleteDialog] = useDialog(
    DeleteAppointmentDialog
  );
  const {
    isLoading,
    isEdit,
    handleSubmit,
    values,
    setFieldValue,
    getFieldTouchedError,
    setValues,
    errors,
    setFieldTouched,
    handleBlur,
    data,
    touched
  } = useEditResource(appointmentApi, {
    uuid,
    initialValues: {
      clientUuid: '',
      serviceCodeUuids: [],
      startDate: new Date(),
      startTime: '',
      endDate: new Date(),
      duration: '',
      servicePlaceUuid: '',
      locationString: '',
      locationUuid: '',
      primaryTherapistUuid: '',
      secondaryTherapistUuid: '',
      typeUuid: '',
      authorizations: []
    },
    validationSchema: yup.object().shape(
      {
        startTime: yup
          .string()
          .typeError('Invalid date format')
          .required('Required field'),
        startDate: yup
          .string()
          .typeError('Invalid date format')
          .required('Required field'),
        servicePlaceUuid: yup
          .string()
          .typeError('Required field')
          .required('Required field'),
        primaryTherapistUuid: yup
          .string()
          .typeError('Required field')
          .required('Required field'),
        secondaryTherapistUuid: yup.string().nullable(),
        typeUuid: yup
          .string()
          .typeError('Required field')
          .required('Required field'),
        duration: yup
          .string()
          .typeError('Required field')
          .required('Required field'),
        locationUuid: yup.string().when('locationString', {
          is: '',
          then: yup.string().min(1, 'Pick at least 1 item'),
          otherwise: yup.string()
        }),
        locationString: yup.string().when('locationUuid', {
          is: (locationUuid) => !locationUuid,
          then: yup.string().required('This field is required.'),
          otherwise: yup.string()
        })
      },
      [['locationUuid', 'locationString']]
    ),
    normalizeSetValues: (values) => {
      const startDate = new Date(
        format(
          new Date(values.startDate.replace('Z', '')),
          'MM/dd/yyyy hh:mm a'
        )
      );
      const endDate = new Date(
        format(new Date(values.endDate.replace('Z', '')), 'MM/dd/yyyy hh:mm a')
      );
      const duration = endDate.getTime() - startDate.getTime();
      const durationInMinutes = Math.floor(duration / 1000 / 60);
      const authorizations = map(
        get(values, 'authorizations'),
        (authorizations) => {
          const startDate = new Date(authorizations.startDate);
          const endDate = new Date(authorizations.endDate);
          const duration = endDate.getTime() - startDate.getTime();
          const durationInMinutes = Math.floor(duration / 1000 / 60);

          return {
            ...authorizations,
            serviceCodeUuid: get(authorizations, 'serviceCode.uuid'),
            duration: durationInMinutes,
            endTime: normalizeTime(values.endDate)
          };
        }
      );
      return {
        ...values,
        isRecurrent: false,
        recurrentPattern: null,
        recurrentStartDate: null,
        recurrentEndDate: null,
        startDate: startDate,
        startTime: startDate,
        endDate: endDate,
        endTime: format(addMinutes(startDate, duration), 'hh:mm a'),
        duration: durationInMinutes > 60 ? '60' : durationInMinutes,
        authorizations
      };
    },
    normalizeValues: (values) => {
      return {
        ...values,
        isRecurrent: false,
        startDate: format(values.startDate, 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(values.endDate, 'yyyy-MM-dd HH:mm:ss'),
        city: selectedCity,
        state: selectedState
      };
    },
    onSuccess: async (values) => {
      const authorizations = get(values, 'authorizations', []);
      const dateStart = get(values, 'startDate').replace(/-/g, '/');

      Promise.all(
        map(authorizations, (code) => {
          const authUuid = get(code, 'uuid');
          const payload = {
            uuid: authUuid,
            appointmentUuid: values.uuid,
            serviceCodeUuid: code.serviceCodeUuid || values.serviceCodeUuid,
            startDate: format(new Date(dateStart), 'yyyy-MM-dd HH:mm:ss'),
            endDate: format(
              addMinutes(new Date(dateStart), code.duration),
              'yyyy-MM-dd HH:mm:ss'
            )
          };

          if (!authUuid) {
            return authorizationApi.create({
              ...payload,
              uuid: uuidv4()
            });
          } else {
            return authorizationApi.update(payload);
          }
        })
      ).then(() => {
        showMessage(
          `Appointment ${isEdit ? 'updated' : 'created'} successfully`
        );
        navigate(url(ROUTE_APPOINTMENTS_LIST, { id: values.clientUuid }));
      });
    },
    onError: () => {
      showMessage('Something went wrong, please try again later', 'error');
    }
  });

  const handleDelete = (appointment) => {
    openDeleteDialog({
      appointment,
      onSuccess: () => {
        closeDeleteDialog();
        if (backUrl) navigate(backUrl);
      }
    });
  };

  const handleConvert = (appointment) => {
    openConvertToTimesheet({
      appointment,
      onSuccess: () => {
        closeConvertToTimesheet();
        if (backUrl) navigate(backUrl);
      }
    });
  };

  const handleCancel = (appointment) => {
    openCancelDialog({
      appointment,
      onSuccess: () => {
        closeCancelDialog();
        if (backUrl) navigate(backUrl);
      }
    });
  };

  return {
    isLoading,
    isEdit,
    handleSubmit,
    values,
    setFieldValue,
    getFieldTouchedError,
    setFieldTouched,
    touched,
    handleBlur,
    setValues,
    data,
    errors,
    handleDelete,
    handleConvert,
    handleCancel
  };
};
