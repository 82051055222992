import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { RoleDto } from '../../../core/http';
import { useRole } from '../../../core/hooks/useRole';

export const RoleField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useRole();
  const selectItems = map(items, (item: RoleDto) => ({
    value: item.uuid,
    label: item.title
  }));

  return <SelectField value={value || ''} items={selectItems} {...props} />;
};
