import map from 'lodash/map';
import { SkillDto } from '../../../core/http';
import { useSkill } from '../../../core/hooks/useSkill';
import { MultipleSelectField } from '../multiple-select-field';

export const SkillMultipleSelectField = ({
  value,
  loading = false,
  disabled = false,
  ...props
}) => {
  const { items } = useSkill();

  const selectItems = map(items, (item: SkillDto) => ({
    value: item.uuid,
    label: item.title
  }));
  return (
    <MultipleSelectField
      loading={loading}
      disabled={disabled}
      label="Skill"
      value={value || ''}
      dataSource={selectItems}
      {...props}
    />
  );
};
