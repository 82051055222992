import { useState, useEffect, FC } from 'react';
import { useDialog } from '../../../core/hooks/useDialog';
import { OptionsAutocompleteProps, Options } from '../options-autocomplete';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField } from '../text-field';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../core/store/store';
import { find, get, isEmpty } from 'lodash';
import {
  fetchState,
  forceFetchState
} from '../../../core/store/slices/stateSlice';
import { CreateStateDialog } from './dialogs/CreateStateDialog';
import { IconSubtract } from '../../icons';
import useStyles from './styles';
import { Label } from '../label';

type Props = {
  label?: string;
  disabled?: boolean;
  setFieldValue?: (name: string, value: string) => void;
  errors?: any;
  required?: boolean;
} & Omit<OptionsAutocompleteProps, 'options'>;

export const StateAutocompleteField: FC<Props> = (props) => {
  const [options, setOptions] = useState<Options[]>([]);
  const [open, close] = useDialog(CreateStateDialog);
  const [value, setValue] = useState<any>(null);
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.state);
  const loading = get(findAll, 'loading', false);
  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(props.value) && !isEmpty(options)) {
      if (props.value) {
        const selectedValue: Options = find(
          options,
          (option) => option.value === props.value
        );
        setValue(selectedValue);
      }
    }
  }, [props.value, options]);

  useEffect(() => {
    setOptions(
      get(findAll, 'payload.items', []).map((item) => ({
        label: item.title,
        value: item.uuid
      }))
    );
    if (value) {
      props.onChange(value.value);
    }
  }, [findAll]);

  useEffect(() => {
    dispatch(fetchState({ limit: 1000 }));
  }, []);

  const handleOpenDialog = (value) => {
    open({
      stateTitle: value,
      onSuccess: async (payload) => {
        const newVal = {
          label: payload.title,
          value: payload.uuid
        };
        setTimeout(() => {
          dispatch(forceFetchState({ limit: 1000 }));
          setValue(newVal);
          close();
        }, 1000);
      }
    });
  };

  const filter = createFilterOptions();

  return (
    <Autocomplete
      className={!!props.errors && classes.error}
      fullWidth
      clearIcon={<IconSubtract />}
      disabled={loading}
      value={value}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          handleOpenDialog(newValue.inputValue);
        } else if (newValue && newValue.value) {
          props.setFieldValue('stateUuid', newValue.value);
          setValue(newValue);
        } else {
          props.setFieldValue('stateUuid', '');
          setValue('');
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      options={options}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      freeSolo
      renderInput={(params) => (
        <>
          <Label required={props.required} errors={!!props.errors}>
            State
          </Label>
          <TextField
            required={props.required}
            sx={{
              '& .MuiAutocomplete-endAdornment': {
                top: 'unset'
              }
            }}
            helperText={!!props.errors && 'Please enter state.'}
            {...params}
          />
        </>
      )}
    />
  );
};
