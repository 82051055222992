import { ClientServiceAuthorizationForm } from '../../../features/clients/ClientServiceAuthorizationForm';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const ClientServiceAuthorizationEdit = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Client Service Authorizations'
  })
)(ClientServiceAuthorizationForm);
