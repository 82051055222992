import { ComponentType } from 'react';
import { Box, Button, Stack, IconButton } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { clientFamilyCommitmentColumns } from '../_mockdata';
import { SearchField } from '../../../components/fields';
import { IconPen, IconTrash } from '../../../components/icons';
import { AddNewCommitmentDialog } from '../dialogs/AddNewCommitmentDialog';
import { useDialog } from '../../../core/hooks/useDialog';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientFamilyCommitmentApi } from '../../../core/http/requests/client-family-commitment.api';
import { useParams } from 'react-router';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { get } from 'lodash';
import { PermissionView } from '../../../components/permission-view';

const clientFamilyCommitmentApi = new ClientFamilyCommitmentApi();

export const FamilyCommitment: ComponentType = () => {
  const [oepnCommitmentDialog, closeCommitmentDialog] = useDialog(
    AddNewCommitmentDialog
  );
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { showMessage } = useSnackbar();
  const { id } = useParams();

  const {
    data,
    isLoading,
    fetchData,
    pagination,
    handleChangePage,
    handleChangeSort
  } = useListResource(clientFamilyCommitmentApi, {
    defaultParams: {
      clientUuid: id
    }
  });

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        clientFamilyCommitmentApi
          .delete({ clientUuid: id }, uuid)
          .then(() => {
            closeConfirm();
            fetchData();
          })
          .catch(() => {
            closeConfirm();
            showMessage(
              'Something went wrong, please try again later.',
              'error'
            );
          });
      }
    });
  };

  const handleEdit = (uuid: string) => () => {
    oepnCommitmentDialog({
      uuid,
      onSuccess: () => {
        showMessage('Family Commitment was updated successfully.');
      },
      onError: () => {
        showMessage('Family Commitment was created successfully.');
      }
    });
  };
  const handleCreate = () => () => {
    oepnCommitmentDialog({
      clientUuid: id,
      onSuccess: () => {
        fetchData();
        closeCommitmentDialog();
        showMessage('Family Commitment was created successfully.', 'error');
      },
      onError: () => {
        closeCommitmentDialog();
        showMessage('Something went wrong, please try again later.', 'error');
      }
    });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
        <PermissionView permission="modifyClients">
          <Button onClick={handleCreate()}>Add new</Button>
        </PermissionView>
        <SearchField sx={{ width: 400 }} size="small" placeholder="Search" />
      </Stack>
      <Box>
        <TableView
          showEmptyState
          loading={isLoading}
          showTablePerPage={false}
          showPagination={true}
          pagination={pagination}
          onChangePage={handleChangePage}
          onChangeSort={handleChangeSort}
          dataSource={get(data, 'items', [])}
          columns={[
            ...clientFamilyCommitmentColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              render: (_, { uuid }) => (
                <Stack
                  direction="row"
                  alignItems="left"
                  justifyContent="flex-end"
                >
                  <IconButton size="small" onClick={handleEdit(uuid)}>
                    <IconPen />
                  </IconButton>
                  <IconButton size="small" onClick={handleDelete(uuid)}>
                    <IconTrash />
                  </IconButton>
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </>
  );
};
