import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  TextField,
  DialogActions,
  Box,
  Grid
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { SelectField, RadioList } from '../../../components/fields';
import { Badge } from '../../../components/badge';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const AddLogDialog: ComponentType<Props> = ({ popDialog }) => {
  const logTypes = [
    {
      id: 0,
      label: 'Warning Notes',
      value: 'warningNotes'
    },
    {
      id: 1,
      label: 'Requirements',
      value: 'requirements'
    },
    { id: 2, label: 'Call Log', value: 'callLog' },
    {
      id: 3,
      label: 'Status Change',
      value: 'statusChange'
    }
  ];

  const statuses = [
    {
      label: 'Active',
      value: 'active'
    },
    {
      label: 'Warning Notes',
      value: 'warningNotes'
    },
    {
      label: 'Requirements',
      value: 'requirements'
    },
    {
      label: 'Call log',
      value: 'callLog'
    }
  ];

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      logType: 'warningNotes',
      modifiedStatus: 'active',
      note: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      logType: yup.string().required('Please select one of the options.'),
      modifiedStatus: yup
        .string()
        .required('Please select one of the options.'),
      note: yup.string()
    }),
    onSubmit: () => {
      return;
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 650
        }
      }}
    >
      <DialogTitle>Add new log record</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <RadioList
            label="Log Type"
            value={values.logType}
            onChange={(value) => {
              if (value !== 'statusChange') {
                setFieldTouched('modifiedStatus', false);
              }
              setFieldValue('logType', value);
            }}
            items={logTypes}
          />
        </Box>

        {values.logType === 'statusChange' && (
          <Grid container direction="row" spacing={2} mb={2}>
            <Grid item md={3} sx={{ alignItems: 'flex-end' }}>
              <Typography variant="subtitle1" mb={1.5} color="common.info">
                Current Status:
              </Typography>
              <Badge
                label={'Active'}
                variant={'success'}
                sx={{
                  fontWeight: '500 !important',
                  color: 'common.dark',
                  backgroundColor: '#E1FFE0'
                }}
              />
            </Grid>
            <Grid item md={9}>
              <SelectField
                required
                label="Change to"
                placeholder="Select"
                items={statuses}
                value={values.modifiedStatus}
                onChange={handleChange('modifiedStatus')}
                onBlur={handleBlur('modifiedStatus')}
                helperText={
                  (touched.modifiedStatus && errors.modifiedStatus) || ' '
                }
              />
            </Grid>
          </Grid>
        )}

        <Typography variant="subtitle1" mb={1} color="common.grey">
          Note
        </Typography>
        <TextField multiline={true} rows={5.6} placeholder="Text here" />
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
