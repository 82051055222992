import { useState, useEffect, FC } from 'react';
import {
  OptionsAutocomplete,
  OptionsAutocompleteProps,
  Options
} from '../options-autocomplete';
import { useActiveEmployee } from '../../../core/hooks/useActiveEmployee';

type Props = {
  label?: string;
  disabled?: boolean;
  size?: string;
} & Omit<OptionsAutocompleteProps, 'options'>;

export const ActiveEmployeeAutocompleteField: FC<Props> = (props) => {
  const [options, setOptions] = useState<Options[]>([]);

  const { items, itemsLoading } = useActiveEmployee();

  useEffect(() => {
    setOptions(
      items.map((item) => ({ label: item.displayName, value: item.uuid }))
    );
  }, [items]);

  return (
    <OptionsAutocomplete
      {...{
        loading: itemsLoading,
        options: options.sort((a, b) => (a.label > b.label ? 1 : -1)),
        disabled: props.disabled || itemsLoading
      }}
      {...props}
    />
  );
};
