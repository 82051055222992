import { PageHeader } from '../../../components/page-header';
import {
  ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE,
  ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE_STATUS,
  ROUTE_MASTER_DATA_APPOINTMENT_TYPE,
  ROUTE_MASTER_DATA_THERAPY_TITLE,
  ROUTE_MASTER_DATA_HOLIDAY,
  ROUTE_MASTER_DATA_LOCATION,
  ROUTE_MASTER_DATA_PLACE_OF_SERVICE,
  ROUTE_MASTER_DATA_PROFIT_CENTER,
  ROUTE_MASTER_DATA_REFERRAL_TYPE,
  ROUTE_MASTER_DATA_REGION,
  ROUTE_MASTER_DATA_SERVICE_DESCRIPTION,
  ROUTE_MASTER_DATA_SERVICE_TYPE,
  ROUTE_MASTER_DATA_TASK_TYPE,
  ROUTE_MASTER_DATA_NOTIFICATION_MESSAGES,
  ROUTE_MASTER_DATA_EMPLOYEE_GROUPS,
  ROUTE_MASTER_DATA_SERVICE_CODE,
  ROUTE_MASTER_DATA_FUNDING_SOURCE,
  ROUTE_MASTER_DATA_INTERNAL_WORK_TYPES
} from '../../../constants/route.paths';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import useStyles from './styles';

interface MenuItem {
  title: string;
  icon?: React.ReactNode;
  path: string;
}

const MENU: MenuItem[] = [
  {
    title: 'Region',
    path: ROUTE_MASTER_DATA_REGION
  },
  {
    title: 'Location',
    path: ROUTE_MASTER_DATA_LOCATION
  },
  {
    title: 'Place of Service',
    path: ROUTE_MASTER_DATA_PLACE_OF_SERVICE
  },
  {
    title: 'Holiday Details',
    path: ROUTE_MASTER_DATA_HOLIDAY
  },
  {
    title: 'Appointment Type',
    path: ROUTE_MASTER_DATA_APPOINTMENT_TYPE
  },
  {
    title: 'App. Cancel Type',
    path: ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE
  },
  {
    title: 'App. Cancel Type (Status)',
    path: ROUTE_MASTER_DATA_APPOINTMENT_CANCEL_TYPE_STATUS
  },
  {
    title: 'Client-info Therapy Title',
    path: ROUTE_MASTER_DATA_THERAPY_TITLE
  },
  {
    title: 'Referral Type',
    path: ROUTE_MASTER_DATA_REFERRAL_TYPE
  },
  {
    title: 'Service Description',
    path: ROUTE_MASTER_DATA_SERVICE_DESCRIPTION
  },
  {
    title: 'Service Type',
    path: ROUTE_MASTER_DATA_SERVICE_TYPE
  },
  {
    title: 'Profit Center',
    path: ROUTE_MASTER_DATA_PROFIT_CENTER
  },
  {
    title: 'Task Type',
    path: ROUTE_MASTER_DATA_TASK_TYPE
  },
  {
    title: 'Notification Messages',
    path: ROUTE_MASTER_DATA_NOTIFICATION_MESSAGES
  },
  {
    title: 'Employee Groups',
    path: ROUTE_MASTER_DATA_EMPLOYEE_GROUPS
  },
  {
    title: 'Service codes',
    path: ROUTE_MASTER_DATA_SERVICE_CODE
  },
  {
    title: 'Funding Sources',
    path: ROUTE_MASTER_DATA_FUNDING_SOURCE
  },
  {
    title: 'Internal Work types',
    path: ROUTE_MASTER_DATA_INTERNAL_WORK_TYPES
  }
];

const MenuItem: FC<MenuItem> = ({ title, path, icon }) => {
  return (
    <Link to={path}>
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        width="100%"
        height="100%"
      >
        {icon && icon}
        <Typography
          fontSize={16}
          fontFamily="Poppins"
          color="#172B4D"
          fontWeight={600}
        >
          {title}
        </Typography>
      </Stack>
    </Link>
  );
};

export const MasterDataMenu = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader title="Master data" divider={false} />
      <Stack direction="row" className={classes.root}>
        {map(MENU, (item) => (
          <MenuItem key={item.title} {...item} />
        ))}
      </Stack>
    </>
  );
};
