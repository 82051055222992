import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormikValues } from 'formik/dist/types';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import * as yup from 'yup';
import { ClientServiceAuthorizationApi } from '../http/requests/client-service-authorization.api';

const api = new ClientServiceAuthorizationApi();

interface Options {
  createUuid?: string;
  uuid?: string;
  employeeUuid?: string;
  validationSchema?: any | (() => any);
  initialValues?: FormikValues;
  onSuccess?: (values: FormikValues) => void;
  onError?: (error: any) => void;
  normalizeValues?: (values: FormikValues) => any;
  normalizeSetValues?: (values: FormikValues) => any;
  onSubmit?: (values: FormikValues) => any;
}

export const useEditClientServiceAuthorization = (
  clientUuid,
  {
    createUuid,
    uuid,
    onSuccess,
    onError,
    normalizeValues = (values) => values,
    normalizeSetValues = (values) => values,
    onSubmit
  }: Options
) => {
  const isEdit = !!uuid;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<Record<string, any>>({});
  const {
    handleSubmit,
    handleChange,
    values,
    errors: validationErrors,
    setValues,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched
  } = useFormik({
    initialValues: {
      serviceCodeUuid: '',
      clientUuid: clientUuid,
      employeeUuid: '',
      fundingSourceUuid: '',
      authorizations: []
    },
    validationSchema: yup.object().shape({
      fundingSourceUuid: yup.string().required('This field is required.'),
      serviceCodeUuid: yup.string().required('This field is required.'),
      authorizations: yup.array().of(
        yup.object().shape({
          status: yup.string().required('This field is required.'),
          serviceCodeUuid: yup.string().required('This field is required.'),
          serviceDescriptionUuid: yup
            .string()
            .required('This field is required.'),
          clientIdMri: yup.string(),
          authorizationNumber: yup
            .number()
            .nullable()
            .typeError('Please enter valid value')
            .required('This field is required.'),
          totalAuthUnits: yup
            .number()
            .nullable()
            .typeError('Please enter valid value')
            .required('This field is required.'),
          budgetCode: yup.string().required('This field is required.'),
          accountCode: yup.string().required('This field is required.'),
          maxHourInPeriod: yup
            .number()
            .nullable()
            .typeError('Please enter valid value')
            .required('This field is required.'),
          authorizedFrom: yup.string().required('This field is required.'),
          authorizedTo: yup.string().required('This field is required.'),
          hoursPerPeriodType: yup.string().required('This field is required.'),
          unitLenght: yup
            .number()
            .nullable()
            .typeError('Please enter valid value')
            .required('This field is required.'),
          reportSubmittedDate: yup.string().required('This field is required.'),
          reportDueDate: yup.string().required('This field is required.')
        })
      )
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: onSubmit
      ? onSubmit
      : async (values) => {
          setIsLoading(true);
          setErrors({});
          const normalizedValues = normalizeValues(values);
          let actionPromise: Promise<any>;
          const payload = {
            uuid: isEdit ? uuid : createUuid ? createUuid : uuidv4(),
            ...normalizedValues
          };
          if (isEdit) {
            actionPromise = api.update(payload);
          } else {
            actionPromise = api.create(payload);
          }

          actionPromise
            .then(() => {
              setIsLoading(false);
              onSuccess && onSuccess(payload);
            })
            .catch((err) => {
              const errorDescription = get(
                err,
                'response.data.errorDescription.fields'
              );

              if (errorDescription) {
                setErrors(errorDescription);
              }

              setIsLoading(false);
              onError && onError(err);
            });
        }
  });
  useEffect(() => {
    setErrors(validationErrors as Record<string, string>);
  }, [validationErrors]);

  useEffect(() => {
    if (clientUuid && uuid) {
      setIsLoading(true);
      api
        .findOne(clientUuid, uuid)
        .then((data) => {
          setData(data);
          setValues(normalizeSetValues(data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [uuid, clientUuid]);

  const getFieldTouchedError = (fieldName: any) => {
    return get(touched, fieldName, false) && get(validationErrors, fieldName);
  };

  const getFieldValue = (fieldName: string) => {
    return get(values, fieldName);
  };

  return {
    isEdit,
    isLoading,
    data,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    setValues,
    errors,
    validationErrors,
    touched,
    setFieldValue,
    getFieldTouchedError,
    setErrors,
    getFieldValue,
    setFieldTouched
  };
};
