import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { SelectField, TextField } from '../../../components/fields';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { RegionApi } from '../../../core/http';
import { useLocation } from '../../../core/hooks/useLocation';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const regionApi = new RegionApi();

export const RegionEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(regionApi, {
    uuid,
    initialValues: {
      name: '',
      locationUuid: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Region name is a required field'),
      locationUuid: yup.string().required('Location is a required field')
    }),
    onSuccess,
    onError
  });

  const { items } = useLocation();

  const locations = items.map((item) => ({
    label: `${item.state}-${item.city}, ${item.region}, ${item.zipCode}`,
    value: item.uuid
  }));

  return (
    <Dialog open onClose={popDialog}>
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField
          label="Region Name"
          value={values.name}
          onChange={handleChange('name')}
          errors={getFieldTouchedError('name')}
        />
        <SelectField
          label="Location"
          required
          items={locations || []}
          placeholder="Select"
          value={values.locationUuid}
          onChange={handleChange('locationUuid')}
          onBlur={handleBlur('locationUuid')}
          errors={getFieldTouchedError('locationUuid')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
