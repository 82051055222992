import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { RolesContainer } from '../../features/roles/RolesContainer';

export const RolesPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Roles'
  })
)(RolesContainer);
