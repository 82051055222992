import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthExpiring } from '../../../core/store/slices/clientDashboardSlice';
import {
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { TableView } from '../../../components/table-view';
import { Card } from '../../../components/cards';
import { AppState } from '../../../core/store/store';
import {
  careTeamMembersColumns,
  expiringAuthsColumn
} from '../../clients/_mockdata';
import { ROUTE_CLIENT_APPOINTMENT_EDIT } from '../../../constants/route.paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { url } from '../../../core';
import { capitalizeFirstLetter, normalizeDate } from '../../../core';
import { AppointmentCalendarDrawerCard } from '../../../components/appointment-calendar-drawer-card';
import { AppointmentCalendarCard } from '../../../components/appointment-calendar-card';
import { CalendarView } from '../../../components/calendar-view/CalendarView';
import { RadioGroup } from '../../../components/fields/radio-group';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { calendarViewTypeItems } from '../../../constants/app.constants';
import { IconAppointmentCancel, IconPen } from '../../../components/icons';
import { ProductivityDashboardCard } from '../../clients/widgets/ProductivityDashboard';
import { LabeledRecord } from '../../../components/labeled-record';
import { usPhoneNumber } from '../../../core';
import { useClientDetail } from '../../../core/hooks/useClientDetail';
import { AppointmentDto, ClientContactDto } from '../../../core/http';
import { get } from 'lodash';
import { useAppointmentList } from '../../../core/hooks/useAppointmentList';
import { clientAppointmentColumns } from '../_mockdata';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientCareTeamMembersApi } from '../../../core/http/requests/client-care-team-members.api';
import truncate from 'lodash/truncate';

const clientCareTeamMembersApi = new ClientCareTeamMembersApi();

export const ClientDetailedDashboard = ({ uuid }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [currentView, setCurrentView] = useState<'calendar' | 'table'>(
    'calendar'
  );

  const authExpiringData = useSelector(
    (state: AppState) => state.clientDashboard.getAuthExpiring.data
  );
  const authExpiringLoading = useSelector(
    (state: AppState) => state.clientDashboard.getAuthExpiring.loading
  );

  useEffect(() => {
    dispatch(getAuthExpiring());
  }, []);

  const {
    data: appointmentResponse,
    isLoading,
    pagination,
    startDate,
    setStartDate
  } = useAppointmentList({
    clientId: uuid,
    defaultParams: {
      'filter[client.uuid][eq]': uuid,
      limit: 5
    }
  });
  const appointmentsData = get(appointmentResponse, 'items', []);
  const { data: clientData } = useClientDetail({
    uuid: uuid
  });

  const primaryContactData = clientData?.contacts.find(
    (item: ClientContactDto) => item.type === 'primary'
  );

  const { data: careTeamMembersResponse, isLoading: careTeamMembersLoading } =
    useListResource(clientCareTeamMembersApi, {
      defaultParams: {
        clientUuid: uuid
      }
    });

  const careTeamMembersData = get(careTeamMembersResponse, 'items', []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" mb={1.5}>
        <Grid item md={12}>
          <Stack mt={1.5} spacing={1} direction="column">
            <Card title="General Information">
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="First Name:"
                    value={capitalizeFirstLetter(clientData?.firstName)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Last Name:"
                    value={capitalizeFirstLetter(clientData?.lastName)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="DOB:"
                    value={normalizeDate(clientData?.birthDate) || '-'}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Age:"
                    value={Math.floor(clientData?.ageMonths / 12) || '-'}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Gender:"
                    value={capitalizeFirstLetter(clientData?.gender) || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Primary Language:"
                    value={clientData?.primaryLanguage}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Secondary Language:"
                    value={clientData?.secondaryLanguage}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Profit Center:"
                    value={clientData?.profitCenter}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Funding Source:"
                    value={clientData?.fundingSource}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Referral Acceptance Date:"
                    value={normalizeDate(clientData?.referralAcceptanceDate)}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Member ID:"
                    value={clientData?.memberId}
                  />
                </Grid>
                <Grid item md={2.4}>
                  <LabeledRecord
                    label="Notes:"
                    value={truncate(clientData?.notes, { length: 10 }) || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h4">Primary Contact Details</Typography>
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Relation to Child:"
                    value={
                      capitalizeFirstLetter(
                        primaryContactData?.relationToChild
                      ) || '-'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="First Name:"
                    value={
                      capitalizeFirstLetter(primaryContactData?.firstName) ||
                      '-'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Last Name:"
                    value={
                      capitalizeFirstLetter(primaryContactData?.lastName) || '-'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Email:"
                    value={primaryContactData?.email || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Address:"
                    value={
                      capitalizeFirstLetter(primaryContactData?.addressLine1) ||
                      'NA'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="City:"
                    value={
                      capitalizeFirstLetter(primaryContactData?.city?.title) ||
                      '-'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Province/State:"
                    value={
                      capitalizeFirstLetter(primaryContactData?.state?.title) ||
                      '-'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Zip Code:"
                    value={primaryContactData?.zipCode || '-'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Primary Phone Number:"
                    value={
                      primaryContactData &&
                      usPhoneNumber(primaryContactData.primaryPhoneNumber)
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Secondary Phone Number:"
                    value={
                      primaryContactData &&
                      usPhoneNumber(primaryContactData.secondaryPhoneNumber)
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Work Phone Number:"
                    value={
                      primaryContactData &&
                      usPhoneNumber(primaryContactData.workPhoneNumber)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={3}>
                <LabeledRecord
                  label="Enable Text Notifications:"
                  value={
                    primaryContactData?.isTextNotificationsEnabled
                      ? 'Yes'
                      : 'No'
                  }
                />
              </Grid>
              <Grid container>
                <Grid item md={12} mb={3}>
                  <Typography variant="h4">Availability</Typography>
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="Theletherapy:"
                    value={
                      primaryContactData?.isTextNotificationsEnabled
                        ? 'Yes'
                        : 'No'
                    }
                  />
                </Grid>
                <Grid item md={3}>
                  <LabeledRecord
                    label="In-home:"
                    value={
                      primaryContactData?.isTextNotificationsEnabled
                        ? 'Yes'
                        : 'No'
                    }
                  />
                </Grid>
              </Grid>
            </Card>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <ProductivityDashboardCard clientUuid={uuid} />
        </Grid>
        <Grid item md={6}>
          <Card
            title="Auths Expiring Next Month"
            sx={{
              minHeight: 352
            }}
          >
            <TableView
              loading={authExpiringLoading}
              showPagination={true}
              showTablePerPage={false}
              dataSource={authExpiringData || []}
              columns={expiringAuthsColumn}
              pagination={{
                page: 0,
                perPage: 10,
                totalPages: 3,
                total: 12
              }}
            />
          </Card>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Card
              mb={1.5}
              px={3}
              py={2}
              sx={{
                height: 540
              }}
            >
              <Stack direction="row" mb={3} justifyContent="space-between">
                <Stack direction="row" spacing={3}>
                  <Typography variant="h4">Appointments</Typography>
                  <RadioGroup
                    items={calendarViewTypeItems}
                    defaultValue="calendar"
                    onChange={(view) => {
                      setCurrentView(view as 'calendar' | 'table');
                    }}
                  />
                </Stack>
                <WeekRangePicker
                  withToday
                  onChange={(startDate) => {
                    setStartDate(startDate);
                  }}
                />
              </Stack>

              {currentView === 'calendar' ? (
                <CalendarView
                  contentHeight={400}
                  dataSource={appointmentsData}
                  groupKey="start"
                  startDate={startDate}
                  renderItem={(item: AppointmentDto, _, selectedItem) => (
                    <AppointmentCalendarCard
                      item={item}
                      selected={selectedItem?.uuid === item.uuid}
                    />
                  )}
                  renderDrawer={(item: AppointmentDto) => (
                    <AppointmentCalendarDrawerCard item={item} />
                  )}
                  renderDrawerFooter={(item) => (
                    <Stack direction="row" marginTop="auto" spacing={1}>
                      <Button
                        onClick={() =>
                          navigate(
                            url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                              clientId: uuid,
                              uuid: item.uuid
                            })
                          )
                        }
                      >
                        Open
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() =>
                          navigate(
                            url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                              clientId: uuid,
                              uuid: item.uuid
                            })
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button color="secondary">Cancel app</Button>
                    </Stack>
                  )}
                />
              ) : (
                <TableView
                  showEmptyState
                  EmptyStateProps={{
                    description: null
                  }}
                  loading={isLoading}
                  showTablePerPage={false}
                  showPagination={true}
                  pagination={pagination}
                  dataSource={appointmentsData}
                  keyExtractor={(item) => item.uuid}
                  onRowClick={() => navigate(pathname + '#appointments')}
                  columns={[
                    ...clientAppointmentColumns,
                    {
                      title: '',
                      dataIndex: 'actions',
                      key: 'actions',
                      render: (_, { uuid }) => (
                        <Stack direction="row" alignItems="center">
                          <Tooltip title="Edit" placement="bottom-end">
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigate(
                                  url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
                                    clientId: uuid,
                                    uuid
                                  })
                                );
                              }}
                            >
                              <IconPen />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel" placement="bottom-end">
                            <IconButton size="small">
                              <IconAppointmentCancel />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      )
                    }
                  ]}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Card title="Care Team member">
        <TableView
          loading={careTeamMembersLoading}
          keyExtractor={(item) => item.id}
          dataSource={careTeamMembersData}
          showEmptyState
          EmptyStateProps={{
            description: null
          }}
          columns={careTeamMembersColumns}
          hideColumns={[
            'employee',
            'fundingSource',
            'phone',
            'serviceDescription'
          ]}
        />
      </Card>
    </>
  );
};
