import { FC } from 'react';
import {
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Typography
} from '@mui/material';
import { BpRadio } from '../BpRadio';
import map from 'lodash/map';

interface Props {
  label?: string;
  value?: string;
  items: {
    label: string;
    value: string;
  }[];
  onChange?: (value: string) => void;
  errors?: any;
}

export const RadioList: FC<Props> = ({ value, label, items, onChange }) => {
  const handleChange = (_, value) => {
    onChange && onChange(value);
  };

  return (
    <>
      <Typography variant="subtitle1" mb={1} color="common.info">
        {label}
      </Typography>
      <RadioGroup row value={value} onChange={handleChange}>
        {map(items, ({ label, value }) => {
          return (
            <FormControlLabel
              sx={{
                mr: 3,
                '& .MuiTypography-root': {
                  fontSize: 14,
                  fontWeight: 400
                }
              }}
              key={value}
              value={value}
              control={<BpRadio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText />
    </>
  );
};
