import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;
ChartJS.overrides.bar.responsive = false;

export const options = {
  responsive: true,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      position: 'right' as const,
      labels: {
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets[0].data.map((data, i) => ({
            text: `${chart.data.labels[i]} ${currencyFormatter(data)}`,
            fillStyle: datasets[0].backgroundColor[i]
          }));
        }
      }
    },
    title: {
      display: false
    }
  }
};
