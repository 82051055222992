import { Expose } from 'class-transformer';

export class EmployeeAvailabilityDto {
  @Expose()
  uuid: string;

  @Expose()
  startTime: string;

  @Expose()
  endTime: string;

  @Expose()
  dateFrom: string;

  @Expose()
  dateTo: string;

  @Expose()
  employeeUuid: string;

  @Expose()
  teletherapyMode: boolean;

  @Expose()
  inHomeMode: boolean;

  @Expose()
  isBusy: boolean;

  @Expose()
  weekday: string[];

  @Expose()
  note: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: boolean;
}
