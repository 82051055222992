import {
  FormControlLabel,
  Stack,
  StackProps,
  Typography,
  FormHelperText
} from '@mui/material';
import { Checkbox } from '../checkbox';
import { FC, useState, ReactNode } from 'react';
import { includes } from 'lodash';

interface Props extends Omit<StackProps, 'onChange'> {
  value: any[];
  helperText?: string | ReactNode;
  items: {
    label: string;
    value: string;
  }[];
  onChange: (value: any[]) => void;
  errors?: any;
}
export const CheckboxList: FC<Props> = ({
  value: baseValue,
  helperText,
  items,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = useState(baseValue);

  const handleCheck = (value: string) => () => {
    const newChecked = [...checked];
    const index = newChecked.indexOf(value);
    if (index === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(index, 1);
    }
    setChecked(newChecked);
    onChange && onChange(newChecked);
  };

  return (
    <>
      <Stack direction="row" spacing={3} ml={1} {...props}>
        {items.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                {...{ value, checked: includes(baseValue, value) }}
                onChange={handleCheck(value)}
              />
            }
            label={<Typography variant="body3">{label}</Typography>}
          />
        ))}
      </Stack>
      <FormHelperText> {helperText}</FormHelperText>
    </>
  );
};
