import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Stack, TextField, Button } from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  ROUTE_SIGN_IN,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { createNewPassword } from '../../core/store/slices/resetPasswordSlice';
import { AppState } from '../../core/store/store';
import omit from 'lodash/omit';
import { BackButton } from '../../components/back-button';

export const CreateNewPassword: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hash } = useParams();

  const createNewPasswordLoading = useSelector(
    (store: AppState) => store.resetPassword.loading
  );

  const { handleChange, handleBlur, handleSubmit, values, touched, errors } =
    useFormik({
      initialValues: {
        hash,
        password: '',
        confirmPassword: ''
      },
      validateOnBlur: true,
      validationSchema: yup.object().shape({
        password: yup.string().required('Password is required field'),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref('password'), null], 'Passwords must match')
      }),
      onSubmit: (values) => {
        dispatch(
          createNewPassword({
            values: omit(values, ['confirmPassword']),
            options: {
              onSuccess: () => navigate(url(ROUTE_SIGN_IN))
            }
          })
        );
      }
    });

  return (
    <>
      <Box className={classes.resetPasswordRoot}>
        <Box className={classes.block}>
          <BackButton title="Login" onClick={() => navigate(ROUTE_SIGN_IN)} />
          <Typography variant="h3" mb={2} mt={2}>
            Reset password
          </Typography>
          <Typography variant="body2">
            Enter your email below to receive reset link.
          </Typography>
          <Stack mt={2}>
            <TextField
              name="password"
              helperText={touched.password && errors.password}
              type="string"
              value={values.password}
              onBlur={handleBlur('password')}
              onChange={handleChange('password')}
            />

            <TextField
              name="confirmPassword"
              helperText={touched.confirmPassword && errors.confirmPassword}
              type="string"
              value={values.confirmPassword}
              onBlur={handleBlur('confirmPassword')}
              onChange={handleChange('confirmPassword')}
            />

            <Button
              size="medium"
              onClick={() => handleSubmit()}
              disabled={createNewPasswordLoading}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </>
  );
};
