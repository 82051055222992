import { ServiceDescriptionContainer } from '../../features/master-data/service-description/ServiceDescriptionContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataServiceDescription = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Service Descriptions'
  })
)(ServiceDescriptionContainer);
