import { makeStyles } from '@mui/styles';

const options = {
  name: 'autocomplete'
};

const useStyles = makeStyles(
  () => ({
    error: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFE6E6 !important'
      }
    }
  }),
  options
);

export default useStyles;
