import { SelectField } from '../select-field';
import { FC } from 'react';
import { SelectFieldProps } from '../select-field/SelectField';
import states from './states.json';
import map from 'lodash/map';

export const StatesField: FC<Omit<SelectFieldProps, 'items'>> = ({
  ...props
}) => {
  return (
    <SelectField
      label="State"
      items={map(states, ({ abbreviation }) => ({
        label: abbreviation,
        value: abbreviation
      }))}
      {...props}
      {...props}
    />
  );
};
