import { Box, Button, Divider, Grid } from '@mui/material';
import ReactResizeDetector from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { PageHeader } from '../../components/page-header';
import { useLayout } from '../../core/hooks/useLayout';
import { ROUTE_REPORTS } from '../../constants/route.paths';
import { useFormik } from 'formik';
import { Card } from '../../components/cards';
import {
  ClientField,
  FileAttach,
  TextField,
  TimePicker
} from '../../components/fields';
import { SignaturePad } from '../../components/signature-pad';
import { useDialog } from '../../core/hooks/useDialog';
import { CodeFormTemplateDialog } from './dialogs/CodeFormTemplateDialog';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const BACK_URL = ROUTE_REPORTS + '#time-cards';

export const TimeSheetEditor = () => {
  const navigate = useNavigate();
  const [openCodeFormTemplateDialog] = useDialog(CodeFormTemplateDialog);
  const refClientSign = useRef<SignatureCanvas>();
  const refProviderSign = useRef<SignatureCanvas>();

  useLayout({
    backButton: {
      path: BACK_URL,
      title: 'Time Card Reports'
    }
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors
  } = useFormik({
    initialValues: {
      payer: '',
      provider: 'Angelica Arredondo',
      dateOfService: '02/08/2022',
      serviceCode: '805 01',
      timeWorked: new Date(),
      unitsOfService: '',
      hours: '1.0',
      clientSignatureName: 'Darrell Steward',
      providerSignatureName: 'Angelica Arredondo'
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      payer: yup.string().required('Please select one of the options.'),
      provider: yup
        .string()
        .required('Please enter provider name.')
        .typeError('Name must contain only alphabets.'),
      dateOfService: yup
        .date()
        .required('Please enter date of service.')
        .typeError('Please enter valid date.'),
      serviceCode: yup.string().required('Please enter Service code.'),
      timeWorked: yup.string().required('Please enter worked time.'),
      unitsOfService: yup
        .number()
        .typeError('Units must contain only numbers.'),
      hours: yup
        .number()
        .integer()
        .typeError('Hours must contain only numbers.'),
      clientSignatureName: yup
        .string()
        .required('Client Name is required.')
        .test(
          'spaces',
          'Client Name must contain at least one character.',
          (value) => {
            return !!value?.trim();
          }
        )
        .matches(
          /^[aA-zZ\s]+$/,
          'Client Name must contain only letters and backspaces.'
        ),
      providerSignatureName: yup
        .string()
        .required('Provider name is Required.')
        .test(
          'spaces',
          'Provider Name must contain at least one character.',
          (value) => {
            return !!value?.trim();
          }
        )
        .matches(
          /^[aA-zZ\s]+$/,
          'Provider Name must contain only letters and backspaces.'
        )
    }),
    onSubmit: () => {
      return;
    }
  });

  return (
    <>
      <PageHeader title="Timesheet Editor" divider={false}>
        <Button onClick={() => handleSubmit()}>Update</Button>
        <Button color="third" onClick={() => navigate(BACK_URL)}>
          Cancel
        </Button>
      </PageHeader>
      <Card title="Service 1 – 97151: ABA Assessment">
        <Grid container>
          <Grid item md={4}>
            <ClientField
              required
              label="Payer"
              placeholder="Select"
              value={values.payer}
              onChange={handleChange('payer')}
              onBlur={handleBlur('payer')}
              errors={touched.payer && errors.payer}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <TextField
              required
              disabled
              label="Provider"
              onChange={handleChange('provider')}
              onBlur={handleBlur('provider')}
              errors={touched.provider && errors.provider}
              value={values.provider}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              required
              disabled
              label="Date of Service"
              onChange={handleChange('dateOfService')}
              onBlur={handleBlur('dateOfService')}
              errors={touched.dateOfService && errors.dateOfService}
              value={values.dateOfService}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              required
              disabled
              label="Service Code"
              onChange={handleChange('serviceCode')}
              onBlur={handleBlur('serviceCode')}
              errors={touched.serviceCode && errors.serviceCode}
              value={values.serviceCode}
            />
          </Grid>
          <Grid item md={2}>
            <Box mt={2.625}>
              <Button
                color="secondary"
                sx={{ height: 40 }}
                onClick={() => openCodeFormTemplateDialog()}
              >
                Fill up 805 Codes Form
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <TimePicker
              required
              label="Time Worked"
              value={values.timeWorked}
              onChange={(value) => {
                setFieldValue('timeWorked', value);
              }}
              onBlur={handleBlur('timeWorked')}
              errors={touched.timeWorked && errors.timeWorked}
            />
          </Grid>
          <Grid item md={2} display="flex" alignItems="flex-end">
            <TimePicker
              label=" "
              value={new Date()}
              onChange={handleChange('timeWorked2')}
              onBlur={handleBlur('timeWorked2')}
              // errors={touched.timeWorked && errors.timeWorked}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              required
              label="Units of Service"
              onChange={handleChange('unitsOfService')}
              onBlur={handleBlur('unitsOfService')}
              errors={touched.unitsOfService && errors.unitsOfService}
              value={values.unitsOfService}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              required
              disabled
              label="Hours"
              onChange={handleChange('hours')}
              onBlur={handleBlur('hours')}
              errors={touched.hours && errors.hours}
              value={values.hours}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container columnSpacing={1} mt={2} rowSpacing={0}>
          <Grid item md={4}>
            <TextField
              required
              label="Client Signature"
              onChange={handleChange('clientSignatureName')}
              onBlur={handleBlur('clientSignatureName')}
              errors={touched.clientSignatureName && errors.clientSignatureName}
              value={values.clientSignatureName}
            />
            <Box mb={2}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  refClientSign.current && refClientSign.current.clear();
                }}
              >
                Update Signature
              </Button>
            </Box>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => (
                <Card height={128} p={0}>
                  <SignaturePad
                    penColor="black"
                    {...{ width, height, ref: refClientSign }}
                  />
                </Card>
              )}
            </ReactResizeDetector>
          </Grid>
          <Grid item md={4} ml={4}>
            <TextField
              required
              label="Provider Signature"
              onChange={handleChange('providerSignatureName')}
              onBlur={handleBlur('providerSignatureName')}
              errors={
                touched.providerSignatureName && errors.providerSignatureName
              }
              value={values.providerSignatureName}
            />
            <Box mb={2}>
              <Button
                size="small"
                color="secondary"
                onClick={() => {
                  refProviderSign.current && refProviderSign.current.clear();
                }}
              >
                Update Signature
              </Button>
            </Box>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width, height }) => (
                <Card height={128} p={0}>
                  <SignaturePad
                    penColor="black"
                    {...{ width, height, ref: refProviderSign }}
                  />
                </Card>
              )}
            </ReactResizeDetector>
          </Grid>
        </Grid>
        <Divider />
        <FileAttach sx={{ mt: 2, background: '#F9FBFE !important' }} />
      </Card>
    </>
  );
};
