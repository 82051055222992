import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminUserProfileContainer } from '../../features/super-admin-users/SuperAdminUserProfile';

export const SuperAdminUserProfile = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Users'
  })
)(SuperAdminUserProfileContainer);
