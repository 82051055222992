import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';

export class ClientInsuredPersonDto {
  @Expose()
  uuid: string;

  @Expose()
  type: string;

  @Expose()
  clientUuid: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  gender: string;

  @Expose()
  birthDate: string;

  @Expose()
  addressLine1: string;

  @Expose()
  addressLine2: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'city.uuid'))
  cityUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'state.uuid'))
  stateUuid: string;

  @Expose()
  zipCode: string;

  @Expose()
  subscriberId: string;

  @Expose()
  groupName: string;

  @Expose()
  planName: string;

  @Expose()
  payer: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'payer.uuid'))
  payerUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'payer.name'))
  payerName: string;

  @Expose()
  isTextNotificationsEnabled: boolean;

  @Expose()
  isEmailNotificationsEnabled: boolean;

  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  fillAsPrimaryContact: boolean;
}
