import React, { FC } from 'react';
import { Button, Link, Stack } from '@mui/material';
import map from 'lodash/map';
import { NotificationCard } from './NotificationCard';
import { useDialog } from '../../core/hooks/useDialog';
import { NotificationDialog } from './NotificationDialog';
import { useListResource } from '../../core/hooks/useListResource';
import { NotificationMessageApi } from '../../core/http';
import get from 'lodash/get';

const notificationMessageApi = new NotificationMessageApi();

interface Props {
  close?: () => void;
}

export const NotificationList: FC<Props> = ({ close }) => {
  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    handleChangeParams,
    handleChangeSort
  } = useListResource(notificationMessageApi);

  const notifications = get(data, 'items', []);

  const [openShowAllDialog] = useDialog(NotificationDialog);

  const handleShowAllClick = () => {
    openShowAllDialog();
    close && close();
  };

  return (
    <>
      <Stack alignItems="flex-end">
        <Link variant="body3" underline="none">
          Clear all
        </Link>
      </Stack>
      {map(notifications, (item, key) => {
        //const isLast = key === notificationCount - 1;
        return <NotificationCard key={key} {...{ item }} />;
      })}
      <Button color="secondary" fullWidth onClick={handleShowAllClick}>
        Show all 24 notifications
      </Button>
    </>
  );
};
