import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import {
  Checkbox,
  FileAttach,
  DatePicker,
  SelectField
} from '../../components/fields';
import * as yup from 'yup';
import { FileApi } from '../../core/http';
import { useEditResource } from '../../core/hooks/useEditResource';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { v4 as uuidv4 } from 'uuid';

const fileApi = new FileApi();

export const UploadNewFileDialog = ({ popDialog, data, fetchData }) => {
  const { showMessage } = useSnackbar();
  const [createUuid] = useState(uuidv4());
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    getFieldTouchedError
  } = useEditResource(fileApi, {
    createUuid,
    uuid: data?.uuid,
    initialValues: {
      uuid: data?.uuid,
      type: data?.type || '',
      expirationDate: data?.expirationDate || '',
      noExpirationDate: data?.noExpirationDate || false,
      description: data?.description || '',
      rootType: data?.root?.type || '',
      rootId: data?.root?.uuid || ''
    },
    validationSchema: yup.object().shape({
      fileName: yup.string().required('File name is a required field'),
      type: yup.string().required('File type is a required field'),
      expirationDate: yup
        .string()
        .required('Expiration date is a required field')
    }),
    onSuccess: async () => {
      popDialog();
      fetchData();
      showMessage(
        data ? 'File was updated successfully' : 'File was created Successfully'
      );
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { maxWidth: 590 } }}
    >
      <DialogTitle>{data ? 'Update file' : 'Upload new file'}</DialogTitle>
      <DialogContent>
        <FileAttach
          canRemove={false}
          value={values.uuid}
          sx={{ mb: 2 }}
          errors={getFieldTouchedError('file')}
        />
        <Grid container columnSpacing={1} rowSpacing={1}>
          <Grid item md={4}>
            <SelectField
              value={values.type}
              label="Type *"
              items={[
                {
                  label: 'Certificate',
                  value: 'certificate'
                },
                {
                  label: 'Licence',
                  value: 'licence'
                }
              ]}
              errors={getFieldTouchedError('type')}
              onChange={handleChange('type')}
            />
          </Grid>
          <Grid item md={4}>
            <DatePicker
              value={values.expirationDate}
              label="Expiration Date *"
              onChange={handleChange('expirationDate')}
              errors={getFieldTouchedError('expirationDate')}
            />
          </Grid>
          <Grid item md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    setFieldValue('noExpirationDate', !values.noExpirationDate)
                  }
                />
              }
              label={<Typography variant="body3">No Exp. Date</Typography>}
              sx={{ mt: 3.2, ml: 0 }}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              required
              helperText={getFieldTouchedError('fileName')}
              error={getFieldTouchedError('fileName')}
              label="File Name"
              value={values.fileName}
              onChange={handleChange('fileName')}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Description"
              multiline={true}
              rows={3}
              value={values.description}
              onChange={handleChange('description')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
