import { Typography } from '@mui/material';
import { Badge } from '../../components/badge';
import { DataTableColumn } from '../../components/table-view/TableView';
import { UserRecord } from '../../components/user-record';
import { normalizeDate, timeConverter } from '../../core';
import { BillingVsPlannedDashboard } from './tabs/BillingVsPlanned';
import { ClientDetailedDashboard } from './tabs/ClientDetailedDashboard';
import { EmployeeDetailedDashboard } from './tabs/EmployeeDetailedDashboard';
import { ClientSearch } from './tabs/ClientSearch';
import { EmployeeSearch } from './tabs/EmployeeSearch';
import { LeadershipDashboard } from './tabs/LeadershipDashboard';

export const selectableTabs: string[] = [
  'client-dashboard',
  'employee-dashboard',
  'leadership-dashboard',
  'billing-vs-planned-dashboard',
  'caseload-management-dashboard'
];

export const possibleTabs = {
  'client-dashboard': {
    label: () => 'Client Dashboard',
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: () => <ClientSearch />,
    permission: 'viewClientDashboard'
  },
  'client-details': {
    label: (clientName) => `Client- ${clientName}`,
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: (clientUuid) => <ClientDetailedDashboard uuid={clientUuid} />,
    permission: 'viewClientDashboard'
  },
  'employee-dashboard': {
    label: () => 'Employee Dashboard',
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: () => <EmployeeSearch />,
    permission: 'viewEmployeeDashboard'
  },
  'employee-details': {
    label: (employeeName) => `Employee- ${employeeName}`,
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: (employeeUuid) => (
      <EmployeeDetailedDashboard uuid={employeeUuid} />
    ),
    permission: 'viewEmployeeDashboard'
  },
  'leadership-dashboard': {
    label: () => 'Leadership Dashboard',
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: () => <LeadershipDashboard />,
    permission: 'viewLeadershipDashboard'
  },
  'billing-vs-planned-dashboard': {
    label: () => 'Billing vs Planned',
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: () => <BillingVsPlannedDashboard />,
    permission: 'viewBilledVsPlannedDashboard'
  },
  'caseload-management-dashboard': {
    label: () => 'Caseload Management Dashboard',
    description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit. ',
    content: () => <></>,
    permission: 'viewCaseloadManagementDashboard'
  }
};

export const clientAppointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'time',
    sortable: false,
    render: (_, { startDate, endDate }) => timeConverter(startDate, endDate)
  },
  {
    title: 'Therapist',
    dataIndex: 'primaryTherapist',
    key: 'therapist',
    render: ({ displayName }) => (
      <UserRecord
        user={{
          avatar: 'none',
          name: displayName
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (value) => <Badge label={value} variant={value} />
  },
  {
    title: 'Type',
    dataIndex: 'type.title',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location.name',
    key: 'location'
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  }
];

export const revenueAndMarginColumns: DataTableColumn[] = [
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    cellProps: {
      width: 200
    }
  },
  ...Array(9).fill({
    title: 'Dec’21 (W1)',
    dataIndex: 'count',
    key: 'count',
    cellProps: {
      sx: {
        '& span': {
          width: '1px'
        }
      }
    }
  }),
  {
    title: 'Previous M2D Actual',
    dataIndex: 'previous',
    key: 'previous',
    cellProps: {
      width: 90,
      sx: { borderLeft: '1px solid #ECF0F7 !important' }
    },
    render: (value) => (
      <Typography variant="body3" fontWeight={'600 !important'}>
        {value}
      </Typography>
    )
  },
  {
    title: 'Current M2D Actual',
    dataIndex: 'current',
    key: 'current',
    cellProps: {
      width: 90
    },
    render: (value) => (
      <Typography variant="body3" fontWeight={'600 !important'}>
        {value}
      </Typography>
    )
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
    key: 'expected',
    cellProps: {
      width: 90
    },
    render: (value) => (
      <Typography variant="body3" fontWeight={'600 !important'}>
        {value}
      </Typography>
    )
  },
  {
    title: '',
    dataIndex: 'percents',
    key: '',
    cellProps: {
      width: 200,
      sx: { borderLeft: '1px solid #ECF0F7 !important' }
    },
    render: (value) => (
      <Typography variant="body1" fontWeight={'400 !important'}>
        {value}
      </Typography>
    )
  }
];

export const revenueAndMarginData = [
  {
    type: 'Forecast',
    count: '97,504',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  },
  {
    type: 'Scheduled',
    count: '97,504',
    previous: '97,504',
    current: '97,504',
    expected: '97,504',
    percents: '>95% of Forecast'
  },
  {
    type: 'Converted',
    count: '97,504',
    previous: '97,504',
    current: '97,504',
    expected: '97,504',
    percents: '>75% of Scheduled'
  },
  {
    type: 'Cancellation $',
    count: '97,504',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  },
  {
    type: 'X-rescheduled $',
    count: '-',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  },
  {
    type: 'Cancellation %',
    count: '22.0%',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  },
  {
    type: 'Atual vs Forecast',
    count: '69%',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  },
  {
    type: 'WoW Revenue growth',
    count: '7,0%',
    previous: '97,504',
    current: '97,504',
    expected: '97,504'
  }
];
