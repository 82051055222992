import { SelectField } from '../select-field';
import map from 'lodash/map';
import { normalizeDate } from '../../../core/utils/data.utils';
import { Badge } from '../../badge';
import { Stack, Typography } from '@mui/material';
import { useListResource } from '../../../core/hooks/useListResource';
import { ClientServiceAuthorizationApi } from '../../../core/http/requests/client-service-authorization.api';
import { get } from 'lodash';

const api = new ClientServiceAuthorizationApi();

export const ClientServiceAuthorizationField = ({
  value,
  clientUuid,
  placeholder,
  ...props
}) => {
  const { data, isLoading } = useListResource(api, {
    defaultParams: {
      clientUuid: clientUuid
    }
  });
  const authorizationsData = get(data, 'items', []);
  const authorizations = get(authorizationsData[0], 'authorizations', []);
  const selectItems = map(authorizations, (item: any) => ({
    value: item.uuid,
    label: (
      <Stack spacing={1} direction="row">
        <Typography variant="body3">
          {item?.serviceCode.code} - {item.serviceDescription.description} -{' '}
          {normalizeDate(item.authorizedFrom)} -
          {normalizeDate(item.authorizedTo)}
        </Typography>
        <Badge
          label="14 days remaining"
          variant="warning"
          size="small"
          textUppercase={false}
        />
      </Stack>
    )
  }));

  return (
    <SelectField
      value={value || ''}
      items={selectItems}
      placeholder={
        isLoading
          ? 'Loading...'
          : selectItems.length
          ? placeholder
          : 'No service authorization available'
      }
      {...props}
    />
  );
};
