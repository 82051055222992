import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncState } from './types';
import { asyncDefaultState } from './store.utils';
import { CreateSliceOptions } from '@reduxjs/toolkit/dist/createSlice';

type CreateFetchAsync<T> = {
  asyncThunk: any;
  initialState: AsyncState<T>;
  extraReducers: CreateSliceOptions['extraReducers'];
};

export const createFetchAsync = (
  module: string,
  name: string,
  payloadCreator: AsyncThunkPayloadCreator<any, any>
): CreateFetchAsync<any> => {
  const asyncThunk = createAsyncThunk<any, any>(
    `${module}/${name}`,
    payloadCreator
  );

  const initialState: AsyncState<any> = asyncDefaultState;

  return {
    asyncThunk,
    initialState,
    extraReducers: {
      [asyncThunk.pending.toString()]: (state) => {
        state[name] = {
          loading: true,
          payload: null,
          errors: null
        };
      },
      [asyncThunk.fulfilled.toString()]: (state, { payload }) => {
        state[name] = {
          loading: false,
          payload: payload,
          errors: null
        };
      },
      [asyncThunk.rejected.toString()]: (state, { payload }) => {
        state[name] = {
          loading: false,
          payload: null,
          errors: payload
        };
      }
    }
  };
};
