import { Expose } from 'class-transformer';

export class ProductivityDto {
  @Expose()
  total: number;

  @Expose()
  cancelled: number;

  @Expose()
  staffCancelled: number;

  @Expose()
  parentCancelled: number;

  @Expose()
  terminated: number;
}
