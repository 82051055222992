import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { RoleApi, RoleDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const roleApi = new RoleApi();

export const fetchRoleAsync = createFetchAsync(
  'role',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await roleApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchRole = fetchRoleAsync.asyncThunk;

export const fetchRole = (payload) => (dispatch, getState) => {
  const {
    role: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchRole(payload));
  }
};

type RoleState = {
  findAll: AsyncState<FindAllResponse<RoleDto>>;
};

const initialState: RoleState = {
  findAll: fetchRoleAsync.initialState
};

const role = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchRoleAsync.extraReducers
  }
});

export default role.reducer;
