import { ComponentType } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Stack, Link, Grid } from '@mui/material';
import { Card } from '../../../components/cards';
import { useDialog } from '../../../core/hooks/useDialog';
import { ChangesHistoryDialog } from '../dialogs/ChangesHistoryDialog';
import { LabeledRecord } from '../../../components/labeled-record';
import { usPhoneNumber } from '../../../core/utils/format.utils';
import {
  normalizeDate,
  capitalizeFirstLetter
} from '../../../core/utils/data.utils';
import { useClientDetail } from '../../../core/hooks/useClientDetail';
import {
  ClientContactDto,
  ClientInsuredPersonDto
} from '../../../core/http/dto';
import format from 'date-fns/format';

export const Profile: ComponentType = () => {
  const [openChangeHistoryDialog] = useDialog(ChangesHistoryDialog);
  const { id } = useParams();

  const { data } = useClientDetail({
    uuid: id
  });

  const primaryContactData = data?.contacts.find(
    (item: ClientContactDto) => item.type === 'primary'
  );

  const secondaryContactData = data?.contacts.find(
    (item: ClientContactDto) => item.type === 'secondary'
  );

  const emergencyContactData = data?.contacts.find(
    (item: ClientContactDto) => item.type === 'emergency'
  );

  const insuredContactData = data?.contacts.find(
    (item: ClientInsuredPersonDto) => item.type === 'insured'
  );

  return (
    <>
      <Stack spacing={2} direction="row">
        <Typography variant="subtitle1" color="common.info">
          Creation Date:{' '}
          {data?.creationDate &&
            format(new Date(data?.creationDate), 'MM/dd/yyyy hh:mm aa')}{' '}
          (
          <Link component="button" sx={{ textDecoration: 'none' }}>
            John Smith
          </Link>
          )
        </Typography>
        {normalizeDate}
        <Typography variant="subtitle1" color="common.info">
          Last Update:{' '}
          {data?.updatedDate &&
            format(new Date(data?.updatedDate), 'MM/dd/yyyy hh:mm aa')}{' '}
          (
          <Link component="button" sx={{ textDecoration: 'none' }}>
            Marta Kaufman
          </Link>
          )
        </Typography>
        <Link
          component="button"
          onClick={openChangeHistoryDialog}
          sx={{ textDecoration: 'none' }}
        >
          Changes history
        </Link>
      </Stack>
      <Stack mt={1.5} spacing={1} direction="column">
        <Card title="General Information">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="First Name:"
                value={capitalizeFirstLetter(data?.firstName)}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Last Name:"
                value={capitalizeFirstLetter(data?.lastName)}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="DOB:"
                value={normalizeDate(data?.birthDate) || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Age:"
                value={Math.floor(data?.ageMonths / 12) || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Gender:"
                value={capitalizeFirstLetter(data?.gender) || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Primary Language:"
                value={data?.primaryLanguage}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Secondary Language:"
                value={data?.secondaryLanguage}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Profit Center:"
                value={data?.profitCenter}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Funding Source:"
                value={data?.fundingSource}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Referral Acceptance Date:"
                value={normalizeDate(data?.referralAcceptanceDate)}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord label="Member ID:" value={data?.memberId} />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Notes:"
                value={data?.notes ? data?.notes.slice(0, 10) + '...' : '-'}
              />
            </Grid>
          </Grid>
        </Card>
        <Card title="Primary Contact Details">
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Relation to Child:"
                value={
                  capitalizeFirstLetter(primaryContactData?.relationToChild) ||
                  '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="First Name:"
                value={
                  capitalizeFirstLetter(primaryContactData?.firstName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Last Name:"
                value={
                  capitalizeFirstLetter(primaryContactData?.lastName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Email:"
                value={primaryContactData?.email || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Address:"
                value={
                  capitalizeFirstLetter(primaryContactData?.addressLine1) ||
                  'NA'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="City:"
                value={
                  capitalizeFirstLetter(primaryContactData?.city.title) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Province/State:"
                value={
                  capitalizeFirstLetter(primaryContactData?.state.title) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Zip Code:"
                value={primaryContactData?.zipCode || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Primary Phone Number:"
                value={
                  primaryContactData &&
                  usPhoneNumber(primaryContactData.primaryPhoneNumber)
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value={
                  primaryContactData &&
                  usPhoneNumber(primaryContactData.secondaryPhoneNumber)
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Work Phone Number:"
                value={
                  primaryContactData &&
                  usPhoneNumber(primaryContactData.workPhoneNumber)
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Enable Text Notifications:"
                value={
                  primaryContactData?.isTextNotificationsEnabled ? 'Yes' : 'No'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Enable Email Notifications:"
                value={
                  primaryContactData?.isEmailNotificationsEnabled ? 'Yes' : 'No'
                }
              />
            </Grid>
          </Grid>
        </Card>

        <Card title="Secondary Contact Details">
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Relation to Child:"
                value={
                  capitalizeFirstLetter(
                    secondaryContactData?.relationToChild
                  ) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="First Name:"
                value={
                  capitalizeFirstLetter(secondaryContactData?.firstName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Last Name:"
                value={
                  capitalizeFirstLetter(secondaryContactData?.lastName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Email:"
                value={secondaryContactData?.email || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Address:"
                value={
                  capitalizeFirstLetter(secondaryContactData?.addressLine1) ||
                  '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="City:"
                value={
                  capitalizeFirstLetter(secondaryContactData?.city.title) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Province/State:"
                value={
                  capitalizeFirstLetter(secondaryContactData?.state.title) ||
                  '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Zip Code:"
                value={secondaryContactData?.zipCode || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Primary Phone Number:"
                value={
                  secondaryContactData
                    ? usPhoneNumber(secondaryContactData.primaryPhoneNumber)
                    : '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value={
                  secondaryContactData
                    ? usPhoneNumber(secondaryContactData.secondaryPhoneNumber)
                    : '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Work Phone Number:"
                value={
                  secondaryContactData
                    ? usPhoneNumber(secondaryContactData.workPhoneNumber)
                    : '-'
                }
              />
            </Grid>
          </Grid>
          {secondaryContactData?.isEnabledNotifications && (
            <Grid container spacing={2}>
              <Grid item md={3}>
                <LabeledRecord label="Enable Text Notifications:" value="Yes" />
              </Grid>
            </Grid>
          )}
        </Card>

        <Card title="Insured Person Details">
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="First Name:"
                value={
                  capitalizeFirstLetter(insuredContactData?.firstName) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Last Name:"
                value={
                  capitalizeFirstLetter(insuredContactData?.lastName) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="DOB:"
                value={normalizeDate(insuredContactData?.birthDate) || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Gender:"
                value={capitalizeFirstLetter(insuredContactData?.gender) || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="City:"
                value={
                  capitalizeFirstLetter(insuredContactData?.city.title) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="State:"
                value={
                  capitalizeFirstLetter(insuredContactData?.state.title) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Zip Code:"
                value={insuredContactData?.zipCode || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Address line 1:"
                value={
                  capitalizeFirstLetter(insuredContactData?.addressLine1) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Address line 2:"
                value={
                  capitalizeFirstLetter(insuredContactData?.addressLine2) || '-'
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}></Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Subscriber Id:"
                value={insuredContactData?.subscriberId || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Group:"
                value={
                  capitalizeFirstLetter(insuredContactData?.groupName) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Plan Name:"
                value={
                  capitalizeFirstLetter(insuredContactData?.planName) || '-'
                }
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Payer Name:"
                value={insuredContactData?.payer?.name || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={2.4}>
              <LabeledRecord
                label="Start Date:"
                value={normalizeDate(insuredContactData?.startDate) || '-'}
              />
            </Grid>
            <Grid item md={2.4}>
              <LabeledRecord
                label="End Date:"
                value={normalizeDate(insuredContactData?.endDate) || '-'}
              />
            </Grid>
          </Grid>
        </Card>

        <Card title={'Emergency Contact Details'}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Relation to Child:"
                value={capitalizeFirstLetter(
                  emergencyContactData?.relationToChild
                )}
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="First Name:"
                value={
                  capitalizeFirstLetter(emergencyContactData?.firstName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Last Name:"
                value={
                  capitalizeFirstLetter(emergencyContactData?.lastName) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Email:"
                value={emergencyContactData?.email || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Address:"
                value={
                  capitalizeFirstLetter(emergencyContactData?.addressLine1) ||
                  '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="City:"
                value={
                  capitalizeFirstLetter(emergencyContactData?.city.title) || '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Province/State:"
                value={
                  capitalizeFirstLetter(emergencyContactData?.state.title) ||
                  '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Zip Code:"
                value={emergencyContactData?.zipCode || '-'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <LabeledRecord
                label="Primary Phone Number:"
                value={
                  emergencyContactData
                    ? usPhoneNumber(emergencyContactData.primaryPhoneNumber)
                    : '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Secondary Phone Number:"
                value={
                  emergencyContactData
                    ? usPhoneNumber(emergencyContactData.secondaryPhoneNumber)
                    : '-'
                }
              />
            </Grid>
            <Grid item md={3}>
              <LabeledRecord
                label="Work Phone Number:"
                value={
                  emergencyContactData
                    ? usPhoneNumber(emergencyContactData.workPhoneNumber)
                    : '-'
                }
              />
            </Grid>
          </Grid>
          {emergencyContactData?.isEnabledNotifications && (
            <Grid container spacing={2}>
              <Grid item md={3}>
                <LabeledRecord label="Enable Text Notifications:" value="Yes" />
              </Grid>
            </Grid>
          )}
        </Card>
      </Stack>
    </>
  );
};
