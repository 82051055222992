import { ComponentType } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { Reset2FADialog } from './Reset2FADialog';
import { Card } from '../../../components/cards';
import GoogleAuthenticatorIcon from '../../../assets/google-authenticator-icon.png';
import { useDialog } from '../../../core/hooks/useDialog';

interface Props extends DialogProps {
  onClose?: () => void;
}

export const Manage2FADialog: ComponentType<Props> = ({ popDialog }) => {
  const [openReset2FADialog] = useDialog(Reset2FADialog);
  const handleReset = () => {
    popDialog();
    openReset2FADialog();
  };

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 400
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        }
      }}
    >
      <DialogTitle>Manage 2-factor authentication</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Card>
            <Grid container justifyContent="center">
              <Grid
                md={2}
                display="flex"
                direction="column"
                justifyContent="center"
              >
                <img src={GoogleAuthenticatorIcon} width="40" />
              </Grid>
              <Grid
                md={7}
                display="flex"
                direction="column"
                justifyContent="center"
              >
                Your account protected with
                <Typography fontWeight={600} variant="body2">
                  Google Autenticator
                </Typography>
              </Grid>
              <Grid
                md={3}
                display="flex"
                direction="column"
                justifyContent="center"
              >
                <Button color="third" onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
