import { Card } from '../../../../components/cards';
import { TableView } from '../../../../components/table-view';
import { revenueAndMarginColumns, revenueAndMarginData } from '../../_mockdata';

export const RevenueAndMargin = () => {
  return (
    <Card
      height={512}
      title="Revenue & Margin"
      width="100%"
      ContentProps={{
        sx: {
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    >
      <TableView
        keyExtractor={(item) => item.uuid}
        columns={[
          ...revenueAndMarginColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 50
            }
            // render: (_) => (
            //   <Stack direction="row" alignItems="center">
            //     <Tooltip title="View" placement="bottom-end">
            //       <IconButton size="small" onClick={openViewDialog}>
            //         <IconView />
            //       </IconButton>
            //     </Tooltip>
            //   </Stack>
            // )
          }
        ]}
        dataSource={revenueAndMarginData}
      />
    </Card>
  );
};
