import {
  Box,
  BoxProps,
  Typography,
  Stack,
  IconButton,
  Button
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import get from 'lodash/get';
import { IconFile, IconTrash, IconUpload } from '../../icons';
import { bytesToSize } from '../../../core';
import clsx from 'clsx';
import { FileApi, FileDto } from '../../../core/http';
import { v4 as uuidv4 } from 'uuid';
import { isObject } from 'lodash';

interface Props extends Omit<BoxProps, 'onChange'> {
  errors?: string;
  value?: string;
  canRemove?: boolean;
  onChange?: (guid: string | null, files: File) => void;
  upload?: boolean;
  createPayload?: {
    description: string;
    expirationDate: string;
    noExpirationDate: boolean;
    type: 'licence' | 'certificate';
    rootId: string;
    rootType: 'employee' | 'client';
  };
  requestOnDeleted?: boolean;
}

const fileApi = new FileApi();

export const FileAttach: FC<Props> = ({
  value,
  upload = false,
  canRemove = true,
  onChange,
  createPayload,
  errors,
  requestOnDeleted = true,
  ...props
}) => {
  const [uploadedFile, setUploadedFile] = useState<FileDto | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const classes = useStyles();
  const isError = Boolean(errors);

  useEffect(() => {
    if (value && !isObject(value)) {
      fileApi.findOne(value).then((file) => {
        setUploadedFile(file);
      });
    } else if (value && isObject(value)) {
      setUploadedFile(value);
    }
  }, [value]);

  const handleDrop = () => {
    setDragActive(false);
  };
  const handleDragLeave = () => {
    setDragActive(false);
  };
  const handleDragOver = () => {
    setDragActive(true);
  };

  const handleChange = (e: any) => {
    const file = get(e, 'target.files.0');
    setFile(file);
    const guid = uuidv4();

    if (upload && file) {
      uploadFile(file, guid).then(() => {
        onChange && onChange(guid, file);
      });
    } else if (file && onChange) {
      onChange(guid, file);
    }
  };

  const uploadFile = (file: File, guid: string) => {
    return fileApi.create({
      guid,
      file,
      fileName: file.name,
      ...createPayload
    });
  };

  const handleDeleteFile = (uuid) => {
    if (requestOnDeleted) {
      fileApi.delete(uuid).then(() => {
        setUploadedFile(null);
        if (onChange) {
          onChange(null, null);
        }
      });
    } else {
      setUploadedFile(null);
      onChange && onChange(null, null);
    }
  };

  const handleRemove = () => {
    setFile(null);
    onChange && onChange(null, null);
  };

  if (uploadedFile) {
    return (
      <Box className={classes.root} {...props}>
        <Stack direction="row" alignItems="center" width="100%">
          <IconFile sx={{ color: '#647593', mr: 1 }} />
          <Box>
            <Typography className={classes.fileName}>
              {uploadedFile.fileName}
            </Typography>
            <Typography className={classes.fileSize}>
              {/*{bytesToSize(uploadedFile?.size)}*/}
            </Typography>
          </Box>
          <IconButton
            disabled={!canRemove}
            onClick={() => handleDeleteFile(uploadedFile.uuid)}
            sx={{ marginLeft: 'auto' }}
          >
            <IconTrash />
          </IconButton>
        </Stack>
      </Box>
    );
  }

  if (file) {
    return (
      <Box className={classes.root} {...props}>
        <Stack direction="row" alignItems="center" width="100%">
          <IconFile sx={{ color: '#647593', mr: 1 }} />
          <Box>
            <Typography className={classes.fileName}>{file.name}</Typography>
            <Typography className={classes.fileSize}>
              {bytesToSize(file.size)}
            </Typography>
          </Box>
          <IconButton onClick={handleRemove} sx={{ marginLeft: 'auto' }}>
            <IconTrash />
          </IconButton>
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      className={clsx(classes.emptyRoot, {
        [classes.dragActive]: dragActive,
        [classes.error]: isError
      })}
      {...props}
      {...props}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1.25}
      >
        <IconUpload />
        <Typography className={classes.label}>
          Drag and drop file here
        </Typography>
        <Button className={classes.browseBtn} color="third">
          Browse file
        </Button>
      </Stack>
      {isError && (
        <Typography className={classes.errorText} variant="body2" mt={1}>
          {errors}
        </Typography>
      )}

      <input className={classes.file} type="file" onChange={handleChange} />
    </Box>
  );
};
