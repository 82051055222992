import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';
import map from 'lodash/map';

export class EmployeeDto {
  @Expose()
  id: number;

  @Expose()
  uuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'title.title'))
  title: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'title.uuid'))
  titleUuid: string;

  @Expose()
  titleGeneralInfo: string;

  @Expose()
  titleCustom: string;

  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  email: string;

  @Expose()
  gender: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'profitCenter.profitCentre'))
  profitCentre: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'profitCenter.uuid'))
  profitCenterUuid: any;

  @Expose()
  availableHours: any;

  @Expose()
  hrid: any;

  @Expose()
  @Transform((_, obj) => map(obj.serviceTypes, 'uuid'))
  serviceTypes: string[];

  @Expose()
  @Transform((_, obj) => map(obj.serviceTypes, 'title'))
  serviceTypeNames: string[];

  @Expose()
  @Transform((_, obj) => map(obj.groups, 'uuid'))
  groups: string[];

  @Expose()
  @Transform((_, obj) => map(obj.groups, 'groupName'))
  groupNames: string[];

  @Expose()
  @Transform((_, obj) => get(obj, 'primaryLanguage.name'))
  primaryLanguage: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'primaryLanguage.uuid'))
  primaryLanguageUuid: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'secondaryLanguage.name'))
  secondaryLanguage: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'secondaryLanguage.uuid'))
  secondaryLanguageUuid: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'contactInfo.phoneNumberPrimary'))
  phone: string;

  @Expose()
  get displayName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  @Expose()
  supervisor: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'supervisor.uuid'))
  supervisorUuid: string;

  @Expose()
  @Transform((_, obj) => map(obj.skills, 'uuid'))
  skillsUuids: string;

  @Expose()
  @Transform((_, obj) => map(obj.skills, 'title'))
  skills: any;

  @Expose()
  types: any[];

  @Expose()
  hourlyRate: number;

  @Expose()
  targetedHour: number;

  @Expose()
  pto: number;

  @Expose()
  hireDate: any;

  @Expose()
  endWorkDate: any;

  @Expose()
  status: string;

  @Expose()
  notes: any;

  @Expose()
  contactInfo: any;

  @Expose()
  isDeleted: boolean;
}
