import { ServiceCodeContainer } from '../../features/master-data/service-code/ServiceCodeContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataServiceCode = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Service Codes'
  })
)(ServiceCodeContainer);
