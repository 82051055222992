import React, { ComponentType } from 'react';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer, {
  TableContainerProps
} from '@mui/material/TableContainer';
import { Pagination, PaginationItem, Stack } from '@mui/material';
import { TableCellProps } from '@mui/material/TableCell/TableCell';
import { TableViewRow } from './TableViewRow';
import { TableViewHeader } from './table-view-header';
import { useTableSelection } from './useTableSelection';
import { useTableSorting } from './useTableSorting';
import { useTableFiltering } from './useTableFiltering';
import useStyles from './styles';
import clsx from 'clsx';
import { TablePerPage } from './table-per-page';
import { useTablePerPage } from './useTablePerPage';
import map from 'lodash/map';
import { TableViewLoadingRow } from './TableViewLoadingRow';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { EmptyState } from '../empty-state';
import { EmptyStateProps } from '../empty-state/EmptyState';
import { times } from 'lodash';

export interface DataTableFilterItem {
  label: string;
  value: string;
}

export interface DataTableColumn {
  title: string;
  dataIndex: string;
  key: string;
  cellProps?: TableCellProps;
  render?: (value: any, record: any, renderProps: any) => any;
  sortable?: boolean;
  filters?: DataTableFilterItem[];
}

export interface DataTableRowSelection {
  selectedRowKeys: string[];
  onChange: (item: string[]) => void;
}

export interface DataTableRowExpandable {
  expandedRowRender: (record: any) => any;
  rowExpandable?: (record: any) => boolean;
}

interface Props extends TableContainerProps {
  dataSource: Record<string, any>[];
  columns: DataTableColumn[];
  rowSelection?: DataTableRowSelection;
  onRowClick?: (item: Record<string, any>) => void;
  expandable?: DataTableRowExpandable;
  hideColumns?: string[];
  striped?: boolean;
  renderProps?: any;
  pagination?: {
    page: number;
    perPage: number;
    totalPages: number;
    total: number;
  };
  showPagination?: boolean;
  showTablePerPage?: boolean;
  keyExtractor?: (item: Record<string, any>) => string;
  loading?: boolean;
  loadingSkeletonCount?: number;
  onChangePage?: (page: number) => void;
  onChangePerPage?: (perPage: number) => void;
  onChangeSort?: (sortState: Record<string, 'desc' | 'asc'>) => void;
  showEmptyState?: boolean;
  EmptyStateProps?: EmptyStateProps;
  TableProps?: TableProps;
  isStickyHeader?: boolean;
  scrollable?: boolean;
}

export const TableView: ComponentType<Props> = ({
  rowSelection,
  columns: defaultColumns,
  dataSource,
  onRowClick,
  expandable,
  hideColumns = [],
  striped,
  renderProps,
  showPagination = true,
  showTablePerPage = true,
  pagination,
  keyExtractor = (item) => item.id,
  loading,
  loadingSkeletonCount = 5,
  onChangePage,
  onChangePerPage,
  showEmptyState = false,
  EmptyStateProps = {},
  TableProps,
  isStickyHeader = false,
  onChangeSort,
  scrollable,
  ...props
}) => {
  const {
    selectedRows,
    isSelectedAll,
    handleSelectRow,
    isSelectedRowsEmpty,
    handleSelectAll
  } = useTableSelection(dataSource, rowSelection, keyExtractor);

  const { perPage, onChange: onChangeTablePerPage, items } = useTablePerPage();
  const { sortingState, sort } = useTableSorting(onChangeSort);
  const { filteringState, filter, clearFilter } = useTableFiltering();

  const isSelectable = !!rowSelection;
  const columns = defaultColumns.filter(
    (column) => !hideColumns.includes(column.key)
  );
  const isExpandable = Boolean(expandable && !!expandable.expandedRowRender);
  const columnsCount =
    columns.length + (isSelectable ? 1 : 0) + (isExpandable ? 1 : 0);
  const classes = useStyles();
  const pagesCount = get(pagination, 'totalPages', 0);

  const handleChangePerPage = (perPage: number) => {
    if (onChangePerPage) {
      onChangePerPage(perPage);
    }
    onChangeTablePerPage(perPage);
  };

  if (!loading && isEmpty(dataSource) && showEmptyState) {
    return (
      <EmptyState
        title="No data found"
        description="Please, try to change filters"
        {...EmptyStateProps}
      />
    );
  }

  return (
    <>
      <TableContainer
        className={clsx(
          classes.root,
          { [classes.striped]: striped },
          { [classes.scrollable]: scrollable }
        )}
        {...props}
      >
        <Table {...TableProps} stickyHeader={isStickyHeader}>
          <TableViewHeader
            {...{
              loading,
              isExpandable,
              isSelectable,
              isSelectedAll,
              isSelectedRowsEmpty,
              handleSelectAll,
              columns,
              sortingState,
              sort,
              filteringState,
              filter,
              clearFilter
            }}
          />

          <TableBody>
            {loading
              ? times(loadingSkeletonCount, (item) => (
                  <TableViewLoadingRow
                    key={item}
                    isSelectable={isSelectable}
                    columns={columns}
                  />
                ))
              : map(dataSource, (item) => (
                  <TableViewRow
                    key={keyExtractor(item)}
                    onClick={(item) => onRowClick && onRowClick(item)}
                    {...{
                      keyExtractor,
                      expandable,
                      columnsCount,
                      item,
                      columns,
                      rowSelection,
                      selectedRows,
                      setSelectedRows: handleSelectRow,
                      renderProps
                    }}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      {(showPagination || showTablePerPage) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          {showPagination && pagesCount > 0 && (
            <Pagination
              page={pagination.page}
              count={pagesCount}
              onChange={(_, page) => onChangePage && onChangePage(page)}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: () => <span>Prev</span>,
                    next: () => <span>Next</span>
                  }}
                  {...item}
                />
              )}
            />
          )}
          {showTablePerPage && pagesCount > 0 && (
            <TablePerPage
              {...{
                pagination,
                perPage,
                onChange: handleChangePerPage,
                items
              }}
            />
          )}
        </Stack>
      )}
    </>
  );
};
