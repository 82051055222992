import { makeStyles } from '@mui/styles';

const options = {
  name: 'base-layout'
};

const useStyles = makeStyles(
  () => ({
    root: {
      height: '100vh',
      display: 'flex'
    },
    main: {
      flexGrow: 1
    },
    header: {},
    content: {
      height: 'calc(100vh - 56px)',
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 40
    }
  }),
  options
);

export default useStyles;
