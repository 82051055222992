import { get } from 'lodash';
import React from 'react';
import { Card } from '../../../components/cards';
import { TableView } from '../../../components/table-view';
import { useListResource } from '../../../core/hooks/useListResource';
import { FileApi } from '../../../core/http';
import { filesColumns } from '../_mockdata';

const fileApi = new FileApi();

export const FilesListWidget = ({ employeeUuid }) => {
  const { data, isLoading, handleChangeSort } = useListResource(fileApi, {
    defaultParams: { rootType: 'employee', rootGuuid: employeeUuid }
  });
  return (
    <Card title="Files">
      <TableView
        showPagination={false}
        onChangeSort={handleChangeSort}
        showTablePerPage={false}
        dataSource={get(data, 'items') || []}
        loading={isLoading}
        columns={filesColumns}
        showEmptyState
        EmptyStateProps={{
          title: 'No files found for this employee.',
          description: null
        }}
      />
    </Card>
  );
};
