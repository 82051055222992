import { PageHeader } from '../../components/page-header';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { LabeledRecord } from '../../components/labeled-record';
import { TableView } from '../../components/table-view';
import { IconPen } from '../../components/icons';
import {
  serviceProviderBillindDetailsData,
  serviceProviderBillingDetailsColumns
} from './_mockdata';
import { useState } from 'react';

export const BillingDetails = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  return (
    <>
      <PageHeader title="Service Provider Billing Details">
        <Button>Export invoice</Button>
      </PageHeader>
      <Stack
        direction={'row'}
        spacing={16}
        mb={3}
        bgcolor="#F6F9FF"
        p={1.5}
        height={64}
      >
        <LabeledRecord label="Client Name" value="Kathryn Murphy" />
        <LabeledRecord label="Invoice #" value="434234" />
        <LabeledRecord label="Invoice Date" value="12/22/2022" />
        <LabeledRecord label="Service Code" value="805 – Infant Dev Program" />
        <LabeledRecord label="Total Units Billed" value="_" />
        <LabeledRecord label="Service M/Y" value="02/2022" />
        <LabeledRecord label="Total Amount Billed" value="-" />
      </Stack>
      <TableView
        loading={false}
        pagination={{
          page: 1,
          perPage: 12,
          totalPages: 10,
          total: 100
        }}
        keyExtractor={(item) => item.uuid}
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: (uuid) => setSelectedIds(uuid)
        }}
        columns={[
          ...serviceProviderBillingDetailsColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 50
            },
            render: () => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton size="small">
                    <IconPen />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
        dataSource={serviceProviderBillindDetailsData}
      />
    </>
  );
};
