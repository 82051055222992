import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'template-component'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 4,
      padding: theme.spacing(0.5)
    },
    rootResponsive: {
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    button: {
      borderRadius: 4,
      margin: 0,
      color: '#496492',
      padding: theme.spacing(0.5, 1),
      height: 24,
      fontSize: 12,
      fontWeight: 500,
      minWidth: 'auto',
      '&:hover': {
        color: '#0052CC',
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px rgba(30, 42, 75, 0.08)'
      }
    },
    buttonActive: {
      color: '#0052CC',
      backgroundColor: '#fff',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)'
    },
    buttonResponsive: {
      [theme.breakpoints.down('md')]: {
        width: '50%'
      }
    }
  }),
  options
);

export default useStyles;
