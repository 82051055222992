import { TimesheetsList } from '../../../features/timesheets/TimesheetsList';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../../core';

export const TimeSheetsListPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Timesheets'
  })
)(TimesheetsList);
