import { AuthApi } from '../http/requests/auth.api';

const authApi = new AuthApi();

export const useAuth = () => {
  const logout = () => {
    authApi.logout();
  };

  return {
    logout
  };
};
