import {
  Box,
  BoxProps,
  IconButton,
  Link,
  Stack,
  styled,
  Typography
} from '@mui/material';
import React, { FC } from 'react';
import { normalizeDate } from '../../core';

interface Props {
  item: any;
  border?: boolean;
  readOpacity?: boolean;
}

const UnreadDot = styled(Box)<BoxProps>(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main
}));

export const NotificationCard: FC<Props> = ({
  item,
  border = true,
  readOpacity = false
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      pb={1}
      mb={1}
      borderBottom={border ? '1px solid #ECF0F7' : 'none'}
      sx={{
        opacity: readOpacity && item.readAt ? 0.7 : 1
      }}
    >
      <Box flex={1} mr={2}>
        <Typography variant="body3" fontWeight={600} component="div">
          {item.title}
        </Typography>
        {/* <Typography variant="body3" component="div">
          {item.message}
        </Typography> */}
        {item.file && (
          <Link variant="body3" underline="none">
            {item.file.name}
          </Link>
        )}
        <Typography variant="body2" component="div" color="#647593">
          {normalizeDate(item.createdAt)}
        </Typography>
      </Box>
      {!item.readAt && (
        <IconButton size="small">
          <UnreadDot />
        </IconButton>
      )}
    </Stack>
  );
};
