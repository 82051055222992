import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { useLayout } from '../../core/hooks/useLayout';
import {
  ROUTE_APPOINTMENTS,
  ROUTE_MAKE_UP_APPOINTMENTS_EDIT
} from '../../constants/route.paths';
import { Card } from '../../components/cards';
import { ClientAutocompleteField, DatePicker } from '../../components/fields';
import { TableView } from '../../components/table-view';
import { newMakeUpAppointmentColumns } from './_mockdata';
import get from 'lodash/get';
import { IconHistory } from '../../components/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core';
import { useAppointmentList } from '../../core/hooks/useAppointmentList';
import { useFilter } from '../../core/hooks/useFilter';
import { PermissionView } from '../../components/permission-view';
import { ActiveEmployeeAutocompleteField } from '../../components/fields/active-employee-autocomplete-field';

export const MakeUpAppointmentsList = () => {
  const navigate = useNavigate();

  useLayout({
    backButton: {
      title: 'Appointments',
      path: ROUTE_APPOINTMENTS
    }
  });
  const {
    data,
    isLoading,
    pagination,
    handleChangePerPage,
    handleChangePage,
    handleChangeSort,
    handleChangeParams
  } = useAppointmentList({
    defaultParams: {
      'filter[startDate][gt]': null,
      'filter[endDate][lte]': null,
      statusFilter: 'cancelled',
      limit: 20
    }
  });

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    initialValue: {
      'filter[startDate][eq]': null,
      'filter[canceledAt][eq]': null
    },
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  return (
    <>
      <PageHeader title="New Make Up Appointment" />
      <Card sx={{ p: 2, backgroundColor: '#F9FBFE', mb: 3 }}>
        <Stack direction="row" spacing={1} alignItems="flex-end" mb={1.5}>
          <Box maxWidth={160}>
            <DatePicker
              label="Appointment Date"
              value={getFilterValue('filter[startDate][eq]')}
              onChange={setFilterValue('filter[startDate][eq]')}
              helperText={null}
            />
          </Box>
          <Box maxWidth={160}>
            <DatePicker
              label="Cancelled Date"
              value={getFilterValue('filter[canceledAt][eq]')}
              onChange={setFilterValue('filter[canceledAt][eq]')}
              helperText={null}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <ClientAutocompleteField
            label="Client"
            helperText={null}
            size="small"
            placeholder="Search"
            value={getFilterValue('filter[client.uuid][eq]')}
            onChange={setFilterValue('filter[client.uuid][eq]')}
          />
          <ActiveEmployeeAutocompleteField
            label="Provider"
            helperText={null}
            size="small"
            placeholder="Search"
            value={getFilterValue('filter[employee][eq]')}
            onChange={setFilterValue('filter[employee][eq]')}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Stack>
      </Card>

      <TableView
        loading={isLoading}
        showEmptyState
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        pagination={pagination}
        keyExtractor={(item: { uuid: string }) => item.uuid}
        columns={[
          ...newMakeUpAppointmentColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <PermissionView permission="modifyAppointments">
                  <Tooltip title="Edit" placement="bottom-end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigate(
                          url(ROUTE_MAKE_UP_APPOINTMENTS_EDIT, { id: uuid })
                        );
                      }}
                    >
                      <IconHistory />
                    </IconButton>
                  </Tooltip>
                </PermissionView>
              </Stack>
            )
          }
        ]}
        dataSource={get(data, 'items', [])}
      />
    </>
  );
};
