import { Button } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { useLayout } from '../../core/hooks/useLayout';
import {
  ROUTE_CLIENT_APPOINTMENT_VIEW,
  ROUTE_MAKE_UP_APPOINTMENTS
} from '../../constants/route.paths';
import { AppointmentGeneralForm } from './AppointmentGeneralForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { url } from '../../core';
import { useAppointmentMakeUp } from '../../core/hooks/useAppointmentMakeUp';

export const MakeUpAppointmentForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedState, setSelectedState] = useState<any>();
  useLayout({
    backButton: {
      title: 'Make Up Appointments',
      path: ROUTE_MAKE_UP_APPOINTMENTS
    }
  });

  const {
    isEdit,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    getFieldTouchedError,
    isLoading,
    setFieldTouched,
    handleBlur
  } = useAppointmentMakeUp(id, selectedCity, selectedState);

  const handleClientView = () => {
    navigate(
      url(ROUTE_CLIENT_APPOINTMENT_VIEW, {
        uuid: values.uuid,
        clientId: values.clientUuid
      })
    );
  };

  return (
    <>
      <PageHeader
        title={isEdit ? 'Edit MakeUp Appointment' : 'New Make Up Appointment'}
      >
        <Button color="secondary" onClick={() => handleClientView()}>
          Open original appointment
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm appointment</Button>
        <Button
          color="third"
          onClick={() => navigate(ROUTE_MAKE_UP_APPOINTMENTS)}
        >
          Cancel
        </Button>
      </PageHeader>
      <AppointmentGeneralForm
        {...{
          errors,
          values,
          isLoading,
          setFieldTouched,
          handleBlur,
          setFieldValue,
          getFieldTouchedError,
          handleSubmit,
          setSelectedCity,
          setSelectedState
        }}
      />
    </>
  );
};
