import { ComponentType, useEffect, useState } from 'react';
import { Box, Button, Stack, IconButton, Tooltip } from '@mui/material';
import { FilterableTabs } from '../../components/tabs/FilterableTabs';
import { TableView } from '../../components/table-view';
import { employeesColumns } from './_mockdata';
import { SearchField } from '../../components/fields';
import { PageHeader } from '../../components/page-header';
import { useNavigate } from 'react-router-dom';
import { url } from '../../core';
import {
  ROUTE_EMPLOYEE_DETAILS,
  ROUTE_EMPLOYEE_PROFILE_CREATE,
  ROUTE_EMPLOYEE_PROFILE_EDIT
} from '../../constants/route.paths';
import { IconPen, IconTrash } from '../../components/icons';
import get from 'lodash/get';
import { useListResource } from '../../core/hooks/useListResource';
import { EmployeeApi, EmployeeDto } from '../../core/http';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { PermissionView } from '../../components/permission-view';

const employeeApi = new EmployeeApi();

const filters = [
  {
    key: 'all',
    label: 'All employees'
  },
  {
    key: 'active',
    label: 'Active'
  },
  {
    key: 'license_expired',
    label: 'License Expired'
  },
  {
    key: 'terminated',
    label: 'Terminated'
  }
];

export const EmployeesList: ComponentType = () => {
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const [convertedEmployeesListData, setConvertedEmployeesList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loadingExpiredEmployees, setLoadingExpiredEmployees] = useState(false);
  const {
    data,
    isLoading,
    pagination,
    fetchData,
    exportData,
    deleteRecord,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort,
    newParamsLoading
  } = useListResource(employeeApi);

  useEffect(() => {
    data &&
      setConvertedEmployeesList(
        get(data, 'items', []).map((item: EmployeeDto) => {
          return {
            ...item,
            id: item.id || '-',
            phone: item.phone,
            employee: {
              firstName: item?.firstName,
              lastName: item?.lastName
            },
            supervisor: {
              firstName: item.supervisor?.firstName,
              lastName: item.supervisor?.lastName
            }
          };
        })
      );
  }, [data]);

  const handleDelete = (data: EmployeeDto) => () => {
    const name = [
      get(data, 'employee.firstName'),
      get(data, 'employee.lastName')
    ]
      .filter(Boolean)
      .join(' ');

    openConfirm({
      title: 'Terminate',
      message: `Do you really want to terminate employee ${name}? `,
      onConfirm: () => {
        deleteRecord(data.uuid).then(() => {
          closeConfirm();
          showMessage(`Employee ${name} terminated successfully`);
          fetchData();
        });
      }
    });
  };

  const handleEdit = (uuid: string) => () => {
    navigate(
      url(ROUTE_EMPLOYEE_PROFILE_EDIT, {
        id: uuid
      })
    );
  };

  return (
    <Box>
      <PageHeader title="Employees" divider={false}>
        <PermissionView permission="modifyEmployees">
          <Button
            onClick={() => navigate(url(ROUTE_EMPLOYEE_PROFILE_CREATE))}
            title="Add new"
          >
            Add new
          </Button>
        </PermissionView>
        <Button color="secondary" onClick={exportData}>
          Export
        </Button>
      </PageHeader>
      <Box>
        <FilterableTabs
          onChange={(key) => {
            const value = get(filters, [key, 'key'], null);
            setSelectedTab(key);
            if (value === 'all') {
              handleChangeParams({
                'filter[status][eq]': null,
                'filter[isDeleted][eq]': null
              });
            } else if (value === 'active') {
              handleChangeParams('filter[isDeleted][eq]', 0);
            } else if (value === 'terminated') {
              handleChangeParams('filter[isDeleted][eq]', 1);
            } else if (value === 'license_expired') {
              setLoadingExpiredEmployees(true);
              employeeApi
                .findExpired()
                .then((data) => {
                  setConvertedEmployeesList(
                    get(data, 'items', []).map((item: EmployeeDto) => {
                      return {
                        ...item,
                        id: item.id || '-',
                        phone: item.phone,
                        employee: {
                          firstName: item?.firstName,
                          lastName: item?.lastName
                        },
                        supervisor: {
                          firstName: item.supervisor?.firstName,
                          lastName: item.supervisor?.lastName
                        }
                      };
                    })
                  );
                })
                .finally(() => setLoadingExpiredEmployees(false));
            }
          }}
          items={filters}
        />
      </Box>
      <SearchField
        loading={newParamsLoading}
        sx={{ width: 400, mb: 3 }}
        size="small"
        placeholder="Search by Employee Name, ID"
        onChange={(value) => {
          handleChangeParams('search', value || null);
        }}
      />
      <Box>
        <TableView
          loading={isLoading || loadingExpiredEmployees}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePerPage}
          onChangeSort={handleChangeSort}
          showTablePerPage={true}
          showPagination={true}
          pagination={pagination}
          keyExtractor={(item: { uuid: string }) => item.uuid}
          onRowClick={(item) =>
            navigate(
              url(ROUTE_EMPLOYEE_DETAILS, {
                id: item.uuid,
                tab: 'dashboard'
              })
            )
          }
          dataSource={convertedEmployeesListData || []}
          showEmptyState
          EmptyStateProps={{
            title: `You dont have any ${get(
              filters,
              [selectedTab, 'label'],
              null
            )} Employee`,
            description: null,
            action: selectedTab === 0 && (
              <Button
                onClick={() => navigate(url(ROUTE_EMPLOYEE_PROFILE_CREATE))}
              >
                Add new
              </Button>
            )
          }}
          columns={[
            ...employeesColumns,
            {
              title: '',
              dataIndex: 'actions',
              key: 'actions',
              cellProps: {
                width: 50
              },
              render: (_, data: EmployeeDto) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  <PermissionView permission="modifyEmployees">
                    <Tooltip title="Edit" placement="bottom-end">
                      <IconButton size="small" onClick={handleEdit(data.uuid)}>
                        <IconPen />
                      </IconButton>
                    </Tooltip>
                  </PermissionView>
                  {data.isDeleted === false && (
                    <PermissionView permission="modifyEmployees">
                      <Tooltip title="Terminate" placement="bottom-end">
                        <IconButton size="small" onClick={handleDelete(data)}>
                          <IconTrash />
                        </IconButton>
                      </Tooltip>
                    </PermissionView>
                  )}
                </Stack>
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
