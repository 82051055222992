import { parse, format, isValid, isDate, differenceInMinutes } from 'date-fns';
import range from 'lodash/range';
import padStart from 'lodash/padStart';

const stringToBoolean = (value: string) => {
  return value === 'true' ? true : value === 'false' ? false : value;
};

const isStringBoolean = (value: string) => {
  return (typeof value === 'string' && value === 'true') || value === 'false';
};

export const currencyFormatter = (value: number, currency = 'USD') => {
  if (!value) return '0.00';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency
  });
  return formatter.format(value);
};

export const dateIsValid = (date) => {
  if (
    typeof date === 'object' &&
    date !== null &&
    typeof date.getTime === 'function' &&
    !isNaN(date)
  ) {
    return true;
  }

  return false;
};

export const normalizePhone = (phone) => {
  return phone.replace(/[^0-9]/g, '');
};

export const arrayFromLength = (number: number) => {
  return Array.from(new Array(number).keys()).map((k) => k + 1);
};

export const pluralize = (count: number, word: string, empty = '') => {
  if (count === 0 || count === undefined) {
    return empty;
  }
  return count === 1 ? `1 ${word}` : `${count} ${word}s`;
};

export const normalizeDate = (date: string, dateFormat = 'MM/dd/yyyy') => {
  if (!date) return;
  const normalizedDate = parseDate(date);
  if (isValid(normalizedDate)) {
    return format(normalizedDate, dateFormat);
  }
};

export const normalizeTime = (date: string) => {
  if (!date) return;
  const normalizedDate = new Date(date);
  if (isValid(normalizedDate)) {
    return format(normalizedDate, 'hh:mm a');
  }
};

export const parseDate = (dateString: string) => {
  const date = parse(dateString.slice(0, 10), 'yyyy-MM-dd', new Date());
  if (isValid(date)) {
    return date;
  }
};

export const transformToBackendDate = (date: string) => {
  if (!date) return;
  const normalizedDate = parseDate(date);
  if (isValid(normalizedDate)) {
    return format(normalizedDate, 'yyyy-MM-dd');
  }
};
export const parseDateString = (value, originalValue) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
};

export const capitalizeFirstLetter = (data: string) => {
  if (data) return data.charAt(0).toUpperCase() + data.slice(1);
  return '';
};

const timeZoneLabel = (value) => {
  const hour = padStart(String(Math.abs(value)), 2, '0');
  const prefix = value > 0 ? '+' : value < 0 ? '-' : '';
  return `UTC (${prefix}${hour}:00)`;
};

export const timeZoneItems = range(-12, 13).map((x) => ({
  label: timeZoneLabel(x),
  value: String(x)
}));

export const timeConverter = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return;

  const startTime = format(new Date(startDate.replace('Z', '')), 'hh:mm a');
  const endTime = format(new Date(endDate.replace('Z', '')), 'hh:mm a');
  return `${startTime} - ${endTime}`;
};

export const dateConverter = (start: string, end: string) => {
  if (!start || !end) return;

  const startDate = format(new Date(start.replace('Z', '')), 'MM/dd/yyyy');
  const endDate = format(new Date(end.replace('Z', '')), 'MM/dd/yyyy');
  return `${startDate} - ${endDate}`;
};

export const dateFromTime = (time: string) => {
  if (!time) return;
  if (isDate(time)) return time;
  const date = new Date();
  const [hours, minutes] = time.split(':');
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date;
};

export const transformAvailability = (data: any) => {
  const therapists = {};

  data.therapists.forEach((therapist) => {
    therapists[therapist.uuid] = [];
    therapist.availability.reduce((acc, item) => {
      const fromHours = format(new Date(item.from), 'H');
      const toHours = format(new Date(item.to), 'H');
      const fromMinutes = format(new Date(item.from), 'm');
      const toMinutes = format(new Date(item.to), 'm');
      const from = +fromHours * 60 + +fromMinutes;
      const to = +toHours * 60 + +toMinutes;
      therapists[therapist.uuid].push({
        from: from,
        to: to <= from ? to + 24 * 60 : to,
        isBusy: item.isBusy
      });

      return acc;
    }, []);
  });

  const formattedTherapists = {};

  Object.keys(therapists).forEach((therapist) => {
    formattedTherapists[therapist] = therapists[therapist].sort(
      (a, b) => a.from - b.from
    );
  });

  const availabilities = Object.keys(therapists).reduce((acc, item) => {
    acc[item] = {
      isBusy: [],
      isNotBusy: []
    };
    return acc;
  }, {});

  ['isBusy', 'isNotBusy'].forEach((status) => {
    Object.keys(formattedTherapists).forEach((therapist) => {
      formattedTherapists[therapist]
        .filter((item) => (status === 'isBusy' ? item.isBusy : !item.isBusy))
        .forEach((item) => {
          if (!availabilities[therapist][status].length) {
            availabilities[therapist][status].push(item);
          } else {
            const intersection = availabilities[therapist][status].find(
              (availability) =>
                item.from <= availability.to && item.to >= availability.to
            );
            if (intersection) {
              intersection.to = item.to;
            } else if (
              availabilities[therapist][status].every(
                (availability) => item.from > availability.to
              )
            ) {
              availabilities[therapist][status].push(item);
            }
          }
        });
    });
  });

  const finalAvailabilities = Object.keys(formattedTherapists).reduce(
    (acc, item) => {
      acc[item] = {
        isBusy: [...availabilities[item].isBusy],
        isNotBusy: []
      };
      return acc;
    },
    {}
  );

  Object.keys(availabilities).forEach((therapist) => {
    if (!availabilities[therapist].isBusy.length) {
      finalAvailabilities[therapist].isNotBusy =
        availabilities[therapist].isNotBusy;
    }
    availabilities[therapist].isNotBusy.forEach((notBusyTime) => {
      if (
        availabilities[therapist].isBusy.some(
          (item) => item.from <= notBusyTime.from && item.to >= notBusyTime.to
        )
      ) {
        return;
      }

      availabilities[therapist].isBusy.forEach((busyTime) => {
        if (
          finalAvailabilities[therapist].isNotBusy.some(
            (item) =>
              item.to === notBusyTime.to && item.from === notBusyTime.from
          )
        ) {
          return;
        }
        if (
          notBusyTime.from < busyTime.from &&
          notBusyTime.to < busyTime.from
        ) {
          if (
            availabilities[therapist].isBusy.some(
              (item) =>
                item.from >= notBusyTime.from && item.to <= notBusyTime.to
            )
          ) {
            return;
          }
          finalAvailabilities[therapist].isNotBusy.push(notBusyTime);
        } else if (
          notBusyTime.from > busyTime.to &&
          notBusyTime.to > busyTime.to
        ) {
          if (
            availabilities[therapist].isBusy.some(
              (item) =>
                item.from >= notBusyTime.from && item.to <= notBusyTime.to
            )
          ) {
            return;
          }
          finalAvailabilities[therapist].isNotBusy.push(notBusyTime);
        } else {
          if (notBusyTime.from < busyTime.from) {
            finalAvailabilities[therapist].isNotBusy.push({
              from: notBusyTime.from,
              to: busyTime.from,
              isBusy: false
            });
          }
          if (notBusyTime.to > busyTime.to) {
            finalAvailabilities[therapist].isNotBusy.push({
              from: busyTime.to,
              to: notBusyTime.to,
              isBusy: false
            });
          }
        }
      });
    });
  });
  return finalAvailabilities;
};

export const transformEmployeeAppointments = (
  data: any,
  employeeUuid?: string
) => {
  if (data) {
    return data
      .filter((item: any) =>
        employeeUuid
          ? item.primaryTherapist.uuid === employeeUuid ||
            item.secondaryTherapist.uuid === employeeUuid
          : true
      )
      .map((item) => ({
        ...item,
        start: item.startDate.replace('UTC', 'Z'),
        end: item.endDate.replace('UTC', 'Z'),
        firstName: item.client.firstName,
        lastName: item.client.lastName,
        therapist: {
          avatar: 'none', //API NOT PROVIDING
          name: [
            item.primaryTherapist.firstName,
            item.primaryTherapist.lastName
          ]
            .filter(Boolean)
            .join(' ')
        },
        clientFullName: `${item.client.firstName} ${item.client.lastName}`,
        date: normalizeDate(item.startDate) || '-',
        time: timeConverter(item.startDate, item.endDate),
        location: item.location.name,
        status: {
          label: item.status,
          value: item.status
        },
        provider: '-',
        type: item.servicePlace.appointmentType.title,
        serviceCode: item.servicePlace.code,
        serviceDescription: '-'
      }));
  } else {
    return [];
  }
};

export const transformAppointments = (data: any, id?: string) => {
  if (data) {
    return data
      .filter((item: any) => (id ? item.client.uuid === id : true))
      .map((item) => ({
        ...item,
        start: item.startDate.replace('UTC', 'Z'),
        end: item.endDate.replace('UTC', 'Z'),
        therapist: {
          avatar: 'none', //API NOT PROVIDING
          name: [
            item.primaryTherapist.firstName,
            item.primaryTherapist.lastName
          ]
            .filter(Boolean)
            .join(' ')
        },
        clientFullName: `${item.client.firstName} ${item.client.lastName}`,
        date: normalizeDate(item.startDate) || '-',
        time: timeConverter(item.startDate, item.endDate),
        location: item.location.name,
        status: {
          label: item.status,
          value: item.status
        },
        provider: '-',
        type: item.servicePlace.appointmentType.title,
        serviceCode: item.servicePlace.code,
        serviceDescription: '-'
      }));
  } else {
    return [];
  }
};

export const clearNullishObject = (data: any) => {
  if (data) {
    return Object.keys(data).reduce((acc, key) => {
      if (data[key] !== null && data[key] !== undefined) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
  }
  return {};
};

export const urlParamsToObject = (urlParams: string) => {
  const params = new URLSearchParams(urlParams);
  const obj = Object.fromEntries(params.entries());

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key] as string;
    if (value === '') {
      return acc;
    } else if (value === 'null') {
      acc[key] = null;
    } else if (isStringBoolean(value)) {
      acc[key] = stringToBoolean(value);
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export const compose =
  (...fns) =>
  (x) =>
    fns.reduceRight((res, fn) => fn(res), x);

export const diffObjectsValues = (obj1, obj2, keys: string[]) => {
  return keys.reduce((acc, key) => {
    if (obj1[key] !== obj2[key]) {
      acc[key] = obj1[key];
    }
    return acc;
  }, {});
};

export const getObjectValues = (obj, keys: string[]) => {
  return keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
};

export const normalizeDuration = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return;
  const start = new Date(startDate);
  const end = new Date(endDate);
  const duration = differenceInMinutes(end, start);
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours > 0 ? hours + ' hour(s)' : ' '} ${
    minutes > 0 ? minutes + ' minutes' : ''
  }`;
};

export const clearHtml = (text: string) => {
  if (!text) return;
  return text.replace(/<(?:.|\n)*?>/gm, '');
};
