import { Expose, Transform } from 'class-transformer';
import get from 'lodash/get';

export class EmployeeReminderDto {
  @Expose()
  uuid: string;

  @Expose()
  title: string;

  @Expose()
  dueDateTime: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'taskType.uuid'))
  taskTypeUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'employee.uuid'))
  employeeUuid: string;

  @Expose()
  repeatsInDays: string[];

  @Expose()
  repeatsInMonth: string[];

  @Expose()
  isTextNotificationsEnabled: boolean;

  @Expose()
  isEmailNotificationsEnabled: boolean;

  @Expose()
  file: any;
}
