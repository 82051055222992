import { SelectField } from '../select-field';
import { FC } from 'react';
import map from 'lodash/map';
import { SelectFieldProps } from '../select-field/SelectField';
import { ServiceDescriptionDto } from '../../../core/http';
import { useServiceDescription } from '../../../core/hooks/useServiceDescription';

export const ServiceDescriptionField: FC<Omit<SelectFieldProps, 'items'>> = ({
  value,
  ...props
}) => {
  const { items } = useServiceDescription();
  const selectItems = map(items, (item: ServiceDescriptionDto) => ({
    value: item.uuid,
    label: item.description
  }));

  return <SelectField value={value || ''} items={selectItems} {...props} />;
};
