import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'header'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: '#F9FBFE',
      color: theme.palette.text.primary,
      border: 0,
      borderRadius: 0
    },
    toolbar: {
      height: 56,
      padding: theme.spacing(1.5, 4),
      minHeight: 56
    }
  }),
  options
);

export default useStyles;
