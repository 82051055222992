import { plainToClass } from 'class-transformer';
import {
  API_HOLIDAY_CREATE,
  API_HOLIDAY_DELETE,
  API_HOLIDAY_FIND_ALL,
  API_HOLIDAY_FIND_ONE,
  API_HOLIDAY_UPDATE
} from '../api.routes';
import { BaseApi } from './base.api';
import { HolidayDto } from '../dto';
import { FindAllResponse } from '../types';

export class HolidayApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<HolidayDto>> {
    return this.httpClient
      .get(API_HOLIDAY_FIND_ALL, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(HolidayDto, data.items)
        };
      });
  }

  /**
   * Find one
   * @param uuid
   */
  public findOne(uuid: string): Promise<HolidayDto> {
    return this.httpClient
      .get(API_HOLIDAY_FIND_ONE, { pathParams: { uuid } })
      .then(({ data }) => {
        return plainToClass(HolidayDto, data);
      });
  }

  /**
   * Create
   * @param data
   */
  public create(data): Promise<HolidayDto> {
    return this.httpClient.post(API_HOLIDAY_CREATE, data).then(({ data }) => {
      return plainToClass(HolidayDto, data);
    });
  }

  /**
   * Update
   * @param data
   */
  public update(data): Promise<HolidayDto> {
    return this.httpClient.put(API_HOLIDAY_UPDATE, data).then(({ data }) => {
      return plainToClass(HolidayDto, data);
    });
  }

  /**
   * Delete
   * @param uuid
   */
  public delete(uuid): Promise<void> {
    return this.httpClient.delete(API_HOLIDAY_DELETE, {
      pathParams: { uuid }
    });
  }
}
