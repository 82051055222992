export const internalWorkTypeColumns = [
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    cellProps: {
      sx: {
        width: '100%'
      }
    }
  }
];
