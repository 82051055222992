import { AppointmentCancelTypeStatusContainer } from '../../features/master-data/appointment-cancel-type-status/AppointmentCancelTypeStatusContainer';
import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';

export const MasterDataAppointmentCancelTypeStatus = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Appointment Cancel Type Statuses'
  })
)(AppointmentCancelTypeStatusContainer);
