import { PageHeader } from '../../../components/page-header';
import { Button, IconButton, Stack } from '@mui/material';
import { SearchField } from '../../../components/fields';
import { useDialog } from '../../../core/hooks/useDialog';
import { NotificationMessagesEditDialog } from './NotificationMessagesEditDialog';
import { IconPen, IconTrash } from '../../../components/icons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { useLayout } from '../../../core/hooks/useLayout';
import { ROUTE_MASTER_DATA } from '../../../constants/route.paths';
import { groupBy, map } from 'lodash';
import { notificationMessagesColumns } from './_mockdata';
import { Card } from '../../../components/cards';
import { TableView } from '../../../components/table-view';
import { useListResource } from '../../../core/hooks/useListResource';
import { NotificationMessageApi } from '../../../core/http';
import get from 'lodash/get';
import { NOTIFICATION_MESSAGE_GROUP } from '../../../constants/enums';

const notificationMessageApi = new NotificationMessageApi();

export const NotificationMessagesContainer = () => {
  const [openEdit, closeEdit] = useDialog(NotificationMessagesEditDialog);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  useLayout({
    backButton: {
      title: 'Master Data',
      path: ROUTE_MASTER_DATA
    }
  });

  const {
    data: notificationData,
    isLoading,
    fetchData,
    deleteRecord,
    handleChangeParams
  } = useListResource(notificationMessageApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  const handleOpenEdit =
    (uuid = '') =>
    () => {
      openEdit({
        uuid,
        onSuccess: () => {
          fetchData();
          closeEdit();
        }
      });
    };

  const items = get(notificationData, 'items', []);

  const data = groupBy(items, 'group');

  return (
    <>
      <PageHeader title="Notification Messages" />

      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Button onClick={handleOpenEdit()}>Add new</Button>
        <SearchField
          placeholder="Search"
          sx={{ maxWidth: 400 }}
          onChange={(value) => {
            handleChangeParams('search', value || null);
          }}
        />
      </Stack>

      {map(NOTIFICATION_MESSAGE_GROUP, (items, groupName) => {
        return (
          <Card
            key={groupName}
            title={get(NOTIFICATION_MESSAGE_GROUP, groupName, groupName)}
            p={3}
            mb={1}
          >
            <TableView
              loadingSkeletonCount={2}
              loading={isLoading}
              showEmptyState
              EmptyStateProps={{
                description: null
              }}
              showPagination={false}
              showTablePerPage={false}
              keyExtractor={(record) => record.uuid}
              columns={[
                ...notificationMessagesColumns,
                {
                  title: '',
                  dataIndex: 'actions',
                  key: 'actions',
                  cellProps: {
                    width: 40,
                    sx: {
                      verticalAlign: 'top'
                    }
                  },
                  render: (_, { uuid }) => (
                    <Stack direction="row" alignItems="center">
                      <IconButton size="small" onClick={handleOpenEdit(uuid)}>
                        <IconPen />
                      </IconButton>
                      <IconButton size="small" onClick={handleDelete(uuid)}>
                        <IconTrash />
                      </IconButton>
                    </Stack>
                  )
                }
              ]}
              dataSource={get(data, groupName, [])}
            />
          </Card>
        );
      })}
    </>
  );
};
