import { arrayFromLength } from '../../core/utils/data.utils';
import { addHours, format } from 'date-fns';
import { HOUR_FORMAT } from './constants';
import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import { FC } from 'react';

interface Props {
  hours: number;
  startDate: Date;
  startHour: number;
  positions: Record<string, number>;
}

export const TimeSlots: FC<Props> = ({
  hours,
  startDate,
  startHour,
  positions
}) => {
  return (
    <>
      {arrayFromLength(hours).map((_, hourIndex) => {
        const currentHour = addHours(startDate, startHour + hourIndex);
        const prevHour = addHours(startDate, startHour + hourIndex - 1);
        const prevHourKey = format(prevHour, HOUR_FORMAT);

        return (
          <Box
            key={hourIndex}
            sx={{
              position: 'absolute',
              top: get(positions, prevHourKey),
              right: 4
            }}
          >
            <Typography
              variant="body2"
              fontWeight={500}
              textAlign="right"
              color="#496492"
            >
              {format(currentHour, 'hh:mm')}
              <br />
              {format(currentHour, 'aa')}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};
