import { PageHeader } from '../../../components/page-header';
import { useLayout } from '../../../core/hooks/useLayout';
import {
  ROUTE_MASTER_DATA,
  ROUTE_MASTER_DATA_CREATE_SERVICE_CODE,
  ROUTE_MASTER_DATA_EDIT_SERVICE_CODE
} from '../../../constants/route.paths';
import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { EnumField, ServiceTypeField } from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { serviceCodeColumns } from './_mockdata';
import { IconPen, IconTrash } from '../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../core';
import { useDialog } from '../../../core/hooks/useDialog';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { Card } from '../../../components/cards';
import { useListResource } from '../../../core/hooks/useListResource';
import { ServiceCodeApi } from '../../../core/http';
import get from 'lodash/get';
import { PAYABLE_TYPE, SERVICE_CODE_TYPE } from '../../../constants/enums';
import { useState } from 'react';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { forceFetchServiceCode } from '../../../core/store/slices/serviceCodeSlice';
import { useDispatch } from 'react-redux';

const serviceCodeApi = new ServiceCodeApi();

export const ServiceCodeContainer = () => {
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const [filters, setFilters] = useState({});
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch();

  useLayout({
    backButton: {
      title: 'Master Data',
      path: ROUTE_MASTER_DATA
    }
  });

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams
  } = useListResource(serviceCodeApi);

  const handleChangeFilter = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value || undefined }));
  };

  const handleSearch = () => {
    handleChangeParams(filters);
  };

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          dispatch(forceFetchServiceCode());
          showMessage('Record successfully deleted');
          closeConfirm();
        });
      }
    });
  };

  return (
    <>
      <PageHeader title="Service Codes">
        <Button onClick={() => navigate(ROUTE_MASTER_DATA_CREATE_SERVICE_CODE)}>
          Add new
        </Button>
      </PageHeader>
      <Card mb={3}>
        <Grid container spacing={1}>
          <Grid item md={3.33}>
            <TextField
              label="Service Code"
              placeholder="Search"
              size="small"
              helperText={null}
              value={get(filters, 'filter[code][eq]')}
              onChange={(e) =>
                handleChangeFilter('filter[code][eq]', e.target.value)
              }
            />
          </Grid>
          <Grid item md={3.33}>
            <EnumField
              label="Code Type"
              placeholder="Select"
              size="small"
              helperText={null}
              value={get(filters, 'filter[type][eq]')}
              items={SERVICE_CODE_TYPE}
              onChange={(value) =>
                handleChangeFilter('filter[type][eq]', value)
              }
            />
          </Grid>
          <Grid item md={3.33}>
            <EnumField
              label="Payable Type"
              placeholder="Select"
              size="small"
              helperText={null}
              items={PAYABLE_TYPE}
              value={get(filters, 'filter[payableType][eq]')}
              onChange={(value) =>
                handleChangeFilter('filter[payableType][eq]', value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <Stack direction="row" alignItems="flex-end" spacing={1}>
              <ServiceTypeField
                label="Service Type"
                placeholder="Select"
                size="small"
                helperText={null}
                value={get(filters, 'filter[serviceType][eq]')}
                onChange={(value) =>
                  handleChangeFilter('filter[serviceType][eq]', value)
                }
              />
              <Button onClick={handleSearch}>Search</Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...serviceCodeColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, { uuid }) => (
              <Stack direction="row" alignItems="center">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(
                      url(ROUTE_MASTER_DATA_EDIT_SERVICE_CODE, {
                        uuid
                      })
                    );
                  }}
                >
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
