import { ComponentType, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  ROUTE_CLIENT_DETAILS,
  ROUTE_CLIENTS
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import { useLayout } from '../../core/hooks/useLayout';
import { useDialog } from '../../core/hooks/useDialog';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { Badge } from '../../components/badge';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Stack,
  Grid,
  Typography,
  Divider,
  Skeleton
} from '@mui/material';
import {
  TextField,
  SelectField,
  DatePicker,
  FileAttach,
  FundingSourceField,
  NumberField
} from '../../components/fields';
import { useSnackbar } from '../../core/hooks/useSnackbar';
import { ServiceCodeField } from '../../components/fields/service-code-field';
import { LabeledRecord } from '../../components/labeled-record';
import { filter, get, isEmpty, map } from 'lodash';
import { ServiceDescriptionField } from '../../components/fields/service-description-field';
import { useClientDetail } from '../../core/hooks/useClientDetail';
import { useEditClientServiceAuthorization } from '../../core/hooks/useEditClientServiceAuthorization';
import { FundingSourceApi } from '../../core/http';
import { ActiveEmployeeAutocompleteField } from '../../components/fields/active-employee-autocomplete-field';

const getEmptyAuthorization = (authorizationUuid) => ({
  uuid: uuidv4(),
  status: '',
  authorizationUuid,
  serviceDescriptionUuid: '',
  authorizationNumber: '',
  totalAuthUnits: null,
  budgetCode: '',
  accountCode: '',
  maxHourInPeriod: null,
  authorizedFrom: '',
  authorizedTo: '',
  hoursPerPeriodType: '',
  unitLenght: '',
  reportSubmittedDate: '',
  reportDueDate: ''
});

const fundingSourceApi = new FundingSourceApi();

export const ClientServiceAuthorizationForm: ComponentType = () => {
  const navigate = useNavigate();
  const [createUuid] = useState(uuidv4());
  const { uuid, clientUuid } = useParams();
  const { showMessage } = useSnackbar();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  useLayout({
    backButton: {
      title: clientUuid ? 'Client Profile' : 'Clients',
      path: clientUuid
        ? url(ROUTE_CLIENT_DETAILS + '#serviceAuthorization', {
            id: clientUuid
          })
        : url(ROUTE_CLIENTS)
    }
  });

  const handleCancel = () => () => {
    openConfirm({
      title: 'Cancel',
      message: 'Do you really want to cancel ? ',
      onConfirm: () => {
        navigate(
          url(ROUTE_CLIENT_DETAILS + '#serviceAuthorization', {
            id: clientUuid
          })
        );
        closeConfirm();
      }
    });
  };

  const { data: clientData, isLoading: clientLoading } = useClientDetail({
    uuid: clientUuid
  });
  const insuredPersons = filter(
    clientData?.contacts,
    (contact) => contact.type === 'insured'
  );

  const insuranceItems = map(insuredPersons, ({ payer }) => {
    return {
      label: payer.name,
      value: payer.uuid
    };
  });

  const {
    isLoading,
    handleSubmit,
    values,
    errors,
    setFieldValue,
    getFieldTouchedError,
    getFieldValue,
    setFieldTouched,
    handleBlur
  } = useEditClientServiceAuthorization(clientUuid, {
    uuid,
    createUuid,
    normalizeSetValues: (values) => {
      return {
        ...values,
        authorizations: isEmpty(values.authorizations)
          ? [getEmptyAuthorization(values.uuid)]
          : map(values.authorizations, (item) => {
              return {
                ...item,
                serviceCodeUuid: item.serviceCode.uuid,
                serviceDescriptionUuid: item.serviceDescription.uuid
              };
            })
      };
    },
    normalizeValues: (values) => {
      return {
        ...values,
        name: 'test',
        clientUuid: clientUuid,
        authorizations: isEmpty(values.authorizations)
          ? [getEmptyAuthorization(values.uuid)]
          : map(values.authorizations, (item) => {
              return {
                ...item,
                totalAuthUnits: +item.totalAuthUnits,
                maxHourInPeriod: +item.maxHourInPeriod,
                unitLenght: +item.unitLenght
              };
            })
      };
    },
    onSuccess: () => {
      navigate(
        url(ROUTE_CLIENT_DETAILS + '#serviceAuthorization', { id: clientUuid })
      );
      showMessage(
        `Service authorization has been successfully ${
          uuid ? 'updated' : 'created'
        }`
      );
    },
    onError: () => {
      showMessage('Something went wrong, please try again later.', 'error');
    }
  });
  const authorizations = get(values, 'authorizations', []);

  const handleAddAuthorization = () => {
    setFieldValue('authorizations', [
      ...values.authorizations,
      { ...getEmptyAuthorization(uuid || createUuid) }
    ]);
  };

  const handleRemoveAuthorization = (uuid) => {
    setFieldValue(
      'authorizations',
      filter(
        values.authorizations,
        (authorization) => authorization.uuid !== uuid
      )
    );
  };

  const headerProps = {
    divider: false,
    title: uuid ? 'Edit Service Authorization' : 'Add Service Authorization',
    meta: (
      <Stack direction="row" spacing={1}>
        {clientLoading ? (
          <>
            <Skeleton variant="text" sx={{ height: 32, width: 250 }} />
          </>
        ) : (
          <>
            <Badge label={clientData?.uuid} variant={'secondary'} />
          </>
        )}
      </Stack>
    )
  };
  const getFieldProps = (fieldName: string) => {
    return {
      disabled: isLoading,
      errors: getFieldTouchedError(fieldName),
      helperText: getFieldTouchedError(fieldName),
      value: getFieldValue(fieldName),
      onChange: (value) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, value);
      },
      onBlur: () => {
        handleBlur(fieldName);
      }
    };
  };

  useEffect(() => {
    if (!uuid) {
      setFieldValue('authorizations', [getEmptyAuthorization(createUuid)]);
    }
  }, [uuid]);

  useEffect(() => {
    const { fundingSourceUuid } = values;
    if (fundingSourceUuid) {
      fundingSourceApi.findOne(fundingSourceUuid).then((data) => {
        if (!isEmpty(data.contracts)) {
          const rate = data.contracts[0].rate;
          map(authorizations, (_, index) =>
            setFieldValue(`authorizations[${index}].rate`, rate)
          );
        }
      });
    }
  }, [values.fundingSourceUuid]);

  return (
    <>
      <PageHeader {...headerProps}>
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button color="secondary" onClick={handleCancel()}>
          Cancel
        </Button>
      </PageHeader>
      <Card>
        <Grid container spacing={1} mb={1}>
          <Grid item md={2}>
            <FundingSourceField
              {...getFieldProps('fundingSourceUuid')}
              helperText={getFieldTouchedError('fundingSourceUuid')}
              required
              placeholder="Select"
              label="Insurance "
              items={insuranceItems}
            />
          </Grid>
          <Grid item md={10}>
            <ServiceCodeField
              required
              placeholder="Please select service code"
              label="Service Code"
              {...getFieldProps('serviceCodeUuid')}
              helperText={getFieldTouchedError('serviceCodeUuid')}
              errors={getFieldTouchedError(['serviceCodeUuid'])}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container mb={2}>
          <Grid item md={6}>
            <Typography variant="h4" mb={2} mt={2}>
              Vendor Information
            </Typography>
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Email:"
              value="indep@gmail.com"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Vendor No.:"
              value="231239873"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Vendor Name:"
              value="Stars Therapy"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Vendor Phone:"
              value="(321) 321-4323"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Address:"
              value="6910 Santa Teresa Blvd. STE 200"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="City:"
              value="San Jose"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="State:"
              value="California"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Zip Code:"
              value="98432"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              valueProps={{ width: 200 }}
              label="Case worker:"
              value={
                <ActiveEmployeeAutocompleteField
                  {...getFieldProps('employeeUuid')}
                />
              }
              direction="row"
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="h4" mb={2} mt={2}>
              Client Information
            </Typography>
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="ID (MRI/...):"
              value={
                <TextField
                  sx={{ height: '40px' }}
                  {...getFieldProps('clientIdMri')}
                />
              }
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="First Name:"
              value={clientData?.firstName}
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Last Name:"
              value={clientData?.lastName}
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Phone:"
              value={clientData?.phone}
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Birth Date:"
              value={clientData?.birthDate}
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Address:"
              value="6910 Santa Teresa Blvd. STE 200"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="City:"
              value="San Jose"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="State:"
              value="California"
              direction="row"
            />
            <LabeledRecord
              mb={0.4}
              labelProps={{ width: 120 }}
              label="Zip Code:"
              value="98432"
              direction="row"
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} mt={2}>
          <Grid item md={12}>
            <Typography variant="h4" mb={2} mt={2}>
              File
            </Typography>
            <FileAttach />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2}>
          <Grid item md={4}>
            <TextField label="File Name" />
          </Grid>
          <Grid item md={8}>
            <TextField label="Description" />
          </Grid>
        </Grid>
      </Card>
      <Button onClick={handleAddAuthorization}>Add new authorization</Button>
      {map(authorizations, (authorization, index) => (
        <Card
          title="Authorization Information"
          meta={
            <Button
              color="third"
              onClick={() => handleRemoveAuthorization(authorization.uuid)}
            >
              Delete
            </Button>
          }
          key={index}
          mt={2}
        >
          <Grid container spacing={1}>
            <Grid item md={3}>
              <SelectField
                disabled={isLoading}
                label="Status"
                required
                placeholder="Select"
                value={authorization.status}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].status`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'status'
                ])}
                items={[
                  {
                    label: 'Active',
                    value: 'active'
                  }
                ]}
              />
            </Grid>
            <Grid item md={3}>
              <ServiceCodeField
                disabled={isLoading}
                label="Authorization Code"
                required
                value={authorization.serviceCodeUuid}
                placeholder="Please enter service code"
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].serviceCodeUuid`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'serviceCodeUuid'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <ServiceDescriptionField
                disabled={isLoading}
                required
                label="Description of service"
                value={authorization.serviceDescriptionUuid}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].serviceDescriptionUuid`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'serviceDescriptionUuid'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <NumberField
                disabled={isLoading}
                required
                label="Authorization No."
                value={authorization.authorizationNumber}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].authorizationNumber`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'authorizationNumber'
                ])}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} mb={2}>
            <Grid item md={3}>
              <NumberField
                disabled={isLoading}
                required
                label="Total Auth. Units"
                value={authorization.totalAuthUnits}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].totalAuthUnits`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'totalAuthUnits'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                disabled={isLoading}
                required
                label="Budget Code"
                value={authorization.budgetCode}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].budgetCode`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'budgetCode'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                disabled={isLoading}
                required
                label="Account Code"
                value={authorization.accountCode}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].accountCode`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'accountCode'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <NumberField
                disabled={isLoading}
                required
                label="Max Hour in period"
                value={authorization.maxHourInPeriod}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].maxHourInPeriod`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'maxHourInPeriod'
                ])}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} mt={2}>
            <Grid item md={3}>
              <DatePicker
                disabled={isLoading}
                required
                label="Authorization from"
                value={authorization.authorizedFrom}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].authorizedFrom`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'authorizedFrom'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <DatePicker
                disabled={isLoading}
                required
                label="Authorized to"
                value={authorization.authorizedTo}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].authorizedTo`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'authorizedTo'
                ])}
              />
            </Grid>
            <Grid item md={6}>
              <SelectField
                disabled={isLoading}
                required
                label="Hours/Period Type"
                placeholder="Select"
                items={[
                  { label: 'Unit/Month', value: 'month' },
                  { label: 'Unit/Week', value: 'week' },
                  { label: 'Unit/Bi-weekly', value: '2week' },
                  { label: 'Unit/6Month', value: 'half_year' },
                  { label: 'Unit/Yearly', value: 'year' }
                ]}
                value={authorization.hoursPerPeriodType}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].hoursPerPeriodType`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'hoursPerPeriodType'
                ])}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} mt={2}>
            <Grid item md={3}>
              <NumberField
                disabled={isLoading}
                required
                label="Unit Length"
                value={authorization.unitLenght}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].unitLenght`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'unitLenght'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                disabled
                label="Rate"
                value={authorization.rate}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].rate`, value)
                }
                errors={getFieldTouchedError(['authorizations', index, 'rate'])}
              />
            </Grid>
            <Grid item md={3}>
              <NumberField
                label="Max Auth Hours"
                value={authorization.maxAuthorizationHours}
                disabled
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].maxAuthorizationHours`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'maxAuthorizationHours'
                ])}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} mt={2}>
            <Grid item md={3}>
              <DatePicker
                required
                disabled={isLoading}
                label="Report Submitted"
                value={authorization.reportSubmittedDate}
                onChange={(value) =>
                  setFieldValue(
                    `authorizations[${index}].reportSubmittedDate`,
                    value
                  )
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'reportSubmittedDate'
                ])}
              />
            </Grid>
            <Grid item md={3}>
              <DatePicker
                required
                disabled={isLoading}
                label="Report Due"
                value={authorization.reportDueDate}
                onChange={(value) =>
                  setFieldValue(`authorizations[${index}].reportDueDate`, value)
                }
                errors={getFieldTouchedError([
                  'authorizations',
                  index,
                  'reportDueDate'
                ])}
              />
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};
