import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFundingSource } from '../store/slices/fundingSourceSlice';
import { AppState } from '../store/store';
import get from 'lodash/get';

export const useFundingSource = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.fundingSource);

  useEffect(() => {
    dispatch(fetchFundingSource({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
