import { Expose, Transform } from 'class-transformer';
import { FileDto } from './file.dto';
import { UserDto } from './user.dto';
import { EmployeeGroupDto } from './employee-group.dto';
import { get } from 'lodash';

export class AnnouncementDto {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  startOn: string;

  @Expose()
  until: string;

  @Expose()
  description: string;

  @Expose()
  file: FileDto;

  @Expose()
  owner: UserDto;

  @Expose()
  showTo: EmployeeGroupDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'showTo.uuid'))
  showToUuid: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform((_, obj) => {
    return {
      startDate: get(obj, 'startOn'),
      endDate: get(obj, 'until')
    };
  })
  period: any;
}
