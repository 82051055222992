import { Expose } from 'class-transformer';

export class CancellationReportDto {
  @Expose()
  hoursCancelled: string;

  @Expose()
  staffCancellation: string;

  @Expose()
  parentCancellation: number;

  @Expose()
  hoursMakeUp: number;

  @Expose()
  rateAmountCancelled: number;

  @Expose()
  rateAmountMakeUp: number;
}
