import { Card } from '../../../components/cards';
import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import {
  DatePicker,
  EmployeeAutocompleteField
} from '../../../components/fields';
import { TableView } from '../../../components/table-view';
import { caseLoadTackerColumns } from '../_mockdata';
import { IconView } from '../../../components/icons';
import get from 'lodash/get';
import { useListResource } from '../../../core/hooks/useListResource';
import { CaseLoadTrackerReportApi } from '../../../core/http/requests/case-load-tracker-report';
import { useFilter } from '../../../core/hooks/useFilter';

const caseLoadTrackerReportApi = new CaseLoadTrackerReportApi();

export const CaseLoadTracker = () => {
  const {
    data,
    pagination,
    isLoading,
    handleChangePage,
    handleChangePerPage,
    handleChangeSort,
    handleChangeParams
  } = useListResource(caseLoadTrackerReportApi);

  const { setFilterValue, getFilterValue, handleSearch } = useFilter({
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });

  return (
    <>
      <Card mb={3}>
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <Box width={150}>
            <DatePicker
              label="From"
              size="small"
              helperText={null}
              value={getFilterValue('filter[startDate][gt]')}
              onChange={setFilterValue('filter[startDate][gt]')}
            />
          </Box>
          <Box width={150}>
            <DatePicker
              label="To"
              size="small"
              helperText={null}
              value={getFilterValue('filter[endDate][lt]')}
              onChange={setFilterValue('filter[endDate][lt]')}
            />
          </Box>
          <Box width={380}>
            <EmployeeAutocompleteField
              label="Employee"
              placeholder="Search"
              size="small"
              helperText={null}
              value={getFilterValue('filter[employee.uuid][eq]')}
              onChange={setFilterValue('filter[employee.uuid][eq]')}
            />
          </Box>
          <Button onClick={handleSearch}>Search</Button>
        </Stack>
      </Card>
      <TableView
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        onChangeSort={handleChangeSort}
        keyExtractor={(item) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...caseLoadTackerColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: () => (
              <Stack direction="row" alignItems="center">
                <Tooltip title="View" placement="bottom-end">
                  <IconButton size="small">
                    <IconView />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
