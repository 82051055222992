import { Expose, Transform, Type } from 'class-transformer';
import get from 'lodash/get';
import { AppointmentTypeDto } from './appointment-type.dto';
import { EmployeeDto } from './employee.dto';
import { AppointmentStatus } from '../../enums';
import { ClientDto } from './client.dto';
import isEmpty from 'lodash/isEmpty';

export class AppointmentDto {
  @Expose()
  uuid: string;

  @Expose()
  authorizations: any[];

  @Expose()
  @Transform((_, obj) => get(obj, 'client.uuid'))
  clientUuid: string;

  @Expose()
  @Type(() => ClientDto)
  client: ClientDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'primaryTherapist.uuid'))
  primaryTherapistUuid: string;

  @Expose()
  @Type(() => EmployeeDto)
  primaryTherapist: EmployeeDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'secondaryTherapist.uuid'))
  secondaryTherapistUuid: string;

  @Expose()
  @Type(() => EmployeeDto)
  secondaryTherapist: EmployeeDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'servicePlace.uuid'))
  servicePlaceUuid: string;

  @Expose()
  @Transform((_, obj) => get(obj, 'servicePlace.title'))
  servicePlaceTitle: string;

  @Expose()
  state: any;

  @Expose()
  city: any;

  @Expose()
  servicePlace: any;

  @Expose()
  @Transform((_, obj) => get(obj, 'type.uuid'))
  typeUuid: string;

  @Expose()
  type: AppointmentTypeDto;

  @Expose()
  @Transform((_, obj) => get(obj, 'location.uuid'))
  locationUuid: string;

  @Expose()
  location: any;

  @Expose()
  locationString: string;

  @Expose()
  status: AppointmentStatus;

  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  @Transform((_, { startDate, endDate }) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return end ? end.getTime() - start.getTime() : null;
  })
  durationTime: number;

  @Expose()
  isRecurrent: boolean;

  @Expose()
  recurrentPattern: any;

  @Expose()
  recurrentOriginAppointment: boolean;

  @Expose()
  recurrentStartDate: string;

  @Expose()
  recurrentEndDate: string;

  @Expose()
  cancelType: any;

  @Expose()
  cancelReason: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  @Transform((_, { status }) => {
    return [
      AppointmentStatus.Active,
      AppointmentStatus.NotConverted,
      AppointmentStatus.Converted,
      AppointmentStatus.Conflicted
    ].includes(status);
  })
  canEdit: boolean;

  @Expose()
  @Transform((_, { status, authorizations }) => {
    return (
      !isEmpty(authorizations) &&
      [
        AppointmentStatus.Active,
        AppointmentStatus.NotConverted,
        AppointmentStatus.Conflicted
      ].includes(status)
    );
  })
  canConvert: boolean;

  @Expose()
  @Transform((_, { status }) => {
    return [AppointmentStatus.Converted].includes(status);
  })
  canUnConvert: boolean;

  @Expose()
  @Transform((_, { status }) => {
    return [
      AppointmentStatus.Active,
      AppointmentStatus.NotConverted,
      AppointmentStatus.Converted,
      AppointmentStatus.Conflicted
    ].includes(status);
  })
  canDelete: boolean;

  @Expose()
  @Transform((_, { status }) => {
    return [
      AppointmentStatus.Active,
      AppointmentStatus.NotConverted,
      AppointmentStatus.Converted,
      AppointmentStatus.Conflicted
    ].includes(status);
  })
  canCancel: boolean;

  @Expose()
  @Transform((_, { status }) => {
    return [AppointmentStatus.Cancelled].includes(status);
  })
  canView: boolean;

  @Expose()
  @Transform((_, { status, makeUpApUuid }) => {
    return [AppointmentStatus.Cancelled].includes(status) && !makeUpApUuid;
  })
  canMakeUp: boolean;
}
