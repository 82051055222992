import { AuthLayout } from '../../../layouts/auth-layout';
import { TwoFactorRecovery } from '../../../features/auth/TwoFactorRecovery';
import { Helmet } from 'react-helmet';

export const TwoFactorRecoveryPage = () => {
  return (
    <AuthLayout>
      <Helmet>
        <title>Splashworks: Two Factor Authentication</title>
      </Helmet>
      <TwoFactorRecovery />
    </AuthLayout>
  );
};
