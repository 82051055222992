import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLanguage } from '../store/slices/languageSlice';
import { AppState } from '../store/store';
import get from 'lodash/get';

export const useLanguage = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.language);

  useEffect(() => {
    dispatch(fetchLanguage({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
