import { makeStyles } from '@mui/styles';

const options = {
  name: 'week-arrow-picker'
};

const useStyles = makeStyles(
  () => ({
    root: {
      position: 'relative',
      minWidth: '170px'
    },
    buttonsContainer: {
      position: 'absolute',
      top: '4px',
      left: '4px',
      zIndex: 10
    },
    roundIconButton: {
      borderRadius: '6px'
    },
    datePicker: {
      pointerEvents: 'none',
      '& .MuiOutlinedInput-input': {
        paddingLeft: '56px',
        fontSize: '12px'
      }
    }
  }),
  options
);

export default useStyles;
