import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { DashboardsSelect } from '../../features/dashboards/DashboardsSelect';

export const DashboardsSelectPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Dashboards'
  })
)(DashboardsSelect);
