import { Expose } from 'class-transformer';
import { FundingSourceContractDto } from './funding-source-contract.dto';

export class FundingSourceDto {
  @Expose()
  uuid: string;

  @Expose()
  name: string;

  @Expose()
  contracts: FundingSourceContractDto[];
}
