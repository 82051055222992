import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'badge'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: 12,
      fontWeight: 500,

      padding: theme.spacing(0.55, 1.1),
      backgroundColor: '#CEF9FF',
      borderRadius: 4,
      alignItems: 'center'
    },
    textUppercase: {
      textTransform: 'uppercase'
    },
    circle: {
      borderRadius: 40
    },
    primary: {
      backgroundColor: '#CEF9FF'
    },
    secondary: {
      backgroundColor: '#EAF1FF',
      color: '#496492'
    },
    warning: {
      backgroundColor: '#FFF6E0',
      color: '#D1A621'
    },
    error: {
      backgroundColor: '#FFE0E0',
      color: '#EB5757',
      '& .MuiSvgIcon-root': {
        '& Path': {
          fill: '#EB5757'
        }
      }
    },
    default: {
      backgroundColor: '#ECF0F7'
    },
    info: {
      backgroundColor: '#EAF1FF'
    },
    success: {
      backgroundColor: '#E0FFED',
      color: '#219653',
      '& .MuiSvgIcon-root': {
        '& Path': {
          fill: '#6FCF97'
        }
      }
    },

    sizeSmall: {
      height: 24,
      padding: theme.spacing(0, 0.5)
    }
  }),
  options
);

export default useStyles;
