import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/store';
import get from 'lodash/get';
import { fetchEmployeeGroup } from '../store/slices/employeeGroupSlice';

export const useEmployeeGroup = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.employeeGroup);

  useEffect(() => {
    dispatch(fetchEmployeeGroup({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
