import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { ProductivityDto } from '../dto/productivity.dto';

export class EmployeeProductivityApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<ProductivityDto>> {
    return this.httpClient
      .get(`/api/v1/employee/${params.employeeUuid}/productivity`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ProductivityDto, data.items)
        };
      });
  }
}
