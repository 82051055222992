import { DataTableColumn } from '../../components/table-view/TableView';
import { Status } from '../../components/status';
import { Tooltip } from '@mui/material';
import { UserRecord } from '../../components/user-record';
import { Badge } from '../../components/badge';
import {
  normalizeDate,
  usPhoneNumber,
  url,
  capitalizeFirstLetter
} from '../../core';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { CLIENT_STATUS_VARIANTS } from '../../constants/enums';
import Stack from '@mui/material/Stack';
import map from 'lodash/map';
import { ROUTE_EMPLOYEE_DETAILS } from '../../constants/route.paths';
import { Link } from 'react-router-dom';

export const familyCommitmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    cellProps: {
      width: 90
    }
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode',
    cellProps: {
      width: 100
    }
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription',
    cellProps: {
      width: 142
    }
  },
  {
    title: 'Serv. Auth. Exp.',
    dataIndex: 'serviceAuthExpiring',
    key: 'serviceAuthExpiring',
    cellProps: {
      width: 112
    }
  },
  {
    title: 'Auth. Hours (wkly)',
    dataIndex: 'authHoursWkly',
    key: 'authHoursWkly',
    cellProps: {
      width: 124
    }
  },
  {
    title: 'Family Available (wkly)',
    dataIndex: 'familyAvailableWkly',
    key: 'phone',
    cellProps: {
      width: 148
    }
  },
  {
    title: '% Family Available per Hour',
    dataIndex: 'familyAvailablePerHour',
    key: 'familyAvailablePerHour',
    cellProps: {
      width: 569
    }
  }
];

export const familyCommitmentData = [
  {
    id: 0,
    date: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 1,
    date: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 2,
    date: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 3,
    date: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 4,
    date: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  }
];

export const clientFamilyCommitmentData = [
  {
    id: 0,
    createdDate: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 1,
    createdDate: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 2,
    createdDate: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 3,
    createdDate: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  },
  {
    id: 4,
    createdDate: '12/22/2022',
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    serviceAuthExpiring: '12/22/2022',
    authHoursWkly: '12',
    familyAvailableWkly: '23',
    familyAvailablePerHour: '12'
  }
];
export const clientFamilyCommitmentColumns: DataTableColumn[] = [
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    sortable: true,
    cellProps: {
      width: 130
    }
  },
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode',
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription',
    cellProps: {
      width: 142
    }
  },
  {
    title: 'Serv. Auth. Exp.',
    dataIndex: 'serviceAuthExpiring',
    key: 'serviceAuthExpiring',
    cellProps: {
      width: 120
    }
  },
  {
    title: 'Auth. Hours (wkly)',
    dataIndex: 'authHoursWkly',
    key: 'authHoursWkly',
    cellProps: {
      width: 130
    }
  },
  {
    title: 'Family Available (wkly)',
    dataIndex: 'familyAvailableWkly',
    key: 'phone',
    cellProps: {
      width: 155
    }
  },
  {
    title: '% Family Available per Hour',
    dataIndex: 'familyAvailablePerHour',
    key: 'familyAvailablePerHour',
    cellProps: {
      width: 570
    }
  }
];
export const clientAppointmentsColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sortable: true
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    sortable: true
  },
  {
    title: 'Therapist',
    dataIndex: 'therapist',
    key: 'therapist',
    render: (user) => <UserRecord user={user} color="common.dark" />
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: ({ label, type }) => (
      <Badge
        label={label}
        variant={type}
        sx={{
          fontWeight: '500 !important'
        }}
      />
    )
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  }
];

export const careTeamMembersColumns: DataTableColumn[] = [
  {
    title: 'Employee Name',
    dataIndex: 'employee',
    key: 'employee',
    render: (employee) => (
      <Link
        to={url(ROUTE_EMPLOYEE_DETAILS, { id: employee.uuid })}
        style={{ textDecoration: 'initial' }}
      >
        <UserRecord
          user={{
            name: employee.firstName + ' ' + employee.lastName,
            avatar: null
          }}
          color="common.dark"
        />
      </Link>
    ),
    cellProps: {
      width: 193
    }
  },
  {
    title: 'Theraphist',
    dataIndex: 'employee',
    key: 'therapist',
    cellProps: {
      width: 193
    },
    render: (employee) => (
      <UserRecord
        user={{
          name: employee.firstName + ' ' + employee.lastName,
          avatar: null
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'employee.title.title',
    key: 'title'
  },
  {
    title: 'Profit Center',
    dataIndex: 'employee.profitCenter.profitCentre',
    key: 'profitCenter'
  },
  {
    title: 'Funding Source',
    dataIndex: 'fundingSource.name',
    key: 'fundingSource'
  },
  {
    title: 'Phone',
    dataIndex: 'employee.contactInfo.phoneNumberPrimary',
    key: 'phone'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceCodes',
    key: 'serviceDescription',
    render: (serviceCodes) => {
      return map(serviceCodes, 'description').join(', ');
    }
  }
];

export const careTeamMembersData = [
  {
    id: 0,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 0,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 0,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 0,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 1,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 2,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 3,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  },
  {
    id: 4,
    title: 'Scheduling Coordinator',
    employee: {
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      name: 'Theresa Webb'
    },
    theraphist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    profitCenter: 'San Jose',
    fundingSource: 'SARC',
    phone: '97151',
    serviceDescription: 'ABA Assessment'
  }
];

export const clientAuthorizationsColumns: DataTableColumn[] = [
  {
    title: 'Service Code',
    dataIndex: 'authorization.serviceCode.code',
    key: 'code',
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Service Description',
    dataIndex: 'authorization.serviceDescription.description',
    key: 'serviceDescription',
    cellProps: {
      width: 150
    }
  },
  {
    title: 'Vendor Name',
    dataIndex: 'fundingSource',
    key: 'vendor',
    cellProps: {
      width: 200
    },
    render: (fundingSource) => fundingSource.name
  },
  {
    title: 'Authorization Period',
    dataIndex: 'authorization',
    key: 'period',
    sortable: true,
    cellProps: {
      width: 150
    },
    render: (authorization) =>
      normalizeDate(authorization.authorizedFrom) +
      '-' +
      normalizeDate(authorization.authorizedTo)
  },
  {
    title: 'Due Date',
    dataIndex: 'authorization.reportDueDate',
    key: 'dueDate',
    sortable: true,
    cellProps: {
      width: 110
    },
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortable: true,
    cellProps: {
      width: 158
    },
    render: (value) => normalizeDate(value)
  }
];

export const clientAuthorizationsData = [
  {
    id: 0,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 1,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 2,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 3,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 4,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 5,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 6,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 7,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 8,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 9,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 10,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  },
  {
    id: 11,
    code: 97151,
    serviceDescription: 'ABA Assessment',
    vendor: 'Stars Therapy',
    period: '01/28/2020-01/28/2020',
    dueDate: '03/06/2022',
    createdDate: '01/28/2022 02:05 PM'
  }
];

export const availabilityDataColumns: DataTableColumn[] = [
  {
    title: 'Scheduling',
    dataIndex: 'scheduling',
    key: 'scheduling',
    render: (value) => (
      <Stack>
        {value.weekday.join(',')} (From {value.startTime} to {value.endTime} -
        {value.dateFrom}
        {value.startDate}
        {value.startDate})
      </Stack>
    )
  },
  {
    title: 'Teletherapy',
    dataIndex: 'teletherapyMode',
    key: 'teletherapyMode',
    render: (value) => (value ? 'YES' : 'NO')
  },
  {
    title: 'In-home',
    dataIndex: 'inHomeMode',
    key: 'inHomeMode',
    render: (value) => (value ? 'YES' : 'NO')
  },
  {
    title: 'Changed by',
    dataIndex: 'changedBy',
    key: 'changedBy'
  },
  {
    title: 'Changed Date',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    cellProps: {
      width: 200
    },
    render: (value) =>
      truncate(value || '-', {
        length: 50
      })
  }
];

export const availabilityData = [
  {
    id: 1,
    scheduling:
      'MON,WED,FRI (From 12:00 AM to 12:05 AM - 02/03/2022 to 02/09/2022 )',
    teletherapy: 'YES',
    inHome: 'NO',
    changedDate: '01/28/2022 02:05 PM',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    notes: '-'
  },

  {
    id: 2,
    scheduling:
      'MON,WED,FRI (From 12:00 AM to 12:05 AM - 02/03/2022 to 02/09/2022 )',
    teletherapy: 'NO',
    inHome: 'YES',
    changedDate: '01/28/2022 02:05 PM',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    notes: '-'
  }
];

export const productivityData = [
  {
    key: 'Completion Rate',
    value: 60
  },
  {
    key: 'Cancellation Rate',
    value: 25
  },
  {
    key: 'Staff Cancelation',
    value: 10
  },
  {
    key: 'Parent Cancellation',
    value: 15
  }
];

export const clientLogsColumns: DataTableColumn[] = [
  {
    title: 'Log Type',
    dataIndex: 'logType',
    key: 'logType',
    sortable: true,
    render: ({ label, type }) => (
      <Badge
        label={label}
        variant={type}
        sx={{
          fontWeight: '500 !important',
          color: 'common.dark',
          backgroundColor: '#E1FFE0'
        }}
      />
    )
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes'
  },
  {
    title: 'Changed by',
    dataIndex: 'changedBy',
    key: 'changedBy',
    render: (user) => <UserRecord user={user} color="common.dark" />
  },
  {
    title: 'Changed Date',
    dataIndex: 'changedDate',
    key: 'changedDate',
    sortable: true
  }
];

export const clientLogsData = [
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes: 'Test',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'ReQuirements',
      type: 'secondary'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'Call log',
      type: 'secondary'
    },
    notes:
      'Parent called scheduling to notify that specialist had not arrived. ',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'Call log',
      type: 'secondary'
    },
    notes: 'Change to ACTIVE. The client made an appointment',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'ReQuirements',
      type: 'secondary'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'Active',
      type: 'success'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'Active',
      type: 'success'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes:
      'Parent called scheduling to notify that specialist had not arrived. ',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  },
  {
    logType: {
      label: 'WARNING NOTES',
      type: 'warning'
    },
    notes: '',
    changedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/12.jpg',
      name: 'Theresa Webb'
    },
    changedDate: '01/28/2022 02:05 PM'
  }
];

export const authExpiringData = [
  {
    id: 0,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '03/06/2022'
  },

  {
    id: 1,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '04/06/2023'
  },
  {
    id: 2,
    serviceCode: '97151',
    serviceDescription: 'ABA Assessment',
    expiringDate: '20/10/2024'
  }
];

export const historyColumns: DataTableColumn[] = [
  {
    title: 'Change Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortable: true,
    render: (createdAt) => normalizeDate(createdAt)
  },
  {
    title: 'Employee Name',
    dataIndex: 'editor',
    key: 'editor',
    render: (editor) => (
      <UserRecord
        user={{ ...editor, name: `${editor.firstName} ${editor.lastName}` }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Field Name',
    dataIndex: 'field',
    key: 'field'
  }
];

export const expiringAuthsColumn: DataTableColumn[] = [
  {
    title: 'Service Code',
    dataIndex: 'serviceCode',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  },

  {
    title: 'Expiring Date',
    dataIndex: 'expiringDate',
    key: 'expiringDate',
    sortable: true
  }
];

export const clientsColumn: DataTableColumn[] = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    cellProps: {
      width: 10
    },
    render: (status) => (
      <div style={{ position: 'relative' }}>
        <Tooltip title={capitalizeFirstLetter(status)} placement="bottom-end">
          <div
            style={{
              position: 'absolute',
              zIndex: 999999,
              width: '8px',
              height: '8px',
              left: '0',
              top: '-5px'
            }}
          >
            <Status variant={get(CLIENT_STATUS_VARIANTS, status, 'active')} />
          </div>
        </Tooltip>
      </div>
    )
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true,
    cellProps: {
      width: 69
    }
  },
  {
    title: 'Member',
    dataIndex: 'memberId',
    key: 'memberId',
    sortable: true,
    cellProps: {
      width: 90
    }
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'Birth Date',
    dataIndex: 'birthDate',
    key: 'birthDate',
    sortable: true,
    render: (date: string) => {
      return normalizeDate(date);
    },
    cellProps: {
      width: 110
    }
  },
  {
    title: 'Primary Contact',
    dataIndex: 'contacts',
    key: 'contacts',

    render: (contacts) => {
      const primaryContact = contacts.find(
        (contact: { type: string }) => contact.type === 'primary'
      );
      return `${primaryContact?.firstName || ''} ${
        primaryContact?.lastName || ''
      }`;
    }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    cellProps: {
      width: 150
    },
    render: (phone) => usPhoneNumber(phone)
  },

  {
    title: 'Profit Center',
    dataIndex: 'profitCenter',
    key: 'profitCenter',
    cellProps: {
      width: 240
    }
  },
  {
    title: 'Funding Source',
    dataIndex: 'fundingSource',
    key: 'fundingSource',
    cellProps: {
      width: 240
    }
  }
];

export const filesColumns: DataTableColumn[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortable: true,
    cellProps: {
      sx: {
        width: 100
      }
    }
  },
  {
    title: 'File Name',
    dataIndex: 'fileName',
    key: 'fileName'
  },
  {
    title: 'Uploaded by',
    dataIndex: 'owner',
    key: 'uploadedBy',
    render: (owner) => (
      <UserRecord
        user={{
          name: owner.firstName + ' ' + owner.lastName,
          avatar: null
        }}
        color="common.dark"
      />
    ),

    cellProps: {
      sx: {
        width: 260
      }
    }
  },
  {
    title: 'Change date',
    dataIndex: 'uploadedDate',
    key: 'uploadedDate',
    sortable: true,
    cellProps: {
      sx: {
        width: 260
      }
    }
  }
];

export const filesData = [
  {
    id: 57349,
    type: 'Certificate',
    fileName: 'Certificate 4382420349',
    expirationDate: '-',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 09:45 AM'
  },
  {
    id: 43933,
    type: 'License',
    fileName: 'Licence_43289',
    expirationDate: '12/22/2022',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 09:45 AM'
  }
];

export const messagesColumns: DataTableColumn[] = [
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <Badge label={status} variant={status === 'sent' ? 'success' : 'error'} />
    ),
    cellProps: {
      sx: {
        width: 260
      }
    }
  },
  {
    title: 'Uploaded by',
    dataIndex: 'uploadedBy',
    key: 'uploadedBy',
    render: (uploadedBy) => (
      <UserRecord user={uploadedBy} color="common.dark" />
    ),

    cellProps: {
      sx: {
        width: 260
      }
    }
  },
  {
    title: 'Date',
    dataIndex: 'uploadedDate',
    key: 'uploadedDate',
    sortable: true,
    cellProps: {
      sx: {
        width: 260
      }
    }
  }
];

export const messagesData = [
  {
    id: 57349,
    message:
      'Hello, Debra Lane! You have an appointment on March 23, 2022 at 04:45 AM. Can you ...',
    status: 'sent',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 09:45 AM'
  },
  {
    id: 57329,
    message: 'Hello, Debra Lane! Pogosio is already here.',
    status: 'error',
    uploadedBy: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'John Smith'
    },
    uploadedDate: '12/22/2022 08:45 AM'
  }
];
