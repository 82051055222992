import { DataTableColumn } from '../../components/table-view/TableView';
import { Badge } from '../../components/badge';

export const companiesColumn: DataTableColumn[] = [
  {
    title: 'Company Code',
    dataIndex: 'companyCode',
    key: 'companyCode',
    sortable: true
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: ({ label, value }) => (
      <Badge
        label={label}
        variant={value}
        sx={{
          fontWeight: '500 !important'
        }}
      />
    )
  },
  {
    title: 'Administrator',
    dataIndex: 'administrator',
    key: 'administrator'
  },
  {
    title: 'Employees',
    dataIndex: 'employees',
    key: 'employees'
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
];

export const companiesData = [
  {
    companyCode: '312',
    companyName: 'Diffco',
    status: { label: 'Active', value: 'active' },
    administrator: 'Gagik Gishyan',
    employees: '10/100',
    createdAt: '02/02/2022 01:34 PM'
  },
  {
    companyCode: '15',
    companyName: 'Diffco 2',
    status: { label: 'Inactive', value: 'inactive' },
    administrator: 'Mika Saghyan',
    employees: '10/100',
    createdAt: '02/02/2022 01:34 PM'
  },
  {
    companyCode: '55',
    companyName: 'Diffco 3',
    status: { label: 'Inactive', value: 'inactive' },
    administrator: 'Oleg Melnic',
    employees: '16/100',
    createdAt: '02/02/2022 01:34 PM'
  }
];
