import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Grid
} from '@mui/material';
import { TextField } from '../../../components/fields';
import { useEditClientFamilyCommitment } from '../../../core/hooks/useEditClientFamilyCommitment';
import { ClientFamilyCommitmentApi } from '../../../core/http/requests/client-family-commitment.api';
import { ClientServiceAuthorizationField } from '../../../components/fields/client-service-authorization-field/ClientServiceAuthorizationField';

const api = new ClientFamilyCommitmentApi();

export const AddNewCommitmentDialog = ({
  popDialog,
  onSuccess,
  onError,
  clientUuid,
  uuid
}) => {
  const isEdit = !!uuid;
  const {
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError,
    handleBlur
  } = useEditClientFamilyCommitment(api, {
    uuid: uuid,
    clientUuid,
    normalizeValues: (values) => {
      return {
        ...values
      };
    },
    normalizeSetValues: (values) => {
      return {
        ...values
      };
    },
    onSuccess: onSuccess,
    onError: onError
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          maxWidth: 688
        },
        '& .MuiDialogActions-root': {
          border: 'none'
        },
        '& .MuiOutlinedInput-root': {
          '& .Mui-disabled': {
            color: '#172B4D',
            WebkitTextFillColor: '#172B4D'
          }
        }
      }}
    >
      <DialogTitle>
        {isEdit ? 'Edit Commitment' : 'Add New Commitment'}
      </DialogTitle>
      <DialogContent>
        <Grid container mb={1}>
          <Grid item md={12}>
            <ClientServiceAuthorizationField
              clientUuid={clientUuid}
              required
              placeholder="Select"
              label="Service Authorization"
              value={values.authorizationServiceUuid}
              onChange={handleChange('authorizationServiceUuid')}
              onBlur={handleBlur('authorizationServiceUuid')}
              errors={getFieldTouchedError('authorizationServiceUuid')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} mb={1}>
          <Grid item md={4}>
            <TextField
              value={values.familyAvailable}
              onChange={handleChange('familyAvailable')}
              onBlur={handleBlur('familyAvailable')}
              errors={getFieldTouchedError('familyAvailable')}
              required
              label="Family Available (wkly)"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label="Auth. hours (wkly)"
              required
              value={values.authHours}
              onChange={handleChange('authHours')}
              onBlur={handleBlur('authHours')}
              errors={getFieldTouchedError('authHours')}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              required
              value={values.familyAvailablePerHour}
              onChange={handleChange('familyAvailablePerHour')}
              onBlur={handleBlur('familyAvailablePerHour')}
              errors={getFieldTouchedError('familyAvailablePerHour')}
              label="% Family Available per hour"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <TextField
              value={values.notes}
              onChange={handleChange('notes')}
              onBlur={handleBlur('notes')}
              errors={getFieldTouchedError('notes')}
              multiline={true}
              rows={5.6}
              label="Notes"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="third" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
