import React from 'react';
import { AppBar, Button, IconButton, Stack, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserMenu } from '../../components/user-menu';
import { IconBack, IconNotification } from '../../components/icons';
import { ROUTE_REMINDERS } from '../../constants/route.paths';
import { NotificationDropdown } from '../../features/notifications/NotificationDropdown';

import useStyles from './styles';
import { useLayout } from '../../core/hooks/useLayout';

export const Header = () => {
  const classes = useStyles();
  const { backButton } = useLayout();

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        {backButton && (
          <Button
            component={Link}
            to={backButton?.path}
            onClick={backButton.onClick}
            startIcon={<IconBack />}
            variant="text"
            sx={{
              color: '#496492',
              marginLeft: -1.5,
              fontSize: 12
            }}
          >
            {backButton.title}
          </Button>
        )}
        <Stack direction="row" ml="auto" alignItems="center">
          <Stack direction="row" spacing={1} mr={3}>
            <IconButton size="medium" component={Link} to={ROUTE_REMINDERS}>
              <IconNotification />
            </IconButton>
            <NotificationDropdown />
          </Stack>
          <UserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
