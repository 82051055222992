import { makeStyles } from '@mui/styles';

const options = {
  name: 'grouped-bar-chart'
};

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    indicator: {
      width: 16,
      height: 8,
      borderRadius: 7
    }
  }),
  options
);

export default useStyles;
