import { Button, Stack } from '@mui/material';
import { AppointmentDto } from '../../core/http';
import { FC } from 'react';

interface Props {
  appointment: AppointmentDto;
  handleEdit: (appointment: AppointmentDto) => void;
  handleDelete: (appointment: AppointmentDto) => void;
  handleCancel: (appointment: AppointmentDto) => void;
  handleMakeUp: (appointment: AppointmentDto) => void;
  handleConvert: (appointment: AppointmentDto) => void;
  handleView: (appointment: AppointmentDto) => void;
}

export const AppointmentDrawerActions: FC<Props> = ({
  appointment,
  handleEdit,
  handleCancel,
  handleMakeUp,
  handleConvert
}) => {
  return (
    <Stack direction="row" marginTop="auto" spacing={1}>
      {appointment.canEdit && (
        <Button onClick={() => handleEdit(appointment)}>Edit</Button>
      )}
      {appointment.canCancel && (
        <Button color="secondary" onClick={() => handleCancel(appointment)}>
          Cancel app
        </Button>
      )}
      {appointment.canMakeUp && (
        <Button color="secondary" onClick={() => handleMakeUp(appointment)}>
          Make up
        </Button>
      )}
      {appointment.canDelete && (
        <Button color="secondary" onClick={() => handleConvert(appointment)}>
          Convert
        </Button>
      )}
    </Stack>
  );
};
