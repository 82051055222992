import { useEffect } from 'react';
import { SelectField } from '../select-field';
import map from 'lodash/map';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployee } from '../../../core/store/slices/employeeSlice';
import { AppState } from '../../../core/store/store';
import { EmployeeDto } from '../../../core/http';

export const EmployeeField = ({ value, status = 'all', ...props }) => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.employee);

  useEffect(() => {
    if (status === 'all') {
      dispatch(fetchEmployee({ limit: 1000 }));
    } else if (status === 'active') {
      dispatch(fetchEmployee({ limit: 1000, 'filter[isDeleted][eq]': 0 }));
    } else {
      dispatch(fetchEmployee({ limit: 1000, 'filter[isDeleted][eq]': 1 }));
    }
  }, []);

  const items = get(findAll, 'payload.items', []);
  const loading = get(findAll, 'loading', false);
  const selectItems = map(items, (item: EmployeeDto) => ({
    value: item.uuid,
    label: [item.firstName, item.lastName].filter(Boolean).join(' ')
  }));

  return (
    <SelectField
      disabled={loading}
      label="Employee"
      value={value || ''}
      items={selectItems}
      {...props}
    />
  );
};
