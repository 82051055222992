import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'table-view-header'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},

    cellRoot: {
      cursor: 'pointer'
    },
    cellContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    cellRootClickable: {
      cursor: 'pointer'
    },
    cellRootSelected: {
      backgroundColor: '#E0EDFF'
    },
    cellRootSortableAsc: {
      '& .arrow-bottom': {
        color: theme.palette.primary.main
      }
    },
    cellRootSortableDesc: {
      '& .arrow-top': {
        color: theme.palette.primary.main
      }
    },

    sortingIcon: {
      padding: 0,
      marginLeft: theme.spacing(0.5),
      '& svg': {
        color: '#C7CBE5',
        fontSize: 22
      }
    },
    filteringIcon: {
      marginLeft: 'auto',
      '& svg': {
        color: '#C7CBE5',
        fontSize: 13
      }
    },

    popover: {
      '& .MuiPopover-paper': {
        boxShadow: '0px 8px 8px rgba(46, 58, 89, 0.12)!important',
        borderRadius: '4px'
      }
    },

    filterItem: {
      minWidth: 170,
      padding: theme.spacing(0.8, 1.5),
      fontWeight: 500,
      color: '#131730',
      cursor: 'pointer',

      '&:hover': {
        color: '#12C8C5'
      }
    },

    filterItemSelected: {
      color: '#12C8C5'
    },

    filterClearAll: {
      color: '#6C7294'
    }
  }),
  options
);

export default useStyles;
