import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { LocationDto } from '../dto';

export class ClientLocationsApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<LocationDto>> {
    return this.httpClient
      .get(`/api/v1/client/${params.clientUuid}/locations`)
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(LocationDto, data.items)
        };
      });
  }
}
