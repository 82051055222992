import { Button } from '@mui/material';
import { ComponentType } from 'react';
import ArrowIcon from '../../assets/arrow.svg';

interface Props {
  title?: string;
  onClick: () => void;
}

export const BackButton: ComponentType<Props> = ({
  title,
  onClick,
  ...props
}) => {
  return (
    <Button
      startIcon={<img src={ArrowIcon} />}
      variant="text"
      sx={{ background: '#F9FBFE', color: '#496492' }}
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
};
