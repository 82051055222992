import React from 'react';
import { styled } from '@mui/system';
import clsx from 'clsx';

export const Label = styled(
  ({
    required,
    children,
    className,
    errors
  }: {
    children?: React.ReactNode;
    className?: string;
    required?: boolean;
    errors?: boolean;
  }) => {
    const showRequiredError = errors && required;

    return (
      <label className={clsx(className, showRequiredError ? 'invalid' : '')}>
        {children}
        <span style={errors ? { color: '#EB5757' } : {}}>
          {required ? ' *' : ''}
        </span>
      </label>
    );
  }
)`
  display: block;
  margin-bottom: 4px;
  color: #496492;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
`;
