import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { EmployeeAutocompleteField } from '../../components/fields';
import { IconPen, IconTrash } from '../../components/icons';
import { TableView } from '../../components/table-view';
import { forEmployeesColumn } from './_mockdata';
import { Link } from 'react-router-dom';
import { url } from '../../core';
import { ROUTE_EDIT_EMPLOYEE_REMINDER } from '../../constants/route.paths';
import { DatePicker } from '../../components/fields';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import { useListResource } from '../../core/hooks/useListResource';
import { EmployeeReminderApi } from '../../core/http';
import get from 'lodash/get';
import { TaskTypeField } from '../../components/fields/task-type-field';
import { useFilter } from '../../core/hooks/useFilter';
import { PermissionView } from '../../components/permission-view';
import { ActiveEmployeeAutocompleteField } from '../../components/fields/active-employee-autocomplete-field';

const employeeReminderApi = new EmployeeReminderApi();

export const ForEmployees = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { handleSearch, getFilterFieldProps } = useFilter({
    initialValue: {
      'filter[dueDateTime][eq]': null
    },
    onSearch: (values) => {
      handleChangeParams(values);
    }
  });
  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    handleChangeSort
  } = useListResource(employeeReminderApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  return (
    <>
      <Grid container spacing={1} mb={3}>
        <Grid item md={3.33}>
          <TextField
            label="Title"
            placeholder="Search"
            size="small"
            helperText={null}
            {...getFilterFieldProps('search')}
          />
        </Grid>
        <Grid item md={3.33}>
          <ActiveEmployeeAutocompleteField
            label="Employee"
            placeholder="Search"
            size="small"
            helperText={null}
            {...getFilterFieldProps('filter[employeeUuid][eq]')}
          />
        </Grid>
        <Grid item md={3.33}>
          <TaskTypeField
            label="Task Type"
            placeholder="Select"
            helperText={null}
            size="small"
            {...getFilterFieldProps('filter[taskTypeUuid][eq]')}
          />
        </Grid>
        <Grid item md={2}>
          <Stack direction="row" alignItems="flex-end" spacing={1}>
            <DatePicker
              label="Due Date"
              size="small"
              helperText={null}
              {...getFilterFieldProps('filter[dueDateTime][eq]')}
            />
            <Button onClick={handleSearch}>Search</Button>
          </Stack>
        </Grid>
      </Grid>

      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangeSort={handleChangeSort}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...forEmployeesColumn,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, record) => (
              <Stack direction="row" alignItems="center">
                <PermissionView permission="modifyOtherEmployeeReminders">
                  <IconButton
                    size="small"
                    component={Link}
                    to={url(ROUTE_EDIT_EMPLOYEE_REMINDER, { id: record.uuid })}
                  >
                    <IconPen />
                  </IconButton>
                </PermissionView>
                <PermissionView permission="modifyOtherEmployeeReminders">
                  <IconButton size="small" onClick={handleDelete(record.uuid)}>
                    <IconTrash />
                  </IconButton>
                </PermissionView>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
