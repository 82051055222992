import { useState, ComponentType } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { TableView } from '../../../components/table-view';
import { Card } from '../../../components/cards';
import {
  careTeamMembersColumns,
  expiringAuthsColumn,
  familyCommitmentData,
  familyCommitmentColumns
} from '../_mockdata';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AppointmentCalendarDrawerCard } from '../../../components/appointment-calendar-drawer-card';
import { AppointmentCalendarCard } from '../../../components/appointment-calendar-card';
import { CalendarView } from '../../../components/calendar-view/CalendarView';
import { RadioGroup } from '../../../components/fields/radio-group';
import { WeekRangePicker } from '../../../components/week-range-picker/WeekRangePicker';
import { calendarViewTypeItems } from '../../../constants/app.constants';
import { ProductivityDashboardCard } from '../widgets/ProductivityDashboard';
import { useListResource } from '../../../core/hooks/useListResource';
import { AppointmentDto } from '../../../core/http';
import { get } from 'lodash';
import { ClientCareTeamMembersApi } from '../../../core/http/requests/client-care-team-members.api';
import { useAppointmentList } from '../../../core/hooks/useAppointmentList';
import { AppointmentDrawerActions } from '../../../components/appointment-drawer-actions';
import { clientAppointmentColumns } from '../../../constants/columns';
import { AppointmentActionButtons } from '../../../components/appointment-action-buttons/appointment-action-buttons';

const clientCareTeamMembersApi = new ClientCareTeamMembersApi();

export const Dashboard: ComponentType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentView, setCurrentView] = useState<'calendar' | 'table'>(
    'calendar'
  );

  const {
    isLoading: appointmentIsLoading,
    data: appointmentResponse,
    pagination,
    handleChangePerPage,
    handleChangePage,
    handleChangeSort,
    handleCancel,
    handleDelete,
    handleConvert,
    handleClientView: handleView,
    handleClientEdit: handleEdit,
    handleClientMakeUp: handleMakeUp,
    startDate,
    setStartDate
  } = useAppointmentList({
    clientId: id,
    defaultParams: {
      'filter[client.uuid][eq]': id,
      limit: 5
    }
  });

  const { data: careTeamMembersResponse, isLoading: careTeamMembersLoading } =
    useListResource(clientCareTeamMembersApi, {
      defaultParams: {
        clientUuid: id
      }
    });

  const appointmentsData = get(appointmentResponse, 'items', []);
  const careTeamMembersData = get(careTeamMembersResponse, 'items', []);

  return (
    <>
      <Grid container spacing={1} justifyContent="center" mb={1.5}>
        <Grid item md={6}>
          <ProductivityDashboardCard clientUuid={id} />
        </Grid>
        <Grid item md={6}>
          <Card
            title="Auths Expiring Next Month"
            sx={{
              minHeight: 352
            }}
          >
            <TableView
              showEmptyState
              EmptyStateProps={{
                description: null
              }}
              loading={false}
              showPagination={true}
              showTablePerPage={false}
              dataSource={[]}
              columns={expiringAuthsColumn}
              pagination={{
                page: 0,
                perPage: 10,
                totalPages: 3,
                total: 12
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Card
        mb={1.5}
        px={3}
        py={2}
        sx={{
          height: 540
        }}
      >
        <Stack direction="row" mb={3} justifyContent="space-between">
          <Stack direction="row" spacing={3}>
            <Typography variant="h4">Appointments</Typography>
            <RadioGroup
              items={calendarViewTypeItems}
              defaultValue="calendar"
              onChange={(view) => {
                setCurrentView(view as 'calendar' | 'table');
              }}
            />
          </Stack>
          <WeekRangePicker
            withToday
            onChange={(startDate) => {
              setStartDate(startDate);
            }}
          />
        </Stack>

        {currentView === 'calendar' ? (
          <CalendarView
            contentHeight={400}
            dataSource={appointmentsData}
            groupKey="startDate"
            startDate={startDate}
            renderItem={(item: AppointmentDto, _, selectedItem) => (
              <AppointmentCalendarCard
                item={item}
                selected={selectedItem?.uuid === item.uuid}
              />
            )}
            renderDrawer={(item: AppointmentDto) => (
              <AppointmentCalendarDrawerCard item={item} />
            )}
            renderDrawerFooter={(appointment: AppointmentDto) => (
              <AppointmentDrawerActions
                {...{
                  appointment,
                  handleConvert,
                  handleDelete,
                  handleEdit,
                  handleCancel,
                  handleView,
                  handleMakeUp
                }}
              />
            )}
          />
        ) : (
          <TableView
            loading={appointmentIsLoading}
            showTablePerPage={false}
            showPagination={true}
            onChangePage={handleChangePage}
            pagination={pagination}
            onChangePerPage={handleChangePerPage}
            onChangeSort={handleChangeSort}
            EmptyStateProps={{
              title: 'Appointments has not been found'
            }}
            dataSource={appointmentsData}
            keyExtractor={(item) => item.uuid}
            onRowClick={() => navigate(pathname + '#appointments')}
            columns={[
              ...clientAppointmentColumns,
              {
                title: '',
                dataIndex: 'actions',
                key: 'actions',
                render: (_, appointment: AppointmentDto) => (
                  <AppointmentActionButtons
                    {...{
                      appointment,
                      handleConvert,
                      handleDelete,
                      handleEdit,
                      handleCancel,
                      handleView,
                      handleMakeUp
                    }}
                  />
                )
              }
            ]}
          />
        )}
      </Card>
      <Card title="Care Team member">
        <TableView
          loading={careTeamMembersLoading}
          keyExtractor={(item) => item.id}
          dataSource={careTeamMembersData}
          showEmptyState
          EmptyStateProps={{
            description: null
          }}
          columns={careTeamMembersColumns}
          hideColumns={[
            'employee',
            'fundingSource',
            'phone',
            'serviceDescription'
          ]}
        />
      </Card>

      <Card title="Family commitment">
        <TableView
          loading={false}
          showTablePerPage={false}
          showPagination={false}
          keyExtractor={(item) => item.id}
          dataSource={familyCommitmentData || []}
          columns={familyCommitmentColumns}
        />
      </Card>
    </>
  );
};
