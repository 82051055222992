import {
  compose,
  withAuthorized,
  withSuperAdminLayout,
  withHelmet
} from '../../core';
import { SuperAdminAdministratorEditContainer } from '../../features/super-admin-administrators/SuperAdminAdministratorEdit';

export const SuperAdminAdministratorEdit = compose(
  withAuthorized,
  withSuperAdminLayout,
  withHelmet({
    title: 'Splashworks: Super Admin Administrators'
  })
)(SuperAdminAdministratorEditContainer);
