import { ComponentType } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import { url } from '../../core';
import { PageHeader } from '../../components/page-header';
import { Card } from '../../components/cards';
import { SelectField, TextField, PhoneField } from '../../components/fields';
import { ROUTE_SUPER_ADMIN_ADMINISTRATORS } from '../../constants/route.paths';
import { useLayout } from '../../core/hooks/useLayout';

export const SuperAdminAdministratorEditContainer: ComponentType = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const headerProps = {
    divider: true,
    title: (
      <Typography variant="h2">
        {id ? 'Edit' : 'Add new'} administrator profile
      </Typography>
    )
  };

  const backUrl = url(ROUTE_SUPER_ADMIN_ADMINISTRATORS);

  useLayout({
    backButton: {
      title: 'Administrators',
      path: backUrl
    }
  });

  return (
    <>
      <PageHeader title="" {...headerProps}>
        <Button
          onClick={() => navigate(ROUTE_SUPER_ADMIN_ADMINISTRATORS)}
          color="third"
        >
          Cancel
        </Button>
        <Button>Save</Button>
      </PageHeader>
      <Card title="General Information">
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <TextField required label="Company name:" disabled value="Diffco" />
          </Grid>
          <Grid item md={3}>
            <SelectField
              label="Status"
              required
              items={[
                { label: 'Active', value: 'active' },
                {
                  label: 'Inactive',
                  value: 'inactive'
                }
              ]}
              placeholder="Select"
            />
          </Grid>
        </Grid>
        <Grid container mt={2} spacing={1}>
          <Grid item md={3}>
            <TextField required label="First name:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Last Name:" />
          </Grid>
          <Grid item md={3}>
            <TextField required label="Email address:" />
          </Grid>
          <Grid item md={3}>
            <PhoneField required label="Phone number:" />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
