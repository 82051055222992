import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { AppointmentApi, AppointmentDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const appointmentApi = new AppointmentApi();

export const fetchAppointmentAsync = createFetchAsync(
  'appointment',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await appointmentApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchAppointment = fetchAppointmentAsync.asyncThunk;

export const fetchAppointment = (payload) => (dispatch, getState) => {
  const {
    appointment: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchAppointment(payload));
  }
};

type AppointmentState = {
  findAll: AsyncState<FindAllResponse<AppointmentDto>>;
};

const initialState: AppointmentState = {
  findAll: fetchAppointmentAsync.initialState
};

const appointment = createSlice({
  name: 'appointment',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchAppointmentAsync.extraReducers
  }
});

export default appointment.reducer;
