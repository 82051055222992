import { ComponentType, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  FormControl
} from '@mui/material';
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  signIn,
  resetOrganization,
  resetSignIn
} from '../../core/store/slices/signInSlice';
import {
  ROUTE_RESET_PASSWORD,
  ROUTE_TWO_FACTOR_SETUP_2,
  ROUTE_TWO_FACTOR_RECOVERY,
  ROUTE_CONNECT_TO_ADMINISTRATOR
} from '../../constants/route.paths';
import { url } from '../../core/utils/route.utils';
import useDebounce from '../../core/hooks/useDebounce';
import { AppState } from '../../core/store/store';
import { PasswordField, NumberField } from '../../components/fields';
import { OrganizationApi } from '../../core/http/requests/organization-api';

const organizationApi = new OrganizationApi();

export const SignIn: ComponentType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signInLoading = useSelector(
    (state: AppState) => state.signIn.signIn.loading
  );
  const signInError = useSelector(
    (state: AppState) => state.signIn.signIn.error
  );
  const signInData = useSelector((state: AppState) => state.signIn.signIn.data);

  const organizationDataError = useSelector(
    (state: AppState) => state.signIn.getOrganization.error
  );

  const [organizationData, setOrganizationData] = useState<any>(null);
  const [loadingOrganization, setLoadingOrganization] =
    useState<boolean>(false);
  const classes = useStyles();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setFieldError
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
      organizationCode: ''
    },
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      organizationCode: yup
        .number()
        .required('Company code is Required.')
        .typeError('Company code must contain only numbers.'),
      username: yup
        .string()
        .email('Please enter valid email.')
        .required('Email address is Required.'),
      password: yup
        .string()
        .min(8, ({ min }) => `Password must be at least ${min} characters.`)
        .required('Password is required.')
    }),
    onSubmit: (values) => {
      dispatch(
        signIn({
          values
        })
      );
    }
  });

  useEffect(() => {
    dispatch(resetSignIn());
    dispatch(resetOrganization());
  }, []);

  useEffect(() => {
    if (!signInData || signInData.login !== 'success') return;
    if (signInData.is2faSetupCompleted) {
      dispatch(resetSignIn());
      navigate(url(`${ROUTE_TWO_FACTOR_RECOVERY}`));
    } else {
      dispatch(resetSignIn());
      navigate(url(`${ROUTE_TWO_FACTOR_SETUP_2}`));
    }
  }, [signInData]);

  const debouncedValue: string = useDebounce<string>(
    values.organizationCode,
    500
  );

  useEffect(() => {
    if (debouncedValue && !errors.organizationCode) {
      setLoadingOrganization(true);
      organizationApi
        .findByCode(debouncedValue)
        .then((resp) => {
          setOrganizationData(resp);
          setFieldValue('organizationCode', resp.authId);
          setLoadingOrganization(false);
        })
        .catch(() => {
          setFieldError('organizationCode', 'Wrong code');
          setLoadingOrganization(false);
        });
    }
  }, [debouncedValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.signInRoot}>
        <Box className={classes.block}>
          <Typography variant="h3" mb={3}>
            Sign in
          </Typography>
          <FormControl className={classes.formControl} required>
            <Stack spacing={0.5}>
              {organizationData ? (
                <Box>
                  <Box className={classes.companyBox} px={2} py={2}>
                    <img
                      src={organizationData.logo || null}
                      alt={organizationData.organization}
                      className={classes.companyLogo}
                    />
                    <Typography variant="h3">
                      {organizationData.name}
                    </Typography>
                  </Box>
                  <Box className={classes.companyChangeBox}>
                    <Button
                      variant="text"
                      onClick={() => setOrganizationData(null)}
                    >
                      Not your company?
                    </Button>
                  </Box>
                </Box>
              ) : (
                <NumberField
                  disabled={loadingOrganization}
                  label="Company Code"
                  helperText={
                    (touched.organizationCode && errors.organizationCode) ||
                    organizationDataError ||
                    ' '
                  }
                  value={values.organizationCode}
                  onChange={handleChange('organizationCode')}
                  onBlur={handleBlur('organizationCode')}
                />
              )}
              <TextField
                disabled={signInLoading}
                label="Email"
                helperText={
                  (touched.username && errors.username) || signInError || ' '
                }
                type="email"
                value={values.username}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
              />
              <PasswordField
                disabled={signInLoading}
                value={values.password}
                helperText={touched.password && errors.password}
                label="Password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
              />

              <Stack direction="row" justifyContent="space-between" mt={2}>
                <Button
                  type="submit"
                  disabled={signInLoading || organizationDataError}
                  size="medium"
                >
                  Sign in
                </Button>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => navigate(url(ROUTE_RESET_PASSWORD))}
                >
                  Forgot password?
                </Button>
              </Stack>
            </Stack>
          </FormControl>
        </Box>
      </Box>
      <Box className={classes.connectToAdminBlock}>
        <Button
          variant="text"
          onClick={() => navigate(ROUTE_CONNECT_TO_ADMINISTRATOR)}
        >
          Connect to your administrator
        </Button>
      </Box>
    </form>
  );
};
