import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRole } from '../store/slices/roleSlice';
import { AppState } from '../store/store';
import get from 'lodash/get';

export const useRole = () => {
  const dispatch = useDispatch();
  const { findAll } = useSelector((state: AppState) => state.role);

  useEffect(() => {
    dispatch(fetchRole({ limit: 1000 }));
  }, []);

  return {
    items: get(findAll, 'payload.items', [])
  };
};
