export const employeeGroupColumns = [
  {
    title: 'Title',
    key: 'groupName',
    dataIndex: 'groupName',
    cellProps: {
      sx: {
        width: '30%'
      }
    }
  },
  {
    title: 'Employee Count',
    key: 'employeeCount',
    dataIndex: 'employeeCount'
  }
];
