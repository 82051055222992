import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';
import { OrganizationApi } from '../../http/requests/organization-api';
import { OrganizationDto } from '../../http/dto/organization-dto';

const organizationApi = new OrganizationApi();

export const fetchOrganizationAsync = createFetchAsync(
  'organization',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await organizationApi.findAll(params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchOrganization = fetchOrganizationAsync.asyncThunk;

export const fetchOrganization = (payload) => (dispatch, getState) => {
  const {
    organization: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchOrganization(payload));
  }
};

type OrganizationState = {
  findAll: AsyncState<FindAllResponse<OrganizationDto>>;
};

const initialState: OrganizationState = {
  findAll: fetchOrganizationAsync.initialState
};

const organization = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchOrganizationAsync.extraReducers
  }
});

export default organization.reducer;
