import { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DialogProps } from '../../../core/providers/dialog.provider';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useEditResource } from '../../../core/hooks/useEditResource';
import * as yup from 'yup';
import { EmployeeGroupApi } from '../../../core/http';
import { TextField } from '../../../components/fields';

interface EditDialogProps extends DialogProps {
  uuid?: string;
  onSuccess?: (values) => void;
  onError?: (error) => void;
}

const employeeGroupApi = new EmployeeGroupApi();

export const EmployeeGroupEditDialog: FC<EditDialogProps> = ({
  popDialog,
  uuid,
  onSuccess,
  onError
}) => {
  const {
    isEdit,
    isLoading,
    handleSubmit,
    handleChange,
    values,
    getFieldTouchedError
  } = useEditResource(employeeGroupApi, {
    uuid,
    initialValues: {
      title: '',
      startDate: null,
      endDate: null
    },
    validationSchema: yup.object().shape({
      groupName: yup.string().required('Title is a required field')
    }),
    onSuccess,
    onError
  });

  return (
    <Dialog open onClose={popDialog}>
      <DialogTitle>{isEdit ? 'Edit record' : 'Add new record'}</DialogTitle>
      <DialogContent sx={{ pb: 1.5 }}>
        <TextField
          label="Title"
          value={values.groupName}
          onChange={handleChange('groupName')}
          errors={getFieldTouchedError('groupName')}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={popDialog}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
