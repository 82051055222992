import { makeStyles } from '@mui/styles';

const options = {
  name: 'autocomplete'
};

const useStyles = makeStyles(
  () => ({
    selectedItems: {}
  }),
  options
);

export default useStyles;
