import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { currencyFormatter } from '../../../../core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.plugins.legend.labels.boxHeight = 4;
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

export const options = {
  indexAxis: 'x' as const,
  responsive: true,
  options: {
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      align: 'start' as const,
      labels: {
        font: {
          size: 12,
          weight: '500',
          borderRadius: 20
        }
      }
    },
    title: {
      display: false
    },
    datalabels: {
      display: true,
      formatter: (_, ctx) => {
        const expenditures =
          ctx.chart.data.datasets[ctx.datasetIndex].expenditures[ctx.dataIndex];
        return `${currencyFormatter(expenditures)}`;
      }
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const expenditures =
            context.chart.data.datasets[context.datasetIndex].expenditures[
              context.dataIndex
            ];
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += currencyFormatter(expenditures);
          }
          return label;
        }
      }
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: false,
        drawBorder: false
      }
    },
    yAxis: {
      grid: {
        drawBorder: false,
        display: false
      },
      scaleLabel: {
        display: false
      },
      ticks: {
        display: false
      }
    }
  }
};
