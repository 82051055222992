import { plainToClass } from 'class-transformer';
import { BaseApi } from './base.api';
import { FindAllResponse } from '../types';
import { API_CLIENT_CHANGES } from '../api.routes';
import { ClientChangesDto } from '../dto/client-changes.dto';

export class ClientChangesApi extends BaseApi {
  /**
   * Find all
   */
  public findAll(params): Promise<FindAllResponse<ClientChangesDto>> {
    return this.httpClient
      .get(API_CLIENT_CHANGES, { params })
      .then(({ data }) => {
        return {
          ...data,
          items: plainToClass(ClientChangesDto, data.items)
        };
      });
  }
}
