import { createSlice } from '@reduxjs/toolkit';
import { AsyncState } from '../types';
import { LocationApi, LocationDto } from '../../http';
import { FindAllResponse } from '../../http/types';
import { createFetchAsync } from '../slice.utils';

const locationApi = new LocationApi();

export const fetchLocationAsync = createFetchAsync(
  'location',
  'findAll',
  async (params, { rejectWithValue }) => {
    try {
      return await locationApi.findAll({ limit: 1000, ...params });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forceFetchLocation = fetchLocationAsync.asyncThunk;

export const fetchLocation = (payload) => (dispatch, getState) => {
  const {
    location: { findAll }
  } = getState();

  if (!findAll.payload && findAll.loading == false) {
    dispatch(forceFetchLocation(payload));
  }
};

type LocationState = {
  findAll: AsyncState<FindAllResponse<LocationDto>>;
};

const initialState: LocationState = {
  findAll: fetchLocationAsync.initialState
};

const location = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchLocationAsync.extraReducers
  }
});

export default location.reducer;
