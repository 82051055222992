import { PageHeader } from '../../components/page-header';
import { SearchField } from '../../components/fields';
import { Button, IconButton, Stack } from '@mui/material';
import { IconPen, IconTrash } from '../../components/icons';
import { TableView } from '../../components/table-view';
import { rolesColumns } from './_mockdata';
import { url } from '../../core';
import {
  ROUTE_ROLES_CREATE,
  ROUTE_ROLES_EDIT
} from '../../constants/route.paths';
import { Link } from 'react-router-dom';
import { RoleApi } from '../../core/http';
import { useListResource } from '../../core/hooks/useListResource';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import get from 'lodash/get';

const roleApi = new RoleApi();

export const RolesContainer = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const {
    data,
    isLoading,
    fetchData,
    deleteRecord,
    pagination,
    handleChangePage,
    handleChangePerPage,
    handleChangeParams,
    newParamsLoading
  } = useListResource(roleApi);

  const handleDelete = (uuid: string) => () => {
    openConfirm({
      title: 'Delete',
      message: 'Do you really want to delete this record?',
      onConfirm: () => {
        deleteRecord(uuid).then(() => {
          fetchData();
          closeConfirm();
        });
      }
    });
  };

  return (
    <>
      <PageHeader title="User Roles" />
      <Stack direction="row" justifyContent="space-between">
        <Button component={Link} to={ROUTE_ROLES_CREATE}>
          Add New
        </Button>
        <SearchField
          loading={newParamsLoading}
          sx={{ width: 400, mb: 3 }}
          size="small"
          placeholder="Search"
          onChange={(value) => {
            handleChangeParams('search', value || null);
          }}
        />
      </Stack>
      <TableView
        showEmptyState
        loading={isLoading}
        pagination={pagination}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        keyExtractor={(item: any) => item.uuid}
        dataSource={get(data, 'items', [])}
        columns={[
          ...rolesColumns,
          {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            cellProps: {
              width: 40
            },
            render: (_, record) => (
              <Stack direction="row" alignItems="center">
                <IconButton
                  size="small"
                  component={Link}
                  to={url(ROUTE_ROLES_EDIT, { id: record.uuid })}
                >
                  <IconPen />
                </IconButton>
                <IconButton size="small" onClick={handleDelete(record.uuid)}>
                  <IconTrash />
                </IconButton>
              </Stack>
            )
          }
        ]}
      />
    </>
  );
};
