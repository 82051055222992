import { DataTableColumn } from '../../components/table-view/TableView';
import { UserRecord } from '../../components/user-record';
import { Badge } from '../../components/badge';
import { addDays, format, setHours } from 'date-fns';
import get from 'lodash/get';
import { DATE_FORMAT } from '../../constants/app.constants';
import { normalizeDate } from '../../core';

export const usersData = [
  {
    id: 0,
    name: 'Theresa',
    lastName: 'Webb'
  },
  {
    id: 1,
    name: 'Flores',
    lastName: 'Flores'
  },
  {
    id: 2,
    name: 'Albert',
    lastName: 'Einshtein'
  },
  {
    id: 3,
    name: 'Debra',
    lastName: 'Leone'
  },
  {
    id: 4,
    name: 'Ariana',
    lastName: 'Grande'
  }
];

export const appointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'time',
    sortable: false,
    render: ({ startDate }) => format(new Date(startDate), 'HH:mm a')
  },
  {
    title: 'Therapist',
    dataIndex: 'primaryTherapist',
    key: 'therapist',
    render: ({ firstName, lastName }) => (
      <UserRecord
        user={{
          avatar: 'none',
          name: `${firstName} ${lastName} `
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sortable: true,
    render: (value) => <Badge label={value} variant={value} />
  },
  {
    title: 'Type',
    dataIndex: 'type.title',
    key: 'type',
    sortable: true
  },
  {
    title: 'Location',
    dataIndex: 'location.name',
    key: 'location'
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription'
  }
];

export const appointmentsData = [
  {
    id: 0,
    start: setHours(addDays(new Date(), -2), 8).toISOString(),
    end: setHours(addDays(new Date(), -2), 11).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 1,
    start: setHours(addDays(new Date(), 0), 9).toISOString(),
    end: setHours(addDays(new Date(), 0), 10).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Not Converted',
      type: 'warning'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 2,
    start: setHours(addDays(new Date(), 5), 13).toISOString(),
    end: setHours(addDays(new Date(), 5), 14).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Conflicted',
      type: 'error'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 3,
    start: setHours(addDays(new Date(), 6), 17).toISOString(),
    end: setHours(addDays(new Date(), 6), 18).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 4,
    start: setHours(addDays(new Date(), 0), 12).toISOString(),
    end: setHours(addDays(new Date(), 0), 13).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 5,
    start: setHours(addDays(new Date(), 1), 13).toISOString(),
    end: setHours(addDays(new Date(), 1), 14).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Confliced',
      type: 'error'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 6,
    start: setHours(addDays(new Date(), 1), 8).toISOString(),
    end: setHours(addDays(new Date(), 1), 9).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 7,
    start: setHours(addDays(new Date(), 4), 12).toISOString(),
    end: setHours(addDays(new Date(), 4), 13).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Not Converted',
      type: 'warning'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 8,
    start: setHours(addDays(new Date(), 5), 13).toISOString(),
    end: setHours(addDays(new Date(), 5), 14).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 9,
    start: setHours(addDays(new Date(), 6), 8).toISOString(),
    end: setHours(addDays(new Date(), 6), 9).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 10,
    start: setHours(addDays(new Date(), 4), 14).toISOString(),
    end: setHours(addDays(new Date(), 4), 15).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  },
  {
    id: 11,
    start: setHours(addDays(new Date(), 4), 15).toISOString(),
    end: setHours(addDays(new Date(), 4), 16).toISOString(),
    date: '01/28/2022',
    time: '09:00 AM – 10:00 AM',
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/12.jpg',
      name: 'Theresa Webb'
    },
    status: {
      label: 'Converted',
      type: 'success'
    },
    type: 'Admin work',
    location: 'Stars Therapy Inc 113958',
    code: '65445',
    description: 'ABA Assessment'
  }
];

export const coreTeamMembersColumns: DataTableColumn[] = [
  {
    title: 'Therapist',
    dataIndex: 'employee',
    key: 'employee',
    render: ({ firstName, lastName, avatar }) => (
      <UserRecord
        user={{ name: `${firstName} ${lastName}`, avatar: avatar }}
        color="primary"
      />
    )
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (_, value) => get(value, 'employee.title.title', '-')
  },
  {
    title: 'Matching',
    dataIndex: 'matching',
    key: 'matching',
    render: (value) => value + '%',
    sortable: true
  },
  {
    title: 'Forecast (h)',
    dataIndex: 'forecast',
    key: 'forecast',
    sortable: true
  },
  {
    title: 'Sch (W)',
    dataIndex: 'scheduled',
    key: 'scheduled',
    sortable: true
  },
  {
    title: 'Conv (W)',
    dataIndex: 'conversion',
    key: 'conversion',
    sortable: true
  }
];

export const coreTeamMembersData = [
  {
    id: 1,
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    title: 'Therapist',
    matching: '49%',
    forecast: '32',
    sch: '32',
    conv: '12'
  },
  {
    id: 2,
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Annette Black'
    },
    title: 'Tech Support',
    matching: '34%',
    forecast: '22',
    sch: '15',
    conv: '56'
  },
  {
    id: 3,
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
      name: 'Leslie Alexander'
    },
    title: 'SLPA',
    matching: '32%',
    forecast: '32',
    sch: '16',
    conv: '13'
  },
  {
    id: 4,
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/4.jpg',
      name: 'Albert Flores'
    },
    title: 'SLPA',
    matching: '29%',
    forecast: '33',
    sch: '13',
    conv: '18'
  },
  {
    id: 5,
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'Dianne Russell'
    },
    title: 'CBS',
    matching: '12%',
    forecast: '11',
    sch: '17',
    conv: '12'
  }
];

export const newMakeUpAppointmentColumns: DataTableColumn[] = [
  {
    title: 'Date',
    dataIndex: 'startDate',
    key: 'startDate',
    sortable: true,
    render: (value) => normalizeDate(value)
  },
  {
    title: 'Time',
    dataIndex: 'startDate',
    key: 'time',
    sortable: false,
    render: (_, { startDate }) => format(new Date(startDate), 'HH:mm a')
  },
  {
    title: 'First Name',
    dataIndex: 'client.firstName',
    key: 'firstName'
  },
  {
    title: 'Last Name',
    dataIndex: 'client.lastName',
    key: 'lastName'
  },
  {
    title: 'Therapist',
    dataIndex: 'primaryTherapist',
    key: 'therapist',
    render: ({ firstName, lastName }) => (
      <UserRecord
        user={{
          avatar: 'none',
          name: `${firstName} ${lastName} `
        }}
        color="common.dark"
      />
    )
  },
  {
    title: 'Service Code',
    dataIndex: 'servicePlace.code',
    key: 'serviceCode',
    render: (value) => value || '-'
  },
  {
    title: 'Service Description',
    dataIndex: 'serviceDescription',
    key: 'serviceDescription',
    render: (value) => value || '-'
  },
  {
    title: 'Make Up (h)',
    dataIndex: 'makeUp',
    key: 'makeUp',
    render: (value) => value || '-'
  },
  {
    title: 'Make Up left (h)',
    dataIndex: 'makeUpLeft',
    key: 'makeUpLeft',
    render: (value) => value || '-',
    sortable: true
  },
  {
    title: 'Make Up Date',
    dataIndex: 'makeUpDate',
    key: 'makeUpDate',
    render: (date) => {
      return date ? format(new Date(date), DATE_FORMAT) : '-';
    }
  }
];

export const newMakeUpAppointmentsData = [
  {
    id: 1,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 2,
    makeUpLeft: 0,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 2,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Annette Black'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    provider: 'SARC',
    makeUp: 3,
    makeUpLeft: 1,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 3,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  },
  {
    id: 4,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Jerome',
      lastName: 'Bell'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Albert Flores'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  },
  {
    id: 5,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 2,
    makeUpLeft: 0,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 6,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Annette Black'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    provider: 'SARC',
    makeUp: 3,
    makeUpLeft: 1,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 7,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  },
  {
    id: 8,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Jerome',
      lastName: 'Bell'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Albert Flores'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  },
  {
    id: 9,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Albert',
      lastName: 'Flores'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
      name: 'Theresa Webb'
    },
    serviceCode: {
      name: '97151'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 2,
    makeUpLeft: 0,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 10,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Kathryn',
      lastName: 'Murphy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
      name: 'Annette Black'
    },
    serviceCode: {
      name: 'x4303'
    },
    serviceDescription: {
      name: 'Speech & Language'
    },
    provider: 'SARC',
    makeUp: 3,
    makeUpLeft: 1,
    makeUpDate: new Date().toISOString()
  },
  {
    id: 11,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Arlene',
      lastName: 'McCoy'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
      name: 'Leslie Alexander'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  },
  {
    id: 12,
    startDate: setHours(addDays(new Date(), -2), 8).toISOString(),
    endDate: setHours(addDays(new Date(), -2), 11).toISOString(),
    client: {
      firstName: 'Jerome',
      lastName: 'Bell'
    },
    therapist: {
      avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
      name: 'Albert Flores'
    },
    serviceCode: {
      name: 'H0031'
    },
    serviceDescription: {
      name: 'ABA Assessment'
    },
    provider: 'SARC',
    makeUp: 0,
    makeUpLeft: 0,
    makeUpDate: null
  }
];
