import {
  Box,
  IconButton,
  Stack,
  TableContainer,
  Typography
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { addDays, startOfDay, addHours, format, setHours } from 'date-fns';
import { get, map } from 'lodash';
import SimpleBar from 'simplebar-react';
import { CalendarViewHeader } from './calendar-view-header/CalendarViewHeader';
import { arrayFromLength, pluralize } from '../../core/utils/data.utils';
import useStyles from './styles';
import clsx from 'clsx';
import { useCalendarLayout } from './useCalendarLayout';
import { IconClose } from '../icons';
import { calculatePositions, excludeOldItems, groupItems } from './utils';
import {
  DEFAULT_CELL_HEIGHT,
  DISPLAY_DAYS,
  GROUP_DATE_FORMAT,
  GROUP_HOUR_FORMAT,
  HOUR_FORMAT,
  ITEM_INFO_HEIGHT
} from './constants';
import { TimeSlots } from './TimeSlots';
import { useOutsideClicked } from '../../core/hooks/useOutsideClicked';

type Item = Record<string, any>;

interface Props {
  startDate?: Date | null;
  groupKey?: string;
  dataSource: Item[];
  onClickItem?: (item: Item) => void;
  renderItem: (
    item: Record<string, any>,
    date: Date,
    selectedItem?: Record<string, any>
  ) => JSX.Element;
  renderDrawer?: (item: Record<string, any>) => JSX.Element;
  renderDrawerFooter?: (item: Record<string, any>) => JSX.Element;
  contentHeight?: number;
  subtractHeight?: number;
}

export const CalendarView: FC<Props> = ({
  startDate: date,
  dataSource,
  groupKey = 'date',
  renderItem,
  renderDrawer,
  renderDrawerFooter,
  contentHeight,
  subtractHeight
}) => {
  const refOutside = useOutsideClicked({
    onClickedOutside: () => {
      handleCloseDrawer();
    }
  });

  const {
    contentHeight: clientHeight,
    open,
    setOpen
  } = useCalendarLayout(subtractHeight);
  const [selectedRecord, setSelectedRecord] = useState<Item | null>(null);
  const classes = useStyles();
  const startDate = startOfDay(date || new Date());
  const endDate = addDays(startDate, DISPLAY_DAYS);
  const hourColumnWidth = 60;
  const columnWidth = Math.floor(100 / DISPLAY_DAYS);
  const startHour = 8;
  const endHour = 24;
  const hours = endHour - startHour + 1;

  const actualDataSource = excludeOldItems(
    startDate,
    endDate,
    dataSource,
    groupKey
  );
  const [groupedByDate, groupedByHour] = groupItems(actualDataSource, groupKey);

  const { positions, totalHeight } = calculatePositions(
    groupedByHour,
    startDate,
    startHour,
    hours
  );

  const handleClickRecord = (item: Item) => () => {
    setOpen(true);
    setSelectedRecord(item);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
    setSelectedRecord(null);
  };
  const renderEvents = useCallback(
    (dayIndex: number) => {
      return arrayFromLength(hours).map((_, hourIndex) => {
        const currentDate = addDays(
          setHours(startDate, startHour + hourIndex),
          dayIndex
        );
        const prevHour = addHours(startDate, startHour + hourIndex - 1);
        const prevHourKey = format(prevHour, HOUR_FORMAT);
        const groupItems = get(
          groupedByHour,
          format(currentDate, GROUP_HOUR_FORMAT),
          []
        );
        const hourPosition = get(positions, prevHourKey, 0);
        let offset = 0;

        return map(groupItems, (item: any) => {
          const height = item?.endDate ? DEFAULT_CELL_HEIGHT : ITEM_INFO_HEIGHT;
          const maxHeight = ITEM_INFO_HEIGHT;
          const top = hourPosition + offset;
          offset = offset + height;

          if (item?.allDay) {
            return (
              <Box
                key={item.uuid}
                sx={{
                  position: 'absolute',
                  inset: 0
                }}
              >
                {renderItem(item, currentDate, selectedRecord)}
              </Box>
            );
          }
          return (
            <Box
              key={item.uuid}
              onClick={handleClickRecord(item)}
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                '&:hover': {
                  zIndex: 2,
                  left: 0,
                  transition: 'all 0.2s ease-in-out'
                }
              }}
              style={{
                top,
                height: maxHeight
              }}
            >
              {renderItem(item, currentDate, selectedRecord)}
            </Box>
          );
        });
      });
    },
    [groupedByHour]
  );

  return (
    <Box className={classes.root}>
      <TableContainer>
        <table className={classes.tableHeader}>
          <thead>
            <tr>
              <td width={hourColumnWidth} />
              {arrayFromLength(DISPLAY_DAYS).map((_, index) => {
                const currentDate = addDays(startDate, index);
                const recordsCount = get(
                  groupedByDate,
                  format(currentDate, GROUP_DATE_FORMAT),
                  0
                );

                return (
                  <td key={index} width={`${columnWidth}%`}>
                    <CalendarViewHeader
                      date={currentDate}
                      meta={pluralize(recordsCount, 'record')}
                    />
                  </td>
                );
              })}
            </tr>
          </thead>
        </table>
      </TableContainer>

      <SimpleBar style={{ height: contentHeight || clientHeight }}>
        <TableContainer>
          <Box component="table" className={classes.table} height={totalHeight}>
            <tbody>
              <tr>
                <Box component="td" width={hourColumnWidth} position="relative">
                  <TimeSlots {...{ hours, positions, startDate, startHour }} />
                </Box>

                {arrayFromLength(DISPLAY_DAYS).map((_, dayIndex) => {
                  return (
                    <Box
                      component="td"
                      key={dayIndex}
                      width={`${columnWidth}%`}
                      position="relative"
                      className={clsx({
                        [classes.hasItems]: false
                      })}
                    >
                      {renderEvents(dayIndex)}
                    </Box>
                  );
                })}
              </tr>
            </tbody>
          </Box>
        </TableContainer>
      </SimpleBar>

      {/* DRAWER */}

      <Box
        ref={refOutside}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open
        })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          width="100%"
        >
          <Typography variant="h4">Appointment details</Typography>
          <IconButton
            onClick={handleCloseDrawer}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <IconClose />
          </IconButton>
        </Stack>
        {renderDrawer && selectedRecord ? renderDrawer(selectedRecord) : null}
        {renderDrawerFooter && selectedRecord ? (
          <Box
            sx={{
              position: 'sticky',
              bottom: -24,
              background: '#fff',
              margin: '0 -24px -26px -24px',
              padding: '12px 0 24px 24px',
              width: 289
            }}
          >
            {renderDrawerFooter(selectedRecord)}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
