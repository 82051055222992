import { Expose } from 'class-transformer';

export class AuthorizationDto {
  @Expose()
  uuid: string;

  @Expose()
  serviceCodeUuid: string;

  @Expose()
  startDate: string;

  @Expose()
  endDate: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  duration: number;
}
