import { FC } from 'react';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import map from 'lodash/map';
import { IconArrowRight16 } from '../icons';
import useStyles from './styles';
import clsx from 'clsx';

interface Props extends BoxProps {
  items: string[];
  activeStep: number;
}

export const HorizontalStepper: FC<Props> = ({
  items,
  activeStep,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box {...props}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {map(items, (label, index) => {
          const isPassed = index < activeStep;
          const isActive = index === activeStep;

          return (
            <Stack key={index} spacing={1} direction="row" alignItems="center">
              {index > 0 && <IconArrowRight16 sx={{ mr: 1 }} />}
              <Box
                className={clsx(classes.step, {
                  [classes.stepPassed]: isPassed,
                  [classes.stepActive]: isActive
                })}
              >
                {index + 1}
              </Box>
              <Typography
                className={clsx(classes.label, {
                  [classes.labelPassed]: isPassed,
                  [classes.labelActive]: isActive
                })}
              >
                {label}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};
