import {
  compose,
  withAuthorized,
  withBaseLayout,
  withHelmet
} from '../../core';
import { AnnouncementsContainer } from '../../features/announcements/AnnouncementsContainer';

export const AnnouncementsPage = compose(
  withAuthorized,
  withBaseLayout,
  withHelmet({
    title: 'Splashworks: Announcements'
  })
)(AnnouncementsContainer);
