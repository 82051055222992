import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../configs/authService';

export const logout = createAsyncThunk('logoutAction', async () => {
  try {
    const response: any = await authService.get('/logout');
    return response;
  } catch (e) {
    throw new Error('Something went wrong');
  }
});

export const appSlice = createSlice({
  name: 'appSlice',
  initialState: {
    logout: {
      loading: false,
      data: null,
      error: null
    }
  } as any,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state) => {
      state.logout.loading = true;
      state.logout.data = {};
      state.logout.error = null;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.logout.loading = false;
      state.logout.data = payload;
      state.logout.error = null;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logout.loading = false;
      state.logout.data = {};
      state.logout.error = 'Something went wrong, please refresh page.';
    });
  }
});

export default appSlice.reducer;
