import { useEffect, useState } from 'react';
import { addDays, startOfWeek } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useListResource } from './useListResource';
import { AppointmentApi, AppointmentDto } from '../http';
import { useDialog } from './useDialog';
import { CancelAppointmentDialog } from '../../features/appointments/dialogs/CancelAppointmentDialog';
import { DeleteAppointmentDialog } from '../../features/appointments/dialogs/DeleteAppointmentDialog';
import { ConvertToTimesheetDialog } from '../../features/appointments/dialogs/ConvertToTimesheetDialog';
import { url } from '../utils';
import {
  ROUTE_CLIENT_APPOINTMENT_EDIT,
  ROUTE_CLIENT_APPOINTMENT_VIEW,
  ROUTE_EMPLOYEE_APPOINTMENT_EDIT,
  ROUTE_EMPLOYEE_APPOINTMENT_VIEW,
  ROUTE_MAKE_UP_APPOINTMENTS_EDIT
} from '../../constants/route.paths';

const appointmentApi = new AppointmentApi();

interface UseAppointmentListProps {
  clientId?: string;
  employeeId?: string;
  defaultParams: Record<string, any>;
}

export const useAppointmentList = ({
  clientId,
  employeeId,
  defaultParams
}: UseAppointmentListProps) => {
  const navigate = useNavigate();
  const [openConvertToTimesheet, closeConvertToTimesheet] = useDialog(
    ConvertToTimesheetDialog
  );
  const [openCancelDialog, closeCancelDialog] = useDialog(
    CancelAppointmentDialog
  );
  const [openDeleteDialog, closeDeleteDialog] = useDialog(
    DeleteAppointmentDialog
  );
  const defaultFromDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const {
    data,
    pagination,
    isLoading,
    handleChangeParams,
    handleChangeSort,
    fetchData
  } = useListResource(appointmentApi, {
    defaultParams: {
      'filter[startDate][gt]': defaultFromDate.toISOString(),
      'filter[endDate][lte]': addDays(defaultFromDate, 7).toISOString(),
      limit: 999999,
      ...defaultParams
    }
  });

  useEffect(() => {
    if (startDate) {
      handleChangeParams({
        'filter[startDate][gt]': startDate.toISOString(),
        'filter[endDate][lte]': addDays(startDate, 7).toISOString(),
        page: 1
      });
    }
  }, [startDate]);

  const handleStatusFilter = (statusFilter) => {
    if (statusFilter === 'all') {
      handleChangeParams('statusFilter', null);
    } else handleChangeParams('statusFilter', statusFilter);
  };

  const handleChangePerPage = (perPage) => {
    handleChangeParams('limit', perPage);
  };

  const handleChangePage = (page) => {
    handleChangeParams('page', page);
  };

  const handleDelete = (appointment) => {
    openDeleteDialog({
      appointment,
      onSuccess: () => {
        closeDeleteDialog();
        fetchData();
      }
    });
  };

  const handleConvert = (appointment) => {
    openConvertToTimesheet({
      appointment,
      onSuccess: () => {
        closeConvertToTimesheet();
        fetchData();
      }
    });
  };

  const handleCancel = (appointment) => {
    openCancelDialog({
      appointment,
      onSuccess: () => {
        closeCancelDialog();
        fetchData();
      }
    });
  };

  const handleClientEdit = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_CLIENT_APPOINTMENT_EDIT, {
        uuid: appointment.uuid,
        clientId
      })
    );
  };

  const handleClientView = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_CLIENT_APPOINTMENT_VIEW, {
        uuid: appointment.uuid,
        clientId
      })
    );
  };

  const handleClientMakeUp = (appointment: AppointmentDto) => {
    navigate(url(ROUTE_MAKE_UP_APPOINTMENTS_EDIT, { id: appointment.uuid }));
  };

  const handleEmployeeEdit = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_EMPLOYEE_APPOINTMENT_EDIT, {
        uuid: appointment.uuid,
        employeeId
      })
    );
  };

  const handleEmployeeView = (appointment: AppointmentDto) => {
    navigate(
      url(ROUTE_EMPLOYEE_APPOINTMENT_VIEW, {
        uuid: appointment.uuid,
        employeeId
      })
    );
  };

  const handleEmployeeMakeUp = (appointment: AppointmentDto) => {
    navigate(url(ROUTE_MAKE_UP_APPOINTMENTS_EDIT, { id: appointment.uuid }));
  };

  return {
    data,
    pagination,
    isLoading,
    handleChangeParams,
    handleChangeSort,
    handleCancel,
    handleDelete,
    handleStatusFilter,
    handleChangePerPage,
    handleChangePage,
    handleConvert,
    handleClientEdit,
    handleClientView,
    handleClientMakeUp,
    handleEmployeeEdit,
    handleEmployeeMakeUp,
    handleEmployeeView,
    startDate,
    setStartDate
  };
};
