import { ComponentType } from 'react';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import get from 'lodash/get';

interface Props {
  user: {
    name: string;
    avatar: string;
  };
  color?: string;
  variant?: 'small' | 'medium' | 'large';
  NameProps?: TypographyProps;
}

const AVATAR_SIZES = {
  small: 16,
  medium: 24,
  large: 40
};

export const UserRecord: ComponentType<Props> = ({
  user,
  color = 'primary',
  variant = 'medium',
  NameProps
}) => {
  const avatarSize = get(AVATAR_SIZES, variant);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        sx={{ width: avatarSize, height: avatarSize }}
        src={user?.avatar}
      />
      <Typography variant="body2" color={color} {...NameProps}>
        {user.name}
      </Typography>
    </Stack>
  );
};
