import { normalizeDate } from '../../../core/utils/data.utils';

export const holidayColumns = [
  {
    title: 'Title',
    key: 'title',
    dataIndex: 'title',
    cellProps: {
      sx: {
        width: '40%'
      }
    }
  },
  {
    title: 'Start Date',
    key: 'startDate',
    dataIndex: 'startDate',
    render: (date: string) => normalizeDate(date)
  },
  {
    title: 'End Date',
    key: 'endDate',
    dataIndex: 'endDate',
    render: (date: string) => normalizeDate(date)
  }
];
